/*
 * Search input component.
 */
import cn from 'clsx';
import { ChangeEvent, FC, FocusEvent, KeyboardEvent, useRef, useState } from 'react';
import { Input, TInputProps } from '@uikit/components/Fields/Inputs/Input';
import { Icon, ICON_TYPES } from '@uikit/components/Icon';
import { noop } from '@uikit/helpers/common.helpers';
import { triggerNativeEventFor } from '@uikit/utils/event.utils';
import styles from './SearchInput.module.scss';

type TSearchInputProps = {
  value: string;
  onChange: (value: string) => void;
  clearable?: boolean;
  onSubmit?: () => void;
} & TInputProps;

export const SearchInput: FC<TSearchInputProps> = ({
  value,
  onChange,
  input,
  clearable = true,
  onSubmit = noop,
  className,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inFocus, setInFocus] = useState<boolean>(false);
  const [wasClear, setWasClear] = useState(false);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    if (input?.onChange) {
      input.onChange(e);
      return;
    }

    onChange(e.target.value);
  };

  const onFocus = (): void => {
    setInFocus(true);
    input?.onFocus && input?.onFocus();
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>): void => {
    // Костыль с wasClear нужен так как есть проблема вокруг того,
    // что blur отрабатывает после onMouseDown на крест очищения и снимает фокус с input - чего происходить не должно.
    if (!wasClear) {
      setInFocus(false);
      input?.onBlur && input?.onBlur();
      return;
    }

    e.target.focus();
    setWasClear(false);
  };

  // Специально вызывается на mouseDown (не click) так как иначе blur происходит раньше
  // и скрывает крест очищения - событие click не происходит.
  const onClear = (): void => {
    triggerNativeEventFor(inputRef.current as HTMLInputElement, { event: 'input', value: '' });
    setWasClear(true);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      onSubmit && onSubmit();
    }
  };

  return (
    <div className={cn(styles.searchInput__container, className)}>
      <Input
        ref={inputRef}
        className={styles.searchInput}
        {...props}
        hideError
        onKeyDown={onKeyDown}
        input={{
          ...input,
          name: input?.name || 'searchInput',
          value: input?.value || value,
          onChange: onChangeHandler,
          onBlur,
          onFocus,
        }}
      />
      {
        // if field on focusing or is any word
        clearable && (inFocus || !!value.length) ? (
          <Icon
            className={cn(styles.searchInput__icon, styles.searchInput__icon_close)}
            type={ICON_TYPES.CLOSE}
            size={10}
            onMouseDown={onClear}
          />
        ) : (
          <Icon
            className={cn(styles.searchInput__icon, !clearable && styles.searchInput__icon_search)}
            type={ICON_TYPES.SEARCH}
            onClick={onSubmit}
          />
        )
      }
    </div>
  );
};
