import { useStore } from 'effector-react/compat';
import compact from 'lodash/compact';
import { FC, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  ICampaignFullInfoDto,
  ICampaignListItemDto,
  IYoutubeSmaStatisticsResponseDto,
  SmaPlatform,
} from '@hypetrainCommon';
import { dialogService } from '@uikit/components/Dialog';
import { GroupActions, TGroupAction } from '@uikit/components/GroupActions';
import { Routes } from '@router/Routes';
import { campaignsUserAccessService } from '@services/userAccess';
import { useSearchPlatformMetrics } from '@hooks/useSearchPlatformMetrics.hook';
import { $activeSocialPlatform } from '@models/activeSocialPlatform';
import { $filteredSmaStore, $selectedSmaStore } from '@models/selection.model';
import {
  $campaignStore,
  createCampaignDealsFx,
} from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.model';
import {
  AddToExistingCampaignDialog,
  TAddToExistingCampaignDialogProps,
} from '@pagesCampaigns/CampaignDetailsPage/components/AddToExistingCampaignDialog';
import {
  $campaignsSimpleList,
  getCampaignsSimpleListFx,
} from '@pagesCampaigns/CampaignsPage/campaignsPage.model';
import {
  CreateCampaignDialog,
  TCreateCampaignDialogProps,
} from '@pagesCampaigns/CampaignsPage/components/CreateCampaignDialog';
import { $browseInfluencers } from '@pagesDiscovery/Browse/browse.model';
import { $searchStore } from '@pagesDiscovery/InfluencersSearch/influencerSearch.model';
import { $uploadedUsersStore } from '@pagesDiscovery/InfluencersUpload/influencersUpload.model';

export const SummaryGroupActions: FC = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedInfluencers = useStore($selectedSmaStore);
  const currentCampaign = useStore($campaignStore);
  const filteredInfluencers = useStore($filteredSmaStore);
  const activePlatform = useStore($activeSocialPlatform);
  const browseInfluencers = useStore($browseInfluencers);
  const searchInfluencers = useStore($searchStore);
  const uploadInfluencers = useStore($uploadedUsersStore);
  const allCampaigns = useStore($campaignsSimpleList);
  const selectedInfValues = useMemo(
    () => Object.values(selectedInfluencers).filter((inf) => !inf.isDisabled),
    [selectedInfluencers]
  );
  // @ts-ignore TODO Андрей
  const metrics = useSearchPlatformMetrics(filteredInfluencers, activePlatform.platform);

  useEffect(() => {
    getCampaignsSimpleListFx();
  }, []);

  const openCampaignPage = (campaignId: string) => {
    const path = generatePath(Routes.campaign, { id: campaignId });
    navigate(path);
  };

  const openAddToExistingCampaignDialog = (): void => {
    dialogService.open<string, TAddToExistingCampaignDialogProps>({
      component: AddToExistingCampaignDialog,
      props: {
        influencers: selectedInfValues as IYoutubeSmaStatisticsResponseDto[],
        campaigns: allCampaigns as ICampaignListItemDto[],
      },
    });
  };

  const addToCampaign = (): void => {
    if (!currentCampaign) return;

    const smas = selectedInfValues.map((influencer) => influencer.sma.uuid);

    createCampaignDealsFx({
      campaignId: currentCampaign.campaignDetails.id,
      body: { smas },
    }).then(() => openCampaignPage(currentCampaign.campaignDetails.id));
  };

  const openCreateCampaignDialog = (): void => {
    dialogService.open<ICampaignFullInfoDto, TCreateCampaignDialogProps>({
      component: CreateCampaignDialog,
    });
  };

  const title =
    activePlatform?.platform === SmaPlatform.Youtube
      ? t('summaryGroupAction.channelsAdded', {
          count: filteredInfluencers?.length,
        })
      : t('summaryGroupAction.accountsAdded', {
          count: filteredInfluencers?.length,
        });

  const bloggerWord =
    activePlatform?.platform === SmaPlatform.Youtube
      ? t('summaryGroupAction.influencers')
      : t('summaryGroupAction.accounts');

  const canAddInExistingCampaign = allCampaigns?.some(
    (camp) => camp.platform === activePlatform?.platform
  );

  const createCampaignAction: TGroupAction = {
    handler: openCreateCampaignDialog,
    tooltip: !selectedInfValues?.length
      ? t('summaryGroupAction.proceedToCampaignTooltip', { blogger: bloggerWord })
      : '',
    button: { disabled: !selectedInfValues?.length },
    name: t('summaryGroupAction.createCampaign'),
    isHide: !campaignsUserAccessService.createCampaign,
  };

  const addToExistingCampaignAction: TGroupAction = {
    handler: openAddToExistingCampaignDialog,
    tooltip: !selectedInfValues?.length
      ? t('summaryGroupAction.proceedToCampaignTooltip', { blogger: bloggerWord })
      : '',
    button: { disabled: !selectedInfValues?.length },
    name: campaignsUserAccessService.createCampaign
      ? t('summaryGroupAction.proceedToCampaign')
      : t('summaryGroupAction.addToCampaign'),
    isHide: !campaignsUserAccessService.canAddToExistingCampaignFromList,
  };

  const proceedToCampaignAction =
    canAddInExistingCampaign && campaignsUserAccessService.canAddToExistingCampaignFromList
      ? addToExistingCampaignAction
      : createCampaignAction;

  const addToCurrentCampaignAction: TGroupAction = {
    handler: addToCampaign,
    tooltip: !selectedInfValues?.length
      ? t('summaryGroupAction.saveTooltip', { blogger: bloggerWord })
      : '',
    button: {
      disabled: !selectedInfValues?.length,
    },
    name: t('summaryGroupAction.save', { blogger: bloggerWord }),
    isHide: !campaignsUserAccessService.addSmaInCampaign,
  };

  const actions = currentCampaign
    ? compact([addToCurrentCampaignAction])
    : compact([proceedToCampaignAction]);

  return (
    <div>
      {(browseInfluencers?.total ||
        searchInfluencers.total ||
        uploadInfluencers.smasStatistics?.length) && (
        <GroupActions
          actions={actions}
          title={title}
          metrics={metrics}
          isDiscovery
        />
      )}
    </div>
  );
});
