/**
 * Events for which popup is displayed.
 */
export enum PopupEvents {
  Click = 'click',
  Hover = 'hover',
}
/**
 * Popup display position.
 */
export enum PopupPositions {
  RightTop = 'right-top', // Drop right to top
  RightCenter = 'right-center', // Drop right center
  RightBottom = 'right-bottom', // Fall right to bottom
  LeftTop = 'left-top', // Drop left to top
  LeftCenter = 'left-center', // Drop Left Center
  LeftBottom = 'left-bottom', // Fall Left To Bottom
  BottomRight = 'bottom-right', // Drop down from the bottom and to the right side
  BottomCenter = 'bottom-center', // Drop down from bottom center
  BottomLeft = 'bottom-left', // Drop down from the bottom and to the left side
  TopRight = 'top-right', // Drop up and to the right
  TopCenter = 'top-center', // Drop down from top center
  TopLeft = 'top-left', // Drop up and to the left
}
/**
 * Side popup display.
 */
export enum PopupSides {
  Center = 'center',
  Right = 'right',
  Left = 'left',
  Bottom = 'bottom',
  Top = 'top',
}

/*
 * Popup visual design type.
 */
export enum PopupStyles {
  Default = 'Default',
  None = 'None',
}
