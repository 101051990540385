import { TSelectOptions } from '@uikit/components/SelectField/SelectField.types';

export enum RANGE_TYPES {
  PERCENT = 'PERCENT',
  NUMBER = 'NUMBER',
  CURRENCY = 'CURRENCY',
  SELECT = 'SELECT',
}

export type TRangeConfig = {
  type: RANGE_TYPES;
  fromOptions?: TSelectOptions;
  toOptions?: TSelectOptions;
  from: TRangeConfigItem;
  to: TRangeConfigItem;
};

export type TRangeConfigItem = {
  placeholder?: string;
  min?: number;
  max?: number;
  precision?: number; // 0 by default
  allowNegative?: boolean; // false by default
};

export type TRangeValue = { from?: number; to?: number };
