import dayjs from 'dayjs';
import * as Yup from 'yup';
import ArraySchema from 'yup/lib/array';
import { AnySchema } from 'yup/lib/schema';
import { EmailValidationErrorType, validateWorkspaceHandler } from '@hypetrainCommon';
import i18n from './i18n';

declare module 'yup' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  export interface StringSchema {
    validEmail(): StringSchema;
    validWorkspace(): StringSchema;
    validField(): StringSchema;
    validYoutubeVideoString(): StringSchema;
    validInstagramPost(): StringSchema;
    validInstagramReel(): StringSchema;
    validInstagramStory(): StringSchema;
    validYoutubeShort(): StringSchema;
    validLink(): StringSchema;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  export interface DateSchema {
    isAfterOrSame(startDatePropName: string, errorMessage: string): DateSchema;
    isBeforeOrSame(endDatePropName: string, errorMessage: string): DateSchema;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  export interface ArraySchema<T> {
    minByValidator<R = unknown>(
      minCount: number,
      validator: (item: R) => boolean,
      errorMessage: string
    ): ArraySchema<T>;
    uniq(errorMessage: string): ArraySchema<T>;
    requiredCheckboxGroup(errorMessage: string): ArraySchema<T>;
  }
}

export const errorsEmail: Record<EmailValidationErrorType, string> = {
  [EmailValidationErrorType.invalid]: i18n.t('invalidEmail'),
  [EmailValidationErrorType.blacklisted]: i18n.t('blacklistedEmail'),
};

Yup.addMethod(Yup.string, 'validEmail', function isEmailValidMessage() {
  return this.email(errorsEmail[EmailValidationErrorType.invalid]);
});

Yup.addMethod(Yup.string, 'validWorkspace', function isValidWorkspaceMessage() {
  return this.test('validWorkspace', '', function isValidWorkspaceValue(value) {
    const { path, createError } = this;

    const checkingWorkspace = value && validateWorkspaceHandler(value);

    if (!value || (checkingWorkspace && checkingWorkspace?.isValid)) return true;

    return createError({
      path,
      message:
        (checkingWorkspace && checkingWorkspace?.validationErrorMessage) || i18n.t('required'),
    });
  });
});

/*
 * Базовая проверка для поля.
 * До 255 символов, запрещает только пробелы (работает за счёт trim)
 */
function validField() {
  // @ts-ignore OK.
  return this.trim().max(255, i18n.t('errors.yup.validFieldMaxLength', { count: 255 }));
}
Yup.addMethod(Yup.string, 'validField', validField);

/*
 * Валиадор группы чекбоксов обязательной для заполнения.
 */
function requiredCheckboxGroup(this: ArraySchema<AnySchema>, errorText: string) {
  return this.transform((_, value) => Object.values(value).filter((v) => v))
    .of(Yup.boolean())
    .nullable()
    .min(1, i18n.t(errorText))
    .required(i18n.t(errorText));
}
Yup.addMethod<ArraySchema<AnySchema>>(
  Yup.array,
  'requiredCheckboxGroup',
  requiredCheckboxGroup as (
    this: ArraySchema<AnySchema>,
    errorText: string
  ) => ArraySchema<AnySchema>
);

/*
 * Проверяет дату на то, она больше или равна переданной.
 * @param startDatePropName - имя поле (в валидаторе) стартовой даты.
 * @param errorMessage - сообщение об ошибке.
 */
Yup.addMethod(
  Yup.date,
  'isAfterOrSame',
  function isAfterOrSame(startDatePropName: string, errorMessage: string) {
    // @ts-ignore OK.
    return this.test('isAfterOrSame', errorMessage, function isDateAfterOrSame(value: string) {
      const { path, createError, parent } = this;
      const startDate = parent[startDatePropName];

      if (!value || !startDate) return true;

      const isDateAfterStartDate = dayjs(value).isAfter(startDate);
      const isDatesTheStame = dayjs(value).isSame(startDate);

      if (isDateAfterStartDate || isDatesTheStame) return true;

      return createError({ path, message: errorMessage });
    });
  }
);

/*
 * Проверяет дату на то, что она меньше или равна переданной.
 * @param startDatePropName - имя поле (в валидаторе) стартовой даты.
 * @param errorMessage - сообщение об ошибке.
 */
Yup.addMethod(
  Yup.date,
  'isBeforeOrSame',
  function isBeforeOrSame(endDatePropName: string, errorMessage: string) {
    // @ts-ignore OK.
    return this.test('isBeforeOrSame', errorMessage, function isDateBeforeOrSame(value: string) {
      const { path, createError, parent } = this;
      const endDate = parent[endDatePropName];

      if (!value || !endDate) return true;

      const isDateBeforeEndDate = dayjs(value).isBefore(endDate);
      const isDatesTheStame = dayjs(value).isSame(endDate);

      if (isDateBeforeEndDate || isDatesTheStame) return true;

      return createError({ path, message: errorMessage });
    });
  }
);

/*
 * Проверяет, что массив имеет заданное минимальное колличество (minCount) элементов удовлятворяющих условию (validator).
 * Если находит, что уловие не выполяется выводит ошибку под первым полем, что неудовлетворяет условию.
 * Например из 15 полей минимум 6 должны удовлетворять условию. Заполнены только 4 (0, 1, 11, 14). Будет возвращена ошибка для поля с инжексом 2.
 */
Yup.addMethod(
  Yup.array,
  'minByValidator',
  function minByValidator(
    minCount: number,
    validator: (item: unknown) => boolean,
    errorMessage: string
  ) {
    // @ts-ignore OK.
    return this.test('minByValidator', errorMessage, function minByValidatorFn(value: unknown[]) {
      if (!value?.length) return true;

      const { path, createError } = this;
      const validItems = value.filter(validator);

      if (validItems.length >= minCount) return true;

      const firstInvalidItemIndex = value.findIndex((v) => !validator(v));

      return createError({ path: `${path}[${firstInvalidItemIndex}]`, message: errorMessage });
    });
  }
);

/*
 * Валиадор уникальности значений в массиве.
 * Если будут найдено повторяющееся значение, то для него будет возвращена переданная ошибка.
 */
Yup.addMethod(Yup.array, 'uniq', function uniq(errorMessage: string) {
  // @ts-ignore OK.
  return this.test('uniq', errorMessage, function uniqFn(value: unknown[]) {
    if (!value?.length) return true;

    const { path, createError } = this;
    const duplicateItemIndex = value.findIndex(
      (item, index) => item && index !== value.indexOf(item)
    );

    if (duplicateItemIndex === -1) return true;

    return createError({ path: `${path}[${duplicateItemIndex}]`, message: errorMessage });
  });
});

// Валидация для проверка ссылки на youtube video
function validYoutubeVideoString() {
  // @ts-ignore OK.
  return this.matches(
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/watch?(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/,
    i18n.t('errors.yup.validYoutubeVideoString')
  );
}
Yup.addMethod(Yup.string, 'validYoutubeVideoString', validYoutubeVideoString);

// Валидация для проверка ссылки на youtube shorts
function validYoutubeShort() {
  // @ts-ignore OK.
  return this.matches(
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))\/(?:shorts)(\/(?:[\w-]+\/)?)([\w-]+)(\S+)?$/,
    i18n.t('errors.yup.validYoutubeShort')
  );
}
Yup.addMethod(Yup.string, 'validYoutubeShort', validYoutubeShort);

// Валидация для проверка ссылки на instagram photo post
function validInstagramPost() {
  // @ts-ignore OK.
  return this.matches(
    /^((?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:p)\/([^/?#&]+)).*/,
    i18n.t('errors.yup.validInstagramPhotoPost')
  );
}
Yup.addMethod(Yup.string, 'validInstagramPost', validInstagramPost);

// Валидация для проверка ссылки на instagram reels
function validInstagramReel() {
  // @ts-ignore OK.
  return this.matches(
    /^((?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:reel|p)\/([^/?#&]+)).*/,
    i18n.t('errors.yup.validInstagramReel')
  );
}
Yup.addMethod(Yup.string, 'validInstagramReel', validInstagramReel);

// Валидация для проверка ссылки на instagram story
function validInstagramStory() {
  // @ts-ignore OK.
  return this.matches(
    /^((?:https?:\/\/)?(?:www\.)?instagram\.com\/stories\/[\w\W]+\/\d+).*/,
    i18n.t('errors.yup.validInstagramStory')
  );
}
Yup.addMethod(Yup.string, 'validInstagramStory', validInstagramStory);

// Валидация для проверка на наличие ссылки
function validLink() {
  // @ts-ignore OK.
  return this.matches(
    /^(https?:\/\/)?([\w-]{1,32}\.[\w-]{1,32})[^\s@]*$/,
    i18n.t('errors.yup.validLink')
  );
}
Yup.addMethod(Yup.string, 'validLink', validLink);
