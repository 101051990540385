/*
 * Utils for DatePickerField component.
 */
import dayjs from 'dayjs';
import {
  DATE_INTERVAL_VIEW_FORMAT,
  DATE_VIEW_FORMAT,
  ISO_DATE_FORMAT,
} from '@uikit/components/DatePickerField/datePickerField.constants';

/*
 * TODO: Если начинаем использовать вне date-picker'а рассмотреть вынос в shared
 * с соответсвующим переименованием в случае необходимости?.
 */

/*
 * Formats date according to the given format.
 */
export const formatDate = (date: string, format: string = DATE_VIEW_FORMAT): string => {
  if (!date) return '';

  return dayjs(date).format(format);
};

/*
 * Formats date interval according to the given format.
 */
export const formatDateInterval = (
  startDate: string,
  endDate: string,
  format: string = DATE_INTERVAL_VIEW_FORMAT
): string => {
  if (!startDate && !endDate) return '';

  const formattedStartDate = formatDate(startDate, format);
  const formattedEndDate = formatDate(endDate, format);

  if (!startDate) return `...–${formattedEndDate}`;
  if (!endDate) return `${formattedStartDate}–...`;

  return `${formattedStartDate}–${formattedEndDate}`;
};

/*
 * Formats date in ISO format.
 */
export const formatDateToISO = (date: Date): string => dayjs(date).utc(true).toISOString();

/*
 * Convers date to JS Date object.
 */
export const convertStringToDate = (
  date: string,
  format: string = ISO_DATE_FORMAT
): Date | null => {
  if (!date) return null;

  return dayjs(date, format).toDate();
};

/*
 * Возвращает TimeStamp от переданной даты.
 * Пример возвращаемого формата:
 *    Yesterday at 10:00 am
 *    Today at 10:45 am
 *    Aug 14, 2022 at 10:45 am
 * @see: https://bloggerslab.fibery.io/Design/Capitalization-and-formats-1096
 */
export function getTimeStamp(date: string): string {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return dayjs(date).tz(timeZone).calendar(null, {
    sameDay: '[Today at] H:mm a',
    lastDay: '[Yesterday at] H:mm a',
    sameElse: 'MMM D, YYYY [at] H:mm a',
  });
}
