/*
 * Компонент блока повторной отправки сообщения чата (сообщения, у которого есть получатели с ошибкой при получении).
 */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IGetUserMessagesResponseDto, MessageStatus } from '@hypetrainCommon';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '@uikit/components/Buttons';
import styles from './ResendMessage.module.scss';

type TResendMessageProps = {
  message: IGetUserMessagesResponseDto;
  onResend: () => void;
  onDelete: () => void;
};

export const ResendMessage: FC<TResendMessageProps> = ({ message, onResend, onDelete }) => {
  const { t } = useTranslation();
  const failedSends = message?.recipients?.filter(({ status }) => status === MessageStatus.error);
  const isAllFailed = failedSends?.length === message?.recipients?.length;
  const resendButtonTitle =
    isAllFailed && message?.recipients.length > 1
      ? t('resendAllChatMessage')
      : t('resendChatMessage');

  if (!failedSends?.length) return null;

  return (
    <div className={styles.resendMessage}>
      <span className={styles.resendMessage__label}>
        {isAllFailed ? t('allMessageNotSent') : t('messageNotSent', { count: failedSends.length })}
      </span>
      {isAllFailed && (
        <Button
          buttonType={BUTTON_TYPE.SECONDARY}
          size={BUTTON_SIZE.S}
          onClick={onDelete}
        >
          {t('deleteChatMessage')}
        </Button>
      )}
      <Button
        buttonType={BUTTON_TYPE.SECONDARY}
        size={BUTTON_SIZE.S}
        onClick={onResend}
      >
        {resendButtonTitle}
      </Button>
    </div>
  );
};
