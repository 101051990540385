/*
 * Utils of icon component.
 */
import cn from 'clsx';
import { FC, SVGProps } from 'react';
import Account from '@uikit/assets/images/account.svg?cmp';
import AddContact from '@uikit/assets/images/addContact.svg?cmp';
import AddressBook from '@uikit/assets/images/addressBook.svg?cmp';
import AnotherIcon from '@uikit/assets/images/anotherIcon.svg?cmp';
import Arrow from '@uikit/assets/images/arrow.svg?cmp';
import Back from '@uikit/assets/images/arrowBack.svg?cmp';
import Next from '@uikit/assets/images/arrowNext.svg?cmp';
import ArrowUpRight from '@uikit/assets/images/arrowUpRight.svg?cmp';
import Attach from '@uikit/assets/images/attach.svg?cmp';
import AudioIcon from '@uikit/assets/images/audioIcon.svg?cmp';
import BarChart from '@uikit/assets/images/barChart.svg?cmp';
import Calendar from '@uikit/assets/images/calendar.svg?cmp';
import Check from '@uikit/assets/images/check.svg?cmp';
import Close from '@uikit/assets/images/close.svg?cmp';
import CloseIcon from '@uikit/assets/images/closeIcon.svg?cmp';
import ContractsIcon from '@uikit/assets/images/contracts.svg?cmp';
import CopyIcon from '@uikit/assets/images/copy.svg?cmp';
import Delete from '@uikit/assets/images/delete.svg?cmp';
import DoubleArrows from '@uikit/assets/images/doubleArrows.svg?cmp';
import DoubleCheck from '@uikit/assets/images/doubleCheck.svg?cmp';
import DoubleRightArrow from '@uikit/assets/images/doubleRightArrow.svg?cmp';
import Down from '@uikit/assets/images/down.svg?cmp';
import DownIcon from '@uikit/assets/images/downIcon.svg?cmp';
import Download from '@uikit/assets/images/download.svg?cmp';
import Edit from '@uikit/assets/images/edit.svg?cmp';
import Etc from '@uikit/assets/images/etc.svg?cmp';
import Exclamation from '@uikit/assets/images/exclamation.svg?cmp';
import FacebookIcon from '@uikit/assets/images/facebook.svg?cmp';
import FileIcon from '@uikit/assets/images/file.svg?cmp';
import Filter from '@uikit/assets/images/filter.svg?cmp';
import Info from '@uikit/assets/images/info.svg?cmp';
import InstagramIcon from '@uikit/assets/images/instagramIcon.svg?cmp';
import Intercom from '@uikit/assets/images/intercom.svg?cmp';
import Link from '@uikit/assets/images/link.svg?cmp';
import LockIcon from '@uikit/assets/images/lock.svg?cmp';
import Mail from '@uikit/assets/images/mail.svg?cmp';
import MailWithCross from '@uikit/assets/images/mailWithCross.svg?cmp';
import Megaphone from '@uikit/assets/images/megaphone.svg?cmp';
import Message from '@uikit/assets/images/message.svg?cmp';
import MinimizeIcon from '@uikit/assets/images/minimize.svg?cmp';
import Minus from '@uikit/assets/images/minus.svg?cmp';
import PaymentIcon from '@uikit/assets/images/payment.svg?cmp';
import PdfIcon from '@uikit/assets/images/pdfIcon.svg?cmp';
import PersonIcon from '@uikit/assets/images/person.svg?cmp';
import PersonHand from '@uikit/assets/images/personWithHand.svg?cmp';
import PictureIcon from '@uikit/assets/images/pictureIcon.svg?cmp';
import Plus from '@uikit/assets/images/plus.svg?cmp';
import PttIcon from '@uikit/assets/images/pptIcon.svg?cmp';
import RarIcon from '@uikit/assets/images/rarIcon.svg?cmp';
import RefreshIcon from '@uikit/assets/images/refresh.svg?cmp';
import Search from '@uikit/assets/images/search.svg?cmp';
import SeatIcon from '@uikit/assets/images/seat.svg?cmp';
import Setting from '@uikit/assets/images/setting.svg?cmp';
import WorkspaceSettings from '@uikit/assets/images/settings.svg?cmp';
import ShareIcon from '@uikit/assets/images/share.svg?cmp';
import Sort from '@uikit/assets/images/sort.svg?cmp';
import StagesIcon from '@uikit/assets/images/stages.svg?cmp';
import StarsIcon from '@uikit/assets/images/stars.svg?cmp';
import Successful from '@uikit/assets/images/successful.svg?cmp';
import TikTokIcon from '@uikit/assets/images/tiktokIcon.svg?cmp';
import TripleDots from '@uikit/assets/images/tripleDots.svg?cmp';
import TwitchIcon from '@uikit/assets/images/twitch.svg?cmp';
import TwitterIcon from '@uikit/assets/images/twitterIcon.svg?cmp';
import UpIcon from '@uikit/assets/images/upIcon.svg?cmp';
import UserAdd from '@uikit/assets/images/userAdd.svg?cmp';
import Users from '@uikit/assets/images/users.svg?cmp';
import VerifiedBadge from '@uikit/assets/images/verifiedBadge.svg?cmp';
import VerifiedBadgeArtist from '@uikit/assets/images/verifiedBadgeArtist.svg?cmp';
import VideoIcon from '@uikit/assets/images/videoIcon.svg?cmp';
import Warning from '@uikit/assets/images/warning.svg?cmp';
import WarningWithStroke from '@uikit/assets/images/warningWithStroke.svg?cmp';
import WordIcon from '@uikit/assets/images/wordIcon.svg?cmp';
import XlsIcon from '@uikit/assets/images/xlsIcon.svg?cmp';
import YoutubeIcon from '@uikit/assets/images/youtubeIcon.svg?cmp';
import ZipIcon from '@uikit/assets/images/zipIcon.svg?cmp';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import Crown from '@assets/images/crown.svg?cmp';
import styles from './Icon.module.scss';

type TSVGIcon = FC<SVGProps<SVGSVGElement>>;

export enum ICON_TYPES {
  BACK = 'back',
  NEXT = 'next',
  MAIL = 'mail',
  EDIT = 'edit',
  INFO = 'info',
  PLUS = 'plus',
  DOWN = 'down',
  UP_ICON = 'up',
  DOWN_ICON = 'downIcon',
  SORT = 'sort',
  CLOSE = 'close',
  MINUS = 'minus',
  CHECK = 'check',
  FILTER = 'filter',
  DOWNLOAD = 'download',
  SEARCH = 'search',
  DELETE = 'delete',
  MEGAPHONE = 'megaphone',
  ACCOUNT = 'account',
  INTERCOM = 'intercom',
  SETTING = 'setting',
  CALENDAR = 'calendar',
  ARROW = 'arrow',
  ADD_CONTACT = 'addContact',
  TRIPLE_DOTS = 'tripleDots',
  DOUBLE_CHECK = 'doubleCheck',
  ADDRESS_BOOK = 'AddressBook',
  DOUBLE_ARROWS = 'doubleArrows',
  VERIFIED_BADGE = 'verifiedBadge',
  VERIFIED_BADGE_ARTIST = 'verifiedBadgeArtist',
  SUCCESSFUL = 'successful',
  WARNING = 'warning',
  WARNING_WITH_STROKE = 'warningWithStroke',
  MESSAGE = 'message',
  BAR_CHART = 'barChart',
  USERS = 'users',
  USER_ADD = 'userAdd',
  WORD_ICON = 'wordIcon',
  CLOSE_ICON = 'closeIcon',
  PDF_ICON = 'pdfIcon',
  PICTURE_ICON = 'pictureIcon',
  VIDEO_ICON = 'videoIcon',
  XLS_ICON = 'xlsIcon',
  PPT_ICON = 'pptIcon',
  ZIP_ICON = 'zipIcon',
  RAR_ICON = 'rarIcon',
  AUDIO_ICON = 'audioIcon',
  ANOTHER_ICON = 'anotherIcon',
  ATTACH = 'attach',
  ETC = 'etc',
  YOUTUBE = 'youtube',
  INSTAGRAM = 'instagram',
  TIKTOK = 'tiktok',
  TWITTER = 'twitter',
  TWITCH = 'twitch',
  FACEBOOK = 'facebook',
  SHARE = 'share',
  PERSON = 'person',
  LOCK = 'lock',
  ARROW_UP_RIGHT = 'arrowUpRight',
  CROWN = 'crown',
  EXCLAMATION = 'exclamation',
  COPY = 'copy',
  SEAT = 'seat',
  REFRESH = 'refresh',
  WORKSPACE_SETTINGS = 'workspaceSettings',
  MINIMIZE = 'minimizeIcon',
  PAYMENT = 'payment',
  CONTRACTS = 'contractsIcon',
  STAGES = 'stages',
  PERSON_WITH_HAND = 'personWithHand',
  STARS = 'stars',
  FILE = 'file',
  DOUBLE_RIGHT_ARROW = 'doubleRightArrow',
  LINK = 'link',
  MAIL_WITH_CROSS = 'mailWithCross',
}

const ICON_MAP: Record<ICON_TYPES, TSVGIcon> = {
  [ICON_TYPES.MAIL]: Mail,
  [ICON_TYPES.EDIT]: Edit,
  [ICON_TYPES.BACK]: Back,
  [ICON_TYPES.NEXT]: Next,
  [ICON_TYPES.INFO]: Info,
  [ICON_TYPES.PLUS]: Plus,
  [ICON_TYPES.DOWN]: Down,
  [ICON_TYPES.UP_ICON]: UpIcon,
  [ICON_TYPES.DOWN_ICON]: DownIcon,
  [ICON_TYPES.SORT]: Sort,
  [ICON_TYPES.CLOSE]: Close,
  [ICON_TYPES.MINUS]: Minus,
  [ICON_TYPES.CHECK]: Check,
  [ICON_TYPES.FILTER]: Filter,
  [ICON_TYPES.DOWNLOAD]: Download,
  [ICON_TYPES.SEARCH]: Search,
  [ICON_TYPES.DELETE]: Delete,
  [ICON_TYPES.MEGAPHONE]: Megaphone,
  [ICON_TYPES.ACCOUNT]: Account,
  [ICON_TYPES.INTERCOM]: Intercom,
  [ICON_TYPES.SETTING]: Setting,
  [ICON_TYPES.WORKSPACE_SETTINGS]: WorkspaceSettings,
  [ICON_TYPES.CALENDAR]: Calendar,
  [ICON_TYPES.ARROW]: Arrow,
  [ICON_TYPES.ADD_CONTACT]: AddContact,
  [ICON_TYPES.TRIPLE_DOTS]: TripleDots,
  [ICON_TYPES.DOUBLE_CHECK]: DoubleCheck,
  [ICON_TYPES.ADDRESS_BOOK]: AddressBook,
  [ICON_TYPES.DOUBLE_ARROWS]: DoubleArrows,
  [ICON_TYPES.VERIFIED_BADGE]: VerifiedBadge,
  [ICON_TYPES.VERIFIED_BADGE_ARTIST]: VerifiedBadgeArtist,
  [ICON_TYPES.SUCCESSFUL]: Successful,
  [ICON_TYPES.WARNING]: Warning,
  [ICON_TYPES.WARNING_WITH_STROKE]: WarningWithStroke,
  [ICON_TYPES.MESSAGE]: Message,
  [ICON_TYPES.BAR_CHART]: BarChart,
  [ICON_TYPES.USERS]: Users,
  [ICON_TYPES.USER_ADD]: UserAdd,
  [ICON_TYPES.WORD_ICON]: WordIcon,
  [ICON_TYPES.CLOSE_ICON]: CloseIcon,
  [ICON_TYPES.PDF_ICON]: PdfIcon,
  [ICON_TYPES.PICTURE_ICON]: PictureIcon,
  [ICON_TYPES.VIDEO_ICON]: VideoIcon,
  [ICON_TYPES.XLS_ICON]: XlsIcon,
  [ICON_TYPES.PPT_ICON]: PttIcon,
  [ICON_TYPES.ZIP_ICON]: ZipIcon,
  [ICON_TYPES.RAR_ICON]: RarIcon,
  [ICON_TYPES.AUDIO_ICON]: AudioIcon,
  [ICON_TYPES.ANOTHER_ICON]: AnotherIcon,
  [ICON_TYPES.ATTACH]: Attach,
  [ICON_TYPES.ETC]: Etc,
  [ICON_TYPES.COPY]: CopyIcon,
  [ICON_TYPES.YOUTUBE]: YoutubeIcon,
  [ICON_TYPES.INSTAGRAM]: InstagramIcon,
  [ICON_TYPES.TIKTOK]: TikTokIcon,
  [ICON_TYPES.TWITTER]: TwitterIcon,
  [ICON_TYPES.TWITCH]: TwitchIcon,
  [ICON_TYPES.FACEBOOK]: FacebookIcon,
  [ICON_TYPES.LOCK]: LockIcon,
  [ICON_TYPES.SHARE]: ShareIcon,
  [ICON_TYPES.PERSON]: PersonIcon,
  [ICON_TYPES.ARROW_UP_RIGHT]: ArrowUpRight,
  [ICON_TYPES.CROWN]: Crown,
  [ICON_TYPES.EXCLAMATION]: Exclamation,
  [ICON_TYPES.MINIMIZE]: MinimizeIcon,
  [ICON_TYPES.CONTRACTS]: ContractsIcon,
  [ICON_TYPES.STAGES]: StagesIcon,
  [ICON_TYPES.PAYMENT]: PaymentIcon,
  [ICON_TYPES.PERSON_WITH_HAND]: PersonHand,
  [ICON_TYPES.SEAT]: SeatIcon,
  [ICON_TYPES.REFRESH]: RefreshIcon,
  [ICON_TYPES.STARS]: StarsIcon,
  [ICON_TYPES.FILE]: FileIcon,
  [ICON_TYPES.DOUBLE_RIGHT_ARROW]: DoubleRightArrow,
  [ICON_TYPES.LINK]: Link,
  [ICON_TYPES.MAIL_WITH_CROSS]: MailWithCross,
};

/*
 * Return icon by type.
 */
export const getIconByType = (type: ICON_TYPES): TSVGIcon => ICON_MAP[type];

const ICON_CLASS_NAMES_BY_STYLE_MAP: Record<ICON_STYLES, string> = {
  [ICON_STYLES.PRIMARY]: styles.icon_PRIMARY,
  [ICON_STYLES.SECONDARY]: styles.icon_SECONDARY,
  [ICON_STYLES.INVERTED]: styles.icon_INVERTED,
  [ICON_STYLES.ACTIVE]: styles.icon_ACTIVE,
  [ICON_STYLES.DISABLED]: styles.icon_DISABLED,
  [ICON_STYLES.POSITIVE]: styles.icon_POSITIVE,
  [ICON_STYLES.NEGATIVE]: styles.icon_NEGATIVE,
  [ICON_STYLES.WARNING]: styles.icon_WARNING,
  [ICON_STYLES.INFORMATIVE]: styles.icon_INFORMATIVE,
};

const BACKGROUND_CLASS_NAMES_BY_STYLE_MAP: Record<ICON_STYLES, string> = {
  [ICON_STYLES.PRIMARY]: styles.icon__background_PRIMARY,
  [ICON_STYLES.SECONDARY]: styles.icon__background_SECONDARY,
  [ICON_STYLES.INVERTED]: styles.icon__background_INVERTED,
  [ICON_STYLES.ACTIVE]: styles.icon__background_ACTIVE,
  [ICON_STYLES.DISABLED]: styles.icon__background_DISABLED,
  [ICON_STYLES.POSITIVE]: styles.icon__background_POSITIVE,
  [ICON_STYLES.NEGATIVE]: styles.icon__background_NEGATIVE,
  [ICON_STYLES.WARNING]: styles.icon__background_WARNING,
  [ICON_STYLES.INFORMATIVE]: styles.icon__background_INFORMATIVE,
};

// Расширяемо!
const sizeIconToBackground: Record<number, number> = {
  16: 30,
  12: 12,
};

export const getBackgroundSize = (sizeIcon: number): number => sizeIconToBackground[sizeIcon];

export const getIconClassNameByStyle = (style: ICON_STYLES) => ICON_CLASS_NAMES_BY_STYLE_MAP[style];

export const getBackgroundClassNameByStyle = (style: ICON_STYLES) =>
  cn(styles.icon__background, BACKGROUND_CLASS_NAMES_BY_STYLE_MAP[style]);
