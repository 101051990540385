/*
 * Сервис для работы с API-запросами selection. (new)
 */
import { ExplorationBaseApiService, explorationBaseApiService } from '@api';
import { IUpdateSelectionRequestDto, SmaPlatform } from '@hypetrainCommon';
import { TSmaStatisticResponse } from '@api/campaigns';
import { TDeletePersonSmasParams } from '@api/selection/selection.api.types';
import { PROFILE_ERROR_SLUGS } from '@api/sma/sma.api.constants';
import { getSocialPlatformBySMAId } from '@utils/socialPlatform.utils';

/* eslint-disable arrow-body-style */
export class SelectionApiServiceNew {
  constructor(private readonly api: ExplorationBaseApiService) {}

  /*
   * Добавление sma в selection
   */
  public addToSelection = (smaUuids: string[] | string): Promise<IUpdateSelectionRequestDto> => {
    return this.api
      .post(
        `/${getSocialPlatformBySMAId(Array.isArray(smaUuids) ? smaUuids[0] : smaUuids)}/selection`,
        { smaUuids },
        {},
        {
          showLoader: smaUuids?.length > 1,
          errorCodesToIgnore: PROFILE_ERROR_SLUGS,
        }
      )
      .then((response) => response.data);
  };

  /*
   * Удаление sma из selection
   */
  public deleteFromSelection = ({
    platform,
    smaUuids,
  }: TDeletePersonSmasParams): Promise<string> => {
    return this.api
      .deleteMethod(
        `/${platform}/selection`,
        { data: { smaUuids: smaUuids || [] } },
        { showLoader: Number(smaUuids?.length) > 1 }
      )
      .then((response) => response.data);
  };

  /*
   * Получение selection
   */
  public getSelection = (platform: SmaPlatform): Promise<TSmaStatisticResponse> => {
    return this.api
      .get(
        `/${platform}/selection`,
        {},
        {
          showLoader: false,
        }
      )
      .then((response) => response.data.smasStatistics);
  };

  /*
   * Экспорт csv sma
   */
  public exportSmasCsv = (platform: SmaPlatform, smaUuids: string[]): Promise<string> => {
    return this.api
      .post(
        `/${platform}/export`,
        { smaUuids },
        {},
        {
          showLoader: false,
        }
      )
      .then((response) => response.data);
  };
}

export const selectionApiServiceNew = new SelectionApiServiceNew(explorationBaseApiService);
