/*
 * Стратегия регистрации пользователя
 */
export enum USER_REGISTRATION_STRATEGY {
  BY_OWN_INTENTION = 'byOwnIntention',
  BY_INVITATION = 'byInvitation',
}

/*
 * Стратегия авторизации пользователя
 */
export enum USER_AUTHENTICATION_STRATEGY {
  LOCAL = 'local',
  GOOGLE = 'google',
}

/*
 * Страница регистрации пользователя
 */
export enum AUTHENTICATION_ORIGIN_PAGE {
  REGISTRATION_PAGE = 'registrationPage',
  LOGIN_PAGE = 'loginPage',
}

/*
 * Страница регистрации пользователя
 */
export enum SEAT_ORIGIN_PAGE {
  TRIAL_START_PAGE = 'trialStartPage',
  WORKSPACE_MEMBER_LIST = 'workspaceMemberList',
  WORKSPACE_BILLING = 'workspaceBilling',
}

/*
 * Страница регистрации пользователя
 */
export enum SEAT_SUBSCRIPTION_TYPE {
  TRIAL = 'trial',
  SUBSCRIPTION = 'subscription',
}

/*
 * Пользователь создан
 */
export enum CREATED_BY {
  ADMIN = 'admin',
  MEMBER = 'member',
}

/*
 * Откуда создан пользователь
 */
export enum EXECUTION_ORIGIN {
  REGISTRATION = 'fromRegistration',
  INVITATION = 'fromInvitation',
}

/*
 * Тип регистрации пользователя
 */
export enum PERSON_REGISTRATION_TYPE {
  USER = 'user',
  REPRESENTATIVE = 'fromInvitation',
}

/*
 * Тип регистрации пользователя
 */
export enum PERSON_PARTY_TYPE {
  OWN = 'own',
  COUNTERPARTY = 'counterparty',
}

/*
 * Тип домена пользователя
 */
export enum PERSON_DOMAIN_TYPE {
  INFLUENCER_MARKETING = 'influencerMarketing',
  BRAND = 'brand',
  CREATING = 'creating',
}

/*
 * Статус пользователя
 */
export enum PERSON_STATUS {
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
