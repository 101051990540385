/*
 * Campaigns list with campaigns search input.
 */
import _debounce from 'lodash/debounce';
import _trim from 'lodash/trim';
import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICampaignListItemDto } from '@hypetrainCommon';
import { BUTTON_TYPE } from '@uikit/components/Buttons';
import { SearchInput } from '@uikit/components/Fields/Inputs';
import { NothingFound } from '@uikit/components/NothingFound';
import { CampaignsSearchList } from '../CampaignsSearchList';
import styles from './CampaignsSearch.module.scss';

type TCampaignsSearchProps = {
  campaigns: ICampaignListItemDto[]; // campaigns list
  selected?: ICampaignListItemDto; // selected campaign
  onSelect?: (campaign: ICampaignListItemDto) => void; // select campaign callback
  onChangeCampaignsList?: (campaigns: ICampaignListItemDto[]) => void; // callback on change showable campaigns list after filtering
  onCreateNewCampaign: () => void; // callback which open create campaign form
};

export const CampaignsSearch: FC<TCampaignsSearchProps> = memo(
  ({ campaigns, selected, onSelect, onChangeCampaignsList, onCreateNewCampaign }) => {
    const { t } = useTranslation();
    const [filter, setFilter] = useState('');
    const [filteredCampaigns, setFilteredCampaigns] = useState<ICampaignListItemDto[]>(
      campaigns?.length ? campaigns : []
    );

    const setFilterWithTrim = (value: string): void => {
      setFilter(_trim(value) ? value : '');
    };

    const filterCampaigns = () => {
      const normalizedFilter = filter.toLowerCase();

      const filteringResult = filter
        ? campaigns.filter((campaign) => campaign?.name?.toLowerCase()?.includes(normalizedFilter))
        : campaigns;

      onChangeCampaignsList && onChangeCampaignsList(filteringResult);
      setFilteredCampaigns(filteringResult || []);
    };

    const actionsNothingFound = [
      {
        buttonText: 'Create campaign',
        buttonType: BUTTON_TYPE.SECONDARY,
        onClick: onCreateNewCampaign,
      },
    ];

    useEffect(filterCampaigns, [campaigns]);

    useEffect(_debounce(filterCampaigns, 300), [filter]);

    return (
      <>
        <SearchInput
          value={filter}
          onChange={setFilterWithTrim}
          placeholder={t('searchCampaign')}
        />
        {filteredCampaigns.length ? (
          <CampaignsSearchList
            className={styles.campaignsSearch__list}
            campaigns={filteredCampaigns}
            selected={selected}
            onSelect={onSelect}
            onCreateNewCampaign={onCreateNewCampaign}
          />
        ) : (
          <NothingFound
            className={styles.campaignsSearch__nothingFountSearch}
            message={t('campaignsSearch.noCampaignsFound')}
            actions={actionsNothingFound}
          />
        )}
      </>
    );
  }
);
