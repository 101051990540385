/*
 * Universal icon component.
 */
import cn from 'clsx';
import { FC, MouseEvent } from 'react';
import { noop } from '@uikit/helpers/common.helpers';
import styles from './Icon.module.scss';
import { ICON_STYLES } from './icon.types';
import {
  getBackgroundClassNameByStyle,
  getBackgroundSize,
  getIconByType,
  getIconClassNameByStyle,
  ICON_TYPES,
} from './icon.utils';

export type TIconProps = {
  type: ICON_TYPES;
  size?: number; // icon width/height
  style?: ICON_STYLES;
  className?: string;
  onClick?: (e: MouseEvent<SVGSVGElement | HTMLDivElement>) => void;
  onMouseDown?: (e: MouseEvent<SVGSVGElement | HTMLDivElement>) => void;
  withBackground?: boolean; // признак необходимости отрисовки иконки на круглом фоне
};

export const Icon: FC<TIconProps> = ({
  type,
  size = 16,
  style = ICON_STYLES.PRIMARY,
  onClick,
  onMouseDown,
  withBackground = false,
  className,
}) => {
  const IconComponent = getIconByType(type);
  const classNameByStyle = getIconClassNameByStyle(style);
  const backgroundClassNameByStyle = getBackgroundClassNameByStyle(style);
  const backgroundSize = getBackgroundSize(size);
  const withCursorPointer = (onClick || onMouseDown) && style !== ICON_STYLES.DISABLED;

  if (!IconComponent) return null;

  const iconTemplate = (
    <IconComponent
      width={size}
      height={size}
      className={cn(
        classNameByStyle,
        withCursorPointer && styles.icon_pointer,
        !withBackground && className
      )}
      onClick={withBackground ? noop : onClick}
      onMouseDown={withBackground ? noop : onMouseDown}
    />
  );

  return withBackground ? (
    <div
      onClick={onClick}
      onMouseDown={onMouseDown}
      className={cn(
        backgroundClassNameByStyle,
        withCursorPointer && styles.icon_pointer,
        className
      )}
      style={{
        width: backgroundSize,
        height: backgroundSize,
      }}
    >
      {iconTemplate}
    </div>
  ) : (
    iconTemplate
  );
};
