/*
 * Компонент для отрисовки каунетра в кружке
 * */
import cn from 'clsx';
import { FC } from 'react';
import { FORMAT_NUMBER_NOTATION, FormatNumber } from '@uikit/components/Formats';
import styles from './CircleCounter.module.scss';

type TCircleCounter = {
  count: number;
  disabled?: boolean;
  className?: string;
};

export const CircleCounter: FC<TCircleCounter> = ({ count, disabled, className }) => {
  if (!count) return null;

  return (
    <div className={cn(styles.circleCounter, disabled && styles.circleCounter_disabled, className)}>
      <h4 className={styles.circleCounter__content}>
        <FormatNumber notation={FORMAT_NUMBER_NOTATION.COMPACT}>{count}</FormatNumber>
      </h4>
    </div>
  );
};
