/*
 * Роутер для страниц раздела Discovery.
 */
import { lazily } from 'react-lazily';
import { Route } from 'react-router-dom';
import { Routes as Links } from 'router/Routes';
import { PrivateRoute } from '@router/PrivateRoute';
import { SummaryGroupActions } from '@pages/Discovery/components/SummaryGroupActions';

const { Browse } = lazily(() => import('@pages/Discovery/Browse'));
const { InfluencerSearch } = lazily(() => import('@pages/Discovery/InfluencersSearch'));
const { InfluencersUpload } = lazily(() => import('@pages/Discovery/InfluencersUpload'));
const { Discovery } = lazily(() => import('@pages/Discovery'));

export const DiscoveryRouter = (): JSX.Element => (
  <Route
    element={
      <PrivateRoute errorBoundaryName="discovery">
        <Discovery />
      </PrivateRoute>
    }
  >
    <Route
      path={Links.discovery}
      element={
        <PrivateRoute errorBoundaryName="browse">
          <Browse />
          <SummaryGroupActions />
        </PrivateRoute>
      }
    />
    <Route
      path={Links.search}
      element={
        <PrivateRoute errorBoundaryName="search">
          <InfluencerSearch />
          <SummaryGroupActions />
        </PrivateRoute>
      }
    />
    <Route
      path={Links.uploadList}
      element={
        <PrivateRoute errorBoundaryName="uploadList">
          <InfluencersUpload />
        </PrivateRoute>
      }
    />
  </Route>
);
