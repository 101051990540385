// Хук для инициализации intercom
import { useStore } from 'effector-react/compat';
import { useEffect } from 'react';
import { IPersonWorkspaceResponseDto } from '@hypetrainCommon';
import { $activeWorkspaceStore } from '@models/activeWorkspace';
import { $userProfile } from '@models/profile';

type TUpdateIntercomParams = {
  verified_at?: Date;
  trial_started?: Date;
};

type TUpdateIntercom = (params: TUpdateIntercomParams) => void;

export const useInitIntercom = (): TUpdateIntercom => {
  const profile = useStore($userProfile);
  const activeWorkspace = useStore<IPersonWorkspaceResponseDto>($activeWorkspaceStore);

  const updateIntercom = (params: TUpdateIntercomParams) => {
    // @ts-ignore OK.
    window.Intercom('update', { ...params });
  };

  // Инициализируем интерком
  useEffect(() => {
    // @ts-ignore OK.
    window.Intercom(window.Intercom.booted ? 'update' : 'boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'upxj9kfr',
      custom_launcher_selector: '#intercom-messenger',
      alignment: 'left',
      hide_default_launcher: !!activeWorkspace?.workspace?.name,
      horizontal_padding: 68,
      application_type: 'ACADEMY',
      ...(profile?.email && {
        email: profile?.email,
        user_hash: profile?.user_hash,
        user_id: profile?.uuid,
      }),
      ...(activeWorkspace?.person?.id && {
        name: activeWorkspace?.person?.name,
        company: {
          company_id: activeWorkspace?.workspace?.id,
          name: activeWorkspace?.workspace?.name,
        },
      }),
    });
  }, [profile, activeWorkspace]);

  return updateIntercom;
};
