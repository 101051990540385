/*
 * Форма компонента редактирования информации о воркспейсе
 */
import { useStore } from 'effector-react/compat';
import { ChangeValue, FormApi, MutableState } from 'final-form';
import { FC, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { validateWorkspaceHandler } from '@hypetrainCommon';
import { BUTTON_TYPE, Button } from '@uikit/components/Buttons';
import { Input, InputAsyncLoader } from '@uikit/components/Fields/Inputs';
import i18n from '@services/i18n';
import { getErrorMessageSlug } from '@utils/api.utils';
import { debounceInstance } from '@utils/debounce';
import { makeValidate } from '@utils/makeValidate';
import { slugify } from '@utils/slugify';
import { transliterate } from '@utils/transliterate';
import { WORKSPACE_NAME_REGEX } from '@constants/workspace.constants';
import { checkWorkspaceHandlerFx } from '@models/workspaces/workspaces.model';
import styles from './WorkspaceInfoForm.module.scss';

const slugifySubdomain = (text: string) => slugify(transliterate(text));

export type TWorkspaceInfo = {
  workspaceName: string;
  handler: string;
};

type TWorkspaceInfoProps = {
  initValue?: TWorkspaceInfo;
  onSubmit: (params: TWorkspaceInfo) => void;
};

export const WorkspaceInfoForm: FC<TWorkspaceInfoProps> = ({ initValue, onSubmit }) => {
  const { t } = useTranslation();
  const [workspaceStatus, setWorkspaceStatus] = useState<string | null>(null);
  const isWorkspaceChecking = useStore(checkWorkspaceHandlerFx.pending);

  const onFormSubmit = (value: TWorkspaceInfo, form: FormApi): void => {
    // если поля не изменились, то форма не сабмитится и ничего не происходит
    if (form.getFieldState('handler')?.pristine && form.getFieldState('workspaceName')?.pristine) {
      return;
    }
    if (!isWorkspaceChecking) {
      onSubmit(value);
    }
  };

  const checkWorkspace = async (value: string, resolve: (value?: string) => string | void) => {
    const slugifiedSubdomain = slugifySubdomain(value);
    // если хэндлер не поменялся, то не дергаем checkWorkspace
    if (initValue?.handler === slugifiedSubdomain) {
      setWorkspaceStatus(null);
      return;
    }

    try {
      await checkWorkspaceHandlerFx(slugifiedSubdomain);
      resolve();
    } catch (error) {
      resolve(t(getErrorMessageSlug(error) || ''));
    }
  };

  const validationDebounced = debounceInstance(checkWorkspace);

  const schema: Yup.SchemaOf<TWorkspaceInfo> = Yup.object().shape({
    workspaceName: Yup.string()
      .trim()
      .matches(WORKSPACE_NAME_REGEX, i18n.t('errors.workspace.unnecessaryCharacters'))
      .required(i18n.t('errors.workspace.workspaceName')),
    handler: Yup.string()
      .validWorkspace()
      .min(2, i18n.t('errors.workspace.subdomainLimit'))
      .max(48, i18n.t('errors.workspace.subdomainLimit'))
      .matches(/^[^0-9]/, i18n.t('errors.workspace.subdomainLatin'))
      .required(i18n.t('errors.workspace.subdomainName')),
  });

  const validate = makeValidate(schema);

  const updateFormFields = (
    changeValue: ChangeValue<TWorkspaceInfo>,
    state: MutableState<TWorkspaceInfo>,
    fieldOne: string,
    fieldTwo: string
  ) => {
    const field = (state?.formState?.values[fieldOne as keyof object] as string)?.trim() || '';
    const slugifyField = slugifySubdomain(field);

    changeValue(state, fieldTwo, () => slugifyField);
    if (validateWorkspaceHandler(slugifyField).isValid) {
      return new Promise(() => {
        validationDebounced(field).then(setWorkspaceStatus);
      });
    }
  };

  const validateHandler = () => workspaceStatus;

  return (
    <>
      <h2 className={styles.workspaceInfoForm__header}>{t('workspaceInfo.title')}</h2>
      <Form
        onSubmit={onFormSubmit}
        initialValues={initValue}
        validate={validate}
        mutators={{
          setHandler: (args, state, { changeValue }) =>
            updateFormFields(
              changeValue,
              state as MutableState<TWorkspaceInfo>,
              'workspaceName',
              'handler'
            ),
          setHiddenText: (args, state, { changeValue }) =>
            updateFormFields(
              changeValue,
              state as MutableState<TWorkspaceInfo>,
              'handler',
              'handler'
            ),
        }}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <Field name="workspaceName">
              {({ meta, input }) => (
                <Input
                  input={input}
                  label={t('workspaceName')}
                  descriptionText={t('workspaceInfo.subdomainDescription')}
                  error={meta?.submitFailed && meta?.error}
                  onKeyUp={form.mutators.setHandler}
                />
              )}
            </Field>
            <div className={styles.workspaceInfoForm__inputContainer}>
              <Field
                name="handler"
                validate={validateHandler}
              >
                {({ meta, input }) => (
                  <InputAsyncLoader isPending={isWorkspaceChecking}>
                    <Input
                      input={input}
                      label={t('subdomainName')}
                      onKeyUp={form.mutators.setHiddenText}
                      error={(workspaceStatus || meta?.submitFailed) && meta?.error}
                    />
                  </InputAsyncLoader>
                )}
              </Field>
              <div className={styles.workspaceInfoForm__siteText}>{values.handler}</div>
            </div>
            <Button buttonType={BUTTON_TYPE.COMMIT}>{t('workspaceInfo.saveButton')}</Button>
          </form>
        )}
      />
    </>
  );
};
