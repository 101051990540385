import { SmaPlatform } from '@hypetrainCommon';

// Список имен всех соцсетей
export const SOCIAL_NETWORK_NAMES_MAP: Record<SmaPlatform, string> = {
  [SmaPlatform.Youtube]: 'YouTube',
  [SmaPlatform.Instagram]: 'Instagram',
  [SmaPlatform.Tiktok]: 'Tiktok',
  [SmaPlatform.Twitch]: 'Twitch',
  [SmaPlatform.Facebook]: 'Facebook',
  [SmaPlatform.Twitter]: 'Twitter',
};

// опции для селектов с соц. сетями
export const SOCIAL_NETWORK_OPTIONS = [
  { label: SOCIAL_NETWORK_NAMES_MAP[SmaPlatform.Youtube], value: SmaPlatform.Youtube },
  { label: SOCIAL_NETWORK_NAMES_MAP[SmaPlatform.Instagram], value: SmaPlatform.Instagram },
  { label: SOCIAL_NETWORK_NAMES_MAP[SmaPlatform.Twitter], value: SmaPlatform.Twitter },
  { label: SOCIAL_NETWORK_NAMES_MAP[SmaPlatform.Twitch], value: SmaPlatform.Twitch },
  { label: SOCIAL_NETWORK_NAMES_MAP[SmaPlatform.Facebook], value: SmaPlatform.Facebook },
  { label: SOCIAL_NETWORK_NAMES_MAP[SmaPlatform.Tiktok], value: SmaPlatform.Tiktok },
];
