/*
 * Filters configuration for browse page (TikTok).
 */
import {
  Age,
  DayNumber,
  Gender,
  InfluencerGender,
  InstagramFilterName,
  SmaPlatform,
  TiktokAudienceAgesFilter,
  TiktokAudienceGendersFilter,
  TiktokEngagementRateFilter,
  TiktokFilterName,
  TiktokGenderFilter,
  TiktokHasContactFilter,
  TiktokReachFilter,
} from '@hypetrainCommon';
import { FILTER_TYPES } from '@uikit/components/Filter';
import { TCheckboxFilterConfig } from '@uikit/components/Filter/components/CheckboxFilter';
import { TInputFilterConfig } from '@uikit/components/Filter/components/InputFilter';
import { TRadioGroupFilterConfig } from '@uikit/components/Filter/components/RadioGroupFilter';
import {
  RANGE_TYPES,
  TRangeConfig,
} from '@uikit/components/Filter/components/RangeFilter/RangeFilter.types';
import {
  DEFAULT_RANGE_SELECT_FROM_OPTIONS,
  DEFAULT_RANGE_SELECT_TO_OPTIONS,
} from '@uikit/components/Filter/components/RangeFilter/components/RangeSelect/rangeSelect.constants';
import { TSelectFilterConfig } from '@uikit/components/Filter/components/SelectFilter';
import { TAvailableFilter } from '@uikit/components/Filters';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { stringToBoolean } from '@uikit/components/Filters/filters.utils';
import { TSelectOptions } from '@uikit/components/SelectField/SelectField.types';
import i18n from '@services/i18n';
import { isoCountries } from '@constants/countries';
import { SOCIAL_NETWORK_OPTIONS } from '@constants/socialPlatform';
import { getLanguagesFx } from '@models/languages';

const countriesOptions: TSelectOptions = Object.entries(isoCountries).map(
  ([countryCode, countryName]) => ({ value: countryCode, label: countryName })
);

const influencerLocationFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: TiktokFilterName.Country,
  filterType: FILTER_TYPES.SELECT,
  label: i18n.t('filters.tikTok.influencerLocation'),
  popupTitle: i18n.t('filters.tikTok.locationLabel'),
  description: i18n.t('filters.tikTok.influencerLocationDescription'),
  isVisible: true,
  testId: 'TT_COUNTRY_FILTER',
  filterProps: {
    isMulti: true,
    placeholder: i18n.t('filters.tikTok.locationPlaceholder'),
    options: countriesOptions,
  },
};

const influencerLanguageFilterConfig: TAvailableFilter = {
  id: TiktokFilterName.Language,
  filterType: FILTER_TYPES.ASYNC_SELECT,
  label: i18n.t('filters.tikTok.influencerLanguage'),
  popupTitle: i18n.t('filters.tikTok.influencerLanguageLabel'),
  isVisible: true,
  testId: 'TT_LANGUAGE_FILTER',
  filterProps: {
    isMulti: false,
    placeholder: i18n.t('filters.tikTok.influencerLanguagePlaceholder'),
    loadOptions: async () => {
      const responseData = await getLanguagesFx();
      return responseData?.map(({ name, code }) => ({
        value: code,
        label: name,
      }));
    },
    platform: SmaPlatform.Tiktok,
  },
};

const followersFilterConfig: TAvailableFilter<TRangeConfig> = {
  id: TiktokFilterName.FollowersCount,
  filterType: FILTER_TYPES.RANGE,
  label: i18n.t('filters.tikTok.followers'),
  isVisible: true,
  testId: 'TT_FOLLOWERS_COUNT_FILTER',
  filterProps: {
    type: RANGE_TYPES.SELECT,
    fromOptions: DEFAULT_RANGE_SELECT_FROM_OPTIONS,
    toOptions: DEFAULT_RANGE_SELECT_TO_OPTIONS,
    from: {
      placeholder: '1k',
    },
    to: {
      placeholder: '>1m',
    },
  },
};

const viewsFilterConfig: TAvailableFilter<TRangeConfig> = {
  id: TiktokFilterName.Reach,
  filterType: FILTER_TYPES.RANGE,
  label: i18n.t('filters.tikTok.views'),
  description: i18n.t('filters.tikTok.viewsDescription'),
  isVisible: true,
  testId: 'TT_VIEWS_COUNT_FILTER',
  filterProps: {
    type: RANGE_TYPES.SELECT,
    fromOptions: DEFAULT_RANGE_SELECT_FROM_OPTIONS,
    toOptions: DEFAULT_RANGE_SELECT_TO_OPTIONS,
    from: {
      placeholder: '1k',
    },
    to: {
      placeholder: '>1m',
    },
  },
  formatValueToDTO: (filter: TFilter): TiktokReachFilter => ({
    name: TiktokFilterName.Reach,
    min: filter.min,
    max: filter.max,
    value: DayNumber.Thirtieth,
  }),
};

const engagementRateFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: TiktokFilterName.EngagementRate,
  filterType: FILTER_TYPES.SELECT,
  label: i18n.t('filters.tikTok.engagementRate'),
  popupTitle: i18n.t('filters.tikTok.engagementRateLabel'),
  description: i18n.t('filters.tikTok.engagementRateDescription'),
  isVisible: true,
  testId: 'TT_ER_FILTER',
  filterProps: {
    placeholder: i18n.t('filters.tikTok.engagementRatePlaceholder'),
    isMulti: false,
    options: Array.from({ length: 20 }, (_, i) => ({
      label: `>${i + 1}%`,
      value: `${(i + 1) / 100}`,
    })),
  },
  formatValueToDTO: (filter: TFilter): TiktokEngagementRateFilter => ({
    name: TiktokFilterName.EngagementRate,
    min: Number(filter?.values?.[0]),
    value: DayNumber.Thirtieth,
  }),
  formatValueFromDTO: (filter: TFilter): TFilter => ({
    name: filter.name,
    values: [filter.min?.toString() || ''],
  }),
};

const haveContactFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: TiktokFilterName.HasContact,
  filterType: FILTER_TYPES.RADIO,
  label: i18n.t('filters.tikTok.hasEmail'),
  isVisible: true,
  testId: 'TT_HAS_CONTACT_FILTER',
  filterProps: {
    defaultValue: null,
    options: [
      {
        label: i18n.t('filters.tikTok.withEmail'),
        value: 'true',
      },
      {
        label: i18n.t('filters.tikTok.withoutEmail'),
        value: 'false',
      },
    ],
  },
  formatValueToDTO: (filter: TFilter): TiktokHasContactFilter => ({
    name: TiktokFilterName.HasContact,
    value: stringToBoolean(filter.values?.[0]),
  }),
};

const haveSocialLinksFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: InstagramFilterName.HasSocialLinks,
  filterType: FILTER_TYPES.SELECT,
  label: i18n.t('filters.tikTok.haveSocialLinks'),
  isVisible: true,
  testId: 'TT_HAS_SOCIAL_LINKS_FILTER',
  popupTitle: i18n.t('filters.tikTok.haveSocialLinksTitle'),
  description: i18n.t('filters.tikTok.socialLinksDescription'),
  filterProps: {
    isMulti: true,
    placeholder: i18n.t('filters.tikTok.platformsPlaceholder'),
    options: SOCIAL_NETWORK_OPTIONS.filter((option) => option.value !== SmaPlatform.Tiktok),
  },
};

const audienceLocationFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: TiktokFilterName.AudienceCountries,
  filterType: FILTER_TYPES.SELECT,
  label: i18n.t('filters.tikTok.audienceLocations'),
  popupTitle: i18n.t('filters.tikTok.locationLabel'),
  infoTooltip: i18n.t('filters.tikTok.audienceLocationTooltipText'),
  isVisible: true,
  testId: 'TT_AUDIENCE_COUNTRIES_FILTER',
  filterProps: {
    isMulti: true,
    maxValues: 3,
    placeholder: i18n.t('filters.tikTok.locationPlaceholder'),
    options: countriesOptions,
    hasPercentage: true,
    percentageFirstLabel: i18n.t('countriesFirstLabel'),
    percentageLastLabel: i18n.t('instagramCountriesSecondLabel'),
  },
  filterInitialValue: {
    name: TiktokFilterName.AudienceCountries,
    min: 0.2,
    max: 1,
  },
  filterValue: {
    name: TiktokFilterName.AudienceCountries,
    min: 0.2,
    max: 1,
  },
};

const influencerGendersFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: TiktokFilterName.Gender,
  filterType: FILTER_TYPES.RADIO,
  label: i18n.t('filters.tikTok.influencerGenders'),
  popupTitle: i18n.t('filters.tikTok.genderRadioLabel'),
  isVisible: true,
  testId: 'TT_GENDER_FILTER',
  filterProps: {
    defaultValue: null,
    options: [
      {
        label: i18n.t('filters.tikTok.any'),
        value: null,
      },
      {
        label: i18n.t('filters.tikTok.male'),
        value: InfluencerGender.Male,
      },
      {
        label: i18n.t('filters.tikTok.female'),
        value: InfluencerGender.Female,
      },
      {
        label: i18n.t('filters.tikTok.maleOrFemale'),
        value: InfluencerGender.Known,
        tooltip: i18n.t('filters.tikTok.maleOrFemaleTooltip'),
      },
      {
        label: i18n.t('filters.tikTok.neutral'),
        value: InfluencerGender.Unknown,
        tooltip: i18n.t('filters.tikTok.neutralTooltip'),
      },
    ],
  },
  formatValueToDTO: (filter: TFilter): TiktokGenderFilter => ({
    name: TiktokFilterName.Gender,
    value: filter.values?.[0] as InfluencerGender,
  }),
};

const audienceGendersFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: TiktokFilterName.AudienceGenders,
  filterType: FILTER_TYPES.RADIO,
  label: i18n.t('filters.tikTok.audienceGenders'),
  popupTitle: i18n.t('filters.tikTok.genderRadioLabel'),
  isVisible: true,
  testId: 'TT_AUDIENCE_GENDER_FILTER',
  filterProps: {
    hasPercentage: true,
    defaultValue: null,
    options: [
      {
        label: i18n.t('filters.tikTok.any'),
        value: null,
      },
      {
        label: i18n.t('filters.tikTok.male'),
        value: Gender.Male,
      },
      {
        label: i18n.t('filters.tikTok.female'),
        value: Gender.Female,
      },
    ],
  },
  filterInitialValue: {
    name: TiktokFilterName.AudienceGenders,
    min: 0.6,
    max: 1,
  },
  filterValue: {
    name: TiktokFilterName.AudienceGenders,
    min: 0.6,
    max: 1,
  },
  formatValueToDTO: (filter: TFilter): TiktokAudienceGendersFilter => ({
    name: TiktokFilterName.AudienceGenders,
    min: filter.min,
    max: filter.max,
    value: filter.values?.[0] as Gender,
  }),
};

const audienceAgesFilterConfig: TAvailableFilter<TCheckboxFilterConfig> = {
  id: TiktokFilterName.AudienceAges,
  filterType: FILTER_TYPES.CHECKBOX_GROUP,
  label: i18n.t('filters.tikTok.audienceAges'),
  popupTitle: i18n.t('filters.tikTok.audienceAges'),
  isVisible: true,
  testId: 'TT_AUDIENCE_AGES_FILTER',
  filterProps: {
    hasPercentage: true,
    isAge: true,
    options: {
      '13–17': false,
      '18–24': false,
      '25–34': false,
      '35–44': false,
      '45–64': false,
      '65+': false,
    },
  },
  filterInitialValue: {
    name: TiktokFilterName.AudienceAges,
    min: 0.2,
    max: 1,
  },
  filterValue: {
    name: TiktokFilterName.AudienceAges,
    min: 0.2,
    max: 1,
  },
  formatValueToDTO: (filter: TFilter): TiktokAudienceAgesFilter => ({
    name: TiktokFilterName.AudienceAges,
    min: filter.min,
    max: filter.max,
    value: filter.values?.[0] === '65-65+' ? Age.From65To65Plus : (filter.values?.[0] as Age),
  }),
};

const keywordsFilterConfig: TAvailableFilter<TInputFilterConfig> = {
  id: TiktokFilterName.Keywords,
  filterType: FILTER_TYPES.INPUT,
  label: i18n.t('filters.tikTok.keywords'),
  popupTitle: i18n.t('filters.tikTok.keywordsLabel'),
  description: i18n.t('filters.tikTok.keywordsDescription'),
  isVisible: true,
  testId: 'TT_KEYWORDS_FILTER',
  filterProps: {
    placeholder: i18n.t('filters.tikTok.keywordsPlaceholder'),
  },
};

// TODO пока что не отображаем фильтр по mentions тк в iqdata он криво работает
// const mentionsFilterConfig: TAvailableFilter<TKeywordFilterConfig> = {
//   id: TiktokFilterName.Mentions,
//   filterType: FILTER_TYPES.KEYWORDS,
//   label: i18n.t('filters.tikTok.mentions'),
//   popupTitle: i18n.t('filters.tikTok.mentionsLabel'),
//   description: i18n.t('filters.tikTok.mentionsDescription'),
//   isVisible: true,
//   testId: 'TT_MENTIONS_FILTER',
//   filterProps: {
//     placeholder: i18n.t('filters.tikTok.mentionsPlaceholder'),
//   },
// };

export const BROWSE_PAGE_AVAILABLE_FILTERS_CONFIG_TIKTOK: TAvailableFilter[] = [
  followersFilterConfig,
  engagementRateFilterConfig,
  viewsFilterConfig,
  influencerLocationFilterConfig,
  influencerGendersFilterConfig,
  influencerLanguageFilterConfig,
  haveContactFilterConfig,
  haveSocialLinksFilterConfig,
  audienceLocationFilterConfig,
  audienceAgesFilterConfig,
  audienceGendersFilterConfig,
  keywordsFilterConfig,
  // mentionsFilterConfig,
];
