// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reviewPaymentsInProgressStep__bzcR2{display:flex;flex-direction:column;align-items:center;justify-content:center;height:100%}.reviewPaymentsInProgressStep__loader__N9gDw{width:220px}.reviewPaymentsInProgressStep__description__REg5I{margin-top:var(--hSpacing60);white-space:break-spaces;text-align:center}", "",{"version":3,"sources":["webpack://./pages/PaymentsPage/components/ReviewPaymentsModal/components/ReviewPaymentsInProgressStep/ReviewPaymentsInProgressStep.module.scss"],"names":[],"mappings":"AAEA,qCACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CAEA,6CACE,WAAA,CAGF,kDACE,4BAAA,CACA,wBAAA,CACA,iBAAA","sourcesContent":["@import '../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.reviewPaymentsInProgressStep {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n\n  &__loader {\n    width: 220px;\n  }\n\n  &__description {\n    margin-top: var(--hSpacing60);\n    white-space: break-spaces;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reviewPaymentsInProgressStep": "reviewPaymentsInProgressStep__bzcR2",
	"reviewPaymentsInProgressStep__loader": "reviewPaymentsInProgressStep__loader__N9gDw",
	"reviewPaymentsInProgressStep__description": "reviewPaymentsInProgressStep__description__REg5I"
};
export default ___CSS_LOADER_EXPORT___;
