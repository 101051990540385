/*
 * Глобальный сервис системных уведомлений (выводятся сверху на весь экран).
 */
import { SYSTEM_NOTIFICATION_TYPES } from './systemNotification.types';
import { SystemNotificationsStore, systemNotificationsStore } from './systemNotifications.store';
import { TSystemNotificationsConfig } from './systemNotifications.types';

export class SystemNotificationsService {
  constructor(private readonly store: SystemNotificationsStore) {}

  /*
   * Отобразить warning-уведомление.
   */
  public warning(config: TSystemNotificationsConfig): void {
    this.push({
      type: SYSTEM_NOTIFICATION_TYPES.WARNING,
      ...config,
    });
  }

  /*
   * Отобразить error-уведомление.
   */
  public error(config: TSystemNotificationsConfig): void {
    this.push({
      type: SYSTEM_NOTIFICATION_TYPES.ERROR,
      ...config,
    });
  }

  /*
   * Скрывает уведомление.
   */
  public hide(id: string): void {
    this.store.delete(id);
  }

  /*
   * Скрывает все уведомления.
   */
  public hideAll(): void {
    this.store.clear();
  }

  /*
   * Добавить уведомление.
   */
  private push(config: TSystemNotificationsConfig): void {
    this.hideAll(); // На данный момент одновременно может отображаться только одно уведомление - при отображении нового удаляем другое (если есть).
    this.store.add(config);
  }
}

export const systemNotificationsService = new SystemNotificationsService(systemNotificationsStore);
