/*
 * Sorts configuration for browse page.
 */
import { InstagramSmaStatisticsSortName } from '@hypetrainCommon';
import { TAvailableSort } from '@uikit/components/Sorts/sorts.types';
import i18n from '@services/i18n';

export const BROWSE_PAGE_AVAILABLE_SORTS_CONFIG_INSTAGRAM: TAvailableSort[] = [
  {
    id: InstagramSmaStatisticsSortName.FollowersCount,
    label: i18n.t('sorts.instagram.followers'),
    selected: true,
    testId: 'IG_FOLLOWERS_COUNT_SORT',
  },
  {
    id: InstagramSmaStatisticsSortName.EngagementRate,
    label: i18n.t('sorts.instagram.engagementRate'),
    selected: true,
    extra: { day: '30' },
    testId: 'IG_ER_SORT',
  },
];
