/*
 * Компонент глобального лоадера (на весь экран).
 */
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Loader, loaderStore } from '@uikit/components/Loader';
import { Portal } from '@uikit/components/Portal';
import styles from './GlobalLoader.module.scss';

export const GlobalLoader: FC = observer(() => {
  const { loadersCount } = loaderStore;

  if (loadersCount <= 0) return null;

  // Отображаем в портале в том числе для того, что бы корректно работал useClickAway (диалоги не закрывались при клике по лоадеру)
  return (
    <Portal>
      <div className={styles.globalLoader}>
        <Loader />
      </div>
    </Portal>
  );
});
