// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectFieldLoadingIndicator__YU8uD{position:absolute;width:37px;height:37px;right:28px}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/SelectField/components/SelectFieldLoadingIndicator/SelectFieldLoadingIndicator.module.scss"],"names":[],"mappings":"AAEA,oCACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.selectFieldLoadingIndicator {\n  position: absolute;\n  width: 37px;\n  height: 37px;\n  right: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectFieldLoadingIndicator": "selectFieldLoadingIndicator__YU8uD"
};
export default ___CSS_LOADER_EXPORT___;
