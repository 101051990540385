/*
 * TikTok таблица в Campaign
 * */
import { ITiktokSmaStatisticsSimpleInfoDto, SmaPlatform, TiktokFilterName } from '@hypetrainCommon';
import { TFilterConfig } from '@uikit/components/Filter';
import { isFilterHasValue } from '@uikit/components/Filters/filters.utils';
import {
  FORMAT_NUMBER_NOTATION,
  FORMAT_NUMBER_STYLES,
  FormatNumber,
} from '@uikit/components/Formats';
import { CELL_POSITION, DefaultCell } from '@uikit/components/Table/components/columns/DefaultCell';
import { TableHeaderCell } from '@uikit/components/Table/components/columns/TableHeaderCell';
import { TColumn } from '@uikit/components/Table/table.types';
import { CellWithTooltip } from 'hypetrain-ui/src/components/Table/components/columns/CellWithTooltip';
import i18n from '@services/i18n';
import { ICampaignDealListItemCustom } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.types';
import { getIsContact } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.utils';
import styles from '@pagesCampaigns/CampaignDetailsPage/components/DataAnalytics/DataAnalytics.module.scss';
import { SelectCampaignAllCheckbox } from '@pagesCampaigns/CampaignDetailsPage/components/SelectCampaignAllCheckbox';
import { SelectCampaignCheckbox } from '@pagesCampaigns/CampaignDetailsPage/components/SelectCampaignCheckbox';
import { ChannelInfoColumn } from '@components/CommonColumnsTable/ChannelInfoColumn';
import { TChannelInfoColumnData } from '@components/CommonColumnsTable/ChannelInfoColumn/ChannelInfoColumn.component';
import {
  getAgeColumn,
  getAudienceLocationColumn,
  getGenderColumn,
  targetAudienceShareColumn,
} from '@components/TableCells';

export const getCampaignTikTokColumns = (
  filters: TFilterConfig[]
): TColumn<ICampaignDealListItemCustom>[] => {
  const filtering = filters.filter(isFilterHasValue);

  return [
    {
      width: 40,
      canResize: false,
      Header: <SelectCampaignAllCheckbox />,
      accessor: 'selection',
      Cell: ({ row }) => (
        <>
          <SelectCampaignCheckbox
            campaignId={row?.original?.id}
            className={styles.tableCheckbox}
          />
          <div className={styles.numbering}>{Number(row.id) + 1}</div>
        </>
      ),
    },
    {
      width: 420,
      Header: (
        <DefaultCell>
          <TableHeaderCell text={i18n.t('smaTable.tikTok.info')} />
        </DefaultCell>
      ),
      accessor: 'channelInfo',
      Cell: ({ row }) => (
        <ChannelInfoColumn
          className={styles.channelInfoCampaign}
          info={row?.original?.smaStatistics?.sma || ({} as TChannelInfoColumnData)}
          highlightedString={row?.original?.highlightedString}
          dealId={row?.original?.id}
          isContact={getIsContact(row?.original)}
        />
      ),
    },
    {
      width: 50,
      Header: (
        <DefaultCell position={CELL_POSITION.CENTER}>
          <TableHeaderCell text={i18n.t('smaTable.tikTok.followers')} />
        </DefaultCell>
      ),
      accessor: 'subscribers',
      Cell: ({ row }) => (
        <DefaultCell
          isEmpty={
            !(row?.original?.smaStatistics as ITiktokSmaStatisticsSimpleInfoDto)?.metrics
              ?.followersCount
          }
          position={CELL_POSITION.CENTER}
        >
          <FormatNumber notation={FORMAT_NUMBER_NOTATION.COMPACT}>
            {
              (row?.original?.smaStatistics as ITiktokSmaStatisticsSimpleInfoDto)?.metrics
                ?.followersCount
            }
          </FormatNumber>
        </DefaultCell>
      ),
    },
    {
      width: 50,
      Header: (
        <DefaultCell position={CELL_POSITION.CENTER}>
          <CellWithTooltip textTooltip={i18n.t('smaTable.tikTok.views30ToolTip')}>
            <TableHeaderCell text={i18n.t('smaTable.tikTok.views')} />
          </CellWithTooltip>
        </DefaultCell>
      ),
      accessor: 'views',
      Cell: ({ row }) => (
        <DefaultCell
          isEmpty={
            !(row?.original?.smaStatistics as ITiktokSmaStatisticsSimpleInfoDto)?.postMetrics?.r
          }
          position={CELL_POSITION.CENTER}
        >
          <FormatNumber notation={FORMAT_NUMBER_NOTATION.COMPACT}>
            {(row?.original?.smaStatistics as ITiktokSmaStatisticsSimpleInfoDto)?.postMetrics?.r}
          </FormatNumber>
        </DefaultCell>
      ),
    },
    {
      width: 50,
      Header: (
        <DefaultCell position={CELL_POSITION.CENTER}>
          <CellWithTooltip textTooltip={i18n.t('smaTable.tikTok.er30ToolTip')}>
            <TableHeaderCell text={i18n.t('smaTable.tikTok.er30')} />
          </CellWithTooltip>
        </DefaultCell>
      ),
      accessor: 'engagementRate',
      Cell: ({ row }) => (
        <DefaultCell
          isEmpty={
            !(row?.original?.smaStatistics as ITiktokSmaStatisticsSimpleInfoDto)?.postMetrics?.er
          }
          position={CELL_POSITION.CENTER}
        >
          <FormatNumber
            notation={FORMAT_NUMBER_NOTATION.STANDARD}
            numberStyles={FORMAT_NUMBER_STYLES.PERCENT}
          >
            {(row?.original?.smaStatistics as ITiktokSmaStatisticsSimpleInfoDto)?.postMetrics?.er}
          </FormatNumber>
        </DefaultCell>
      ),
    },
    // @ts-ignore OK, линтер не понимает .flat
    ...filtering
      .map((currentFilter, index) => {
        const isLastColumn = index === filtering.length - 1;
        if (currentFilter.id === TiktokFilterName.AudienceCountries) {
          return [
            ...getAudienceLocationColumn(
              currentFilter.filterValue.values || [],
              SmaPlatform.Tiktok
            ),
            isLastColumn && targetAudienceShareColumn,
          ];
        }
        if (currentFilter.id === TiktokFilterName.AudienceGenders) {
          return [
            getGenderColumn(currentFilter, SmaPlatform.Tiktok),
            isLastColumn && targetAudienceShareColumn,
          ];
        }
        if (currentFilter.id === TiktokFilterName.AudienceAges) {
          return [
            getAgeColumn(currentFilter, SmaPlatform.Tiktok),
            isLastColumn && targetAudienceShareColumn,
          ];
        }
        return null;
      })
      .flat(100) // максимальная глубина на данный момент 2.
      .filter((el) => el),
  ];
};
