/*
 * Компонент кнопки очистки в селекте
 */
import { FC, MouseEvent } from 'react';
import { ClearIndicatorProps } from 'react-select/dist/declarations/src/components/indicators';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';

export const SelectClearIndicator: FC = ({ children, ...props }: ClearIndicatorProps) => {
  const onMouseDown = (event: MouseEvent) => {
    event.stopPropagation();
    props.clearValue();
  };
  return (
    <div>
      <Icon
        onMouseDown={onMouseDown}
        type={ICON_TYPES.CLOSE_ICON}
        style={ICON_STYLES.SECONDARY}
        size={16}
      />
    </div>
  );
};
