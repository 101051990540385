/*
 * Компонент выводит содержимое сообщения в таблицу communication
 * */
import { useStore } from 'effector-react/compat';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IGetUserMessagesResponseDto, MessageStatus } from '@hypetrainCommon';
import { AttachFile } from '@uikit/components/Attach/AttachFile/AttachFile.component';
import { TAttachedFile } from '@uikit/components/Attach/AttachFile/attachFile.types';
import { AttachManyFilesOneIcon } from '@uikit/components/Attach/AttachManyFilesOneIcon';
import { $activeWorkspaceStore } from '@models/activeWorkspace';
import { ResendMessage } from '@pagesCampaigns/CampaignDealPage/components/DealPageCommunication/components/ChatMessage/components/ResendMessage';
import {
  deleteMessageFx,
  resendMessageFx,
} from '@pagesCampaigns/CampaignDealPage/components/DealPageCommunication/dealPageCommunication.model';
import { CAMPAIGN_DETAILS_TABS } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.types';
import { EmptyTextCommunication } from '@pagesCampaigns/CampaignDetailsPage/components/Communication/components/EmptyText';
import { RecipientsList } from '@components/RecipientsList';
import styles from './LastMessageContent.module.scss';

type TLastMessageProps = {
  message: IGetUserMessagesResponseDto;
  attachFiles?: TAttachedFile[];
};

export const LastMessageContent: FC<TLastMessageProps> = ({ message, attachFiles }) => {
  const { t } = useTranslation();
  const activeWorkspace = useStore($activeWorkspaceStore);
  const failedSends = message?.recipients?.filter(({ status }) => status === MessageStatus.error);

  const onResendMessage = () => {
    resendMessageFx({
      chatId: message?.chatId,
      messageId: message?.id,
      invokedBy: CAMPAIGN_DETAILS_TABS.COMMUNICATION,
    });
  };

  const onDelete = () => {
    deleteMessageFx({
      chatId: message?.chatId,
      messageId: message?.id,
      invokedBy: CAMPAIGN_DETAILS_TABS.COMMUNICATION,
    });
  };

  if (!message?.data) {
    return <EmptyTextCommunication />;
  }

  return (
    <div className={styles.lastMessageContent}>
      <div className={styles.lastMessageContent__textContainer}>
        <div
          className={styles.lastMessageContent__text}
          dangerouslySetInnerHTML={{ __html: message?.data }} // eslint-disable-line
        />
        <div className={styles.lastMessageContent__systemText}>
          <ResendMessage
            message={message}
            onResend={onResendMessage}
            onDelete={onDelete}
          />
        </div>
      </div>
      {!failedSends?.length && (
        <div className={styles.lastMessageContent__info}>
          {!!attachFiles?.length &&
            (attachFiles?.length > 1 ? (
              <AttachManyFilesOneIcon
                text={`${t('communication.lastMessage.attachedFiles')} ${attachFiles.length}`}
              />
            ) : (
              <AttachFile
                attachedFile={attachFiles[0]}
                isShowInfo={false}
                isShowDownload={false}
              />
            ))}
          {message?.sender?.id === activeWorkspace?.person?.id && (
            <RecipientsList message={message} />
          )}
        </div>
      )}
    </div>
  );
};
