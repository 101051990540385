/*
 * Утилиты для работы с датами и временем.
 */
import dayjs, { OpUnitType, QUnitType } from 'dayjs';

/*
 * Возвращает разницу между двумя датами в юнитах (минутсы/часы/дни и тд).
 * Первая дата (beforeDate) должна быть раньше второй (targetDate), иначе будет отрицательная разница.
 * Если вторая дата (targetDate) не передана - использует текущее время.
 */
export function getDateUnitBefore(
  beforeDate: string,
  unit: QUnitType | OpUnitType,
  targetDate?: string
): number {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const beforeDateObj = dayjs(beforeDate).tz(timeZone);
  const targetDateObj = targetDate ? dayjs(targetDate).tz(timeZone) : dayjs();

  return beforeDateObj.diff(targetDateObj, unit);
}

/*
 * Возвращает разницу между двумя датами в юнитах (минутсы/часы/дни и тд).
 * Первая дата (afterDate) должна быть позже второй (targetDate), иначе будет отрицательная разница.
 * Если вторая дата (afterDate) не передана - использует текущее время.
 */
export function getDateUnitAfter(
  afterDate: string,
  unit: QUnitType | OpUnitType,
  targetDate?: string
): number {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const afterDateObj = dayjs(afterDate).tz(timeZone);
  const targetDateObj = targetDate ? dayjs(targetDate).tz(timeZone) : dayjs();

  return targetDateObj.diff(afterDateObj, unit);
}
