export enum FORMAT_NUMBER_NOTATION {
  /* 1000 - 1k */
  COMPACT = 'compact',
  /* 1000 - 1,000 */
  STANDARD = 'standard',
}

export enum FORMAT_NUMBER_STYLES {
  PERCENT = 'percent',
  DECIMAL = 'decimal',
  CURRENCY = 'currency',
}
