/*
 * Важность (серьёзность/приоритет) логирубщего сообщения.
 */
export enum LOG_SEVERITY {
  EXPECTED_API_ERROR = 30,
  UNEXPECTED_API_ERROR = 100,
  JS_ERROR = 100,
  CRITICAL_SUBSCRIPTION_ERRORS = 100,
}

/*
 * Топик к которому принадлежит ошибка.
 */
export enum LOG_TOPIC {
  JS_ERROR = 'JS_ERROR',
  EXPECTED_API_ERROR = 'EXPECTED_API_ERROR',
  UNEXPECTED_API_ERROR = 'UNEXPECTED_API_ERROR',
}
