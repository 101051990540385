/*
 *   Компонент для отрисовки модальных окон
 */
import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { BUTTON_TYPE } from '@uikit/components/Buttons';
import { ErrorBoundary } from '@uikit/components/ErrorBoundary';
import { useModal } from '@uikit/components/Modal/hooks/useModal.hook';
import { ModalWindow } from '@uikit/components/ModalWindow';
import styles from './Modal.module.scss';
import { TModalAPI } from './modal.types';

export type TModalButton = {
  text: string;
  type?: BUTTON_TYPE;
  handler: () => void;
};

type TModalProps = {
  withClose?: boolean;
  withBlur?: boolean;
  width?: string;
  height?: string;
  onClose?: (modalAPI: TModalAPI) => void;
  className?: string;
  classNameModalWindow?: string;
};

export const Modal: FC<PropsWithChildren<TModalProps>> = ({
  children,
  withClose = true,
  width = '540px',
  height,
  onClose,
  className,
  classNameModalWindow,
  withBlur,
}) => {
  const modalAPI = useModal();

  return (
    <ModalWindow
      onClose={() => (onClose ? onClose(modalAPI) : modalAPI.reject())}
      withClose={withClose}
      withBlur={withBlur}
      className={classNameModalWindow}
    >
      <ErrorBoundary layerName="modalAbstract">
        <div
          style={{ width, height }}
          className={cn(styles.modal, className)}
        >
          {children}
        </div>
      </ErrorBoundary>
    </ModalWindow>
  );
};
