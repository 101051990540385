/*
 * Компонент модального окна профиля.
 */
import cn from 'clsx';
import { FC, PropsWithChildren, Ref, useCallback, useEffect } from 'react';
import { ErrorBoundary } from '@uikit/components/ErrorBoundary';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { Portal } from '@uikit/components/Portal';
import { CLOSE_PORTAL_NODE_ID } from '@uikit/constants/common.constants';
import { useClickAway } from '@uikit/hooks/useClickAway.hook';
import { useServices } from '@hooks/useServices.hook';
import { resetProfileLinkedList, setProfileIsLookalike } from '@components/Profile/profile.model';
import styles from './ProfileModalWindow.module.scss';

export const PROFILE_MODAL_WINDOW = 'PROFILE_MODAL_WINDOW';

export const ProfileModalWindow: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { influencerProfileService } = useServices();

  const closeProfileModalWindow = useCallback(() => {
    influencerProfileService.closeInfluencerProfileModal();
    resetProfileLinkedList();
    setProfileIsLookalike(false);
  }, []);

  const windowRef = useClickAway(closeProfileModalWindow);

  useEffect(() => {
    windowRef.current?.focus();
  }, []);

  return (
    <Portal>
      <div className={styles.profileModalWindow__container}>
        <div
          id={CLOSE_PORTAL_NODE_ID}
          className={cn(styles.profileModalWindow, PROFILE_MODAL_WINDOW)}
        >
          <div
            ref={windowRef as Ref<HTMLDivElement>}
            className={styles.profileModalWindow__content}
          >
            <button
              className={styles.profileModalWindow__closeButton}
              type="button"
              onClick={closeProfileModalWindow}
            >
              <Icon
                type={ICON_TYPES.CLOSE}
                size={10}
              />
            </button>

            <ErrorBoundary layerName="profile">{children}</ErrorBoundary>
          </div>
        </div>
      </div>
    </Portal>
  );
};
