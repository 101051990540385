/*
 * Component-wrapper for simple text that adds ellipsis if text does not fit on sizes.
 * If was added ellipsis shows tooltip on mousemove with full text (without ellipsis in tooltip).
 */
import cn from 'clsx';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { PopupPositions } from '@uikit/components/Popup';
import { Tooltip } from '@uikit/components/Tooltip';
import { noop } from '@uikit/helpers/common.helpers';
import styles from './TextEllipsis.module.scss';

export enum SUPPORT_SHOW_LINES {
  ONE,
  TWO,
  THREE,
}

const SUPPORT_SHOW_LINES_MAP = {
  [SUPPORT_SHOW_LINES.ONE]: '',
  [SUPPORT_SHOW_LINES.TWO]: styles.twoLine,
  [SUPPORT_SHOW_LINES.THREE]: styles.threeLine,
};

type TTextEllipsisProps = {
  className?: string;
  onClick?: () => void;
  lineCount?: SUPPORT_SHOW_LINES;
  tooltipWidth?: number;
};

export const TextEllipsis: FC<PropsWithChildren<TTextEllipsisProps>> = ({
  className,
  onClick = noop,
  tooltipWidth,
  lineCount = SUPPORT_SHOW_LINES.ONE,
  children,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [withEllipsis, setWithEllipsis] = useState(false);
  const [disableTip, setDisableTip] = useState(false);

  const onTextClick = () => {
    // По клику на текст может отображаться другой popup, например какое-то меню.
    // Очевидно, что подсказка с полным содержимым в этом случае уже не актуальна - скрываем её.
    setDisableTip(true);
    onClick();
  };
  const onTextMouseLeave = () => {
    // Обрабатывваем кейс, когда подсказка могла отображаться, но была скрыта при клике по тексту.
    // При уходе с элемента необходимо обратно включить возможность её отображения.
    if (withEllipsis && disableTip) {
      setDisableTip(false);
    }
  };

  useEffect(() => {
    // Устанавливает признак наличия многоточия у текста.
    // Для многострочных сокращений используем scrollHeight и clientHeight в качестве сверки о том был ли блок сокращен
    lineCount === SUPPORT_SHOW_LINES.ONE
      ? setWithEllipsis(
          (contentRef.current?.scrollWidth || 0) > (contentRef.current?.clientWidth || 0)
        )
      : setWithEllipsis(
          (contentRef.current?.scrollHeight || 0) > (contentRef.current?.clientHeight || 0)
        );
  }, [
    contentRef?.current?.scrollWidth,
    contentRef?.current?.scrollHeight,
    contentRef?.current?.clientWidth,
    contentRef?.current?.clientHeight,
  ]);

  const content = (
    <div className={cn(styles.textEllipsis__container, className)}>
      <div
        ref={contentRef}
        className={cn(styles.textEllipsis__content, SUPPORT_SHOW_LINES_MAP[lineCount])}
        onClick={onTextClick}
        onMouseLeave={onTextMouseLeave}
      >
        {children}
      </div>
    </div>
  );

  if (!withEllipsis || disableTip) return content;

  return (
    <Tooltip
      content={<div className={styles.textEllipsis__tooltip}>{children}</div>}
      position={PopupPositions.BottomCenter}
      width={tooltipWidth}
      className={styles.textEllipsis__tooltipContainer}
    >
      {content}
    </Tooltip>
  );
};
