/*
 * Компонент фильтров для таблицы готовых к оплате платежей.
 */
import { useStore } from 'effector-react/compat';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency } from '@hypetrainCommon';
import { ChipToggler } from '@uikit/components/ChipToggler';
import {
  $paymentsCurrencyFilter,
  setPaymentsCurrencyFilter,
} from '@pagesPaymentsPage/payments.model';
import { getPaymentAccountName } from '@pagesPaymentsPage/payments.utils';
import styles from './ReadyForPaymentFilters.module.scss';

export const ReadyForPaymentFilters: FC = () => {
  const { t } = useTranslation();
  const selectedCurrency = useStore($paymentsCurrencyFilter);

  return (
    <div className={styles.readyForPaymentFilters}>
      <ChipToggler
        name={t('paymentsReadyForPayment.payFrom', {
          accountName: getPaymentAccountName(Currency.USD),
        })}
        isToggled={selectedCurrency === Currency.USD}
        onToggle={() => setPaymentsCurrencyFilter(Currency.USD)}
      />
      <ChipToggler
        name={t('paymentsReadyForPayment.payFrom', {
          accountName: getPaymentAccountName(Currency.EUR),
        })}
        isToggled={selectedCurrency === Currency.EUR}
        onToggle={() => setPaymentsCurrencyFilter(Currency.EUR)}
      />
    </div>
  );
};
