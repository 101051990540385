/*
 * Константы модуля платежей.
 */
import {
  Currency,
  IPaymentListItemDto,
  PaymentStatus,
  PaymentsSortName,
  SortOrder,
} from '@hypetrainCommon';
import {
  TPaymentCurrecyFilter,
  TPaymentsFilterName,
  TPaymentsList,
  TPaymentsOffset,
} from '@pagesPaymentsPage/payments.types';

/*
 * Первональное значение стора с offsets для загрузки платежей.
 */
export const INIT_PAYMENTS_OFFSET: TPaymentsOffset[] = [
  { filterValue: Currency.USD, offset: 0 },
  { filterValue: Currency.EUR, offset: 0 },
  { filterValue: PaymentStatus.Ready, offset: 0 },
  { filterValue: PaymentStatus.Paid, offset: 0 },
];

/*
 * Первональное значение стора с выбранными пользователем платежами.
 */
export const INIT_SELECTED_PAYMENTS: Record<TPaymentCurrecyFilter, IPaymentListItemDto[]> = {
  [Currency.USD]: [],
  [Currency.EUR]: [],
};

/*
 * Первоначальное значение стора платежей.
 */
export const INIT_PAYMENTS_STATE: Record<TPaymentsFilterName, TPaymentsList> = {
  [Currency.USD]: null,
  [Currency.EUR]: null,
  [PaymentStatus.Ready]: null,
  [PaymentStatus.Paid]: null,
};

/*
 * Количество платежей на странице.
 */
export const COUNT_PAYMENTS_ON_PAGE = 50;

/*
 * Сортировки для платежей по умолчанию.
 */
export const DEFAULT_PAYMENTS_SORTS = {
  name: PaymentsSortName.DueDate,
  options: { order: SortOrder.Descending },
};

/*
 * Фильтр платежей по валюте по умолчанию.
 */
export const DEFAULT_PAYMENTS_CURRENCY_FILTER = Currency.USD;
