/*
 * Контейнер для списка уведомлений
 */
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { NotificationsList, notificationsStore } from '@uikit/components/Notification';

export const NotificationsContainer: FC = observer(() => {
  const { notifications } = notificationsStore;

  return <NotificationsList notifications={toJS(notifications)} />;
});
