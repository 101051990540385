/*
 * Хелперы для работы со стилями.
 */

const computedStyle = getComputedStyle(document.body);

export const CSS_OUTLINE_DIVIDER = computedStyle.getPropertyValue('--outlineDivider');
export const CSS_ICON_SECONDARY = computedStyle.getPropertyValue('--iconSecondary');
export const CSS_TEXT_SECONDARY = computedStyle.getPropertyValue('--textSecondary');
export const CSS_BARCHART_COMMENTS_INSTAGRAM = computedStyle.getPropertyValue('--commentsBarChart');
export const CSS_BARCHART_LIKES_INSTAGRAM = computedStyle.getPropertyValue('--likesBarChart');
export const CSS_NO_DATA_DEMOGRAPHIC_GRAPHIC = computedStyle.getPropertyValue(
  '--backgroundTertiarySurface'
);
export const CSS_MALE_NO_SELECTED_DEMOGRAPHIC_GRAPHIC = computedStyle.getPropertyValue('--blue010');
export const CSS_MALE_SELECTED_DEMOGRAPHIC_GRAPHIC = computedStyle.getPropertyValue('--blue05');
export const CSS_FEMALE_NO_SELECTED_DEMOGRAPHIC_GRAPHIC =
  computedStyle.getPropertyValue('--red010');
export const CSS_FEMALE_SELECTED_DEMOGRAPHIC_GRAPHIC = computedStyle.getPropertyValue('--red05');

export const CSS_WIDTH_SHORT_VERTICAL_TAB = parseInt(
  computedStyle.getPropertyValue('--shortVerticalTab'),
  10
);

export const CSS_TABLE_COUNT_BLOCK = parseInt(
  computedStyle.getPropertyValue('--tableCountBlock'),
  10
);

// avatar sizes
export const CSS_AVATAR_XS = parseInt(computedStyle.getPropertyValue('--avatarXS'), 10);
export const CSS_AVATAR_S = parseInt(computedStyle.getPropertyValue('--avatarS'), 10);
export const CSS_AVATAR_M = parseInt(computedStyle.getPropertyValue('--avatarM'), 10);
export const CSS_AVATAR_XL = parseInt(computedStyle.getPropertyValue('--avatarXL'), 10);

export const CSS_Z_INDEX_DROPDOWN = computedStyle.getPropertyValue('--dropdownZIndex');
export const CSS_Z_INDEX_APP_INTRODUCTION =
  computedStyle.getPropertyValue('--appIntroductionZIndex');
export const CSS_Z_INDEX_POPUP = computedStyle.getPropertyValue('--popupZIndex');
export const CSS_Z_INDEX_TOOLTIP = computedStyle.getPropertyValue('--tooltipZIndex');
