/*
 * Компонент значения для метрики суммы платежа.
 */
import cn from 'clsx';
import { useStore } from 'effector-react/compat';
import { FC, memo } from 'react';
import { FeeType } from '@hypetrainCommon';
import { FormatCurrency } from '@uikit/components/Formats';
import { DEFAULT_METRIC_SIZE } from '@uikit/components/Metric/Metric.component';
import mecticStyles from '@uikit/components/Metric/Metric.module.scss';
import { TextEllipsis } from '@uikit/components/TextEllipsis';
import { LOADER_SIZE, LOADER_TYPE, Loader } from 'hypetrain-ui/src/components/Loader';
import { PaymentCompositionInfo } from '@pagesPaymentsPage/components/PaymentCompositionInfo';
import {
  $calculatedPaymentsSummary,
  $paymentsCurrencyFilter,
  calculatePaymentsFx,
} from '@pagesPaymentsPage/payments.model';
import styles from './PaymentAmountMetricValue.module.scss';

export const PaymentAmountMetricValue: FC = memo(() => {
  const currency = useStore($paymentsCurrencyFilter);
  const paymentsTotalAndFees = useStore($calculatedPaymentsSummary);
  const isCalculating = useStore(calculatePaymentsFx.pending);

  const totalAndFees = paymentsTotalAndFees?.[currency];
  const total = totalAndFees?.total;
  const fees = totalAndFees?.totalAppliedFees;

  return (
    <div className={cn(styles.paymentAmountMetricValue, mecticStyles[DEFAULT_METRIC_SIZE])}>
      <TextEllipsis>
        <FormatCurrency
          className={cn(isCalculating && styles.paymentAmountMetricValue_disabled)}
          currency={currency}
        >
          {total}
        </FormatCurrency>
      </TextEllipsis>

      {total && !isCalculating && (
        <PaymentCompositionInfo
          className={styles.paymentAmountMetricValue__infoIcon}
          currency={currency}
          subTotal={totalAndFees?.subTotal}
          hypetrainFee={fees?.[FeeType.Hypetrain]}
          conversionFee={fees?.[FeeType.CurrencyConversion]}
          crossBorderFee={fees?.[FeeType.CurrencyConversion]}
          operationalFee={fees?.[FeeType.Operational]}
          total={total}
        />
      )}

      {isCalculating && (
        <Loader
          className={styles.paymentAmountMetricValue__loader}
          size={LOADER_SIZE.XS}
          type={LOADER_TYPE.ADDITIONAL}
        />
      )}
    </div>
  );
});
