/*
 * Модель данных для платёжных счетов.
 */
import { createEffect, createEvent, createStore } from 'effector/compat';
import { IPaymentAccountDto, ITransactionDto, TransactionType } from '@hypetrainCommon';
import { paymentsApiService } from '@api/payments';

/*
 * Устанавливает открытый в данным момент платёжный счёт.
 */
export const setCurrentPaymentAccount = createEvent<string>();

/*
 * Сбрасывает открытый в данным момент платёжный счёт.
 */
export const resetCurrentPaymentAccount = createEvent();

/*
 * Событие очистки всех данных по платежам.
 */
export const resetPayments = createEvent();

/*
 * Получение списка платежных счетов пользователя.
 */
export const getPaymentAccountsFx = createEffect(paymentsApiService.getPaymentAccounts);

/*
 * Активация модуля платежей в приложении.
 */
export const activatePaymentsModuleFx = createEffect(paymentsApiService.activatePaymentsModule);

/*
 * Получение списка транзакций по платежному счету.
 */
export const getPaymentAccountTransactionsFx = createEffect(
  paymentsApiService.getPaymentAccountTransactions
);

/*
 * Платёжные счета.
 */
export const $paymentAccounts = createStore<IPaymentAccountDto[] | null>(null)
  .on(getPaymentAccountsFx.doneData, (_, payload) => payload)
  .reset(resetPayments);

/*
 * Открытый в данным момент платёжный счёт.
 */
export const $currentPaymentAccount = createStore<IPaymentAccountDto | null>(null)
  .on(
    setCurrentPaymentAccount,
    (_, accountId) =>
      $paymentAccounts.getState()?.find((account) => account?.id === accountId) || null
  )
  .reset(resetCurrentPaymentAccount);

/*
 * Список всех транзакций по платёжному аккаунту.
 */
export const $allPaymentAccountTransactions = createStore<ITransactionDto[]>([])
  .on(getPaymentAccountTransactionsFx.doneData, (_, transactions) => transactions)
  .reset(resetPayments);

/*
 * Список входящий транзакций по платёжному аккаунту.
 */
export const $incomingPaymentAccountTransactions = createStore<ITransactionDto[]>([])
  .on(getPaymentAccountTransactionsFx.doneData, (_, transactions) =>
    transactions.filter((transaction) => transaction?.type === TransactionType.IN)
  )
  .reset(resetPayments);

/*
 * Список исходящий транзакций по платёжному аккаунту.
 */
export const $outgoingPaymentAccountTransactions = createStore<ITransactionDto[]>([]).on(
  getPaymentAccountTransactionsFx.doneData,
  (_, transactions) =>
    transactions.filter((transaction) => transaction?.type === TransactionType.OUT)
);
