import { RefObject, useEffect, useState } from 'react';
import { FixedSizeList as List } from 'react-window';

const MIN_TABLE_ELEMENTS_FOR_BUTTON = 6;

type TLastUnreadMessageReturn = [
  number,
  (scrollPositionHeight: number, currentHeight: number, lastElements: number[]) => void,
  () => void
];

export const useLastUnreadMessage = (
  listRef: RefObject<List>,
  tablePosition: number,
  scrollElementsPosition: number[],
  setTablePosition: (number: number) => void
): TLastUnreadMessageReturn => {
  const [lastUnreadMessage, setLastUnreadMessage] = useState(0);

  const calculateUnreadMessage = (
    scrollPositionHeight: number,
    currentHeight: number,
    lastElements: number[]
  ): number => {
    const newLastElement = Math.ceil(
      (scrollPositionHeight + currentHeight) / Number(listRef.current?.props?.itemSize)
    );
    return lastElements.find((e) => e >= newLastElement) ?? 0;
  };

  const setLastUnread = (
    scrollPositionHeight: number,
    currentHeight: number,
    lastElements: number[]
  ) => {
    setLastUnreadMessage(calculateUnreadMessage(scrollPositionHeight, currentHeight, lastElements));
  };

  useEffect(() => {
    if (listRef.current) {
      setLastUnreadMessage(
        calculateUnreadMessage(tablePosition, +listRef.current.props.height, scrollElementsPosition)
      );
      if (setTablePosition) {
        setTablePosition(tablePosition);
      }
    } else if (scrollElementsPosition) {
      const isElements = scrollElementsPosition.find((e) => e >= MIN_TABLE_ELEMENTS_FOR_BUTTON);
      if (isElements) {
        setLastUnreadMessage(isElements);
      }
    }
  }, [listRef.current]);

  const scrollNewMessage = () => {
    listRef.current?.scrollToItem(lastUnreadMessage, 'center');
  };

  return [lastUnreadMessage, setLastUnread, scrollNewMessage];
};
