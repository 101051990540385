import { useEffect, useState } from 'react';

/*
 * Хук необходимый для предзагрузки и кэширования изображений
 * стандартные компоненты не позволяют отображать плейсхолдер/индикатор загрузки во врема загрузки изображения
 * чтобы это реализовать надо загружать изображение отдельно и пока оно грузится отображать плейсхолдер.
 *
 * Принцип работы:
 * 1) вызываем хук с ссылкой на изображение
 * 2) хук с помощью компонента Image его загружает, и кэширует
 * 3) по окончанию загрузки хук возвращает ссылку на закэшированное изображение
 *
 */
export const usePreloadImage = (srcUrl?: string): string | null => {
  const [preloadedSource, setPreloadedSource] = useState<string | null>(null);

  useEffect(() => {
    if (!srcUrl) return;

    const img = new Image();
    img.src = srcUrl;
    img.onload = () => setPreloadedSource(srcUrl);
  }, [srcUrl]);

  return preloadedSource;
};
