/*
 *   Компонент для отрисовки формы для legal entity
 */
import { FormApi } from 'final-form';
import { FC, useCallback, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Country, ILegalEntityDto, ILegalEntityInfoResponseDto } from '@hypetrainCommon';
import { INPUT_TYPE, Input } from '@uikit/components/Fields/Inputs';
import { SelectField } from '@uikit/components/SelectField';
import { makeValidate } from '@utils/makeValidate';
import { getCountryOptions } from '@constants/countries';
import { useFinalForm } from '@hooks/useFinalForm';
import { TLegalEntitySchema } from '@pagesWorkspaceSettings/components/LegalEntity';
import styles from '@pagesWorkspaceSettings/components/LegalEntity/components/LegalEntityDialog/LegalEntityDialog.module.scss';

type TLegalEntityFormProps = {
  legalEntity: ILegalEntityInfoResponseDto | ILegalEntityDto | undefined;
  onChangeForm: (personContacts: FormApi<ILegalEntityInfoResponseDto | ILegalEntityDto>) => void;
  submitFn: (
    values: TLegalEntitySchema
  ) => Promise<Record<keyof TLegalEntitySchema, string> | null>;
};
export const LegalEntityForm: FC<TLegalEntityFormProps> = ({
  legalEntity,
  onChangeForm,
  submitFn,
}) => {
  const { t } = useTranslation();

  const schema: Yup.SchemaOf<ILegalEntityDto> = Yup.object().shape({
    name: Yup.string().validField().required(t('workspaceSettings.legalEntity.modal.error.name')),
    registrationNumber: Yup.string()
      .validField()
      .required(t('workspaceSettings.legalEntity.modal.error.registrationNumber')),
    phoneNumber: Yup.string()
      .validField()
      .required(t('workspaceSettings.legalEntity.modal.error.phoneNumber')),
    email: Yup.string()
      .validField()
      .email()
      .required(t('workspaceSettings.legalEntity.modal.error.email')),
    address: Yup.string()
      .validField()
      .required(t('workspaceSettings.legalEntity.modal.error.address')),
    city: Yup.string().validField().required(t('workspaceSettings.legalEntity.modal.error.city')),
    postalCode: Yup.string()
      .max(20)
      .required(t('workspaceSettings.legalEntity.modal.error.postalCode')),
    region: Yup.string()
      .validField()
      .required(t('workspaceSettings.legalEntity.modal.error.region')),
    country: Yup.mixed<Country>()
      .oneOf(Object.values(Country))
      .required(t('workspaceSettings.legalEntity.modal.error.country')),
  });

  const validate = makeValidate(schema);

  const onSubmit = useCallback(
    (
      formValues: ILegalEntityInfoResponseDto | ILegalEntityDto,
      formInstance: FormApi<ILegalEntityInfoResponseDto | ILegalEntityDto>
    ) => {
      const formState = formInstance.getState();

      if (formState?.invalid && !formState?.dirtySinceLastSubmit) return;

      return submitFn(formValues);
    },
    [submitFn]
  );

  const form = useFinalForm<ILegalEntityInfoResponseDto | ILegalEntityDto>({
    onSubmit,
    initialValues: legalEntity,
    validate,
  });

  useEffect(() => {
    onChangeForm(form);
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      form={form}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field name="name">
            {({ meta, input }) => (
              <Input
                input={input}
                label={t('workspaceSettings.legalEntity.modal.companyName')}
                error={
                  (meta?.submitFailed && meta?.error) ||
                  (!meta?.dirtySinceLastSubmit && meta?.submitError)
                }
                type={INPUT_TYPE.TEXT}
              />
            )}
          </Field>
          <Field name="registrationNumber">
            {({ meta, input }) => (
              <Input
                input={input}
                label={t('workspaceSettings.legalEntity.modal.registrationNumber')}
                error={
                  (meta?.submitFailed && meta?.error) ||
                  (!meta?.dirtySinceLastSubmit && meta?.submitError)
                }
                type={INPUT_TYPE.TEXT}
              />
            )}
          </Field>
          <Field name="phoneNumber">
            {({ meta, input }) => (
              <Input
                input={input}
                label={t('workspaceSettings.legalEntity.modal.phoneNumber')}
                error={
                  (meta?.submitFailed && meta?.error) ||
                  (!meta?.dirtySinceLastSubmit && meta?.submitError)
                }
                type={INPUT_TYPE.TEXT}
              />
            )}
          </Field>
          <Field name="email">
            {({ meta, input }) => (
              <Input
                input={input}
                label={t('workspaceSettings.legalEntity.modal.email')}
                error={
                  (meta?.submitFailed && meta?.error) ||
                  (!meta?.dirtySinceLastSubmit && meta?.submitError)
                }
                type={INPUT_TYPE.TEXT}
              />
            )}
          </Field>
          <Field name="address">
            {({ meta, input }) => (
              <Input
                input={input}
                label={t('workspaceSettings.legalEntity.modal.address')}
                error={
                  (meta?.submitFailed && meta?.error) ||
                  (!meta?.dirtySinceLastSubmit && meta?.submitError)
                }
                type={INPUT_TYPE.TEXT}
              />
            )}
          </Field>
          <Field name="city">
            {({ meta, input }) => (
              <Input
                input={input}
                label={t('workspaceSettings.legalEntity.modal.city')}
                error={
                  (meta?.submitFailed && meta?.error) ||
                  (!meta?.dirtySinceLastSubmit && meta?.submitError)
                }
                type={INPUT_TYPE.TEXT}
              />
            )}
          </Field>
          <div className={styles.legalEntityDialog__halfBlock}>
            <Field name="postalCode">
              {({ meta, input }) => (
                <Input
                  input={input}
                  label={t('workspaceSettings.legalEntity.modal.postalCode')}
                  error={
                    (meta?.submitFailed && meta?.error) ||
                    (!meta?.dirtySinceLastSubmit && meta?.submitError)
                  }
                  type={INPUT_TYPE.TEXT}
                  className={styles.legalEntityDialog__halfInputs}
                />
              )}
            </Field>
            <Field name="region">
              {({ meta, input }) => (
                <Input
                  input={input}
                  label={t('workspaceSettings.legalEntity.modal.state')}
                  error={
                    (meta?.submitFailed && meta?.error) ||
                    (!meta?.dirtySinceLastSubmit && meta?.submitError)
                  }
                  type={INPUT_TYPE.TEXT}
                  className={styles.legalEntityDialog__halfInputs}
                />
              )}
            </Field>
          </div>
          <Field name="country">
            {({ meta, input }) => (
              <SelectField
                input={input}
                label={t('workspaceSettings.legalEntity.modal.country')}
                autocomplete
                viewValuePath="label"
                valuePath="value"
                clearInputOnMenuClose
                error={
                  (meta?.submitFailed && meta?.error) ||
                  (!meta?.dirtySinceLastSubmit && meta?.submitError)
                }
                options={getCountryOptions()}
              />
            )}
          </Field>
        </form>
      )}
    />
  );
};
