/*
 * Компонент ячейки таблицы с информацией об инфлюенсере.
 */
import cn from 'clsx';
import { FC } from 'react';
import { TextEllipsis } from 'hypetrain-ui/src/components/TextEllipsis';
import { InfluencerAvatar } from '@components/InfluencerAvatar';
import styles from './SmaInfoCell.module.scss';

type TSmaInfoCellProps = {
  name: string;
  influencerId: string;
  img: string;
  className?: string;
};

export const SmaInfoCell: FC<TSmaInfoCellProps> = ({ name, influencerId, img, className }) => (
  <div className={cn(styles.smaInfoCell, className)}>
    <InfluencerAvatar
      className={styles.smaInfoCell__icon}
      influencerId={influencerId}
      img={img}
    />
    <TextEllipsis>{name}</TextEllipsis>
  </div>
);
