/*
 * Multiselect filter option.
 */
import { FC } from 'react';
import { OptionProps } from 'react-select';
import { ListItem } from '@uikit/components/ListItem';
import styles from './FilterMultiSelectOption.module.scss';

export const FilterMultiSelectOption: FC<OptionProps<{ label: string; value: string }>> = ({
  innerProps,
  innerRef,
  data,
  isSelected,
}) => (
  <ListItem
    mainText={data.label || ''}
    innerProps={innerProps}
    innerRef={innerRef}
    isSelected={isSelected}
    className={styles.filterMultiSelectOption}
  />
);
