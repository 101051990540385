import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { PopupPositions } from '@uikit/components/Popup';
import { Tooltip } from '@uikit/components/Tooltip';
import styles from './informationTooltip.module.scss';

type TInformationTooltipProps = {
  iconStyle?: ICON_STYLES;
  className?: string;
  classNameIcon?: string;
  position?: PopupPositions;
  width?: number;
};

export const InformationTooltip: FC<PropsWithChildren<TInformationTooltipProps>> = ({
  children,
  iconStyle = ICON_STYLES.PRIMARY,
  className,
  classNameIcon,
  position = PopupPositions.BottomCenter,
  width = 230,
}) => (
  <Tooltip
    content={<span>{children}</span>}
    position={position}
    className={cn(styles.informationTooltip, className)}
    offset={10}
    width={width}
  >
    <Icon
      type={ICON_TYPES.INFO}
      style={iconStyle}
      className={classNameIcon}
    />
  </Tooltip>
);
