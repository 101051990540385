/*
 * Сервис для работы с webSocket-нотификациями внутри контрактинга о генерации и подписи контракта.
 */
import { NotificationContext, NotificationType, UserNotification } from '@hypetrainCommon';
import {
  TWebSocketMessageCallback,
  TWebSocketMessageHandler,
  WebSocketService,
} from '@api/webSocket';

export class CampaignDealContractingSocketService {
  private handlers: TWebSocketMessageHandler[] = [];

  constructor(private readonly socket: WebSocketService) {}

  /*
   * Инициализация.
   * Слушает целевой топик CampaignDealContract, отправляя все поступившие нотификации в менеджер (обработчик).
   */
  public init = (): void => {
    this.socket.on(NotificationContext.CampaignDealContract, this.messageManager);
  };

  /*
   * Отписывается от просушиваемого топика, очищает список обработчиков.
   */
  public destroy = (): void => {
    this.socket.off(NotificationContext.CampaignDealContract, this.messageManager);
    this.handlers = [];
  };

  /*
   * Подписка на нотификацию об генерации контракта.
   */
  public onContractGenerate = (dealId: string, cb: TWebSocketMessageCallback): void => {
    const selector = (notification: UserNotification): boolean =>
      notification?.type === NotificationType.campaignDealContractStatusUpdated &&
      dealId.includes(notification?.data?.dealId);

    this.handlers.push({
      id: NotificationType.campaignDealContractStatusUpdated,
      selector,
      cb,
    });
  };

  /*
   * Подписка на нотификацию об подписи контракта.
   */
  public onContractSign = (dealId: string, cb: TWebSocketMessageCallback): void => {
    const selector = (notification: UserNotification): boolean =>
      notification?.type === NotificationType.campaignDealContractSignStatusUpdated &&
      dealId.includes(notification?.data?.dealId);

    this.handlers.push({
      id: NotificationType.campaignDealContractSignStatusUpdated,
      selector,
      cb,
    });
  };

  /*
   * Менеджер обрабатывающий WebSocket-сообщения топика CampaignDealContracting.
   * Вызывает селекторы каждого обработчика, что бы понять нужно ли вызывать его callback.
   */
  private messageManager = (notification: UserNotification, ack: () => void): void => {
    this.handlers.forEach((handler) => {
      if (handler.selector(notification)) {
        ack();
        handler.cb(notification);
      }
    });
  };
}

export const createCampaignDealContractingSocketService = (webSocketService: WebSocketService) =>
  new CampaignDealContractingSocketService(webSocketService);
