// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inputAsyncLoader__aC1yi{position:relative}.inputAsyncLoader__turnOffContent__zlZF9{position:relative}.inputAsyncLoader__turnOffContent__zlZF9::before{content:\"\";top:28px;height:37px;width:100%;left:0;position:absolute;z-index:1}.inputAsyncLoader__turnOffContent_withoutLabel__XsZBS::before{top:10px}.spinner__bsa4_{position:absolute;width:37px;height:37px;right:2px;top:27px}.spinner__withDescription__Deoqf{top:54px}.spinner__withoutLabel__et19j{top:10px}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Fields/Inputs/Input/components/InputAsyncLoader/InputAsyncLoader.module.scss"],"names":[],"mappings":"AAEA,yBACE,iBAAA,CAEA,yCACE,iBAAA,CAEA,iDACE,UAAA,CACA,QAAA,CACA,WAAA,CACA,UAAA,CACA,MAAA,CACA,iBAAA,CACA,SAAA,CAIA,8DACE,QAAA,CAMR,gBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,QAAA,CAEA,iCACE,QAAA,CAGF,8BACE,QAAA","sourcesContent":["@import '../../../../../../assets/styles/ui_kit_mixins';\n\n.inputAsyncLoader {\n  position: relative;\n\n  &__turnOffContent {\n    position: relative;\n\n    &::before {\n      content: '';\n      top: 28px;\n      height: 37px;\n      width: 100%;\n      left: 0;\n      position: absolute;\n      z-index: 1;\n    }\n\n    &_withoutLabel {\n      &::before {\n        top: 10px;\n      }\n    }\n  }\n}\n\n.spinner {\n  position: absolute;\n  width: 37px;\n  height: 37px;\n  right: 2px;\n  top: 27px;\n\n  &__withDescription {\n    top: 54px;\n  }\n\n  &__withoutLabel {\n    top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputAsyncLoader": "inputAsyncLoader__aC1yi",
	"inputAsyncLoader__turnOffContent": "inputAsyncLoader__turnOffContent__zlZF9",
	"inputAsyncLoader__turnOffContent_withoutLabel": "inputAsyncLoader__turnOffContent_withoutLabel__XsZBS",
	"spinner": "spinner__bsa4_",
	"spinner__withDescription": "spinner__withDescription__Deoqf",
	"spinner__withoutLabel": "spinner__withoutLabel__et19j"
};
export default ___CSS_LOADER_EXPORT___;
