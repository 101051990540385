// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".portal_error__BhigB{position:fixed;background-color:#fff;bottom:0;height:150px}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Portal/Portal.module.scss"],"names":[],"mappings":"AAGE,qBACE,cAAA,CACA,qBAAA,CACA,QAAA,CACA,YAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.portal {\n  &_error {\n    position: fixed;\n    background-color: white;\n    bottom: 0;\n    height: 150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portal_error": "portal_error__BhigB"
};
export default ___CSS_LOADER_EXPORT___;
