// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".createCampaignDialog__HXIth{height:100%}.createCampaignDialog__backButton__WOwCG{margin-bottom:var(--hSpacing16)}", "",{"version":3,"sources":["webpack://./pages/Campaigns/CampaignsPage/components/CreateCampaignDialog/CreateCampaignDialog.module.scss"],"names":[],"mappings":"AAEA,6BACE,WAAA,CAEA,yCACE,+BAAA","sourcesContent":["@import '../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.createCampaignDialog {\n  height: 100%;\n\n  &__backButton {\n    margin-bottom: var(--hSpacing16);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createCampaignDialog": "createCampaignDialog__HXIth",
	"createCampaignDialog__backButton": "createCampaignDialog__backButton__WOwCG"
};
export default ___CSS_LOADER_EXPORT___;
