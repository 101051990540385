/*
 *  Хук для создания и мемоизации final form
 */
import { Config, FormApi, createForm } from 'final-form';
import { useMemo } from 'react';

export function useFinalForm<T>(config: Config<T>): FormApi<T> {
  const form = useMemo(() => createForm<T>(config), [config]);

  return form;
}
