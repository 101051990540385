/*
 * Date picker field component.
 */

/*
 * TODO: Если начинаем использовать вне date-picker'а рассмотреть вынос в shared?.
 */

export const DATE_VIEW_FORMAT = 'MM/DD/YYYY';
export const DATE_INTERVAL_VIEW_FORMAT = 'MMM D, YYYY';
export const ISO_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
