import { AVATAR_COLORS, MAX_NUMBER_HEX } from './avatar.constants';

/*
  У нас есть n-ое количество цветов. Надо что-бы каждому юзеру пренадлежал
  цвет, который не меняется. За основу берем первые 2 символа его uuid
  который преобразуется в десятичную систему исчисления из шестнадцатиричной.
  (т.к. 2 первых символа по сути ложатся на шестрадцатиричную систему)
  Максимальное десятиричное число, в котором есть буквы шестнадцатиричной это
  255, т.е. 255 === FF
  Дальше мы это максимальное число делим на количество цветов, что бы знать
  дельту соотношения юзеров к цвету.
  Дальше мы делим наше десятиричное число(которое уже преобразовали) на количество
  "дельту" с округлением.
  И уже после мы берем из массива всех цветов, тот цвет, по индексу, который
  соответствует данному юзеру по его uuid
*/

export const getColorAvatar = (id = '', isDisabled = false): string => {
  if (isDisabled) return '#ECEFF4';

  const random = parseInt(id[0] + id[1], 16);
  const count = MAX_NUMBER_HEX / AVATAR_COLORS.length;
  const currentColor = Math.round(random / count);

  // Для редкого кейса (id начинается на FF) avatarColors будет 23, а цвета от 0 до 22 - возвращаем avatarColors[0].
  return AVATAR_COLORS[currentColor] || AVATAR_COLORS[0];
};
