/*
 * Колонки таблиц списка транзакций по платёжному счёту.
 */
import { CellProps, Row, UseExpandedRowProps } from 'react-table';
import { ITransactionDto, ITransferDto } from '@hypetrainCommon';
import { formatDate } from '@uikit/components/DatePickerField/datePickerField.utils';
import {
  DefaultCell,
  ExpandCell,
  TColumn,
  TTableRow,
  TableHeaderCell,
} from '@uikit/components/Table';
import i18n from '@services/i18n';
import { PaymentTransactionAmount } from '@pagesPaymentsPage/components/PaymentAccountPage/components/PaymentTransactionAmount';
import { PaymentAmountCell } from '@pagesPaymentsPage/components/PaymentAmountCell';
import { SmaInfoCell } from '@components/TableCells/SmaInfoCell';
import { PaymentTransactionDescription } from './components/PaymentTransactionDescription';
import styles from './PaymentAccountPage.module.scss';

/* eslint-disable react/destructuring-assignment */
export const paymentAccountTransactionsColumns: TColumn<ITransactionDto>[] = [
  {
    id: 'createdAt',
    Header: (
      <DefaultCell>
        <TableHeaderCell text={i18n.t('paymentAccountTransactionsTable.date')} />
      </DefaultCell>
    ),
    accessor: '',
    disableSortBy: true,
    Cell: (cell: CellProps<ITransactionDto>) => {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const row = cell.row as Row<ITransactionDto | any> & UseExpandedRowProps<ITransferDto>;

      if (row.depth === 0) {
        return <DefaultCell>{formatDate(row.original?.createdAt, 'MMM D, YYYY')}</DefaultCell>;
      }

      return (
        <DefaultCell>
          <SmaInfoCell
            className={styles.paymentAccountPage__recipientCell}
            name={row.original?.recipient?.name}
            influencerId={row.original?.recipient?.smaId}
            img={row.original?.recipient?.avatarUrl}
          />
        </DefaultCell>
      );
    },
  },
  {
    id: 'amount',
    Header: (
      <DefaultCell>
        <TableHeaderCell text={i18n.t('paymentAccountTransactionsTable.amount')} />
      </DefaultCell>
    ),
    accessor: '',
    disableSortBy: true,
    Cell: (cell: CellProps<ITransactionDto>) => {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const row = cell.row as Row<ITransactionDto | any> & UseExpandedRowProps<ITransferDto>;

      if (row.depth === 0) {
        return (
          <DefaultCell>
            <PaymentTransactionAmount transaction={row.original} />
          </DefaultCell>
        );
      }

      return (
        <PaymentAmountCell
          amount={row.original?.amount}
          currency={row.original?.currency}
          convertedAmount={row.original?.chargeAmount}
          convertedCurrency={row.original?.chargeCurrency}
          withConverted
        />
      );
    },
  },
  {
    id: 'description',
    Header: (
      <DefaultCell>
        <TableHeaderCell text={i18n.t('paymentAccountTransactionsTable.description')} />
      </DefaultCell>
    ),
    accessor: 'description',
    disableSortBy: true,
    Cell: (cell: CellProps<ITransactionDto>) => {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const row = cell.row as Row<ITransactionDto | any> & UseExpandedRowProps<ITransferDto>;

      if (row.depth === 0) {
        return (
          <DefaultCell>
            <PaymentTransactionDescription transaction={row.original} />
          </DefaultCell>
        );
      }

      return null;
    },
  },
  {
    id: 'expand',
    accessor: '',
    disableSortBy: true,
    width: 34,
    canResize: false,
    Cell: (cell: CellProps<ITransactionDto>) => {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const row = cell.row as Row<ITransactionDto | any> & UseExpandedRowProps<ITransferDto>;

      return <ExpandCell row={row as TTableRow} />;
    },
  },
];
