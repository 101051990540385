/*
 * A data bus whose events can be subscribed to.
 */

type TUnsubscribeCallback = () => void;
type TEventBusHandler = (args: unknown) => void;

export class EventBus {
  private eventsMap: Map<string, Set<TEventBusHandler>> = new Map();

  public on = (eventName: string, handler: TEventBusHandler): TUnsubscribeCallback => {
    if (!this.eventsMap.has(eventName)) {
      this.eventsMap.set(eventName, new Set());
    }

    const eventSet = this.eventsMap.get(eventName);
    eventSet?.add(handler);

    return () => this.off(eventName, handler);
  };

  public emit = (eventName: string, payload?: unknown): void => {
    const eventSet = this.eventsMap.get(eventName);
    if (!eventSet?.size) return;

    eventSet.forEach((handler) => handler(payload));
  };

  public deleteListeners = (eventName: string): void => {
    this.eventsMap.delete(eventName);
  };

  private off = (eventName: string, handler: TEventBusHandler): void => {
    const eventSet = this.eventsMap.get(eventName);
    if (!eventSet?.size) return;

    eventSet.delete(handler);
  };
}
