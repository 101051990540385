import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import uiKitEnTranslations from '@uikit/assets/locales/ui-kit.en';
import { UI_KIT_INTL_NAMESPACE_NAME } from '@uikit/constants/common.constants';
import enTranslations from '@assets/locales/en';

const APP_INTL_NAMESPACE_NAME = 'app';

i18n.use(initReactI18next).init({
  lng: process.env.DEFAULT_LANG || 'EN',
  defaultNS: APP_INTL_NAMESPACE_NAME,
  ns: [APP_INTL_NAMESPACE_NAME, UI_KIT_INTL_NAMESPACE_NAME],
  fallbackLng: process.env.DEFAULT_LANG || 'EN',
  resources: {
    EN: {
      [APP_INTL_NAMESPACE_NAME]: enTranslations,
      [UI_KIT_INTL_NAMESPACE_NAME]: uiKitEnTranslations,
    },
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
