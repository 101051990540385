// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".circleCounter__y7TCC{width:20px;height:20px;background-color:var(--backgroundSurface);border-radius:var(--round22);display:flex;justify-content:center;align-items:center}.circleCounter__content__tcWo6{width:16px;height:16px;background-color:var(--indicatorBadge);border-radius:var(--round22);color:var(--textInverted);display:flex;justify-content:center;align-items:center}.circleCounter_disabled__DSrmD{background-color:var(--backgroundSecondarySurface)}.circleCounter_disabled__DSrmD .circleCounter__content__tcWo6{color:var(--textDisabled)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/CircleCounter/CircleCounter.module.scss"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,WAAA,CACA,yCAAA,CACA,4BAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,+BACE,UAAA,CACA,WAAA,CACA,sCAAA,CACA,4BAAA,CACA,yBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,+BACE,kDAAA,CAEA,8DACE,yBAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.circleCounter {\n  width: 20px;\n  height: 20px;\n  background-color: var(--backgroundSurface);\n  border-radius: var(--round22);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  &__content {\n    width: 16px;\n    height: 16px;\n    background-color: var(--indicatorBadge);\n    border-radius: var(--round22);\n    color: var(--textInverted);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  &_disabled {\n    background-color: var(--backgroundSecondarySurface);\n\n    .circleCounter__content {\n      color: var(--textDisabled);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circleCounter": "circleCounter__y7TCC",
	"circleCounter__content": "circleCounter__content__tcWo6",
	"circleCounter_disabled": "circleCounter_disabled__DSrmD"
};
export default ___CSS_LOADER_EXPORT___;
