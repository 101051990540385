import {
  CSS_FEMALE_NO_SELECTED_DEMOGRAPHIC_GRAPHIC,
  CSS_FEMALE_SELECTED_DEMOGRAPHIC_GRAPHIC,
  CSS_MALE_NO_SELECTED_DEMOGRAPHIC_GRAPHIC,
  CSS_MALE_SELECTED_DEMOGRAPHIC_GRAPHIC,
  CSS_NO_DATA_DEMOGRAPHIC_GRAPHIC,
} from '@uikit/helpers/styles.helpers';
import {
  SEX_DEMOGRAPHIC,
  TGetAudienceDemographicColor,
} from '@components/AudienceDemographic/AudienceDemographic.types';

const grayColor = CSS_NO_DATA_DEMOGRAPHIC_GRAPHIC;
const maleColor = {
  gray: grayColor,
  lightBlue: CSS_MALE_NO_SELECTED_DEMOGRAPHIC_GRAPHIC,
  blue: CSS_MALE_SELECTED_DEMOGRAPHIC_GRAPHIC,
};
const femaleColor = {
  gray: grayColor,
  lightPink: CSS_FEMALE_NO_SELECTED_DEMOGRAPHIC_GRAPHIC,
  pink: CSS_FEMALE_SELECTED_DEMOGRAPHIC_GRAPHIC,
};

// TODO Женя: Переписать эту логики! Происследовать влияние на производительнось - найти лучший путь!?
export const getAudienceDemographicColor = ({
  rangeAge,
  filterAudience,
  maxAge,
  sex,
}: TGetAudienceDemographicColor): string => {
  if (!filterAudience) {
    return sex === SEX_DEMOGRAPHIC.MALE ? maleColor.lightBlue : femaleColor.lightPink;
  }
  if (filterAudience?.values?.length === 1 && maxAge.key === rangeAge) {
    return sex === SEX_DEMOGRAPHIC.MALE ? maleColor.blue : femaleColor.pink;
  }
  // @ts-ignore TODO Женя: Логика ниже вообще нужна? кажется что она не работает
  if (filterAudience?.values?.[rangeAge] && maxAge.key === rangeAge) {
    return sex === SEX_DEMOGRAPHIC.MALE ? maleColor.blue : femaleColor.pink;
  }
  // @ts-ignore TODO Женя
  if (filterAudience?.values?.[rangeAge]) {
    return sex === SEX_DEMOGRAPHIC.MALE ? maleColor.lightBlue : femaleColor.lightPink;
  }
  return sex === SEX_DEMOGRAPHIC.MALE ? maleColor.lightBlue : femaleColor.lightPink;
};
