/*
 * Masked input component.
 * Can be used for input formatted numbers.
 */
import cn from 'clsx';
import { FocusEvent, forwardRef, useEffect, useState } from 'react';
import NumberFormat, { NumberFormatValues, SourceInfo } from 'react-number-format';
import { Link } from 'react-router-dom';
import { TInputProps } from '@uikit/components/Fields/Inputs/Input';
import { INPUT_TYPE } from '@uikit/components/Fields/Inputs/Input.constants';
import inputStyles from '@uikit/components/Fields/Inputs/Input/Input.module.scss';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { InformationTooltip } from '@uikit/components/InformationTooltip';
import { PopupPositions } from '@uikit/components/Popup';
import { UnderlineTooltip } from '@uikit/components/UnderlineTooltip';
import { MASKED_INPUT_TYPES } from './MaskedInput.constants';
import styles from './MaskedInput.module.scss';

type TMaskedInputProps = {
  min?: number; // minimal allowed value
  max?: number; // maximal allowed value
  maskType?: MASKED_INPUT_TYPES; // masked input type
  precision?: number; // limit for number fractional part
  allowNegative?: boolean; // allow negative numbers
  value?: number;
  onChange?: (value: number | undefined) => void;
  format?: string;
  mask?: string;
  numberFormatValidate?: (inputValues: NumberFormatValues) => boolean;
};

export const MaskedInput = forwardRef<HTMLInputElement, TInputProps & TMaskedInputProps>(
  (
    {
      input,
      value,
      onChange,
      label,
      disabled = false,
      descriptionText = '',
      forwardText = '',
      forwardLink = '',
      placeholder = '',
      onKeyUp = () => {},
      onKeyDown = () => {},
      onBlur,
      onClick,
      error,
      className,
      hideError,
      autocomplete,
      min,
      max,
      maskType = MASKED_INPUT_TYPES.NUMBER,
      precision = 2,
      allowNegative = false,
      format,
      mask,
      tooltip,
      underlineTooltip,
      numberFormatValidate,
    },
    ref
  ) => {
    const isPercent = maskType === MASKED_INPUT_TYPES.PERCENT;
    const [values, setValues] = useState<Partial<NumberFormatValues>>({
      value: value?.toString() || input?.value,
    });

    useEffect(() => {
      setValues({ value: value?.toString() || input?.value || '' });
    }, [input, value]);

    const onBlurHandler = (e: FocusEvent<HTMLInputElement>): void => {
      /*
       *
       * Мы не можем делать валидацию минимального значения в validateLimits т.к.
       * в этом случае жестко ограничим ввод для min такой вариант не подходит.
       *
       * Например для min=100 чтобы ввести 100 надо сначала ввести 1, что есть невалидное значение
       *
       * Позволяем вводить любое число и на блюр приводим его к минимальному если оно не подходит под условия
       */
      if (min && Number(values.floatValue) < min) {
        setValues({ value: min.toString() });
      }
      onBlur && onBlur(e);
      input?.onBlur && input.onBlur(e);
    };

    /*
     * Метод валидации введенного числа
     */
    const validateLimits = (inputValues: NumberFormatValues): boolean => {
      /*
       * если передан макс то валидируем,
       * max может быть 0, при этом это falsy значение, по этому проверяем его отдельно
       */
      if (max || max === 0) {
        const { floatValue } = inputValues;
        // удаление последнего символа в инпуте это всегда валидное действие
        if (!floatValue && floatValue !== 0) return true;

        return floatValue <= max;
      }

      return true;
    };

    const onChangeHandler = (inputValues: NumberFormatValues, sourceInfo: SourceInfo): void => {
      // если источник изменений пропс то нет смысла тригерить onChange,
      // так как значение и так пришло из верхних компонентов
      if (sourceInfo.source === 'prop') return;

      onChange && onChange(inputValues.floatValue);
      input?.onChange &&
        input.onChange({
          ...sourceInfo.event,
          target: {
            ...sourceInfo.event?.target,
            value: inputValues.floatValue,
          },
        });
      setValues(inputValues);
    };

    // TODO практически полная копипаста стилей и верстки из Input, переписать в будущем на FormField
    return (
      <div className={cn(inputStyles.inputBlock, className, styles.maskedInput)}>
        <div className={cn(inputStyles.topText, tooltip && inputStyles.topText_withTooltip)}>
          <div className={inputStyles.label}>
            {underlineTooltip ? (
              <UnderlineTooltip tooltipText={underlineTooltip}>{label}</UnderlineTooltip>
            ) : (
              label
            )}
          </div>
          {forwardText && (
            <Link
              to={forwardLink}
              tabIndex={-1}
            >
              {forwardText}
            </Link>
          )}
          {tooltip && (
            <InformationTooltip
              position={PopupPositions.TopCenter}
              iconStyle={ICON_STYLES.SECONDARY}
            >
              {tooltip}
            </InformationTooltip>
          )}
        </div>
        {!!descriptionText?.length && (
          <div className={inputStyles.descriptionText}>{descriptionText}</div>
        )}
        <div className={inputStyles.inputBlock__container}>
          <NumberFormat
            {...input}
            getInputRef={ref}
            className={cn(inputStyles.inputBlock__input, [
              {
                [inputStyles.errorInput]: error,
              },
            ])}
            allowNegative={allowNegative}
            disabled={disabled}
            type={INPUT_TYPE.TEXT}
            isAllowed={numberFormatValidate || validateLimits}
            placeholder={placeholder}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            decimalScale={precision}
            onBlur={onBlurHandler}
            onClick={onClick}
            onValueChange={onChangeHandler}
            value={values?.value}
            autoComplete={autocomplete}
            thousandSeparator={isPercent ? '' : ','}
            suffix={isPercent ? '%' : ''}
            format={format}
            mask={mask}
          />
          {!hideError && <span className={inputStyles.inputBlock__error}>{error}</span>}
        </div>
      </div>
    );
  }
);
