/*
 * Стор системых уведомлений.
 */
import { makeAutoObservable } from 'mobx';
import {
  TSystemNotificationsConfig
} from '@uikit/components/SystemNotification/systemNotifications.types';

export class SystemNotificationsStore {
  /*
   * Текущие уведомления.
   */
  public systemNotifications: TSystemNotificationsConfig[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  /*
   * Добавить уведомление в стор.
   */
  public add(config: TSystemNotificationsConfig): void {
    this.systemNotifications.push(config);
  }

  /*
   * Удалить уведомление из стора.
   */
  public delete(id: string): void {
    this.systemNotifications = this.systemNotifications.filter(
      (notification) => notification.id !== id
    );
  }

  /*
   * Удалить все уведомления из стора.
   */
  public clear(): void {
    this.systemNotifications = [];
  }
}

export const systemNotificationsStore = new SystemNotificationsStore();
