// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paymentAmountMetricValue___5jXP{display:flex}.paymentAmountMetricValue__infoIcon__O7QA6,.paymentAmountMetricValue__loader__Id2AM{margin-left:var(--vSpacing8)}.paymentAmountMetricValue__loader__Id2AM{max-width:unset}.paymentAmountMetricValue_disabled__mbZD4{color:var(--textDisabled)}", "",{"version":3,"sources":["webpack://./pages/PaymentsPage/components/PaymentsReadyForPayment/components/PaymentAmountMetricValue/PaymentAmountMetricValue.module.scss"],"names":[],"mappings":"AAEA,iCACE,YAAA,CAEA,oFAEE,4BAAA,CAGF,yCACE,eAAA,CAGF,0CACE,yBAAA","sourcesContent":["@import '../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.paymentAmountMetricValue {\n  display: flex;\n\n  &__infoIcon,\n  &__loader {\n    margin-left: var(--vSpacing8);\n  }\n\n  &__loader {\n    max-width: unset;\n  }\n\n  &_disabled {\n    color: var(--textDisabled);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentAmountMetricValue": "paymentAmountMetricValue___5jXP",
	"paymentAmountMetricValue__infoIcon": "paymentAmountMetricValue__infoIcon__O7QA6",
	"paymentAmountMetricValue__loader": "paymentAmountMetricValue__loader__Id2AM",
	"paymentAmountMetricValue_disabled": "paymentAmountMetricValue_disabled__mbZD4"
};
export default ___CSS_LOADER_EXPORT___;
