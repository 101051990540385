/*
 * Компонент-посредник для отрисовки тикток версии круговой диаграммы стран
 * */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ITiktokSmaResponseDto, SmaAudienceCountryValue, SmaPlatform } from '@hypetrainCommon';
import { AudienceLocation } from '@components/AudienceLocation/components/AudienceLocation.component';
import { getLimitAudienceData } from '@components/AudienceLocation/utils';

type TAudienceLocationTikTokProps = {
  influencer: ITiktokSmaResponseDto;
  isProfile?: boolean;
};

export const AudienceLocationTikTok: FC<TAudienceLocationTikTokProps> = ({
  influencer,
  isProfile = false,
}) => {
  const { t } = useTranslation();
  const subscribers = influencer?.metrics?.followersCount;
  const countryValues = getLimitAudienceData(influencer?.audience?.followers?.countries?.values);

  return (
    <AudienceLocation
      platform={SmaPlatform.Tiktok}
      countries={countryValues as SmaAudienceCountryValue[]}
      subscribers={subscribers}
      isProfile={isProfile}
      uuid={influencer?.uuid}
      info={t('profile.audience.locationTikTokFollowersInfo')}
      selectedTab="followers"
    />
  );
};
