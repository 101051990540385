import { FC } from 'react';
import { Field, Form } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ICreateUserDto } from '@hypetrainCommon';
import { BUTTON_TYPE, Button } from '@uikit/components/Buttons';
import { CheckboxField } from '@uikit/components/Fields/CheckboxField';
import { INPUT_TYPE, Input } from '@uikit/components/Fields/Inputs';
import { DefaultForm } from '@uikit/components/templates/DefaultForm';
import { Routes } from '@router/Routes';
import i18n from '@services/i18n';
import { makeValidate } from '@utils/makeValidate';
import styles from './SignUpForm.module.scss';

export type TSignUpFormSchema = {
  email: string;
  password: string;
  marketingConsent?: boolean;
};

type TSignUpFormProps = {
  mainTitle: string;
  secondaryTitle: string;
  onSubmit: (params: ICreateUserDto) => Promise<void | string | { email: string }>;
  email?: string;
};

export const SignUpForm: FC<TSignUpFormProps> = ({
  mainTitle,
  secondaryTitle,
  onSubmit,
  email,
}) => {
  const { t } = useTranslation();

  const schema: Yup.SchemaOf<TSignUpFormSchema> = Yup.object().shape({
    email: Yup.string().validEmail().required(i18n.t('errors.email.templateEmail')),
    password: Yup.string()
      .required(i18n.t('errors.password.createPassword'))
      .min(8, i18n.t('errors.password.shortPassword'))
      .matches(/^\S*$/, i18n.t('errors.password.whitespaceNotAllowed')),
    marketingConsent: Yup.boolean().optional(),
  });

  const validate = makeValidate(schema);

  return (
    <DefaultForm
      mainTitle={mainTitle}
      secondaryTitle={secondaryTitle}
      linkTitle={t('haveAnAccount')}
      directionTitle={t('loginScreen.login')}
      whereTo={Routes.login}
      customClassNameSecondaryTitle={styles.signUpForm__secondaryTitle}
      customClassNameLinkTitle={styles.signUpForm__linkTitle}
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{ termsAgree: false, marketingConsent: false, email }}
        render={() => (
          <form>
            <Field name="email">
              {({ meta, input }) => (
                <Input
                  input={input}
                  valueFormatter={(value) => value.toLowerCase()}
                  label={t('loginScreen.emailForLogin')}
                  disabled={!!email}
                  error={
                    (meta?.submitFailed && meta?.error) ||
                    (!meta?.dirtySinceLastSubmit && meta?.submitError)
                  }
                />
              )}
            </Field>
            <Field name="password">
              {({ meta, input }) => (
                <Input
                  input={input}
                  label={t('password')}
                  error={meta?.submitFailed && meta?.error}
                  type={INPUT_TYPE.PASSWORD}
                />
              )}
            </Field>
            <Field
              name="marketingConsent"
              type="checkbox"
            >
              {({ meta, input }) => (
                <CheckboxField
                  input={input}
                  label={t('marketingConsent')}
                  error={meta?.submitFailed && meta?.error}
                  className={styles.signUpForm__checkbox}
                  isDisabled
                />
              )}
            </Field>
            <Button
              buttonType={BUTTON_TYPE.COMMIT}
              disabled
            >
              {t('createAccount')}
            </Button>
          </form>
        )}
      />
      <div className={styles.signUpForm__terms}>
        <Trans
          i18nKey="termsAgree"
          components={{ a: <a /> }}
        />
      </div>
    </DefaultForm>
  );
};
