/*
 * Модель для работы с языками
 */
import { createEffect, createStore } from 'effector/compat';
import { SmaPlatform } from '@hypetrainCommon';
import { TLanguage, explorationApiService } from '@api/exploration';
import { $activeSocialPlatform } from '@models/activeSocialPlatform';

type TLanguageByPlatform = Partial<Record<SmaPlatform, TLanguage[]>>;
const INIT_LANGUAGE: TLanguageByPlatform = {
  [SmaPlatform.Youtube]: [],
  [SmaPlatform.Instagram]: [],
  [SmaPlatform.Tiktok]: [],
};

/*
 * Стор языков
 */
export const $languagesStore = createStore<TLanguageByPlatform>(INIT_LANGUAGE);

/*
 * Получаем языки
 */
export const getLanguagesFx = createEffect((platform?: SmaPlatform) => {
  const activePlatform =
    platform || $activeSocialPlatform.getState()?.platform || SmaPlatform.Youtube;
  const languages = $languagesStore.getState();

  if (!languages[activePlatform]?.length) {
    return explorationApiService.getLanguages(activePlatform);
  }

  return languages[activePlatform];
});

$languagesStore.on(getLanguagesFx.done, (state, payload) => {
  const activePlatform = $activeSocialPlatform.getState()?.platform || SmaPlatform.Youtube;

  return {
    ...state,
    [activePlatform]: payload.result,
  };
});
