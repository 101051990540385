import cn from 'clsx';
import { FC } from 'react';
import mainLoader from '@uikit/assets/images/mainLoader.svg';
import smallLoader from '@uikit/assets/images/smallLoader.svg';
import { LOADER_POSITION, LOADER_SIZE, LOADER_TYPE } from './Loader.constants';
import styles from './Loader.module.scss';

type TLoaderProps = {
  type?: LOADER_TYPE;
  size?: LOADER_SIZE;
  position?: LOADER_POSITION;
  className?: string;
};

const LOADER_SIZE_MAP = {
  [LOADER_SIZE.M]: styles.m,
  [LOADER_SIZE.S]: styles.s,
  [LOADER_SIZE.XS]: styles.xs,
};

export const Loader: FC<TLoaderProps> = ({
  type = LOADER_TYPE.MAIN,
  size = LOADER_SIZE.M,
  position = LOADER_POSITION.ABSOLUTE,
  className,
}) => {
  if (type === LOADER_TYPE.ADDITIONAL) {
    return (
      <img
        src={smallLoader}
        className={cn(styles.smallLoader, className, LOADER_SIZE_MAP[size])}
        alt="loader"
      />
    );
  }

  return (
    <div
      className={cn(styles.overlay, className, [
        { [styles.localOverlay]: position === LOADER_POSITION.RELATIVE },
      ])}
    >
      <div
        className={cn(styles.content, [
          { [styles.localContent]: position === LOADER_POSITION.RELATIVE },
        ])}
      >
        <img
          className={cn(styles.loader, LOADER_SIZE_MAP[size])}
          src={mainLoader}
          alt="loader"
        />
      </div>
    </div>
  );
};
