/*
 * Popup component.
 */
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { FC, RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ErrorBoundary } from '@uikit/components/ErrorBoundary';
import { Portal, usePortal } from '@uikit/components/Portal';
import { noop } from '@uikit/helpers/common.helpers';
import { useClickAway } from '@uikit/hooks/useClickAway.hook';
import style from './Popup.module.scss';
import { PopupEvents, PopupPositions, PopupStyles } from './popup.enum';
import { PopupProvider } from './popup.provider';
import { PopupService, createPopupService } from './popup.service';
import { PopupStore, createPopupStore } from './popup.store';
import { TPopup, TPopupAPI, TPopupProps } from './popup.types';

export const Popup: FC<TPopupProps> = observer<TPopupProps>(
  (
    {
      content,
      position = PopupPositions.TopRight,
      event = PopupEvents.Hover,
      width,
      offset = 8,
      showDuration,
      showDelay,
      styles = PopupStyles.Default,
      disable = false,
      withExternalControl = false,
      onOpen = noop,
      onClose = noop,
      className,
      popupClassName,
      showClassName,
      children,
    },
    ref: RefObject<TPopup>
  ) => {
    const parentPortal = usePortal();
    const anchorRef = useRef<HTMLDivElement>(null);
    const popupRef: RefObject<TPopup> = useClickAway(
      () => service.hidePopup(), // eslint-disable-line @typescript-eslint/no-use-before-define
      [anchorRef],
      !!parentPortal
    );
    const [store] = useState<PopupStore>(() =>
      createPopupStore(offset, position, showDuration, withExternalControl)
    );
    const [service] = useState<PopupService>(() => createPopupService(store, popupRef, anchorRef));

    useEffect(() => {
      service.setEventType(event);
    }, [event]);

    useEffect(() => {
      store.setIsDisabled(disable);
      if (disable) service.hidePopup();
    }, [disable]);

    useEffect(() => {
      service.onOpen(onOpen);
      service.onClose(onClose);
    }, [onOpen, onClose]);

    /*
     * Making the component's public API.
     */
    useImperativeHandle<unknown, TPopupAPI>(
      ref,
      () => ({
        toggle: service.toggle,
        open: service.showPopup,
        close: service.hidePopup,
      }),
      [ref, service]
    );

    useEffect(() => {
      document.addEventListener('scroll', service.hidePopup);
      return () => document.removeEventListener('scroll', service.hidePopup);
    }, []);

    /*
     * Часть flow отображения popup. Подробнее в описании needShow.
     */
    useEffect(() => {
      if (store.needShow && !disable) {
        service.toggle();
      }
    }, [store.needShow]);

    return (
      <PopupProvider
        value={{
          popupService: service,
          popupStore: store,
        }}
      >
        <div
          ref={anchorRef}
          className={className}
          onClick={service.toggleOnClick}
          onPointerEnter={service.onMouseEnter}
          onPointerLeave={service.onMouseLeave}
        >
          {children}
        </div>
        {!disable && store.needShow && (
          <Portal>
            <div
              ref={popupRef}
              style={{ width, animationDelay: `${showDelay}ms` }}
              className={cn(
                style.popup,
                style[`popup_${styles}`],
                store.isShow && style.popup_show,
                store.isShow && showClassName,
                store.isShowByHover && style.popup_withHover,
                popupClassName
              )}
            >
              <ErrorBoundary layerName="popup">{content}</ErrorBoundary>
            </div>
          </Portal>
        )}
      </PopupProvider>
    );
  },
  { forwardRef: true }
);
