/*
 * Switcher (toggler) component.
 */
import cn from 'clsx';
import { ChangeEvent, FC, useState } from 'react';
import { FieldInputProps } from 'react-final-form';
import { useSwitcherGroup } from '@uikit/components/SwitcherGroup';
import { UnderlineTooltip } from '@uikit/components/UnderlineTooltip';
import styles from './Switcher.module.scss';

export enum SWITCHER_POSITION {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

type TSwitcherProps = {
  checked?: boolean; // Is required for use without SwitcherGroup!!!
  onChange?: (value: unknown) => void; // Is required for use without SwitcherGroup!!!
  id?: string; // Is required for use with SwitcherGroup!!!
  positiveValue?: unknown;
  negativeValue?: unknown;
  className?: string;
  position?: SWITCHER_POSITION;
  input?: FieldInputProps<string>;
  value?: string;
  name?: string;
  error?: string;
  underlineTooltip?: string;
  label?: string | JSX.Element;
  descriptionText?: string;
};

// TODO: Использовать useId из React 18 после его внедрения.
export const Switcher: FC<TSwitcherProps> = ({
  checked,
  positiveValue = true,
  negativeValue = false,
  onChange,
  id,
  className,
  position = SWITCHER_POSITION.LEFT,
  input,
  value,
  name,
  error,
  underlineTooltip,
  label,
  descriptionText,
}) => {
  const [inputId] = useState(Math.random().toString()); // не используй id из props, так как возможны кейсы их совпадения в разных switcherGroup
  /*
   * Switcher может работать как в составе группы, будучи обёрнутым в SwitcherGroup, так и без неё, автономно.
   * Если обнаружена обёртка SwitcherGroup - признак checked и колбэк onChange используется из группы, иначе из props.
   */
  const switcherGroup = useSwitcherGroup();
  const isChecked = switcherGroup
    ? switcherGroup.switcherGroupValue[id as string] === positiveValue
    : checked === positiveValue;

  const onSwitch = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.checked ? positiveValue : negativeValue;
    if (switcherGroup) {
      switcherGroup.onChangeSwitcherGroup(id as string, newValue);
      return;
    }

    onChange && onChange(e);
  };

  return (
    <>
      <label
        className={cn(styles.switcher, className)}
        htmlFor={inputId}
      >
        <div
          className={cn(
            styles.switcher__container,
            position === SWITCHER_POSITION.RIGHT && styles.switcher__rightContent
          )}
        >
          <input
            {...input}
            type="checkbox"
            id={inputId}
            checked={isChecked}
            onChange={onSwitch}
            value={value}
            name={name}
          />
          <span
            className={cn(
              styles.switcher__label,
              styles.switcher__leftContentMargin,
              position === SWITCHER_POSITION.RIGHT && styles.switcher__rightContentMargin
            )}
          >
            {underlineTooltip ? (
              <UnderlineTooltip
                className={styles.switcher__labelTooltip}
                tooltipText={underlineTooltip}
              >
                <div>{label}</div>
                <div className={styles.switcher__description}>{descriptionText}</div>
              </UnderlineTooltip>
            ) : (
              <>
                <div>{label}</div>
                <div className={styles.switcher__description}>{descriptionText}</div>
              </>
            )}
          </span>
        </div>
      </label>
      {error && <div className={styles.switcher__error}>{error}</div>}
    </>
  );
};
