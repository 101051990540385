// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".legalEntityCard__JBW8M{display:flex;justify-content:center;align-items:center;width:280px;height:360px;border:1px solid var(--outline);border-radius:var(--round8)}", "",{"version":3,"sources":["webpack://./pages/WorkspaceSettings/components/LegalEntity/components/LegalEntityCard/LegalEntityCard.module.scss"],"names":[],"mappings":"AAEA,wBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,YAAA,CACA,+BAAA,CACA,2BAAA","sourcesContent":["@import '../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.legalEntityCard {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 280px;\n  height: 360px;\n  border: 1px solid var(--outline);\n  border-radius: var(--round8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legalEntityCard": "legalEntityCard__JBW8M"
};
export default ___CSS_LOADER_EXPORT___;
