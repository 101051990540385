/*
 * Сервис разграничения доступа пользователя к функционалу подписок.
 */
import { UserAccessService, userAccessService } from './userAccess.service';
import { TUsetRights } from './userAccess.service.types';

export class SubscriptionUserAccessService {
  private get userRights(): TUsetRights {
    return this.userAccess.userRights;
  }

  /*
   * Может ли пользователь работать с подпиской.
   */
  public get isAvailable(): boolean {
    return this.userAccess.hasSubscriptionRightGroup;
  }

  /*
   * Может ли пользователь создать подписку.
   */
  public get createSubscription(): boolean {
    return Boolean(this.userRights.CreateSubscription);
  }

  /*
   * Может ли пользователь возобновить подписку.
   */
  public get resumeSubscription(): boolean {
    return Boolean(this.userRights.ResumeSubscription);
  }

  /*
   * Может ли пользователь обновить данные для выставления счета.
   */
  public get updateBillingDetails(): boolean {
    return Boolean(this.userRights.UpdateBillingDetails);
  }

  /*
   * Может ли пользователь отменить подписку.
   */
  public get cancelSubscription(): boolean {
    return Boolean(this.userRights.CancelSubscription);
  }

  /*
   * Может ли пользователь обновить количество рабочих мест в подписке.
   */
  public get updateSubscriptionSeats(): boolean {
    return Boolean(this.userRights.UpdateSubscriptionSeats);
  }

  /*
   * Может ли пользователь получить историю выставленных счетов.
   */
  public get getInvoiceHistory(): boolean {
    return Boolean(this.userRights.GetInvoiceHistory);
  }

  /*
   * Может ли пользователь получить полную информацию о текущей подписке.
   */
  public get getFullSubscriptionInfo(): boolean {
    return Boolean(this.userRights.GetFullSubscriptionInfo);
  }

  constructor(private readonly userAccess: UserAccessService) {}
}

const createSubscriptionUserAccessService = (userAccess: UserAccessService) =>
  new SubscriptionUserAccessService(userAccess);

export const subscriptionUserAccessService = createSubscriptionUserAccessService(userAccessService);
