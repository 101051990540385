/*
 * Утилиты компонента сообщения в чате.
 */
import cn from 'clsx';
import {
  IGetUserMessagesResponseDto,
  IPersonMessageStatusDto,
  MessageStatus,
} from '@hypetrainCommon';
import i18n from '@services/i18n';
import { TUsersListItem } from '@components/UsersList';
import styles from './ChatMessage.module.scss';

const SEND_STATUS_CLASS_NAME_MAP: Record<MessageStatus, string> = {
  [MessageStatus.sending]: styles.chatMessage_sending,
  [MessageStatus.sent]: styles.chatMessage_sent,
  [MessageStatus.read]: styles.chatMessage_read,
  [MessageStatus.error]: styles.chatMessage_error,
};

const MESSAGE_STATUS_NAME_MAP: Record<MessageStatus, string> = {
  [MessageStatus.read]: i18n.t('readStatus'),
  [MessageStatus.sent]: i18n.t('sentStatus'),
  [MessageStatus.sending]: i18n.t('sendingStatus'),
  [MessageStatus.error]: i18n.t('errorStatus'),
};

/*
 * Преобразует список получаетей сообщения в список пользователей системы для отображения в <UsersList/>
 */
export function getUsersListFromRecipients(
  recipients: IPersonMessageStatusDto[]
): TUsersListItem[] {
  return recipients?.map((recipient) => ({
    id: recipient?.person?.id || '',
    name: recipient?.person?.name || '',
    avatarClassName: cn(
      styles.chatMessage__recipientAvatar,
      SEND_STATUS_CLASS_NAME_MAP[recipient?.status]
    ),
  }));
}

/*
 * Возвращает имя класса для сообщения в зависимости от его статуса.
 */
export function getMessageStatusClassName(status: MessageStatus): string {
  return SEND_STATUS_CLASS_NAME_MAP[status];
}

/*
 * Возвращает локализованное имя статуса сообщения.
 */
export function getMessageStatusName(status: MessageStatus): string {
  return `${MESSAGE_STATUS_NAME_MAP[status]}.`;
}

/*
 * Возвращает признак наличия получателей, при отправке сообщения которым произошла ошибка.
 */
export function isMessageHasFailedSends(message: IGetUserMessagesResponseDto): boolean {
  return message?.recipients?.some((recipient) => recipient?.status === MessageStatus.error);
}
