/*
 * Range filter component
 */
import { FC, KeyboardEvent, memo, useEffect, useRef, useState } from 'react';
import { Filter, TFilterConfig } from '@uikit/components/Filter';
import {
  RANGE_TYPES,
  TRangeConfig,
  TRangeValue,
} from '@uikit/components/Filter/components/RangeFilter/RangeFilter.types';
import { RangeInput } from '@uikit/components/Filter/components/RangeFilter/components/RangeInput';
import { RangeSelect } from '@uikit/components/Filter/components/RangeFilter/components/RangeSelect';
import {
  formatRangeFromDTO,
  formatRangeToDTO,
  prepareRangeViewValue,
} from '@uikit/components/Filter/components/RangeFilter/rangeFilter.utils';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { TPopupAPI } from '@uikit/components/Popup';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';

type TRangeFilterProps = {
  config: TFilterConfig<TRangeConfig>;
  onApply: (value: TFilter) => void;
  applyOnClose: boolean;
};

export const RangeFilter: FC<TRangeFilterProps> = memo(({ config, onApply, applyOnClose }) => {
  const { t } = useUiKitTranslation();

  const [filterValue, setFilterValue] = useState<TFilter>(
    formatRangeFromDTO(config.filterProps.type, config.filterValue)
  );
  const [error, setError] = useState<string>();
  const popupRef = useRef<TPopupAPI>(null);

  useEffect(() => {
    setFilterValue(formatRangeFromDTO(config.filterProps.type, config.filterValue));
  }, [config.filterValue]);

  const onApplyHandler = (value: TFilter, needToggle?: boolean) => {
    if (value.max === 0) {
      setError(t('rangeFilter.zeroInToFieldError'));
      return false;
    }
    if (value.min && value.max && Number(value.min) > Number(value.max)) {
      setError(t('rangeFilter.minimumError'));
      return false;
    }
    needToggle && (popupRef.current as TPopupAPI).toggle();
    onApply(formatRangeToDTO(config.filterProps.type, value));
  };

  const onClearCallback = () => {
    setFilterValue(config.filterInitialValue);
    onApply(formatRangeToDTO(config.filterProps.type, config.filterInitialValue));
  };

  const onChangeHandler = (rangeValue: TRangeValue): void => {
    setFilterValue({
      ...config.filterValue,
      min: rangeValue.from,
      max: rangeValue.to,
    });
    setError(undefined);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      onApplyHandler(filterValue, true);
    }
  };

  return (
    <Filter
      outerRef={popupRef}
      config={config}
      viewValue={prepareRangeViewValue(
        formatRangeFromDTO(config.filterProps.type, config.filterValue),
        config.filterProps.type
      )}
      onClear={onClearCallback}
      onApply={() => onApplyHandler(filterValue)}
      applyOnClose={applyOnClose}
    >
      {config.filterProps.type === RANGE_TYPES.SELECT ? (
        <RangeSelect
          config={config.filterProps}
          onChange={onChangeHandler}
          value={{
            from: filterValue.min,
            to: filterValue.max,
          }}
          error={error}
        />
      ) : (
        <RangeInput
          config={config.filterProps}
          onChange={onChangeHandler}
          value={{
            from: filterValue.min,
            to: filterValue.max,
          }}
          onKeyDown={onKeyDown}
          error={error}
        />
      )}
    </Filter>
  );
});
