/*
 *   Модалка для добавления legacy entity
 */
import { FormApi } from 'final-form';
import { FC, useState } from 'react';
import { ILegalEntityDto, ILegalEntityInfoResponseDto } from '@hypetrainCommon';
import { BorderedScrollableContainer } from '@uikit/components/BorderedScrollableContainer';
import { Dialog } from '@uikit/components/Dialog';
import { TLegalEntitySchema } from '../../LegalEntity.types';
import { LegalEntityForm } from '../LegalEntityForm';
import styles from './LegalEntityDialog.module.scss';

type TLegalEntityModalProps = {
  legalEntity?: ILegalEntityInfoResponseDto | ILegalEntityDto;
  action: (values: TLegalEntitySchema) => Promise<Record<keyof TLegalEntitySchema, string> | null>;
  buttonText: string;
  title: string;
};
export const LegalEntityDialog: FC<TLegalEntityModalProps> = ({
  legalEntity,
  buttonText,
  action,
  title,
}) => {
  const [form, setForm] = useState<FormApi<ILegalEntityInfoResponseDto | ILegalEntityDto>>();

  const onSubmit = () => {
    form?.submit();
  };

  return (
    <Dialog
      title={title}
      minHeight="auto"
      buttons={[
        {
          text: buttonText,
          handler: onSubmit,
        },
      ]}
    >
      <BorderedScrollableContainer className={styles.legalEntityDialog}>
        <LegalEntityForm
          legalEntity={legalEntity}
          onChangeForm={setForm}
          submitFn={action}
        />
      </BorderedScrollableContainer>
    </Dialog>
  );
};
