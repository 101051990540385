import _flatten from 'lodash/flatten';
import _identity from 'lodash/identity';
import _pickBy from 'lodash/pickBy';
import { TCheckboxGroupValues } from '@uikit/components/CheckboxGroup';

export const ageChipFormatter = (values: TCheckboxGroupValues, options: TCheckboxGroupValues) => {
  const ageKeys = Object.keys(_pickBy(values, _identity)).reverse();
  const convertedAges = ageKeys.map((age) => age.split('–'));
  const numbersArray = _flatten(convertedAges).map((n) => Number(n.split('+').join('')));

  const max = Math.max.apply(null, numbersArray);
  const min = Math.min.apply(null, numbersArray);

  const optionsKeys = Object.keys(options);
  const maxOption = optionsKeys[optionsKeys.length - 1];
  const maxNumber = +maxOption.replace('+', '');

  return { min, max: max === maxNumber ? maxOption : max, numberMax: max };
};
