import cn from 'clsx';
import { FC } from 'react';
import { BUTTON_SIZE, Button } from '@uikit/components/Buttons';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { noop } from '@helpers/common.helpers';
import styles from './Notification.module.scss';
import {
  NOTIFICATION_TYPES,
  NOTIFICATION_TYPES_TO_ICON_TYPES,
  TNotificationProps,
} from './notification.types';

export const Notification: FC<TNotificationProps> = ({
  type = NOTIFICATION_TYPES.INFO,
  caption,
  description,
  onClose,
  actions,
  className,
}) => (
  <div
    className={cn(styles.notificationContainer, className)}
    onClick={onClose && !actions?.length ? onClose : noop}
  >
    {type !== NOTIFICATION_TYPES.INFO && (
      <Icon
        type={NOTIFICATION_TYPES_TO_ICON_TYPES[type]}
        size={20}
        className={cn(
          styles.notificationContainer__icon,
          type === NOTIFICATION_TYPES.ERROR && styles.notificationContainer__icon_error,
          type === NOTIFICATION_TYPES.SUCCESSFUL && styles.notificationContainer__icon_successful
        )}
      />
    )}
    <div className={styles.notificationContainer__textContainer}>
      <div className={styles.notificationContainer__caption}>{caption}</div>
      {description && (
        <div className={styles.notificationContainer__description}>{description}</div>
      )}
    </div>
    {!!actions?.length &&
      actions.map((action) => (
        <Button
          key={action.text}
          size={BUTTON_SIZE.XS}
          className={styles.notificationContainer__button}
          onClick={action.onClick}
          {...action.button}
        >
          {action.text}
        </Button>
      ))}
    {onClose && !!actions?.length && (
      <Icon
        type={ICON_TYPES.CLOSE_ICON}
        onClick={onClose}
        style={ICON_STYLES.SECONDARY}
        size={12}
        className={styles.notificationContainer__crossIcon}
      />
    )}
  </div>
);
