// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectRowCheckboxButton__gnErN{margin:unset;width:56px !important}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Table/components/columns/SelectRowCheckbox/SelectRowCheckbox.module.scss"],"names":[],"mappings":"AAEA,gCACE,YAAA,CACA,qBAAA","sourcesContent":["@import '../../../../../assets/styles/ui_kit_mixins';\n\n.selectRowCheckboxButton {\n  margin: unset;\n  width: 56px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectRowCheckboxButton": "selectRowCheckboxButton__gnErN"
};
export default ___CSS_LOADER_EXPORT___;
