/*
 * Simple table for several columns (by standard without pagination, pagination by pages is possible)
 */
import cn from 'clsx';
import { FC, memo } from 'react';
import { useExpanded, useFlexLayout, useTable } from 'react-table';
import { NothingFound } from '@uikit/components/NothingFound';
import { TNothingFoundProps } from '@uikit/components/NothingFound/NothingFound.component';
import { TBody } from '@uikit/components/Table/components/TBody';
import { TFooter } from '@uikit/components/Table/components/TFooter';
import { THead } from '@uikit/components/Table/components/THead';
import styles from './Table.module.scss';
import { TTableRow } from './table.types';

type TTableComponentNewProps = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  columns: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any;
  isFooter?: boolean;
  className?: string;
  emptyTable?: JSX.Element;
  wasFiltered?: boolean;
  isLoading?: boolean;
  nothingFoundOptions?: TNothingFoundProps;
  showHead?: boolean;
  infoFirstRow?: string; // текст информационной строки таблицы
  getSubRows?: (originalRow: object, relativeIndex: number) => object[];
};

export const Table: FC<TTableComponentNewProps> = memo(
  ({
    columns,
    data = [],
    isFooter,
    className,
    emptyTable,
    wasFiltered,
    isLoading,
    nothingFoundOptions,
    showHead = true,
    infoFirstRow,
    getSubRows,
  }) => {
    const tablePlagins = getSubRows ? [useFlexLayout, useExpanded] : [useFlexLayout];
    const { getTableProps, headerGroups, rows, prepareRow, footerGroups, getTableBodyProps } =
      useTable(
        {
          columns,
          data,
          getSubRows,
        },
        ...tablePlagins
      );

    if (isLoading) return null;

    return (
      <>
        <table
          {...getTableProps()}
          className={cn(styles.table, [className])}
        >
          {showHead && <THead headerGroups={headerGroups} />}
          {infoFirstRow && (
            <tbody>
              <tr className={styles.infoTip}>
                <td className={styles.infoTip__textContainer}>
                  <h4 className={styles.infoTip__text}>{infoFirstRow}</h4>
                </td>
              </tr>
            </tbody>
          )}

          <TBody
            prepareRow={prepareRow}
            rows={rows as TTableRow[]}
            {...getTableBodyProps()}
          />
          {/*
           * TODO Is isFooter needed?
           */}
          {isFooter && <TFooter footerGroups={footerGroups} />}
        </table>
        {!rows.length && !wasFiltered && emptyTable}
        {!rows.length && wasFiltered && <NothingFound {...nothingFoundOptions} />}
      </>
    );
  }
);
