// проверяем есть ли группа в списке без типизации
// Тайпгард для проверки типа группы селекта
import { TSelectGroupItem, TSelectItem } from './SelectField.types';

export const isGroupSelectItem = (type: TSelectItem[]): type is TSelectGroupItem[] =>
  (type as TSelectGroupItem[])[0]?.options !== undefined &&
  (type as TSelectGroupItem[])[0]?.label !== undefined;

// Проверяем является ли это группой
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const isOptionsGroup = (selectedOptions: any) => !!isGroupSelectItem(selectedOptions);
