/*
 * Global dialog service.
 */
import { nanoid } from 'nanoid';
import { TDialogAPI } from '@uikit/components/Dialog';
import { DialogStore, dialogStore, TDialogConfig } from './dialog.store';
import { TOpenDialogConfig } from './dialog.types';

export class DialogService {
  constructor(private readonly store: DialogStore) {}

  /*
   * Open dialog.
   */
  public open<D = never, P = Record<never, never>, R = never>(
    config: TOpenDialogConfig<P, D, R>
  ): Promise<D> {
    const id = nanoid();

    const dialogResult = new Promise<D>((resolve: (data: D) => void, reject: (data: R) => void) => {
      const props = {
        ...(config.props || ({} as P)),
        dialog: this.makeDialogAPI<D, R>(id, resolve, reject),
      };

      const dialogConfig: TDialogConfig<P, D, R> = {
        ...config,
        props,
        id,
      };
      this.store.add<P, D, R>(dialogConfig);
    });

    return dialogResult;
  }

  /*
   * Making dialog API for forwarding in dialog component.
   */
  private makeDialogAPI<D, R>(
    dialogId: string,
    resolveDialog: (data?: D) => void,
    rejectDialog: (data?: R) => void
  ): TDialogAPI<D, R> {
    const closeDialog = (): void => this.store.delete(dialogId);
    const resolve = (resolveData: D): void => {
      closeDialog();
      resolveDialog(resolveData);
    };
    const reject = (rejectData: R): void => {
      closeDialog();
      rejectDialog(rejectData);
    };

    return {
      resolve,
      reject,
    };
  }
}

export const dialogService = new DialogService(dialogStore);
