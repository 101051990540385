/* eslint-disable no-template-curly-in-string */

export default {
  // UI-KIT

  // BackButton
  backButton: 'Go back',

  // Modal Influencer
  subscribers: 'Subscribers',
  followers: 'Followers',
  price: 'Recommended price',
  male: 'Male',
  female: 'Female',
  metricER: 'ER',

  // Table
  dataAnalytics: 'Analytics',
  yearsOld: 'Y.O.',

  // Login
  newPassword: 'New password',
  countryLabel: 'Influencer location',

  // Search
  browse: 'Filter',
  uploadList: 'Upload list',
  searchLabel: 'Search',

  selection: {
    selection: 'Selection',
    instagram: {
      accountInfo: 'Account info',
      followers: 'Followers',
      er30: 'er, 30 posts',
      engagements: 'Engagements',
    },
  },
  // Upload
  uploadTitle: 'Upload your .csv file',

  dropzoneArea: 'Click “Select file” or drag & drop your .csv file here.',
  dropzoneButton: 'Select file',
  downloadDescLink: 'You can only upload your file after filling in our template.',
  linkTemplate: ' Download a template',

  modalUploadTitle: 'Uploading your file',
  notFoundFileTitle: 'channels-not-found',
  modalUploadText: 'It might take a few minutes. Don’t close \n this window.',
  foundCount: 'Channels found: {{count}} in our database',
  notFoundCount: 'Not found: {{count}}',
  uploadErrorTitle: 'Check your file',
  uploadErrorText:
    'Fill your data in according to our template. \n Once completed, upload your file again.',
  uploadErrorButton: 'Got it',
  // Selection
  add: 'ADD',
  clearChannels: 'Clear all',

  // Browse
  channel: 'channel',
  engagementRate: 'ER, 30 days',
  priceReach: 'Approximate price',
  audienceLocations: 'Audience location',
  audienceLocationsPlaceholder: 'Select countries...',
  categories: 'Categories',
  audienceGenders: 'Audience gender',
  audienceAges: 'Audience age',
  keywords: 'Keywords',
  hasVideosLabel: 'Updated 90 days ago',
  subscriberCount: 'Subscribers',
  games: 'Games',
  channelName: 'Channel name',
  apply: 'Apply',
  from: 'From',
  to: 'To',
  country: 'location',
  brandMentions: 'Brand mentions',
  brandMentionsPlaceholder: 'Enter brand...',
  brandMentionsTitle: 'Select a brand',

  audienceCountryLabel: 'Country',
  audienceLanguageLabel: 'Language',

  audienceCountriesInfoLabel: 'Location',
  gamesInfoLabel: 'Select games',
  gamesDescription: 'Discover YouTube channels that mentioned games in the last 90 days',
  brandsDescription: 'Discover YouTube channels that mentioned a brand in the last 90 days.',
  categoriesLabel: 'Channels specialize in',
  categoriesPlaceholder: 'Select categories...',
  atLeast: 'at least',
  audienceAgeIs: 'Select an age range',
  audienceGendersIs: 'Select a gender',
  enterKeywords: 'Enter keyword and press “Enter”',

  textFilterTitle: 'Videos include',
  enterGame: 'Enter game',
  audienceCountriesTitle: 'Audience location',
  audienceDemographics: 'Audience Demographics',
  maleVisitors: 'Views by males',
  femaleVisitors: 'Views by females',
  proceed: 'Go next: create profile ',
  selectAllModalButton: 'Select...',
  unselect: 'Unselect',
  subscribersModal: '{{count}} subscribers',

  performance: 'Performance',
  audienceModal: 'Audience',
  contentModal: 'Content',
  hours24: '24 hours',
  days3: '3 days',
  week1: '1 week',
  weeks2: '2 weeks',
  month1: '1 month',
  // Profile
  updatedTime: 'Updated {{time}}',
  noData: 'No data',
  monthlyDynamics: 'Dynamics',
  geography: 'Geography',
  openChannel: 'Open channel',
  demographics: 'Demographics',
  audienceTitle: 'Audience',
  show: 'Show:',
  lastPublishing: 'Published videos, last 3 months',
  viewsSortLabel: 'Views, 30 days',

  cr: 'CR',

  showMetricsAfter: 'Show metrics after:',
  intro: 'Intro',
  noteProfileAudience:
    'Note: The audience data below is estimated by the algorithm according to influencers statistics obtained from different sources.',
  metrics: 'Metrics',
  lastVideosProfile: 'Last 3 Months Videos',
  loadMoreVideo: 'Load {{value}} More…',
  notePerformance: 'Note: All the performance gains/losses are calculated on the last month basis.',
  medianChannel: 'Median for this channel',

  noMoreVideos90: 'No videos past 90 days',
  watchMoreOnYoutube: 'Watch more on YouTube',

  nothingFound: 'Nothing found',

  foundChannels: 'Channels found: {{total}}',

  priceProfile:
    'This is an approximate\nprice for a 60-90 second roll.\n\nWe calculate it by using these metrics:<ul><li>The audience location and demographics</li><li>The number of subscribers</li><li>The median views of channel videos. We measure them on the 30th day of publication.</li><li>The median engagement rate of channel videos. We measure it on the 30th day of publication.</li><li>The likes to views ratio of channel videos.</li></ul>\nConsider this approximate price a reference point while negotiating your deal. Please note that the actual one might be different. \n',
  switcherProfile:
    'These time periods mean that we measured the specific metric for separate Channel Videos at 5 particular time points when every Video had reached them starting from the upload date.',
  viewsAfterProfile:
    "The number of <bold>Median Views</bold> means that the regular (median) Channel Video has earned this number of Views over a certain time period since the Video's upload date.\n The <h4>Monthly Gain in Median Views</h4> indicates that the actual Median Views either have <increased>increased</increased> or <decreased>decreased</decreased> by a given percentage or have remained <h4>unchanged</h4> in relation to the average value of Median Views calculated for the previous calendar month.",
  erAfterProfile:
    "The value of <bold>Median Engagement Rate means</bold> that the regular (median) Channel Video has reached this ratio of interactions (total sum of the Likes, Dislikes & Comments) to the number of Views over a certain time period since the Video's upload date. The <h4>Monthly Gain in Engagement Rate</h4> indicates that the actual Median Engagement Rate either has <increased>increased</increased> or <decreased>decreased</decreased> by a given number of percentage points or has remained <h4>unchanged</h4> in relation to the average value of the Median Engagement Rate calculated for the previous calendar month.",
  crAfterProfile:
    "The value of <bold>Median Like-Dislike Ratio</bold> means that the regular (median) Channel Video has reached this ratio of gained Likes to the total sum of accumulated Likes & Dislikes over a certain time period since the Video's upload date. The <h4>Monthly Gain in Engagement Rate</h4> indicates that the actual Median Engagement Rate either has <increased>increased</increased> or <decreased>decreased</decreased> by a given number of percentage points or has remained <h4>unchanged</h4> in relation to the average value of the Median Engagement Rate calculated for the previous calendar month.",
  noSubsData: 'No data on subscribers quantity',
  noCategoriesData: 'No data on YouTube categories.',
  unknown: 'Unknown',
  noDataSocial: 'No data on social links',
  return: 'return',
  shareInfluencer: 'Share Influencer',

  // Errors
  'create-selected-sma--sma-already-selected': 'Check your "Selection"',
  'create-selected-sma--sma-already-selected_description':
    'Seems like you’ve already added the influencer.',
  'sign-in--wrong-credentials-provided': 'Incorrect password or email ',
  'sign-in--user-in-waiting-list': 'Please wait for approve your account',
  'change-password--wrong-credentials-provided': 'Check your current password and enter it again.',
  'refresh-tokens--token-expired': 'refresh-tokens--token-expired',
  'refresh-tokens--fingerprints-do-not-match': 'refresh-tokens--fingerprints-do-not-match',
  'refresh-tokens--tokens-do-not-match': 'refresh-tokens--tokens-do-not-match',
  'reset-password--token-expired': 'Reset link expired',
  'reset-password--token-expired_description': 'Let’s ask for a new one.',
  'sign-in--email-is-not-verified': 'To sign in, check your inbox and verify the email.',
  'verify-email--email-already-verified': 'verify-email--email-already-verified',
  'export-selection-to-google-sheet--selection-is-empty': 'We couldn’t export your channels',
  'export-selection-to-google-sheet--selection-is-empty_description':
    'To export them, add the channels to “Selection.”',
  'accept-invite-error--invalid-user': 'accept-invite-error--invalid-user',
  'google-sheets-exporting--provider-unavailable': 'google-sheets-exporting--provider-unavailable',
  'external-exploration--provider-unavailable': 'external-exploration--provider-unavailable',
  'user-mailing--provider-unavailable': 'user-mailing--provider-unavailable',
  'user-mailing--email-is-not-valid':
    ' Email domain doesn’t look right. Check your email for typos. Or create an account with another email.',
  'create-organization--organization-already-exists':
    'create-organization--organization-already-exists',
  'get-organization-invite--invite-not-found': 'get-organization-invite--invite-not-found',
  'get-refresh-session--session-not-found': 'get-refresh-session--session-not-found',
  'get-youtube-sma-error--smas-not-found': 'get-youtube-sma-error--smas-not-found',
  'get-youtube-sma-error--sma-id-not-found': 'get-youtube-sma-error--sma-id-not-found',
  'get-youtube-sma-error--sma-external-id-not-found':
    'get-youtube-sma-error--sma-external-id-not-found',
  'find-unknown-youtube-smas-list--list-not-found':
    'find-unknown-youtube-smas-list--list-not-found',
  'create-user--user-already-exists':
    'Account with that email already exists. Enter another email.',
  'get-user--email-not-found':
    'Account with that email doesn’t exist. Check your email for typos or create an account.',
  'get-user--id-not-found': 'get-user--id-not-found',
  'get-user--email-verification-token-not-found': 'get-user--email-verification-token-not-found',
  'get-user--password-reset-token-not-found': 'Reset link isn’t right.',
  'get-user--password-reset-token-not-found_description': 'Let’s ask for a new one.',
  'access-forbidden--wrong-rights-to-access-resource':
    'access-forbidden--wrong-rights-to-access-resource',
  'template-validation--nested-templates-not-allowed':
    'template-validation--nested-templates-not-allowed',
  'template-validation--invalid-nested-template-name':
    'template-validation--invalid-nested-template-name',
  'template-validation--invalid-variables': 'template-validation--invalid-variables',
  'template-validation--wrong-template-type-to-nest':
    'template-validation--wrong-template-type-to-nest',
  'get-chat--chat-not-found': 'get-chat--chat-not-found',
  'create-chat--chat-already-exists': 'create-chat--chat-already-exists',
  'get-chat-message-template--chat-message-template-not-found':
    'get-chat-message-template--chat-message-template-not-found',
  'get-chat-message-template--chat-message-template-not-available':
    'get-chat-message-template--chat-message-template-not-available',
  'create-chat-message--chat-message-already-exists':
    'create-chat-message--chat-message-already-exists',
  'create-chat-message-attachment--chat-message-attachment-already-exists':
    'create-chat-message-attachment--chat-message-attachment-already-exists',
  'get-chat-message-attachment--chat-message-attachment-not-found':
    'get-chat-message-attachment--chat-message-attachment-not-found',
  'get-chat-message-template-attachment--chat-message-template-attachment-not-found':
    'get-chat-message-template-attachment--chat-message-template-attachment-not-found',
  'create-chat-message-template-attachment--chat-message-template-attachment-already-exists':
    'create-chat-message-template-attachment--chat-message-template-attachment-already-exists',
  'create-chat-message-template--chat-message-template-already-exists':
    'create-chat-message-template--chat-message-template-already-exists',
  'get-chat-sma--chat-sma-not-found': 'get-chat-sma--chat-sma-not-found',
  'storage--provider-unavailable': 'Content isn’t available now. Try adding it later.',
  'create-organization-invite--user-already-invited':
    'create-organization-invite--user-already-invited',
  'create-role--role-already-exists': 'create-role--role-already-exists',
  'delete-user-role--cannot-delete-protected-role':
    'delete-user-role--cannot-delete-protected-role',
  'get-organization-user--organization-user-id-not-found':
    'get-organization-user--organization-user-id-not-found',
  'get-user-role--user-role-not-found': 'get-user-role--user-role-not-found',
  'update-user-role--cannot-update-protected-role':
    'update-user-role--cannot-update-protected-role',
  'create-user-extra-right--user-extra-right-already-exists':
    'create-user-extra-right--user-extra-right-already-exists',
  'create-user-right--user-right-already-exists': 'create-user-right--user-right-already-exists',
  'get-user-right--user-right-not-found': 'get-user-right--user-right-not-found',
  'create-selected-youtube-sma--youtube-sma-already-selected':
    'create-selected-youtube-sma--youtube-sma-already-selected',
  'get-chat-message--message-not-found': 'get-chat-message--message-not-found',
  'create-youtube-sma--youtube-sma-already-exists':
    'create-youtube-sma--youtube-sma-already-exists',
  'create-youtube-sma-statistics--youtube-sma-statistics-already-exists':
    'create-youtube-sma-statistics--youtube-sma-statistics-already-exists',
  'create-performance-parameters--performance-parameters-already-exists':
    'create-performance-parameters--performance-parameters-already-exists',
  'create-target-audience--target-audience-already-exists':
    'create-target-audience--target-audience-already-exists',
  'create-chat-message-event--chat-message-event-already-exists':
    'create-chat-message-event--chat-message-event-already-exists',
  'unauthorized--wrong-credentials': 'unauthorized--wrong-credentials',
  'create-chat-person--chat-person-already-exists':
    'create-chat-person--chat-person-already-exists',
  'get-chat-person--person-not-found': 'get-chat-person--person-not-found',
  'create-chat-person-contact--chat-person-contact-already-exists':
    'create-chat-person-contact--chat-person-contact-already-exists',
  'get-chat-person-contact--person-contact-not-found':
    'get-chat-person-contact--person-contact-not-found',
  'create-chat-unread-message--chat-unread-message-already-exists':
    'create-chat-unread-message--chat-unread-message-already-exists',
  'get-chat-unread-message--unread-message-not-found':
    'get-chat-unread-message--unread-message-not-found',
  'get-sma-error--sma-not-found': 'get-sma-error--sma-not-found',
  'create-sma--sma-already-exists': 'create-sma--sma-already-exists',
  'create-workspace--workspace-handler-already-exists':
    'Subdomain with that name already exists. Create a new one.',
  'check-person-contact--handler-not-unique': 'Contact with that email already exists.',
  'frontend-custom-slug--unknown-server-error': 'Something isn’t right.',
  'frontend-err-network': "You're offline",
  'frontend-err-network_description': 'To get back online, check your Internet connection',
  'personal-inbox-provider--account-not-found': 'Email disconnected',
  'personal-inbox-provider--account-not-found_description': 'Try reloading your web page.',
  'unknown-server-error': 'Something isn’t right. Try again later.',
  'reject-deal--contract-signed-error': 'Contract signed so you can’t reject this deal.',
  'create-deal--no-available-stage-state-to-move':
    'Deal rejected. So you can’t do any other actions or go to the next stage.',
  'campaign-deal-promo-content--incompatible-promo-content-type ':
    'Promo content incompatible promo content type ',
  'campaign-deal-promo-content--incompatible-promo-content-type':
    'Content format from the link doesn’t match the one includedin your contract.',
  required: 'Required',

  // Login
  loginScreen: {
    loginMainTitle: 'Welcome back',
    loginSecondaryTitle:
      'To sign in to the simulator, go to \nthe platform and use your assignment link',
    loginLinkTitle: 'No account yet? ',
    loginDirectionTitle: 'Create a new one',
    emailForLogin: 'Email',
    password: 'Password',
    forgotPassword: 'Forgot your password?',
    rememberMe: 'Keep me signed in',
    login: 'Sign in',
    logOut: 'Sign out',
    noAccessTitle: 'Seems like you can’t access this workspace',
    noAccessDescription:
      'Your account is deactivated. Contact {{adminName}} to use “{{workspaceName}}” like before.',
    noAccessTitleMulti: 'Seems like you can’t access these workspaces',
    noAccessDescriptionMulti:
      'Your account is deactivated. Contact {{adminName}} to use {{workspaceName}} like before.',
    noSubscriptionDescriptionMulti:
      'Subscription has expired or payment is late. To access {{workspaceName}}, their admins should renew our subscription.',
    noSubscriptionDescription:
      'Subscription has expired or payment is late. To access “{{workspaceName}}”, the admin should renew our subscription.',
    openAcademy: 'Go to platform',
    adminInfo: 'Let’s sign in to your admin account',
  },

  editWorkspace: {
    title: 'Let’s give your workspace a name',
    description:
      'We’ve merged your workspaces and stored their data in \na new one. Let’s give it a name and start inviting your \nteammates.',
    createName: 'Create workspace name',
    workspaceNameCreated: 'Workspace name created.',
  },

  email: 'Email',
  password: 'Password',
  forgotPassword: 'Forgot your password?',
  rememberMe: 'Keep me signed in',
  incorrectLoginOrPasswordError: 'Email or password don’t look right. Check them and try again.',
  emailNotFound: 'Account with that email doesn’t exist. Check your email for typos.',
  // Sign Up
  repeatPassword: 'Repeat a password',
  termsAgree:
    'By clicking “Sign up with Google” or “Sign me up”, I agree to Hypetrain’s <a href="https://hypetrain.io/terms"  target="_blank">Terms of service</a> and <a href="https://hypetrain.io/privacy" target="_blank">Privacy policy</a>.',
  createAccount: 'Sign me up',
  marketingConsent:
    'I want to receive Hypetrain’s emails about marketing best practices, feature updates, promotions, and news',
  welcomeToHypetrain: 'Welcome to Hypetrain',
  createYourAccount: 'Let’s get you signed up',
  haveAnAccount: 'Create your account for free or ',
  checkYourEmail: 'Check your email',
  emailTitle: 'We’ve sent a verification link to \n "{{email}}"',
  emailDescOne: 'Let’s verify that your email belongs to you.',
  emailDescTwo: 'Haven’t received our email? Check your spam folder.',
  emailVerified: 'Email verified.',
  emailVerified_description: 'Let’s sign in to your account.',

  invalidEmail: 'Enter your email following this format: name@example.com.',
  blacklistedEmail: 'Enter your business email.',

  signUp: {
    google: 'Open Gmail',
    icloud: 'Open iCloud',
    outlook: 'Open Outlook',
    yahoo: 'Open Yahoo',
    protonmail: 'Open Proton',
  },

  // Reset password
  resetMainTitle: 'Forgot your password?',
  resetSecondaryTitle:
    'Don’t worry, it happens to the best of us. Enter your email for login, and we’ll send you a password reset link.',
  sendInstructions: 'Send me reset link',
  checkEmailTitle: 'Check your email',
  passwordRecoverEmail: 'We’ve sent you the link to \n "{{email}}" to reset your password',
  checkSpam: 'Haven’t received our email? Check your spam folder.',
  passwordChanged: 'Password changed',
  returnProceed: 'Let’s sign in to your account with a new password',
  returnToMain: 'Take me to “Login”',
  confirmPassword: 'Repeat a new password',

  // person workspace creation
  needToCreate: 'Let’s create your profile for this \n organization to get started',
  joinCompany: 'Join "{{ organizationName }}"',
  createPerson: 'Create profile',
  youWereInvited: 'You’ve been invited to \n "{{ organizationName }}"',
  welcomeTo: 'Welcome to "{{ organizationName }}"',
  logInJoin: 'Let’s sign in to your account',

  // Registration via invite
  createYourAccountStarted: 'Let’s create your account',
  tellUsAboutYourSelf: 'What’s your full name?',
  whatsYourName: 'Let’s enter it for your personal account',
  accountCreated: 'Personal account created',
  nowProceed: 'Next, create your account for "{{ organizationName }}" where \n you’ve been invited',
  stepsBar: 'Step {{currentStep}} of {{stepsCount}}',

  // Navigation panel
  account: 'Account',
  addressBook: 'Contacts',
  campaigns: 'Campaigns',
  logOut: 'Sign out',
  changePassword: 'Change a password',
  workspaces: 'Select a workspace',
  navigationPanel: {
    academyMod: 'SiTo',
    testModeDescription: 'It’s a testing version. To get back\nto our platform, contact support.',
  },

  // Create workspace
  createTeamWorkspace: 'Create your workspace',
  detailsTourCompany: 'Let’s fill in some workspace info',
  companyInfoBackButton: 'Sign out',
  roleDetailsBackButton: 'Fill in some workspace info',
  roleDetailsNextButton: 'Start free 2-week trial',
  chosePlanBackButton: 'Create your profile',
  workspaceName: 'Workspace name',
  subdomainName: 'Subdomain name',
  organizationName: 'Organization name',
  organizationNameDescription:
    'Write your company name or something that your teammates will easily recognize',
  workspaceCreated: 'Workspace created.',
  sendInvites: 'Let’s invite your teammates to join \n "{{ organizationName }}"',
  sendInviteButton: 'Send invitations',
  skipInviteButton: 'Maybe later',
  tellUsMore: 'Let’s create your profile for "{{companyName}}"',
  detailsYourRole: 'Let’s fill in some details',
  contactEmail: 'Email for login',

  // Address Book
  influencers: 'Influencers',
  team: 'Company',
  addContact: 'Create contact',
  pressEnter: 'Enter an email and press "Enter"',
  emptyAddressBook: 'Let’s create your contacts',
  pressAddContact: 'Create your first contact in a few clicks. \n After, they’ll show up here. ',
  emailLabel: 'Contact email',
  fullName: 'Full name',
  jobTitle: 'Job title',
  nameColumn: 'FULL NAME',
  jobTitleColumn: 'JOB TITLE',
  roleColumn: 'ROLE',
  contactColumn: 'CONTACT EMAIL',
  smaColumn: 'SMA',
  setAsPrimary: 'Set as primary',
  invite: 'Invite teammates',
  sendInvitesAB: 'Send invitations',
  creatingNewContact: 'Create a contact',
  createContact: 'Create contact',
  maximumNumberOfMails: 'Enter up to {{maxEmails}} emails',
  editContact: 'Edit a contact',
  editMember: 'Edit a member',
  saveChanges: 'Save changes',
  deactivated: 'Deactivated',
  invited: 'Invited',
  admin: 'Admin',
  member: 'Member',
  representative: 'Representative',
  createWorkspace: 'Create workspace',
  invitedPerson: 'Invited teammate',
  contactPerson: 'Contact person',
  inviteTeammatesButton: 'Invite teammates',

  // Influencer Search
  er30: "er'30",
  views30: "views'30",

  // Campaigns
  createFirstCampaign: 'Let’s create your campaign',
  clickCreateCampaign:
    'Create your first campaign in a few clicks. Next, find influencers that fit your needs.',
  viewsByMonthFilterDescription: 'Specify the minimum and maximum number of video views',
  engagementRateFilterDescription: 'Specify the minimum and maximum engagement rate (%)',
  recommendedPriceFilterDescription: 'Specify the minimum and maximum deal price',
  influencersLocationDescription: 'Discover YouTube channels by their location',
  hasEmail: 'Has email',
  noContact: 'No email',
  applyFilters: 'Apply filters ({{count}})',
  clearAll: 'Clear all',
  haveContact: 'Has contact',
  haveSocialLinks: 'Has social links',
  influencersLocation: 'Influencer location',
  channeBasedIn: 'Location',
  viewsByMonth: 'Views, 30 days',
  withEmail: 'With email',
  withoutEmail: 'Without email',
  withSocialLinks: 'With social links',
  withoutSocialLinks: 'Without social links',
  influencersLocationPlaceholder: 'Select countries...',
  createCampaign: 'Create campaign',
  editCampaign: 'Edit a campaign',
  editCampaignSaveChanges: 'Save changes',
  campaignName: 'Campaign name',
  productName: 'Product name',
  brandName: 'Brand name',
  startDate: 'Start date',
  endDate: 'End date',
  assignee: 'Assignee',
  createNewCampaign: 'Create campaign',
  addToExistingCampaign: 'Add to campaign',
  addToCampaign: 'Add to campaign',
  searchCampaign: 'Enter campaign name',
  startAddChannelsToCampaign: 'Let’s start your workflow for \n this campaign',
  searchToFindChannelsCampaign:
    'First, discover influencers that fit your needs. After you add \n them to this campaign, you’ll go to the next stage.',
  searchChannels: 'Discover influencers',
  channelsSelected: 'Channels selected: {{count}}',
  accountsSelected: 'Accounts selected: {{count}}',
  filtering: 'Filters',
  sortingLabel: 'Sorts',
  addSorting: 'Add sorts',
  addFilters: 'Add filters',
  openChat: 'Open deal',
  targetAudienceShare: 'Target audience (%)',
  cpmCaplLock: 'CPM',
  minimumError: 'Enter the minimum first.',
  zeroInToFieldError: 'Enter 0 in the “From” field.',
  countriesFirstLabel: 'is',
  youtubeCountriesSecondLabel: 'of the channel audience total',
  instagramCountriesSecondLabel: 'of the account audience total ',
  keywordsDescription:
    'Discover YouTube channels by the keywords they use in their videos. You can enter up to 3 keywords per time.',
  categoriesDescription: 'Discover YouTube channels by their content topics',
  reset: 'Reset',
  campaignsFullName: 'Full name',
  addAndGoNext: 'Create and go next',
  campaignsRole: 'Role',
  campaignsContactDetails: 'Contact email',
  addContactToChannel: 'Create a contact for channel',
  addContactToChannelInstagram: 'Create a contact for account',
  addContactAttention: 'You can also create a contact in “Contacts.”',
  searchResults: 'Search results:',
  match: 'match',
  hasEmailTitle:
    'We have the contact email of \nthis influencer in our database. \n\nTo send a message: ' +
    '<ul >' +
    '  <li>Add this influencer to your \ncampaign</li>' +
    '  <li>Click “Open deal” near the \ninfluencer icon</li>' +
    '  <li>Click “Write message” in the \nCommunication tab</li>' +
    '  <li>Create a message and send it.</li>' +
    '</ul>' +
    '\nIf you need the contact email for \nsomething else, export it.',
  noContactTitle:
    'We don’t have the influencer’s \ncontact email. You can add it \nmanually by creating a contact or \nselecting it from “Contacts.”',

  // Group Actions
  summaryGroupAction: {
    clearAllSelected: 'CLEAR SELECTED',
    viewsTotal: 'views, all',
    erMedian: 'er, median',
    recPriceTotal: 'rec. price, all',
    cpmTotal: 'cpm, all',
    channelsAdded: 'Channels ADDED: {{count}}',
    accountsAdded: 'Accounts ADDED: {{count}}',
    influencers: 'influencers',
    accounts: 'accounts',
    saveTooltip: 'To save {{blogger}}, click\n “All” or “Add.”',
    save: 'Save {{blogger}}',
    exportTooltip: 'To export {{blogger}}, click “All” or “Add.”',
    export: 'Export {{blogger}}',
    proceedToCampaignTooltip: 'To add {{blogger}} to the campaign, click “Add.”',
    addToCampaign: 'Add to campaign',
    proceedToCampaign: 'Create or add to campaign',
    createCampaign: 'Create campaign',
  },
  // Browse

  emptyBrowseTitle: 'Discover {{platform}} across our database',
  youtubeChannels: 'YouTube channels',
  instagramAccounts: 'Instagram accounts',
  tikTokAccounts: 'TikTok accounts',
  emptyBrowseDescription: 'To find influencers that fit your campaign needs, apply filters.',

  // Selection
  totalSelectionChannels: 'All channels: {{formattedCount}}',
  totalSelectionAccounts: 'All accounts: {{formattedCount}}',
  channelInfo: 'channel info',
  countries: 'location',
  targetAudience: 'target audience',
  viewsMonth: 'views, 30 days',
  engagementRateColumn: 'er, 30 days',
  recPrice: 'rec. price',
  cpm: 'cpm',
  emptySelectionTitle: "Let's add your influencers",
  emptySelectionDescription:
    'First, find them on the “Filter” or “Search” tab. \n After you add one or more influencers, they’ll show up here.',
  addColumn: 'Add',
  all: 'All',
  selectAll: 'Add to “Selection”?',
  addChannels: 'You’re about to add channels ({{count}}) to “Selection.”',
  addAccounts: 'You’re about to add accounts ({{count}}) to “Selection.”',
  addToSelection: 'Add',

  // Communication
  communication: {
    communication: 'Communication',
    communicationTable: {
      lastMessage: 'Last message',
    },

    contacts: {
      addContactFromPopup: 'Create contact',
      selectFromAddressBook: 'Select from "Contacts"',
      noContactSelectTooltip: 'No contacts created yet.',
    },
    newMessage: 'New message',
    firstMessage: 'First message',

    lastMessage: {
      noContact: 'This channel doesn’t have a contact. To add it, open the deal.',
      noContactInstagram: 'This account doesn’t have a contact. To add it, open the deal.',
      noContactTikTok: 'This account doesn’t have a contact. To add it, open the deal.',
      attachedFiles: 'Attached files:',
    },
    letsWriteMessage: 'Let’s write a message',
    emptyMessageDescription:
      'After you write it, we’ll send your message as an email.\nWe’ll also store all replies and attachments here.',
    writeMessage: 'Write it manually',
    createMessageViaAiButton: 'Create it with AI',

    editMessage: {
      unableToUploadFile: '(We couldn’t upload your file. Try again later.)',
      renameYourFile: '(Rename your file and attach it again.)',
      filesUploadingWarning: 'After we upload all files, you can send your message.',
      collapseChatFooterButton: 'Cancel',
      sendMessage: 'Send message',
      sendMessageWithCount: 'Send message ({{count}})',
      all: 'All',
      sendTo: 'SEND TO:',
      subject: 'Subject',
      message: 'Message',
      autofill: 'Autofill:',

      campaignName: 'Campaign name',
      campaignBrandName: 'Brand name',
      campaignProductName: 'Product name',
      campaignStartDate: 'Campaign start date',
      campaignEndDate: 'Campaign end date',
      smaName: 'Channel name',

      subjectRequiredError: 'To continue, create your subject.',
      messageRequiredError: 'To continue, write your message.',
      supportKey: 'To use them, go to campaign details and fill your fields in.',
    },

    systemMessage: {
      personAdded: 'added to thread.',
    },
    massMailingPanel: {
      from: 'From:',
      to: 'To:',
      countChannel: ' {{count}} channels ',
      countAccounts: ' {{count}} accounts ',
      fromAllChannel: ' of {{count}} selected ',
      title: 'Send a message',
      channelWithSeveral: '{{count}} channels have several contacts',
      accountsWithSeveral: '{{count}} accounts have several contacts',
      toContinues: 'To continue, select the contacts you’d like to send a message.',
      selectCountPerson: 'Select contacts ({{count}})',
      tipWrapperToSend: 'You’re about to send a message to',
      countContacts: ' {{count}} contacts. ',
      selectContacts: 'Select contacts',
      createMessage: 'Reach out to influencers',
      close: 'Close',
    },
    massMailing: {
      messageNotSent: 'Message not sent to 1 influencer',
      messagesNotSent: 'Message not sent to {{failedMessages}} influencers',
      toSeeUnsent: 'To see the unsent message, go to “Communication.”',
      messagesSentTo: 'Message sent to {{channels}} influencers.',
      messageSentTo: 'Message sent to 1 influencer.',
    },
  },
  statementOfWork: {
    statementOfWork: 'Deal details',
  },

  gmail: 'Gmail',
  outlook: 'Outlook',
  other: 'Other...',
  fileTooBig: 'We couldn’t attach',
  fileTooBigDescription:
    'You can send up to 20 mb in attachments. Reduce your file size and attach them again.',
  fileTooBigCloseModal: 'Got it',
  removeChannel: 'Remove channel',
  removeAccount: 'Remove account',
  zeroContacts: 'Let’s add a contact to this channel',
  zeroContactsInstagram: 'Let’s add a contact to this account',
  zeroContactsDescription:
    'To start communication, create a contact or select them from "Contacts."',
  deleteChatMessage: 'Delete',
  resendChatMessage: 'Resend',
  resendAllChatMessage: 'Resend all',
  allMessageNotSent: 'Message not sent.',
  messageNotSent: 'Message not sent to recipients ({{ count }}). Send it again.',
  readStatus: 'Read',
  sentStatus: 'Sent',
  sendingStatus: 'Sending',
  errorStatus: 'Error',
  ownMessageAuthor: 'Me',
  writeMessage: 'Write message',
  createNewContact: 'Create contact',
  chooseFromAddressBook: 'Select from "Contacts"',
  contactAlready: 'Contact added to chat.',
  addContactDialogTitle: 'Select influencer’s contacts and add them to chat',
  contactsFound: 'Contacts found: {{count}}',
  noContactsFound: 'Not found any results',
  addToChat: 'Add to chat',
  addToChatCount: 'Add to chat ({{ count }})',
  emptyPopupParticipants: 'Create a contact or select them from “Contacts.”',
  titlePopupParticipants: 'DEAL PARTICIPANTS:',
  titleAddNewContact: 'Create a contact',
  resolveMailTitleDialog: 'Select contacts for channels',
  resolveMailTitleDialogInstagram: 'Select contacts for accounts',
  resolveMailButtonDialog: 'Done',
  autofill: 'Autofill:',
  sendMessage: 'Send message',

  campaignNameVariable: 'Campaign name',
  campaignBrandNameVariable: 'Brand name',
  campaignProductNameVariable: 'Product name',
  campaignDatesVariable: 'Campaign dates',
  smaNameVariable: 'Channel name',
  connectEmailButton: 'Connect email',
  advancedSettings: '+ Advanced settings',
  hotspot: 'Hotspot',
  port: 'Port',
  useTls: 'Use TLS',
  goBack: 'Go back',
  imapTooltipText:
    'To secure your email connection,\n use TLS (Transport Layer Security).\n Enter 993 port for IMAP.',
  smtpTooltipText:
    'To secure your email connection,\n use TLS (Transport Layer Security).\n Enter 465 port for SMTP.',
  smtpError:
    'We couldn’t connect your email to this workspace. \nCheck if you’ve entered the correct email or password.\n Or enter hotspot and port in “Advanced settings.”',
  smtpConnectError:
    'We couldn’t connect your email to this workspace. Check if you’ve entered the correct IMAP or SMTP settings.',
  enterEmail: 'Enter email',
  enterPassword: 'Enter email password',
  socialConnectError:
    'We couldn’t connect your email\nAllow Hypetrain access to your email provider or contact us via hello@hypetrain.io.',
  socialConnectSuccess: '{{email}} connected.',

  // Attach UIKIT
  download: 'Download',

  // Unsupported Page
  unsupportedTitle: 'We don’t support the mobile version of Hypetrain',
  unsupportedText: 'To find influencers and run campaigns, sign in from your computer.',
  unsupportedButton: 'Take me "Home"',

  // Navigation panel
  navigation: {
    discovery: 'Influencer discovery',
    campaigns: 'Campaigns',
    addressBook: 'Contacts',
    workspaceSettings: 'Workspace settings',
    payments: 'Payments',
    workspaceTooltip: 'Workspaces',
    accountTooltip: 'Manage account',
    support: 'Get help',
    account: 'Account',
    logOut: 'Sign out',
    changePassword: 'Change a password',
    workspaces: 'Select a workspace',
    helpCenter: {
      supportChat: 'Support chat',
      videoTutorials: 'Video tutorials',
      intercomHelpCenter: 'Help center',
      blog: 'Blog',
      termsOfService: 'Terms of service',
      privacyPolicy: 'Privacy policy',
      cookiePolicy: 'Cookie Policy',
    },
    addChannels: 'Add channels',
    addContact: 'Add accounts',
    campaignSettings: 'Go to campaign details',
  },

  virtualizeTable: {
    newMessage: 'New message',
  },
  influencerDiscovery: {
    search: {
      inputPlaceholder: 'Enter channel name, ID, or URL',
      instagramInputPlaceholder: 'Enter @handle or URL',
      tiktokInputPlaceholder: 'Enter @handle, URL, or account name',
      nothingFoundTitleYT: 'We couldn’t find any influencers',
      nothingFoundDescriptionYT: 'Try adding their YouTube channel’s URLs to our platform. ',
      nothingFoundButtonYT: 'Add YouTube channels',
      footerText: 'Haven’t found the influencers you need?',
      footerLinkText: 'Add YouTube channels',
    },
    influencerDiscoveryTitle: 'Influencer discovery {{campaignName}}',
    platforms: {
      youtube: 'YouTube',
      instagram: 'Instagram',
      tikTok: 'TikTok',
      choosePlatform: 'To find influencers, choose a platform',
      backButton: 'Choose a platform',
      backToCampaign: 'Back to campaign',
      addChannelsButton: 'Add channels',
      addChannelsTooltip: 'Add the channels you haven’t found \nto our database.',
    },
    filtering: {
      pendingModal: {
        title: 'Looking for influencers',
        descriptionOne: 'Preparing results...',
        descriptionTwo: 'Filtering database...',
        descriptionThree: 'Sorting database...',
        descriptionFour: 'Loading comments you’ve added to profiles...',
        descriptionFive: 'Preparing to show...',
        descriptionSix: 'A few moments left...',
      },
    },
  },
  CircleCounter: {
    thousand: 'k',
  },

  filters: {
    instagram: {
      followers: 'Followers',
      engagementRate: 'ER, 30 posts',
      engagementRateLabel: 'From',
      engagementRatePlaceholder: '>0%',
      engagementRateDescription: 'Select the engagement rate (%)',
      influencerLocation: 'Influencer location',
      locationInGroup: 'Location',
      influencerLocationDescription: 'Discover Instagram accounts by their location',

      locationLabel: 'Location',
      locationPlaceholder: 'Select countries...',

      influencerGenders: 'Influencer gender',
      genderRadioLabel: 'Select a gender',

      influencerLanguage: 'Influencer language',
      languageInGroup: 'Language',
      influencerLanguagePlaceholder: 'Select language...',
      influencerLanguageLabel: 'Language',

      hasEmail: 'Has email',
      withEmail: 'With email',
      withoutEmail: 'Without email',

      audienceLocations: 'Audience location',
      audienceLocationTooltipText: 'You can select up to 3 \ncountries per time.',

      keywords: 'Keyword',
      keywordsDescription:
        'Discover Instagram accounts by the keyword they use in their posts. You can enter 1 keyword per time.',
      keywordsLabel: 'Specify a keyword',
      keywordsPlaceholder: 'Enter keyword and click “Apply”',

      audienceGenders: 'Audience gender',
      genderInGroup: 'Gender',
      male: 'Male',
      female: 'Female',
      any: 'Any',
      neutral: 'Gender neutral',
      maleOrFemale: 'Male or female',
      neutralTooltip: 'Find Instagram accounts that haven’t specified \ntheir gender. ',
      maleOrFemaleTooltip: 'Find human influencers regardless \ntheir gender.',

      audienceAges: 'Audience age',
      agesInGroup: 'Age',
      audienceAgeLabel: 'Select an age range',

      categories: 'Categories',
      categoriesDescription: 'Discover Instagram accounts by their content topics',
      categoriesLabel: 'Accounts specialize in',
      categoriesPlaceholder: 'Select categories...',

      brandAffinity: 'Influencer brand affinity',
      brandAffinityLabel: 'Brand affinity',
      brandAffinityTooltipText:
        'We define it after analyzing \nposts for @mentions, \n#hashtags, captions, and \nlocation tags.',
      brandAffinityPlaceholder: 'Select brand...',

      replyStatus: 'Reply status',
      replied: 'Replied',
      notReplied: 'Not replied',

      haveSocialLinksTitle: 'Links',
      haveSocialLinks: 'Has social media links',
      platformsPlaceholder: 'Select platforms...',
      socialLinksDescription:
        'Discover Instagram accounts that have links to other social media platforms',
    },
    youtube: {
      replyStatus: 'Reply status',
      replied: 'Replied',
      notReplied: 'Not replied',

      haveSocialLinksTitle: 'Links',
      haveSocialLinks: 'Has social media links',
      platformsPlaceholder: 'Select platforms...',
      socialLinksDescription:
        'Discover YouTube channels that have links to other social media platforms',
    },
    tikTok: {
      followers: 'Followers',
      views: 'Views, 30 posts',
      viewsDescription: 'Specify the minimum and maximum number of video views',
      engagementRate: 'ER, 30 posts',
      engagementRateLabel: 'From',
      engagementRatePlaceholder: '>1%',
      engagementRateDescription: 'Select the engagement rate (%)',
      influencerLocation: 'Influencer location',
      locationInGroup: 'Location',
      influencerLocationDescription: 'Discover TikTok accounts by their location',

      locationLabel: 'Location',
      locationPlaceholder: 'Select countries...',

      influencerGenders: 'Influencer gender',
      neutral: 'Gender neutral',
      maleOrFemale: 'Male or female',
      neutralTooltip: 'Find TikTok accounts that haven’t specified \ntheir gender. ',
      maleOrFemaleTooltip: 'Find human influencers regardless \ntheir gender.',
      genderRadioLabel: 'Select a gender',

      influencerLanguage: 'Influencer language',
      languageInGroup: 'Language',
      influencerLanguagePlaceholder: 'Select language...',
      influencerLanguageLabel: 'Language',

      hasEmail: 'Has email',
      withEmail: 'With email',
      withoutEmail: 'Without email',

      audienceLocations: 'Audience location',
      audienceLocationTooltipText:
        'Select up to 3 audience \ncountries and specify their \nminimum percentage.',

      keywords: 'Keyword',
      keywordsDescription:
        'Discover TikTok accounts by the keyword they use in their posts. You can enter 1 keyword per time.',
      keywordsLabel: 'Specify a keyword',
      keywordsPlaceholder: 'Enter keyword and click “Apply”',

      mentions: 'Mentions',
      mentionsDescription: 'Discover TikTok accounts by the words they mention in their posts',
      mentionsLabel: 'Specify mentions',
      mentionsPlaceholder: 'Enter word and press “Enter”',

      audienceGenders: 'Audience gender',
      genderInGroup: 'Gender',
      male: 'Male',
      female: 'Female',
      any: 'Any',

      audienceAges: 'Audience age',
      agesInGroup: 'Age',
      audienceAgeLabel: 'Select an age range',

      replyStatus: 'Reply status',
      replied: 'Replied',
      notReplied: 'Not replied',

      haveSocialLinksTitle: 'Links',
      haveSocialLinks: 'Has social media links',
      platformsPlaceholder: 'Select platforms...',
      socialLinksDescription:
        'Discover TikTok accounts that have links to other social media platforms',
    },
  },

  sorts: {
    noAudienceFilterTooltip: 'To make it active, apply audience filters.',
    instagram: {
      followers: 'Followers',
      engagementRate: 'ER, 30 posts',
      newMessages: 'New messages',
      newMessagesLabel: 'New messages first',
    },
    youtube: {
      newMessages: 'New messages',
      newMessagesLabel: 'New messages first',
    },
    tikTok: {
      followers: 'Followers',
      engagementRate: 'ER, 30 posts',
      views: 'Views, 30 posts',
      newMessages: 'New messages',
      newMessagesLabel: 'New messages first',
    },
  },

  hideUnreadMessage:
    'New messages ({{unreadMessageCount}}) hidden. To view them, reset the filters you’ve applied.',

  search: {
    goToSelection: 'Go to “Selection”',
  },

  upload: {
    found: 'Channels found: {{count}}',
    upload: 'Upload a new list',
  },

  changePasswordForm: {
    changePassword: 'Change a password',
    changePasswordButtonLabel: 'Change password',
    confirmationPassword: 'Repeat a new password',
    newPassword: 'New password',
    currentPassword: 'Current password',
  },

  resetByEmailForm: {
    resetPasswordButtonLabel: 'Reset password',
    title: 'Reset a password',
    newPasswordRequired: 'Create your new password.',
    repeatNewPasswordRequired: 'Enter your new password.',
    subTitle: 'Let’s create a new one',
    confirmationPassword: 'Repeat a new password',
    password: 'New password',
  },
  editContactForm: {
    roleLabel: 'Role',
    jobTitleLabel: 'Job title',
    roleDescription: 'Enter a role, job title, or description',
    changeEmailPriorityHint: 'Every workspace member can send messages via new primary email.',
  },
  profile: {
    price: 'Approximate price',
    minPrice: 'from',
    maxPrice: 'to',

    metrics: {
      erLabel: 'ER, 30 posts',
      erTooltip:
        'Shows a median engagement rate for the last 30 posts. ER is a percent of average likes divided by followers.',
      comments: 'Comments, 30 posts',
      commentsTooltip:
        'Shows the average sum of \ncomments on the last 30 posts. \nA percent indicates the difference \nbetween the previous and current \nmonth.',
      followingsPerMonth: 'Followings',
      views30Posts: 'Views, 30 posts',
      followersPerMonth: 'Followers',
      likesPosts: 'Likes, 30 posts',
      followersTooltip:
        'The number of followers the account got in the current month. A percent shows the difference between the previous and current month.',
      followingsTooltip:
        'The number of accounts the influencer has followed in the current month. A percent shows the difference between the previous and current month.',
      views30PostsTooltip:
        'Shows the average sum of views on the last 30 posts. A percent indicates the difference between the previous and current month.',

      likesTooltip:
        'Shows the average sum of likes on the last 30 posts. A percent indicates the difference between the previous and current month.',
    },
    dynamics: {
      dynamics: 'Dynamics',
      description:
        'We show you the number of video views and channel subscribers for the last 6 months',
      views: 'Views',
      subscribers: 'Subscribers',
    },
    goBackTo: 'Go back to “{{name}}”',
    showMore: ' Read the full description',
    showLess: ' Show less',
    subcribers: 'Subcribers',
    qualityFollowers: 'Followers quality ',
    qualityFollowersText:
      'Shows a percent of credible followers. Accounts with a genuine audience have a followers’ quality of 80% or higher.',
    followers: 'Followers ',
    removeFromSelection: 'Remove',
    addToSelection: 'Add to “Selection”',
    addToCampaign: 'Add to campaign',
    removeFromCampaign: 'Remove',
    linkCopied: 'Profile link copied.',
    addAComment: 'Add a comment...',
    viewAllComments: 'View all comments...',
    perMonth: 'per month',

    brands: {
      brandAffinity: 'Brand affinity',
      audience: 'Audience',
      influencer: 'Influencer',
      titleTooltip:
        'We define it after analyzing \nposts for @mentions, \n#hashtags, captions, and \nlocation tags.',
      noData: 'No data yet',
      brandMentions: 'Brand mentions',
      brandCount: 'Brands, last 90 days: {{count}}',
      infoTooltip:
        'Mentions or a link to the brand in the channel’s video descriptions. Views and ER depend on the time point you’ve selected.',
      brandVideosCounter: 'Videos: {{count}}',
      noBrandsMentions: 'This influencer didn’t mention any brands yet',
    },

    navbar: {
      profile: 'Profile',
      content: 'Content',
      performance: 'Performance',
      dynamics: 'Dynamics',
      audience: 'Audience',
      lookalikes: 'Lookalikes',
      brand: 'Brand',
      popularPosts: 'Popular posts',
      updatedMinutesAgo: 'Updated {{minutes}} minutes ago',
      updatedAt: 'Updated at {{updateTime}}',
      updatedOn: 'Updated on {{updateDate}}',
    },

    content: {
      titleYoutube: 'Videos, last 3 months',
      titleInstagram: 'Content',
      titleTikTok: 'Popular posts',
      viewMoreOnYoutube: 'View the YouTube channel',
      viewMoreOnInstagram: 'View the Instagram account',
      viewMoreOnTikTok: 'View the TikTok account',
      timeSwitcherLabel: 'Select a time point',
      timeSwitcherTooltip:
        'To see how the channel video performs after publication, select a time point.',
      timeSwitcherTitle: 'Performance',

      likesLabel: 'Likes',
      commentsLabel: 'Comments',
      noMoreVideos90: 'No videos past 90 days',

      popular: 'Popular',
      sponsored: 'Sponsored',

      twentyFourHours: '24 hours',
      threeDays: '3 days',
      sevenDays: '7 days',
      fourteenDays: '14 days',
      thirtyDays: '30 days',

      noData: 'No content yet',
    },
    kpi: {
      performance: 'Performance',
      kpis: 'KPIs',
      weUseData: 'We use data from the last month to calculate performance gains/losses',
      views: 'Views',
      er: 'ER',
      afterOneDay: 'after 24 hours',
      afterThirdDays: 'after 3 days',
      afterSeventhDays: 'after 7 days',
      afterFourteenthDays: 'after 14 days',
      afterThirtiethDays: 'after 30 days',
      erInfo:
        'The median engagement rate of the channel video in a given period. Compared to the last month’s ER, actual ER may increase, decrease, or remain unchanged.',
      viewsInfo:
        'The median number of video views in a given period. Compared to the last month’s median views, actual views may increase, decrease, or remain unchanged.',
      barChart: {
        publishedVideos: 'Published videos, last 3 months',
        median: 'Median: {{count}}',
        views: 'Views',
        er: 'ER',
        allVideos: 'All videos: {{count}}',
        otherVideos: 'Other videos: {{count}}',
        engagementSpread: 'Engagements spread',
        dateShared: 'date shared',
        likes: 'likes',
        comments: 'comments',
        posts: 'posts',
        view: 'View',
        viewDetails: 'View details',
        hide: 'Hide',
        barChartInfo: 'Shows the number of likes and comments on account’s last posts.',
        viewsYoutubeBarChart: 'The median number of the channel video views after publication.',
        erYoutubeBarChart: 'The median engagement rate of the channel video after publication.',
      },
      thirtyDays: '30 days',
    },
    lookalikes: {
      youtube: {
        title: 'Lookalikes',
        content: 'By content',
        audience: 'By audience',
      },
      instagram: {
        title: 'Audience lookalikes',
        accountInfo: 'Account info',
        followers: 'Followers',
        engagements: 'Engagements',
      },
      showMore: 'Show {{value}} more',
      hideAll: 'Hide',
      noData: 'Seems like this influencer is unique',
    },
    audience: {
      age: 'age',
      males: 'males',
      females: 'females',
      titleAudienceMetrics: 'Audience metrics',
      followers: 'Followers',
      likes: 'Likes',
      location: 'Location',
      language: 'Language',
      demographics: 'Demographics',
      locationYoutubeInfo:
        'The number and percent of channel subscribers for every audience country.',
      demographicsYoutubeInfo:
        'The number and percent of channel subscribers divided by gender and age.',
      locationInstagramFollowersInfo:
        'The number and percent of followers for every audience country.',
      locationInstagramLikesInfo: 'The number and percent of likes for every audience country.',
      demographicsInstagramFollowersInfo:
        'The number and percent of followers divided by gender and age.',
      demographicsInstagramLikesInfo: 'The number and percent of likes divided by gender and age.',
      likesByMale: 'Likes by males',
      likesByFemale: 'Likes by females',
      locationTikTokFollowersInfo:
        'Shows the number and percent of followers for every audience country.',
      demographicsTikTokFollowersInfo:
        'Shows the number and percent of followers divided by gender and age.',
      languagesTikTokFollowersInfo:
        'Shows the number and percent of followers’ language for every audience country.',
    },
    updateProfileError: 'We’re updating data for the profile',
    updateProfileErrorWithTitle: 'We’re updating data for “{{title}}”',
    updateProfileErrorDescription: 'Try opening the profile and adding it to “Selection” later.',
    updateProfileSelectionErrorDescription: 'Try adding it to “Selection” later.',
  },

  cardList: {
    hideAll: 'Hide',
    showMore: 'Show {{value}} more',
  },
  table: {
    comments: 'Comments: {{count}}',
    location: 'The number and percent of channel subscribers for every audience country.',
    demographics: 'The number and percent of channel subscribers divided by gender and age.',
    instagram: {
      targetAudienceShareToolTip: 'Shows the share of the target audience.',
      targetAudienceHeader: 'target audience',
    },
    youtube: {
      views30ToolTip:
        'Shows the number of views a channel video gets in the first 30 days after publication.',
      er30ToolTip:
        'Shows the percent of interactions (likes and comments) a channel video gets in the first 30 days after publication.',
      recPriceToolTip:
        'This is a recommended price for video integration. Consider it a reference point while negotiating a deal.',
      cpmToolTip: 'Cost per mille shows a recommended price for 1,000 video views.',
    },
  },

  campaignsList: {
    campaignsPlatform: 'Platform',
    campaignsBrand: 'Brand',
    product: 'Product',
    datesOfCampaign: 'Campaign dates',
    totalUnreadDealsMessagesCount: ' New messages: {{totalUnreadDealsMessagesCount}}',
  },

  createOrEditCampaign: {
    createCampaignWithoutSelectionTitle: 'Create {{platform}} campaign',
    createCampaignTitle: 'Create a campaign',
    editCampaignTitle: 'Edit a campaign',
    selectPlatformLabel: 'Select a platform',
  },

  addToExistingCampaignDialog: {
    dialogTitle: 'Add to {{platform}} campaign',
  },

  campaignsSearch: {
    noCampaignsFound:
      'We couldn’t find any campaigns.\nTry searching by a different name \nor create a new campaign. ',
    createCampaign: '+ Create new campaign',
  },

  instagramTitle: 'Instagram',
  youtubeTitle: 'YouTube',
  tikTokTitle: 'TikTok',
  followersHeaderTable: 'Followers',
  platform: {
    youtube: 'YouTube',
    instagram: 'Instagram',
    tiktok: 'TikTok',
  },

  errors: {
    yup: {
      validFieldMaxLength: 'The string length cannot be more than {{count}} symbol characters',
      validYoutubeVideoString:
        'Content format from the link doesn’t match the one included in your contract.',
      validYoutubeShort:
        'Content format from the link doesn’t match the one included in your contract.',
      validInstagramPhotoPost:
        'Content format from the link doesn’t match the one included in your contract.',
      validInstagramReel:
        'Content format from the link doesn’t match the one included in your contract.',
      validInstagramStory:
        'Content format from the link doesn’t match the one included in your contract.',
      validLink: 'Enter the link following this format: http://www.example.com.',
    },
    password: {
      passwordsDoNotMatch:
        'Passwords don’t match. Enter the same password in both fields and try again.',
      shortPassword: 'Create a password at least 8 characters long.',
      whitespaceNotAllowed: ' Whitespace is not allowed',
      mustMatch: 'Passwords don’t match. Enter the same password in both fields and try again.',
      createPassword: 'Create a password.',
      enterCreatedPassword: 'Enter the password you’ve created.',
      enterPassword: 'Enter your password.',
      currentPassword: 'Enter your current password.',
      newPassword: 'Create your new password.',
    },
    email: {
      templateEmail: 'Enter your email following this format: name@example.com.',
      followingEmail: 'Enter the email following this format: name@example.com.',
      enterEmail: 'Enter your email.',
      contactEmail: 'Enter a contact email.',
      required: 'Email is required',
    },
    terms: {
      readPolicies: 'Read and agree to our policies.',
    },
    workspace: {
      workspaceName: 'Create your workspace name.',
      organizationName: 'Enter your organization name.',
      subdomainName: 'Enter your subdomain name.',
      subdomainLimit: 'Create a subdomain name between 2 and 48 characters.',
      orgNameLimit: 'Enter no more than 48 characters.',
      subdomainLatin: 'Enter a Latin letter first.',
      enterFullName: 'Enter your full name.',
      enterJobTitle: 'Enter your job title.',
      unnecessaryCharacters: 'Check for unnecessary characters or spaces and try again.',
      roles: 'Select workspace roles for your teammates.',
      fillAtLeastOneEmail: 'To send invitations, enter at lest 1 email.',
    },
    person: {
      fullName: 'Enter a full name.',
      role: 'Enter a role.',
      jobTitle: 'Enter a job title.',
    },
    campaign: {
      enterCampaignName: 'Enter your campaign name.',
      enterProductName: 'Enter your product name.',
      enterBrandName: 'Enter your brand name.',
      max: 'The maximum field length is 255 characters',
      startDate: 'Select your start date.',
      endDate: 'Select your end date.',
      assignee: 'Select your assignee.',
      endDateLess: 'The end date can’t be less than the start date.',
    },
    required: 'Fill in this field.',
  },

  campaignPage: {
    totalAccounts: 'All accounts: {{count}}',
    totalChannels: 'All channels: {{count}}',
    exportTooltip: 'To export influencers, select them.',
    addContactsTooltip: 'To create contacts, select your influencers.',
    removeChannelsTooltip: 'To remove influencers, select them.',
    exportTooltipActive: 'Export their data to Google Sheets.',
    addContactsTooltipActive: 'Create contacts for influencers.',
    removeChannelsTooltipActive: 'Remove influencers from the campaign.',
    createMessageTooltip: 'To reach out and send a message to\ninfluencers, select them.',
    createMessageTooltipActive: 'Create and send a mass message to\ninfluencers. ',
    notAccessToCampaignTitle: 'No access yet',
    notAccessToCampaignDescription:
      'To get it, ask your workspace admin to assign you to this campaign. Can’t access it for another reason? Contact us via',
    export: 'Export influencers',

    youtube: {
      notFoundChannelCampaign: 'No channels found',
    },
    instagram: {
      notFoundChannelCampaign: 'No accounts found',
    },
  },

  smaTable: {
    instagram: {
      info: 'Account info',
      followers: 'Followers',
      er30: 'er, 30 posts',
      engagements: 'Engagements',
    },

    tikTok: {
      info: 'Account info',
      followers: 'Followers',
      views: 'Views, 30 posts',
      er30: 'er, 30 posts',
      engagements: 'Engagements',
      views30ToolTip: 'Shows the average sum of views on the last 30 posts.',
      er30ToolTip:
        'Shows a median engagement rate for the last 30 posts. ER is a percent of average likes divided by followers.',
    },

    youtube: {
      info: 'channel info',
    },
  },

  personalInboxConnection: {
    social: {
      connectEmail: "Let's connect your email",
      gmail: 'Sign in with Google',
      outlook: 'Sign in with Outlook',
      other: 'Sign in with another email provider',
      descriptionText:
        'To connect, choose your email provider and enter your login details. <br/> After, you can communicate with influencers in this workspace. <br/> We’ll only use your email to redirect messages. Read our <a href="https://hypetrain.io/privacy" target="_blank">Privacy policy</a>',
    },
    helper: {
      gmail: {
        header: 'What kind of Gmail account do you want to connect?',
        recommended: 'Recommended',
        companyHeader: 'My company email (name@company.com)',
        companyTextFirst:
          'To connect, ask {{adminName}} ({{adminEmail}}) to add Hypetrain to the allowlist in Google Workspace.',
        companyTextSecond:
          'If you’ve added Hypetrain to the allowlist, you can connect your email.',
        readOurGuide: 'Read our guide',
        addToAllowlist: 'Add to allowlist',
        personalHeader: 'My personal email (name@gmail.com)',
        personalTextFirst: 'To connect, turn on 2-Step verification and generate the app password.',
        personalTextSecond: 'If you’ve generated the app password, you can connect your email.',
        connect: 'Let’s connect',
        supportText: 'Need more help? Contact our support',
        headerAdvanced: "Let's connect your email",
        personEmail: 'Personal email',
        appPassword: 'App password',
        appPasswordTooltip: ' The 16-character code generated \n by Google. ',
        connectEmail: 'Connect email',
        goBackGoogleConnection: 'Choose Gmail account',
        connectAnotherProvider: 'Connect another email provider',
        enterYourEmail: 'Enter your email.',
        enterYourAppPassword: 'Enter the app password.',
        minAppPassword: 'Enter at least 16 characters.',
        smtpError:
          'We couldn’t connect your email to this workspace.\n' +
          'Check if you’ve entered the correct email or app password.\n',
      },
    },
    disconnectEmail: {
      header: 'Disconnect email?',
      mainText: 'Once disconnected, you can’t send and receive messages in this workspace.',
      secondText: 'You can connect {{email}} or another email later.',
      handler: 'Disconnect email',
      cancel: 'Cancel',
      success: '{{email}} disconnected.',
    },
  },

  errorBoundaries: {
    weHavingAProblem: 'Something isn’t right. \n Try again later or reload your web page.',
  },
  otherProvider: {
    hotspot: 'Enter a hotspot.',
    port: 'Enter a port number.',
    password: 'Enter your email password.',
    minPassword: 'Enter a password at least 1 characters long.',
    email: 'Enter your email.',
    whitespaceNotAllowed: ' Whitespace is not allowed',
    required: 'Required',
  },

  acceptWorkspaceInviteError: {
    acceptErrorHeader: 'Seems like you’re on another account',
    acceptErrorDescription:
      'To join “{{organizationName}}” with “{{newEmail}}”, sign out from “{{currentEmail}}”',
    // TODO текста временные пока Саша в отпуске
    getInfoErrorHeader: 'Seems like you’re trying to access the invitation that no longer exists',
    getInfoErrorDescription: 'Contact your admin to invite you to this workspace again',
    helpText: "Can't sign in for another reason? Contact us via ",
  },

  helpEmail: {
    hello: 'hello@hypetrain.io',
  },

  addressBookPage: {
    influencersContacts: 'Influencers’ contacts: {{count}}',
    teamContacts: 'Team’s contacts: {{count}}',
    noContactsFound: 'No contacts found',
    createTeamContactTitle: 'Create a team’s contact',
    createInfluencerContactTitle: 'Create an influencer’s contact',
    teamTabTip: 'Create team’s contacts associated with your organization.',
  },

  workspaceSettings: {
    title: 'Workspace settings',
    personName: 'You’re {{name}}',
    billingTabName: 'Billing',
    emailConnectionTab: 'Email connection',
    workspaceInfoTab: 'Workspace info',
    workspaceMembersTab: 'Workspace members',
    legalEntityTab: 'Legal entity',

    collaborative: 'Collaborative workspace',
    personal: 'Personal workspace',
    admin: 'Admin',

    workspaceMembers: {
      nameColumn: 'FULL NAME',
      jobTitleColumn: 'JOB TITLE',
      workspaceRoleColumn: 'WORKSPACE ROLE',
      contactDetailsColumn: 'Contact email',
      emailForLoginColumn: 'Email for login',

      inviteTeammatesButton: 'Invite teammates',
      addContact: 'Create contact',

      teammatesTip: 'Teammates: {{count}}',
      otherTip: 'Deactivated teammates',
      representativesTip: 'Representatives: {{count}}',
      usedSeats: 'Used seats: {{usedSeats}}/{{allSeats}}',
      addSeats: 'Add seats',
      addSeatsNotification: 'Seats added: {{count}}.',
      removeSeatNotification: 'Seat removed.',
      updateSubscriptionError: 'Unpaid. To pay, add your seats again.',
      updateSubscriptionErrorButton: 'Check billing history',

      emptyTableTitle: 'No deactivated teammates',
      emptyTableDescription: 'After you deactivate your teammate, they’ll show up here.',

      noTeammatesFound: 'No teammates found',
      noOtherFound: 'No inactive teammates & representatives found',
      noRepresentativesFound: 'No representatives found',
      inviteButtonTooltip: 'You can only invite \nyour teammates in the \ncollaborative workspace.',
      inviteButtonTooltipListHeader: 'To create it:',
      inviteButtonTooltipFirstStep:
        'Go to the left navigation \nmenu and click on the \nworkspace icon',
      inviteButtonTooltipSecondStep: 'Click on “+” to create \nyour collaborative \nworkspace.',

      addSeatsDialog: {
        addSeats: 'Add seats',
        dialogName: 'Add seats',
        seatsCount: 'You have Seats: {{count}}',
        description:
          '1 seat costs {{prorationSeatCost}} for\n{{startProrationDate}} – {{endProrationDate}}',
        total: 'Total',
        numberOfSeats: 'Number of seats',
        enterYourNumberOfSeats: 'Enter your number of seats.',
        maxSeatsError: 'Enter {{count}} seats as a maximum.',
        tip: 'You’re about to pay {{totalCost}} for {{seats}} new seats. You’ll have your next payment of {{everyMonthSubscriptionCost}} ({{planCost}} per seat / month + tax) on {{nextPaymentDate}}.',
        tralTip: 'Now free during trial period',
        cost: 'Cost',
        tax: 'Tax ({{taxPercent}}%)',
      },

      removeSeatModal: {
        removeSeatModalName: 'Remove a seat?',
        removeSeat: 'Remove seat',
        keepSeat: 'Keep seat',
        descriptionForOneSeat:
          'Once removed, you can’t invite a teammate to\n this seat. We’ll reduce your payment to {{price}}\n next time and keep {{newSeatsCount}} seat.',
        descriptionSeveralSeats:
          'Once removed, you can’t invite a teammate to\n this seat. We’ll reduce your payment to {{price}}\n next time and keep {{newSeatsCount}} seats.',
      },

      unsubscribeModal: {
        title: 'Unsubscribe from {{planName}}?',
        pastDueDescription:
          'You and your teammates won’t access our \nplatform after you unsubscribe.\n\nCome back anytime. If you renew, your \nworkspace data will remain intact.',

        description:
          'Once unsubscribed, you and your teammates \nwill lose access to Hypetrain from \n{{currentPeriodEnd}}.\n\nYou can renew your {{planName}} subscription at \nany time.',
        cancelButton: 'Keep {{planName}}',
        successButton: 'Unsubscribe',
      },

      inviteTeammatesDialog: {
        title: 'Invite teammates',
        sendInvitation: 'Send invitation',
        sendInvitations: 'Send invitations',
        seats: 'Seats: {{seatsCount}}',
        enterEmails: 'Enter emails',
        youHaveemptySeat: 'You have 1 empty seat so you can invite 1 teammate',
        youHaveemptySeats:
          'You have {{seatsCount}} empty seats so you can invite {{seatsCount}} teammates',
        fillAtLeastOneField: 'To send your invitation, fill in at least 1 field.',
        enterAnEmail: 'To send your invitation, enter an email.',
        enterNewOne: 'You’ve entered this email. Enter a new one.',
      },

      table: {
        emptySeat: 'Empty seat',
        pending: 'Pending',
        inviteTeammate: 'Invite teammate',
      },
    },

    billing: {
      seats: 'Seats',
      monthlyPayment: 'Monthly payment',
      addSeatsDisableTooltip: 'To add more seats, activate your \nsubscription.',
      dueDate: 'Due date',
      subscription: '{{name}} subscription',
      updatePaymentDetails: 'Update payment info',
      unsubscribe: 'Unsubscribe',
      activateSubscription: 'Activate subscription',
      getSubscription: 'Get subscription',
      addSeats: 'Add seats',
      billingHistory: {
        title: 'Billing history',
        headerDescription:
          'It takes a few minutes to show you the actual payment statuses. To update them, click “Refresh.”',
        statusUnpaid: 'Unpaid',
        statusPaid: 'Paid',
        statusCanceled: 'Canceled',
        statusProcessing: 'Processing',
        date: 'Date',
        description: 'Description',
        amount: 'Amount',
        status: 'Status',
        noDataTitle: 'After you make your first payment, it’ll show up here.',
        payAgain: 'Pay again',
        pay: 'Pay',
        subscriptionCreateInvoice: 'Activated Universal subscription',
        subscriptionCycle: 'Universal (automatic payment per month)',
        subscriptionUpdate: 'Added seats: {{seatsCount}} ({{period}})',
      },
      subscriptionsStatuses: {
        active: 'Active',
        trial: 'Trial',
      },
      manageSeatsDialog: {
        done: 'Done',
        manageSeats: 'Manage seats',
        addSeats: 'Add seats',
        listTitle: 'Your seats',
        listDescription:
          'You can remove your seats if you haven’t invited any teammates to the workspace.',
        removeSeat: 'Remove seat',
        revokeInvitation: 'Revoke invitation',
        deactivateTeammate: 'Deactivate teammate',
        description:
          'You’re about to pay {{monthlyPayment}} for {{seatsCount}} seats ({{planPrice}} per seat / month + tax).',
      },
      reviewPaymentDialog: {
        reviewYourPayment: 'Review your payment',
        letsGetSubscription: 'Let’s get a subscription',
        reviewAndPay: 'Review and pay',
        getSubscription: 'Get subscription',
        youHaveSeats: 'You have seats',
        seatMonth: '1 seat / month',
        manageSeats: 'Manage seats',
        allFeaturesIncluded: 'All features are included',
        total: 'Total',
        seatsFor: 'Seats: {{seats}} for {{totalCost}}',
        monthlySubscription: 'Monthly subscription',
        cost: 'Monthly subscription',
        tax: 'Tax ({{taxPercent}}%)',
      },
    },

    emailConnection: {
      title: '{{email}} connected',
      description: 'You can communicate with influencers in this workspace.',
      disconnectEmail: 'Disconnect email',
    },

    legalEntity: {
      header: 'Legal entity',
      description:
        'Create or edit your company’s legal details. So we’ll include them in your contracts with influencers.',
      addButton: 'Create legal entity',
      edit: 'Edit legal entity',
      update: 'Update',
      modal: {
        addHeader: 'Create a legal entity',
        editHeader: 'Update a legal entity',
        companyName: 'Company name',
        registrationNumber: 'Registration number',
        phoneNumber: 'Phone number',
        email: 'Email',
        address: 'Address',
        city: 'City',
        postalCode: 'Postal code',
        state: 'State/Province',
        country: 'Country',
        create: 'Create legal entity',
        update: 'Edit legal entity',
        error: {
          name: 'Enter your company name.',
          registrationNumber: 'Enter your registration number.',
          phoneNumber: 'Enter your phone number.',
          email: 'Enter your email.',
          address: 'Enter your address.',
          city: 'Enter your city.',
          postalCode: 'Enter your postal code.',
          region: 'Enter your state or province.',
          country: 'Select your country.',
        },
      },
      addSuccess: 'Legal entity created.',
      deleteSuccess: 'Legal entity deleted.',
    },
  },

  influencersUpload: {
    emptyFileTitle: 'File is empty',
    emptyFileDescription: 'Fill your data in our template and upload it again.',
    invalidFileTitle: 'Check your file',
    invalidFileDescription:
      'Fill your data in according to our template. \nOnce completed, upload your file again.',
    invalidFileFormatTitle: 'Unsupported file format',
    invalidFileFormatDescription: 'Upload your file in a .csv format.',
  },

  menu: {
    createWorkspaceTooltip: 'Create a collaborative workspace.',
    createWorkspaceTitle: 'Let’s create your collaborative workspace',
    createWorkspaceDescription:
      'Invite your teammates, assign them to campaigns, and track their progress.',
  },

  addYourCard: {
    title: 'Let’s review your payment',
    description:
      'Your trial subscription has ended. To keep using Hypetrain, review your \npayment and pay for your subscription. If you want to make another \npayment, you can manage your seats.',
    buttonText: 'Review payment',
  },

  startTrial: {
    universal: {
      title: 'Let’s start your free trial, \n no payment card is needed',
      description:
        'Your free plan has ended in “{{workspaceName}}.” \nTo keep using Hypetrain, get our subscription plan.',
      buttonText: 'Start free trial',
      firstPoint: 'Free trial for 2 weeks',
      secondPoint: '{{price}} + tax per workspace member’s seat after your trial ends',
      thirdPoint: 'Monthly payment with no annual commitment',
      fourthPoint: 'All features are included',
      fifthPoint: 'No payment card is needed',
      viewFeatures: 'View features',
      linkTitle: 'Or ask your admin to invite you to an existing workspace',
    },
    taxAddressForm: {
      postalCodeRequired: 'Enter your postal code.',
      countryRequired: 'Select your country.',
      countryLabel: 'Country',
      countryDescription: 'Select where you’re doing your business from',
      postalCodeLabel: 'Postal code',
      postalCodeDescription: 'Only for the US and Canada',
      postalCodePlaceHolder: 'Enter postal code',
      buttonText: 'Start free trial',
    },
    backButton: 'Sign out',
    trialNotification: 'Your 2-week trial is active.',
    invalidPostalCode: 'Check the postal code in your company address.',
    videoText: 'Let’s watch a quick \n overview of Hypetrain.',
    workspaceDescription: 'Write your company name or something familiar to your team',
  },

  profileFeedback: {
    header: 'We’d love your feedback',
    metrics: 'What metrics are you looking for?',
    leaveFeedback: 'Leave feedback',
    enterMetrics: 'Enter metrics',
    validateTextError: 'Enter your metrics.',
    success: 'Feedback sent.',
    error: 'Something went wrong.',
  },

  menuSubscriptionBadge: {
    unpaid: 'Unpaid',
    latePayment: 'Late payment',
    subscriptionNeeded: 'Subscription needed',
  },

  verificationIcon: {
    youtube: 'Verified by YouTube.',
    youtubeArtist: 'The Official Artist Channel \non YouTube.',
    instagram: 'Verified by Instagram.',
    tikTok: 'Verified by TikTok.',
  },

  actionsMenu: {
    removeSeat: 'Remove seat',
    activate: 'Activate',
    deactivate: 'Deactivate',
    delete: 'Delete',
    edit: 'Edit',
    revokeInvitation: 'Revoke invitation',
    sure: 'Sure?',
  },

  subscriptionsStatuses: {
    trial: 'Trial',
    trialDays: 'Trial (days left: {{days}})',
    active: 'Active',
    deactivated: 'Deactivated',
    unsubscribed: 'Unsubscribed',
    deactivatedLeftDay: 'Unsubscribed ({{diff}} day left to lose access)',
    deactivatedLeftDays: 'Unsubscribed ({{diff}} days left to lose access)',
  },

  noSeatsNotification: {
    title: 'You don’t have any seats',
    description: 'To activate a teammate, add a seat.',
    addSeats: 'Add seats',
  },

  subscriptionNotifications: {
    trialWillEnd:
      'Your trial will end on {{endDate}}. To keep using Hypetrain, pay for your {{name}} subscription.',
    trialWillEndWithPaymentMethod:
      'Your trial will end on {{endDate}}. After, you need to pay {{cost}} + tax.',
    trialWillEndButton: 'Add payment info',
    paymentFailedAdmin:
      'You’re about to lose access to Hypetrain due to the late payment. To discover the amount you need to pay, check your billing history.',
    paymentFailedMember:
      'Late payment. To keep using Hypetrain, the workspace admin should pay for the {{name}} subscription.',
    paymentFailedButton: 'Check billing history',
    deactivatedByUserAdmin:
      'You unsubscribed from {{planName}}. You’re about to lose access on {{endedAt}}. To keep using Hypetrain, activate your {{planName}} subscription.',
    deactivatedByUserMember:
      'The workspace admin unsubscribed from {{planName}}. You’re about to lose access on {{endedAt}}. To keep using Hypetrain, the workspace admin should activate our {{planName}} subscription.',
    activateSubscription: 'Activate subscription',
  },

  subscriptionStatusBadge: {
    labelStatus: 'Subscription status: {{status}}',
    labelSubscription: 'Subscription: {{status}}',
    trialDescription:
      'You have a 2-week trial \nsubscription now. So you can use \nall our features for free. We’ll email \nyou 2 days before the end of \nyour subscription.',
    activeDescription:
      'Your {{name}} subscription is \nactive now. So you can add more \nseats and invite your teammates \nto the workspace.',
    deactivatedDescription:
      'Your {{name}} subscription is \ndeactivated now. To make it active, \nrenew your subscription.',
    unsubscribedDescription:
      'You unsubscribed from {{name}}. \nYou can use our platform by the \nend of your billing period.',
  },

  subscriptionPlanNames: {
    universal: 'Universal',
  },

  marketingConsentPanel: {
    confirm: 'Subscribe',
    cancel: 'No, thanks',
    title: 'Become the smartest marketer in the field',
    description:
      'With our best marketing content delivered to your inbox\n twice a month. No spam. We promise.',
  },

  campaignSettings: {
    updated: 'Changes saved.',
    tabs: {
      product: 'Settings & product',
      terms: 'Contract terms',
      requirements: 'Content requirements',
    },
    header: 'Campaign details',
    actionTip:
      'Save your time by creating a campaign template. So you can reuse it while negotiating your deals with influencers.',
    save: 'Save changes and go to campaign',
    tip: 'Save your time by creating a campaign template. So you can reuse it while negotiating your deals with influencers.',
    form: {
      complianceClauses: {
        title: 'Compliance requirements',
        brandSafetyRestrictions: {
          label: 'Brand safety',
          tooltip: 'Enter what goods or services the brand wants to protect in the campaign.',
        },
        ageRestrictions: {
          label: 'Age restriction',
          tooltip:
            'Enter the audience’s age range for whom the promotional content isn’t suitable.',
          placeholder: '0–10 years old',
        },
        nonMentioningOfCompetitors: {
          label: 'Non-mentioning of competitors',
          tooltip:
            'Enter what brand’s competitors the influencer shouldn’t mention in their promotional content. ',
        },
        otherSponsoredContentProhibition: {
          label: 'Sponsored content prohibition',
          tooltip:
            "Enter what sponsored content the influencer shouldn’t mention for the other party's benefit.",
        },
        disclosureOfContentSponsorshipRequirements: {
          label: 'Sponsorship disclosure',
          tooltip:
            'Enter the text about the sponsor the influencer should mention in their content.',
        },
      },
      deadlinesTerms: {
        title: 'Deadlines',
        termServiceProduct: {
          label: 'Time frame for a draft',
          placeholder: '0 calendar days',
          tooltip:
            'Enter the number of days during which the influencer should send their draft of the promotional content.',
        },
        dateOfPlacement: {
          label: 'Date of placement',
          tooltip:
            'Specify when the influencer should place their promotional content on social media.',
          placeholder: 'MM/DD/YYYY',
        },
      },
      campaignSettings: {
        title: 'Campaign settings',
        campaignName: {
          label: 'Campaign name',
        },
        assignee: {
          label: 'Assignee',
        },
        startDate: {
          label: 'Start date',
        },
        endDate: {
          label: 'End date',
        },
      },
      generalTerms: {
        title: 'General terms',
        config: {
          instagram: {
            photoPost: 'Instagram photo',
            videoPost: 'Instagram video',
            story: 'Instagram story',
            reel: 'Instagram reel',
          },
          youtube: {
            video: 'YouTube video',
            short: 'YouTube short',
          },
          collaboration: {
            integration: 'In-video integration',
            roll: 'Roll ad',
            productPlacement: 'Product placement',
          },
        },
        collaborationFormat: {
          label: 'Format of promotional content',
          tooltip: 'Select what type of work the influencer should create for your campaign.',
        },
        formatPlacementWithinInfluencerServices: {
          label: 'Format of placement on social media',
          tooltip: 'Select in what format the influencer should place their promotional content.',
        },
        notes: {
          label: 'Notes',
          placeholder: '',
          tooltip:
            'Enter brand requirements for the contract such as guidelines, references, and so on.',
        },
      },
      ownershipLicensingTerms: {
        title: 'Ownership and licensing',
        termServiceProduct: {
          label: 'Content licensing',
          tooltip: 'Allow the brand to use the promotional content on their social media.',
        },
        ownershipLicensingTerms: {
          label: 'Ownership of the promotional content',
          tooltip: 'Select to who the intellectual rights of the promotional content belong.',
        },
        config: {
          advertiser: 'Brand',
          influencer: 'Influencer',
        },
      },
      paymentTerms: {
        title: 'Payment terms',
        fixedPrice: 'Fixed price',
        paymentFormat: {
          label: 'Payment format',
          tooltip: 'The influencer will receive a fixed price for their promotional content.',
        },
        paymentAmount: {
          label: 'Amount',
          tooltip: 'Enter the amount of money the influencer will receive for their services.',
        },
        remunerationCurrency: {
          label: 'Currency',
          tooltip: 'Select the currency in which the influencer should receive payment.',
        },
        paymentOption: {
          label: 'How would you like to pay?',
          tooltip:
            'Select how you want to pay your influencer You can specify the number of calendar days for payment.',
        },
        config: {
          prePay:
            '100% before the date of placement when the influencer should place content (pay within the number of calendar days you’ve specified)',
          postPay:
            '100% after the date of placement when the influencer should place content (pay within the number of calendar days you’ve specified)',
          halfPay:
            '50% before the date of placement / 50% after the date of placement (pay within the number of calendar days you’ve specified)',
          placeholder: '0 calendar days',
          company: 'Company',
          individual: 'Individual ',
        },
        paymentCountry: {
          label: 'Country',
          tooltip: 'Select the country where your influencer is doing their business.',
        },
        creatorBusinessType: {
          label: 'Business type',
          tooltip: 'Select what party’s type represents the influencer in signing your contract.',
        },
      },
      productConditions: {
        title: 'Product campaign',
        brandName: {
          label: 'Brand name',
        },
        productName: {
          label: 'Product name',
        },
        productLink: {
          label: 'Product link',
          placeholder: 'https://',
        },
        productDescription: {
          label: 'Product description',
        },
      },
      productCreationProcess: {
        title: 'Content creation process',
        scenarioSuggestions: {
          label: 'Scenario suggestions',
          tooltip:
            'Enter a scenario (a plot or a script) from the brand the influencer should follow in their content.',
        },
        approvalPeriodForDraft: {
          label: 'Approval period for a draft',
          tooltip:
            'Enter the number of calendar days you need to approve the draft of the promotional content.',
          placeholder: '0 calendar days',
        },
        talkingPoints: {
          label: 'Talking points',
          tooltip:
            'Enter the brand’s promotional phrases the influencer should mention in their content.',
        },
        pinnedCommentRequirements: {
          label: 'Pinned comment requirements',
          tooltip: 'text!',
        },
        otherTechnicalRequirements: {
          label: 'Other technical requirements',
          tooltip: 'text!',
        },
      },
      technicalRequirements: {
        title: 'Technical requirements',
        sponsoredLink: {
          label: 'Sponsored link',
          tooltip:
            'Enter the brand referral link the influencer should include in their promotional content.',
          placeholder: 'https://',
        },
        promoCode: {
          label: 'Promo code',
          tooltip: 'Enter the promo code the influencer should place in their promotional content.',
        },
        descriptionRequirements: {
          label: 'Requirements for description',
          tooltip: 'Enter what text the influencer should place in the “Description” section.',
        },
        pinnedCommentRequirements: {
          label: 'Requirements for pinned comments',
          tooltip: 'Enter what text the influencer should follow in the “Comments” section. ',
        },
        otherTechnicalRequirements: {
          label: 'Other requirements',
          tooltip:
            'Enter technical requirements for the promotional content (video or image size, aspect ratio, and so on).',
        },
      },
      timing: {
        title: 'Timing',
        publicationTimeOfDay: {
          label: 'Publication time',
          tooltip:
            'Enter when the influencer should publish or share their promotional content on social media.',
          placeholder: '00:00 UTC',
        },
        minimumAdvertisementDuration: {
          label: 'Minimum length of promotional content',
          tooltip: 'Enter the minimum amount of time for the ad.',
          placeholder: '0 seconds',
        },
        earliestStartPoint: {
          label: 'Earliest start point',
          tooltip:
            'Enter the earliest timestamp (measured in minutes or seconds) when the promotional content should start.',
          placeholder: '0 seconds',
        },
        latestStartPoint: {
          label: 'Latest start point',
          tooltip:
            'Enter the latest timestamp (measured in minutes or seconds) when the promotional content should start.',
          placeholder: '0 seconds',
        },
      },
    },
  },
  deal: {
    communication: {
      nameTab: 'Communication',
    },
    statementOfWork: {
      nameTab: 'Deal details',
      tabs: {
        termsOfContract: 'Contract terms',
        requirements: 'Content requirements',
      },
    },
    recruitment: {
      nameTab: 'Recruitment: {{status}}',
      tabs: {
        defaultTitle: 'Let’s recruit the influencer \nfor your campaign',
        infoText:
          'After you decide if you want to collaborate,\n choose “Approved” or “Rejected.” \n\n You can change your decision at any recruitment \n step.',
        rejectTitle: 'Influencer rejected',
        infoTextReject:
          'The deal doesn’t go any further in your \n campaign. \n\n If you want to work with this influencer again, \n press “Approved.”',
        approvedTitle: 'Influencer approved',
        infoTextApproved:
          'The deal is on the next stage. You can change \n your decision about the influencer anytime. \n\n If you press “Rejected”, your deal won’t go any \n further.',
        agreedTitle: 'Yay! You’ve completed the {{state}} stage',
        agreedDescription: 'We’re moving you to the {{nextState}} one...',
      },
      steps: {
        initial: 'Added to campaign',
        initialInfo:
          'You’ve added the influencer to your campaign. Let’s start negotiating your collaboration offer.',
        messageSent: 'Message sent',
        messageSentInfo:
          'We’ve sent your message. Check if the influencer read it in the communication tab and wait for their reply.',
        replied: 'Replied',
        repliedInfo: 'The influencer replied to your message.',
        decide: 'Approved / rejected',
        decideInfo: 'Let’s decide if you want to collaborate with the influencer.',
      },
      infoStepperTitle: 'Recruitment stage: Steps to take',
      stepsCounter: 'STEPS {{count}}/{{totalCount}}',
      approved: 'Approved',
      rejected: 'Rejected',
    },
    contracting: {
      nameTab: 'Contracting: {{status}}',
      specifyDealDetails: {
        title: 'Specify your deal details ({{current}}/{{total}})',
        description: 'Include your contract terms and content requirements for the influencer.',
        done: 'Done',
      },
      header: 'Let’s create your contract with this influencer',
      description:
        'Specify your deal details, product campaign, legal entity, and contract signers.',
      approved: 'Create contract',
      approvedTooltip: 'Include your contract details.',
      specifyProductCampaign: {
        title: 'Specify your product campaign ({{current}}/{{total}})',
        description: 'Include the product info you want to promote in your campaign.',
        modal: {
          title: 'Specify a product campaign',
          save: 'Done',
        },
      },
      specifyLegalEntity: {
        title: 'Specify your legal entity',
        description: 'Select your company’s legal details you want to include in the contract.',
        modal: {
          title: 'Select legal entity',
          save: 'Select',
        },
      },
      specifyContractSigners: {
        title: 'Specify your contract signers',
        description:
          'Select who will sign the contract from the influencer’s side and your company.',
        done: '{{creator}} and {{advertiser}}',
        modal: {
          title: 'Specify contract signers',
          influencerSigner: 'Influencer’s signer',
          influencerSignerDescription:
            'Select who will sign your contract from the influencer’s side',
          companySigner: 'Company signer',
          companySignerDescription: 'Select who will sign your contract from your company side',
          requiredCreator: 'Company signer is required',
          requiredInfluencer: 'Influencer’s signer is required',
          save: 'Done',
          companySignerDoesntHaveJobTitle:
            'This teammate doesn’t have a job title. If you’re the Owner, enter it in “Workspace settings.”',
        },
        groupSelect: {
          dealParticipants: 'Deal participants:',
          companyContacts: 'Company contacts:',
          influencersContacts: 'Influencer’s contacts:',
        },
        createContact: 'Create contact',
        createContactActionBtn: '+ Create a contact',
        back: 'Specify contract signers',
      },
      infoStepperTitle: 'Contracting stage: Steps to take',
      steps: {
        filling: 'Filling',
        fillingInfo:
          'You need to complete your contract details. So you can review them before signing.',
        reviewing: 'Reviewing',
        reviewingInfo: 'You can edit your contract. Or send it to sign.',
        signing: 'Signing',
        signingInfo:
          'We’ve sent your contract to signers. You just need to wait when they sign it.',
        signed: 'Signed / rejected / skipped',
        signedInfo:
          'Your contract signed / once rejected, your deal won’t go any further / if you skip contracting, you’ll go to another stage.',
      },
      reject: 'Reject deal',
      skip: 'Skip contracting',
      reviewing: {
        header: 'Let’s get your contract signed',
        description: 'Send your contract for signing. Or you can edit it and do this later.',
        review: 'Review contract',
        sign: 'Send to sign',
        edit: 'Edit contract',
        cancel: 'Cancel',
        modalTitle: 'Send to sign?',
        modalDescription:
          'Once sent, you can’t edit your contract details. Before sending, check them.',
      },
      rejectPage: {
        title: 'Deal rejected',
        description: 'The deal doesn’t go any further in your campaign.',
      },
      skippedPage: {
        title: 'Contracting skipped',
        description: 'You’ve skipped the contracting stage. So you’ll go to the next one.',
      },
      signedPage: {
        title: 'Yay, contract signed',
        description:
          'We’re working on the payment stage. So you can make payments in our platform soon. \n\n' +
          'If you want to pay now, use other services.',
        contractButton: 'Open contract',
      },
      signingPage: {
        title: 'Waiting to sign',
        description:
          'We’ve sent your contract to {{firstPerson}} and {{secondPerson}}. We’ll let you know when they sign it here.',
        waiting: 'Waiting...',
        signed: 'Signed',
      },
    },
    payment: {
      nameTab: 'Payment: {{half}} {{status}}',
      creatingPayment: {
        title: 'Yay! You’ve completed the contracting stage',
        description: 'This might take up to 1 minute...',
      },
      paymentInfo:
        'The Payment Manager should pay the influencer. After, we’ll move you to the drafting stage. To pay by yourself, <0>go to payments</0>',
      approvedTooltip: 'To approve payment, the influencer should \ncomplete the KYC process.',
      approvedBtn: 'Approve payment',
      infoStepperTitle: 'Payment stage: Steps to take',
      onboarding: {
        title: 'Let’s send a payment to \nthis influencer',
        description: 'After you send your payment, we’ll move you to the \nnext stage.',
      },
      sendPay: {
        header: 'Let’s check payment for this influencer',
        description:
          'If it’s correct, press “Approve payment.” After, your Payment Manager receives it and can pay the influencer.',
      },
      waitingPayment: {
        title: 'Waiting for payment',
        description:
          'The Payment Manager should pay the influencer. After, we’ll move you to the drafting stage.',
        descriptionWithRule:
          'The Payment Manager should pay the influencer. After, we’ll move you to the drafting stage. To pay by yourself, <0>go to payments</0>',
        pre: {
          pre: '100% before the date of placement (due date is {{date}})',
          half: '50% before the date of placement (due date is {{date}})',
        },
        post: {
          post: '100% after the date of placement (due date is {{date}})',
          half: '50% after the date of placement (due date is {{date}})',
        },
      },
      steps: {
        waitingForApproval: 'Waiting for approval',
        waitingForApprovalInfo:
          'Approve your payment if it’s correct and the influencer completed the KYC process.',
        waitingForPayment: 'Waiting for payment',
        waitingForPaymentInfo:
          'Once your Payment Manager paid the influencer, you’ll go automatically to the next step.',
        signed: 'Paid/ skipped',
        signedInfo:
          'Payment sent to your influencer/ if you skip the payment stage, you’ll go to another one.',
      },
      skip: 'Skip payment',
      approved: {
        title: 'Payment made',
        description: {
          prePayment:
            'The Payment Manager has already paid your influencer. So you can work in the next stages.',
          postPayment:
            'The Payment Manager sent your payment to the influencer. So you’ve finished your deal. To track  your metrics, <0>go to the publishing stage</0>',
          fiftyOnFifty: {
            postPayment:
              'The Payment Manager sent your payment to the influencer. So you’ve finished your deal. To track  your metrics, <0>go to the publishing stage</0>',
            postPaymentPublishingSkipped:
              'The Payment Manager sent your payment to the influencer. So you’ve finished your deal.',
          },
        },
      },
      skipped: {
        title: 'Payment skipped',
        description: 'You’ve skipped the payment stage. So you’ll go to the next one.',
      },
      declined: {
        title: 'Deal rejected',
        description: 'The deal doesn’t go any further in your campaign.',
      },
    },
    drafting: {
      nameTab: 'Drafting: {{status}}',
      header: 'Let’s approve the influencer’s content draft',
      description: 'If it suits your campaign needs, press “Approve draft.”',
      infoStepperTitle: 'Drafting stage: Steps to take',
      secondDescription:
        'In case you want to stop working with this influencer, click the three dots icon and choose “Reject deal.” Or press “Skip drafting” if you don’t work with drafts. Once skipped, you’ll go to the next stage.',
      steps: {
        waiting: 'Waiting for approval',
        waitingInfo:
          'Check the influencer’s content draft and decide if it suits your campaign needs.',
        approved: 'Approved/ rejected/ skipped',
        approvedInfo:
          'The influencer’s content draft approved / once rejected, your deal won’t go any further / if you skip drafting, you’ll go to another stage.',
      },
      skip: 'Skip drafting',
      reject: 'Reject deal',
      approve: 'Approve draft',
      skippedPage: {
        title: 'Drafting skipped',
        description: 'You’ve skipped the drafting stage. So you’ll go to the next one.',
      },
      rejectPage: {
        title: 'Deal rejected',
        description: 'The deal doesn’t go any further in your campaign.',
      },
      approved: {
        title: 'Draft approved',
        description:
          'You’ve approved the influencer’s content draft.\n So you can work in the next stages.',
      },
    },
    publishing: {
      viewContentlink: 'View influencer’s content',
      validate: {
        requiredContentLink: 'Enter the link following this format: http://www.example.com.',
      },
      nameTab: 'Publishing: {{status}}',
      approve: 'Approve and track',
      goToPayment: 'Go to payment',
      approveAndNext: 'Approve and go to next stage',
      approveTooltip: 'To approve and track, add your link to \nthe influencer’s content.',
      reject: 'Reject deal',
      skip: 'Skip publishing',
      infoStepperTitle: 'Publishing stage: Steps to take',
      linkInputPlaceholder: 'https://',
      addBtnTooltip: 'Select "Format of placement" in deal details',
      likes: 'Likes',
      views: 'Views',
      comments: 'Comments',
      urlValidationError: 'Enter the link following this format: http://www.example.com.',
      steps: {
        initial: 'Waiting for link',
        initialInfo: 'Add your content link to track campaign metrics.',
        reviewing: 'Reviewing',
        reviewingInfo:
          'Сheck if the influencer’s content meets your requirements in the contract. ',
        final: 'Tracking/ rejected/ skipped',
        finalInfo:
          'We’re collecting your campaign data / once rejected, your deal won’t go any further / if you skip publishing, you’ll go to another stage.',
      },
      initial: {
        buttonText: 'Add link',
      },

      reviewing: {
        title:
          'We’re collecting your campaign data. It can be lost if you edit this link. Add a new one,\n and we’ll start collecting your campaign data again.',
        buttonText: 'Edit link',
        modalTitle: 'Edit this link?',
        modalDescription:
          'If you edit, your current campaign data will be lost. We’ll start collecting it again after you add a new link. ',
        editButton: 'Edit link',
        cancelButton: 'Cancel',
      },
      approveTitle: 'Let’s approve the link to track your campaign metrics',
      approveDescription:
        'Add your link to the influencer’s content. After, we’ll start tracking your campaign metrics.',
      approveTitleStory: 'Let’s approve the influencer’s content',
      approveDescriptionStory:
        'We don’t support any metrics for Instagram stories. You can track them by yourself and go to the next stage.',
      skippedPage: {
        title: 'Publishing skipped',
        description: 'You’ve skipped the publishing stage. So you’ll go to the next one.',
      },
      rejectPage: {
        title: 'Deal rejected',
        description: 'The deal doesn’t go any further in your campaign.',
      },
      approvedPage: {
        title: 'Link approved',
        description:
          'You’ve approved the influencer’s content link. So you can work in the next stage.\n\n' +
          'We don’t support metrics tracking for Instagram stories. Try checking them by yourself.',
      },
      tracking: {
        chart: {
          views: 'Views',
          likes: 'Likes',
          comments: 'Comments',
          er: 'ER',
          range1: '0d',
          range2: '7d',
          range3: '14d',
          range4: '21d',
          range5: '28d',
          title: 'Performance',
          description: 'We track content data once per day during the month',
          viewsInfo: 'Shows the number of video views after publication.',
          likesInfo: 'Shows the number of likes the content got after publication.',
          commentsInfo: 'Shows the number of comments the content got after publication.',
          erInfo: 'Shows the engagement rate the content got after publication.',
        },
      },
    },
  },
  stages: {
    title: 'Stages:',
    recruitment: {
      name: 'Recruitment',
      initial: 'Added to campaign',
      'reached out': 'Message sent',
      replied: 'Replied',
      agreed: 'Approved',
      declined: 'Rejected',
      skipped: 'Skipped',
    },
    contracting: {
      name: 'Contracting',
      filling: 'Filling',
      initial: 'Initial',
      review: 'Reviewing',
      signing: 'Signing',
      signed: 'Signed',
      declined: 'Rejected',
      skipped: 'Skipped',
    },
    payment: {
      name: 'Payment',
      initial: 'Waiting for approval',
      approved: 'Paid',
      paid: 'Paid',
      skipped: 'Skipped',
      'waiting for payment': 'Waiting for payment',
      'payment-pre': '(1/2)',
      'payment-post': '(2/2)',
    },
    drafting: {
      name: 'Drafting',
      initial: 'Waiting for approval',
      approved: 'Approved',
      declined: 'Rejected',
      skipped: 'Skipped',
    },
    publishing: {
      name: 'Publishing',
      initial: 'Waiting for link',
      review: 'Reviewing',
      approved: 'Tracking',
      declined: 'Rejected',
      skipped: 'Skipped',
    },
    moreStagesTooltip: 'We’ll add more stages soon.',
    notStarted: 'Not started yet...',
  },
  campaign: {
    moreStagesTooltip: 'We’ll add statistics soon.',
    hint: {
      title: 'Let’s edit your campaign',
      body: 'Save your time by creating a campaign template. So you can reuse it while negotiating your deals with influencers.',
    },
    stages: {
      agreed: 'Approved',
      declined: 'Rejected',
      recruitmentStageName: 'Recruitment stage',
      contractingStageName: 'Contracting stage',
      paymentStageName: 'Payment stage',
      draftingStageName: 'DraftingStageName stage',
      publishingStageName: 'Publishing stage',
      trackingStageName: 'Tracking stage',
      reportingStageName: 'Reporting stage',
      totalSpecificStat: 'Influencers added',
      reachedOutSpecificStat: 'Messages sent',
      repliedSpecificStat: 'Replied',
    },
  },

  campaignDetailsTab: {
    dataAnalytics: 'Analytics',
    stages: 'Stages',
  },
  dealPageHeader: {
    showMore: 'Show stats',
    showLess: 'Hide stats',
  },

  exportingSelectionModal: {
    copiedDoneExport: 'Link copied.',
    channelsExported: 'Channels exported',
    accountsExported: 'Accounts exported',
    exportedChannelsTotal: 'All channels: {{ count }}',
    exportedAccountsTotal: 'All accounts: {{ count }}',
    openDocument: 'Open file',
    copyLink: 'Copy link',
    exportingChannels: 'Exporting channels',
    exportingAccounts: 'Exporting accounts',
    exportingFileWait: 'It might take a few minutes. Don’t close \n this window.',
    exportedFileTitle: 'accounts-exported',
  },

  monthlyPaymentTooltip: {
    subtotalOneSeat: 'Subtotal (1 seat*{{planPrice}})',
    subtotalSeveralSeats: 'Subtotal ({{seatsCount}} seats*{{planPrice}})',
    tax: 'Tax ({{percent}}%)',
    total: 'Total',
  },

  accordion: {
    showMore: 'Show more',
    hideAll: 'Hide all',
  },

  fillLabel: {
    fillIn: 'Fill in',
    select: 'Select',
  },

  choosingTopUpAccount: {
    title: 'What account do you want to top up?',
  },

  topUpAccountInstructions: {
    backButton: 'What account do you want to top up?',
    title: 'Top up your {{ accountName }}',
    detailsDescription: 'To top it up, send money from your payment account using these details:',
    downloadDescription: 'To prove your financial operations, download the funds transfer request.',
    routingNumber: 'Routing number:',
    accountNumber: 'Account number:',
    accountHolder: 'Account holder::',
    BICCode: 'BIC code:',
    IBAN: 'IBAN:',
    country: 'Country:',
    downloadButton: 'Download funds transfer request',
  },

  withCopy: {
    copy: ' Copy ',
    copied: 'Copied',
  },

  payments: {
    title: 'Payments',
    readyForPayment: 'Ready for payment',
    history: 'History',
    topUp: 'Top-up',
    withdraw: 'Withdraw',
    accountsNames: {
      usd: 'US Dollar account',
      eur: 'EURO account',
    },
  },

  paymentAccount: {
    title: '{{currency}} Account',
    backButton: 'Payments',
    totalBalance: 'Total balance',
    moneyMovement: 'Money movement',
    all: 'All transactions',
    incoming: 'Incoming',
    outgoing: 'Outgoing',
  },

  paymentAccountAllTransactionsTab: {
    nothingFoundTitle: 'No transactions yet',
    nothingFoundDescription:
      'You’ll see them after you top up your account or\n pay your influencers.',
  },

  paymentAccountIncomingTab: {
    nothingFoundTitle: 'No incoming transactions yet',
    nothingFoundDescription: 'You’ll see them after you top up your account.',
  },

  paymentAccountOutgoingTab: {
    nothingFoundTitle: 'No outgoing transactions yet',
    nothingFoundDescription: 'You’ll see them after you pay your influencers.',
  },

  paymentAccountTransactionsTable: {
    description: 'Description',
    amount: 'Amount',
    date: 'Date',
  },

  emptyDealContacts: {
    noAccess: 'Seems like you don’t have rights to add the contact.',
  },

  uploadInfluencers: {
    title: 'File uploaded',
    continueButton: 'View found ({{count}})',
    downloadList: 'Download not found ({{count}})',

    matches: 'Channels found in our database',
    notFoundTitle: 'Not found',
    notFoundDescription: 'We’ll add them to our database within 24 hours',
    damaged: 'Broken links',
  },

  welcomePayment: {
    title: 'Let’s make your first\n payment',
    buttonText: 'Make payment',
    description: 'Easily pay influencers for their services in our platform. You can also:',
    firstPoint: 'Pay safely across the world',
    secondPoint: 'Have a low fee per transaction',
    thirdPoint: 'Top up your account quickly',
  },

  addYoutubeChannels: {
    hint: 'We’ll add your YouTube channels to our database within 24 hours.',
    channelFieldLabel: 'Enter YouTube channel’s URLs',
    channelFieldDescription: 'Enter a URL and press “Enter”',
    modalButton: 'Add channels',
    title: 'Add YouTube channels',
    description: 'Add the channels you haven’t found to our database',
    requiredError: 'Enter your channel’s URL.',
    channelError: 'Check your URL format and enter it again.',
    notificationTitle: 'Channels added: {{count}}',
    notificationDescription: 'We’ll add them to our database within 24 hours.',
    notificationOnlyChannelsExistedTitle: 'Channels already exist in our database.',
    notificationOnlyChannelExistedTitle: 'Channel already exist in our database.',
  },

  firstMessageAi: {
    editButton: 'Edit details',
    title: 'Create your first message with AI',
    descriptionText: 'Increase your response rate by creating a customized email',
    badResultFillField: 'Let’s fill in your fields to increase your response rate.',
    goodResultFillField: 'Great job! Just fill in a few fields.',
    perfectResultFillField: 'Everything looks great. Let’s create your message.',

    formFields: {
      agencyName: 'Company name',
      brandName: 'Brand name',
      productName: 'Product name',
      productDescription: 'Product description',
      notes: 'Additional notes:',
      placeholderDescription:
        'Enter a link or other product info the influencer should include in your campaign',
      placeholderNotes: 'Write something about your company or collaboration offer',
      createMessageButton: 'Create message',
    },

    formLabelToUiText: {
      agencyName: 'Company',
      brandName: 'Brand',
      productName: 'Product',
      productDescription: 'Product description',
      notes: 'Additional notes',
    },
  },

  workspaceRoleNames: {
    owner: 'Owner',
    admin: 'Admin',
    IMManager: 'Influencer Marketing Manager',
    IMPaymentSpecialist: 'Payment Manager',
  },

  inviteTeammatesDialog: {
    title: 'Invite teammates',
    sendInvitation: 'Send invitation (1/1)',
    sendInvitations: 'Send invitations ({{emailsCount}}/{{seatsCount}})',
    seats: 'Empty seats: {{emptySeatsCount}}/{{seatsCount}}',
    emailsLabel: 'Emails',
    emailsDescription:
      'You can invite {{seatsCount}} teammates to your workspace.\nEnter an email and press “Enter.”',
    emailDescription:
      'You can invite 1 teammate to your workspace.\nEnter an email and press “Enter.”',
    youHaveemptySeat: 'You have 1 empty seat so you can invite 1 teammate',
    youHaveemptySeats:
      'You have {{seatsCount}} empty seats so you can invite {{seatsCount}} teammates',
  },

  workspaceRolesField: {
    rolesLabel: 'Select workspace roles',
  },

  rolesDescription: {
    linkLabel: 'Discover details',
    rolesTitle: 'About workspace roles',
    rolesDescription: 'To view info, select a workspace role.',
    descriptions: {
      admin:
        ' manages your workspace, contacts, campaigns, and subscriptions. This role has the same rights as the\nowner.',
      IMManager: ' has the rights to manage your contacts and campaigns.',
      IMPaymentSpecialist:
        ' gets only access to the Payments section. Assign this role to your teammates who responsible for payments.',
    },
  },

  editTeammateForm: {
    fullNameError: 'Enter a full name.',
    jobTitleError: 'Enter a job title.',
  },

  editTeammateDialog: {
    title: 'Edit a teammate',
    saveButton: 'Save changes',
  },

  paymentsList: {
    influencer: 'Influencer',
    amount: 'Amount',
    amountDescription:
      'Shows the local currency’s amount from your contract. The dollar amount indicates how much you can pay from your US Dollar account.',
    amountHistoryTabDescription:
      'Shows the local currency’s amount in dollars paid from your US Dollar account. If you’ve paid from your EUR account, you see the amount only in euro.',
    dateColumn: 'Due date',
    dateColumnHistoryTab: 'Payment Sent on',
    dueDateDescription: 'Shows the last date for paying your influencer.',
    dueDateHistoryTabDescription: 'Shows the date when we’ve sent your money to the influencer.',
    assignee: 'Assignee',
    assigneesContactEmail: 'Assignee’s contact email',
    indicatorTooltip:
      'Save money by paying from your\n{{accountName}}. No conversion fee is\nincluded.',
  },

  paymentsReadyForPayment: {
    noDataMessage: 'No payments ready yet',
    noDataDescription: "You'll see them after your company and your influencer\nsign the contract.",
    payFrom: 'Pay from {{accountName}}',
    disableTooltip: 'To review and pay,\nselect your payments.',
  },

  paymentsHistory: {
    noDataMessage: 'No payment history yet',
    noDataDescription: 'After you make your payments, you’ll see them here.',
    paymentsSent: 'Payments sent',
    paymentsCanceled: 'Payments canceled',
  },

  paymentActionsCell: {
    contract: 'Go to contract',
    deal: 'Go to deal',
  },

  paymentsGroupActions: {
    title: 'payments selected: {{selectedPaymentsCount}}',
    description:
      'The fixed operating cost doesn’t depend on the number of payments. So you can save money by paying several influencers at once.',
    openPaymentDialogButton: 'Review and pay',
    amount: 'amount, ALL',
  },

  paymentAmountMetricTooltip: {
    subtotal: 'Subtotal',
    ourFee: 'Our fee',
    conversionFee: 'Conversion fee',
    crossBorderFee: 'Cross-border fee',
    fixedOperatingCost: 'Fixed operating cost',
    total: 'Total',
  },

  reviewPaymentsReviewStep: {
    title: 'Review your payments',
    countDescription: 'You have payments',
    subtotal: 'Subtotal',
    ourFee: 'Our fee',
    conversionFee: 'Conversion fee',
    crossBorderFee: 'Cross-border fee',
    fixedOperatingCost: 'Fixed operating cost',
    total: 'Total',
    payButton: 'Review and pay',
  },

  reviewPaymentsInProgressStep: {
    description: 'We’re processing your\n payment...',
  },

  reviewPaymentsSucessStep: {
    title: 'Yay!\nPayment is on the way',
    description:
      'We’ve sent {{total}} from your\n {{accountName}} to {{paymentsCount}} influencers.\n\nCheck your payment details in the “Ready for\n payment” or “History” tabs.',
  },

  reviewPaymentsFailStep: {
    title: 'We couldn’t process\nyour payment',
    description:
      'Try paying {{total}} to your influencers\n later. Or check if you have enough money\n on your account.',
  },

  paymentAlternativeMethodIndicator: {
    account: '{{currencySign}} acct.',
  },

  paymentTransactionDescription: {
    in: 'Payment to Hypetrain',
    outOne: '1 payment',
    outSeveral: '{{transfers}} payments',
  },

  workspaceInfo: {
    title: 'Edit your workspace info',
    subdomainDescription: 'Write your company name or something familiar to your team',
    saveButton: 'Save changes',
    saveNotification: 'Changes saved.',
  },

  editor: {
    dealChat: {
      descriptionSkeleton: 'We’ll create your message in up to 30 seconds...',
    },
  },

  academyDisclaimer: {
    title: 'We don’t send messages \nto real people',
    description:
      'The influencers aren’t real people in our simulator. We \nuse them for learning purposes. We won’t send your \nmessages to real people except our team. So they can \nanalyze the course data and improve your learning \nexperience.',
    buttonText: 'Got it',
  },
};
