/*
 * Сервис для работы с webSocket-нотификациями о сообщениях в сделке компании.
 */
import { NotificationContext, NotificationType, UserNotification } from '@hypetrainCommon';
import {
  TWebSocketMessageCallback,
  TWebSocketMessageHandler,
  WebSocketService,
} from '@api/webSocket';
import { handleMassMailingChangeStatus } from '@api/webSocket/campaignDealMessages/campaignDealMessages.socket.utils';

const MESSAGE_INCOME_HANDLER_ID = 'MESSAGE_INCOME_HANDLER';
const MESSAGE_STATUS_CHANGED_HANDLER_ID = 'MESSAGE_STATUS_CHANGED_HANDLER';

export class CampaignDealMessagesSocketService {
  private handlers: TWebSocketMessageHandler[] = [];

  constructor(private readonly socket: WebSocketService) {
    this.init();
  }

  /*
   * Инициализация.
   * Слушает целевой топик CampaignDealMessages, отправляя все поступившие нотификации в менеджер (обработчик).
   */
  private init = (): void => {
    this.socket.on(NotificationContext.CampaignDealMessages, this.messageManager);
    this.onMassMailingStatusChange(); // Реагируем на эти нотификации в любой части приложения - запускам случшатель сразу на init сервиса.
  };

  /*
   * Отписывается от просушиваемого топика, очищает список обработчиков.
   */
  public destroy = (): void => {
    this.socket.off(NotificationContext.CampaignDealMessages, this.messageManager);
    this.handlers = [];
  };

  /*
   * Подписка на нотификацию о входящем сообщении.
   */
  public onMessageIncome = (chatsIds: string[], cb: TWebSocketMessageCallback): void => {
    const selector = (notification: UserNotification): boolean =>
      notification?.type === NotificationType.messageIncome &&
      chatsIds.includes(notification?.data?.chatId);

    this.handlers.push({
      id: MESSAGE_INCOME_HANDLER_ID,
      selector,
      cb,
    });
  };

  /*
   * Подписка на нотификацию об изменении статуса сообщения.
   */
  public onMessageStatusChanges = (chatsIds: string[], cb: TWebSocketMessageCallback): void => {
    const selector = (notification: UserNotification): boolean =>
      notification?.type === NotificationType.messageStatusChanged &&
      chatsIds.includes(notification?.data?.chatId);

    this.handlers.push({
      id: MESSAGE_STATUS_CHANGED_HANDLER_ID,
      selector,
      cb,
    });
  };

  /*
   * Подписка на нотификацию о доставке сообщений при массовой рассылке.
   */
  public onMassMailingStatusChange = (): void => {
    const selector = (notification: UserNotification): boolean =>
      notification?.type === NotificationType.bulkMessagingFinished;

    this.handlers.push({
      id: MESSAGE_INCOME_HANDLER_ID,
      selector,
      cb: handleMassMailingChangeStatus,
    });
  };

  /*
   * Отписка от нотификации о входящем сообщении.
   */
  public offMessageIncome = (): void => {
    this.off(MESSAGE_INCOME_HANDLER_ID);
  };

  /*
   * Отписка от нотификации об изменении статуса сообщения.
   */
  public offMessageStatusChanges = (): void => {
    this.off(MESSAGE_STATUS_CHANGED_HANDLER_ID);
  };

  /*
   * Отписка.
   */
  private off = (deleteHandlerId: string): void => {
    this.handlers = this.handlers.filter((handler) => handler.id !== deleteHandlerId);
  };

  /*
   * Менеджер обрабатывающий WebSocket-сообщения топика CampaignDealMessages.
   * Вызывает селекторы каждого обработчика, что бы понять нужно ли вызывать его callback.
   */
  private messageManager = (notification: UserNotification, ack: () => void): void => {
    this.handlers.forEach((handler) => {
      if (handler.selector(notification)) {
        ack();
        handler.cb(notification);
      }
    });
  };
}

export const createCampaignDealMessagesSocketService = (webSocketService: WebSocketService) =>
  new CampaignDealMessagesSocketService(webSocketService);
