// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".item__oi6CP{display:flex;align-items:center;cursor:pointer;padding:2px 3px}.item__oi6CP img{margin-right:var(--vSpacing4)}.warning__CDqk3{background:var(--indicatorActionAttention);padding:0 var(--vSpacing16);border-radius:2px}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Tooltip/components/TooltipActionWithConfirmation/TooltipActionWithConfirmation.module.scss"],"names":[],"mappings":"AAEA,aACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CAEA,iBACE,6BAAA,CAIJ,gBACE,0CAAA,CACA,2BAAA,CACA,iBAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.item {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  padding: 2px 3px;\n\n  img {\n    margin-right: var(--vSpacing4);\n  }\n}\n\n.warning {\n  background: var(--indicatorActionAttention);\n  padding: 0 var(--vSpacing16);\n  border-radius: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "item__oi6CP",
	"warning": "warning__CDqk3"
};
export default ___CSS_LOADER_EXPORT___;
