/*
 * Список уведомлений
 */
import cn from 'clsx';
import { FC, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Notification } from '@uikit/components/Notification/Notification.components';
import { TNotificationsConfig } from '@uikit/components/Notification/notification.types';
import { Portal } from '@uikit/components/Portal';
import style from './NotificationsList.module.scss';

export type TNotificationsListProps = {
  notifications: TNotificationsConfig[];
};

export const NotificationsList: FC<TNotificationsListProps> = ({ notifications }) => {
  const [itemEntering, setItemEntering] = useState(false);
  const [itemEnter, setItemEnter] = useState(false);

  return (
    <Portal>
      <div className={style.notificationsList}>
        <TransitionGroup
          className={cn(itemEntering && style.listItemEntering, itemEnter && style.listItemEnter)}
        >
          {notifications?.map(({ id, props }) => (
            <CSSTransition
              key={id}
              timeout={300}
              onEnter={() => setItemEnter(true)}
              onEntering={() => {
                setItemEntering(true);
              }}
              onEntered={() => {
                setItemEntering(false);
                setItemEnter(false);
              }}
              classNames={{
                enter: style.notificationEnter,
                enterActive: style.notificationEnterActive,
                exit: style.notificationExit,
                exitActive: style.notificationExitActive,
              }}
            >
              <Notification
                {...props}
                className={style.notification}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </Portal>
  );
};
