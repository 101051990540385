// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".underlineTooltip__k66tq{display:inline-flex}.underlineTooltip__content__dFNrf{color:var(--textPrimary);border-bottom:1px dashed var(--textSecondary)}.underlineTooltip__contentSize__Ng54t{display:flex;max-width:230px;padding:var(--vSpacing4)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/UnderlineTooltip/UnderlineTooltip.module.scss"],"names":[],"mappings":"AAEA,yBACE,mBAAA,CAEA,kCACE,wBAAA,CACA,6CAAA,CAGF,sCACE,YAAA,CACA,eAAA,CACA,wBAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.underlineTooltip {\n  display: inline-flex;\n\n  &__content {\n    color: var(--textPrimary);\n    border-bottom: 1px dashed var(--textSecondary);\n  }\n\n  &__contentSize {\n    display: flex;\n    max-width: 230px;\n    padding: var(--vSpacing4);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"underlineTooltip": "underlineTooltip__k66tq",
	"underlineTooltip__content": "underlineTooltip__content__dFNrf",
	"underlineTooltip__contentSize": "underlineTooltip__contentSize__Ng54t"
};
export default ___CSS_LOADER_EXPORT___;
