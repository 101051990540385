// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".groupActionsButtonList__y9liw{display:flex;align-items:center}.groupActionsButtonList__list__lrEe2{display:flex}.groupActionsButtonList__item__hAP2_:not(:last-child){margin-right:var(--hSpacing16)}.groupActionsButtonList__tip__fMYXb{margin-right:var(--hSpacing16);width:fit-content}.groupActionsButtonList__button__YW3fT{margin-top:0}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/GroupActions/component/GroupActionsButtonList/GroupActionsButtonList.module.scss"],"names":[],"mappings":"AAEA,+BACE,YAAA,CACA,kBAAA,CAEA,qCACE,YAAA,CAGF,sDACE,8BAAA,CAGF,oCACE,8BAAA,CACA,iBAAA,CAGF,uCACE,YAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.groupActionsButtonList {\n  display: flex;\n  align-items: center;\n\n  &__list {\n    display: flex;\n  }\n\n  &__item:not(:last-child) {\n    margin-right: var(--hSpacing16);\n  }\n\n  &__tip {\n    margin-right: var(--hSpacing16);\n    width: fit-content;\n  }\n\n  &__button {\n    margin-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groupActionsButtonList": "groupActionsButtonList__y9liw",
	"groupActionsButtonList__list": "groupActionsButtonList__list__lrEe2",
	"groupActionsButtonList__item": "groupActionsButtonList__item__hAP2_",
	"groupActionsButtonList__tip": "groupActionsButtonList__tip__fMYXb",
	"groupActionsButtonList__button": "groupActionsButtonList__button__YW3fT"
};
export default ___CSS_LOADER_EXPORT___;
