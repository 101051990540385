import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { Button, BUTTON_TYPE } from '@uikit/components/Buttons';
import { Icon, ICON_TYPES } from '@uikit/components/Icon';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import styles from './NothingFound.module.scss';

export type TNothingFoundProps = PropsWithChildren<{
  message?: string; // displayed message
  description?: string;
  actions?: { buttonText: string; buttonType?: BUTTON_TYPE; onClick: () => void }[];
  className?: string;
}>;

export const NothingFound: FC<TNothingFoundProps> = ({
  message,
  description,
  actions,
  className,
}) => {
  const { t } = useUiKitTranslation();

  return (
    <div className={cn(styles.nothingFound, className)}>
      <div className={styles.nothingFound__iconContainer}>
        <Icon type={ICON_TYPES.SEARCH} />
      </div>
      <div className={styles.nothingFound__title}>{message || t('nothingFound.title')}</div>
      {description && <div className={styles.nothingFound__description}>{description}</div>}
      <div className={styles.nothingFound__buttonContainer}>
        {actions &&
          actions.map((action, index) => (
            <Button
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={styles.nothingFound__button}
              onClick={action.onClick}
              buttonType={action.buttonType}
            >
              {action.buttonText}
            </Button>
          ))}
      </div>
    </div>
  );
};
