/*
 * Компонент-заглушка выводимый в случе падения фатальной JS-ошибки.
 */
import cn from 'clsx';
import { FC } from 'react';
import errorBoundaryFlag from '@uikit/assets/images/errorBoundaryFlag.svg';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import styles from './ErrorStub.module.scss';

type TErrorStubProps = {
  className?: string;
};

export const ErrorStub: FC<TErrorStubProps> = ({ className }) => {
  const { t } = useUiKitTranslation();

  return (
    <div className={cn(styles.errorStub, className)}>
      <img
        width={40}
        height={40}
        src={errorBoundaryFlag}
        alt="error icon"
      />
      <div className={styles.errorStub__text}>{t('errorStub.weHavingAProblem')}</div>
    </div>
  );
};
