// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".globalLoader__qvji7{position:absolute;top:0;z-index:var(--globalLoaderZIndex)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Loader/components/GlobalLoader/GlobalLoader.module.scss"],"names":[],"mappings":"AAEA,qBACE,iBAAA,CACA,KAAA,CACA,iCAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.globalLoader {\n  position: absolute;\n  top: 0;\n  z-index: var(--globalLoaderZIndex);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"globalLoader": "globalLoader__qvji7"
};
export default ___CSS_LOADER_EXPORT___;
