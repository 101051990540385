import cn from 'clsx';
import { NoteIcon } from 'components/NoteIcon';
import { FC, ReactElement, memo } from 'react';
import { SmaPlatform, VerificationStatus } from '@hypetrainCommon';
import { TextEllipsis } from '@uikit/components/TextEllipsis';
import { VerificationIcon } from '@components/VerificationIcon';
import styles from './ChannelName.module.scss';

type TChannelName = {
  title: string | ReactElement;
  verificationStatus?: VerificationStatus;
  platform: SmaPlatform;
  channelNote?: string;
  handler?: string;
  onClick?: () => void;
};

export const ChannelName: FC<TChannelName> = memo(
  ({ verificationStatus, title, channelNote, handler, platform, onClick }) => (
    <div
      className={cn(
        styles.channelName__nameBlock,
        platform !== SmaPlatform.Youtube && styles.channelName__nameBlock_iqdata
      )}
    >
      <TextEllipsis
        className={cn(
          styles.channelName__name,
          platform !== SmaPlatform.Youtube && styles.channelName__name_iqdata
        )}
        onClick={onClick}
      >
        {title}
      </TextEllipsis>
      {handler && platform !== SmaPlatform.Youtube && (
        <TextEllipsis className={styles.channelName__extarnalName}>{handler}</TextEllipsis>
      )}
      {verificationStatus && (
        <VerificationIcon
          verificationStatus={verificationStatus}
          platform={platform}
          className={styles.channelName__verificationStatus}
        />
      )}
      {channelNote && <NoteIcon>{channelNote}</NoteIcon>}
    </div>
  )
);
