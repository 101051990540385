/*
 * Компонент опции в меню выбора воркспейса
 */
import { useStore } from 'effector-react/compat';
import { FC } from 'react';
import { IPersonWorkspaceResponseDto, SubscriptionStatusType } from '@hypetrainCommon';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { TextEllipsis } from '@uikit/components/TextEllipsis';
import checkIcon from '@assets/images/check.svg';
import { redirectToApp } from '@utils/redirect.utils';
import { isWorkspaceAdmin } from '@utils/workspace.utils';
import { $activeWorkspaceStore } from '@models/activeWorkspace';
import { MenuSubscriptionBadge } from '@components/Background/components/NavigationPanel/components/WorkspacesMenu/components/MenuSubscriptionBadge';
import styles from './WorkspaceMenuItem.module.scss';

export const WorkspaceMenuItem: FC<IPersonWorkspaceResponseDto> = ({
  workspace,
  person,
  subscription,
}) => {
  const activeWorkspace = useStore($activeWorkspaceStore);

  const isSubscriptionWithoutProblems = [
    SubscriptionStatusType.active,
    SubscriptionStatusType.trialing,
  ].includes(subscription?.status as SubscriptionStatusType);

  const redirect = () => {
    redirectToApp(workspace?.handler || '');
  };

  return (
    <div
      className={styles.workspaceMenuItem}
      onClick={redirect}
    >
      <div className={styles.workspaceMenuItem__topBlock}>
        <div className={styles.workspaceMenuItem__topBlock__nameBlock}>
          {isWorkspaceAdmin(person) && (
            <Icon
              className={styles.workspaceMenuItem__topBlock__nameBlock__crown}
              type={ICON_TYPES.CROWN}
              style={ICON_STYLES.WARNING}
            />
          )}
          <TextEllipsis className={styles.workspaceMenuItem__nameText}>
            {workspace.name}
          </TextEllipsis>
          {workspace.id === activeWorkspace?.workspace?.id && (
            <img
              src={checkIcon}
              className={styles.workspaceMenuItem__topBlock__nameBlock__check}
              alt="checkIcon"
            />
          )}
        </div>

        <div className={styles.workspaceMenuItem__topBlock__personsCount}>
          {workspace.personsCount}
        </div>
      </div>
      <h4 className={styles.workspaceMenuItem__h3}>
        {!isSubscriptionWithoutProblems && (
          <>
            <MenuSubscriptionBadge subscription={subscription} />
            <div className={styles.workspaceMenuItem__h3__divider} />
          </>
        )}
        <div className={styles.workspaceMenuItem__h3__personName}>{person.name}</div>
      </h4>
    </div>
  );
};
