/*
 *   Компонент для отрисовки legal entity в настройках воркспейса
 */
import { useStore } from 'effector-react/compat';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Scroll } from '@uikit/components/Scroll';
import { LegalEntityAddAddress } from '@pagesWorkspaceSettings/components/LegalEntity/components/LegalEntityAddAddress/LegalEntityAddAddress.component';
import { LegalEntityCard } from '@pagesWorkspaceSettings/components/LegalEntity/components/LegalEntityCard/LegalEntityCard.component';
import { LegalEntityInfo } from '@pagesWorkspaceSettings/components/LegalEntity/components/LegalEntityInfo';
import {
  $legalEntitiesStore,
  getLegalEntitiesFx,
} from '@pagesWorkspaceSettings/components/LegalEntity/model';
import styles from './LegalEntity.module.scss';

export const LegalEntity: FC = () => {
  const { t } = useTranslation();
  const legalEntitiesStore = useStore($legalEntitiesStore);

  useEffect(() => {
    getLegalEntitiesFx();
  }, []);

  return (
    <Scroll>
      <div className={styles.legalEntity}>
        <div className={styles.legalEntity__header}>
          <h3>{t('workspaceSettings.legalEntity.header')}</h3>
          <div className={styles.legalEntity__headerDescription}>
            {t('workspaceSettings.legalEntity.description')}
          </div>
        </div>

        <div className={styles.legalEntity__body}>
          <LegalEntityCard>
            <LegalEntityAddAddress />
          </LegalEntityCard>
          {legalEntitiesStore.map((legalEntity) => (
            <LegalEntityCard key={legalEntity.id}>
              <LegalEntityInfo legalEntity={legalEntity} />
            </LegalEntityCard>
          ))}
        </div>
      </div>
    </Scroll>
  );
};
