import { createEvent, createStore } from 'effector/compat';
import { signUpUserFx } from '@pagesSignUp/signUp.model';

const resetStore = createEvent();

const changeStep = createEvent<number>();

const $registrationInvitationStore = createStore(1);

$registrationInvitationStore
  .on(signUpUserFx.doneData, () => 2)
  .on(changeStep, (state, step) => step)
  .reset(resetStore);

export { $registrationInvitationStore, changeStep };
