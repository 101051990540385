import { DealTermsCountries, DealTermsCountryCurrencies, countriesNames } from '@hypetrainCommon';
import { TRadioGroupFormFieldConfig } from 'hypetrain-ui/src/components/Fields/RadioGroupFormField/RadioGroupFormField.types';

// Формируем данные для того что бы по коду получить название страны
export const isoCountries = Object.entries(countriesNames).reduce((acc, [key, country]) => {
  acc[key] = country.name;
  return acc;
}, {} as Record<string, string>);

export const getCountryName = (countryCode: string) => isoCountries[countryCode] || countryCode;

// Формируем массив обьектов в наш формат [{value: string, label: string}]
export const getCountryOptions = () =>
  Object.entries(countriesNames)
    // выводим опции только у которых isDisplay === true, т.к. под false храняться страные которые не поддерживает бэк
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, country]) => country.isDisplay)
    .map(([key, country]) => ({
      value: key,
      label: country.name,
    }));

export const getSupportedCountriesForPayments = () =>
  Object.entries(DealTermsCountryCurrencies).flatMap((termsCountry) => {
    const matchingItem = Object.entries(countriesNames).find(
      (country) => country[0] === termsCountry[0]
    );

    return matchingItem ? [{ value: matchingItem[0], label: matchingItem[1]?.name }] : [];
  });

export const getSupportedCurrencyForPayments = (
  country: DealTermsCountries
): TRadioGroupFormFieldConfig[] => {
  const res: TRadioGroupFormFieldConfig[] = [];
  DealTermsCountryCurrencies[country]?.map((currency) =>
    res.push({
      label: currency,
      value: currency,
      disable: false,
    })
  );

  return res;
};
