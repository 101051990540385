/*
 * Сервис логирования пользовательских действий.
 */
import { init, reset, setUserId, track } from '@amplitude/analytics-browser';
import { IGetUserProfileResponseDto } from '@hypetrainCommon';
import { USERS_ACTIONS } from '@services/usersActionsLog/usersActions.enum';

/* eslint-disable class-methods-use-this */
export class UsersActionsLogService {
  private userProfile: IGetUserProfileResponseDto | null = null;

  constructor() {
    reset();
    init(process.env.AMPLITUDE_API_KEY);
  }

  /*
   * Инициализация пользователя системы в сервисе.
   */
  public initUser = (userProfile: IGetUserProfileResponseDto): void => {
    this.userProfile = userProfile;
    setUserId(userProfile?.uuid);
  };

  /*
   * Логирование пользовательского дейстивия.
   */
  public log = (
    eventName: USERS_ACTIONS,
    eventProperties: Record<string, unknown> = {},
    userProperties: Record<string, unknown> = {}
  ): void => {
    track(
      {
        event_type: eventName,
        user_properties: {
          userUuid: this.userProfile?.uuid,
          userEmail: this.userProfile?.email,
          ...userProperties,
        },
      },
      {
        ...eventProperties,
      }
    );
  };
}

const createUsersActionsLogService = (): UsersActionsLogService => new UsersActionsLogService();

export const usersActionsLogService = createUsersActionsLogService();
