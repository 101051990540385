/*
 * Компонент таблицы платежей, готовых к оплате.
 */
import { useStore } from 'effector-react/compat';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency } from '@hypetrainCommon';
import { GroupActions, TGroupAction } from '@uikit/components/GroupActions';
import { modalService } from '@uikit/components/Modal';
import { VirtualizedTable } from '@uikit/components/Table';
import emptyIcon from '@assets/images/addressBook/emptyAddressBook.svg';
import { ReviewPaymentsModal } from '@pagesPaymentsPage/components/ReviewPaymentsModal';
import { useSelectedPayments } from '@pagesPaymentsPage/hooks/useSelectedPayments.hook';
import { getPaymentsColumns } from '@pagesPaymentsPage/payments.colums';
import { DEFAULT_PAYMENTS_CURRENCY_FILTER } from '@pagesPaymentsPage/payments.constants';
import {
  $payments,
  $paymentsCurrencyFilter,
  getEURReadyPaymnetsFx,
  getUSDReadyPaymnetsFx,
  onLoadMorePaymentsFx,
} from '@pagesPaymentsPage/payments.model';
import { PaymentAmountMetricValue } from './components/PaymentAmountMetricValue';
import { ReadyForPaymentFilters } from './components/ReadyForPaymentFilters';
import styles from './PaymentsReadyForPayment.module.scss';

export const PaymentsReadyForPayment: FC = () => {
  const { t } = useTranslation();
  const currencyFilter = useStore($paymentsCurrencyFilter);
  const isUSDLoading = useStore(getUSDReadyPaymnetsFx.pending);
  const isEURLoading = useStore(getEURReadyPaymnetsFx.pending);
  const allLoadedPayments = useStore($payments);
  const selectedPayments = useSelectedPayments();

  const selectedPaymentsCount = selectedPayments?.length;
  const payments = allLoadedPayments[currencyFilter];
  const isLoading = currencyFilter === Currency.USD ? isUSDLoading : isEURLoading;

  const openReadyPaymentsDialog = () => {
    modalService.open({ component: ReviewPaymentsModal }).then(() => window.location.reload());
  };

  const executePaymentsAction: TGroupAction = {
    handler: openReadyPaymentsDialog,
    button: { disabled: !selectedPaymentsCount },
    name: t('paymentsGroupActions.openPaymentDialogButton'),
    tooltip: !selectedPaymentsCount ? t('paymentsReadyForPayment.disableTooltip') : '',
  };

  const columns = useMemo(() => getPaymentsColumns(true), []);

  useEffect(() => {
    // Первоначальная загрузка платежей для фильтра по умолчанию.
    if (!allLoadedPayments[DEFAULT_PAYMENTS_CURRENCY_FILTER]) {
      getUSDReadyPaymnetsFx();
    }
  }, []);

  return (
    <div className={styles.paymentsReadyForPayment}>
      <ReadyForPaymentFilters />
      <div className={styles.paymentsReadyForPayment__table}>
        <VirtualizedTable
          data={payments?.results || []}
          columns={columns}
          isLoading={isLoading}
          itemHeight={48}
          totalCount={payments?.total}
          noDataOptions={{
            title: t('paymentsReadyForPayment.noDataMessage'),
            description: t('paymentsReadyForPayment.noDataDescription'),
            img: emptyIcon,
            useMediumTitle: true,
          }}
          loadOnScroll={() => onLoadMorePaymentsFx(currencyFilter)}
        />
      </div>
      <GroupActions
        title={t('paymentsGroupActions.title', { selectedPaymentsCount })}
        actions={[executePaymentsAction]}
        actionsTip={payments?.results?.length ? t('paymentsGroupActions.description') : ''}
        metrics={[
          {
            value: <PaymentAmountMetricValue />,
            title: t('paymentsGroupActions.amount'),
          },
        ]}
      />
    </div>
  );
};
