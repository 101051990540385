/*
 * Sorts configuration for campaign.
 */
import { CampaignDealsSortName, YoutubeSmaStatisticsSortName } from '@hypetrainCommon';
import { TAvailableSort } from '@uikit/components/Sorts/sorts.types';
import i18n from '@services/i18n';

export const AVAILABLE_CAMPAIGN_SORTS_CONFIG: TAvailableSort[] = [
  {
    id: YoutubeSmaStatisticsSortName.SubscriberCount,
    label: i18n.t('subscriberCount'),
    testId: 'YT_SUBSCRIBERS_COUNT_SORT',
  },
  {
    id: YoutubeSmaStatisticsSortName.Reach,
    label: i18n.t('viewsSortLabel'),
    extra: { day: '30' },
    testId: 'YT_VIEWS_SORT',
  },
  {
    id: YoutubeSmaStatisticsSortName.EngagementRate,
    label: i18n.t('engagementRate'),
    extra: { day: '30' },
    testId: 'YT_ER_SORT',
  },
  {
    id: YoutubeSmaStatisticsSortName.PriceReach,
    label: i18n.t('priceReach'),
    testId: 'YT_PRICE_SORT',
  },
  {
    id: YoutubeSmaStatisticsSortName.ProjectedCPM,
    label: i18n.t('cpmCaplLock'),
    extra: { day: '30' },
    testId: 'YT_CPM_SORT',
  },
  {
    id: CampaignDealsSortName.NewMessages,
    label: i18n.t('sorts.youtube.newMessagesLabel'),
    toggleMode: true,
    switcherLabel: i18n.t('sorts.youtube.newMessages'),
    testId: 'YT_NEW_MESSAGES_SORT',
  },
];
