import { nanoid } from 'nanoid';
import { FC } from 'react';
import { OverlaysStore, overlaysStore } from './overlays.store';
import { TOverlayConfig } from './overlays.types';

export class OverlaysService {
  constructor(private readonly store: OverlaysStore) {}

  public addOverlay<P = Record<string, unknown>>(component: FC<P>, props: P): string {
    const id = nanoid();
    const overlayConfig: TOverlayConfig<P> = {
      id,
      component,
      props,
    };

    this.store.add<P>(overlayConfig);

    return id;
  }

  public deleteOverlay(id: string): void {
    this.store.delete(id);
  }
}

const createOverlaysService = (store: OverlaysStore): OverlaysService => new OverlaysService(store);

export const overlaysService = createOverlaysService(overlaysStore);
