/*
 * Утилиты связанные с воркспейсами.
 */
import {
  IPersonInfoResponseDto,
  IPersonSimpleInfoDto,
  IPersonWorkspaceResponseDto,
  IUserPersonInfoResponseDto,
  PersonPartyType,
  PersonRegistrationType,
  RoleName,
} from '@hypetrainCommon';
import { currentSubdomain, defaultSubdomain } from '@constants/common';
import { TWorkspaceMap } from '@models/workspaces/workspaces.model';

/*
 * Возвращает признак того что кроме текущего есть другие активные воркспейсы
 */
export const hasOtherActiveWorkspace = (
  activeWorkspace: IPersonWorkspaceResponseDto,
  workspaces: TWorkspaceMap | null
): boolean =>
  Number(
    workspaces?.active?.filter((ws) => ws.workspace?.id !== activeWorkspace?.workspace?.id).length
  ) >= 1;

/*
 * Возвращает признак персоны-члена workspace.
 */
export function isWorkspaceTeammate(person: IPersonInfoResponseDto): boolean {
  return (
    person.partyType === PersonPartyType.own &&
    person.registrationType === PersonRegistrationType.user
  );
}

/*
 * Возвращает признак персоны-владельца workspace.
 */
export function isWorkspaceOwner(
  person: IPersonInfoResponseDto | IUserPersonInfoResponseDto | IPersonSimpleInfoDto
): boolean {
  return Boolean(person?.roles?.includes(RoleName.Owner));
}

/*
 * Возвращает признак персоны-администратора workspace.
 */
export function isWorkspaceAdmin(
  person: IPersonInfoResponseDto | IUserPersonInfoResponseDto
): boolean {
  return Boolean(person?.roles?.includes(RoleName.Admin));
}

/*
 * Возвращает признак персоны-члена workspace (участника, не админа).
 */
export function isWorkspaceMember(person: IPersonInfoResponseDto): boolean {
  return isWorkspaceTeammate(person) && !isWorkspaceAdmin(person);
}

/*
 * Возвращает признак дефолтного сабдомена (app).
 */
export const isDefaultSubdomain = () => currentSubdomain === defaultSubdomain;

/*
 * Возвращает признак открытия приложения принадлежащего чужому аккаунту.
 */
export const isAnothersAccount = (workspaceMap: TWorkspaceMap | null): boolean => {
  const workspaces: IPersonWorkspaceResponseDto[] = workspaceMap
    ? [...workspaceMap.active, ...workspaceMap.inactive]
    : [];

  return !workspaces.some((workspace) => workspace?.workspace?.handler === currentSubdomain);
};
