/*
 * Глобальный сервис уведомлений (выводятся снизу слева в виде всплывающих окон)
 */
import { nanoid } from 'nanoid';
import {
  NOTIFICATION_TYPES,
  TAddNotificationsConfig,
  TNotificationProps,
  TNotificationsConfig,
} from './notification.types';
import { NotificationsStore, notificationsStore } from './notifications.store';

export class NotificationsService {
  constructor(private readonly store: NotificationsStore) {}

  /*
   * Отобразить Info-сообщение.
   */
  public info(config: TAddNotificationsConfig): void {
    this.push({
      ...config,
      props: {
        type: NOTIFICATION_TYPES.INFO,
        caption: config.props?.caption || '',
        ...(config.props || {}),
      },
    });
  }

  /*
   * Отобразить successful-сообщение.
   */
  public successful(config: TAddNotificationsConfig): void {
    this.push({
      timeout: 5000,
      closable: true,
      ...config,
      props: {
        type: NOTIFICATION_TYPES.SUCCESSFUL,
        caption: config.props?.caption || '',
        ...(config.props || {}),
        ...config.props,
      },
    });
  }

  /*
   * Отобразить error-сообщение.
   */
  public error(config: TAddNotificationsConfig): void {
    this.push({
      timeout: 5000,
      closable: true,
      ...config,
      props: {
        type: NOTIFICATION_TYPES.ERROR,
        caption: config.props?.caption || '',
        ...(config.props || {}),
        ...config.props,
      },
    });
  }

  /*
   * Добавить уведомление.
   */
  private push(config: TAddNotificationsConfig): void {
    const id = nanoid();
    const props = {
      ...(config.props || ({} as TNotificationProps)),
      onClose: config.closable ? () => this.store.delete(id) : undefined,
    };

    const notificationsConfig: TNotificationsConfig = {
      props,
      id,
    };

    this.store.add(notificationsConfig);
    if (config.timeout) {
      setTimeout(() => {
        this.store.delete(id);
      }, config.timeout);
    }
  }
}

export const notificationsService = new NotificationsService(notificationsStore);
