import { Country, SmaAudienceCountryValue, SmaAudienceLanguageValue } from '@hypetrainCommon';
import { TFilter } from '@uikit/components/Filters/filters.types';
import i18n from '@services/i18n';
import { INSTAGRAM_AUDIENCE_TYPES } from '@components/AudienceDemographic/audience.constant';

export const isIncludeCountry = (list: string[], country: string): string =>
  list?.find((el) => el === country) || '';

export const getAudienceGraphicColor = (
  country: string,
  color: string,
  filterValues?: TFilter
): string => {
  if (filterValues && isIncludeCountry(filterValues.values || [], country)) {
    return `${color}`;
  }
  if (!filterValues) {
    return `${color}`;
  }
  return `${color}80`;
};

// Нужна для >5 аудиторных данных. Необходимо оставить первые 4, остальные объединить в Other
export const getLimitAudienceData = (
  data: SmaAudienceCountryValue[] | SmaAudienceLanguageValue[]
): SmaAudienceCountryValue[] | SmaAudienceLanguageValue[] => {
  if (!data) return [];
  if (data.length <= 4) return data;

  const visibleData = data.slice(0, 4);
  const other = data.slice(4, data.length).reduce((acc, country) => acc + country.value, 0);
  visibleData.push({
    key: Country.UnknownRegion,
    value: other,
  });
  return visibleData;
};

// Получем название колонки audience в зависимости в какой табе находимся: followers/likes
export const getHeaderInstagramCell = (selectedTab: string): string => {
  if (selectedTab === INSTAGRAM_AUDIENCE_TYPES.followers) {
    return i18n.t('profile.audience.followers');
  }

  return i18n.t('profile.audience.likes');
};
