// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dialog__Xm2o1{position:relative;display:flex;flex-direction:column;justify-content:space-between;padding:var(--hSpacing30) var(--vSpacing30);z-index:var(--dialogZIndex)}.dialog__header__itUlt{display:flex;flex-direction:column}.dialog__title__Y6R2D{width:100%;margin-bottom:30px}.dialog__buttons__JQLuh{display:flex;height:56px}.dialog__buttons__JQLuh>*{margin-right:var(--hSpacing16)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Dialog/dialog.module.scss"],"names":[],"mappings":"AAEA,eACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,2CAAA,CACA,2BAAA,CAEA,uBACE,YAAA,CACA,qBAAA,CAGF,sBACE,UAAA,CACA,kBAAA,CAGF,wBACE,YAAA,CACA,WAAA,CAEA,0BACE,8BAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.dialog {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: var(--hSpacing30) var(--vSpacing30);\n  z-index: var(--dialogZIndex);\n\n  &__header {\n    display: flex;\n    flex-direction: column;\n  }\n\n  &__title {\n    width: 100%;\n    margin-bottom: 30px;\n  }\n\n  &__buttons {\n    display: flex;\n    height: 56px;\n\n    & > * {\n      margin-right: var(--hSpacing16);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "dialog__Xm2o1",
	"dialog__header": "dialog__header__itUlt",
	"dialog__title": "dialog__title__Y6R2D",
	"dialog__buttons": "dialog__buttons__JQLuh"
};
export default ___CSS_LOADER_EXPORT___;
