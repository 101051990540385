// Хук для инициализации воркспейсов
import { useStore } from 'effector-react/compat';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes as Links, Routes } from '@router/Routes';
import { workspaceUserAccessService } from '@services/userAccess/workspaceUserAccess.service';
import { isAuth } from '@utils/auth.utils';
import { redirectToApp } from '@utils/redirect.utils';
import { currentSubdomain } from '@constants/common';
import { $currentWorkspace, $workspacesStore } from '@models/workspaces/workspaces.model';
import { $personInvitationStore } from '@pagesPersonInvitation/personInvitation.model';

// TODO Переписать на роутинг?
export const useInitWorkspaces = () => {
  const navigate = useNavigate();
  const currentWorkspace = useStore($currentWorkspace);
  const workspaces = useStore($workspacesStore);
  const personInvitation = useStore($personInvitationStore);

  useEffect(() => {
    if (!workspaces || !isAuth() || personInvitation.inviteId) return;

    // eslint-disable-next-line default-case
    switch (true) {
      // если у персоны нет созданных воркспейсов переходим на создание
      case !workspaces.active.length && !workspaces.inactive.length:
        navigate(Links.workspaceCreation);
        break;
      // если у персоны есть только воркспейсы где она деактивирована переходим на экран ошибки
      case !workspaces.active.length && !!workspaces.inactive.length:
        navigate(Links.noAccess);
        break;
      // если у персоны воркспейс создан через миграцию из персонального, то переходим на форму изменения хэндлера
      case workspaceUserAccessService.updateWorkspaceInfo &&
        !!currentWorkspace?.shouldChangeHandler:
        navigate(Links.editWorkspace);
        break;
      // если у персоны есть текущий воркспейс, то переходим на него
      case !!currentWorkspace?.handler && currentSubdomain !== currentWorkspace.handler:
        redirectToApp(currentWorkspace?.handler || '', Routes.discovery);
    }
  }, [currentWorkspace, workspaces, personInvitation]);
};
