/*
 * Сервис для работы с профилем
 */
import { ApiService, apiService } from '@api';
import { IAddUserFeedbackDto, IGetUserProfileResponseDto } from '@hypetrainCommon';

/* eslint-disable arrow-body-style */
export class ProfileApiService {
  constructor(private readonly api: ApiService) {}

  /*
   * Запрашивает профиль пользователя.
   * @see: https://reference.api.team-master-dev.hypetrain.io/?http#usercontroller_getprofile
   */
  public getUserProfile = (): Promise<IGetUserProfileResponseDto> => {
    return (
      this.api
        // Игнорируем ошибку при создании персонального воркспейса, так как на этом этапе у пользователя нет профиля
        .get('/users/profile', {}, { errorCodesToIgnore: ['get-workspace--personal-not-found'] })
        .then((response) => response.data)
    );
  };

  /*
   * Отправить feedback из профиля
   * @see: https://reference.api.dev.hypetrain.io/?javascript#tocS_UserFeedbackLocation
   */
  public addProfileFeedback = (body: IAddUserFeedbackDto): Promise<void> => {
    return this.api.post('/users/feedback', body).then((response) => response.data);
  };
}

export const profileApiService = new ProfileApiService(apiService);
