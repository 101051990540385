// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".textEllipsis__container__uC_am{width:100%}.textEllipsis__content__qWvoD{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.textEllipsis__tooltip__DVsBz{max-width:740px}.textEllipsis__tooltipContainer__BJOKu{overflow:hidden}.twoLine__F8DRi{display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;white-space:unset}.threeLine__EPBtQ{display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;white-space:unset}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/TextEllipsis/TextEllipsis.module.scss"],"names":[],"mappings":"AAGE,gCACE,UAAA,CAGF,8BACE,eAAA,CACA,sBAAA,CACA,kBAAA,CAGF,8BACE,eAAA,CAGF,uCACE,eAAA,CAIJ,gBACE,mBAAA,CACA,oBAAA,CACA,2BAAA,CACA,iBAAA,CAGF,kBACE,mBAAA,CACA,oBAAA,CACA,2BAAA,CACA,iBAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.textEllipsis {\n  &__container {\n    width: 100%;\n  }\n\n  &__content {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n\n  &__tooltip {\n    max-width: 740px;\n  }\n\n  &__tooltipContainer {\n    overflow: hidden;\n  }\n}\n\n.twoLine {\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  white-space: unset;\n}\n\n.threeLine {\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  white-space: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textEllipsis__container": "textEllipsis__container__uC_am",
	"textEllipsis__content": "textEllipsis__content__qWvoD",
	"textEllipsis__tooltip": "textEllipsis__tooltip__DVsBz",
	"textEllipsis__tooltipContainer": "textEllipsis__tooltipContainer__BJOKu",
	"twoLine": "twoLine__F8DRi",
	"threeLine": "threeLine__EPBtQ"
};
export default ___CSS_LOADER_EXPORT___;
