// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reviewPaymentsFailStep__GFdQW{height:100%}", "",{"version":3,"sources":["webpack://./pages/PaymentsPage/components/ReviewPaymentsModal/components/ReviewPaymentsFailStep/ReviewPaymentsFailStep.module.scss"],"names":[],"mappings":"AAEA,+BACE,WAAA","sourcesContent":["@import '../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.reviewPaymentsFailStep {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reviewPaymentsFailStep": "reviewPaymentsFailStep__GFdQW"
};
export default ___CSS_LOADER_EXPORT___;
