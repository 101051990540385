import { createEffect, createEvent, createStore, forward } from 'effector/compat';
import _cloneDeep from 'lodash/cloneDeep';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import { MessageIncomeNotification, MessageStatusChangedNotification } from '@hypetrainCommon';
import { addressBookApiService } from '@api/addressBook';
import {
  IGetCampaignDealsParams,
  TCreateCampaignDealsParams,
  TCreateMassMailingFx,
  TDeleteCampaignDealsParams,
  TGetCampaignsById,
  campaignsApiService,
} from '@api/campaigns';
import { communicationApiService } from '@api/communication/communication.api.service';
import { campaignsUserAccessService } from '@services/userAccess';
import { CONTACT_ROLE } from '@pagesAddressBook/addressBook.constants';
import { getAddressBookRole } from '@pagesAddressBook/addressBook.utils';
import {
  TAddContactToChannelParams,
  TCampaignDealListItemMassMailing,
  TCampaignStore,
} from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.types';
import {
  getDealsSend,
  updateCampaignStoreOnIncomeChatMessage,
  updateCampaignStoreOnUpdateChatMessageStatus,
} from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.utils';
import { updateCampaignFx } from '@pagesCampaigns/CampaignsPage/campaignsPage.model';
import { profileNoteChanged } from '@components/Profile/profile.model';

type TCommunicationTableInfo = {
  scrollTablePosition: number;
};

export const resetCampaignDetailStore = createEvent();

export const setSearchTerm = createEvent<string>();

export const resetSearchTerm = createEvent();

export const setCommunicationTableInfo = createEvent<TCommunicationTableInfo>();

export const resetCommunicationTableInfo = createEvent();

/*
 * Событие нотификации о новом сообщении в чате.
 */
export const incomeChatMessage = createEvent<MessageIncomeNotification>();

/*
 * Событие нотификации об изменении статуса сообщения в чате.
 */
export const chatMessageStatusChanged = createEvent<MessageStatusChangedNotification>();

/*
 * Получение информации о компании.
 */
export const getCampaignFx = createEffect((params: TGetCampaignsById) => {
  if (!campaignsUserAccessService.getCampaignById) {
    return Promise.reject();
  }

  return campaignsApiService.getCampaignsById(params);
});

/*
 * Получение deals компании.
 */
export const getCampaignDealsFx = createEffect((params: IGetCampaignDealsParams) => {
  if (!campaignsUserAccessService.getCampaignDeals) {
    return Promise.reject();
  }

  return campaignsApiService.getCampaignDeals(params);
});

/*
 * Поиск по deals компании.
 */
export const searchCampaignDealsFx = createEffect((params: IGetCampaignDealsParams) => {
  if (!campaignsUserAccessService.getCampaignDeals) {
    return Promise.reject();
  }

  return campaignsApiService.searchCampaignDeals(params);
});

/*
 * Создаёт новый контакт в addressBook.
 */
export const createContactInCampaignFx = createEffect(
  addressBookApiService.createRepresentativePerson
);

/*
 * Стор для хранения строки поиска, нужен т.к.
 * поиск может происходить на других страницах
 */
export const $searchTerm = createStore<string>('')
  .on(setSearchTerm, (state, payload) => payload)
  .reset(resetSearchTerm);

/*
 * Стор для хранения позиции таблицы
 */
export const $scrollTableInfo = createStore<TCommunicationTableInfo>({
  scrollTablePosition: 0,
})
  .on(setCommunicationTableInfo, (state, payload) => payload)
  .reset(resetCommunicationTableInfo);

/*
 * В зависимости от режима(фильтры/поиск) получает список deals.
 */
export const getCampaignDealsWithSearchFx = createEffect(
  ({ campaignId, signal }: IGetCampaignDealsParams) => {
    const requestParams: IGetCampaignDealsParams = {
      campaignId,
      signal,
      searchTerm: $searchTerm.getState() || undefined,
    };

    if ($searchTerm.getState()) return searchCampaignDealsFx(requestParams);

    return getCampaignDealsFx(requestParams);
  }
);

/*
  Массовая рассылка из Campaign
 */
export const createMassMailingFx = createEffect(
  ({ subject, message, attachmentIds, deals, campaignId }: TCreateMassMailingFx) =>
    communicationApiService.createMassMailing({
      subject,
      data: message,
      campaignId,
      attachmentIds,
      deals: getDealsSend(deals),
    })
);

/*
 * Добавление smas в компанию.
 */
export const createCampaignDealsFx = createEffect((params: TCreateCampaignDealsParams) => {
  if (!campaignsUserAccessService.addSmaInCampaign) {
    return Promise.reject();
  }

  return campaignsApiService.createCampaignDeals(params);
});

/*
 * Удаление deals компании.
 */
export const deleteDealsFromCampaignFx = createEffect((params: TDeleteCampaignDealsParams) => {
  if (!campaignsUserAccessService.deleteCampaignDeal) {
    return Promise.reject();
  }

  return campaignsApiService.deleteCampaignDeals(params);
});

/*
 * Добавление контакта к сделке.
 */
export const linkPersonToDealFX = createEffect(campaignsApiService.linkPersonToDeal);

/*
 * Добавление контакта к каналу.
 */
export const addContactToChannel = createEffect((params: TAddContactToChannelParams) =>
  createContactInCampaignFx(params.contact).then((personContact) =>
    linkPersonToDealFX({
      campaignId: params.campaignId,
      dealId: params.dealId,
      body: { personIds: [personContact.id] },
    })
  )
);

export const $campaignStore = createStore<TCampaignStore | null>(null)
  .on(getCampaignFx.doneData, (state, payload) => ({
    ...(state as TCampaignStore),
    campaignDetails: payload,
  }))
  .on(getCampaignDealsFx.doneData, (state, payload) => ({
    ...(state as TCampaignStore),
    campaignDeals: _keyBy(payload.deals, 'id'),
    unreadMessages: payload.unread,
  }))
  .on(searchCampaignDealsFx.done, (state, { params, result }) => {
    const preparedDeals = result.deals.map((deal) => ({
      ...deal,
      highlightedString: params.searchTerm,
    }));

    return {
      ...(state as TCampaignStore),
      campaignDeals: _keyBy(preparedDeals, 'id'),
    };
  })
  .on(updateCampaignFx.done, (state, payload) => ({
    ...(state as TCampaignStore),
    campaignDetails: payload.result,
  }))
  .on(incomeChatMessage, updateCampaignStoreOnIncomeChatMessage)
  .on(chatMessageStatusChanged, updateCampaignStoreOnUpdateChatMessageStatus)
  .on(profileNoteChanged, (store, payload) => {
    if (store?.campaignDeals) {
      const [changedDealId, changedDeal] = Object.entries(store?.campaignDeals).filter(
        ([, value]) => value.smaStatistics?.sma?.uuid === payload.smaId
      )[0];

      const clonedDeal = _cloneDeep(changedDeal);

      if (clonedDeal.smaStatistics?.sma) {
        clonedDeal.smaStatistics.sma.notesCount = payload.newCount;
        return {
          ...store,
          campaignDeals: { ...store.campaignDeals, [changedDealId]: clonedDeal },
        };
      }
    }

    return store;
  })
  .reset(resetCampaignDetailStore);

export const addToCampaignSelection = createEvent<string>();
export const deleteFromCampaignSelection = createEvent<string>();
export const addAllToCampaignSelection = createEvent<string[]>();
export const deleteAllFromCampaignSelection = createEvent();

export const $campaignSelectionStore = createStore<string[]>([])
  .on(addAllToCampaignSelection, (state, payload) => [...new Set([...state, ...payload])])
  .on(addToCampaignSelection, (state, payload) => [...state, payload])
  .on(deleteAllFromCampaignSelection, () => [])
  .on(deleteDealsFromCampaignFx.done, () => [])
  .on(deleteFromCampaignSelection, (state, payload) => [
    ...state.filter((deal) => deal !== payload),
  ])
  .reset(resetCampaignDetailStore);

// Обновляем стор блоггеров при взаимодействии с ними в выборе персон в диалоге Mass Mailing
export const updateDealMassMailing = createEvent<TCampaignDealListItemMassMailing[]>();

// Инициализация стора, путем копии дилов(блогеров) из стора $campaignStore и
// для каждой персоны проставляем isCheckbox: false, поскольку по дефолту они не
// учавствуют в рассылке
export const initialDealMassMailing = createEvent();

// Сброс стора $massMailingDeals до []
export const resetDealsMassMailing = createEvent();

export const $massMailingDeals = createStore<TCampaignDealListItemMassMailing[]>([])
  // делаем копию deals из $campaignStore, т.к. нужна прямая манипуляция над ними в MassMailingPanel
  // и убираем всех, у кого роль не representative
  // Если у Блогера(deal) только 1(своя) почта, то она по дефолту выбрана
  .on(initialDealMassMailing, () =>
    _map($campaignStore.getState()?.campaignDeals, (deal) => ({
      ...deal,
      persons: deal.persons
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .filter((person) => getAddressBookRole(person) === CONTACT_ROLE.REPRESENTATIVE)
        .map((person, index, array) => ({ ...person, isCheckbox: array.length === 1 })),
    }))
  )
  .on(updateDealMassMailing, (state, deals) => deals)
  .reset(resetDealsMassMailing);

forward({
  from: [deleteDealsFromCampaignFx.done.map(({ params }) => ({ campaignId: params.campaignId }))],
  to: getCampaignDealsWithSearchFx,
});

// Нужно обновить Deals при добавлении representative, что бы Mass Mailing знал что обновились персоны
forward({
  from: addContactToChannel.done.map((payload) => ({ campaignId: payload.params.campaignId })),
  to: getCampaignDealsWithSearchFx,
});

// После обновления Deals мы инициализируем MassMailing Store
forward({
  from: getCampaignDealsWithSearchFx.done,
  to: initialDealMassMailing,
});
