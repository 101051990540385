/*
 * Async select filter component
 */
import { useStore } from 'effector-react/compat';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { OptionProps } from 'react-select';
import { SmaPlatform } from '@hypetrainCommon';
import { Filter, TFilterConfig } from '@uikit/components/Filter';
import {
  $asyncSelectFilterSelectedOptionsStore,
  setSelectedOptions,
} from '@uikit/components/Filter/components/AsyncSelectFilter/asyncSelectFilter.model';
import { FilterMultiSelectOption } from '@uikit/components/Filter/components/SelectFilter/components/FilterMultiSelectOption';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { MultiSelect } from '@uikit/components/MultiSelect';
import { SelectField } from '@uikit/components/SelectField';
import { TSelectOption, TSelectOptions } from '@uikit/components/SelectField/SelectField.types';
import { noop } from '@uikit/helpers/common.helpers';
import styles from './AsyncSelectFilter.module.scss';

export type TAsyncSelectFilterConfig = {
  loadOptions: (key: string) => Promise<unknown[]>;
  optionComponent?: FC<OptionProps<any>>; // eslint-disable-line  @typescript-eslint/no-explicit-any
  placeholder?: string;
  valuePath?: string;
  viewValuePath?: string;
  needToReload?: boolean;
  itemHeight?: number;
  isMulti?: boolean;
  platform?: SmaPlatform;
};

type TAsyncSelectFilterProps = {
  config: TFilterConfig<TAsyncSelectFilterConfig>;
  onApply: (value: TFilter) => void;
  applyOnClose: boolean;
};

export const AsyncSelectFilter: FC<TAsyncSelectFilterProps> = memo(
  ({ config, onApply, applyOnClose }) => {
    const valuePath = config.filterProps.valuePath || 'value';
    const viewValuePath = config.filterProps.viewValuePath || 'label';
    const selectedValues = useStore($asyncSelectFilterSelectedOptionsStore);
    const selectedValuesPlatform =
      selectedValues[config.filterProps.platform || SmaPlatform.Youtube];
    const [filterValue, setFilterValue] = useState<TFilter>(config.filterValue);
    const [options, setOptions] = useState<TSelectOptions>([]);

    useEffect(() => {
      if (!config.filterProps.needToReload) {
        config.filterProps.loadOptions('').then((result: TSelectOptions) => {
          setOptions(result);
          const prepared = config.filterValue?.values?.map((item) =>
            result.find((option) => option.value === item)
          ) as TSelectOptions;
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          onSelect(config.filterProps.isMulti ? prepared : prepared?.[0]);
        });
      }
    }, [config.filterProps.loadOptions]);

    useEffect(() => {
      if (!Object.keys(selectedValuesPlatform).length) {
        setFilterValue(config.filterValue);
      }
    }, [selectedValuesPlatform]);

    const onApplyHandler = (value: TFilter) => onApply(value);

    const onClearCallback = () => {
      setFilterValue(config.filterInitialValue);
      setSelectedOptions({ type: config.id, options: [] });
      onApplyHandler(config.filterInitialValue);
    };

    const onSelect = (selected: TSelectOptions | TSelectOption): void => {
      if (!selected) return;
      const selectedItems = config.filterProps.isMulti ? selected : [selected];
      setSelectedOptions({ type: config.id, options: selectedItems as TSelectOptions });
      // сохраняем выбранные значения фильтра или приводим его к начальному состоянию если ничего не выбрано
      setFilterValue(
        (selectedItems as TSelectOptions)?.length
          ? {
              ...filterValue,
              values: (selectedItems as TSelectOptions)?.map(
                (value) => value?.[valuePath as keyof TSelectOption] as string
              ),
            }
          : config.filterInitialValue
      );
    };

    const preparedViewValue = useMemo((): string => {
      if (
        (config.filterValue && !config.filterValue.values?.length) ||
        (!config.filterProps.needToReload && !options.length)
      )
        return '';

      const selectOptions = config.filterProps.needToReload
        ? selectedValuesPlatform[config.id]
        : options;
      // формируем viewValue фильтра из выбранных опций
      return (
        config.filterValue?.values
          ?.map(
            (item) =>
              (selectOptions?.find((option) => option.value === item) as TSelectOption)?.[
                viewValuePath as keyof TSelectOption
              ]
          )
          ?.join(', ') || ''
      );
    }, [config.filterValue, options]);

    return (
      <Filter
        config={config}
        viewValue={preparedViewValue}
        onClear={onClearCallback}
        onApply={() => onApplyHandler(filterValue)}
        applyOnClose={applyOnClose}
      >
        <div className={styles.asyncSelectFilter__select}>
          {config.filterProps.isMulti ? (
            <MultiSelect
              onChange={onSelect}
              value={(selectedValuesPlatform[config.id] as TSelectOptions) || []}
              placeholder={config.filterProps.placeholder}
              valuePath={valuePath}
              viewValuePath={viewValuePath}
              autocomplete
              closeMenuOnSelect
              clearInputOnMenuClose
              needToReload={config.filterProps.needToReload}
              defaultOptions={config.filterProps.needToReload ? [] : options}
              optionComponent={config.filterProps.optionComponent || FilterMultiSelectOption}
              loadOptions={config.filterProps.loadOptions}
              itemHeight={config.filterProps.itemHeight}
              hideError
            />
          ) : (
            <SelectField
              input={{
                name: 'selectFilter',
                onFocus: noop,
                onBlur: noop,
                onChange: onSelect,
                value: selectedValuesPlatform[config.id] || [],
              }}
              placeholder={config.filterProps.placeholder}
              viewValuePath={viewValuePath}
              isMulti={false}
              autocomplete
              closeMenuOnSelect
              optionComponent={config.filterProps.optionComponent || FilterMultiSelectOption}
              needToReload={config.filterProps.needToReload}
              defaultOptions={config.filterProps.needToReload ? [] : options}
              loadOptions={config.filterProps.loadOptions}
              itemHeight={config.filterProps.itemHeight}
              clearInputOnMenuClose
              hideError
            />
          )}
        </div>
      </Filter>
    );
  }
);
