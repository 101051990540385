import { CELL_POSITION, DefaultCell, TableHeaderCell } from '@uikit/components/Table';
import { CellWithTooltip } from '@uikit/components/Table/components/columns/CellWithTooltip';
import { TColumn } from '@uikit/components/Table/table.types';
import i18n from '@services/i18n';
import { TargetAudience } from '@components/CommonColumnsTable/TargetAudience';
import { useDynamicColumns } from './useDynamicColumns.hook';

export const targetAudienceShareColumn: TColumn = {
  width: 50,
  Header: (
    <DefaultCell position={CELL_POSITION.CENTER}>
      <CellWithTooltip textTooltip={i18n.t('table.instagram.targetAudienceShareToolTip')}>
        <TableHeaderCell text={i18n.t('table.instagram.targetAudienceHeader')} />
      </CellWithTooltip>
    </DefaultCell>
  ),
  accessor: 'calculations.targetAudienceShare',
  Cell: ({ row }) => (
    <DefaultCell position={CELL_POSITION.CENTER}>
      <TargetAudience
        percent={Number(
          useDynamicColumns({
            campaignValue: row?.original?.smaStatistics?.calculations?.targetAudienceShare,
            browseValue: row?.original?.calculations?.targetAudienceShare,
          })
        )}
      />
    </DefaultCell>
  ),
};
