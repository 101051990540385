import { FC } from 'react';
import { Button } from '@uikit/components/Buttons';
import { Modal, TModalProps } from '@uikit/components/Modal';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import { TActiveSocialPlatforms } from '@models/activeSocialPlatform';
import styles from './SelectAllModal.module.scss';

export type TSelectAllModal = {
  activePlatform: TActiveSocialPlatforms | null; // TODO: По необходимости сделать необязательным
  onClick: () => void;
  count: number;
};

export const SelectAllModal: FC<TSelectAllModal & TModalProps> = ({
  onClick,
  count,
  modal,
  activePlatform,
}) => {
  const { t } = useUiKitTranslation();

  const description = activePlatform?.isYoutube
    ? t('selectAllModal.addChannels', {
        count,
      })
    : t('selectAllModal.addAccounts', {
        count,
      });

  const actionHandler = () => {
    onClick();
    modal.reject();
  };

  return (
    <Modal>
      <div className={styles.selectAllModal}>
        <h2>{t('selectAllModal.selectAll')}</h2>
        <div className={styles.selectAllModal__description}>{description}</div>
        <Button onClick={actionHandler}>{t('selectAllModal.addToSelection')}</Button>
      </div>
    </Modal>
  );
};
