/*
 * Стор и эффекты для страницы списка компаний.
 */
import { createEffect, createEvent, createStore, forward } from 'effector/compat';
import { ICampaignListItemDto, ICampaignSimpleInfoDto, IPaginatedDto } from '@hypetrainCommon';
import { IGetCampaignsParams, campaignsApiService } from '@api/campaigns';
import { campaignsUserAccessService } from '@services/userAccess';

const INITIAL_CAMPAIGNS: IPaginatedDto<ICampaignListItemDto> = {
  limit: 0,
  offset: 0,
  results: [],
  total: 0,
};

/*
 * Получение короткого списка кампаний
 */
export const getCampaignsSimpleListFx = createEffect(() => {
  if (!campaignsUserAccessService.canAddToExistingCampaignFromList) {
    return Promise.reject();
  }

  return campaignsApiService.getCampaignsSimpleList();
});

/*
 * reset стора
 */

export const resetCampaignStore = createEvent();

/*
 * Получение списка компаний.
 */
export const getCampaignsFx = createEffect((params: IGetCampaignsParams) => {
  if (!campaignsUserAccessService.getCampaigns) {
    return Promise.reject();
  }

  return campaignsApiService.getCampaigns(params);
});

/*
 * Получение начального списка кампаний.
 */
export const getInitialCampaignsFx = createEffect(() => {
  resetCampaignStore();
  return getCampaignsFx({ limit: 10, offset: 0 });
});

/*
 * Создание компании.
 */
export const createCampaignFx = createEffect(campaignsApiService.createCampaign);

/*
 * Редактирование компании.
 */
export const updateCampaignFx = createEffect(campaignsApiService.updateCampaign);

/*
 * Стор страницы компаний.
 */
export const $campaignsStore = createStore<IPaginatedDto<ICampaignListItemDto>>(INITIAL_CAMPAIGNS)
  .on(getCampaignsFx.doneData, (state, payload) => ({
    ...payload,
    results: [...state.results, ...(payload?.results || [])],
  }))
  .reset(resetCampaignStore);

export const $campaignsSimpleList = createStore<ICampaignSimpleInfoDto[] | null>(null).on(
  getCampaignsSimpleListFx.doneData,
  (_, payload) => payload
);

forward({
  from: [createCampaignFx.done, updateCampaignFx.done],
  to: getInitialCampaignsFx,
});
