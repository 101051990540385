/*
 * Компонент отображающий изменение сущности.
 * TODO: Если что, то можно переименовать в ChangeBudge.
 */
import { FC } from 'react';
import { FORMAT_NUMBER_STYLES, FormatNumber } from '@uikit/components/Formats';
import { ICON_TYPES } from '@uikit/components/Icon';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { StateBadge } from '@uikit/components/StateBadge';
import { STATE_BUDGE_STYLES } from '@uikit/components/StateBadge/StateBadge.utils';
import styles from './Change.module.scss';

type TChangeProps = {
  value: number;
  text?: string;
  className?: string;
};

export const Change: FC<TChangeProps> = ({ value, text, className }) => {
  const isNegative = value < 0;

  const getStateBudgeStyles = () => {
    if (value === 0) {
      return STATE_BUDGE_STYLES.NEUTRAL;
    }
    return isNegative ? STATE_BUDGE_STYLES.NEGATIVE : STATE_BUDGE_STYLES.POSITIVE;
  };

  return (
    <StateBadge
      className={className}
      style={getStateBudgeStyles()}
      leftIcon={{
        type: ICON_TYPES.ARROW,
        style: isNegative ? ICON_STYLES.NEGATIVE : ICON_STYLES.POSITIVE,
        className: isNegative ? styles.arrowRotate : '',
      }}
    >
      <>
        <FormatNumber numberStyles={FORMAT_NUMBER_STYLES.PERCENT}>{value}</FormatNumber>
        {text && <span>{` ${text}`}</span>}
      </>
    </StateBadge>
  );
};
