// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".startProcess__FKZBH{display:flex;flex-direction:column;align-items:flex-start}.startProcess__container__yS6Vc{display:flex;width:100%;height:100%;align-items:center;justify-content:center}.startProcess__FKZBH h1{margin-bottom:var(--hSpacing30);white-space:pre-line}.startProcess__img__QyRe3{margin-bottom:var(--hSpacing30)}.startProcess__description__Ao7kx{white-space:pre-line;margin-bottom:var(--hSpacing30)}.startProcess__buttonsContainer__HycD_{display:flex}.startProcess__buttonsContainer__HycD_>*{margin:0 var(--vSpacing16) 0 0}.startProcess__buttonsContainer_topMargin__n8rwr{margin-top:var(--hSpacing30)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/StartProcess/StartProcess.module.scss"],"names":[],"mappings":"AAEA,qBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CAEA,gCACE,YAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CACA,sBAAA,CAGF,wBACE,+BAAA,CACA,oBAAA,CAGF,0BACE,+BAAA,CAGF,kCACE,oBAAA,CACA,+BAAA,CAGF,uCACE,YAAA,CAEA,yCACE,8BAAA,CAGF,iDACE,4BAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.startProcess {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n\n  &__container {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    align-items: center;\n    justify-content: center;\n  }\n\n  h1 {\n    margin-bottom: var(--hSpacing30);\n    white-space: pre-line;\n  }\n\n  &__img {\n    margin-bottom: var(--hSpacing30);\n  }\n\n  &__description {\n    white-space: pre-line;\n    margin-bottom: var(--hSpacing30);\n  }\n\n  &__buttonsContainer {\n    display: flex;\n\n    & > * {\n      margin: 0 var(--vSpacing16) 0 0;\n    }\n\n    &_topMargin {\n      margin-top: var(--hSpacing30);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"startProcess": "startProcess__FKZBH",
	"startProcess__container": "startProcess__container__yS6Vc",
	"startProcess__img": "startProcess__img__QyRe3",
	"startProcess__description": "startProcess__description__Ao7kx",
	"startProcess__buttonsContainer": "startProcess__buttonsContainer__HycD_",
	"startProcess__buttonsContainer_topMargin": "startProcess__buttonsContainer_topMargin__n8rwr"
};
export default ___CSS_LOADER_EXPORT___;
