/*
 * Сервис для работы с API-запросами коммуникаций.
 */
import { ApiService, apiService } from '@api';
import {
  IGetUserMessagesResponseDto,
  ISendBulkMessagesRequestDto,
  IUploadedAttachmentDto,
  MessageVariables,
} from '@hypetrainCommon';
import {
  TCreateAndSendMessageParams,
  TDeleteMessageParams,
  TGetMessageVariablesParams,
  TGetMessagesByChatIdParams,
  TMarkMessagesAsReadParams,
  TResendMessageParams,
  TUploadsMessageAttachmentsParams,
} from '@api/communication';

/* eslint-disable arrow-body-style */
export class CommunicationApiService {
  constructor(private readonly api: ApiService) {}

  /*
   * Создание и отправка сообщения.
   * @see: https://reference.api.rc-staging.hypetrain.io/?http#create-and-send-message
   */
  public createAndSendMessage = ({ chatId, body }: TCreateAndSendMessageParams): Promise<void> => {
    return this.api
      .post(`/communication/chat/${chatId}/messages`, body)
      .then((response) => response.data);
  };

  /*
   * Запрашивает сообщения конкретного чата.
   * @see: https://reference.api.rc-staging.hypetrain.io/?http#get-messages-by-chat-id
   */
  public getMessagesByChatID = ({
    chatId,
    showLoader = true,
  }: TGetMessagesByChatIdParams): Promise<IGetUserMessagesResponseDto[]> => {
    return this.api
      .get(`/communication/chat/${chatId}/messages`, {}, { showLoader })
      .then((response) => response.data);
  };

  /*
   * Отмечает сообщения как прочитанные.
   * @see: https://reference.api.rc-staging.hypetrain.io/?http#mark-messages-as-read
   */
  public markMessagesAsRead = ({ chatId, body }: TMarkMessagesAsReadParams): Promise<void[]> => {
    return this.api
      .post(`/communication/chat/${chatId}/messages/read`, body, {}, { showLoader: false })
      .then((response) => response.data);
  };

  /*
   * Загружает attachments сообщения.
   * @see: https://reference.api.rc-staging.hypetrain.io/?http#uploads-message-attachments-to-temporary-folder
   */
  public uploadsMessageAttachments = ({
    body,
  }: TUploadsMessageAttachmentsParams): Promise<IUploadedAttachmentDto[]> => {
    return this.api
      .post(`/communication/files`, body, {}, { showLoader: false })
      .then((response) => response.data);
  };

  /*
    Массовая рассылка.
    @see https://reference.api.rc-staging.hypetrain.io/?javascript#create-and-send-messages-in-bulk
   */
  public createMassMailing = (body: ISendBulkMessagesRequestDto): Promise<void> => {
    return this.api.post('/communication/messages/bulk', body).then((response) => response.data);
  };

  /*
   * Выполняет повторную отправку сообщения пользователям.
   * @see: https://reference.api.rc-staging.hypetrain.io/?http#resend-message
   */
  public resendMessage = ({ chatId, messageId }: TResendMessageParams): Promise<void> => {
    return this.api
      .post(`/communication/chat/${chatId}/messages/${messageId}/resend`)
      .then((response) => response.data);
  };

  /*
   * Выполняет удаление сообщения.
   * @see: https://reference.api.rc-staging.hypetrain.io/?http#delete-message
   */
  public deleteMessage = ({
    chatId,
    messageId,
    isForce = false,
  }: TDeleteMessageParams): Promise<void> => {
    return this.api
      .deleteMethod(`/communication/chat/${chatId}/messages/${messageId}`, {
        params: {
          force: isForce,
        },
      })
      .then((response) => response.data);
  };

  /*
   * Получение списка переменных
   * @see: https://reference.api.rc-staging.hypetrain.io/#get-messages-variables
   */
  public getMessageVariables = ({
    campaignId,
    dealId,
  }: TGetMessageVariablesParams): Promise<Record<MessageVariables, string>> => {
    return this.api
      .get(`/communication/variables`, { params: { campaignId, dealId } }, { showLoader: false })
      .then((response) => response.data);
  };

  /*
   * Создание первого сообщения
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public createFirstMessage = (payload: IGenerateFirstMessageRequestDto) => {
    return this.api
      .post('/communication/generation/first-message', { ...payload }, {}, { showLoader: false })
      .then((response) => response.data);
  };
}

export const communicationApiService = new CommunicationApiService(apiService);
