import _debounce from 'lodash/debounce';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type TAsyncFunction = (...args: any[]) => Promise<any>;

export function asyncDebounce(func: TAsyncFunction, wait = 0): TAsyncFunction {
  let timer: NodeJS.Timeout | null = null;
  let resolves: ((value: unknown) => unknown)[] = [];

  return (...args) => {
    // При вызове сбрасываем таймаут
    timer && clearTimeout(timer);

    timer = setTimeout(() => {
      // Получаем результат функции, и резолвим все промисы которые были созданы предыдущими вызовами
      const result = func(...args);
      resolves.forEach((resolve) => resolve(result));
      resolves = [];
    }, wait);

    // каждый раз при вызове debounced функции создается новый промис,
    // сохраняем его resolve метод чтобы потом зарезолвить
    return new Promise((resolve) => {
      resolves.push(resolve);
    });
  };
}

export const debounceInstance = (func: (...args: unknown[]) => unknown, delay = 500) => {
  const funcDebounced = _debounce(func, delay);
  return (newValue: unknown) =>
    new Promise((resolve) => {
      funcDebounced(newValue, resolve);
    });
};
