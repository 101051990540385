// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".actionsCell__fPsHE{cursor:pointer}.actionsCell__button__jvQkB{margin-top:0}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Table/components/columns/Actions%D0%A1ell/ActionsCell.module.scss"],"names":[],"mappings":"AAEA,oBACE,cAAA,CAEA,4BACE,YAAA","sourcesContent":["@import '../../../../../assets/styles/ui_kit_mixins';\n\n.actionsCell {\n  cursor: pointer;\n\n  &__button {\n    margin-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsCell": "actionsCell__fPsHE",
	"actionsCell__button": "actionsCell__button__jvQkB"
};
export default ___CSS_LOADER_EXPORT___;
