// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".panel__Lic92{position:absolute;display:grid;grid-template-rows:auto minmax(0%, 100%) auto;padding:var(--hSpacing30) var(--vSpacing30);background:var(--backgroundSurface);border:1px solid var(--outline);box-shadow:0 6px 15px rgba(31,32,63,.16);border-radius:var(--round8);z-index:var(--panelZIndex)}.panel__header__gA8L8{display:flex}.panel__title__J2XaT{margin-bottom:var(--hSpacing8)}.panel__buttons__O40hO{display:flex;height:56px}.panel__buttons__O40hO>*{margin-right:var(--hSpacing16)}.panel__buttons_left__W93OP{margin-left:auto}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Panel/panel.module.scss"],"names":[],"mappings":"AAEA,cACE,iBAAA,CACA,YAAA,CACA,6CAAA,CACA,2CAAA,CACA,mCAAA,CACA,+BAAA,CACA,wCAAA,CACA,2BAAA,CACA,0BAAA,CAEA,sBACE,YAAA,CAGF,qBACE,8BAAA,CAGF,uBACE,YAAA,CACA,WAAA,CAEA,yBACE,8BAAA,CAGF,4BACE,gBAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.panel {\n  position: absolute;\n  display: grid;\n  grid-template-rows: auto minmax(0%, 100%) auto;\n  padding: var(--hSpacing30) var(--vSpacing30);\n  background: var(--backgroundSurface);\n  border: 1px solid var(--outline);\n  box-shadow: 0 6px 15px rgb(31 32 63 / 16%); // TODO: Move to constant\n  border-radius: var(--round8);\n  z-index: var(--panelZIndex);\n\n  &__header {\n    display: flex;\n  }\n\n  &__title {\n    margin-bottom: var(--hSpacing8);\n  }\n\n  &__buttons {\n    display: flex;\n    height: 56px;\n\n    & > * {\n      margin-right: var(--hSpacing16);\n    }\n\n    &_left {\n      margin-left: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": "panel__Lic92",
	"panel__header": "panel__header__gA8L8",
	"panel__title": "panel__title__J2XaT",
	"panel__buttons": "panel__buttons__O40hO",
	"panel__buttons_left": "panel__buttons_left__W93OP"
};
export default ___CSS_LOADER_EXPORT___;
