// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paymentsReadyForPayment__xm_KT{height:100%;display:flex;flex-direction:column;position:relative;margin:var(--hSpacing20) var(--vSpacing20)}.paymentsReadyForPayment__table__dNjZp{position:relative;display:flex;height:100%}", "",{"version":3,"sources":["webpack://./pages/PaymentsPage/components/PaymentsReadyForPayment/PaymentsReadyForPayment.module.scss"],"names":[],"mappings":"AAEA,gCACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,0CAAA,CAEA,uCACE,iBAAA,CACA,YAAA,CACA,WAAA","sourcesContent":["@import '../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.paymentsReadyForPayment {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  margin: var(--hSpacing20) var(--vSpacing20);\n\n  &__table {\n    position: relative;\n    display: flex;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentsReadyForPayment": "paymentsReadyForPayment__xm_KT",
	"paymentsReadyForPayment__table": "paymentsReadyForPayment__table__dNjZp"
};
export default ___CSS_LOADER_EXPORT___;
