/*
 * Button component.
 */
import cn from 'clsx';
import _isNumber from 'lodash/isNumber';
import { FC, MouseEvent, PropsWithChildren } from 'react';
import { CircleCounter } from '@uikit/components/CircleCounter';
import { Icon, ICON_TYPES } from '@uikit/components/Icon';
import { BUTTON_SIZE, BUTTON_TYPE } from './Button.constants';
import styles from './Button.module.scss';

export type TButtonProps = {
  buttonType?: BUTTON_TYPE; // defines look
  size?: BUTTON_SIZE; // button size
  icon?: ICON_TYPES; // for button without text (only icon)
  leftIcon?: ICON_TYPES; // icon to the left of the text
  rightIcon?: ICON_TYPES; // icon to the right of the text
  counter?: number; // counter in button corner
  disabled?: boolean; // button disable sign
  type?: 'submit' | 'reset' | 'button'; // native button type
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

export const Button: FC<PropsWithChildren<TButtonProps>> = ({
  size = BUTTON_SIZE.L,
  buttonType = BUTTON_TYPE.PRIMARY,
  onClick,
  icon,
  leftIcon,
  rightIcon,
  counter,
  disabled = false,
  type,
  className,
  children,
  ...props
}) => (
  <button
    className={cn(
      styles.button,
      className,
      styles[size],
      styles[buttonType],
      icon && styles.button_buttonIcon
    )}
    type={type}
    onClick={onClick}
    disabled={disabled}
    {...props}
  >
    {_isNumber(counter) && (
      <CircleCounter
        count={counter}
        className={styles.button__counter}
      />
    )}

    {leftIcon && (
      <Icon
        className={styles.button__leftIcon}
        type={leftIcon}
      />
    )}

    {children ||
      (icon && (
        <Icon
          className={styles.button__icon}
          type={icon}
        />
      ))}

    {rightIcon && (
      <Icon
        className={styles.button__rightIcon}
        type={rightIcon}
      />
    )}
  </button>
);
