/*
 * Abstract filers types.
 */
import {
  IInstagramCampaignFilterDto,
  IInstagramFilterDto,
  ITiktokCampaignFilterDto,
  ITiktokFilterDto,
  IYoutubeFilterDto,
  InstagramCampaignFilterName,
  InstagramFilterName,
  TiktokCampaignFilterName,
  TiktokFilterName,
  YoutubeCampaignFilterName,
  YoutubeFilterName,
  YoutubeReplyStatusCampaignFilter,
} from '@hypetrainCommon';
import { FILTER_TYPES } from '@uikit/components/Filter';

/*
 * Элемент списка доступных для отображения фильтров (для отображения в меню их выбора).
 */
export type TAvailableFilter<T = unknown> = {
  id:
    | InstagramFilterName
    | InstagramCampaignFilterName
    | YoutubeFilterName
    | YoutubeCampaignFilterName
    | TiktokFilterName
    | TiktokCampaignFilterName;
  group?: string; // группа к которой относится фильтр
  switcherLabel?: string; // название фильтра если он сгруппирован
  label: string; // filter label
  popupTitle?: string;
  infoTooltip?: string;
  description?: string; // filter description
  filterPopupWidth?: number; // ширина попапа
  isVisible?: boolean;
  filterType: FILTER_TYPES; // filter type range, radio etc.
  filterProps?: T; // properties of filter component
  filterInitialValue?: TFilter;
  filterValue?: TFilter; // значение фильтра при его инициализации
  formatValueToDTO?: (filterValue: TFilter) => TFilterDto; // преобразует значение фильтра перед отправкой на backend
  formatValueFromDTO?: (filterValue: TFilterDto) => TFilter; // преобразует значение фильтра при получении данных с backend
  testId?: string;
};

export type TAbstractFilterConfig = {
  selectedFilters: InstagramCampaignFilterName[] | YoutubeCampaignFilterName[];
  activeFilters: TFilterDto[];
};

// Тип для переиспользуемых форматтеров фильтров и тд, которые используются сразу для нескольких платформ
export type TFilterDto =
  | IInstagramFilterDto
  | IInstagramCampaignFilterDto
  | IYoutubeFilterDto
  | YoutubeReplyStatusCampaignFilter
  | ITiktokFilterDto
  | ITiktokCampaignFilterDto;

export type TFilterName =
  | InstagramFilterName
  | YoutubeFilterName
  | InstagramCampaignFilterName
  | YoutubeCampaignFilterName
  | TiktokFilterName
  | TiktokCampaignFilterName;

// Объединяем Insta и Youtube enum для компонентов, которые используются сразу для нескольких платформ
export const EFilterName = {
  ...InstagramFilterName,
  ...YoutubeFilterName,
};

// Локальный тип для фильтров
export type TFilter = {
  name: TFilterName;
  values?: string[];
  min?: number;
  max?: number;
};
