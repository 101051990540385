/*
 * Колонки таблицы платежей.
 */
import { CellProps } from 'react-table';
import { Currency, IPaymentListItemDto, IPaymentRecipientDto } from '@hypetrainCommon';
import { Chip } from '@uikit/components/Chips/Chip';
import { formatDate } from '@uikit/components/DatePickerField/datePickerField.utils';
import { CELL_POSITION, DefaultCell, TableHeaderCell } from '@uikit/components/Table';
import { TColumn } from '@uikit/components/Table/table.types';
import { CellWithTooltip } from 'hypetrain-ui/src/components/Table/components/columns/CellWithTooltip';
import { TextEllipsis } from 'hypetrain-ui/src/components/TextEllipsis';
import i18n from '@services/i18n';
import { PaymentActionsCell } from '@pagesPaymentsPage/components/PaymentActionsCell';
import { PaymentAmountCell } from '@pagesPaymentsPage/components/PaymentAmountCell';
import { $paymentsCurrencyFilter } from '@pagesPaymentsPage/payments.model';
import { SmaInfoCell } from '@components/TableCells/SmaInfoCell';
import { SelectAllPaymentsCell } from './components/SelectAllPaymentsCell';
import { SelectPaymentCheckbox } from './components/SelectPaymentCheckbox';

/* eslint-disable react/destructuring-assignment */
export const getPaymentsColumns = (
  isReadyForPaymentTab = false
): TColumn<IPaymentListItemDto>[] => {
  const amountHeaderTooltip = isReadyForPaymentTab
    ? i18n.t('paymentsList.amountDescription')
    : i18n.t('paymentsList.amountHistoryTabDescription');

  const dueDateTooltip = isReadyForPaymentTab
    ? i18n.t('paymentsList.dueDateDescription')
    : i18n.t('paymentsList.dueDateHistoryTabDescription');

  const dateColumnName = isReadyForPaymentTab
    ? i18n.t('paymentsList.dateColumn')
    : i18n.t('paymentsList.dateColumnHistoryTab');

  const columns: TColumn<IPaymentListItemDto>[] = [
    {
      id: 'select',
      width: 26,
      Header: (
        <DefaultCell position={CELL_POSITION.LEFT}>
          <SelectAllPaymentsCell />
        </DefaultCell>
      ),
      accessor: '',
      disableSortBy: true,
      Cell: ({ row }: CellProps<IPaymentListItemDto>) => (
        <SelectPaymentCheckbox payment={row.original} />
      ),
    },
    {
      id: 'recipient',
      width: 260,
      Header: (
        <DefaultCell>
          <TableHeaderCell text={i18n.t('paymentsList.influencer')} />
        </DefaultCell>
      ),
      accessor: 'recipient',
      disableSortBy: true,
      canResize: true,
      Cell: ({ value }: CellProps<IPaymentListItemDto, IPaymentRecipientDto>) => (
        <DefaultCell>
          <SmaInfoCell
            name={value?.name}
            influencerId={value?.smaId}
            img={value?.avatarUrl}
          />
        </DefaultCell>
      ),
    },
    {
      id: 'amount',
      width: 150,
      Header: (
        <CellWithTooltip textTooltip={amountHeaderTooltip}>
          <TableHeaderCell text={i18n.t('paymentsList.amount')} />
        </CellWithTooltip>
      ),
      accessor: '',
      disableSortBy: true,
      canResize: true,
      Cell: ({ row }: CellProps<IPaymentListItemDto>) => {
        const currencyFilter = $paymentsCurrencyFilter.getState();
        const showConvertedAmount = currencyFilter === Currency.USD;

        return (
          <DefaultCell>
            <PaymentAmountCell
              amount={row.original?.payment?.amount}
              currency={row.original?.payment?.currency}
              withConverted={showConvertedAmount}
              convertedAmount={row.original?.payment?.convertedAmount}
              convertedCurrency={row.original?.payment?.convertedCurrency}
            />
          </DefaultCell>
        );
      },
    },
    {
      id: 'date',
      width: 120,
      Header: (
        <DefaultCell>
          <CellWithTooltip textTooltip={dueDateTooltip}>
            <TableHeaderCell text={dateColumnName} />
          </CellWithTooltip>
        </DefaultCell>
      ),
      accessor: isReadyForPaymentTab ? 'dueDate' : 'paidAt',
      disableSortBy: true,
      canResize: true,
      Cell: ({ value }) => <DefaultCell>{formatDate(value, 'MMM D, YYYY')}</DefaultCell>,
    },
    {
      id: 'assignee',
      width: 230,
      Header: (
        <DefaultCell>
          <TableHeaderCell text={i18n.t('paymentsList.assignee')} />
        </DefaultCell>
      ),
      accessor: 'assignee',
      disableSortBy: true,
      canResize: true,
      Cell: ({ value }) => (
        <DefaultCell>
          <TextEllipsis>{value?.name}</TextEllipsis>
        </DefaultCell>
      ),
    },
    {
      id: 'assigneesContactEmail',
      width: 230,
      Header: (
        <DefaultCell>
          <TableHeaderCell text={i18n.t('paymentsList.assigneesContactEmail')} />
        </DefaultCell>
      ),
      accessor: 'assignee',
      disableSortBy: true,
      canResize: true,
      Cell: ({ value }) => (
        <DefaultCell>
          <Chip>{value?.email}</Chip>
        </DefaultCell>
      ),
    },
    {
      id: 'actions',
      accessor: '',
      disableSortBy: true,
      width: 34,
      canResize: false,
      Cell: ({ row }: CellProps<IPaymentListItemDto>) => (
        <PaymentActionsCell payment={row.original} />
      ),
    },
  ];

  // В истории платежей не нужно отображать колонку с чекбоксами.
  return isReadyForPaymentTab ? columns : columns.slice(1);
};
