export const topicsKeys: Record<string, string> = {
  'auto-and-vehicles': 'Auto & Vehicles',
  'beauty-and-fashion': 'Beauty & Fashion',
  business: 'Business',
  'dance-and-performing-arts': 'Dance & Performing Arts',
  education: 'Education',
  entertainment: 'Entertainment',
  'food-and-cooking': 'Food & Cooking',
  gaming: 'Gaming',
  health: 'Health',
  hobby: 'Hobby',
  humour: 'Humour',
  'lifestyle-and-vlogging': 'Lifestyle & Vlogging',
  military: 'Military',
  'movies-and-animation': 'Movies & Animation',
  music: 'Music',
  'pets-and-animals': 'Pets & Animals',
  politics: 'Politics',
  religion: 'Religion',
  society: 'Society',
  sports: 'Sports',
  technology: 'Technology',
  tourism: 'Tourism',
  'tv-shows-and-series': 'TV Shows & Series',
};
