import cn from 'clsx';
import { FC } from 'react';
import { CIRCLE_TYPE } from './Circle.constants';
import styles from './Circle.module.scss';

type TCircleProps = {
  color: string | number;
  type: CIRCLE_TYPE;
  className?: string;
};

export const Circle: FC<TCircleProps> = ({ color, type, className }) => {
  if (!color) return null;

  return (
    <div
      className={cn(styles.circle, [className], {
        [styles.circle__circleMin]: type === CIRCLE_TYPE.MIN,
        [styles.circle__circleMax]: type === CIRCLE_TYPE.MAX,
      })}
      style={{ background: `rgb(${color})` }}
    />
  );
};
