/*
 * Service for executing requests for entities in selection.
 */
import { ApiService, apiService } from '@api';
import {
  IExportingSelectedSmasRequestDto,
  IExportingUserSelectionToGoogleSheetResponseDto,
  IUpdateSelectionRequestDto,
  SmaPlatform,
} from '@hypetrainCommon';
import { TSmaStatisticResponse } from '@api/campaigns';
import { TDeletePersonSmasParams } from '@api/selection/selection.api.types';
import { PROFILE_ERROR_SLUGS } from '@api/sma/sma.api.constants';
import { getSocialPlatformBySMAId } from '@utils/socialPlatform.utils';

/* eslint-disable arrow-body-style */
export class SelectionApiService {
  get activeSocialPlatform(): string {
    return this.api.activeSocialPlatform || '';
  }

  constructor(private readonly api: ApiService) {}

  /*
   * Получение всех персон selection
   * @see: https://reference.api.rc-staging.hypetrain.io/#add-multiple-youtube-smas-to-user-39-s-selection
   */
  public getSelection = (): Promise<TSmaStatisticResponse> => {
    return this.api
      .get(
        `/selection/${this.activeSocialPlatform}`,
        {},
        {
          showLoader: false,
          // Игнорируем ошибку при создании персонального воркспейса, так как на этот этапе у пользователя нет профиля
          errorCodesToIgnore: ['get-workspace--personal-not-found'],
        }
      )
      .then(
        (response) => response.data.youtubeSmasStatistics || response.data.instagramSmasStatistics
      );
  };

  /*
   * Добавление персоны в selection
   */
  public addToSelection = (smaUuids: string[]): Promise<IUpdateSelectionRequestDto> => {
    return this.api
      .post(
        `/selection/${getSocialPlatformBySMAId(Array.isArray(smaUuids) ? smaUuids[0] : smaUuids)}`,
        { smaUuids },
        {},
        {
          showLoader: smaUuids?.length > 1,
          errorCodesToIgnore: PROFILE_ERROR_SLUGS,
        }
      )
      .then((response) => response.data);
  };

  /*
   * Удаление персоны из selection
   * @see: https://reference.api.rc-staging.hypetrain.io/#delete-youtube-sma-from-user-39-s-selection-if-no-youtube-smas-specified-deletes-all
   */
  public deletePersonFromSelection = ({
    platform,
    smaUuids,
  }: TDeletePersonSmasParams): Promise<string> => {
    return this.api
      .deleteMethod(
        `/selection/${platform}`,
        { data: { smaUuids: smaUuids || [] } },
        { showLoader: Number(smaUuids?.length) > 1 }
      )
      .then((response) => response.data);
  };

  /*
   * Экспорт youtube sma
   * @see: https://reference.api.rc-staging.hypetrain.io/#hypetrain-api-exporting
   */
  public exportYoutubeSmas = (): Promise<IExportingUserSelectionToGoogleSheetResponseDto> => {
    return this.api
      .get(
        '/exporting/selection/google-sheet',
        {},
        {
          showLoader: false,
        }
      )
      .then((response) => response.data);
  };

  /*
   * Экспорт csv sma
   * @see: https://reference.api.dev.hypetrain.io/?shell#exports-selection-to-csv-file
   */
  public exportSmasCsv = (platform: SmaPlatform): Promise<string> => {
    return this.api
      .get(
        `/exporting/selection/${platform}/csv`,
        {},
        {
          showLoader: false,
        }
      )
      .then((response) => response.data);
  };

  /*
   * Экспорт youtube sma by id
   * @see: https://reference.api.rc-staging.hypetrain.io/#export-user-selection-to-google-sheet
   */
  public exportYoutubeSmasByIds = (
    smas: IExportingSelectedSmasRequestDto
  ): Promise<IExportingUserSelectionToGoogleSheetResponseDto> => {
    return this.api
      .post('/exporting/smas-list/google-sheet', smas, {}, { showLoader: false })
      .then((response) => response.data);
  };

  /*
   * Экспорт csv sma by id
   * @see: https://reference.api.test.hypetrain.io/?shell#exports-selection-to-csv-file
   */
  public exportSmasCsvByIds = (data: {
    platform: SmaPlatform;
    smas: string[];
  }): Promise<string> => {
    return this.api
      .post(
        `/exporting/smas-list/${data.platform}/csv`,
        { smas: data.smas },
        {},
        { showLoader: false }
      )
      .then((response) => response.data);
  };
}

export const selectionApiService = new SelectionApiService(apiService);
