/*
 * Counter of users with show all them in dropdown on click.
 */
import { FC } from 'react';
import { Popup, PopupEvents, PopupPositions } from '@uikit/components/Popup';
import { TUsersListItem } from '@components/UsersList';
import { UsersListTooltip } from '@components/UsersList/components/UsersListTooltip';
import styles from './UsersListCounter.module.scss';

export enum USERS_LIST_COUNTER_MODE {
  SHOW_ALL = 'SHOW_ALL', // show all users in counter
  SHOW_OTHER = 'SHOW_OTHER', // show only users that do not displayed in main list
}

type TUsersListCounterProps = {
  total: number; // total users count
  users: TUsersListItem[]; // users list
  maxShowCount?: number; // users count that will show in list (other will hide in counter)
  mode?: USERS_LIST_COUNTER_MODE; // mode for counter
};

export const UsersListCounter: FC<TUsersListCounterProps> = ({
  total,
  users,
  maxShowCount,
  mode = USERS_LIST_COUNTER_MODE.SHOW_ALL,
}) => {
  const usersList = mode === USERS_LIST_COUNTER_MODE.SHOW_ALL ? users : users.slice(maxShowCount);
  const displayedCount =
    mode === USERS_LIST_COUNTER_MODE.SHOW_ALL ? total : `+${total - Number(maxShowCount)}`;

  return (
    <Popup
      content={<UsersListTooltip users={usersList} />}
      position={PopupPositions.BottomRight}
      event={PopupEvents.Click}
      offset={20}
      width={184}
    >
      <div className={styles.usersListCounter}>{displayedCount}</div>
    </Popup>
  );
};
