import { IYoutubeSmaStatisticsResponseDto, YoutubeFilterName } from '@hypetrainCommon';
import { TFilterConfig } from '@uikit/components/Filter';
import { isFilterHasValue } from '@uikit/components/Filters/filters.utils';
import {
  FORMAT_NUMBER_NOTATION,
  FORMAT_NUMBER_STYLES,
  FormatNumber,
} from '@uikit/components/Formats';
import { CELL_POSITION, DefaultCell, TableHeaderCell } from '@uikit/components/Table';
import { CellWithTooltip } from '@uikit/components/Table/components/columns/CellWithTooltip';
import { TColumn } from '@uikit/components/Table/table.types';
import i18n from '@services/i18n';
import { metricsForTable } from '@services/tableSmaFormatter';
import { getRangePriceForView, getRangeRecPrice } from '@utils/metrics';
import { ICampaignDealListItemCustom } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.types';
import { getIsContact } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.utils';
import { SelectCampaignAllCheckbox } from '@pagesCampaigns/CampaignDetailsPage/components/SelectCampaignAllCheckbox';
import { AudienceDemographicYoutube } from '@components/AudienceDemographic/AudienceDemographicYoutube.component';
import { AudienceLocationYoutube } from '@components/AudienceLocation';
import { ChannelInfoColumn } from '@components/CommonColumnsTable/ChannelInfoColumn';
import { TChannelInfoColumnData } from '@components/CommonColumnsTable/ChannelInfoColumn/ChannelInfoColumn.component';
import { MetricsInfoColumn } from '@components/CommonColumnsTable/MetricsInfoColumn';
import { audienceShareCellCampaign } from '@components/TableCells';
import { SelectCampaignCheckbox } from '../SelectCampaignCheckbox';
import styles from './DataAnalytics.module.scss';

export const getCampaignsYoutubeColumns = (
  filters: TFilterConfig[]
): TColumn<ICampaignDealListItemCustom>[] => {
  const filtering = filters.filter(isFilterHasValue);
  const audienceFilters: string[] = [
    YoutubeFilterName.AudienceCountries,
    YoutubeFilterName.AudienceGenders,
    YoutubeFilterName.AudienceAges,
  ];
  const isHasAudieceFilter = filtering.some((currentFilter) =>
    audienceFilters.includes(currentFilter.id)
  );

  const columns: TColumn<ICampaignDealListItemCustom>[] = [
    {
      width: 40,
      canResize: false,
      Header: <SelectCampaignAllCheckbox />,
      accessor: 'selection',
      Cell: ({ row }) => (
        <>
          <SelectCampaignCheckbox
            campaignId={row.original?.id}
            className={styles.tableCheckbox}
          />
          <div className={styles.numbering}>{Number(row.id) + 1}</div>
        </>
      ),
    },
    {
      minWidth: 280,
      Header: (
        <DefaultCell>
          <TableHeaderCell text={i18n.t('channelInfo')} />
        </DefaultCell>
      ),
      accessor: 'channelInfo',
      Cell: ({ row }) => (
        <ChannelInfoColumn
          className={styles.channelInfoCampaign}
          info={row.original?.smaStatistics?.sma || ({} as TChannelInfoColumnData)}
          highlightedString={row.original?.highlightedString}
          dealId={row.original?.id}
          isContact={getIsContact(row.original)}
        />
      ),
    },
    {
      width: 70,
      Header: (
        <DefaultCell position={CELL_POSITION.RIGHT}>
          <TableHeaderCell text={i18n.t('subscribers')} />
        </DefaultCell>
      ),
      accessor: 'subscribers',
      Cell: ({ row }) => (
        <DefaultCell
          isEmpty={
            !(row.original?.smaStatistics as IYoutubeSmaStatisticsResponseDto)?.metrics
              ?.subscriberCount
          }
          position={CELL_POSITION.RIGHT}
        >
          <FormatNumber notation={FORMAT_NUMBER_NOTATION.COMPACT}>
            {
              (row.original?.smaStatistics as IYoutubeSmaStatisticsResponseDto)?.metrics
                ?.subscriberCount
            }
          </FormatNumber>
        </DefaultCell>
      ),
    },
    {
      width: 110,
      Header: (
        <DefaultCell position={CELL_POSITION.CENTER}>
          <TableHeaderCell text={i18n.t('countries')} />
        </DefaultCell>
      ),
      accessor: 'audience.countries',
      Cell: ({ row }) => (
        <DefaultCell position={CELL_POSITION.CENTER}>
          <AudienceLocationYoutube
            influencer={row.original?.smaStatistics as IYoutubeSmaStatisticsResponseDto}
          />
        </DefaultCell>
      ),
    },
    {
      width: 170,
      Header: (
        <DefaultCell position={CELL_POSITION.CENTER}>
          <TableHeaderCell text={i18n.t('demographics')} />
        </DefaultCell>
      ),
      accessor: 'audience.demographics',
      Cell: ({ row }) => (
        <DefaultCell position={CELL_POSITION.CENTER}>
          <AudienceDemographicYoutube
            influencer={row.original?.smaStatistics as IYoutubeSmaStatisticsResponseDto}
          />
        </DefaultCell>
      ),
    },
    {
      width: 110,
      Header: (
        <DefaultCell position={CELL_POSITION.LEFT}>
          <CellWithTooltip textTooltip={i18n.t('table.youtube.views30ToolTip')}>
            <TableHeaderCell text={i18n.t('viewsMonth')} />
          </CellWithTooltip>
        </DefaultCell>
      ),
      accessor: 'views',
      Cell: ({ row }) => (
        <MetricsInfoColumn
          {...(row.original?.smaStatistics as IYoutubeSmaStatisticsResponseDto)?.videosMetrics?.[
            '30'
          ]?.r?.value}
        />
      ),
    },
    {
      width: 125,
      Header: (
        <DefaultCell>
          <CellWithTooltip textTooltip={i18n.t('table.youtube.er30ToolTip')}>
            <TableHeaderCell text={i18n.t('engagementRateColumn')} />
          </CellWithTooltip>
        </DefaultCell>
      ),
      accessor: 'engagementRate',
      Cell: ({ row }) => (
        <MetricsInfoColumn
          {...(row.original?.smaStatistics as IYoutubeSmaStatisticsResponseDto)?.videosMetrics?.[
            '30'
          ]?.er?.value}
          numberStyles={FORMAT_NUMBER_STYLES.PERCENT}
        />
      ),
    },
    {
      width: 100,
      Header: (
        <DefaultCell>
          <CellWithTooltip textTooltip={i18n.t('table.youtube.recPriceToolTip')}>
            <TableHeaderCell text={i18n.t('recPrice')} />
          </CellWithTooltip>
        </DefaultCell>
      ),
      accessor: 'price',
      Cell: ({ row }) => {
        const rangePrice = getRangeRecPrice(
          (row.original?.smaStatistics as IYoutubeSmaStatisticsResponseDto)?.price?.priceReach
        );

        return (
          <DefaultCell
            isEmpty={
              !(row.original?.smaStatistics as IYoutubeSmaStatisticsResponseDto)?.price?.priceReach
            }
          >
            {getRangePriceForView(rangePrice)}
          </DefaultCell>
        );
      },
    },
    {
      Header: (
        <DefaultCell>
          <CellWithTooltip textTooltip={i18n.t('table.youtube.cpmToolTip')}>
            <TableHeaderCell text={i18n.t('cpm')} />
          </CellWithTooltip>
        </DefaultCell>
      ),
      accessor: 'calculations.calculatedMetrics[30].projectedCPM',
      Cell: ({ row }) => (
        <MetricsInfoColumn
          {...((row.original?.smaStatistics as IYoutubeSmaStatisticsResponseDto)?.calculations
            ?.calculatedMetrics?.['30']?.projectedCPM || { min: 0, med: 0, max: 0 })}
          metricsColors={metricsForTable?.[row.original?.smaStatistics?.uuid || '']?.rgb}
          isCurrency
        />
      ),
    },
  ];

  if (isHasAudieceFilter) {
    return [...columns, audienceShareCellCampaign];
  }

  return columns;
};
