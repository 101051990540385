/*
 * Утилиты для редиректа.
 * TODO: Преобразовать во что-то другое? Сервис?
 */
import { Routes } from '@router/Routes';
import { isDefaultSubdomain } from '@utils/workspace.utils';
import { defaultSubdomain } from '@constants/common';

const getAppLink = (subdomain: string): string =>
  `${window.location.protocol}//${subdomain}${process.env.DOMAIN_FOR_COOKIES}`;

export const redirectToApp = (subdomain: string, path = '', needNotify?: boolean): void => {
  const subdomainsArray = window.location.host.split('.').slice(1, -2);
  const link = document.createElement('a');
  link.href =
    getAppLink(
      subdomainsArray.length > 0 ? `${subdomain}.${subdomainsArray.join('.')}` : subdomain
    ) +
    path +
    (needNotify ? `?needNotify=true` : '');

  // Исключаем зацикленный переход.
  if (link.href === window.location.href) return;

  link.referrerPolicy = 'unsafe-url';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/*
 * Переход на страницу Login.
 */
export const redirectToLoginPage = (): void => {
  const currentRouteIsLoginPage = window.location.pathname.indexOf(Routes.login) === 0;
  const isDefautlDomainLoginPage = isDefaultSubdomain() && currentRouteIsLoginPage;

  if (isDefautlDomainLoginPage) return;

  redirectToApp(defaultSubdomain, Routes.login);
};
