// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paymentAccountOutgoingTab__KtRvM{position:relative;display:flex;height:100%;margin:var(--hSpacing4) var(--vSpacing20)}.paymentAccountOutgoingTab__noData__m_kX_{position:unset}", "",{"version":3,"sources":["webpack://./pages/PaymentsPage/components/PaymentAccountPage/components/PaymentAccountOutgoingTab/PaymentAccountOutgoingTab.module.scss"],"names":[],"mappings":"AAEA,kCACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,yCAAA,CAEA,0CACE,cAAA","sourcesContent":["@import '../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.paymentAccountOutgoingTab {\n  position: relative;\n  display: flex;\n  height: 100%;\n  margin: var(--hSpacing4) var(--vSpacing20);\n\n  &__noData {\n    position: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentAccountOutgoingTab": "paymentAccountOutgoingTab__KtRvM",
	"paymentAccountOutgoingTab__noData": "paymentAccountOutgoingTab__noData__m_kX_"
};
export default ___CSS_LOADER_EXPORT___;
