/*
 *   Компонент для отрисовки страницы с таблицой стадий
 */
import cn from 'clsx';
import { useStore } from 'effector-react/compat';
import { t } from 'i18next';
import { FC, memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmaPlatform } from '@hypetrainCommon';
import { TFilterConfig } from '@uikit/components/Filter';
import { hasActiveFilters } from '@uikit/components/Filters/filters.utils';
import { VirtualizedTable } from '@uikit/components/Table';
import emptyCampaignDetails from '@assets/images/campaigns/emptyCampaignDetails.svg';
import { Routes } from '@router/Routes';
import { prepareInfluencersData } from '@services/tableSmaFormatter';
import { setActiveSocialPlatform } from '@models/activeSocialPlatform';
import { CAMPAIGN_DETAILS_TABLE_NAME } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.constants';
import {
  $campaignSelectionStore,
  $campaignStore,
  $searchTerm,
} from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.model';
import {
  $campaignFilters,
  $isFilterUsedStore,
} from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPageConfig.model';
import { getStagesColumns } from '@pagesCampaigns/CampaignDetailsPage/components/CampaignStages/CampaignStages.column';
import styles from './CampaignStages.module.scss';

export const CampaignStages: FC = memo(() => {
  const navigate = useNavigate();
  const campaign = useStore($campaignStore);
  const searchTerm = useStore($searchTerm);
  const loadedFilters = useStore<TFilterConfig[]>($campaignFilters);
  const isFilterUsed = useStore($isFilterUsedStore);
  const selectedCampaignDeals = useStore($campaignSelectionStore);

  const campaignDeals = useMemo(() => {
    const allCampaignDeals = campaign?.campaignDeals && Object.values(campaign.campaignDeals);

    // TODO Никита: Табы открываемыпе по дефолту могут меняться. Это недёжно. Нужно вынести наверх!
    if (allCampaignDeals && campaign?.campaignDetails?.platform === SmaPlatform.Youtube) {
      prepareInfluencersData(allCampaignDeals, []);
    }

    return allCampaignDeals;
  }, [campaign?.campaignDeals]);

  if (!campaign || !campaignDeals) return null;

  const navigateToDiscovery = () => {
    setActiveSocialPlatform(campaign?.campaignDetails?.platform);
    navigate(`${Routes.discovery}?campaignId=${campaign?.campaignDetails?.id}`);
  };

  return (
    <div className={styles.campaignStages}>
      <div className={styles.campaignStages__tableBlock}>
        <VirtualizedTable
          columns={getStagesColumns(campaign?.campaignDetails?.platform as SmaPlatform)}
          data={campaignDeals || []}
          tableName={CAMPAIGN_DETAILS_TABLE_NAME}
          wasFiltered={hasActiveFilters(loadedFilters) || !!searchTerm || isFilterUsed}
          className={styles.campaignStages__table}
          rowClassName={cn(
            styles.campaignTableRow,
            selectedCampaignDeals.length && styles.showCheckboxes
          )}
          noDataOptions={{
            title: t('startAddChannelsToCampaign'),
            description: t('searchToFindChannelsCampaign'),
            img: emptyCampaignDetails,
            actions: [
              {
                buttonText: t('searchChannels'),
                onClick: navigateToDiscovery,
              },
            ],
          }}
        />
      </div>
    </div>
  );
});
