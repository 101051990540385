// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".radioGroupFilter__radioGroup__SxEeL{margin-top:var(--hSpacing8)}.radioGroupFilter__radioGroup_withPercentage__Lqqmi>*:last-of-type{margin-bottom:var(--hSpacing8)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Filter/components/RadioGroupFilter/RadioGroupFilter.module.scss"],"names":[],"mappings":"AAGE,qCACE,2BAAA,CAGE,mEACE,8BAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.radioGroupFilter {\n  &__radioGroup {\n    margin-top: var(--hSpacing8);\n\n    &_withPercentage {\n      & > *:last-of-type {\n        margin-bottom: var(--hSpacing8);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioGroupFilter__radioGroup": "radioGroupFilter__radioGroup__SxEeL",
	"radioGroupFilter__radioGroup_withPercentage": "radioGroupFilter__radioGroup_withPercentage__Lqqmi"
};
export default ___CSS_LOADER_EXPORT___;
