/*
 * Компонент рисует бейдж "no contact/has email"
 * */
import cn from 'clsx';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PopupPositions } from '@uikit/components/Popup';
import { Tooltip } from '@uikit/components/Tooltip';
import styles from './ContactBadge.module.scss';

type TContactBadgeProps = {
  isContact: boolean;
  className?: string;
};

export const ContactBadge: FC<TContactBadgeProps> = ({ isContact, className }) => {
  const { t } = useTranslation();

  const title = isContact ? t('hasEmail') : t('noContact');
  const tooltipKey = isContact ? 'hasEmailTitle' : 'noContactTitle';

  return (
    <Tooltip
      content={
        <Trans
          i18nKey={tooltipKey}
          components={{ ul: <ul className={styles.contactBadge__list} />, li: <li /> }}
        />
      }
      position={PopupPositions.BottomCenter}
    >
      <div className={cn(styles.contactBadge, className)}>{title}</div>
    </Tooltip>
  );
};
