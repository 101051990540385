import { FC } from 'react';
import { LoadingIndicatorProps } from 'react-select';
import spinner from '@uikit/assets/images/smallLoader.svg';
import styles from './SelectFieldLoadingIndicator.module.scss';

export const SelectFieldLoadingIndicator: FC<LoadingIndicatorProps> = () => (
  <img
    className={styles.selectFieldLoadingIndicator}
    src={spinner}
    alt="loader"
  />
);
