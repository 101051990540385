/*
 * Хук для проверки, является ли компонент смонтированным
 * */
import { MutableRefObject, useEffect, useRef } from 'react';

export const useIsComponentMounted = (): MutableRefObject<boolean> => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};
