// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gamesFilterOption__listItem__SZLL3{height:56px;padding-right:var(--vSpacing8)}.gamesFilterOption__avatar__iGSUy{height:48px;min-width:82px;margin-right:var(--vSpacing12)}.gamesFilterOption__avatar__iGSUy img{height:48px;width:82px;object-fit:cover}", "",{"version":3,"sources":["webpack://./components/GamesFilterOption/GamesFilterOption.module.scss"],"names":[],"mappings":"AAGE,oCACE,WAAA,CACA,8BAAA,CAGF,kCACE,WAAA,CACA,cAAA,CACA,8BAAA,CAEA,sCACE,WAAA,CACA,UAAA,CACA,gBAAA","sourcesContent":["@import '../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.gamesFilterOption {\n  &__listItem {\n    height: 56px;\n    padding-right: var(--vSpacing8);\n  }\n\n  &__avatar {\n    height: 48px;\n    min-width: 82px;\n    margin-right: var(--vSpacing12);\n\n    img {\n      height: 48px;\n      width: 82px;\n      object-fit: cover;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gamesFilterOption__listItem": "gamesFilterOption__listItem__SZLL3",
	"gamesFilterOption__avatar": "gamesFilterOption__avatar__iGSUy"
};
export default ___CSS_LOADER_EXPORT___;
