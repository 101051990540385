/*
 * Component-container for placing dialogs in app.
 */
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { DialogContext, dialogStore } from '@uikit/components/Dialog';

export const DialogsContainer: FC = observer(() => {
  const { dialogs } = dialogStore;

  return (
    <>
      {dialogs.map(({ component: Component, props, id }) => (
        <DialogContext.Provider
          key={id}
          value={props.dialog}
        >
          <Component {...toJS(props)} />
        </DialogContext.Provider>
      ))}
    </>
  );
});
