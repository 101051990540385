// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltipActionMenu__B8x4m{display:flex}.tooltipActionMenu__item__oIemU:not(:last-child){margin-right:var(--vSpacing20)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Tooltip/components/TooltipActionMenu/TooltipActionMenu.module.scss"],"names":[],"mappings":"AAEA,0BACE,YAAA,CAEA,iDACE,8BAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.tooltipActionMenu {\n  display: flex;\n\n  &__item:not(:last-child) {\n    margin-right: var(--vSpacing20);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipActionMenu": "tooltipActionMenu__B8x4m",
	"tooltipActionMenu__item": "tooltipActionMenu__item__oIemU"
};
export default ___CSS_LOADER_EXPORT___;
