import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { Routes } from 'router/Routes';
import { ErrorBoundary } from '@uikit/components/ErrorBoundary';
import { isAuth } from '@utils/auth.utils';

type TPublicRouteProps = {
  errorBoundaryName: string;
};

export const PublicRoute: FC<PropsWithChildren<TPublicRouteProps>> = ({
  errorBoundaryName,
  children,
}) =>
  !isAuth() ? (
    <ErrorBoundary
      key={errorBoundaryName}
      layerName={errorBoundaryName}
    >
      {children}
    </ErrorBoundary>
  ) : (
    <Navigate to={Routes.discovery} />
  );
