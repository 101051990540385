import { Store, createEffect, createEvent, createStore } from 'effector/compat';
import {
  CampaignDealStageName,
  CampaignDealStageStateName,
  CampaignDealStageStatus,
  IAddCampaignDealPromoContentDto,
  ICampaignDealContractInfoResponseDto,
  ICampaignDealResponseDto,
  ICampaignDealStageDto,
  ISetCampaignDealContractDataDto,
  IYoutubeSmaStatisticsResponseDto,
  SmaPlatform,
} from '@hypetrainCommon';
import { addressBookApiService } from '@api/addressBook';
import { campaignsApiService } from '@api/campaigns';
import { calculationDataForOneInfluencer } from '@services/tableSmaFormatter';
import { getNextActiveStage } from '@pagesCampaigns/CampaignDealPage/utils/CampaignDealPage.utils';
import {
  $campaignStore,
  linkPersonToDealFX,
} from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.model';

export const resetCampaignDeal = createEvent();
export const resetContractDeal = createEvent();
export const resetActiveStage = createEvent();
export const resetNextToActiveStage = createEvent();
export const updateCampaignDeal = createEvent<ICampaignDealResponseDto>();

export const getCampaignDealFx = createEffect(campaignsApiService.getCampaignDeal);

/*
 * Обновляет данные для контракта
 */
export const updateDealContractFx = createEffect((state: ISetCampaignDealContractDataDto) => {
  const campaignId = $campaignStore.getState()?.campaignDetails.id;
  const dealId = $campaignDealStore.getState()?.id; // eslint-disable-line @typescript-eslint/no-use-before-define
  if (!campaignId || !dealId) return;

  return campaignsApiService.updateDealContract({
    campaignId,
    dealId,
    body: state,
  });
});

/*
 * Получение списка данных контракта.
 */
export const getDealContractFx = createEffect(campaignsApiService.getDealContract);

/*
 * Получение отслеживаемого поста
 */
export const getTrackingPostFx = createEffect(() => {
  const campaignId = $campaignStore.getState()?.campaignDetails.id;
  const dealId = $campaignDealStore.getState()?.id; // eslint-disable-line @typescript-eslint/no-use-before-define
  if (!campaignId || !dealId) return;

  return campaignsApiService.getTrackingPost({ campaignId, dealId });
});

/*
 * Добавление поста для трекинга
 */
export const addTrackingPostFx = createEffect((body: IAddCampaignDealPromoContentDto) => {
  const campaignId = $campaignStore.getState()?.campaignDetails.id;
  const dealId = $campaignDealStore.getState()?.id; // eslint-disable-line @typescript-eslint/no-use-before-define
  if (!campaignId || !dealId) return;

  return campaignsApiService.addTrackingPost({
    campaignId,
    dealId,
    body,
  });
});

/*
 * Обновляет статус у deal в рамках recruitment стадии
 */
export const updateDealStageStatusFx = createEffect((state: CampaignDealStageStateName) => {
  const campaignId = $campaignStore.getState()?.campaignDetails.id;
  const stage = $campaignDealStore.getState()?.stages.current; // eslint-disable-line @typescript-eslint/no-use-before-define
  const dealId = $campaignDealStore.getState()?.id; // eslint-disable-line @typescript-eslint/no-use-before-define

  if (!campaignId || !dealId || !stage) return;

  return campaignsApiService.updateDealStageStatus({
    campaignId,
    dealId,
    body: {
      stage,
      state,
    },
  });
});

// Обновление terms and requirements для дила
export const updateDealTermRequirements = createEffect(
  campaignsApiService.updateDealTermsRequirements
);

export const deleteCampaignDealFx = createEffect(campaignsApiService.deleteCampaignDeal);

export const addToDealCounterparty = createEvent<string>();
export const deleteFromDealCounterparty = createEvent<string>();
export const clearDealCounterparty = createEvent();
export const setActiveStage = createEvent<ICampaignDealStageDto | undefined>();

// Дубликат метода из адресс бука, мотивация: не тянуть все сайд эффекты которые
// происходят в адресс буке. Метод обновляет персону(имя, почта, роль и т.д.)
export const updateAddressBookPersonFx = createEffect(addressBookApiService.updatePerson);

// стор для хранение uuid counterparty персон в рамках 1 deal
// TODO: Разобраться со всеми не слишком ли это модно и молодёжно. Должно ли это тут лежать, не проще ли было в втаблицу прокинуть 1 пропс. Тяжело читать - размазано.
export const $dealCounterpartyStore = createStore<string[]>([])
  .on(addToDealCounterparty, (state, payload) => [...state, payload])
  .on(deleteFromDealCounterparty, (state, payload) => [...state.filter((deal) => deal !== payload)])
  .reset(clearDealCounterparty);

export const $campaignDealStore: Store<ICampaignDealResponseDto | null> =
  createStore<ICampaignDealResponseDto | null>(null)
    .on(getCampaignDealFx.doneData, (state, payload) => {
      if ($campaignStore.getState()?.campaignDetails?.platform === SmaPlatform.Youtube) {
        calculationDataForOneInfluencer(payload.smaStatistics as IYoutubeSmaStatisticsResponseDto);
      }

      return payload;
    })
    .on(updateCampaignDeal, (state, payload) => ({
      ...(state as ICampaignDealResponseDto),
      persons: payload.persons,
    }))
    .on(updateDealTermRequirements.doneData, (state, payload) => ({
      ...state,
      ...payload,
    }))
    .on(updateDealStageStatusFx.doneData, (_, payload) => payload)
    .on(linkPersonToDealFX.doneData, (state, payload) => ({
      ...((state || {}) as ICampaignDealResponseDto),
      persons: payload.persons,
    }))
    .reset(resetCampaignDeal);

/*
 * Стор для хранения данных контракта.
 */
export const $contractStore = createStore<ICampaignDealContractInfoResponseDto | null>(null)
  .on(getDealContractFx.doneData, (state, payload) => ({
    ...payload,
  }))
  .on(updateDealContractFx.doneData, (state, payload) => ({
    ...(payload as ICampaignDealContractInfoResponseDto),
  }))
  .reset(resetContractDeal);

export const pingMatvei = createEvent();

export const resetPingMatvei = createEvent();

/*
 * Ради всего святого удалить этот костыль.
 * Нужно сделать новый step StartTrackong для Publishing stage и всё будет хорошо.
 * Хранит флаг того, что был активирован tracking и в рамках текущей сессии мы не пробрасывали автоматом юзера на stage Payment в этом deal и
 * нужно в tracking отображать кнопку 'go to payment' 1 единственный раз, пока она её не нажмёт.
 */
export const $pingMatveiStore = createStore<boolean>(false)
  .on(pingMatvei, () => true)
  .reset(resetPingMatvei);

/*
 * Стор для хранения выбранного стейджа
 */
export const $stageActiveStore = createStore<ICampaignDealStageDto | null>(null)
  .on(setActiveStage, (state, payload) => payload)
  .on($campaignDealStore.updates, (prevActiveStage, campaignDeal) => {
    if (!campaignDeal) return prevActiveStage;

    const newActiveStage = campaignDeal?.stages?.list
      ?.filter(
        (item) =>
          item.isAvailable && item?.status !== CampaignDealStageStatus.NotStarted && item?.status
      )
      .pop();

    const skipMoveToNextStage =
      prevActiveStage?.name === CampaignDealStageName.Publishing &&
      newActiveStage?.name === CampaignDealStageName.Payment;

    // Дичь полная!
    // Матвей сказал, что на крови поклялся это исправить и сделать новый step StartTrackong для Publishing stage.
    if (skipMoveToNextStage) {
      const updatedPrevStage = campaignDeal?.stages?.list?.find(
        (stage) => stage?.name === prevActiveStage?.name
      );

      if (updatedPrevStage?.currentState !== 'skipped') {
        pingMatvei();
        return prevActiveStage;
      }
    }

    return newActiveStage;
  })
  .reset(resetActiveStage);

/*
 * Стор для хранения следующего за активным стейджем, стейджа
 */
export const $stageNextToActiveStore = createStore<ICampaignDealStageDto | null>(null)
  .on(setActiveStage, (state, lastStage) => {
    const campaignDeal = $campaignDealStore.getState();

    if (!campaignDeal) return;

    return getNextActiveStage(campaignDeal?.stages?.list, lastStage?.name || '');
  })
  .reset(resetNextToActiveStage);
