import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BUTTON_TYPE, Button } from '@uikit/components/Buttons';
import styles from './Unsupported.module.scss';

type TUnsupported = {
  icon: string;
  title: string;
  text: string;
  onClick?: () => void;
};

export const Unsupported: FC<TUnsupported> = ({ icon, title, text, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.unsupported}>
      <div className={styles.unsupported__image}>
        <img
          src={icon}
          alt="unsupportedIcon"
        />
      </div>
      <div>
        <h2>{title}</h2>
      </div>
      <div>
        <p>{text}</p>
      </div>
      {onClick && (
        <Button
          className={styles.unsupported__button}
          buttonType={BUTTON_TYPE.SECONDARY}
          onClick={onClick}
        >
          {t('unsupportedButton')}
        </Button>
      )}
    </div>
  );
};
