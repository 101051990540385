// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".arrowRotate__swbM5{transform:rotate(180deg)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Change/Change.module.scss"],"names":[],"mappings":"AAEA,oBACE,wBAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.arrowRotate {\n  transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrowRotate": "arrowRotate__swbM5"
};
export default ___CSS_LOADER_EXPORT___;
