/*
 * Dumb component, controlled outside / store model outside.
 * @see https://www.figma.com/file/TCZxcHBXR1hXEvOco2kqnc/UI-Kit-Hypetrain?type=design&node-id=5668-7605&t=JxoosNMyD0PiSD2Y-0
 */
import cn from 'clsx';
import { FC, memo } from 'react';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { TextEllipsis } from '@uikit/components/TextEllipsis';
import { noop } from '@uikit/helpers/common.helpers';
import styles from './ChipToggler.module.scss';

type TChipTogglerProps = {
  onToggle: () => void; // on toggle cb
  onClear?: () => void; // on clear cb
  name: string; // filter property name
  isToggled?: boolean;
  value?: string | null; // selected filter value
  withoutValue?: boolean;
  testId?: string;
  showCloseIcon?: boolean;
  className?: string;
};

export const ChipToggler: FC<TChipTogglerProps> = memo(
  ({
    name,
    value,
    onToggle,
    isToggled,
    onClear = noop,
    withoutValue = false,
    testId,
    showCloseIcon = true,
    className,
  }) => (
    <div
      id={testId}
      className={cn(
        styles.chipToggler,
        value && styles.chipToggler_withValue,
        (value || isToggled) && styles.chipToggler_contrast
      )}
    >
      <div
        className={cn(styles.chipToggler__content, className)}
        onClick={onToggle}
      >
        <div className={styles.chipToggler__name}>{name}</div>
        <div className={styles.chipToggler__nameFiller}>{name}</div>

        {/* TODO разобраться почему в сафари TextEllipsis создает клонированую ноду */}
        <div className={styles.chipToggler__value}>
          <TextEllipsis>{value}</TextEllipsis>
        </div>
      </div>
      {(!!value || (withoutValue && isToggled)) && showCloseIcon && (
        <div
          className={styles.chipToggler__close}
          onClick={onClear}
        >
          <Icon
            className={styles.chipToggler__closeIcon}
            type={ICON_TYPES.CLOSE}
            size={8}
          />
        </div>
      )}
    </div>
  )
);
