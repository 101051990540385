import { apiService } from '@api';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter } from 'react-router-dom';
// TODO: Delete it!
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import { ErrorBoundary } from '@uikit/components/ErrorBoundary';
import { Loader } from '@uikit/components/Loader';
import { ErrorHandlingProvider } from '@uikit/providers/errorHandling.provider';
import { overlaysService } from '@uikit/services/overlays';
import { Router } from '@src/router';
import '@api/interceptors/auth.interceptor';
import webSocketService from '@api/webSocket';
import '@services/day';
import '@services/i18n';
import { createInfluencerProfileService } from '@services/influencerProfile';
import { createInitAppService } from '@services/initApp';
import { subscriptionNotificationsService } from '@services/subscriptionNotifications';
import { subscriptionUserAccessService, userAccessService } from '@services/userAccess';
import '@services/yup';
import { componentsErrorHandler } from '@helpers/errorHandling.helpers';
import { useAuthorize } from '@hooks/useAuthorize';
import { useIdentifyFeatureToggling } from '@hooks/useIdentifyFeatureToggling.hook';
import { useInitGoogleAnalytics } from '@hooks/useInitGoogleAnalytics';
import { useInitIntercom } from '@hooks/useInitIntercom';
import { ServicesProvider } from '@components/ServicesProvider';

const influencerProfileService = createInfluencerProfileService(overlaysService);

const initAppService = createInitAppService(
  apiService,
  webSocketService,
  userAccessService,
  subscriptionUserAccessService,
  subscriptionNotificationsService
);

const App: FC = () => {
  const isAuthChecked = useAuthorize(initAppService);
  const isFeatureTogglingInited = useIdentifyFeatureToggling(isAuthChecked);
  useInitIntercom();
  useInitGoogleAnalytics();

  return (
    <ErrorHandlingProvider value={{ handler: componentsErrorHandler }}>
      <ErrorBoundary layerName="app">
        <GoogleOAuthProvider clientId={process.env.GOOGLE_AUTH_CLIENT_ID}>
          <ServicesProvider
            services={{
              influencerProfileService,
              initAppService,
            }}
          >
            {isAuthChecked && isFeatureTogglingInited ? (
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            ) : (
              <Loader />
            )}
          </ServicesProvider>
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </ErrorHandlingProvider>
  );
};

export const AppWithFeatureToggilng = withLDProvider({
  clientSideID: process.env.LAUNCH_DARKLY_CLIENT_ID,
})(App);
