import _cloneDeep from 'lodash/cloneDeep';
import {
  IBulkMessagesDealInfo,
  MessageIncomeNotification,
  MessageStatusChangedNotification,
} from '@hypetrainCommon';
import { CONTACT_ROLE } from '@pagesAddressBook/addressBook.constants';
import { getAddressBookRole } from '@pagesAddressBook/addressBook.utils';
import {
  ICampaignDealListItemCustom,
  TCampaignDealListItemMassMailing,
  TCampaignStore,
} from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.types';

/*
 * Подготовка deals для отправки массовой рассылки блоггерам
 * */
export const getDealsSend = (deals: TCampaignDealListItemMassMailing[]): IBulkMessagesDealInfo[] =>
  deals.reduce((acc, deal) => {
    const bulkMessagesDealInfo = {
      id: deal?.id,
      recipients: deal?.persons?.filter((person) => person.isCheckbox).map((person) => person?.id),
    };

    if (bulkMessagesDealInfo.recipients.length) {
      return [...acc, bulkMessagesDealInfo];
    }

    return [...acc];
  }, []);

// Deals, которые выбраны в компании и у них есть персоны
export const getDealWithPersons = (
  deals: TCampaignDealListItemMassMailing[],
  countPersons: number
): TCampaignDealListItemMassMailing[] =>
  deals?.filter((deal) => deal?.persons?.length > countPersons);

// Выбираем те Deals, которые выбраны чекбоксом в кампании
export const getSelectedDeals = (
  deals: TCampaignDealListItemMassMailing[],
  selectedDeals: string[]
): TCampaignDealListItemMassMailing[] => deals.filter((deal) => selectedDeals.includes(deal?.id));

export const getDealsCount = (
  deals: TCampaignDealListItemMassMailing[],
  campaignSelection: string[]
): number => {
  const dealWithPersons = getDealWithPersons(deals, 0);
  const selectedDealsWithPersons = getSelectedDeals(dealWithPersons, campaignSelection);
  return selectedDealsWithPersons.length;
};

export const getIsContact = (deal: ICampaignDealListItemCustom): boolean =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  deal?.persons?.some((person) => getAddressBookRole(person) === CONTACT_ROLE.REPRESENTATIVE) ||
  false;

/*
 * Обновление стора компании на событие нового сообщения в чате.
 * Ищем чат в котором произошли изменения, заменяя в его последнее сообщение на новое.
 */
export const updateCampaignStoreOnIncomeChatMessage = (
  campaign: TCampaignStore,
  notification: MessageIncomeNotification
): TCampaignStore => {
  if (!campaign?.campaignDeals || !notification?.data) return campaign;

  const chatForUpdate = campaign.campaignDeals[notification.data?.dealId]?.chat;

  if (chatForUpdate) {
    chatForUpdate.latestMessage = notification.data?.message;
  }

  return {
    ...campaign,
    campaignDeals: _cloneDeep(campaign.campaignDeals),
    unreadMessages: {
      ...campaign.unreadMessages,
      total: Number(campaign.unreadMessages?.total) + 1,
    },
  };
};

/*
 * Обновление стора компании на событие обновление статуса сообщения.
 * 1. Ищем чат в котором произошли изменения.
 * 2. Проверяем, что нотификация об изменении пришла для последнего сообщения в чате (на вкладке communication отображаются только последние).
 * 3. Если пункт 2 выполняется, то ищем тех получателей сообщения, статусы которых изменились и обновляем их.
 */
export const updateCampaignStoreOnUpdateChatMessageStatus = (
  campaign: TCampaignStore,
  notification: MessageStatusChangedNotification
): TCampaignStore => {
  if (!campaign?.campaignDeals || !notification?.data) return campaign;

  const dealForUpdate = campaign.campaignDeals?.[notification.data?.dealId];
  const chatForUpdate = dealForUpdate?.chat;
  const isMessageForUpdate = chatForUpdate?.latestMessage?.id === notification.data?.messageId;

  if (isMessageForUpdate) {
    notification.data?.recipientsStatuses?.forEach((newStatus) => {
      const recipientForUpdate = chatForUpdate?.latestMessage?.recipients.find(
        (recipient) => recipient?.person?.id === newStatus?.personId
      );

      if (recipientForUpdate) {
        recipientForUpdate.status = newStatus?.status;
      }
    });
  }

  campaign.campaignDeals[notification.data?.dealId] = _cloneDeep(dealForUpdate); // eslint-disable-line no-param-reassign

  return {
    ...campaign,
    campaignDeals: { ...campaign?.campaignDeals },
  };
};
