/*
 * Модель для страницы коммуникации в сделке.
 */
import { createEffect, createEvent, createStore, forward } from 'effector/compat';
import i18n from 'i18next';
import {
  IGetUserMessagesResponseDto,
  MessageStatusChangedNotification,
  MessageVariables,
} from '@hypetrainCommon';
import {
  TDeleteMessageParams,
  TResendMessageParams,
  communicationApiService,
} from '@api/communication';
import { $campaignDealStore } from '@pagesCampaigns/CampaignDealPage/campaignDealPage.model';
import {
  $campaignStore,
  getCampaignDealsFx,
} from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.model';
import { CAMPAIGN_DETAILS_TABS } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.types';
import { TCreateMessageParams } from './dealPageCommunication.types';
import { getCreateMessageQueryParams, updateMessageStatus } from './dealPageCommunication.utils';

// Сброс переменных
export const resetVariables = createEvent();

export const updateChatMessageStatus = createEvent<MessageStatusChangedNotification>();

/*
 * Создание и отправка сообщения.
 */
export const createMessageFx = createEffect((params: TCreateMessageParams) =>
  communicationApiService.createAndSendMessage(getCreateMessageQueryParams(params))
);

/*
 * Генерация первого сообщения
 */
export const generateFirstMessageFx = createEffect(communicationApiService.createFirstMessage);

/*
 * Запрашивает сообщения конкретного чата.
 */
export const getMessagesByChatFx = createEffect(communicationApiService.getMessagesByChatID);

/*
 * Запрашивает сообщения конкретного чата.
 */
export const getMessageVariablesFx = createEffect(communicationApiService.getMessageVariables);

/*
 * Отмечает сообщения как прочитанные.
 */
export const markMessagesAsReadFx = createEffect(communicationApiService.markMessagesAsRead);

/*
 * Выполняет повторную отправку сообщения пользователям.
 */
export const resendMessageFx = createEffect(communicationApiService.resendMessage);

/*
 * Выполняет удаление сообщения пользователя.
 */
export const deleteMessageFx = createEffect(communicationApiService.deleteMessage);

/*
 * Обновляет статус сообщений.
 * Находит непрочитанные сообщения, если они есть - выполянет запрос, отмечая их как прочитанные.
 */
export const updateMessagesReadStatusFx = createEffect(
  (messages: IGetUserMessagesResponseDto[]) => {
    const chatId = messages[0]?.chatId;
    const unreadMessagesIds = messages
      ?.filter((message) => message?.isUnread)
      .map((message) => message.id);

    if (unreadMessagesIds.length) {
      return markMessagesAsReadFx({
        chatId,
        body: {
          chatId,
          messageIds: unreadMessagesIds,
        },
      });
    }
  }
);

/*
 * Реакция на изменение сообщения - обновление сущностей.
 */
const onResendOrDeleteMessageFx = createEffect(
  (params: TResendMessageParams | TDeleteMessageParams) => {
    if (params.invokedBy === CAMPAIGN_DETAILS_TABS.DATA_ANALYTICS) {
      return getMessagesByChatFx({ chatId: $campaignDealStore.getState()?.chat?.id || '' });
    }

    return getCampaignDealsFx({ campaignId: $campaignStore.getState()?.campaignDetails?.id || '' });
  }
);

/*
 * Стор всех сообщений чата.
 */
export const $chatMessages = createStore<IGetUserMessagesResponseDto[]>([])
  .on(getMessagesByChatFx.doneData, (_, messages) => messages)
  .on(updateChatMessageStatus, (messages, update) => updateMessageStatus(messages, update));

/*
 * Стор переменных.
 */
export const $messageVariables = createStore<Record<MessageVariables, string>>(
  {} as Record<MessageVariables, string>
)
  .on(getMessageVariablesFx.doneData, (_, variables) => {
    const preparedVariables = { ...variables };
    if (!preparedVariables[MessageVariables.SmaName])
      preparedVariables[MessageVariables.SmaName] = i18n.t(
        `communication.editMessage.${MessageVariables.SmaName}`
      );

    return preparedVariables;
  })
  .reset(resetVariables);

forward({
  from: [createMessageFx.done.map((payload) => ({ chatId: payload.params.deal?.chat?.id }))],
  to: [getMessagesByChatFx],
});

forward({
  from: [
    resendMessageFx.done.map(({ params }) => params),
    deleteMessageFx.done.map(({ params }) => params),
  ],
  to: onResendOrDeleteMessageFx,
});
