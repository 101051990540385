/*
 * Утилиты модуля payments.
 */
import i18n from 'i18next';
import { Currency } from '@hypetrainCommon';
import { TSelectedPayments } from './payments.types';

/*
 * Маппинг названий платежных счетов.
 */
const PAYMENT_ACCOUT_NAME_MAP: Partial<Record<Currency, string>> = {
  [Currency.USD]: i18n.t('payments.accountsNames.usd'),
  [Currency.EUR]: i18n.t('payments.accountsNames.eur'),
};

/*
 * Возвращает название платежного счета.
 */
export const getPaymentAccountName = (currency: Currency): string => {
  if (!currency) return '';

  return PAYMENT_ACCOUT_NAME_MAP[currency] ?? '';
};

/*
 * Возвращает признак наличия выбранных пользователем платежей.
 */
export function hasSelectedPayments(selectedPayments: TSelectedPayments): boolean {
  return Boolean(
    Object.values(selectedPayments).find((selectedByFilter) => selectedByFilter.length)
  );
}
