import { FC, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { BUTTON_TYPE, Button } from '@uikit/components/Buttons';
import { Input } from '@uikit/components/Fields/Inputs';
import { DefaultForm } from '@uikit/components/templates/DefaultForm';
import { Routes } from '@router/Routes';
import { GET_INVITE_INFO_ERROR_CODES } from '@api/workspace/workspace.api.constants';
import i18n from '@services/i18n';
import { makeValidate } from '@utils/makeValidate';
import { redirectToApp } from '@utils/redirect.utils';
import { AcceptWorkspaceInviteError } from '@pagesPersonInvitation/components/AcceptWorkspaceInviteError';
import { personCreation } from './model';

type TCreateOrgPersonProps = {
  email: string;
  organizationName?: string;
};

type TCreateOrgPersonSchema = {
  personFullName: string;
  personJobTitle: string;
};

export const CreateOrgPerson: FC<TCreateOrgPersonProps> = ({ email, organizationName }) => {
  const { t } = useTranslation();
  const { inviteId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const [acceptWorkspaceInviteError, setAcceptWorkspaceInviteError] = useState(false);

  const onSubmit = ({ personFullName, personJobTitle }: TCreateOrgPersonSchema): void => {
    personCreation({
      personInfo: {
        personFullName,
        personJobTitle,
      },
      inviteId,
      workspaceId: searchParams.get('workspace') || '',
    })
      .then(({ handler }) => {
        redirectToApp(handler, Routes.discovery);
      })
      .catch((error) => {
        if (GET_INVITE_INFO_ERROR_CODES.includes(error.response?.data?.slug)) {
          setAcceptWorkspaceInviteError(true);
        }
      });
  };

  const schema: Yup.SchemaOf<TCreateOrgPersonSchema> = Yup.object().shape({
    personFullName: Yup.string().validField().required(i18n.t('errors.person.fullName')),
    personJobTitle: Yup.string().validField().required(i18n.t('errors.person.jobTitle')),
  });

  const validate = makeValidate(schema);

  if (acceptWorkspaceInviteError) {
    return (
      <AcceptWorkspaceInviteError
        header={t('acceptWorkspaceInviteError.getInfoErrorHeader')}
        description={t('acceptWorkspaceInviteError.getInfoErrorDescription')}
      />
    );
  }
  return (
    <DefaultForm
      mainTitle={t('joinCompany', { organizationName })}
      secondaryTitle={t('needToCreate')}
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{ email }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field name="email">
              {({ meta, input }) => (
                <Input
                  input={input}
                  label={t('loginScreen.emailForLogin')}
                  error={meta?.submitFailed && meta?.error}
                  disabled
                />
              )}
            </Field>
            <Field name="personFullName">
              {({ meta, input }) => (
                <Input
                  input={input}
                  label={t('fullName')}
                  error={meta?.submitFailed && meta?.error}
                />
              )}
            </Field>
            <Field name="personJobTitle">
              {({ meta, input }) => (
                <Input
                  input={input}
                  label={t('jobTitle')}
                  error={meta?.submitFailed && meta?.error}
                />
              )}
            </Field>
            <Button buttonType={BUTTON_TYPE.COMMIT}>{t('createPerson')}</Button>
          </form>
        )}
      />
    </DefaultForm>
  );
};
