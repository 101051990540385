// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".campaignsSearch__list__ktHP1{margin-top:var(--hSpacing20);height:calc(100% - var(--hSpacing20) - 40px)}.campaignsSearch__nothingFountSearch__x55GN{white-space:break-spaces;text-align:center}", "",{"version":3,"sources":["webpack://./pages/Campaigns/CampaignDetailsPage/components/AddToExistingCampaignDialog/components/CampaignsSearch/CampaignsSearch.module.scss"],"names":[],"mappings":"AAME,8BACE,4BALwB,CAMxB,4CAAA,CAGF,4CACE,wBAAA,CACA,iBAAA","sourcesContent":["@import '../../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n$campaigns-list-margin-top: var(--hSpacing20);\n$search-input-height: 40px;\n\n.campaignsSearch {\n  &__list {\n    margin-top: $campaigns-list-margin-top;\n    height: calc(100% - #{$campaigns-list-margin-top} - #{$search-input-height});\n  }\n\n  &__nothingFountSearch {\n    white-space: break-spaces;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"campaignsSearch__list": "campaignsSearch__list__ktHP1",
	"campaignsSearch__nothingFountSearch": "campaignsSearch__nothingFountSearch__x55GN"
};
export default ___CSS_LOADER_EXPORT___;
