/*
 * Компонент фильтра переключателя
 */
import { FC, memo } from 'react';
import { ChipToggler } from '@uikit/components/ChipToggler';
import { TFilterConfig } from '@uikit/components/Filter';
import { TFilter } from '@uikit/components/Filters/filters.types';

type TToggleFilterProps = {
  config: TFilterConfig;
  onApply: (value: TFilter) => void;
};

export const ToggleFilter: FC<TToggleFilterProps> = memo(({ config, onApply }) => {
  const onToggle = () => {
    if (config.filterValue.values?.[0] === 'true') {
      onApply({ ...config.filterValue, values: [] });
      return;
    }

    onApply({ ...config.filterValue, values: ['true'] });
  };

  return (
    <ChipToggler
      onToggle={onToggle}
      isToggled={config.filterValue.values?.[0] === 'true'}
      onClear={() => onApply({ ...config.filterValue, values: [] })}
      name={config.label}
      withoutValue
    />
  );
});
