import { useStore } from 'effector-react/compat';
import { Suspense, useEffect } from 'react';
import { lazily } from 'react-lazily';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Routes as Links } from 'router/Routes';
import { HypetrainUIContainer } from '@uikit/components/HypetrainUIContainer';
import { Loader } from '@uikit/components/Loader';
import { CampaignsRouter } from '@router/CampaignsRouter';
import { DiscoveryRouter } from '@router/DiscoveryRouter';
import { PaymentsRouter } from '@router/PaymentsRouter';
import { WorkspaceSettingsRouter } from '@router/WorkspaceSettingsRouter';
import { locationService } from '@services/location/location.service';
import { isAuth } from '@utils/auth.utils';
import { useInitWorkspaces } from '@hooks/useInitWorkspaces';
import { $userProfile } from '@models/profile';
import { Background } from '@layouts/../components/Background';
import { PersonInvitation } from '@pagesPersonInvitation';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import styles from './Router.module.scss';

const { Login } = lazily(() => import('@pages/Login'));
const { ResetPassword } = lazily(() => import('@pages/ResetPassword'));
const { SignUp } = lazily(() => import('@pages/SignUp'));
const { ChangeWorkspaceHandler } = lazily(() => import('@pages/ChangeWorkspaceHandler'));
const { AddressBook } = lazily(() => import('@pages/AddressBook'));
const { NoAccess } = lazily(() => import('@pages/NoAccess'));
const { ReviewPaymentPage } = lazily(() => import('@pages/ReviewPaymentPage'));
const { StartTrial } = lazily(() => import('@pages/StartTrial'));

const { ChangePassword } = lazily(() => import('@pages/ChangePassword'));

export const Router = () => {
  const location = useLocation();
  const profile = useStore($userProfile);

  useInitWorkspaces();

  useEffect(() => {
    locationService.setNewLocation(location);
  }, [location]);

  return (
    <div className={styles.rootWrapper}>
      <HypetrainUIContainer className={styles.rootWrapper__contentError} />

      <Background>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path={Links.login}
              element={
                <PublicRoute errorBoundaryName="login">
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path={Links.joinWorkspace}
              element={<PersonInvitation />}
            />
            <Route
              path={Links.join}
              element={
                <PublicRoute errorBoundaryName="join">
                  <SignUp />
                </PublicRoute>
              }
            />
            <Route path={Links.resetPassword}>
              <Route
                path=":token"
                element={
                  <PublicRoute errorBoundaryName="resetPasswordWitkToken">
                    <ResetPassword />
                  </PublicRoute>
                }
              />
              <Route
                path=""
                element={
                  <PublicRoute errorBoundaryName="resetPassword">
                    <ResetPassword />
                  </PublicRoute>
                }
              />
            </Route>

            {/* Private */}

            <Route
              path={Links.noAccess}
              element={
                <PrivateRoute errorBoundaryName="noAccess">
                  <NoAccess />
                </PrivateRoute>
              }
            />
            {profile?.hasPassword && (
              <Route
                path={Links.changePassword}
                element={
                  <PrivateRoute errorBoundaryName="changePassword">
                    <ChangePassword />
                  </PrivateRoute>
                }
              />
            )}
            <Route
              path={Links.workspaceCreation}
              element={
                <PrivateRoute errorBoundaryName="workspaceCreation">
                  <StartTrial />
                </PrivateRoute>
              }
            />
            <Route
              path={Links.editWorkspace}
              element={
                <PrivateRoute errorBoundaryName="workspaceEdit">
                  <ChangeWorkspaceHandler />
                </PrivateRoute>
              }
            />
            <Route
              path={Links.addressBook}
              element={
                <PrivateRoute errorBoundaryName="addressBook">
                  <AddressBook />
                </PrivateRoute>
              }
            />
            <Route
              path={Links.reviewPayment}
              element={
                <PrivateRoute errorBoundaryName="reviewPayment">
                  <ReviewPaymentPage />
                </PrivateRoute>
              }
            />
            <Route
              path={Links.choseTariffPlan}
              element={
                <PrivateRoute errorBoundaryName="choseTariffPlan">
                  <StartTrial />
                </PrivateRoute>
              }
            />

            {DiscoveryRouter()}

            {CampaignsRouter()}

            {WorkspaceSettingsRouter()}

            {PaymentsRouter()}

            <Route
              path="*"
              element={<Navigate to={isAuth() ? Links.discovery : Links.login} />}
            />
          </Routes>
        </Suspense>
      </Background>
    </div>
  );
};
