import _get from 'lodash/get';
import {
  IInstagramSmaStatisticsResponseDto,
  ITiktokSmaStatisticsSimpleInfoDto,
  IYoutubeSmaStatisticsResponseDto,
} from '@hypetrainCommon';

export type TSummaryDeals = {
  viewsTotal?: number | null;
  erMedian?: number | null;
  recPrice?: number | null;
  cpmTotal?: number | null;
};

export const getSummaryDeals = (
  selectedInfluencers: IYoutubeSmaStatisticsResponseDto[]
): TSummaryDeals => {
  const summaryDeals = selectedInfluencers.reduce(
    (prev, current) => ({
      viewsTotal: _get(prev, 'viewsTotal', 0) + _get(current, 'videosMetrics["30"].r.value.med', 0),
      erSum:
        _get(prev, 'erSum', 0) +
        _get(current, 'videosMetrics["30"].er.value.med', 0) *
          _get(current, 'videosMetrics["30"].r.value.med', 0),
      recPrice: _get(prev, 'recPrice', 0) + _get(current, 'price.priceReach', 0),
    }),
    { viewsTotal: 0, erSum: 0, recPrice: 0 }
  );

  return {
    viewsTotal: !Number.isNaN(summaryDeals.viewsTotal) ? summaryDeals.viewsTotal : null,
    erMedian: !Number.isNaN(summaryDeals.erSum / summaryDeals.viewsTotal)
      ? summaryDeals.erSum / summaryDeals.viewsTotal
      : null,
    recPrice: !Number.isNaN(summaryDeals.recPrice) ? summaryDeals.recPrice : null,
    cpmTotal: !Number.isNaN((summaryDeals.recPrice * 1000) / summaryDeals.viewsTotal)
      ? (summaryDeals.recPrice * 1000) / summaryDeals.viewsTotal
      : null,
  };
};

export const getInstagramSummaryDeals = (
  selectedInfluencers: IInstagramSmaStatisticsResponseDto[]
): TSummaryDeals => {
  const summaryDeals = selectedInfluencers.reduce(
    (prev, current) => ({
      erSum: _get(prev, 'erSum', 0) + _get(current, 'postMetrics.er', 0),
    }),
    { erSum: 0 }
  );

  return {
    erMedian: !Number.isNaN(summaryDeals.erSum / selectedInfluencers.length)
      ? summaryDeals.erSum / selectedInfluencers.length
      : null,
  };
};

export const getTikTokSummaryDeals = (
  selectedInfluencers: ITiktokSmaStatisticsSimpleInfoDto[]
): TSummaryDeals => {
  const summaryDeals = selectedInfluencers.reduce(
    (prev, current) => ({
      erSum: _get(prev, 'erSum', 0) + _get(current, 'postMetrics.er', 0),
      viewsTotal: _get(prev, 'viewsTotal', 0) + _get(current, 'postMetrics.r', 0),
    }),
    { erSum: 0, viewsTotal: 0 }
  );

  return {
    erMedian: !Number.isNaN(summaryDeals.erSum / selectedInfluencers.length)
      ? summaryDeals.erSum / selectedInfluencers.length
      : null,
    viewsTotal: !Number.isNaN(summaryDeals.viewsTotal) ? summaryDeals.viewsTotal : null,
  };
};
