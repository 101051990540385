/*
 * Компонент описания транзакции в платежах.
 */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ITransactionDto, TransactionType } from '@hypetrainCommon';

type TPaymentTransactionDescriptionProps = {
  transaction: ITransactionDto;
};

export const PaymentTransactionDescription: FC<TPaymentTransactionDescriptionProps> = ({
  transaction,
}) => {
  const { t } = useTranslation();

  if (transaction?.type === TransactionType.IN) return null;

  if (transaction?.transfers?.length === 1) {
    return <span>{t('paymentTransactionDescription.outOne')}</span>;
  }

  return (
    <span>
      {t('paymentTransactionDescription.outSeveral', { transfers: transaction?.transfers?.length })}
    </span>
  );
};
