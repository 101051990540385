/* График Демографии
 https://www.figma.com/file/b1FywVG2x7jxxcWxuPlTJI/Instagram-addition?node-id=3920%3A72486
 */
import { SmaPlatform } from '@hypetrainCommon';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { CELL_POSITION, DefaultCell, TableHeaderCell } from '@uikit/components/Table';
import { TColumn } from '@uikit/components/Table/table.types';
import i18n from '@services/i18n';
import {
  SEX_DEMOGRAPHIC,
  TGenders,
  TGendersPerAges,
  TMaxAgeAudienceDemographic,
} from '@components/AudienceDemographic/AudienceDemographic.types';
import { INSTAGRAM_AUDIENCE_TYPES } from '@components/AudienceDemographic/audience.constant';
import styles from './AudienceDemographic.module.scss';
import { DemographicGraphic } from './DemographicGraphic.component';
import { DemographicVisitors } from './DemographicVisitors.component';

type TAudienceDemographicsColumnProps = {
  gendersPerAges: TGendersPerAges;
  views: number;
  filterAudience: TFilter;
  maxAge: TMaxAgeAudienceDemographic;
  genders: TGenders;
  selectedTab?: string;
  platform: SmaPlatform;
};

// TODO вынести в utils
const getHeaderMaleInstagramCell = (selectedTab: string): string => {
  if (selectedTab === INSTAGRAM_AUDIENCE_TYPES.followers) {
    return i18n.t('profile.audience.males');
  }

  return i18n.t('profile.audience.likesByMale');
};

// TODO вынести в utils
const getHeaderFemaleInstagramCell = (selectedTab: string): string => {
  if (selectedTab === INSTAGRAM_AUDIENCE_TYPES.followers) {
    return i18n.t('profile.audience.females');
  }

  return i18n.t('profile.audience.likesByFemale');
};

export const getAudienceDemographicsColumn = ({
  gendersPerAges,
  views,
  filterAudience,
  maxAge,
  genders,
  selectedTab,
  platform,
}: TAudienceDemographicsColumnProps): TColumn[] => [
  {
    id: 'male',
    accessor: '',
    Header: (
      <DefaultCell position={CELL_POSITION.LEFT}>
        <TableHeaderCell
          text={
            platform === SmaPlatform.Youtube
              ? i18n.t('profile.audience.males')
              : getHeaderMaleInstagramCell(selectedTab as string)
          }
        />
      </DefaultCell>
    ),
    width: 100,
    disableSortBy: true,
    Cell: ({ row }) => (
      <DemographicVisitors
        gendersPerAges={gendersPerAges}
        rangeAge={row?.original}
        views={views}
        sex={SEX_DEMOGRAPHIC.MALE}
      />
    ),
  },
  {
    id: 'male%',
    accessor: '',
    Header: (
      <DefaultCell position={CELL_POSITION.RIGHT}>
        <TableHeaderCell
          textClassName={styles.textPrimary}
          text={`${(
            ((genders?.male || genders?.[SEX_DEMOGRAPHIC.MALE]?.weight) ?? 0) * 100
          ).toFixed()}%`}
        />
      </DefaultCell>
    ),
    width: 80,
    disableSortBy: true,
    Cell: ({ row }) => (
      <DemographicGraphic
        gendersPerAges={gendersPerAges}
        rangeAge={row?.original}
        filterAudience={filterAudience}
        maxAge={maxAge}
        sex={SEX_DEMOGRAPHIC.MALE}
      />
    ),
  },
  {
    id: 'empty',
    accessor: '',
    Header: (
      <DefaultCell position={CELL_POSITION.CENTER}>
        <TableHeaderCell text={i18n.t('profile.audience.age')} />
      </DefaultCell>
    ),
    width: 50,
    disableSortBy: true,
    Cell: ({ row }) => (
      <div className={styles.demographicTable__ages}>{row.original.replace('65-', '65+')}</div>
    ),
  },
  {
    id: 'female%',
    accessor: '',
    Header: (
      <DefaultCell position={CELL_POSITION.LEFT}>
        <TableHeaderCell
          text={`${(
            ((genders?.female || genders?.[SEX_DEMOGRAPHIC.FEMALE]?.weight) ?? 0) * 100
          ).toFixed()}%`}
          textClassName={styles.textPrimary}
        />
      </DefaultCell>
    ),
    width: 80,
    disableSortBy: true,
    Cell: ({ row }) => (
      <DemographicGraphic
        gendersPerAges={gendersPerAges}
        rangeAge={row?.original}
        filterAudience={filterAudience}
        maxAge={maxAge}
        sex={SEX_DEMOGRAPHIC.FEMALE}
      />
    ),
  },
  {
    id: 'female',
    accessor: '',
    Header: (
      <DefaultCell position={CELL_POSITION.RIGHT}>
        <TableHeaderCell
          text={
            platform === SmaPlatform.Youtube
              ? i18n.t('profile.audience.females')
              : getHeaderFemaleInstagramCell(selectedTab as string)
          }
        />
      </DefaultCell>
    ),
    width: 100,
    disableSortBy: true,
    Cell: ({ row }) => (
      <DemographicVisitors
        gendersPerAges={gendersPerAges}
        rangeAge={row?.original}
        views={views}
        sex={SEX_DEMOGRAPHIC.FEMALE}
      />
    ),
  },
];
