/*
 * Filters configuration for campaign detail page.
 */
import {
  CampaignFilterName,
  DayNumber,
  YoutubeCampaignFilterName,
  YoutubeEngagementRateFilter,
  YoutubeFilterName,
  YoutubeHasContactFilter,
  YoutubeReachFilter,
  YoutubeReplyStatusCampaignFilter,
} from '@hypetrainCommon';
import { FILTER_TYPES } from '@uikit/components/Filter';
import { TRadioGroupFilterConfig } from '@uikit/components/Filter/components/RadioGroupFilter';
import {
  RANGE_TYPES,
  TRangeConfig,
} from '@uikit/components/Filter/components/RangeFilter/RangeFilter.types';
import { TSelectFilterConfig } from '@uikit/components/Filter/components/SelectFilter';
import { TAvailableFilter } from '@uikit/components/Filters';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { stringToBoolean } from '@uikit/components/Filters/filters.utils';
import i18n from '@services/i18n';
import { getCountryOptions } from '@constants/countries';

const engagementRateFilterConfig: TAvailableFilter<TRangeConfig> = {
  id: YoutubeFilterName.EngagementRate,
  filterType: FILTER_TYPES.RANGE,
  label: i18n.t('engagementRate'),
  description: i18n.t('engagementRateFilterDescription'),
  testId: 'YT_ER_FILTER',
  filterProps: {
    type: RANGE_TYPES.PERCENT,
    from: {
      placeholder: '0%',
      precision: 1,
    },
    to: {
      placeholder: '100%',
      precision: 1,
    },
  },
  formatValueToDTO: (filter: TFilter): YoutubeEngagementRateFilter => ({
    name: YoutubeFilterName.EngagementRate,
    min: filter.min,
    max: filter.max,
    value: DayNumber.Thirtieth,
  }),
};

const subcribersFilterConfig: TAvailableFilter<TRangeConfig> = {
  id: YoutubeFilterName.SubscriberCount,
  filterType: FILTER_TYPES.RANGE,
  label: i18n.t('subscriberCount'),
  testId: 'YT_SUBSCRIBERS_COUNT_FILTER',
  filterProps: {
    type: RANGE_TYPES.NUMBER,
    from: {
      placeholder: '3,000',
    },
    to: {
      placeholder: '10,000,000+',
    },
  },
};

const viewsByMonthFilterConfig: TAvailableFilter<TRangeConfig> = {
  id: YoutubeFilterName.Reach,
  filterType: FILTER_TYPES.RANGE,
  label: i18n.t('viewsByMonth'),
  description: i18n.t('viewsByMonthFilterDescription'),
  testId: 'YT_REACH_FILTER',
  filterProps: {
    type: RANGE_TYPES.NUMBER,
    from: {
      placeholder: '3,000',
    },
    to: {
      placeholder: '10,000,000+',
    },
  },
  formatValueToDTO: (filter: TFilter): YoutubeReachFilter => ({
    name: YoutubeFilterName.Reach,
    min: filter.min,
    max: filter.max,
    value: DayNumber.Thirtieth,
  }),
};

const recommendedPriceFilterConfig: TAvailableFilter<TRangeConfig> = {
  id: YoutubeFilterName.PriceReach,
  filterType: FILTER_TYPES.RANGE,
  label: i18n.t('priceReach'),
  description: i18n.t('recommendedPriceFilterDescription'),
  testId: 'YT_PRICE_FILTER',
  filterProps: {
    type: RANGE_TYPES.CURRENCY,
    from: {
      placeholder: '10',
    },
    to: {
      placeholder: '25,000+',
    },
  },
};

const influencerssLocationFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: YoutubeFilterName.Country,
  filterType: FILTER_TYPES.SELECT,
  label: i18n.t('influencersLocation'),
  popupTitle: i18n.t('channeBasedIn'),
  description: i18n.t('influencersLocationDescription'),
  testId: 'YT_COUNTRY_FILTER',
  filterProps: {
    isMulti: true,
    placeholder: i18n.t('influencersLocationPlaceholder'),
    options: getCountryOptions(),
  },
};

const haveContactFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: YoutubeFilterName.HasContact,
  filterType: FILTER_TYPES.RADIO,
  label: i18n.t('hasEmail'),
  testId: 'YT_HAS_CONTACT_FILTER',
  filterProps: {
    defaultValue: null,
    options: [
      {
        label: i18n.t('withEmail'),
        value: 'true',
      },
      {
        label: i18n.t('withoutEmail'),
        value: 'false',
      },
    ],
  },
  formatValueToDTO: (filter: TFilter): YoutubeHasContactFilter => ({
    name: YoutubeFilterName.HasContact,
    value: stringToBoolean(filter.values?.[0] || ''),
  }),
};

const replyStatusFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: CampaignFilterName.ReplyStatus,
  filterType: FILTER_TYPES.RADIO,
  label: i18n.t('filters.youtube.replyStatus'),
  testId: 'YT_REPLY_STATUS_FILTER',
  filterProps: {
    defaultValue: null,
    options: [
      {
        label: i18n.t('filters.youtube.replied'),
        value: 'true',
      },
      {
        label: i18n.t('filters.youtube.notReplied'),
        value: 'false',
      },
    ],
  },
  formatValueToDTO: (filter: TFilter): YoutubeReplyStatusCampaignFilter => ({
    name: YoutubeCampaignFilterName.ReplyStatus,
    value: stringToBoolean(filter.values?.[0] || ''),
  }),
};

export const AVAILABLE_CAMPAIGN_FILTERS_CONFIG: TAvailableFilter[] = [
  engagementRateFilterConfig,
  subcribersFilterConfig,
  viewsByMonthFilterConfig,
  recommendedPriceFilterConfig,
  influencerssLocationFilterConfig,
  haveContactFilterConfig,
  replyStatusFilterConfig,
];
