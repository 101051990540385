/*
 * Утилиты CampaignDealMessagesSocketService'а.
 */
import i18n from 'i18next';
import { BulkMessagingFinishedNotification, MessageStatus } from '@hypetrainCommon';
import { notificationsService } from '@uikit/components/Notification';

/*
 * Обработчик нотификации об изменении статуса массовой рассылки.
 */
export const handleMassMailingChangeStatus = (
  notification: BulkMessagingFinishedNotification
): void => {
  const failedMessages = notification?.data?.messages?.filter((message) =>
    message?.recipientsStatuses?.some((recipient) => recipient?.status === MessageStatus.error)
  );

  if (failedMessages?.length) {
    const errorCaption =
      failedMessages?.length > 1
        ? i18n.t('communication.massMailing.messagesNotSent', { failedMessages })
        : i18n.t('communication.massMailing.messageNotSent');

    notificationsService.error({
      timeout: 10000,
      props: {
        caption: errorCaption,
        description: i18n.t('communication.massMailing.toSeeUnsent'),
      },
    });
  }

  const successCaption =
    failedMessages?.length > 1
      ? i18n.t('communication.massMailing.messagesSentTo', { failedMessages })
      : i18n.t('communication.massMailing.messageSentTo');

  notificationsService.successful({
    timeout: 10000,
    props: {
      caption: successCaption,
    },
  });
};
