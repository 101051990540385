import styles from '@uikit/components/StateBadge/StateBadge.module.scss';

export enum STATE_BUDGE_STYLES {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
  DISABLE = 'DISABLE',
}

export const TIP_CLASS_MAP_BY_STYLES: Record<STATE_BUDGE_STYLES, string> = {
  [STATE_BUDGE_STYLES.POSITIVE]: styles.stateBadge__positive,
  [STATE_BUDGE_STYLES.NEGATIVE]: styles.stateBadge__negative,
  [STATE_BUDGE_STYLES.NEUTRAL]: styles.stateBadge__neutral,
  [STATE_BUDGE_STYLES.DISABLE]: styles.stateBadge__disable,
};
