// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filterMultiSelectOption__kMwHm{padding-right:var(--vSpacing8)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Filter/components/SelectFilter/components/FilterMultiSelectOption/FilterMultiSelectOption.module.scss"],"names":[],"mappings":"AAEA,gCACE,8BAAA","sourcesContent":["@import '../../../../../../assets/styles/ui_kit_mixins';\n\n.filterMultiSelectOption {\n  padding-right: var(--vSpacing8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterMultiSelectOption": "filterMultiSelectOption__kMwHm"
};
export default ___CSS_LOADER_EXPORT___;
