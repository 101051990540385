import { Store, createEffect, createStore } from 'effector/compat';
import { IUploadingListResponseDto } from '@hypetrainCommon';
import { explorationApiService } from '@api/exploration';
import { prepareInfluencersData } from '@services/tableSmaFormatter';
import { logInfluencersUploading } from '@pages/Discovery/InfluencersUpload/influencersUpload.utils';

/*
 * Отправка csv файла со списком инфлюенсеров.
 */
export const uploadUsersListFx = createEffect(
  (requestData: { formData: FormData; showLoader?: boolean }) =>
    explorationApiService
      .uploadUserList(requestData.formData, requestData.showLoader)
      .then((response) => {
        logInfluencersUploading(response);
        return response;
      })
);

export const $uploadedUsersStore: Store<IUploadingListResponseDto> = createStore({}).on(
  uploadUsersListFx.doneData,
  (state, payload) => {
    prepareInfluencersData(payload?.smasStatistics || []);
    return {
      ...payload,
      unknownYoutubeSmasListUuid: `/unknown-youtube-smas-list/${payload.unknownYoutubeSmasListUuid}`,
    };
  }
);
