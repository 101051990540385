// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paymentAccountPage__tCgcd{display:flex;flex-direction:column;position:relative;height:100%}.paymentAccountPage__recipientCell__qOKps{margin-left:var(--vSpacing36)}", "",{"version":3,"sources":["webpack://./pages/PaymentsPage/components/PaymentAccountPage/PaymentAccountPage.module.scss"],"names":[],"mappings":"AAEA,2BACE,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,WAAA,CAEA,0CACE,6BAAA","sourcesContent":["@import '../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.paymentAccountPage {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  height: 100%;\n\n  &__recipientCell {\n    margin-left: var(--vSpacing36);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentAccountPage": "paymentAccountPage__tCgcd",
	"paymentAccountPage__recipientCell": "paymentAccountPage__recipientCell__qOKps"
};
export default ___CSS_LOADER_EXPORT___;
