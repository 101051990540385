import { createRoot } from 'react-dom/client';
import '@uikit/assets/styles/ui_kit_variables.scss';
import '@assets/styles/app.scss';
import '@assets/styles/fonts.scss';
import '@assets/styles/reset.scss';
import './services/i18n';
import { AppWithFeatureToggilng } from './App';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<AppWithFeatureToggilng />);
