/*
 * Общие утилиты для модуля profile.
 */
import dayjs from 'dayjs';
import { Store } from 'effector/compat';
import {
  IInstagramSmaResponseDto,
  IMetricPointResponseDto,
  ITiktokSmaResponseDto,
  IYoutubeSmaResponseDto,
  MetricPoint,
  SmaPlatform,
} from '@hypetrainCommon';
import { $profileInstagramStore } from '@components/Profile/components/ProfileInstagram/profileInstagram.model';
import { $profileTikTokStore } from '@components/Profile/components/ProfileTikTok/profileTikTok.model';
import { $profileYoutubeStore } from '@components/Profile/components/ProfileYoutube/profileYoutube.model';

/*
 * Преобразуем ISO дату к timestamp так как для формирования точки на графике нужно числовое значение
 */
export function prepareTimestampProfileMetric(chartData: IMetricPointResponseDto[]): MetricPoint[] {
  return (
    chartData?.map((metric) => ({
      ...(metric || { value: null }),
      timestamp: dayjs(metric?.timestamp)?.valueOf(),
    })) || []
  );
}

export const profileStoresMap: Partial<
  Record<
    SmaPlatform,
    Store<IYoutubeSmaResponseDto> | Store<IInstagramSmaResponseDto> | Store<ITiktokSmaResponseDto>
  >
> = {
  [SmaPlatform.Youtube]: $profileYoutubeStore,
  [SmaPlatform.Instagram]: $profileInstagramStore,
  [SmaPlatform.Tiktok]: $profileTikTokStore,
};

export const getProfileStore = (platform: SmaPlatform) => profileStoresMap[platform];
