/* Компонент для отрисовки круговой диаграммы стран
 Используется как в таблице так и в профайле.
 @see https://www.figma.com/file/jl6mAOWEYarcQ4TvtFIjMy/YouTube-profile?node-id=199%3A6624
 */
import isEmpty from 'lodash/isEmpty';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SmaAudienceCountryValue, SmaPlatform } from '@hypetrainCommon';
import { ChartCard } from '@uikit/components/ChartCard';
import { EMPTY_BLOCK_POSITION, EmptyBlock } from '@uikit/components/EmptyBlock';
import { Popup, PopupPositions } from '@uikit/components/Popup';
import { Table } from '@uikit/components/Table';
import { getAudienceCountries, metricsForTable } from '@services/tableSmaFormatter';
import { colorCountries } from '@helpers/colorCountries';
import { useGetBrowseFiltersByPlatform } from '@hooks/useGetBrowseFiltersByPlatform';
import { EmptyAddressBook } from '@pagesAddressBook/components/EmptyAddressBook';
import { getAudienceLocationColumn } from '@components/AudienceLocation/components/AudienceLocationColumn';
import { useCountriesFilters } from '@components/AudienceLocation/useCountriesFilters';
import { PieAudienceGraphic } from '@components/PieAudienceGraphic';
import styles from './AudienceLocation.module.scss';

type TAudienceLocationProps = {
  countries: SmaAudienceCountryValue[];
  subscribers: number;
  isProfile: boolean;
  uuid: string;
  info: string;
  platform: SmaPlatform;
  selectedTab?: string;
};

export const AudienceLocation: FC<TAudienceLocationProps> = ({
  countries,
  subscribers,
  isProfile,
  uuid,
  platform,
  info,
  selectedTab,
}) => {
  const { t } = useTranslation();
  const browseFilters = useGetBrowseFiltersByPlatform();
  const filterCountries = useCountriesFilters();

  const countriesDiagram =
    metricsForTable[uuid]?.countriesDiagram ||
    getAudienceCountries(
      browseFilters.map((el) => el.filterValue),
      countries
    );

  const countryValues = filterCountries?.values ? countriesDiagram?.memoizedValues : countries;

  const isTargetedCountries = countriesDiagram?.isTargetedCountries;
  const isNonTargetedCountries = countriesDiagram?.isNonTargetedCountries;
  const graphicData: SmaAudienceCountryValue[][] = filterCountries?.values
    ? [isTargetedCountries, isNonTargetedCountries]
    : [countryValues];

  if (isEmpty(countryValues) && !isProfile) {
    return <EmptyBlock position={EMPTY_BLOCK_POSITION.CENTER} />;
  }

  const contentAudienceLocation = (
    <ChartCard
      className={styles.audienceLocation}
      name={t('profile.audience.location')}
      isNoData={isEmpty(countryValues) && isProfile}
      info={isProfile ? info : undefined}
    >
      <div className={styles.audienceLocation__graphicContainer}>
        <div className={styles.audienceLocation__graphic}>
          <PieAudienceGraphic
            uuid={uuid}
            values={countryValues}
            filterValues={filterCountries}
            colorsMap={colorCountries}
            graphicData={graphicData}
            isProfile
          />
        </div>
        <div className={styles.audienceLocation__descriptionGraphic}>
          <Table
            data={countryValues}
            className={styles.audienceLocation__audienceCountryTable}
            columns={getAudienceLocationColumn({
              filterCountries,
              subscribers,
              platform,
              selectedTab,
            })}
            emptyTable={<EmptyAddressBook />}
          />
        </div>
      </div>
    </ChartCard>
  );

  if (isProfile) {
    return contentAudienceLocation;
  }

  return (
    <Popup
      content={contentAudienceLocation}
      position={PopupPositions.LeftCenter}
      offset={20}
    >
      <PieAudienceGraphic
        uuid={uuid}
        values={countryValues}
        filterValues={filterCountries}
        colorsMap={colorCountries}
        graphicData={graphicData}
      />
    </Popup>
  );
};
