/*
 * Wrapper for value in assignee select field.
 */
import { FC } from 'react';
import { SingleValueProps, components } from 'react-select';
import { IPersonSimpleInfoDto } from '@hypetrainCommon';
import { Avatar } from '@uikit/components/Avatar';
import styles from './AssigneeSelectValue.module.scss';

export const AssigneeSelectValue: FC<SingleValueProps> = ({ ...props }) => (
  <components.SingleValue {...props}>
    <div className={styles.assigneeSelectValue}>
      <Avatar
        className={styles.assigneeSelectValue__avatar}
        idForColor={(props.data as IPersonSimpleInfoDto)?.id}
        entityName={(props.data as IPersonSimpleInfoDto)?.name}
      />
      {props.children}
    </div>
  </components.SingleValue>
);
