/*
 * Games filter option component
 */
import { FC, memo } from 'react';
import { OptionProps } from 'react-select';
import { ListItem } from '@uikit/components/ListItem';
import styles from './GamesFilterOption.module.scss';
import { TGameInfo } from './gamesFilterOption.types';

export const GamesFilterOption: FC<OptionProps<TGameInfo>> = memo(
  ({ innerProps, innerRef, data, isSelected }) => {
    const { thumbnail, label } = data;
    return (
      <ListItem
        mainText={label}
        avatar={{
          img: thumbnail,
          className: styles.gamesFilterOption__avatar,
        }}
        innerProps={innerProps}
        innerRef={innerRef}
        isSelected={isSelected}
        className={styles.gamesFilterOption__listItem}
      />
    );
  }
);
