// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".divider___LxOa{min-width:3px;width:3px;height:3px;background-color:var(--textSecondary);border-radius:50%;margin:0 var(--vSpacing12)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Divider/Divider.module.scss"],"names":[],"mappings":"AAEA,gBACE,aAAA,CACA,SAAA,CACA,UAAA,CACA,qCAAA,CACA,iBAAA,CACA,0BAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.divider {\n  min-width: 3px;\n  width: 3px;\n  height: 3px;\n  background-color: var(--textSecondary);\n  border-radius: 50%;\n  margin: 0 var(--vSpacing12);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "divider___LxOa"
};
export default ___CSS_LOADER_EXPORT___;
