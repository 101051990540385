// Хук нотификации при редиректе
import i18n from 'i18next';
import isArray from 'lodash/isArray';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { notificationsService } from '@uikit/components/Notification';
import { Routes } from '@router/Routes';

const NOTIFICATIONS_MAP = {
  [Routes.editWorkspace]: i18n.t('editWorkspace.workspaceNameCreated'),
  [Routes.workspaceCreation]: [i18n.t('workspaceCreated'), i18n.t('startTrial.trialNotification')],
  [Routes.choseTariffPlan]: i18n.t('startTrial.trialNotification'),
  [Routes.workspaceInfo]: i18n.t('workspaceInfo.saveNotification'),
};
export const useRedirectNotification = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const needNotify = searchParams.get('needNotify');
    searchParams.delete('needNotify');
    setSearchParams(searchParams);

    if (document.referrer) {
      const url = new URL(document.referrer);

      if (needNotify) {
        if (isArray(NOTIFICATIONS_MAP[url.pathname])) {
          (NOTIFICATIONS_MAP[url.pathname] as string[]).forEach((notification) =>
            notificationsService.successful({
              timeout: 3000,
              props: {
                caption: notification,
              },
            })
          );
          return;
        }

        notificationsService.successful({
          timeout: 3000,
          props: {
            caption: NOTIFICATIONS_MAP[url.pathname] as string,
          },
        });
      }
    }
  }, []);
};
