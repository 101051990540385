/*
 * Компонент для отрисовки каунтера для непрочитанных сообщений на странице communication
 * */
import { FC } from 'react';
import { CircleCounter } from '@uikit/components/CircleCounter';
import styles from './UnreadMessageCounter.module.scss';

type TUnreadMessage = {
  count: number;
};
export const UnreadMessageCounter: FC<TUnreadMessage> = ({ count }) => {
  if (!Number(count)) return null;

  return (
    <CircleCounter
      count={Number(count)}
      className={styles.unreadMessageCounter}
    />
  );
};
