import { useStore } from 'effector-react/compat';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { resetSelectedOptions } from '@uikit/components/Filter/components/AsyncSelectFilter/asyncSelectFilter.model';
import { ICON_TYPES } from '@uikit/components/Icon';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { Popup, PopupEvents, PopupPositions } from '@uikit/components/Popup';
import { StateBadge } from '@uikit/components/StateBadge';
import { STATE_BUDGE_STYLES } from '@uikit/components/StateBadge/StateBadge.utils';
import navigationLogo from '@assets/images/navigationLogo.svg';
import { Routes } from '@router/Routes';
import { addressBookUserAccessService, campaignsUserAccessService } from '@services/userAccess';
import { paymentUserAccessService } from '@services/userAccess/paymentsUserAccess.service';
import { USERS_ACTIONS, usersActionsLogService } from '@services/usersActionsLog';
import { isInactiveSubscription } from '@utils/subscription.utils';
import { hasOtherActiveWorkspace, isAnothersAccount } from '@utils/workspace.utils';
import { resetActivePlatform } from '@models/activeSocialPlatform';
import { $activeWorkspaceStore } from '@models/activeWorkspace';
import { $subscription } from '@models/subscription.model';
import { $workspacesStore } from '@models/workspaces/workspaces.model';
import { HelpCenter } from '@components/Background/components/NavigationPanel/components/HelpCenter';
import { PanelItem } from './components/PanelItem';
import { ProfileMenu } from './components/ProfileMenu';
import { WorkspacesMenu } from './components/WorkspacesMenu';
import styles from './NavigationPanel.module.scss';

export const NavigationPanel = memo(() => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const workspaces = useStore($workspacesStore);
  const activeWorkspace = useStore($activeWorkspaceStore);
  const subscription = useStore($subscription);
  const isSubscriptionCanceled = isInactiveSubscription(subscription);
  const showWorkspaceMenu = hasOtherActiveWorkspace(activeWorkspace, workspaces);

  const getMenuItems = useCallback(
    (isSubscriptionCanceledProp: boolean) => [
      {
        key: 'discovery',
        icon: ICON_TYPES.SEARCH,
        disabled: isSubscriptionCanceledProp,
        action: () => {
          resetActivePlatform();
          resetSelectedOptions();
        },
      },
      {
        key: 'campaigns',
        activeKey: 'campaign',
        icon: ICON_TYPES.MEGAPHONE,
        disabled: isSubscriptionCanceledProp,
        hide: !campaignsUserAccessService.canShowCampaignsMenuItem,
      },
      {
        key: 'addressBook',
        activeKey: 'address-book',
        icon: ICON_TYPES.ADDRESS_BOOK,
        disabled: isSubscriptionCanceledProp,
        hide: !addressBookUserAccessService.isAvailable,
      },
      {
        key: 'workspaceSettings',
        activeKey: 'workspace-settings',
        icon: ICON_TYPES.WORKSPACE_SETTINGS,
        disabled: true,
      },
      {
        key: 'payments',
        activeKey: 'payments',
        icon: ICON_TYPES.PAYMENT,
        hide: !paymentUserAccessService.canManagePayments,
      },
    ],
    []
  );

  return (
    <div className={styles.panel}>
      <div>
        <img
          src={navigationLogo}
          alt="navigationLogo"
          onClick={() => usersActionsLogService.log(USERS_ACTIONS.CLICK_ON_LOGO)}
        />
        <h4 className={styles.panel__betaText}>(beta)</h4>
        <StateBadge
          style={STATE_BUDGE_STYLES.NEGATIVE}
          className={styles.panel__testBadge}
        >
          {t('navigationPanel.academyMod')}
        </StateBadge>
      </div>
      <div className={styles.center}>
        {showWorkspaceMenu && (
          <>
            <Popup
              content={<WorkspacesMenu />}
              position={PopupPositions.RightBottom}
              event={PopupEvents.Click}
              offset={16}
              width={230}
              popupClassName={styles.panelPopup}
            >
              <div className={styles.workspaceIcon}>
                <PanelItem
                  name={t('navigation.account')}
                  tooltipText={t(`navigation.workspaceTooltip`)}
                  icon={
                    isAnothersAccount(workspaces)
                      ? {
                          type: ICON_TYPES.WARNING,
                          style: ICON_STYLES.WARNING,
                          withBackground: true,
                        }
                      : undefined
                  }
                />
              </div>
            </Popup>
            <div className={styles.divider} />
          </>
        )}

        {getMenuItems(isSubscriptionCanceled)
          .filter((item) => !item.hide)
          .map(({ activeKey, key, icon, disabled, action }) => (
            <PanelItem
              key={key}
              name={t(key)}
              icon={{ type: icon }}
              tooltipText={t(`navigation.${key}`)}
              redirectUrl={Routes[key]}
              isActive={pathname.includes(activeKey || key)}
              disabled={disabled}
              action={action}
            />
          ))}
      </div>
      <div>
        <Popup
          content={<HelpCenter />}
          position={PopupPositions.RightTop}
          event={PopupEvents.Click}
          offset={16}
          width={230}
        >
          <PanelItem
            name={t('helpCenter')}
            tooltipText={t(`navigation.support`)}
            icon={{ type: ICON_TYPES.INTERCOM }}
          />
        </Popup>
        <Popup
          content={<ProfileMenu />}
          position={PopupPositions.RightTop}
          event={PopupEvents.Click}
          offset={16}
          width={230}
          disable
        >
          <PanelItem
            name={t('navigation.account')}
            tooltipText={t(`navigation.accountTooltip`)}
            icon={{ type: ICON_TYPES.ACCOUNT }}
            disabled
          />
        </Popup>
      </div>
    </div>
  );
});
