/*
 * Component-wrapper for select dropdown.
 */
import { FC } from 'react';
import { MenuProps, components } from 'react-select';
import styles from './SelectFieldDropdown.module.scss';

export const SelectFieldDropdown: FC<MenuProps> = ({ children, ...props }) => (
  <components.Menu {...props}>
    <div className={styles.selectFieldDropdown}>{children}</div>
  </components.Menu>
);
