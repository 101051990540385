/*
 * Constants of button component.
 */

/*
 * Button sizes.
 */
export enum BUTTON_SIZE {
  L = 'L',
  M = 'M',
  S = 'S',
  XS = 'XS',
}

/*
 * Button types (defines look).
 */
export enum BUTTON_TYPE {
  PRIMARY = 'PRIMARY',
  COMMIT = 'COMMIT',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  CRITICAL = 'CRITICAL',
  ACCEPT = 'ACCEPT',
  DECLINED = 'DECLINED',
}
