/*
 * Utils for sorts.
 */
import { CampaignSortConfig, SortOrder } from '@hypetrainCommon';
import { TOptionsToggleItem, TOptionsTogglerValue } from '@uikit/components/OptionsToggler';
import { TAbstractSortConfig, TAvailableSort, TSortItem } from './sorts.types';

/*
 * Возвращает инициализированные сортировки на основе первоначального конфига.
 */
export function getInitSorts(config: TAvailableSort[]): TSortItem[] {
  return config.map((sort) => ({
    id: sort.id || '',
    label: sort.label || '',
    isVisible: sort.selected || false,
    isDisabled: sort.isDisabled,
    order: null,
    extra: sort.extra || null,
    toggleMode: sort.toggleMode,
    switcherLabel: sort.switcherLabel,
    testId: sort.testId,
  }));
}

/*
 * На основе списка сортировок создаёт их объект-конфигурацию для отправки на backend.
 */
export function makeSortRequesData(
  sorts: TSortItem[],
  defaultSortName: string
): TAbstractSortConfig {
  const selectedSorts = sorts.filter((sort) => sort.isVisible).map((sort) => sort.id);

  const activeSort = sorts.find((sort) => sort.order);

  return {
    activeSort: {
      name: activeSort?.id || defaultSortName,
      options: {
        order: activeSort?.order as SortOrder,
      },
      extra: activeSort?.extra || undefined,
    },
    selectedSorts,
  };
}

/*
 * Преобразовывает backend модель в модель которую использует компонент сортировок
 */
export const transformSortFromDTO = (
  currentSorts: TSortItem[],
  newSorts: CampaignSortConfig
): TSortItem[] =>
  currentSorts?.map((sort) => ({
    ...sort,
    isVisible: !!(newSorts?.selectedSorts as string[])?.includes(sort.id),
    order: sort.id === newSorts?.activeSort?.name ? newSorts.activeSort.options.order : null,
  }));

/*
 * На основе списка сортировок создаёт список switcher'ов для отображения в меню их выбора.
 */
export function getSortsSwitchers(sorts: TSortItem[]): TOptionsToggleItem[] {
  return sorts.map((sort) => ({
    id: sort.id,
    label: sort.label,
    switcherLabel: sort.switcherLabel,
    selected: !!sort.isVisible,
  }));
}

/*
 * Возвращает сортировки с актуализированным признаком видимости.
 */
export function getSortsWithUpdatedVisibility(
  sorts: TSortItem[],
  visibleSortsIds: TOptionsTogglerValue
): TSortItem[] {
  return sorts.map((sort) => {
    const isVisible = visibleSortsIds[sort.id];

    return {
      ...sort,
      isVisible,
      order: isVisible ? sort.order : null,
    };
  });
}

/*
 * Возвращает скрытую активную сортировку (если она есть).
 */
export function getHideActiveSort(
  sorts: TSortItem[],
  visibleSortsIds: TOptionsTogglerValue
): TSortItem | undefined {
  return sorts.find((sort) => {
    const isVisible = visibleSortsIds[sort.id];

    return !isVisible && sort.order;
  });
}

/*
 * Возвращает актуализированный список сортировок после измеения какой либо из них.
 */
export function getUpdatedSorts(
  sorts: TSortItem[],
  changedSortId: string,
  order: SortOrder | null
): TSortItem[] {
  return sorts.map((sort) => {
    const isChangedSort = sort.id === changedSortId;

    // Если это изменённая сортировка (новая активная) - меняем её направление (order)
    if (isChangedSort) {
      return {
        ...sort,
        order,
      };
    }

    // Если это предыдущая активная сортировка (не изменённая, но имеющая ранее выставленное направление) - сбрасываем
    // её состояние, так как одновременно может быть только одна активная (с выбранным напрвление) сортировка.
    if (sort.order) {
      return {
        ...sort,
        order: null,
      };
    }

    // Если это сортировка состояние которой не изменилось - ничего не меняем.
    return sort;
  });
}
