/*
 * Switcher group that opens in popup by click on button with plus icon.
 */
import cn from 'clsx';
import { FC, memo, useState } from 'react';
import { Icon, ICON_TYPES } from '@uikit/components/Icon';
import { Popup, PopupEvents, PopupPositions } from '@uikit/components/Popup';
import { OptionsTogglerPopup } from './component/OptionsTogglerPopup';
import styles from './OptionsToggler.module.scss';
import { TOptionsToggleItem, TOptionsTogglerGroup } from './optionsToggler.types';

type TOptionsTogglerProps = {
  options: TOptionsToggleItem[];
  onChange: (options: TOptionsToggleItem[]) => void;
  groups?: TOptionsTogglerGroup[]; // группы по которым фильтры будут визуально разделены
  title: string; // заголовок в списке
  text?: string; // текст в самой кнопке
  className?: string;
};

export const OptionsToggler: FC<TOptionsTogglerProps> = memo(
  ({ options, onChange, groups, title, text, className }) => {
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

    return (
      <Popup
        className={cn(
          styles.optionsToggler,
          !text && styles.optionsToggler_onlyIcon,
          isPopupOpen && styles.optionsToggler_contract,
          className
        )}
        content={
          <OptionsTogglerPopup
            options={options}
            onChange={onChange}
            groups={groups}
            title={title}
          />
        }
        position={PopupPositions.BottomRight}
        event={PopupEvents.Click}
        onClose={() => setIsPopupOpen(false)}
        onOpen={() => setIsPopupOpen(true)}
        offset={20}
      >
        <div className={styles.optionsToggler__content}>
          <Icon
            className={styles.optionsToggler__icon}
            type={ICON_TYPES.PLUS}
          />
          {text && <span className={styles.optionsToggler__text}>{text}</span>}
        </div>
      </Popup>
    );
  }
);
