/*
 * Компонент-контейнер страницы "Pages".
 */
import { useStore } from 'effector-react/compat';
import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { WelcomeToPayments } from '@pagesPaymentsPage/components/WelcomeToPayments';
import {
  $paymentAccounts,
  getPaymentAccountsFx,
  resetPayments,
} from '@pagesPaymentsPage/paymentsAccounts.model';

export const PaymentsContainer: FC = () => {
  const payments = useStore($paymentAccounts);

  useEffect(() => {
    if (!payments?.length) {
      getPaymentAccountsFx();
    }

    return resetPayments;
  }, []);

  // Список счетов ещё не загружен.
  if (!Array.isArray(payments)) return null;

  if (!payments?.length) return <WelcomeToPayments />;

  return <Outlet />;
};
