/*
 * Компонент-interceptor необработанных JS-ошибок.
 */
import { ErrorInfo, PropsWithChildren, PureComponent, ReactNode } from 'react';
import { ErrorHandlingContext } from '@uikit/providers/errorHandling.provider';
import { ErrorStub } from './components/ErrorStub';

type TErrorBoundaryProps = {
  layerName: string;
  errorStub?: ReactNode | null;
  errorStubClassName?: string;
};

type TErrorBoundaryState = {
  hasError: boolean;
};

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/static-property-placement */
export class ErrorBoundary extends PureComponent<
  PropsWithChildren<TErrorBoundaryProps>,
  TErrorBoundaryState
> {
  static override contextType = ErrorHandlingContext;

  declare context: React.ContextType<typeof ErrorHandlingContext>;

  constructor(props: TErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(): TErrorBoundaryState {
    return { hasError: true };
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    if (!this.context?.handler) {
      console.error(`Unexpected JS error (ErrorBoundary layer): handler does not exist`); // eslint-disable-line no-console
      return;
    }
    this.context?.handler(this.props.layerName, error, errorInfo);
  }

  override render(): ReactNode {
    if (this.state.hasError) {
      // Проверяем на undefined, так как может быть передан null.
      return this.props.errorStub !== undefined ? (
        this.props.errorStub
      ) : (
        <ErrorStub className={this.props.errorStubClassName} />
      );
    }

    return this.props.children;
  }
}
