/*
 * Конфигурация сортировок для кампании в TikTok
 */
import { CampaignDealsSortName, TiktokSmaStatisticsSortName } from '@hypetrainCommon';
import { TAvailableSort } from '@uikit/components/Sorts/sorts.types';
import i18n from '@services/i18n';

export const AVAILABLE_TIKTOK_CAMPAIGN_SORTS_CONFIG: TAvailableSort[] = [
  {
    id: TiktokSmaStatisticsSortName.FollowersCount,
    label: i18n.t('sorts.tikTok.followers'),
    testId: 'TT_FOLLOWERS_COUNT_SORT',
  },
  {
    id: TiktokSmaStatisticsSortName.EngagementRate,
    label: i18n.t('sorts.tikTok.engagementRate'),
    extra: { day: '30' },
    testId: 'TT_ER_SORT',
  },
  {
    id: TiktokSmaStatisticsSortName.Views,
    label: i18n.t('sorts.tikTok.views'),
    extra: { day: '30' },
    testId: 'TT_VIEWS_SORT',
  },
  {
    id: CampaignDealsSortName.NewMessages,
    label: i18n.t('sorts.tikTok.newMessagesLabel'),
    toggleMode: true,
    switcherLabel: i18n.t('sorts.tikTok.newMessages'),
    testId: 'TT_NEW_MESSAGES_SORT',
  },
];
