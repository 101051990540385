/*
 * Утилиты компонента группы переключателей.
 */
import { TOptionsToggleItem, TOptionsTogglerValue } from './optionsToggler.types';

/*
 * Возвращает на основе списка switcher'ов мапу {id: признак выбранности}.
 */
export function getOptionsTogglerValue(switchers: TOptionsToggleItem[]): TOptionsTogglerValue {
  const optionsVisibility: Record<string, boolean> = {};

  switchers.forEach((switcher) => {
    optionsVisibility[switcher.id] = switcher.selected;
  });

  return optionsVisibility;
}
