// Хук для инициализации Feature toggling
import { useStore } from 'effector-react/compat';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { IPersonWorkspaceResponseDto } from '@hypetrainCommon';
import { componentsErrorHandler } from '@helpers/errorHandling.helpers';
import { $activeWorkspaceStore } from '@models/activeWorkspace';
import { $userProfile } from '@models/profile';

const LDErrorToIgnore = 'LaunchDarklyFlagFetchError';

type TUseIdentifyFeatureToggling = (isAuthChecked: boolean) => boolean;

export const useIdentifyFeatureToggling: TUseIdentifyFeatureToggling = (isAuthChecked: boolean) => {
  const [isFeatureTogglingInited, setIsFeatureTogglingInited] = useState(false);
  const profile = useStore($userProfile);
  const activeWorkspace = useStore<IPersonWorkspaceResponseDto>($activeWorkspaceStore);
  const ldClient = useLDClient();

  // Инициализируем Feature toggling
  useEffect(() => {
    if (!profile?.email && !activeWorkspace?.workspace?.id && isAuthChecked) {
      setIsFeatureTogglingInited(true);
    }

    ldClient?.identify(
      {
        key: profile?.uuid || 'anonymous',
        ...(!profile?.uuid && { anonymous: true }),
        custom: {
          userId: profile?.uuid || '',
          userEmail: profile?.email || '',
          workspaceId: activeWorkspace?.workspace?.id,
          workspaceHandler: activeWorkspace?.workspace?.handler,
          personId: activeWorkspace?.person?.id,
        },
      },
      '',
      (err) => {
        // Игнорируем ошибку на fetch флагов, иногда выпадает
        // из-за интернета соединений пользователей и ошибка летит в datadog
        if (err && !err?.stack?.includes(LDErrorToIgnore)) {
          componentsErrorHandler('initFeatureToggling', err);
        }
        setIsFeatureTogglingInited(true);
      }
    );
  }, [profile, activeWorkspace, isAuthChecked]);

  return isFeatureTogglingInited;
};
