import cn from 'clsx';
import { useStore } from 'effector-react/compat';
import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as List, ListOnScrollProps } from 'react-window';
import { SmaPlatform } from '@hypetrainCommon';
import { BUTTON_TYPE, Button } from '@uikit/components/Buttons';
import { TFilterConfig } from '@uikit/components/Filter';
import { hasActiveFilters } from '@uikit/components/Filters/filters.utils';
import { VirtualizedTable } from '@uikit/components/Table';
import { prepareInfluencersData } from '@services/tableSmaFormatter';
import { useLastUnreadMessage } from '@hooks/useLastUnreadMessage.hook';
import { CAMPAIGN_DETAILS_TABLE_NAME } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.constants';
import {
  $campaignSelectionStore,
  $campaignStore,
  $scrollTableInfo,
  $searchTerm,
  setCommunicationTableInfo,
} from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.model';
import {
  $campaignFilters,
  $isFilterUsedStore,
} from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPageConfig.model';
import { getCommunicationColumns } from '@pagesCampaigns/CampaignDetailsPage/components/Communication/Communication.column';
import { HideUnreadMessage } from '@pagesCampaigns/CampaignDetailsPage/components/Communication/components/HideUnreadMessage';
import { useLiveUpdateCommunication } from '@pagesCampaigns/CampaignDetailsPage/components/Communication/useLiveUpdateCommunication.hook';
import styles from './Communication.module.scss';

export const Communication: FC = () => {
  const selectedCampaignDeals = useStore($campaignSelectionStore);
  const campaign = useStore($campaignStore);
  const { t } = useTranslation();
  const listRef = useRef<List>(null);
  const scrollTableInfo = useStore($scrollTableInfo);
  const searchTerm = useStore($searchTerm);
  const loadedFilters = useStore<TFilterConfig[]>($campaignFilters);
  const isFilterUsed = useStore($isFilterUsedStore);

  const [scrollElementsPosition, setScrollElementsPosition] = useState<number[]>([]);

  useLiveUpdateCommunication();

  const setTablePosition = (val: number): void => {
    setCommunicationTableInfo({ ...scrollTableInfo, scrollTablePosition: val });
  };

  const [lastUnreadMessage, setLastUnreadMessage, scrollNewMessage] = useLastUnreadMessage(
    listRef,
    scrollTableInfo.scrollTablePosition,
    scrollElementsPosition,
    setTablePosition
  );

  const onScroll = (props: ListOnScrollProps): void => {
    if (listRef?.current && !props.scrollUpdateWasRequested) {
      setLastUnreadMessage(
        props.scrollOffset,
        +listRef.current.props.height,
        scrollElementsPosition
      );
      if (setTablePosition) {
        setTablePosition(props.scrollOffset);
      }
    }
  };

  const campaignDeals = useMemo(() => {
    const allCampaignDeals = campaign?.campaignDeals && Object.values(campaign.campaignDeals);
    allCampaignDeals &&
      campaign?.campaignDetails?.platform === SmaPlatform.Youtube &&
      prepareInfluencersData(allCampaignDeals, []);
    const allCampaignDealsComplete =
      allCampaignDeals?.map((campaignDeal) => {
        if (Number(campaignDeal?.unreadCount)) {
          return { ...campaignDeal, rowClassNameItem: styles.unreadMessage };
        }
        return { ...campaignDeal };
      }) || [];

    const filterUnreadIndexes =
      allCampaignDealsComplete?.reduce((filterIndexes, campaignDeal, i) => {
        if (Number(campaignDeal?.unreadCount)) {
          return [...filterIndexes, i];
        }
        return [...filterIndexes];
      }, []) || [];

    setScrollElementsPosition(filterUnreadIndexes);
    return allCampaignDealsComplete;
  }, [campaign?.campaignDeals]);

  return (
    <div className={styles.communication}>
      <HideUnreadMessage count={Number(campaign?.unreadMessages?.hidden)} />
      <div className={styles.communication__tableBlock}>
        <VirtualizedTable
          columns={getCommunicationColumns(campaign?.campaignDetails?.platform as SmaPlatform)}
          data={campaignDeals}
          tableName={CAMPAIGN_DETAILS_TABLE_NAME}
          tablePosition={scrollTableInfo.scrollTablePosition}
          onScroll={onScroll}
          className={styles.communication__table}
          wasFiltered={hasActiveFilters(loadedFilters) || !!searchTerm || isFilterUsed}
          rowClassName={cn(
            styles.campaignTableRow,
            selectedCampaignDeals.length && styles.showCheckboxes
          )}
          listRef={listRef}
        />
        {!!lastUnreadMessage && (
          <div className={styles.newMessage}>
            <Button
              buttonType={BUTTON_TYPE.TERTIARY}
              onClick={scrollNewMessage}
            >
              {t('virtualizeTable.newMessage')}
            </Button>
            <div className={styles.newMessage__rectangle} />
          </div>
        )}
      </div>
    </div>
  );
};
