/*
 * Global panel service.
 */
import { PanelStore, TPanelConfig, panelStore } from './panel.store';
import { TOpenPanelConfig, TPanelAPI } from './panel.types';

export class PanelService {
  constructor(private readonly store: PanelStore) {}

  /*
   * Open panel.
   */
  public open<D = never, P = Record<never, never>, R = never>(
    config: TOpenPanelConfig<P, D, R>
  ): Promise<D> {
    const id = config.component.name;
    const panelIsAlreadyShow = this.store.has(id);

    // Если такая панель уже отображается - не дублируем её,
    // возвращаем пустой промис, который никогда не зарезолвится, что бы не дублировать обработку собюытий панели.
    if (panelIsAlreadyShow) {
      return new Promise(() => {});
    }

    const panelResult = new Promise<D>((resolve: (data: D) => void, reject: (data: R) => void) => {
      const props = {
        ...(config.props || ({} as P)),
        panel: this.makePanelAPI<D, R>(id, resolve, reject),
      };

      const panelConfig: TPanelConfig<P, D, R> = {
        ...config,
        props,
        id,
      };

      this.store.add<P, D, R>(panelConfig);
    });

    return panelResult;
  }

  /*
   * Making panel API for forwarding in panel component.
   */
  private makePanelAPI<D, R>(
    panelId: string,
    resolvePanel: (data?: D) => void,
    rejectPanel: (data?: R) => void
  ): TPanelAPI<D, R> {
    const closePanel = (): void => this.store.delete(panelId);
    const resolve = (resolveData: D): void => {
      closePanel();
      resolvePanel(resolveData);
    };
    const reject = (rejectData: R): void => {
      closePanel();
      rejectPanel(rejectData);
    };

    return {
      resolve,
      reject,
    };
  }
}

const createPanelService = (store: PanelStore) => new PanelService(store);

export const panelService = createPanelService(panelStore);
