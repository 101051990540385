/*
 * Сервис для работы с API платежей.
 */
import { ApiService, apiService } from '@api';
import {
  ICalculateTotalAndFeeRequestDto,
  ICalculateTotalAndFeeResponseDto,
  IPaginatedDto,
  IPaymentAccountDto,
  IPaymentListItemDto,
  IPaymentsFilteringRequestDto,
  ITransactionDto,
} from '@hypetrainCommon';

/* eslint-disable arrow-body-style */
export class PaymentsApiService {
  constructor(private readonly api: ApiService) {}

  /*
   * Получение списка платежных счетов пользователя.
   */
  public getPaymentAccounts = (): Promise<IPaymentAccountDto[]> => {
    return this.api.get(`/payments/accounts`).then((response) => response.data);
  };

  /*
   * Активация модуля платежей в приложении.
   */
  public activatePaymentsModule = (): Promise<IPaymentAccountDto[]> => {
    return this.api.post(`/payments/activate`).then((response) => response.data);
  };

  /*
   * Получение списка транзакций по платежному счету.
   */
  public getPaymentAccountTransactions = (accountID: string): Promise<ITransactionDto[]> => {
    return this.api.get(`/payments/${accountID}/transactions`).then((response) => response.data);
  };

  /*
   * Получение списка платежей.
   */
  public getPayments = (
    params: IPaymentsFilteringRequestDto
  ): Promise<IPaginatedDto<IPaymentListItemDto>> => {
    return this.api.post(`/payments`, params).then((response) => response.data);
  };

  /*
   * Рассчитывает суммы и комиссии по выбранным платежам.
   */
  public calculatePaymentsFx = (
    params: ICalculateTotalAndFeeRequestDto
  ): Promise<ICalculateTotalAndFeeResponseDto> => {
    return this.api
      .post(`/payments/calculate`, params, {}, { showLoader: false })
      .then((response) => response.data);
  };

  /*
   * Выполненяет платежи.
   */
  public executePayments = (params: ICalculateTotalAndFeeRequestDto): Promise<void> => {
    return this.api
      .post(`/payments/execute`, params, {}, { showLoader: false })
      .then((response) => response.data);
  };
}

export const paymentsApiService = new PaymentsApiService(apiService);
