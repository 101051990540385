/*
 * Helpers для обработки ошибок в приложении.
 */
import { datadogLogs } from '@datadog/browser-logs';
import { ErrorInfo } from 'react';
import { LOG_SEVERITY, LOG_TOPIC } from '@enums/log.enum';

/*
 * Обработчик JS-ошибок в компонентах приложения.
 */
export function componentsErrorHandler(
  errorLayerName: string,
  error: Error,
  errorInfo?: ErrorInfo
) {
  console.error(`Unexpected JS error (${errorLayerName} layer): `, error, errorInfo); // eslint-disable-line no-console
  datadogLogs.logger.error(`Unexpected JS error (${errorLayerName} layer)`, {
    topic: LOG_TOPIC.JS_ERROR,
    severity: LOG_SEVERITY.JS_ERROR,
    error,
    errorInfo,
  });
}
