// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cellWithTooltip__NeFmo{text-decoration:dotted;text-decoration-line:underline;cursor:default}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Table/components/columns/CellWithTooltip/CellWithTooltip.module.scss"],"names":[],"mappings":"AAEA,wBACE,sBAAA,CACA,8BAAA,CACA,cAAA","sourcesContent":["@import '../../../../../assets/styles/ui_kit_mixins';\n\n.cellWithTooltip {\n  text-decoration: dotted;\n  text-decoration-line: underline;\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cellWithTooltip": "cellWithTooltip__NeFmo"
};
export default ___CSS_LOADER_EXPORT___;
