import { Routes } from '@router/Routes';
import { USERS_ACTIONS, usersActionsLogService } from '@services/usersActionsLog';
import {
  USER_AUTHENTICATION_STRATEGY,
  USER_REGISTRATION_STRATEGY,
} from '@enums/logUsersActions.enum';

export const logUserAccountCreated = (
  userRegistrationStrategy: USER_REGISTRATION_STRATEGY,
  userAuthenticationStrategy: USER_AUTHENTICATION_STRATEGY
) => {
  usersActionsLogService.log(
    USERS_ACTIONS.USER_ACCOUNT_CREATED,
    {},
    { userRegistrationStrategy, userAuthenticationStrategy }
  );
};

export const logUserAccountDuplicate = (duplicateFingerprint: boolean, fingerPrint: string) => {
  if (duplicateFingerprint) {
    usersActionsLogService.log(USERS_ACTIONS.USER_REPEATED_REGISTRATION_DETECTED, {
      repeatedVisitorId: fingerPrint,
    });
  }
};

export const logUserEmailVerification = (isVerificationManual: boolean) => {
  usersActionsLogService.log(USERS_ACTIONS.USER_EMAIL_VERIFIED, {
    isVerificationManual,
  });
};

export const logUserAccountCreationAndEmailVerificationWithGoogle = (handler?: string) => {
  if (!handler) return;
  usersActionsLogService.log(USERS_ACTIONS.USER_EMAIL_VERIFIED, {
    isVerificationManual: false,
  });
  const withInvite = window.location.pathname.includes(Routes.joinWorkspace.split(':')[0]);
  usersActionsLogService.log(
    USERS_ACTIONS.USER_ACCOUNT_CREATED,
    {},
    {
      userRegistrationStrategy: withInvite
        ? USER_REGISTRATION_STRATEGY.BY_INVITATION
        : USER_REGISTRATION_STRATEGY.BY_OWN_INTENTION,
      userAuthenticationStrategy: USER_AUTHENTICATION_STRATEGY.GOOGLE,
    }
  );
};
