/*
 * Подготовка отображаемого значения фильтра для фильтров которые могут содержать проценты
 */
import { TFilter } from '@uikit/components/Filters/filters.types';

export const prepareViewValueWithPercents = (value: string, percentValue: number) =>
  `${value} >${(percentValue * 100).toFixed(0)}%`;

export const transformFilterModelToDto = (filterValue: TFilter, hasPercentage = false): TFilter =>
  hasPercentage
    ? {
        ...filterValue,
        min: Number(filterValue.min) / 100,
      }
    : filterValue;

export const transformDtoToFilterModel = (filterValue: TFilter, hasPercentage = false): TFilter =>
  hasPercentage
    ? {
        ...filterValue,
        min: +(Number(filterValue.min) * 100).toFixed(0),
      }
    : filterValue;
