// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sorts__BEnXH{display:flex}.sorts__container__Fa_84{display:flex;flex-wrap:wrap}.sorts__container__Fa_84>*{margin-right:var(--vSpacing12)}.sorts__icon__fSV7z{display:flex;flex-shrink:0;margin:6px var(--vSpacing12) 0 0}.sorts__options__yzydD{margin-bottom:var(--hSpacing16)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Sorts/Sorts.module.scss"],"names":[],"mappings":"AAEA,cACE,YAAA,CAEA,yBACE,YAAA,CACA,cAAA,CAEA,2BACE,8BAAA,CAIJ,oBACE,YAAA,CACA,aAAA,CACA,gCAAA,CAGF,uBACE,+BAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.sorts {\n  display: flex;\n\n  &__container {\n    display: flex;\n    flex-wrap: wrap;\n\n    & > * {\n      margin-right: var(--vSpacing12);\n    }\n  }\n\n  &__icon {\n    display: flex;\n    flex-shrink: 0;\n    margin: 6px var(--vSpacing12) 0 0;\n  }\n\n  &__options {\n    margin-bottom: var(--hSpacing16);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sorts": "sorts__BEnXH",
	"sorts__container": "sorts__container__Fa_84",
	"sorts__icon": "sorts__icon__fSV7z",
	"sorts__options": "sorts__options__yzydD"
};
export default ___CSS_LOADER_EXPORT___;
