/*
 * Базовый API-сервис для exploration.
 */
import { loaderService } from '@uikit/components/Loader';
import { notificationsService } from '@uikit/components/Notification';
import { ApiService } from '@api/api.service';

/* eslint-disable class-methods-use-this */
/* eslint-disable arrow-body-style */
export class ExplorationBaseApiService extends ApiService {
  override get baseUrl(): string {
    return process.env.EXPLORATION_BASE_URL;
  }
}

export const explorationBaseApiService: ExplorationBaseApiService = new ExplorationBaseApiService(
  notificationsService,
  loaderService
);
