// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectPaymentCheckbox__b3nCa{margin:unset}", "",{"version":3,"sources":["webpack://./pages/PaymentsPage/components/SelectPaymentCheckbox/SelectPaymentCheckbox.module.scss"],"names":[],"mappings":"AAEA,8BACE,YAAA","sourcesContent":["@import '../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.selectPaymentCheckbox {\n  margin: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectPaymentCheckbox": "selectPaymentCheckbox__b3nCa"
};
export default ___CSS_LOADER_EXPORT___;
