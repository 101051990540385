import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import {
  FORMAT_NUMBER_NOTATION,
  FORMAT_NUMBER_STYLES,
} from '@uikit/components/Formats/Formats.constants';
import styles from '@uikit/components/Formats/Formats.module.scss';
import { EMPTY_PLACEHOLDER } from '@uikit/constants/common.constants';
import { isNumeric } from '@uikit/utils/number.utils';

type TFormatNumber = {
  className?: string;
  precision?: number;
  notation?: FORMAT_NUMBER_NOTATION;
  numberStyles?: FORMAT_NUMBER_STYLES;
  id?: string;
};

/*
 * Формат чисел.
 * @see https://bloggerslab.fibery.io/Design/Capitalization-and-formats-1096
 */
export const getNumberFormatter =
  (notation: FORMAT_NUMBER_NOTATION, maximumFractionDigits: number, style: FORMAT_NUMBER_STYLES) =>
  (number: number) =>
    new Intl.NumberFormat('en', {
      notation,
      maximumFractionDigits,
      style,
    })
      .format(number)
      .toLowerCase();

export const FormatNumber: FC<PropsWithChildren<TFormatNumber>> = ({
  children,
  className,
  precision,
  notation = FORMAT_NUMBER_NOTATION.STANDARD,
  numberStyles = FORMAT_NUMBER_STYLES.DECIMAL,
  id,
}) => {
  const defaultPrecision = numberStyles === FORMAT_NUMBER_STYLES.PERCENT ? 2 : 1;
  const formatter = getNumberFormatter(notation, precision || defaultPrecision, numberStyles);

  if (!children || !isNumeric(children.toString())) return <div>{EMPTY_PLACEHOLDER}</div>;

  return (
    <div
      id={id}
      className={cn(styles.formatCase, className)}
    >
      {formatter(Number(children))}
    </div>
  );
};
