// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectCampaignAllCheckbox__fFnBF{width:100%}", "",{"version":3,"sources":["webpack://./pages/Campaigns/CampaignDetailsPage/components/SelectCampaignAllCheckbox/SelectCampaignAllCheckbox.module.scss"],"names":[],"mappings":"AAEA,kCACE,UAAA","sourcesContent":["@import '../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.selectCampaignAllCheckbox {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectCampaignAllCheckbox": "selectCampaignAllCheckbox__fFnBF"
};
export default ___CSS_LOADER_EXPORT___;
