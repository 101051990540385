import { SmaAudienceCountryValue, SmaPlatform } from '@hypetrainCommon';
import {
  FORMAT_NUMBER_NOTATION,
  FORMAT_NUMBER_STYLES,
  FormatNumber,
} from '@uikit/components/Formats';
import { CELL_POSITION, DefaultCell, TableHeaderCell } from '@uikit/components/Table';
import { TColumn } from '@uikit/components/Table/table.types';
import { getCountryName } from '@constants/countries';
import { audienceValuesFieldName } from '@components/TableCells/IqdataCells/iqdataCells.constants';
import { useDynamicColumns } from './useDynamicColumns.hook';

export const getAudienceLocationColumn = (countries: string[], platform: SmaPlatform): TColumn[] =>
  countries.map((country) => ({
    width: 50,
    Header: (
      <DefaultCell position={CELL_POSITION.CENTER}>
        <TableHeaderCell text={getCountryName(country)} />
      </DefaultCell>
    ),
    accessor: country,
    Cell: ({ row }) => {
      const countryValue = (
        useDynamicColumns({
          campaignValue:
            row?.original?.smaStatistics?.audience?.[audienceValuesFieldName[platform] as string]
              ?.countries?.values,
          browseValue:
            row?.original?.audience?.[audienceValuesFieldName[platform] as string]?.countries
              ?.values,
        }) as SmaAudienceCountryValue[]
      )?.find((el) => el.key === country);

      return (
        <DefaultCell
          isEmpty={!countryValue?.value}
          position={CELL_POSITION.CENTER}
        >
          <FormatNumber
            notation={FORMAT_NUMBER_NOTATION.STANDARD}
            numberStyles={FORMAT_NUMBER_STYLES.PERCENT}
          >
            {countryValue?.value}
          </FormatNumber>
        </DefaultCell>
      );
    },
  }));
