/*
 * Компонент скролла.
 * Обязателен для использования!
 */
import cn from 'clsx';
import { PropsWithChildren, forwardRef } from 'react';
import styles from './Scroll.module.scss';

type TScrollProps = {
  className?: string;
};

export const Scroll = forwardRef<HTMLDivElement, PropsWithChildren<TScrollProps>>(
  ({ className, children }, ref) => (
    <div
      ref={ref}
      className={cn(styles.scroll, className)}
    >
      {children}
    </div>
  )
);
