/*
 * Модели страницы Browse.
 * Конфигурации включают в себя фильтры и сортировки.
 */
import { createEffect, createEvent, createStore, forward } from 'effector/compat';
import {
  IInstagramFilterDto,
  IInstagramFilteringRequestDto,
  IInstagramSmaStatisticsResponseDto,
  ILastFilteringRequestsResponseDto,
  ITiktokFilterDto,
  ITiktokFilteringRequestDto,
  IYoutubeFilterDto,
  IYoutubeFilteringRequestDto,
  IYoutubeSmaStatisticsResponseDto,
  IYoutubeSmaStatisticsSortDto,
  SmaPlatform,
  YoutubeBrandsFilter,
  YoutubeFilterName,
  YoutubeGamesFilter,
} from '@hypetrainCommon';
import { TFilterConfig } from '@uikit/components/Filter';
import { setSelectedOptions } from '@uikit/components/Filter/components/AsyncSelectFilter/asyncSelectFilter.model';
import { getInitFilters } from '@uikit/components/Filters';
import {
  hasActiveFilters,
  transformBrowseFiltersFromDTO,
} from '@uikit/components/Filters/filters.utils';
import { TSelectOptions } from '@uikit/components/SelectField/SelectField.types';
import { TSortItem, getInitSorts } from '@uikit/components/Sorts';
import { TAvailableSort } from '@uikit/components/Sorts/sorts.types';
import { explorationApiService, explorationApiServiceNew } from '@api/exploration';
import { prepareInfluencersData } from '@services/tableSmaFormatter';
import { $activeSocialPlatform, resetActivePlatform } from '@models/activeSocialPlatform';
import { BROWSE_PAGE_AVAILABLE_FILTERS_CONFIG_INSTAGRAM } from '@pages/Discovery/Browse/BrowseFilters/browseFiltersInstagram.config';
import { BROWSE_PAGE_AVAILABLE_FILTERS_CONFIG } from '@pages/Discovery/Browse/BrowseFilters/browseFiltersYoutube.config';
import { BROWSE_PAGE_AVAILABLE_SORTS_CONFIG } from '@pages/Discovery/Browse/BrowseSorts/browseSorts.config';
import { BROWSE_PAGE_AVAILABLE_SORTS_CONFIG_INSTAGRAM } from '@pages/Discovery/Browse/BrowseSorts/browseSortsInstagram.config';
import { BROWSE_LOADING_LIMIT } from '@pages/Discovery/Browse/browse.constants';
import {
  getBrowseFiltersDTO,
  getBrowseSortsDTO,
  prepareBrowseSorts,
  transformBrowseSortFromDTO,
  updateDiscoveryNoteCounts,
} from '@pages/Discovery/Browse/browse.utils';
import { BROWSE_PAGE_AVAILABLE_FILTERS_CONFIG_TIKTOK } from '@pagesDiscovery/Browse/BrowseFilters/browseFiltersTikTok.config';
import { BROWSE_PAGE_AVAILABLE_SORTS_CONFIG_TIKTOK } from '@pagesDiscovery/Browse/BrowseSorts/browseSortsTikTok.config';
import { profileNoteChanged } from '@components/Profile/profile.model';

const BROWSE_SORTS_CONFIG: Record<SmaPlatform, TAvailableSort[]> = {
  [SmaPlatform.Youtube]: BROWSE_PAGE_AVAILABLE_SORTS_CONFIG,
  [SmaPlatform.Instagram]: BROWSE_PAGE_AVAILABLE_SORTS_CONFIG_INSTAGRAM,
  [SmaPlatform.Tiktok]: BROWSE_PAGE_AVAILABLE_SORTS_CONFIG_TIKTOK,
  [SmaPlatform.Twitter]: [],
  [SmaPlatform.Twitch]: [],
  [SmaPlatform.Facebook]: [],
};

const BROWSE_FILTER_CHANGED_CONFIG = {
  [SmaPlatform.Youtube]: false,
  [SmaPlatform.Instagram]: false,
  [SmaPlatform.Tiktok]: false,
  [SmaPlatform.Twitch]: false,
  [SmaPlatform.Facebook]: false,
  [SmaPlatform.Twitter]: false,
};

const INITIAL_BROWSE_INFLUENCERS: {
  smasStatistics: IYoutubeSmaStatisticsResponseDto[] | IInstagramSmaStatisticsResponseDto[];
  total: number;
  isFetched: boolean;
} = {
  smasStatistics: [],
  total: 0,
  isFetched: false,
};

type TFilterChangedStore = Record<SmaPlatform, boolean>;

/*
 * Устанавливает true если были изменены фильтра и false при запросе на бэк.
 */
export const setIsBrowseFiltersChanged = createEvent<boolean>();

const INITIAL_BROWSE_SORTS: Partial<Record<SmaPlatform, TSortItem[]>> = {
  [SmaPlatform.Youtube]: getInitSorts(BROWSE_PAGE_AVAILABLE_SORTS_CONFIG),
  [SmaPlatform.Instagram]: getInitSorts(BROWSE_PAGE_AVAILABLE_SORTS_CONFIG_INSTAGRAM),
  [SmaPlatform.Tiktok]: getInitSorts(BROWSE_PAGE_AVAILABLE_SORTS_CONFIG_TIKTOK),
};

const INITIAL_BROWSE_FILTERS: Partial<Record<SmaPlatform, TFilterConfig[]>> = {
  [SmaPlatform.Youtube]: getInitFilters(BROWSE_PAGE_AVAILABLE_FILTERS_CONFIG),
  [SmaPlatform.Instagram]: getInitFilters(BROWSE_PAGE_AVAILABLE_FILTERS_CONFIG_INSTAGRAM),
  [SmaPlatform.Tiktok]: getInitFilters(BROWSE_PAGE_AVAILABLE_FILTERS_CONFIG_TIKTOK),
};

/*
 * Устанавливает сортировки для списка инфлюенсеров.
 */
export const setBrowseSort = createEvent<TSortItem[]>();
export const setAllBrowseSort = createEvent<Partial<Record<SmaPlatform, TSortItem[]>>>();

/*
 * Устанавливает фильтры для списка инфлюенсеров.
 */
export const setBrowseFilters = createEvent<TFilterConfig[]>();
export const setAllBrowseFilters = createEvent<Partial<Record<SmaPlatform, TFilterConfig[]>>>();

/*
 * Устанавливает offset для загрузки списка инфлюенсеров.
 */
export const increaseBrowseOffset = createEvent<void>();
const audienceFilters = ['audienceGenders', 'audienceAges', 'audienceCountries'];

/*
 * Обнуляет browse offset, influencers. Платформа остаётся
 */

export const resetBrowseInfo = createEvent<SmaPlatform>();

/*
 * Специально очищаем фильтра определённой соц сети в нужный момент.
 */

export const clearBrowseFilters = createEvent<SmaPlatform>();
/*
 * Текущие сортировки.
 *
 * !!!ВНИМАНИЕ для корректной работы установки дефолтной сортировки $browseSorts должен быть выше $browseFilters
 */
export const $browseSorts = createStore<Partial<Record<SmaPlatform, TSortItem[]>>>(
  INITIAL_BROWSE_SORTS
)
  .on(setBrowseSort, (state, newSorts) => {
    const activePlatform = $activeSocialPlatform.getState()?.platform || SmaPlatform.Youtube;
    return {
      ...state,
      [activePlatform]: newSorts,
    };
  })
  .on(setBrowseFilters, (state, filters) => {
    const activePlatform = $activeSocialPlatform.getState()?.platform || SmaPlatform.Youtube;
    const hasActiveAudienceFilters = filters.some(
      (filter) =>
        filter.filterValue.values?.length && audienceFilters.includes(filter.filterValue.name)
    );
    // если фильтры очищаются, то очищаем и сортировки
    if (!hasActiveFilters(filters)) {
      return {
        ...state,
        [activePlatform]: getInitSorts(BROWSE_SORTS_CONFIG[activePlatform]),
      };
    }

    /*
     * Сортировка по views при выставлении фильтров устанавливается если она не была установлена до этого,
     * и если в предыдущем состоянии фильтры не были выставлены.
     * effector не предоставляет возможности получать предыдущее состояние стора, по этому здесь используется хак.
     * Дело в том что обработчики выполняются в порядке их регистрации,
     * соответственно обработчик setBrowseFilters в $browseFilters выполнится позже чем в $browseSorts,
     * таким образом на текущий момент $browseFilters еще не обновился и в нем содержится его предыдущее значение.
     *
     * !!!ВНИМАНИЕ если поменять местами $browseFilters и $browseSorts то хак перестанет работать
     */

    return {
      ...state,
      [activePlatform]: prepareBrowseSorts(
        state[activePlatform] as TSortItem[],
        hasActiveFilters($browseFilters.getState()[activePlatform] as TFilterConfig[]), // eslint-disable-line @typescript-eslint/no-use-before-define
        hasActiveAudienceFilters
      ),
    };
  })
  .on(setAllBrowseSort, (_, newSorts) => newSorts);

/*
 * Текущие фильтры.
 *
 * !!!ВНИМАНИЕ для корректной работы установки дефолтной сортировки $browseSorts должен быть выше $browseFilters
 */
export const $browseFilters = createStore<Partial<Record<SmaPlatform, TFilterConfig[]>>>(
  INITIAL_BROWSE_FILTERS
)
  .on(setBrowseFilters, (state, newFilters) => {
    const activePlatform = $activeSocialPlatform.getState()?.platform || SmaPlatform.Youtube;
    return {
      ...state,
      [activePlatform]: newFilters,
    };
  })
  .on(clearBrowseFilters, (state, platform) => ({
    ...state,
    [platform]: INITIAL_BROWSE_FILTERS[platform],
  }))
  .on(setAllBrowseFilters, (_, newFilters) => newFilters)
  .reset(resetActivePlatform);

/*
 * Стор отвечающий за текущий статус изменённости фильтров.
 *  Если изменялись, то кнопка apply filters становится доступной.
 */
export const $browseFiltersChanged = createStore<TFilterChangedStore>(BROWSE_FILTER_CHANGED_CONFIG)
  .on(setIsBrowseFiltersChanged, (state, status) => {
    const activePlatform = $activeSocialPlatform.getState()?.platform || SmaPlatform.Youtube;
    return {
      ...state,
      [activePlatform]: status,
    };
  })
  .reset(resetActivePlatform);

/*
 * Текущий offset списка инфлюенсеров.
 */
export const $browseOffset = createStore<number>(0)
  .on(increaseBrowseOffset, (offset) => offset + BROWSE_LOADING_LIMIT)
  .on(setBrowseSort, () => 0)
  .on(setBrowseFilters, () => 0)
  .reset(resetBrowseInfo)
  .reset(resetActivePlatform);

/*
 * Фильтрация и сортировка инфлюенсеров.
 * Используется так же для простого запроса инфлюенсеров.
 */
export const loadInfluencersAPIFx = createEffect(
  (
    params: ITiktokFilteringRequestDto | IYoutubeFilteringRequestDto | IInstagramFilteringRequestDto
  ) => {
    if ($activeSocialPlatform.getState()?.isTikTok) {
      return explorationApiServiceNew.filterInfluencers(params as ITiktokFilteringRequestDto);
    }

    return explorationApiService.filterAndSortInfluencers(
      params as IYoutubeFilteringRequestDto | IInstagramFilteringRequestDto
    );
  }
);

/*
 * Сетаем стор ассинхронных фильтров
 */
const setAsyncFiltersStore = (
  filter: YoutubeFilterName,
  savedFilters: ILastFilteringRequestsResponseDto
) => {
  // Достаем фильтр(используется для игр/брендов)
  const isFilter = (savedFilters[SmaPlatform.Youtube]?.filters as IYoutubeFilterDto[]).find(
    (el) => el.name === filter
  ) as YoutubeBrandsFilter | YoutubeGamesFilter;

  if (isFilter?.meta) {
    // Сетаем игры/брендов в стор, для отображения их
    setSelectedOptions({
      type: filter,
      options: isFilter.meta[filter] as TSelectOptions,
    });
  }
};

/*
 * Получение последних примененных сортов
 */
const setLastSorts = (savedSorts: ILastFilteringRequestsResponseDto) => {
  const currentSorts = $browseSorts.getState();
  setAllBrowseSort({
    [SmaPlatform.Youtube]: savedSorts[SmaPlatform.Youtube]?.sorts
      ? transformBrowseSortFromDTO(
          currentSorts[SmaPlatform.Youtube] as TSortItem[],
          // @ts-ignore TODO Женя, разобраться с типом, мб нужен typeguard, !быстро не получится сделать!
          savedSorts[SmaPlatform.Youtube]?.sorts[0]
        )
      : currentSorts[SmaPlatform.Youtube],
    [SmaPlatform.Instagram]: savedSorts[SmaPlatform.Instagram]?.sorts
      ? transformBrowseSortFromDTO(
          currentSorts[SmaPlatform.Instagram] as TSortItem[],
          // @ts-ignore TODO Женя, разобраться с типом, мб нужен typeguard, !быстро не получится сделать!
          savedSorts[SmaPlatform.Instagram]?.sorts[0]
        )
      : currentSorts[SmaPlatform.Instagram],
    [SmaPlatform.Tiktok]: savedSorts[SmaPlatform.Tiktok]?.sorts
      ? transformBrowseSortFromDTO(
          currentSorts[SmaPlatform.Tiktok] as TSortItem[],
          // @ts-ignore TODO Женя, разобраться с типом, мб нужен typeguard, !быстро не получится сделать!
          savedSorts[SmaPlatform.Tiktok]?.sorts[0]
        )
      : currentSorts[SmaPlatform.Tiktok],
  });
};

/*
 * Получение последних примененных фильтров
 */
export const setLastFiltersFx = createEffect(async () => {
  const currentFilters = $browseFilters.getState();
  const response = await explorationApiService.getFilters();
  setAsyncFiltersStore(YoutubeFilterName.Brands, response);
  setAsyncFiltersStore(YoutubeFilterName.Games, response);
  setLastSorts(response);

  // Сетаем фильтры, если они пришли с бэка, то прогоняем через transformBrowseFiltersFromDTO
  setAllBrowseFilters({
    [SmaPlatform.Youtube]: response[SmaPlatform.Youtube]
      ? transformBrowseFiltersFromDTO(
          currentFilters[SmaPlatform.Youtube] as TFilterConfig[],
          response[SmaPlatform.Youtube]?.filters as IYoutubeFilterDto[]
        )
      : currentFilters[SmaPlatform.Youtube],
    [SmaPlatform.Instagram]: response[SmaPlatform.Instagram]
      ? transformBrowseFiltersFromDTO(
          currentFilters[SmaPlatform.Instagram] as TFilterConfig[],
          response[SmaPlatform.Instagram]?.filters as IInstagramFilterDto[]
        )
      : currentFilters[SmaPlatform.Instagram],
    [SmaPlatform.Tiktok]: response[SmaPlatform.Tiktok]
      ? transformBrowseFiltersFromDTO(
          currentFilters[SmaPlatform.Tiktok] as TFilterConfig[],
          response[SmaPlatform.Tiktok]?.filters as ITiktokFilterDto[]
        )
      : currentFilters[SmaPlatform.Tiktok],
  });
});

/*
 * Фильтрация и сортировка инфлюенсеров.
 * Используется так же для простого запроса инфлюенсеров.
 */
export const loadInfluencersFx = createEffect(() => {
  const activePlatform = $activeSocialPlatform.getState()?.platform || SmaPlatform.Youtube;

  const filters = getBrowseFiltersDTO($browseFilters.getState()[activePlatform] as TFilterConfig[]);
  const sorts = getBrowseSortsDTO(
    $browseSorts.getState()[activePlatform] as TSortItem[]
  ) as IYoutubeSmaStatisticsSortDto[];

  loadInfluencersAPIFx({
    sorts,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    filters: filters as any, // TODO разобраться с типами
    limit: BROWSE_LOADING_LIMIT,
    offset: $browseOffset.getState(),
  });
});

/*
 * Список инфлюенсеров на станице Browse.
 */
export const $browseInfluencers = createStore(INITIAL_BROWSE_INFLUENCERS)
  .on(setBrowseFilters, (influencers, filters) => {
    if (!hasActiveFilters(filters)) return INITIAL_BROWSE_INFLUENCERS;

    return {
      ...influencers,
      smasStatistics: influencers.smasStatistics,
    };
  })
  .on(loadInfluencersAPIFx.done, (influencers, { params, result }) => {
    const activePlatform = $activeSocialPlatform.getState()?.platform || SmaPlatform.Youtube;
    const filters = getBrowseFiltersDTO(
      $browseFilters.getState()[activePlatform] as TFilterConfig[]
    );
    // Если фильтров нет, бэк нам все равно присылает блогеров, отображать нам их не надо.
    if (!filters.length) return INITIAL_BROWSE_INFLUENCERS;
    /*
     * Если offset больше 0, значит это подгрузка данных на скролл - добавляем новую порцию данных к ранее загруженной.
     */
    const isUpdateOnScroll = Number(params.offset) > 0;

    $activeSocialPlatform.getState()?.isYoutube &&
      // TODO: Эхо старых костылей. Шкварка дикая. Выпилить.
      // Кроме того текущий prepareInfluencersData не подходит для инстаграма
      prepareInfluencersData(
        result.smasStatistics as IYoutubeSmaStatisticsResponseDto[],
        params.filters
      );

    const smasStatistics = isUpdateOnScroll
      ? // any тут полностью легально, т.к. мы не можем привести его к одному
        // из двух типов IYoutubeSmaStatisticsResponseDto[] | IInstagramSmaStatisticsResponseDto[]
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        [...(influencers.smasStatistics as any), ...result.smasStatistics]
      : result.smasStatistics;

    return { ...result, smasStatistics, isFetched: true };
  })
  .on(profileNoteChanged, (store, payload) => {
    const prepared = updateDiscoveryNoteCounts(
      store.smasStatistics,
      payload.smaId,
      payload.newCount
    );
    // any тут полностью легально, т.к. мы не можем привести его к одному
    // из двух типов IYoutubeSmaStatisticsResponseDto[] | IInstagramSmaStatisticsResponseDto[]
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    return { ...store, smasStatistics: prepared as any };
  })
  .reset(resetBrowseInfo)
  .reset(resetActivePlatform);

forward({
  from: [setBrowseSort, increaseBrowseOffset],
  to: loadInfluencersFx,
});

forward({
  from: [
    setBrowseFilters.map(() => true),
    loadInfluencersFx.done.map(() => false),
    setAllBrowseFilters.map(() => false),
  ],
  to: setIsBrowseFiltersChanged,
});
