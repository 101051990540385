import { FC } from 'react';
import { Icon, ICON_TYPES } from '@uikit/components/Icon';
import { PopupPositions } from '@uikit/components/Popup';
import { Tooltip } from '@uikit/components/Tooltip';
import styles from './TableHeaderCell.module.scss';

type THeaderProps = {
  text: string;
  textTooltip?: string;
  textClassName?: string;
};

export const TableHeaderCell: FC<THeaderProps> = ({ text, textTooltip, textClassName }) => (
  <h4 className={styles.tableHeaderCell}>
    <span className={textClassName}>{text}</span>
    {textTooltip && (
      <Tooltip
        content={<div>{textTooltip}</div>}
        position={PopupPositions.BottomCenter}
        offset={20}
      >
        <Icon
          type={ICON_TYPES.INFO}
          size={20}
        />
      </Tooltip>
    )}
  </h4>
);
