/*
 * Утилиты для работы с API.
 */
import { datadogLogs } from '@datadog/browser-logs';
import { AxiosError } from 'axios';
import _omit from 'lodash/omit';
import { TBackendError, TRequestSettings } from '@api/api.types';
import { LOG_SEVERITY, LOG_TOPIC } from '@enums/log.enum';

const UNKNOWN_SERVER_ERROR_FRONTEND_CUSTOM_SLUG = 'frontend-custom-slug--unknown-server-error';
const ERR_NETWORK_SLUG = 'frontend-err-network';

const EXPECTED_SLUGS: string[] = [
  UNKNOWN_SERVER_ERROR_FRONTEND_CUSTOM_SLUG,
  'instagram-data-provider--provider-unavailable',
  'check-person-contact--handler-not-unique',
];

const EXPECTED_API_ERRORS: string[] = ['ERR_CANCELED'];

/*
 * Возвращает код текста ошибки.
 */
export const getErrorMessageSlug = (
  error: TBackendError,
  requestSettings?: TRequestSettings
): string | null => {
  const status = error?.response?.status;
  const slug = error?.response?.data?.slug;

  if (status && requestSettings?.errorStatusesToIgnore?.includes(status)) return null;
  if (slug && requestSettings?.errorCodesToIgnore?.includes(slug)) return null;
  if (requestSettings?.skipErrorFn && requestSettings?.skipErrorFn(error)) return null;
  if (error.code === AxiosError.ERR_NETWORK) return ERR_NETWORK_SLUG;
  if (error?.code && EXPECTED_API_ERRORS.includes(error?.code)) return null;

  return error?.response?.data?.slug || UNKNOWN_SERVER_ERROR_FRONTEND_CUSTOM_SLUG;
};

/*
 * Возвращает признак необходимости логирования ошибки.
 */
export function isloggableError(): boolean {
  return process.env.ENVIRONMENT !== 'localhost';
}

/*
 * Выполняет логирование API ошибки в datadog.
 */
export function logAPIError(error: TBackendError, errorMessageSlug: string): void {
  const isExpectedError = EXPECTED_SLUGS.includes(errorMessageSlug);

  datadogLogs.logger.error(
    `${error.config?.method}: ${error.config?.url} (${error.response?.status}) (slug: ${error.response?.data?.slug})`,
    {
      severity: isExpectedError
        ? LOG_SEVERITY.EXPECTED_API_ERROR
        : LOG_SEVERITY.UNEXPECTED_API_ERROR,
      topic: isExpectedError ? LOG_TOPIC.EXPECTED_API_ERROR : LOG_TOPIC.UNEXPECTED_API_ERROR,
      traceId: error.config?.headers?.['x-request-id'],
      appName: `hypetrain-frontend-${process.env.ENVIRONMENT}`,
      headers: _omit(error.config?.headers, ['Authorization']),
      baseURL: error.config?.baseURL,
      method: error.config?.method,
      url: error.config?.url,
      params: error.config?.params,
      response: {
        data: error.response?.data,
        status: error.response?.status,
        statusText: error.response?.statusText,
      },
    }
  );
}
