/*
 * Типы компонента системного уведомления.
 */
import { TButtonProps } from '@uikit/components/Buttons';

/*
 * Параметры действия в системном уведомлении (кнопки).
 */
export type TSystemNotificationAction = {
  text: string;
  button?: TButtonProps;
  onClick: () => void;
  closeOnClick?: boolean;
};

/*
 * Типы системных уведомлений.
 */
export enum SYSTEM_NOTIFICATION_TYPES {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}
