import {
  ICampaignFullInfoDto,
  ICreateRepresentativePersonRequestDto,
  IPersonSimpleInfoDto,
  IUnreadMessagesCountDto,
} from '@hypetrainCommon';
import { ICampaignDealListItemDto } from '@api/campaigns';

export type TCampaignStore = {
  campaignDetails: ICampaignFullInfoDto;
  campaignDeals: Record<string, ICampaignDealListItemCustom>;
  unreadMessages: IUnreadMessagesCountDto;
};

// TODO выпилить этот интрфейс, костыль потому что есть кастомное поле highlightedString
export interface ICampaignDealListItemCustom extends ICampaignDealListItemDto {
  // TODO я думаю это нужно выпилить т.к. бэк отдает ICampaignDealResponseDto, а зачем нам тут
  //  highlightedString непонятно
  highlightedString?: string; // TODO к Жене: Мы не можем на месте это брать из стора???
}

export type TCampaignDealListItemMassMailing = Omit<ICampaignDealListItemDto, 'persons'> & {
  persons: TPersonSimpleInfoMassMailing[];
};

export type TPersonSimpleInfoMassMailing = IPersonSimpleInfoDto & {
  isCheckbox: boolean;
};

/*
 * Параметры для выполнения запроса на добавления контакта к каналу.
 */
export type TAddContactToChannelParams = {
  contact: ICreateRepresentativePersonRequestDto;
  campaignId: string;
  dealId: string;
};

export enum CAMPAIGN_DETAILS_TABS {
  DATA_ANALYTICS = 'DATA_ANALYTICS',
  COMMUNICATION = 'COMMUNICATION',
  STAGES = 'STAGES',
}
