// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".highlightedText__IbpbS mark{background-color:#dfdbff}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/HighlightedText/HighlightedText.module.scss"],"names":[],"mappings":"AAGE,6BACE,wBAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.highlightedText {\n  mark {\n    background-color: #dfdbff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlightedText": "highlightedText__IbpbS"
};
export default ___CSS_LOADER_EXPORT___;
