/*
 * Компонент панели.
 */
import cn from 'clsx';
import { FC, MouseEvent, PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, BUTTON_TYPE } from '@uikit/components/Buttons';
import { ErrorBoundary } from '@uikit/components/ErrorBoundary';
import { usePanel } from '@uikit/components/Panel/hooks/usePanel.hook';
import { Portal } from '@uikit/components/Portal';
import styles from './panel.module.scss';

type TPanelButton = {
  text: string; // text in button
  type?: BUTTON_TYPE; // button type (PRIMARY by default)
  handler: (e: MouseEvent<HTMLButtonElement>) => void; // button click callback
};

type TPanelProps = {
  title?: string; // panel title
  left?: string; // position
  right?: string;
  top?: string;
  bottom?: string;
  width?: string; // panel width
  height?: string; // panel height
  minHeight?: string; // max panel height
  leftButtons?: TPanelButton[]; // list panel buttons (place on left side)
  rightButtons?: TPanelButton[]; // list panel buttons (place on right side)
  hideButtons?: boolean; // sign of the need to hide buttons (space that buttons occupy will be saved/not hide)
  className?: string;
};

export const Panel: FC<PropsWithChildren<TPanelProps>> = ({
  title,
  left,
  right = '20px',
  bottom = '20px',
  top,
  width = '540px',
  height = '655px',
  minHeight = '540px',
  leftButtons,
  rightButtons,
  hideButtons,
  className,
  children,
}) => {
  const hasButtons = !!leftButtons?.length || !!rightButtons?.length;
  const location = useLocation();

  const panelAPI = usePanel();
  useEffect(() => panelAPI.reject, [location.pathname]);

  return (
    <Portal>
      <div
        className={cn(styles.panel, className)}
        style={{ minHeight, width, height, left, right, top, bottom }}
      >
        <ErrorBoundary layerName="panel">
          <div className={styles.panel__header}>
            {title && <h2 className={styles.panel__title}>{title}</h2>}
          </div>

          <div>{children}</div>

          {hasButtons && (
            <div className={styles.panel__buttons}>
              {!hideButtons && (
                <>
                  <div>
                    {leftButtons?.map((button) => (
                      <Button
                        key={button.text}
                        buttonType={button.type || BUTTON_TYPE.PRIMARY}
                        onClick={button.handler}
                      >
                        {button.text}
                      </Button>
                    ))}
                  </div>

                  <div className={styles.panel__buttons_left}>
                    {rightButtons?.map((button) => (
                      <Button
                        key={button.text}
                        buttonType={button.type || BUTTON_TYPE.PRIMARY}
                        onClick={button.handler}
                      >
                        {button.text}
                      </Button>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </ErrorBoundary>
      </div>
    </Portal>
  );
};
