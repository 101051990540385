/*
 * Сервис для работы с webSocket-нотификациями о подписках.
 */
import { NotificationContext, NotificationType, UserNotification } from '@hypetrainCommon';
import {
  TWebSocketMessageCallback,
  TWebSocketMessageHandler,
  WebSocketService,
} from '@api/webSocket';

export class SubscriptionSocketService {
  private handlers: TWebSocketMessageHandler[] = [];

  constructor(private readonly socket: WebSocketService) {
    this.init();
  }

  /*
   * Инициализация.
   * Слушает целевой топик Subscription, отправляя все поступившие нотификации в менеджер (обработчик).
   */
  private init = (): void => {
    this.socket.on(NotificationContext.Subscription, this.messageManager);
  };

  /*
   * Отписывается от просушиваемого топика, очищает список обработчиков.
   */
  public destroy = (): void => {
    this.socket.off(NotificationContext.Subscription, this.messageManager);
    this.handlers = [];
  };

  /*
   * Подписка на нотификацию о скором окончании триала.
   */
  public onTrialWillEnd = (cb: TWebSocketMessageCallback): void => {
    const selector = (notification: UserNotification): boolean =>
      notification?.type === NotificationType.trialWillEnd;

    this.handlers.push({
      id: NotificationType.trialWillEnd,
      selector,
      cb,
    });
  };

  /*
   * Подписка на нотификацию об окончании триала.
   */
  public onTrialEnded = (cb: TWebSocketMessageCallback): void => {
    const selector = (notification: UserNotification): boolean =>
      notification?.type === NotificationType.trialEnded;

    this.handlers.push({
      id: NotificationType.trialEnded,
      selector,
      cb,
    });
  };

  /*
   * Подписка на нотификацию об окончании подписки.
   */
  public onSubscriptionEnded = (cb: TWebSocketMessageCallback): void => {
    const selector = (notification: UserNotification): boolean =>
      notification?.type === NotificationType.subscriptionEnded;

    this.handlers.push({
      id: NotificationType.subscriptionEnded,
      selector,
      cb,
    });
  };

  /*
   * Подписка на нотификацию о старте грейс-периода.
   */
  public onSubscriptionPastDue = (cb: TWebSocketMessageCallback): void => {
    const selector = (notification: UserNotification): boolean =>
      notification?.type === NotificationType.subscriptionPastDue;

    this.handlers.push({
      id: NotificationType.subscriptionPastDue,
      selector,
      cb,
    });
  };

  /*
   * Менеджер обрабатывающий WebSocket-сообщения топика Subscription.
   * Вызывает селекторы каждого обработчика, что бы понять нужно ли вызывать его callback.
   */
  private messageManager = (notification: UserNotification, ack: () => void): void => {
    this.handlers.forEach((handler) => {
      if (handler.selector(notification)) {
        ack();
        handler.cb(notification);
      }
    });
  };
}

export const createSubscriptionSocketService = (webSocketService: WebSocketService) =>
  new SubscriptionSocketService(webSocketService);
