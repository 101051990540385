// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unreadMessageCounter__osOkC{position:absolute;top:-5px;right:-5px}", "",{"version":3,"sources":["webpack://./pages/Campaigns/CampaignDetailsPage/components/Communication/components/UnreadMessageCounter/UnreadMessageCounter.module.scss"],"names":[],"mappings":"AAEA,6BACE,iBAAA,CACA,QAAA,CACA,UAAA","sourcesContent":["@import '../../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.unreadMessageCounter {\n  position: absolute;\n  top: -5px;\n  right: -5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unreadMessageCounter": "unreadMessageCounter__osOkC"
};
export default ___CSS_LOADER_EXPORT___;
