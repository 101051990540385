/*
 * Filter component
 */
import isUndefined from 'lodash/isUndefined';
import { FC, PropsWithChildren, RefObject, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { BUTTON_TYPE, Button } from '@uikit/components/Buttons';
import { ChipToggler } from '@uikit/components/ChipToggler';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { InformationTooltip } from '@uikit/components/InformationTooltip';
import { Popup, PopupEvents, PopupPositions } from '@uikit/components/Popup';
import { TPopupAPI } from '@uikit/components/Popup/popup.types';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import { noop } from '@helpers/common.helpers';
import styles from './Filter.module.scss';
import { TFilterConfig } from './filter.types';

const DEFAULT_FILTER_POPUP_WIDTH = 273;

type TFilterProps = {
  config: TFilterConfig;
  viewValue?: string;
  outerRef?: RefObject<TPopupAPI>;
  onApply: () => void | boolean;
  onClear: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  applyOnClose: boolean; // Если данный параметр true, то валидация и сабмит фильтра происходит по клику вне popup, если false, то по кнопке
};

export const Filter: FC<PropsWithChildren<TFilterProps>> = ({
  config,
  viewValue,
  onApply,
  onClear,
  children,
  outerRef,
  applyOnClose,
  onOpen = noop,
  onClose = noop,
}) => {
  const { t } = useUiKitTranslation();
  const innerRef = useRef<TPopupAPI>(null);
  const popupRef = outerRef || innerRef;
  // const filtersContext = useFilters();

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const onPopupOpen = (): void => {
    // TODO Кирилл: Исправить после выноса ui-kit
    /*     usersActionsLogService.log(LOG_FILTER_ACTIONS_BY_PLATFORM[filtersContext.platform], {
      filterId: config.id,
      place: filtersContext?.embeddingPlace,
    }); */
    setIsPopupOpen(true);
    onOpen();
  };

  // Данная функция используется для ситуаций когда apply
  // происходит по нажатию кнопки внутри popup
  const applyOnButtonClick = () => {
    // если валидация не пройдена, то прилетает false в остальных случаях undefined,
    // сделано для того чтобы не надо было в фильтрах в которых нет валидации отправлять true
    if (isUndefined(onApply())) popupRef.current?.toggle();
  };

  // Данная функция используется, когда apply происходит по нажатию
  // вне popup(кнопки apply внутри popup в данном случае нет)
  const applyOnPopupClose = () => {
    // Хак, который позволяет как бы не давать закрывать popup, если есть ошибка валидации
    if (isUndefined(onApply())) {
      // Всё хорошо и валидация пройдена
      popupRef.current?.close();
    } else {
      // Ошибка валидации и не закрываем popup
      popupRef.current?.open();
    }
  };

  const onPopupClose = () => {
    setIsPopupOpen(false);
    onClose();
    if (applyOnClose) {
      applyOnPopupClose();
    }
  };

  const filterPopupTemplate = (
    <div className={styles.filter__popupContent}>
      <div className={styles.filter__popupTitle}>
        {config.popupTitle && (
          <span className={styles.filter__popupTitleText}>{config.popupTitle}</span>
        )}
        {config.infoTooltip && (
          <InformationTooltip
            className={styles.filter__informationTooltip}
            iconStyle={ICON_STYLES.SECONDARY}
          >
            {config.infoTooltip}
          </InformationTooltip>
        )}
      </div>

      {children}
      {config.description && (
        <div className={styles.filter__descriptionText}>
          <Trans components={{ div: <div />, ul: <ul />, li: <li /> }}>{config.description}</Trans>
        </div>
      )}

      {!applyOnClose && (
        <Button
          type="button"
          buttonType={BUTTON_TYPE.PRIMARY}
          className={styles.filter__applyButton}
          onClick={applyOnButtonClick}
        >
          {t('filter.apply')}
        </Button>
      )}
    </div>
  );

  return (
    <Popup
      ref={popupRef}
      className={styles.filter}
      popupClassName={styles.filter__popup}
      width={config.filterPopupWidth || DEFAULT_FILTER_POPUP_WIDTH}
      withExternalControl
      content={filterPopupTemplate}
      position={PopupPositions.BottomRight}
      event={PopupEvents.Click}
      onClose={onPopupClose}
      onOpen={onPopupOpen}
    >
      <ChipToggler
        // методы из рефов нельзя напрямую передавать как колбэк
        // реф инициализируется с current = null и только позже заполняется не вызывая при этом перерисовку
        // если здесь передать .toggle напрямую то он будет undefined
        onToggle={() => popupRef.current?.toggle()}
        isToggled={isPopupOpen}
        onClear={onClear}
        name={config.label}
        value={viewValue}
        testId={config.testId}
      />
    </Popup>
  );
};
