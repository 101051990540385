/*
 * Контейнер для списка системных уведомлений.
 */
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { SystemNotification, systemNotificationsStore } from '@uikit/components/SystemNotification';

export const SystemNotificationsContainer: FC = observer(() => {
  const { systemNotifications } = systemNotificationsStore;

  if (!systemNotifications.length) return null;

  return (
    <>
      {systemNotifications.map((notification) => (
        <SystemNotification
          key={notification.id}
          {...notification}
        />
      ))}
    </>
  );
});
