/*
 * Create compaign dialog.
 */
import { useStore } from 'effector-react/compat';
import { FormApi } from 'final-form';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { ICampaignFullInfoDto, ICreateCampaignDto, SmaPlatform } from '@hypetrainCommon';
import { BorderedScrollableContainer } from '@uikit/components/BorderedScrollableContainer';
import { Dialog, TDialogProps } from '@uikit/components/Dialog';
import { TITLES_BY_SOCIAL_MEDIA } from '@uikit/helpers/social.helpers';
import { Routes } from '@router/Routes';
import { USERS_ACTIONS, usersActionsLogService } from '@services/usersActionsLog';
import { $activeSocialPlatform } from '@models/activeSocialPlatform';
import { createCampaignFx } from '@pages/Campaigns/CampaignsPage/campaignsPage.model';
import { CreateCampaignForm } from '@pages/Campaigns/CampaignsPage/components/CreateCampaignForm';
import { isCreate } from '@components/BlurScreen/BlurScreen.constants';
import styles from './CreateCampaignDialog.module.scss';

export type TCreateCampaignDialogProps = {
  createWithoutSelection?: boolean;
};

export enum CAMPAIGN_CREATION_PATH {
  SELECTION = 'from-selection',
  CAMPAIGN_LIST = 'from-campaigns-list',
}

export const CreateCampaignDialog: FC<
  TDialogProps<TCreateCampaignDialogProps, ICampaignFullInfoDto>
> = ({ dialog, createWithoutSelection }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState<FormApi<ICreateCampaignDto>>();
  const activePlatform = useStore($activeSocialPlatform);
  const navigate = useNavigate();

  const onSubmit = (): void => {
    if (!form) return;

    const formState = form.getState();
    const campaignInfo = formState.values;

    if (createWithoutSelection) {
      campaignInfo.smas = [];
    }

    form.submit();

    if (!formState.valid) return;

    createCampaignFx(campaignInfo).then((info) => {
      usersActionsLogService.log(USERS_ACTIONS.CAMPAIGN_CREATED, {
        campaignId: info?.id,
        platform: info?.platform,
        origin: createWithoutSelection
          ? CAMPAIGN_CREATION_PATH.CAMPAIGN_LIST
          : CAMPAIGN_CREATION_PATH.SELECTION,
      });
      dialog.resolve(info);
      const path = generatePath(Routes.campaign, {
        id: info?.id,
      });

      navigate({ pathname: path, search: `?${isCreate}=true` });
    });
  };

  const getTitle = (): string => {
    if (createWithoutSelection) {
      return t('createOrEditCampaign.createCampaignTitle');
    }

    return t('createOrEditCampaign.createCampaignWithoutSelectionTitle', {
      platform: TITLES_BY_SOCIAL_MEDIA[activePlatform?.platform ?? SmaPlatform.Youtube],
    });
  };

  return (
    <Dialog
      title={getTitle()}
      minHeight="auto"
      buttons={[
        {
          text: t('createCampaign'),
          handler: onSubmit,
        },
      ]}
    >
      <BorderedScrollableContainer className={styles.createCampaignDialog}>
        <CreateCampaignForm
          /* @ts-ignore TODO Андрей */
          onChangeForm={setForm}
          createWithoutSelection={createWithoutSelection}
        />
      </BorderedScrollableContainer>
    </Dialog>
  );
};
