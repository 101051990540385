/*
 * Хук возвращает значение в зависимости от того где мы находимся: Campaign или Browse или 1 Deal внутри кампании
 * */
import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { SmaAudienceCountryValue } from '@hypetrainCommon';
import { Routes } from '@router/Routes';

type TUseInstagramDynamicColumns = {
  campaignValue: string | SmaAudienceCountryValue[];
  browseValue: string | SmaAudienceCountryValue[];
};

export const useDynamicColumns = ({
  campaignValue,
  browseValue,
}: TUseInstagramDynamicColumns): string | SmaAudienceCountryValue[] => {
  const location = useLocation();

  return useMemo(() => {
    if (matchPath({ path: Routes.campaign }, location.pathname)) {
      return campaignValue;
    }
    if (matchPath({ path: Routes.campaignDeal }, location.pathname)) {
      return browseValue;
    }
    return browseValue;
  }, [location.pathname]);
};
