/*
 * Popup тогглера выбора опций.
 */
import { FC } from 'react';
import { TOptionsToggleItem, TOptionsTogglerGroup } from '@uikit/components/OptionsToggler';
import { Switcher } from '@uikit/components/Switcher';
import { SwitcherGroup, TSwitcherGroupValues } from '@uikit/components/SwitcherGroup';
import styles from './OptionsTogglerPopup.module.scss';

type TOptionsTogglerPopupProps = {
  options: TOptionsToggleItem[];
  onChange: (options: TOptionsToggleItem[]) => void;
  groups?: TOptionsTogglerGroup[];
  title: string;
};

export const OptionsTogglerPopup: FC<TOptionsTogglerPopupProps> = ({
  options,
  onChange,
  groups,
  title,
}) => {
  // Создаём объект value для SwitcherGroup где каждому id опции соответсвует boolean признака выбранности.
  const value: TSwitcherGroupValues = options.reduce(
    (acc, option) => ({
      ...acc,
      [option.id]: option.selected,
    }),
    {}
  );

  // Конвертируем формат value SwitcherGroup в формат options для OptionsToggler.
  const onChangeHandler = (groupValue: TSwitcherGroupValues): void => {
    const updatedOptions: TOptionsToggleItem[] = options.map((option) => ({
      ...option,
      selected: groupValue[option.id],
    }));

    onChange(updatedOptions);
  };

  const getOptionsWithoutGroups = () => (
    <SwitcherGroup
      value={value}
      onChange={onChangeHandler}
    >
      {options.map((option) => (
        <Switcher
          key={option.id}
          className={styles.optionsTogglerPopup__option}
          id={option.id}
          label={option.switcherLabel || option.label}
        />
      ))}
    </SwitcherGroup>
  );

  const getOptionsWithGroups = () => (
    <div className={styles.optionsTogglerPopup__groups}>
      {(groups as TOptionsTogglerGroup[]).map((group) => (
        <div key={group.id}>
          <div className={styles.optionsTogglerPopup__groupLabel}>{group.label}</div>
          <SwitcherGroup
            value={value}
            onChange={onChangeHandler}
          >
            {options
              .filter((o) => o.group === group.id)
              .map((option) => (
                <Switcher
                  key={option.id}
                  className={styles.optionsTogglerPopup__option}
                  id={option.id}
                  label={option.switcherLabel || option.label}
                />
              ))}
          </SwitcherGroup>
        </div>
      ))}
    </div>
  );

  return (
    <div className={styles.optionsTogglerPopup}>
      <div className={styles.optionsTogglerPopup__title}>{title}</div>

      {groups?.length ? getOptionsWithGroups() : getOptionsWithoutGroups()}
    </div>
  );
};
