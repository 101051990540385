// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".borderedScrollableContainer__Nv1l8{overflow-y:auto}.borderedScrollableContainer_withScroll__qYITf{border-top:1px solid rgba(0,0,0,.08);border-bottom:1px solid rgba(0,0,0,.08);padding:var(--hSpacing4) 0}.borderedScrollableContainer_withoutTopDivider__cVgSZ{border-top:unset !important}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/BorderedScrollableContainer/BorderedScrollableContainer.module.scss"],"names":[],"mappings":"AAEA,oCACE,eAAA,CAEA,+CACE,oCAAA,CACA,uCAAA,CACA,0BAAA,CAGF,sDACE,2BAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.borderedScrollableContainer {\n  overflow-y: auto;\n\n  &_withScroll {\n    border-top: 1px solid rgb(0 0 0 / 8%);\n    border-bottom: 1px solid rgb(0 0 0 / 8%);\n    padding: var(--hSpacing4) 0;\n  }\n\n  &_withoutTopDivider {\n    border-top: unset !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"borderedScrollableContainer": "borderedScrollableContainer__Nv1l8",
	"borderedScrollableContainer_withScroll": "borderedScrollableContainer_withScroll__qYITf",
	"borderedScrollableContainer_withoutTopDivider": "borderedScrollableContainer_withoutTopDivider__cVgSZ"
};
export default ___CSS_LOADER_EXPORT___;
