import { FC, memo, useMemo } from 'react';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '@uikit/components/Buttons';
import { CheckboxField } from '@uikit/components/Fields/CheckboxField';
import { ICON_TYPES } from '@uikit/components/Icon';
import { modalService } from '@uikit/components/Modal';
import { SelectAllModal } from '@uikit/components/Table/components/columns/SelectAllRowsCheckbox/SelectAllModal';
import { TSelectAllModal } from '@uikit/components/Table/components/columns/SelectAllRowsCheckbox/SelectAllModal/SelectAllModal.component';
import { noop } from '@uikit/helpers/common.helpers';
import { useAllRowSelect } from '@uikit/hooks/useAllRowSelect';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import { TActiveSocialPlatforms } from '@models/activeSocialPlatform';
import styles from './SelectAllRowsCheckbox.module.scss';

type TCheckboxProps = {
  displayedRows: unknown[];
  selectedRows: unknown[];
  activePlatform: TActiveSocialPlatforms | null;
  onUnselect: (props?: unknown) => unknown;
  onSelect: (props?: unknown) => unknown;
  withPartialSelection?: boolean;
  pathId?: string;
};

// There two types of checkbox - withPartialSelection and without
// withPartialSelection - square checkbox with three steps (checked, unchecked, partially checked with minus as icon)
// Without partial - default checkbox button with two steps (checked, unchecked)

export const SelectAllRowsCheckbox: FC<TCheckboxProps> = memo(
  ({
    displayedRows,
    selectedRows,
    onUnselect,
    onSelect,
    withPartialSelection,
    pathId,
    activePlatform,
  }) => {
    const { t } = useUiKitTranslation();
    const { isAllChecked, isAnyChecked, toggleAllRowSelection, notSelectedRows } = useAllRowSelect({
      displayedRows,
      selectedRows,
      onUnselect,
      onSelect,
      pathId,
      withPartialSelection,
    });

    const isChecked = useMemo(
      () => (withPartialSelection ? isAllChecked() || isAnyChecked() : isAllChecked()),
      [selectedRows, withPartialSelection]
    );

    const openModal = () => {
      modalService.open<never, TSelectAllModal>({
        component: SelectAllModal,
        props: {
          count: notSelectedRows.length,
          activePlatform,
          onClick: () => toggleAllRowSelection(),
        },
      });
    };

    const handleChange = () => {
      if (withPartialSelection) {
        toggleAllRowSelection();
        return;
      }
      isAllChecked() ? toggleAllRowSelection() : openModal();
    };

    if (withPartialSelection) {
      return (
        <CheckboxField
          input={{
            name: 'globalCheckbox',
            checked: isChecked,
            onChange: handleChange,
            onFocus: noop,
            onBlur: noop,
            value: '',
          }}
          isPartial={!isAllChecked() && isAnyChecked()}
          className={styles.tableCheckbox}
        />
      );
    }

    return (
      <Button
        className={styles.selectAllRowsCheckboxButton}
        buttonType={isChecked ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.SECONDARY}
        icon={isChecked ? ICON_TYPES.DOUBLE_CHECK : undefined}
        size={BUTTON_SIZE.XS}
        onClick={handleChange}
      >
        {!isChecked && t('selectAllRowsCheckbox.all')}
      </Button>
    );
  }
);
