/*
 * Sorts configuration for browse page (TikTok).
 */
import { TiktokSmaStatisticsSortName } from '@hypetrainCommon';
import { TAvailableSort } from '@uikit/components/Sorts/sorts.types';
import i18n from '@services/i18n';

export const BROWSE_PAGE_AVAILABLE_SORTS_CONFIG_TIKTOK: TAvailableSort[] = [
  {
    id: TiktokSmaStatisticsSortName.FollowersCount,
    label: i18n.t('sorts.tikTok.followers'),
    selected: true,
    testId: 'TT_FOLLOWERS_COUNT_SORT',
  },
  {
    id: TiktokSmaStatisticsSortName.EngagementRate,
    label: i18n.t('sorts.tikTok.engagementRate'),
    selected: true,
    extra: { day: '30' },
    testId: 'TT_ER_SORT',
  },
  {
    id: TiktokSmaStatisticsSortName.Views,
    label: i18n.t('sorts.tikTok.views'),
    selected: true,
    extra: { day: '30' },
    testId: 'TT_VIEWS_SORT',
  },
];
