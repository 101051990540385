/*
 * Global panel store.
 */
import { makeAutoObservable } from 'mobx';
import { FunctionComponent } from 'react';
import { TPanelProps } from './panel.types';

export type TPanelConfig<P = Record<never, never>, D = never, R = never> = {
  id: string;
  component: FunctionComponent<TPanelProps<P, D, R>>;
  props: TPanelProps<P, D, R>;
};

export class PanelStore {
  /*
   * Displayed panels.
   */
  public panels: TPanelConfig[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  /*
   * Add panel for display.
   */
  public add<P, D, R>(config: TPanelConfig<P, D, R>): void {
    this.panels.push(config as TPanelConfig<unknown, D, R>);
  }

  /*
   * Delete (hide) panel.
   */
  public delete(id: string): void {
    this.panels = this.panels.filter((panel) => panel.id !== id);
  }

  /*
   * Возвращает признак существования панели с переданным id.
   */
  public has(id: string): boolean {
    return this.panels.some((panel) => panel.id === id);
  }
}

const createPanelStore = () => new PanelStore();

export const panelStore = createPanelStore();
