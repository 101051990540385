/*
 * Общий стор профиля.
 * Не захламлять!!!
 */
import { createEffect } from 'effector';
import { createEvent, createStore } from 'effector/compat';
import { SmaPlatform } from '@hypetrainCommon';
import { explorationApiServiceNew } from '@api/exploration';
import { smaApiService } from '@api/sma';
import { getSocialPlatformBySMAId } from '@utils/socialPlatform.utils';
import { getProfileStore } from '@components/Profile/profile.utils';

export type TSelectedSocialPlatforms = {
  type: SmaPlatform;
};

export type TLinkedListProfile = {
  sma: {
    uuid: string;
    title: string;
  };
};

export type TProfileLinkedList = {
  current: TLinkedListProfile;
  prevProfile: {
    paginationItems: TLinkedListProfile[];
  };
};

type TSmaNoteParams = { note: string; noteCount: number; oldNote: string };

const emptyLinkedList: TProfileLinkedList = {
  current: { sma: { uuid: '', title: '' } },
  prevProfile: { paginationItems: [] },
};

export const initProfile = createEvent<string>();

export const addProfileToLinkedList = createEvent<TProfileLinkedList>();
export const setProfileIsLookalike = createEvent<boolean>();

export const resetProfileIsLookalike = createEvent();
export const resetProfile = createEvent();
export const resetProfileLinkedList = createEvent();
export const profileNoteChanged = createEvent<{ smaId: string; newCount: number }>();
/*
 * Стор для хранения выбранной соц. сети
 */
export const $profileSocialPlatform = createStore<TSelectedSocialPlatforms>({
  type: SmaPlatform.Youtube, // нужно что-то выставить по умолчанию.
})
  .on(initProfile, (_, id) => ({
    type: getSocialPlatformBySMAId(id),
  }))
  .reset(resetProfile);

/*
 * Стор для хранения цепочки открытых профилей
 */
export const $profileLinkedList = createStore<TProfileLinkedList>(emptyLinkedList)
  .on(addProfileToLinkedList, (store, payload) => payload)
  .reset(resetProfileLinkedList);

/*
 * Стор для хранения состояния что мы кликнули по лукалайкам
 */
export const $profileIsLookalike = createStore<boolean>(false)
  .on(setProfileIsLookalike, (store, payload) => payload)
  .reset(resetProfileIsLookalike);

/*
 * Сохранение заметки по SMA.
 */
export const setSmaNoteFx = createEffect((params: TSmaNoteParams) => {
  const sma = getProfileStore($profileSocialPlatform.getState().type)!.getState(); // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const isNoteAddedOrRemoved =
    (!params.oldNote.length && params.note.length) ||
    (params.oldNote.length && !params.note.length);

  if (isNoteAddedOrRemoved) {
    const newCount = !params.note.length ? params.noteCount - 1 : params.noteCount + 1;

    profileNoteChanged({ smaId: sma?.uuid, newCount });
  }

  if ($profileSocialPlatform.getState().type === SmaPlatform.Tiktok) {
    return explorationApiServiceNew
      .setSmaNote({ note: params.note, smaId: sma?.uuid })
      .then(() => {});
  }

  return smaApiService.setSmaNote({ note: params.note, smaId: sma?.uuid }).then(() => {});
});
