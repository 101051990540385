import cn from 'clsx';
import { FocusEvent, KeyboardEvent, MouseEvent, ReactElement, forwardRef, useState } from 'react';
import { FieldInputProps } from 'react-final-form';
import { Link } from 'react-router-dom';
import hiddenPassword from '@uikit/assets/images/hiddenPassword.svg';
import showedPassword from '@uikit/assets/images/showedPassword.svg';
import { INPUT_TYPE } from '@uikit/components/Fields/Inputs/Input.constants';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { InformationTooltip } from '@uikit/components/InformationTooltip';
import { PopupPositions } from '@uikit/components/Popup';
import { UnderlineTooltip } from '@uikit/components/UnderlineTooltip';
import styles from './Input.module.scss';

export type TInputProps = {
  input?: FieldInputProps<string>;
  type?: string;
  label?: string;
  disabled?: boolean;
  descriptionText?: string;
  forwardText?: string;
  forwardLink?: string;
  placeholder?: string;
  hideError?: boolean;
  onKeyUp?: (text: KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (text: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onClick?: (event?: MouseEvent<HTMLInputElement>) => void;
  error?: string | null;
  className?: string;
  containerClassName?: string;
  autocomplete?: string;
  autoFocus?: boolean;
  tooltip?: ReactElement;
  underlineTooltip?: string;
  valueFormatter?: (value: string) => string;
};

export const Input = forwardRef<HTMLInputElement, TInputProps>(
  (
    {
      input,
      type,
      label,
      disabled = false,
      descriptionText = '',
      forwardText = '',
      forwardLink = '',
      placeholder = '',
      onKeyUp = () => {},
      onKeyDown = () => {},
      onBlur,
      onClick,
      error,
      className,
      containerClassName,
      hideError,
      autocomplete,
      autoFocus,
      tooltip,
      underlineTooltip,
      valueFormatter = (value) => value,
    },
    ref
  ) => {
    const [values, setValues] = useState({
      hidePasswordText: type === INPUT_TYPE.PASSWORD,
    });

    const fieldType =
      (type === INPUT_TYPE.PASSWORD &&
        (values.hidePasswordText ? INPUT_TYPE.PASSWORD : INPUT_TYPE.TEXT)) ||
      type;

    const handleClickShowPassword = () => {
      setValues({ ...values, hidePasswordText: !values.hidePasswordText });
    };

    const onBlurHandler = (e: FocusEvent<HTMLInputElement>): void => {
      const initValue = e.target.value;
      const trimValue = valueFormatter(e.target.value.trim());

      if (initValue !== trimValue) {
        e.target.value = trimValue;
        input?.onChange(e);
      }

      onBlur && onBlur(e);
      input?.onBlur(e);
    };

    return (
      <div className={cn(styles.inputBlock, className)}>
        <div className={cn(styles.topText, tooltip && styles.topText_withTooltip)}>
          <div className={styles.label}>
            {underlineTooltip ? (
              <UnderlineTooltip tooltipText={underlineTooltip}>{label}</UnderlineTooltip>
            ) : (
              label
            )}
          </div>
          {forwardText && (
            <Link
              to={forwardLink}
              tabIndex={-1}
            >
              {forwardText}
            </Link>
          )}
          {tooltip && (
            <InformationTooltip
              position={PopupPositions.TopCenter}
              iconStyle={ICON_STYLES.SECONDARY}
            >
              {tooltip}
            </InformationTooltip>
          )}
        </div>
        {!!descriptionText?.length && (
          <div className={styles.descriptionText}>{descriptionText}</div>
        )}
        <div className={cn(styles.inputBlock__container, containerClassName)}>
          <input
            {...input}
            ref={ref}
            className={cn(styles.inputBlock__input, [
              {
                [styles.inputBlock__input__errorInput]: error,
              },
              type === INPUT_TYPE.PASSWORD && styles.inputBlock__passwordInput,
            ])}
            disabled={disabled}
            type={fieldType}
            placeholder={placeholder}
            onKeyUp={(e) => onKeyUp(e)}
            onKeyDown={(e) => onKeyDown(e)}
            onBlur={onBlurHandler}
            onClick={onClick}
            autoComplete={autocomplete}
            autoFocus={Boolean(autoFocus)}
          />
          {type === INPUT_TYPE.PASSWORD && (
            <img
              onClick={handleClickShowPassword}
              className={styles.inputBlock__passwordIcon}
              src={values.hidePasswordText ? hiddenPassword : showedPassword}
              alt="emailIcon"
            />
          )}
          {!hideError && <span className={styles.inputBlock__error}>{error}</span>}
        </div>
      </div>
    );
  }
);
