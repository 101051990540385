import _keyBy from 'lodash/keyBy';
import {
  CampaignDealContractingStageStateName,
  ICampaignDealResponseDto,
  ICampaignDealStageDto,
} from '@hypetrainCommon';

// Получаем активную стадию в диле
export const getActiveStateInDeal = <T>(
  campaignDeal: ICampaignDealResponseDto | null,
  activeStage: ICampaignDealStageDto | null
): T => {
  if (!campaignDeal) return CampaignDealContractingStageStateName.Initial as T;

  const stages = _keyBy(campaignDeal?.stages?.list, 'id');

  return stages[activeStage?.id || '']?.currentState as T;
};

// Получаем следующий стейдж который идет за активным
export const getNextActiveStage = (dealStages: ICampaignDealStageDto[], name: string) => {
  const index = dealStages.findIndex((stage) => stage.name === name);
  return index !== -1 && index < dealStages.length - 1
    ? (dealStages[index + 1] as ICampaignDealStageDto)
    : undefined;
};

export enum AMOUNT_PERCENTS {
  HALF = 50,
  FULL = 100,
}
