/*
 * Component that adds specific for tooltip styles.
 */
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { FC, PropsWithChildren } from 'react';
import { ErrorBoundary } from '@uikit/components/ErrorBoundary';
import { PopupPositions, usePopup } from '@uikit/components/Popup';
import styles from './tooltipContainer.module.scss';

type TTooltipContainerProps = {
  position?: PopupPositions; // position to display the tooltip
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const TooltipContainer: FC<PropsWithChildren<TTooltipContainerProps>> = observer(
  ({ position, children }) => {
    const { popupStore } = usePopup();
    /*
     * Смотрим на фактическое место отрисовки tooltip, если tooltip не влез оно может отличаться от заданного.
     */
    const renderedPosition = popupStore?.renderedPosition || position;

    return (
      <div className={cn(styles.tooltipContainer, renderedPosition && styles[renderedPosition])}>
        <ErrorBoundary
          layerName="tooltip"
          errorStubClassName={styles.tooltipContainer_error}
        >
          {children}
        </ErrorBoundary>
      </div>
    );
  }
);
