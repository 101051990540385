import { TFilter } from '@uikit/components/Filters/filters.types';

type TMale = {
  male: string;
  weight: number;
};

type TFemale = {
  male: string;
  weight: number;
};

export type TGenders = {
  male?: number;
  female?: number;
  MALE?: TMale;
  FEMALE?: TFemale;
};

export enum SEX_DEMOGRAPHIC {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum DISPLAYED_AGES {
  '13-17' = '13-17',
  '18-24' = '18-24',
  '25-34' = '25-34',
  '35-44' = '35-44',
  '45-54' = '45-54',
  '45-64' = '45-64',
  '55+' = '55+',
  '65+' = '65+',
}

export type TGetAudienceDemographicColor = {
  rangeAge: DISPLAYED_AGES;
  filterAudience: TFilter;
  maxAge: TMaxAgeAudienceDemographic;
  sex: SEX_DEMOGRAPHIC;
};

export type TGendersPerAges = Partial<Record<DISPLAYED_AGES, TGenders>>;

export type TMaxAgeAudienceDemographic = {
  key: string;
  value?: number;
};
