/*
 * Компонент empty state-заглушки для таблиц списка транзакций по платёжным счетам.
 */
import { FC } from 'react';
import { NoDataComponent } from '@uikit/components/templates/NoDataComponent';
import emptyIcon from '@assets/images/payments/emptyPaymentAccountHistory.svg';
import styles from './PaymentAccounNotingFound.module.scss';

type TPaymentAccounNotingFoundProps = {
  title: string;
  description: string;
};

export const PaymentAccounNotingFound: FC<TPaymentAccounNotingFoundProps> = ({
  title,
  description,
}) => (
  <div className={styles.workspaceMembersTab__emptyTableContainer}>
    <NoDataComponent
      title={title}
      description={description}
      img={emptyIcon}
      className={styles.workspaceMembersTab__emptyTable}
      useMediumTitle
    />
  </div>
);
