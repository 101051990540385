/*
 * Модели для конфигурации страницы детальной информации по компании.
 * Конфигурации включают в себя фильтры и сортировки.
 */
import axios from 'axios';
import { createEffect, createEvent, createStore, forward } from 'effector/compat';
import _differenceWith from 'lodash/differenceWith';
import _isEqual from 'lodash/isEqual';
import {
  CampaignDealsSortName,
  CampaignFilterConfig,
  CampaignSortConfig,
  ISetPersonCampaignConfigRequestDto,
  SmaPlatform,
} from '@hypetrainCommon';
import { TFilterConfig } from '@uikit/components/Filter';
import {
  getInitFilters,
  makeFilterRequestData,
  transformFiltersFromDTO,
} from '@uikit/components/Filters/filters.utils';
import { TAvailableSort, TSortItem } from '@uikit/components/Sorts/sorts.types';
import {
  getInitSorts,
  makeSortRequesData,
  transformSortFromDTO,
} from '@uikit/components/Sorts/sorts.utils';
import { TAvailableFilter } from 'hypetrain-ui/src/components/Filters';
import {
  TCampaignDealsResponse,
  TSetPersonCampaignConfigParams,
  campaignsApiService,
} from '@api/campaigns';
import { USERS_ACTIONS, usersActionsLogService } from '@services/usersActionsLog';
import {
  $campaignStore,
  $searchTerm,
  getCampaignDealsFx,
  getCampaignDealsWithSearchFx,
} from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.model';
import { AVAILABLE_CAMPAIGN_FILTERS_CONFIG } from '@pagesCampaigns/CampaignDetailsPage/components/CampaignsFilters/YoutubeCampaignsFilters.config';
import { AVAILABLE_INSTAGRAM_CAMPAIGN_FILTERS_CONFIG } from '@pagesCampaigns/CampaignDetailsPage/components/CampaignsFilters/instagramCampaignsFilters.config';
import { AVAILABLE_TIKTOK_CAMPAIGN_FILTERS_CONFIG } from '@pagesCampaigns/CampaignDetailsPage/components/CampaignsFilters/tiktokCampaignsFilters.config';
import { AVAILABLE_CAMPAIGN_SORTS_CONFIG } from '@pagesCampaigns/CampaignDetailsPage/components/CampaignsSorts/campaignsSorts.config';
import { AVAILABLE_INSTAGRAM_CAMPAIGN_SORTS_CONFIG } from '@pagesCampaigns/CampaignDetailsPage/components/CampaignsSorts/instagramCampaignsSorts.config';
import { AVAILABLE_TIKTOK_CAMPAIGN_SORTS_CONFIG } from '@pagesCampaigns/CampaignDetailsPage/components/CampaignsSorts/tiktokCampaignsSorts.config';

const platformFiltersMap: Partial<Record<SmaPlatform, TAvailableFilter[]>> = {
  [SmaPlatform.Youtube]: AVAILABLE_CAMPAIGN_FILTERS_CONFIG,
  [SmaPlatform.Instagram]: AVAILABLE_INSTAGRAM_CAMPAIGN_FILTERS_CONFIG,
  [SmaPlatform.Tiktok]: AVAILABLE_TIKTOK_CAMPAIGN_FILTERS_CONFIG,
};

const platformSortsMap: Partial<Record<SmaPlatform, TAvailableSort[]>> = {
  [SmaPlatform.Youtube]: AVAILABLE_CAMPAIGN_SORTS_CONFIG,
  [SmaPlatform.Instagram]: AVAILABLE_INSTAGRAM_CAMPAIGN_SORTS_CONFIG,
  [SmaPlatform.Tiktok]: AVAILABLE_TIKTOK_CAMPAIGN_SORTS_CONFIG,
};

type TUpdateCampaignParams = {
  campaignId: string;
  sort?: TSortItem[];
  filter?: TFilterConfig[];
  withUpdateCampaign?: boolean;
};

/*
 * Обновление сортировок
 */
export const updateSort = createEvent<TUpdateCampaignParams>();

/*
 * Обновление фильтров
 */
export const updateFilters = createEvent<TUpdateCampaignParams>();

/*
 * Сброс фильтров и сортировок
 */
export const resetFiltersAndSort = createEvent();

/*
 * Сохранение конфигурации фильтров и сортировок (без последующего обновления компании!!!).
 * Используется, например, при изменении списка отображаемых (доступных/не активных) фильтров/сортировок.
 */
export const setCampaignConfigFx = createEffect(campaignsApiService.setPersonCampaignConfig);

/*
 * TODO: Переписать на какую-то обёрту возвращающую кастомный abortAPI, отказавшись от фабрики.
 * Фабрика возвращающая метод сохранения конфигурации фильтров и сортировок с посоедующим обновлением компании.
 * Используется если внесённое в конфигурацию изменении изменение влияет на отображаемую компанию,
 * то есть по факту изменена сортировка или фильтр а не просто список отображаемых togglers для сортировок или фильтров.
 */
const setCampaignConfigWithUpdateFactory = (): ((
  params: TSetPersonCampaignConfigParams
) => Promise<TCampaignDealsResponse>) => {
  let abortController = new AbortController();

  return (params: TSetPersonCampaignConfigParams): Promise<TCampaignDealsResponse> => {
    /*
     * При быстром включении-выключении фильров/сортировок происходит рассинхрон между актуальным локальным стейтом и тем,
     * что пришёл от бэка после обновления компании. По этому отменяем предыдущий невыполненный запрос, если есть новый.
     */
    if (
      setCampaignConfigWithUpdateFx.pending.getState() || // eslint-disable-line @typescript-eslint/no-use-before-define
      getCampaignDealsWithSearchFx.pending.getState()
    ) {
      abortController.abort();
      abortController = new AbortController();
    }

    return setCampaignConfigFx({
      campaignId: params.campaignId,
      body: params.body,
      signal: abortController.signal,
    }).then(() =>
      getCampaignDealsWithSearchFx({
        campaignId: params.campaignId,
        signal: abortController.signal,
      })
    );
  };
};

/*
 * Сохранение конфигурации фильтров и сортировок с последующим обнолением компании.
 */
export const setCampaignConfigWithUpdateFx = createEffect(setCampaignConfigWithUpdateFactory());

/*
 * Обновляет сортировки.
 */
export const updateCampaignSortAndFilterFx = createEffect(
  ({ campaignId, withUpdateCampaign }: TUpdateCampaignParams) => {
    const requestParams: TSetPersonCampaignConfigParams = {
      campaignId,
      body: {
        sort: makeSortRequesData(
          $campaignSorts.getState(), // eslint-disable-line @typescript-eslint/no-use-before-define
          CampaignDealsSortName.Default
        ) as CampaignSortConfig,
        filter: makeFilterRequestData($campaignFilters.getState()) as CampaignFilterConfig, // eslint-disable-line @typescript-eslint/no-use-before-define
      },
    };

    if (withUpdateCampaign && !$searchTerm.getState()) {
      return setCampaignConfigWithUpdateFx(requestParams);
    }

    return setCampaignConfigFx(requestParams);
  }
);

/*
 * предыдущее состояние фильтров и сортировок
 * необходимо для возвращения фильтрам и сортировкам предыдущего состояние если произошла ошибка сохранения
 */
export const $prevSortsAndFiltersState = createStore<ISetPersonCampaignConfigRequestDto | null>(
  null
)
  .on(setCampaignConfigFx.done, (state, { params }) => params.body)
  .on(getCampaignDealsFx.doneData, (state, payload) => ({
    filter: payload.filter,
    sort: payload.sort,
  }));

/*
 * Текущие сортировки.
 */
export const $campaignSorts = createStore<TSortItem[]>([])
  .on($campaignStore, (state, payload) => {
    if (state.length) {
      return state;
    }

    if (!payload?.campaignDetails?.platform) return [];

    return getInitSorts(
      platformSortsMap[payload.campaignDetails.platform]! // eslint-disable-line @typescript-eslint/no-non-null-assertion
    );
  })
  .on(getCampaignDealsFx.doneData, (state, payload) =>
    payload.sort ? transformSortFromDTO(state, payload.sort) : []
  )
  .on(updateSort, (state, { sort, campaignId }) => {
    const changedSort = _differenceWith(sort, state, _isEqual);
    usersActionsLogService.log(
      changedSort[0]?.isVisible
        ? USERS_ACTIONS.CAMPAIGN_SORT_ADDED
        : USERS_ACTIONS.CAMPAIGN_SORT_DELETED,
      {
        campaignId,
        name: changedSort[0]?.id,
      }
    );

    return sort;
  })
  .on(setCampaignConfigFx.fail, (state, { error }) => {
    const prevSorts = $prevSortsAndFiltersState.getState()?.sort;
    // если призошла ошибка сохранения и если это не отмена запроса то возвращаем сортировкам предыдущее состояние
    if (axios.isCancel(error) || !prevSorts) return state;

    return transformSortFromDTO(state, prevSorts);
  })
  .reset(resetFiltersAndSort);

/*
 * Текущие фильтры.
 */
export const $campaignFilters = createStore<TFilterConfig[]>([])
  .on($campaignStore, (state, payload) => {
    if (state.length) {
      return state;
    }

    if (!payload?.campaignDetails?.platform) return [];

    return getInitFilters(
      platformFiltersMap[payload.campaignDetails.platform]! // eslint-disable-line @typescript-eslint/no-non-null-assertion
    );
  })
  .on(getCampaignDealsFx.doneData, (state, payload) =>
    payload.filter ? transformFiltersFromDTO(state, payload.filter) : []
  )
  .on(updateFilters, (state, { filter, campaignId }) => {
    const changedFilter = _differenceWith(filter, state, _isEqual);
    usersActionsLogService.log(
      changedFilter[0]?.isVisible
        ? USERS_ACTIONS.CAMPAIGN_FILTER_ADDED
        : USERS_ACTIONS.CAMPAIGN_FILTER_DELETED,
      {
        campaignId,
        name: changedFilter[0]?.id,
      }
    );

    return filter;
  })
  .on(setCampaignConfigFx.fail, (state, { error }) => {
    const prevFilters = $prevSortsAndFiltersState.getState()?.filter;
    // если призошла ошибка сохранения и если это не отмена запроса то возвращаем фильтрам предыдущее состояние
    if (axios.isCancel(error) || !prevFilters) return state;

    return transformFiltersFromDTO(state, prevFilters);
  })
  .reset(resetFiltersAndSort);

/*
 * Стор который показывает использовали ли мы фильтр в текущей сессии кампании. Сделан чтобы показывать фильтр
 *  и непоказывать пустую страницу кампании(ибо в момент отмены последнего фильтра мы получаем ситуацию с пустыми дилами и пустым фильтро,
 *  что является условием пустой кампании), а нам нужно все показывать дальше при фильтрации данных.
 */
export const $isFilterUsedStore = createStore<boolean>(false)
  .on(updateFilters, () => true)
  .reset(resetFiltersAndSort);

forward({
  from: [updateFilters, updateSort],
  to: [updateCampaignSortAndFilterFx],
});
