import cn from 'clsx';
import { FC, memo } from 'react';
import { getColorAvatar } from '@uikit/components/Avatar/avatar.utils';
import { Icon, ICON_TYPES } from '@uikit/components/Icon';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { usePreloadImage } from '@uikit/hooks/usePreloadImage';
import styles from './Avatar.module.scss';
import { AVATAR_SIZES, DEFAULT_AVATAR_SIZE } from './avatar.constants';

export type TAvatarProps = {
  img?: string;
  size?: AVATAR_SIZES;
  idForColor?: string;
  entityName?: string;
  className?: string;
  isDisabled?: boolean;
  onClick?: () => void;
};

export const Avatar: FC<TAvatarProps> = memo(
  ({
    img,
    size = DEFAULT_AVATAR_SIZE,
    idForColor,
    entityName = '',
    isDisabled,
    className,
    onClick,
  }) => {
    const loadedImage = usePreloadImage(img);

    if (img) {
      return (
        <div
          onClick={onClick}
          className={cn(
            styles.avatar,
            styles[`${size}Size`],
            !loadedImage && styles.avatar__avatarContainerEmpty,
            className
          )}
        >
          {loadedImage ? (
            <img
              src={img}
              className={styles[`${size}Size`]}
              alt={img}
            />
          ) : (
            <Icon
              type={ICON_TYPES.PERSON}
              style={ICON_STYLES.SECONDARY}
              size={16}
            />
          )}
        </div>
      );
    }

    return (
      <div
        className={cn(styles.avatar__colored, styles[`${size}Size`], className)}
        style={{ background: getColorAvatar(idForColor, isDisabled) }}
      >
        {!!entityName?.length && entityName[0]}
      </div>
    );
  }
);
