/*
 *   Модалка для добавления legacy entity
 */
import i18n from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ILegalEntityDto } from '@hypetrainCommon';
import { TDialogProps } from '@uikit/components/Dialog';
import { notificationsService } from '@uikit/components/Notification';
import { handleSubmitLegalEntityErrors } from '@pagesWorkspaceSettings/components/LegalEntity/LegalEntity.utils';
import { LegalEntityDialog } from '@pagesWorkspaceSettings/components/LegalEntity/components/LegalEntityDialog';
import {
  getLegalEntitiesFx,
  legalEntityCreationFx,
} from '@pagesWorkspaceSettings/components/LegalEntity/model';

type TLegalEntityDialogAddProps = {
  legalEntity?: ILegalEntityDto;
  hasResolve?: boolean;
};
export const LegalEntityDialogAdd: FC<TLegalEntityDialogAddProps & TDialogProps> = ({
  legalEntity,
  hasResolve = true,
  dialog,
}) => {
  const { t } = useTranslation();

  const addLegalEntity = (values: ILegalEntityDto): Promise<Record<string, string> | null> =>
    legalEntityCreationFx(values)
      .then(() => {
        getLegalEntitiesFx();
        hasResolve && dialog.resolve();
        notificationsService.successful({
          props: {
            caption: i18n.t('workspaceSettings.legalEntity.addSuccess'),
          },
        });
        return {};
      })
      .catch(handleSubmitLegalEntityErrors);

  return (
    <LegalEntityDialog
      legalEntity={legalEntity}
      action={addLegalEntity}
      buttonText={t('workspaceSettings.legalEntity.modal.create')}
      title={t('workspaceSettings.legalEntity.modal.addHeader')}
    />
  );
};
