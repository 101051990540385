/* Hook for selection column in all tables */
import _differenceBy from 'lodash/differenceBy';
import _map from 'lodash/map';

type TUseRowSelectParams = {
  displayedRows: unknown[];
  selectedRows: unknown[];
  onSelect: (params: string[]) => void;
  onUnselect: (params: string[]) => void;
  pathId?: string;
  withPartialSelection?: boolean;
};

export const useAllRowSelect = ({
  displayedRows,
  selectedRows,
  onSelect,
  onUnselect,
  pathId,
  withPartialSelection,
}: TUseRowSelectParams) => {
  const displayedUuids = pathId ? _map(displayedRows, pathId) : displayedRows;
  const selectedUuid = pathId ? _map(selectedRows, pathId) : selectedRows;
  const notSelectedRows = pathId
    ? _differenceBy(displayedRows, selectedRows, pathId)
    : _differenceBy(displayedRows, selectedRows);

  const isAnyChecked = () => displayedUuids.some((uuid) => selectedUuid.includes(uuid));

  const isAllChecked = () => displayedUuids.every((uuid) => selectedUuid.includes(uuid));

  const toggleAllRowSelection = () => {
    const notSelectedRowsUuids = _map(notSelectedRows, pathId);

    if (isAllChecked()) {
      onUnselect([]);
      return true;
    }

    if (isAnyChecked()) {
      withPartialSelection ? onUnselect([]) : onSelect(notSelectedRowsUuids);
      return true;
    }

    const areAllUnChecked = displayedUuids.every((uuid) => !selectedUuid.includes(uuid));

    if (areAllUnChecked) {
      onSelect(notSelectedRowsUuids);
      return true;
    }
  };

  return {
    isAllChecked,
    isAnyChecked,
    toggleAllRowSelection,
    notSelectedRows,
  };
};
