import webSocketService from './webSocket.service';

export { WebSocketService } from './webSocket.service';
export {
  TWebSocketMessageCallback,
  TWebSocketMessageHandler,
  TWebSocketMessageSelector,
} from './webSocket.types';

export default webSocketService;
