/*
 * Router for pages from Payments section.
 */
import { lazily } from 'react-lazily';
import { Navigate, Route } from 'react-router-dom';
import { Routes as Links } from 'router/Routes';
import { PrivateRoute } from '@router/PrivateRoute';
import { paymentUserAccessService } from '@services/userAccess/paymentsUserAccess.service';
import { PaymentAccountOutgoingTab } from '@pages/PaymentsPage/components/PaymentAccountPage/components/PaymentAccountOutgoingTab';
import { PaymentsContainer } from '@pages/PaymentsPage/components/PaymentsContainer';
import { PaymentAccountAllTransactionsTab } from '@pagesPaymentsPage/components/PaymentAccountPage/components/PaymentAccountAllTransactionsTab';
import { PaymentAccountIncomingTab } from '@pagesPaymentsPage/components/PaymentAccountPage/components/PaymentAccountIncomingTab';
import { PaymentsHistory } from '@pagesPaymentsPage/components/PaymentsHistory';
import { PaymentsReadyForPayment } from '@pagesPaymentsPage/components/PaymentsReadyForPayment';

const { PaymentsPage } = lazily(() => import('@pages/PaymentsPage'));
const { PaymentAccountPage } = lazily(
  () => import('@pagesPaymentsPage/components/PaymentAccountPage')
);

export const PaymentsRouter = (): JSX.Element | null => {
  if (!paymentUserAccessService.isAvailable) return null;

  return (
    <Route
      element={
        <PrivateRoute errorBoundaryName="paymentsContainer">
          <PaymentsContainer />
        </PrivateRoute>
      }
    >
      <Route
        path={Links.payments}
        element={<Navigate to={Links.paymentsReadyForPayment} />}
      />
      <Route
        element={
          <PrivateRoute errorBoundaryName="paymentsPage">
            <PaymentsPage />
          </PrivateRoute>
        }
      >
        <Route
          path={Links.paymentsReadyForPayment}
          element={
            <PrivateRoute errorBoundaryName="paymentsReadyForPayment">
              <PaymentsReadyForPayment />
            </PrivateRoute>
          }
        />
        <Route
          path={Links.paymentsHistory}
          element={
            <PrivateRoute errorBoundaryName="paymentsHistory">
              <PaymentsHistory />
            </PrivateRoute>
          }
        />
      </Route>

      <Route
        path={Links.paymentAccount}
        element={
          <PrivateRoute errorBoundaryName="paymentAccount">
            <PaymentAccountPage />
          </PrivateRoute>
        }
      >
        <Route
          path={Links.paymentAccountAll}
          element={
            <PrivateRoute errorBoundaryName="paymentAccountAllHistory">
              <PaymentAccountAllTransactionsTab />
            </PrivateRoute>
          }
        />
        <Route
          path={Links.paymentAccountIncoming}
          element={
            <PrivateRoute errorBoundaryName="paymentAccountCreaditHistory">
              <PaymentAccountIncomingTab />
            </PrivateRoute>
          }
        />
        <Route
          path={Links.paymentAccountOutgoing}
          element={
            <PrivateRoute errorBoundaryName="paymentAccountDebitsHistory">
              <PaymentAccountOutgoingTab />
            </PrivateRoute>
          }
        />
      </Route>
    </Route>
  );
};
