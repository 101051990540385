/*
 * Сервис для работы с урлами
 */
import { nanoid } from 'nanoid';
import { Location } from 'react-router-dom';

type TLocationChangeHandler = (location: Location, unsubscribe: () => void) => void;

class LocationService {
  private location: Location | null = null;

  private handlers: Record<string, TLocationChangeHandler> = {};

  /*
   * Инициализация сервиса, добавление location за которым он будет следить
   */
  public setNewLocation(location: Location): void {
    this.location = location;

    Object.entries(this.handlers).forEach(([id, handler]) => {
      const unsubscribe = () => {
        delete this.handlers[id];
      };

      handler(this.location!, unsubscribe); // eslint-disable-line @typescript-eslint/no-non-null-assertion
    });
  }

  /*
   * Подписка на изменение location, сервис будет вызывать переданный колбэк при изменении
   */
  public subscribe(cb: TLocationChangeHandler): () => void {
    const id = nanoid();

    this.handlers[id] = cb;

    return () => {
      delete this.handlers[id];
    };
  }
}
export const locationService = new LocationService();
