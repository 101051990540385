/*
 * Компонента для отображения системных сообщений в таблице communication
 *
 * */
import { FC } from 'react';
import { IGetSystemMessagesResponseDto } from '@hypetrainCommon';
import { AVATAR_SIZES, Avatar } from '@uikit/components/Avatar';
import { Tip } from '@uikit/components/Tip';
import { EmptyTextCommunication } from '@pagesCampaigns/CampaignDetailsPage/components/Communication/components/EmptyText';
import { getSystemMessage } from '@pagesCampaigns/CampaignDetailsPage/components/Communication/components/SystemMessage/systemMessage.utils';
import styles from './SystemMessage.module.scss';

type TSystemMessageProps = {
  message: IGetSystemMessagesResponseDto;
};

export const SystemMessage: FC<TSystemMessageProps> = ({ message }) => {
  if (getSystemMessage(message.systemMessageType)) {
    return (
      <div className={styles.systemMessage}>
        <Tip>
          <div className={styles.systemMessage__container}>
            <div className={styles.systemMessage__containerAvatar}>
              <Avatar
                idForColor={message.systemData.person.id}
                entityName={message.systemData.person.name}
                size={AVATAR_SIZES.XS}
              />
            </div>
            <div className={styles.systemMessage__containerName}>
              {message.systemData.person.name}
            </div>

            <div className={styles.systemMessage__container__message}>
              {getSystemMessage(message.systemMessageType)}
            </div>
          </div>
        </Tip>
      </div>
    );
  }
  return <EmptyTextCommunication />;
};
