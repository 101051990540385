import i18n from 'i18next';
import { FC } from 'react';
import {
  IGetSystemMessagesResponseDto,
  IGetUserMessagesResponseDto,
  SmaPlatform,
} from '@hypetrainCommon';
import { FILE_UPLOAD_STATUS } from '@uikit/components/Attach/AttachFile/attachFile.constants';
import { TAttachedFile } from '@uikit/components/Attach/AttachFile/attachFile.types';
import { Tip } from '@uikit/components/Tip';
import { LastMessageContent } from '@pagesCampaigns/CampaignDetailsPage/components/Communication/components/LastMessageContent';
import { SystemMessage } from '@pagesCampaigns/CampaignDetailsPage/components/Communication/components/SystemMessage';
import styles from './LastMessage.module.scss';

type TLastMessage = {
  hasContact: boolean;
  attachFiles?: TAttachedFile[];
  message?: IGetUserMessagesResponseDto | IGetSystemMessagesResponseDto;
  platform: SmaPlatform;
  countPerson: number | undefined;
};

const PLATFORM_TEXT: Partial<Record<SmaPlatform, string>> = {
  [SmaPlatform.Youtube]: i18n.t('communication.lastMessage.noContact'),
  [SmaPlatform.Instagram]: i18n.t('communication.lastMessage.noContactInstagram'),
  [SmaPlatform.Tiktok]: i18n.t('communication.lastMessage.noContactTikTok'),
  [SmaPlatform.Twitch]: '',
  [SmaPlatform.Twitter]: '',
  [SmaPlatform.Facebook]: '',
};

export const LastMessage: FC<TLastMessage> = ({
  hasContact,
  attachFiles,
  message,
  platform,
  countPerson,
}) => {
  if (attachFiles?.length) {
    attachFiles.map((file) => ({ ...file, status: FILE_UPLOAD_STATUS.DONE }));
  }

  if (!hasContact) {
    const noContactText = PLATFORM_TEXT[platform];

    return <Tip className={styles.lastMessage__noContact}>{noContactText}</Tip>;
  }

  // Если у нас всего 2 персоны и нет сообщения(его не должно быть), то отображаем просто пустоту.
  if (countPerson === 2 && message && !('data' in message)) {
    return null;
  }

  if (message && !('data' in message)) {
    return <SystemMessage message={message} />;
  }

  if (hasContact || (message && 'data' in message)) {
    return (
      <LastMessageContent
        message={message as IGetUserMessagesResponseDto}
        attachFiles={attachFiles}
      />
    );
  }

  return null;
};
