/*
 * Модель Legal Entity
 */
import { Store, createEffect, createEvent, createStore } from 'effector/compat';
import { ILegalEntityInfoResponseDto } from '@hypetrainCommon';
import { workspaceApiService } from '@api/workspace';

/*
 * Создание legal entity
 */
export const legalEntityCreationFx = createEffect(workspaceApiService.createLegalEntity);

/*
 * Получение legal entities
 */
export const getLegalEntitiesFx = createEffect(workspaceApiService.getLegalEntities);

/*
 * Удаление legal entities
 */
export const deleteLegalEntitiesFx = createEffect(workspaceApiService.deleteLegalEntities);

/*
 * Обновление legal entity по id
 */
export const updateLegalEntityFx = createEffect(workspaceApiService.updateLegalEntity);

export const resetLegalEntities = createEvent();

/*
 * Стор для хранения legal entities
 */
export const $legalEntitiesStore: Store<ILegalEntityInfoResponseDto[]> = createStore(
  [] as ILegalEntityInfoResponseDto[]
)
  .on(getLegalEntitiesFx.doneData, (state, payload) => payload)
  .reset(resetLegalEntities);
