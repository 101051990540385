/*
 * Коимпонент активации платёжного модуля в приложении.
 */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StartProcess } from 'hypetrain-ui/src/components/StartProcess';
import welcomePayment from '@assets/images/welcomePayment.svg';
import { Routes } from '@router/Routes';
import {
  activatePaymentsModuleFx,
  getPaymentAccountsFx,
} from '@pagesPaymentsPage/paymentsAccounts.model';
import styles from './WelcomeToPayments.module.scss';

export const WelcomeToPayments: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const activatePaymentsModule = () => {
    activatePaymentsModuleFx()
      .then(() => getPaymentAccountsFx())
      .then(() => navigate(Routes.paymentsReadyForPayment));
  };

  return (
    <StartProcess
      className={styles.welcomeToPayments}
      img={welcomePayment}
      title={t('welcomePayment.title')}
      description={t('welcomePayment.description')}
      buttons={[{ text: t('welcomePayment.buttonText'), onClick: () => activatePaymentsModule() }]}
    >
      <ul className={styles.welcomeToPayments__list}>
        <li>{t('welcomePayment.firstPoint')}</li>
        <li>{t('welcomePayment.secondPoint')}</li>
        <li>{t('welcomePayment.thirdPoint')}</li>
      </ul>
    </StartProcess>
  );
};
