/*
 *   Компонент для отрисовки кнопки добавления для legal entity карточки
 */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '@uikit/components/Buttons';
import { dialogService } from '@uikit/components/Dialog';
import { ICON_TYPES } from '@uikit/components/Icon';
import { LegalEntityDialogAdd } from '@pagesWorkspaceSettings/components/LegalEntity/components/LegalEntityDialog/components/LegalEntityDialogAdd';
import styles from './LegalEntityAddAddress.module.scss';

export const LegalEntityAddAddress: FC = () => {
  const { t } = useTranslation();

  const openModal = () => {
    dialogService.open({
      component: LegalEntityDialogAdd,
    });
  };

  return (
    <div
      className={styles.legalEntityAddAddress}
      onClick={openModal}
    >
      <Button
        buttonType={BUTTON_TYPE.SECONDARY}
        icon={ICON_TYPES.PLUS}
        size={BUTTON_SIZE.L}
      />
      <div className={styles.legalEntityAddAddress__text}>
        {t('workspaceSettings.legalEntity.addButton')}
      </div>
    </div>
  );
};
