/*
 * Константы API-сервиса подписок.
 */

/*
 * Коды ошибок оплаты при обновлении подписок.
 */
export const UPDATE_SUBSCRIPTION_PAYMENT_ERRORS = [
  'billing--payment-failed',
  'billing--payment-method-required',
];

/*
 * Ошибка определения локации юзера от страйпа.
 */
export const WRONG_TAX_LOCATION = 'billing--wrong-tax-location';
