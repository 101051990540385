/*
 * Компонент чекбокса для выбора платежа.
 */
import { FC, memo } from 'react';
import { IPaymentListItemDto } from '@hypetrainCommon';
import { SelectRowCheckbox } from '@uikit/components/Table/components/columns/SelectRowCheckbox';
import { useSelectedPayments } from '@pagesPaymentsPage/hooks/useSelectedPayments.hook';
import { selectPayment, unselectPayment } from '@pagesPaymentsPage/payments.model';
import styles from './SelectPaymentCheckbox.module.scss';

type TSelectPaymentCheckboxProps = {
  payment: IPaymentListItemDto;
};

export const SelectPaymentCheckbox: FC<TSelectPaymentCheckboxProps> = memo(({ payment }) => {
  const selectedPayments = useSelectedPayments();

  return (
    <SelectRowCheckbox
      className={styles.selectPaymentCheckbox}
      id={payment?.id}
      pathId="id"
      selectedRows={selectedPayments}
      onUnselect={() => unselectPayment(payment)}
      onSelect={() => selectPayment(payment)}
      withPartialSelection
    />
  );
});
