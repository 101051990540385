/*
 * Abstract sorts component.
 */
import cn from 'clsx';
import { FC, memo } from 'react';
import { SortOrder } from '@hypetrainCommon';
import { Icon, ICON_TYPES } from '@uikit/components/Icon';
import { OptionsToggler, TOptionsToggleItem } from '@uikit/components/OptionsToggler';
import { getOptionsTogglerValue } from '@uikit/components/OptionsToggler/optionsToggler.utils';
import { SortToggler } from '@uikit/components/SortToggler';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import styles from './Sorts.module.scss';
import { TSortItem } from './sorts.types';
import {
  getHideActiveSort,
  getSortsSwitchers,
  getSortsWithUpdatedVisibility,
  getUpdatedSorts,
} from './sorts.utils';

type TSortsProps = {
  sorts: TSortItem[];
  onSortChange: (sorts: TSortItem[], needUpdateTableData: boolean) => void;
  withSortsManagment?: boolean;
  className?: string;
};

export const Sorts: FC<TSortsProps> = memo(
  ({ sorts, onSortChange, withSortsManagment = true, className }) => {
    const { t } = useUiKitTranslation();
    const sortsSwitchers: TOptionsToggleItem[] = getSortsSwitchers(sorts);
    const hasVisibleSorts = sortsSwitchers.some((sort) => sort.selected);

    const onSortChangeHandler = (changedSortId: string, order: SortOrder | null): void => {
      const updatedSorts = getUpdatedSorts(sorts, changedSortId, order);

      onSortChange(updatedSorts, true);
    };

    const onChangeVisibility = (switchers: TOptionsToggleItem[]): void => {
      const visibilitySortsState = getOptionsTogglerValue(switchers);
      const updatedSorts = getSortsWithUpdatedVisibility(sorts, visibilitySortsState);
      const hideActiveSort = getHideActiveSort(sorts, visibilitySortsState);

      onSortChange(updatedSorts, !!hideActiveSort); // обновляем таблицу если была скрыта активная сортировка (в этом случае она сбрасывается)
    };

    return (
      <div className={cn(styles.sorts, className)}>
        <Icon
          className={styles.sorts__icon}
          type={ICON_TYPES.SORT}
        />

        <div className={styles.sorts__container}>
          {sorts.map(
            (sort) =>
              sort.isVisible && (
                <SortToggler
                  key={sort.id}
                  name={sort.label}
                  order={sort.order}
                  isDisabled={sort.isDisabled}
                  toggleMode={sort.toggleMode}
                  onToggle={(order) => onSortChangeHandler(sort.id, order)}
                  testId={sort.testId}
                />
              )
          )}

          {withSortsManagment && (
            <OptionsToggler
              className={styles.sorts__options}
              options={sortsSwitchers}
              onChange={onChangeVisibility}
              title={t('sorts.sortingLabel')}
              text={hasVisibleSorts ? '' : t('sorts.addSorting')}
            />
          )}
        </div>
      </div>
    );
  }
);
