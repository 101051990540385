/*
 * Root API-service.
 * Storage shared reusable properties/entities that use with api-request.
 * Contains shared api-methods.
 */
import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { nanoid } from 'nanoid';
import { IWorkspaceInfoResponseDto, SmaPlatform } from '@hypetrainCommon';
import { LoaderService, loaderService } from '@uikit/components/Loader';
import { NotificationsService, notificationsService } from '@uikit/components/Notification';
import { TBackendError, TRequestSettings } from '@api/api.types';
import i18n from '@services/i18n';
import { getErrorMessageSlug, isloggableError, logAPIError } from '@utils/api.utils';

axios.defaults.headers.common['Content-Type'] = 'application/json';

const DEFAULT_REQUEST_SETTINGS: TRequestSettings = {
  showLoader: true,
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
/* eslint-disable arrow-body-style */
export class ApiService {
  constructor(readonly notifications: NotificationsService, readonly loader: LoaderService) {}

  protected get baseUrl(): string {
    return process.env.BASE_URL;
  }

  /*
   * Активный workspace.
   */
  public activeWorkspace: IWorkspaceInfoResponseDto | null = null;

  /*
   * Активная платформа.
   */
  public activeSocialPlatform: SmaPlatform | null = null;

  /*
   * Устанавливает активный workspace.
   */
  public setActiveWorkspace = (workspace: IWorkspaceInfoResponseDto): void => {
    this.activeWorkspace = workspace;
  };

  /*
   * Устанавливаем активную платформу.
   */
  public setActiveSocialPlatform = (platform: SmaPlatform): void => {
    this.activeSocialPlatform = platform;
  };

  /*
   * Выполняет GET-запрос.
   */
  public get = <T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config: AxiosRequestConfig<D> = {},
    settings: TRequestSettings = DEFAULT_REQUEST_SETTINGS
  ): Promise<R | AxiosResponse<T>> => {
    const requestSettings = { ...DEFAULT_REQUEST_SETTINGS, ...settings };

    if (requestSettings.showLoader) {
      this.loader.show();
    }

    return axios
      .get<T, R, D>(`${this.baseUrl}${url}`, {
        ...config,
        headers: this.transformHeaders(config?.headers || {}),
      })
      .catch((e) => {
        return this.handleRequestError(e, requestSettings);
      })
      .finally(() => this.finnalyRequestHandler(requestSettings));
  };

  /*
   * Выполняет POST-запрос.
   */
  public post = <T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config: AxiosRequestConfig<D> = {},
    settings: TRequestSettings = DEFAULT_REQUEST_SETTINGS
  ): Promise<R | AxiosResponse<T>> => {
    const requestSettings = { ...DEFAULT_REQUEST_SETTINGS, ...settings };

    if (requestSettings.showLoader) {
      this.loader.show();
    }

    return axios
      .post<T, R, D>(`${this.baseUrl}${url}`, data, {
        ...config,
        headers: this.transformHeaders(config?.headers || {}),
      })
      .catch((e) => this.handleRequestError(e, requestSettings))
      .finally(() => this.finnalyRequestHandler(requestSettings));
  };

  /*
   * Выполняет PATCH-запрос.
   */
  public patch = <T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config: AxiosRequestConfig<D> = {},
    settings: TRequestSettings = DEFAULT_REQUEST_SETTINGS
  ): Promise<R | AxiosResponse<T>> => {
    const requestSettings = { ...DEFAULT_REQUEST_SETTINGS, ...settings };

    if (requestSettings.showLoader) {
      this.loader.show();
    }

    return axios
      .patch<T, R, D>(`${this.baseUrl}${url}`, data, {
        ...config,
        headers: this.transformHeaders(config?.headers || {}),
      })
      .catch((e) => this.handleRequestError(e, requestSettings))
      .finally(() => this.finnalyRequestHandler(requestSettings));
  };

  /*
   * Выполняет PUT-запрос.
   */
  public put = <T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config: AxiosRequestConfig<D> = {},
    settings: TRequestSettings = DEFAULT_REQUEST_SETTINGS
  ): Promise<R | AxiosResponse<T>> => {
    const requestSettings = { ...DEFAULT_REQUEST_SETTINGS, ...settings };

    if (requestSettings.showLoader) {
      this.loader.show();
    }

    return axios
      .put<T, R, D>(`${this.baseUrl}${url}`, data, {
        ...config,
        headers: this.transformHeaders(config?.headers || {}),
      })
      .catch((e) => this.handleRequestError(e, requestSettings))
      .finally(() => this.finnalyRequestHandler(requestSettings));
  };

  /*
   * Выполняет DELETE-запрос.
   */
  public deleteMethod = <T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config: AxiosRequestConfig<D> = {},
    settings: TRequestSettings = DEFAULT_REQUEST_SETTINGS
  ): Promise<R | AxiosResponse<T>> => {
    const requestSettings = { ...DEFAULT_REQUEST_SETTINGS, ...settings };

    if (requestSettings.showLoader) {
      this.loader.show();
    }

    return axios
      .delete<T, R, D>(`${this.baseUrl}${url}`, {
        ...config,
        headers: this.transformHeaders(config?.headers || {}),
      })
      .catch((e) => this.handleRequestError(e, requestSettings))
      .finally(() => this.finnalyRequestHandler(requestSettings));
  };

  /*
   * Обработчик ошибок для API-запросов.
   * Отображает ошибку, если это не запрещено настройками переданными при выполнении запроса.
   */
  private handleRequestError<R = any>(
    error: TBackendError,
    settings: TRequestSettings = DEFAULT_REQUEST_SETTINGS
  ): Promise<R> {
    const errorMessageSlug = getErrorMessageSlug(error, settings);

    if (errorMessageSlug) {
      this.notifications.error({
        props: {
          caption: i18n.t(errorMessageSlug),
          description: i18n.exists(`${errorMessageSlug}_description`)
            ? i18n.t(`${errorMessageSlug}_description`)
            : '',
        },
      });

      if (isloggableError()) {
        logAPIError(error, errorMessageSlug);
      }
    }

    return Promise.reject(error);
  }

  private finnalyRequestHandler(settings: TRequestSettings): void {
    if (settings.showLoader) {
      this.loader.hide();
    }
  }

  private transformHeaders(headers: AxiosRequestHeaders): AxiosRequestHeaders {
    return {
      ...(headers || {}),
      'x-request-id': nanoid(),
    };
  }
}

export const apiService: ApiService = new ApiService(notificationsService, loaderService);
