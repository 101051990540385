/*
 * Конфигурация для фильтров в кампании инстагарама
 */
import {
  Age,
  DayNumber,
  Gender,
  InfluencerGender,
  InstagramAudienceAgesFilter,
  InstagramAudienceGendersFilter,
  InstagramCampaignFilterName,
  InstagramEngagementRateFilter,
  InstagramFilterName,
  InstagramGenderFilter,
  InstagramHasContactFilter,
  InstagramReplyStatusCampaignFilter,
} from '@hypetrainCommon';
import { FILTER_TYPES } from '@uikit/components/Filter';
import { TCheckboxFilterConfig } from '@uikit/components/Filter/components/CheckboxFilter';
import { TRadioGroupFilterConfig } from '@uikit/components/Filter/components/RadioGroupFilter';
import {
  RANGE_TYPES,
  TRangeConfig,
} from '@uikit/components/Filter/components/RangeFilter/RangeFilter.types';
import {
  DEFAULT_RANGE_SELECT_FROM_OPTIONS,
  DEFAULT_RANGE_SELECT_TO_OPTIONS,
} from '@uikit/components/Filter/components/RangeFilter/components/RangeSelect/rangeSelect.constants';
import { TSelectFilterConfig } from '@uikit/components/Filter/components/SelectFilter';
import { TAvailableFilter } from '@uikit/components/Filters';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { stringToBoolean } from '@uikit/components/Filters/filters.utils';
import { TOptionsTogglerGroup } from '@uikit/components/OptionsToggler';
import i18n from '@services/i18n';
import { getCountryOptions } from '@constants/countries';
import { getLanguagesFx } from '@models/languages';

export enum CAMPAIGN_FILTERS_GROUPS {
  INFLUENCER = 'INFLUENCER',
  AUDIENCE = 'AUDIENCE',
}

export const CAMPAIGN_FILTERS_GROUPS_CONFIG: TOptionsTogglerGroup<CAMPAIGN_FILTERS_GROUPS>[] = [
  {
    id: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
    label: 'Influencer',
  },
  {
    id: CAMPAIGN_FILTERS_GROUPS.AUDIENCE,
    label: 'Audience',
  },
];

const engagementRateFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: InstagramFilterName.EngagementRate,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  filterType: FILTER_TYPES.SELECT,
  label: i18n.t('filters.instagram.engagementRate'),
  popupTitle: i18n.t('filters.instagram.engagementRateLabel'),
  description: i18n.t('filters.instagram.engagementRateDescription'),
  testId: 'IG_ER_FILTER',
  filterProps: {
    placeholder: i18n.t('filters.instagram.engagementRatePlaceholder'),
    isMulti: false,
    options: Array.from({ length: 100 }, (_, i) => ({
      label: `>${i + 1}%`,
      value: `${(i + 1) / 100}`,
    })),
  },
  formatValueToDTO: (filter: TFilter): InstagramEngagementRateFilter => ({
    name: InstagramFilterName.EngagementRate,
    min: Number(filter.values?.[0]),
    value: DayNumber.Thirtieth,
  }),
  formatValueFromDTO: (filter: InstagramEngagementRateFilter): TFilter => ({
    name: filter.name,
    values: [filter.min?.toString() || ''],
  }),
};

const followersFilterConfig: TAvailableFilter<TRangeConfig> = {
  id: InstagramFilterName.FollowersCount,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  filterType: FILTER_TYPES.RANGE,
  label: i18n.t('filters.instagram.followers'),
  testId: 'IG_FOLLOWERS_COUNT_FILTER',
  filterProps: {
    type: RANGE_TYPES.SELECT,
    fromOptions: DEFAULT_RANGE_SELECT_FROM_OPTIONS,
    toOptions: DEFAULT_RANGE_SELECT_TO_OPTIONS,
    from: {
      placeholder: '1k',
    },
    to: {
      placeholder: '>1m',
    },
  },
};

const influencerLocationFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: InstagramFilterName.Country,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  switcherLabel: i18n.t('filters.instagram.locationInGroup'),
  filterType: FILTER_TYPES.SELECT,
  popupTitle: i18n.t('filters.instagram.locationLabel'),
  label: i18n.t('filters.instagram.influencerLocation'),
  description: i18n.t('filters.instagram.influencerLocationDescription'),
  testId: 'IG_COUNTRY_FILTER',
  filterProps: {
    isMulti: true,
    placeholder: i18n.t('filters.instagram.locationPlaceholder'),
    options: getCountryOptions(),
  },
};

const influencerLanguageFilterConfig: TAvailableFilter = {
  id: InstagramFilterName.Language,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  switcherLabel: i18n.t('filters.instagram.languageInGroup'),
  filterType: FILTER_TYPES.ASYNC_SELECT,
  label: i18n.t('filters.instagram.influencerLanguage'),
  popupTitle: i18n.t('filters.instagram.influencerLanguageLabel'),
  testId: 'IG_LANGUAGE_FILTER',
  filterProps: {
    isMulti: false,
    placeholder: i18n.t('filters.instagram.influencerLanguagePlaceholder'),
    loadOptions: async () => {
      const responseData = await getLanguagesFx();
      return responseData?.map(({ name, code }) => ({
        value: code,
        label: name,
      }));
    },
  },
};

const influencerGendersFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: InstagramFilterName.Gender,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  switcherLabel: i18n.t('filters.instagram.genderInGroup'),
  popupTitle: i18n.t('filters.instagram.genderRadioLabel'),
  filterType: FILTER_TYPES.RADIO,
  label: i18n.t('filters.instagram.influencerGenders'),
  testId: 'IG_GENDER_FILTER',
  filterProps: {
    defaultValue: null,
    options: [
      {
        label: i18n.t('filters.instagram.any'),
        value: null,
      },
      {
        label: i18n.t('filters.instagram.male'),
        value: InfluencerGender.Male,
      },
      {
        label: i18n.t('filters.instagram.female'),
        value: InfluencerGender.Female,
      },
      {
        label: i18n.t('filters.instagram.maleOrFemale'),
        value: InfluencerGender.Known,
        tooltip: i18n.t('filters.instagram.maleOrFemaleTooltip'),
      },
      {
        label: i18n.t('filters.instagram.neutral'),
        value: InfluencerGender.Unknown,
        tooltip: i18n.t('filters.instagram.neutralTooltip'),
      },
    ],
  },
  formatValueToDTO: (filter: TFilter): InstagramGenderFilter => ({
    name: InstagramFilterName.Gender,
    value: filter.values?.[0] as InfluencerGender,
  }),
};

const haveContactFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: InstagramFilterName.HasContact,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  filterType: FILTER_TYPES.RADIO,
  label: i18n.t('filters.instagram.hasEmail'),
  testId: 'IG_HAS_CONTACT_FILTER',
  filterProps: {
    defaultValue: null,
    options: [
      {
        label: i18n.t('filters.instagram.withEmail'),
        value: 'true',
      },
      {
        label: i18n.t('filters.instagram.withoutEmail'),
        value: 'false',
      },
    ],
  },
  formatValueToDTO: (filter: TFilter): InstagramHasContactFilter => ({
    name: InstagramFilterName.HasContact,
    value: stringToBoolean(filter.values?.[0] || ''),
  }),
};

const audienceGendersFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: InstagramFilterName.AudienceGenders,
  group: CAMPAIGN_FILTERS_GROUPS.AUDIENCE,
  switcherLabel: i18n.t('filters.instagram.genderInGroup'),
  filterType: FILTER_TYPES.RADIO,
  popupTitle: i18n.t('filters.instagram.genderRadioLabel'),
  label: i18n.t('filters.instagram.audienceGenders'),
  testId: 'IG_AUDIENCE_GENDER_FILTER',
  filterProps: {
    hasPercentage: true,
    defaultValue: null,
    options: [
      {
        label: i18n.t('filters.instagram.any'),
        value: null,
      },
      {
        label: i18n.t('filters.instagram.male'),
        value: 'male',
      },
      {
        label: i18n.t('filters.instagram.female'),
        value: 'female',
      },
    ],
  },
  filterInitialValue: {
    name: InstagramFilterName.AudienceGenders,
    min: 0.6,
    max: 1,
  },
  filterValue: {
    name: InstagramFilterName.AudienceGenders,
    min: 0.6,
    max: 1,
  },
  formatValueToDTO: (filter: TFilter): InstagramAudienceGendersFilter => ({
    name: InstagramFilterName.AudienceGenders,
    min: filter.min,
    max: filter.max,
    value: filter.values?.[0] as Gender,
  }),
};

const audienceAgesFilterConfig: TAvailableFilter<TCheckboxFilterConfig> = {
  id: InstagramFilterName.AudienceAges,
  group: CAMPAIGN_FILTERS_GROUPS.AUDIENCE,
  switcherLabel: i18n.t('filters.instagram.agesInGroup'),
  filterType: FILTER_TYPES.CHECKBOX_GROUP,
  popupTitle: i18n.t('filters.instagram.audienceAges'),
  label: i18n.t('filters.instagram.audienceAges'),
  testId: 'IG_AUDIENCE_AGES_FILTER',
  filterProps: {
    hasPercentage: true,
    isAge: true,
    options: {
      '13–17': false,
      '18–24': false,
      '25–34': false,
      '35–44': false,
      '45–64': false,
      '65+': false,
    },
  },
  filterInitialValue: {
    name: InstagramFilterName.AudienceAges,
    min: 0.2,
    max: 1,
  },
  filterValue: {
    name: InstagramFilterName.AudienceAges,
    min: 0.2,
    max: 1,
  },
  formatValueToDTO: (filter: TFilter): InstagramAudienceAgesFilter => ({
    name: InstagramFilterName.AudienceAges,
    min: filter.min,
    max: filter.max,
    value: filter.values?.[0] === '65-65+' ? Age.From65To65Plus : (filter.values?.[0] as Age),
  }),
  formatValueFromDTO: (filter: InstagramAudienceAgesFilter): TFilter => ({
    name: filter.name,
    min: filter.min,
    max: filter.max,
    values: filter.value === Age.From65To65Plus ? ['65-65+'] : [filter.value],
  }),
};

const audienceLocationFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: InstagramFilterName.AudienceCountries,
  group: CAMPAIGN_FILTERS_GROUPS.AUDIENCE,
  switcherLabel: i18n.t('filters.instagram.locationInGroup'),
  filterType: FILTER_TYPES.SELECT,
  popupTitle: i18n.t('filters.instagram.locationLabel'),
  label: i18n.t('filters.instagram.audienceLocations'),
  infoTooltip: i18n.t('filters.instagram.audienceLocationTooltipText'),
  testId: 'IG_AUDIENCE_COUNTRIES_FILTER',
  filterProps: {
    isMulti: true,
    maxValues: 3,
    placeholder: i18n.t('filters.instagram.locationPlaceholder'),
    options: getCountryOptions(),
    hasPercentage: true,
    percentageFirstLabel: i18n.t('countriesFirstLabel'),
    percentageLastLabel: i18n.t('instagramCountriesSecondLabel'),
  },
  filterInitialValue: {
    name: InstagramFilterName.AudienceCountries,
    min: 0.2,
    max: 1,
  },
  filterValue: {
    name: InstagramFilterName.AudienceCountries,
    min: 0.2,
    max: 1,
  },
};

const replyStatusFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: InstagramCampaignFilterName.ReplyStatus,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  filterType: FILTER_TYPES.RADIO,
  label: i18n.t('filters.instagram.replyStatus'),
  testId: 'IG_REPLY_STATUS_FILTER',
  filterProps: {
    defaultValue: null,
    options: [
      {
        label: i18n.t('filters.instagram.replied'),
        value: 'true',
      },
      {
        label: i18n.t('filters.instagram.notReplied'),
        value: 'false',
      },
    ],
  },
  formatValueToDTO: (filter: TFilter): InstagramReplyStatusCampaignFilter => ({
    name: InstagramCampaignFilterName.ReplyStatus,
    value: stringToBoolean(filter.values?.[0] || ''),
  }),
};

export const AVAILABLE_INSTAGRAM_CAMPAIGN_FILTERS_CONFIG: TAvailableFilter[] = [
  followersFilterConfig,
  engagementRateFilterConfig,
  influencerLocationFilterConfig,
  influencerGendersFilterConfig,
  influencerLanguageFilterConfig,
  haveContactFilterConfig,
  replyStatusFilterConfig,
  audienceLocationFilterConfig,
  audienceAgesFilterConfig,
  audienceGendersFilterConfig,
];
