/*
 * Utils for addressBook module.
 */
import { TBackendError } from '@api';
import { IPersonInfoResponseDto, PersonRegistrationType, PersonStatus } from '@hypetrainCommon';
import i18n from '@services/i18n';
import { getErrorMessageSlug } from '@utils/api.utils';
import { isWorkspaceAdmin, isWorkspaceMember, isWorkspaceTeammate } from '@utils/workspace.utils';
import { CONTACT_ROLE } from './addressBook.constants';

export const makeEmailIsNotUniqueError = (error: TBackendError): string => {
  const errorMessage = getErrorMessageSlug(error) || '';
  const personName = error?.response?.data.data?.personName;

  return `${i18n.t(errorMessage)} (${personName})`;
};

export const getAddressBookRole = (person: IPersonInfoResponseDto): CONTACT_ROLE | null => {
  if (isWorkspaceTeammate(person) && isWorkspaceAdmin(person)) {
    return CONTACT_ROLE.ADMIN;
  }

  if (isWorkspaceMember(person)) {
    return CONTACT_ROLE.MEMBER;
  }

  if (person.registrationType === PersonRegistrationType.representative) {
    return CONTACT_ROLE.REPRESENTATIVE;
  }

  return null;
};

/*
 * Возвращает указанную при создании персоны должность, если она есть.
 */
export const getJobTitle = (person: IPersonInfoResponseDto): string => {
  if (person?.jobTitle) {
    return person.jobTitle;
  }

  if (person?.status === PersonStatus.pending) {
    return i18n.t('invitedPerson');
  }

  return '';
};
