/*
 * Sorts configuration for browse page.
 */
import { YoutubeSmaStatisticsSortName } from '@hypetrainCommon';
import { TAvailableSort } from '@uikit/components/Sorts/sorts.types';
import i18n from '@services/i18n';

export const BROWSE_PAGE_AVAILABLE_SORTS_CONFIG: TAvailableSort[] = [
  {
    id: YoutubeSmaStatisticsSortName.Title,
    label: i18n.t('channelName'),
    selected: true,
    testId: 'YT_CHANNEL_NAME_SORT',
  },
  {
    id: YoutubeSmaStatisticsSortName.SubscriberCount,
    label: i18n.t('subscriberCount'),
    selected: true,
    testId: 'YT_SUBSCRIBERS_COUNT_SORT',
  },
  {
    id: YoutubeSmaStatisticsSortName.TargetAudienceShare,
    label: i18n.t('targetAudienceShare'),
    selected: true,
    isDisabled: true,
    testId: 'YT_TARGET_AUDIENCE_SORT',
  },
  {
    id: YoutubeSmaStatisticsSortName.Reach,
    label: i18n.t('viewsSortLabel'),
    selected: true,
    extra: { day: '30' },
    testId: 'YT_REACH_SORT',
  },
  {
    id: YoutubeSmaStatisticsSortName.EngagementRate,
    label: i18n.t('engagementRate'),
    selected: true,
    extra: { day: '30' },
    testId: 'YT_ER_SORT',
  },
  {
    id: YoutubeSmaStatisticsSortName.ProjectedCPM,
    label: i18n.t('cpmCaplLock'),
    selected: true,
    extra: { day: '30' },
    testId: 'YT_CPM_SORT',
  },
];
