// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rootWrapper__kzi0r{display:flex;flex-direction:column;height:100%;background:#f0f1f1}.rootWrapper__contentError__DZb0m{height:150px}", "",{"version":3,"sources":["webpack://./router/Router.module.scss"],"names":[],"mappings":"AAEA,oBACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,kBAAA,CAEA,kCACE,YAAA","sourcesContent":["@import '../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.rootWrapper {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  background: #f0f1f1;\n\n  &__contentError {\n    height: 150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootWrapper": "rootWrapper__kzi0r",
	"rootWrapper__contentError": "rootWrapper__contentError__DZb0m"
};
export default ___CSS_LOADER_EXPORT___;
