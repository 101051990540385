import { SmaPlatform } from '@hypetrainCommon';
import { TFilterConfig } from '@uikit/components/Filter';
import { TColumn } from '@uikit/components/Table/table.types';
import { getCampaignTikTokColumns } from '@pagesCampaigns/CampaignDetailsPage/components/DataAnalytics/DataAnalyticsTikTok.columns';
import { getCampaignInstagramColumns } from './DataAnalyticsInstagram.columns';
import { getCampaignsYoutubeColumns } from './DataAnalyticsYoutube.column';

/*
 Получаем колонки в зависимости от выбраной платформы
 используется на странице кампании с дилами(блогерами)
 */
export const getPlatformCampaignColumnsMap = (
  filters: TFilterConfig[]
): Partial<Record<SmaPlatform, TColumn[]>> => ({
  [SmaPlatform.Youtube]: getCampaignsYoutubeColumns(filters),
  [SmaPlatform.Instagram]: getCampaignInstagramColumns(filters),
  [SmaPlatform.Tiktok]: getCampaignTikTokColumns(filters),
});
