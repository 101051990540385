/*
 * Constants of addressBook module.
 */

export const EMAIL_IS_NOT_UNIQUE_ERROR_NUMBER = 1509;

export const CONTACTS_ON_PAGE = 23;

export enum CONTACT_ROLE {
  ADMIN = 'admin',
  MEMBER = 'member',
  REPRESENTATIVE = 'representative',
}
