/*
 * Модель профиля TikTok.
 */
import { createEffect, createEvent, createStore } from 'effector/compat';
import i18n from 'i18next';
import { ITiktokSmaResponseDto } from '@hypetrainCommon';
import { notificationsService } from '@uikit/components/Notification';
import { explorationApiServiceNew } from '@api/exploration';
import { PROFILE_ERROR_SLUGS } from '@api/sma/sma.api.constants';

/*
 * Сброс профиля TikTok.
 */
export const resetTikTokProfile = createEvent<void>();

/*
 * Получение профиля TikTok.
 */
export const getTikTokProfileFx = createEffect(({ id, title }: { id: string; title: string }) =>
  explorationApiServiceNew.getTikTokSmaProfile(id).catch((error) => {
    if (PROFILE_ERROR_SLUGS.includes(error.response?.data?.slug)) {
      notificationsService.error({
        timeout: 10000,
        props: {
          caption: title
            ? i18n.t('profile.updateProfileErrorWithTitle', { title })
            : i18n.t('profile.updateProfileError'),
          description: i18n.t('profile.updateProfileErrorDescription'),
        },
      });
    }

    throw error;
  })
);

/*
 * Стор профиля TikTok.
 */
export const $profileTikTokStore = createStore<ITiktokSmaResponseDto>({} as ITiktokSmaResponseDto)
  .on(getTikTokProfileFx.doneData, (_, payload) => payload)
  .reset(resetTikTokProfile);
