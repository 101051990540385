/*
 * Шаг с ошибкой в модальном окне проводения платежей.
 */
import { useStore } from 'effector-react/compat';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrencyFn } from '@uikit/components/Formats/FormatCurrency';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { InfoDialogTemplate } from '@uikit/components/templates/InfoDialogTemplate';
import {
  $calculatedPaymentsSummary,
  $paymentsCurrencyFilter,
} from '@pagesPaymentsPage/payments.model';
import styles from './ReviewPaymentsFailStep.module.scss';

export const ReviewPaymentsFailStep: FC = () => {
  const { t } = useTranslation();
  const currency = useStore($paymentsCurrencyFilter);
  const paymentsTotalAndFees = useStore($calculatedPaymentsSummary);

  const data = paymentsTotalAndFees?.[currency];
  const total = data?.total;

  return (
    <InfoDialogTemplate
      className={styles.reviewPaymentsFailStep}
      icon={
        <Icon
          type={ICON_TYPES.CLOSE_ICON}
          withBackground
          style={ICON_STYLES.NEGATIVE}
        />
      }
      title={t('reviewPaymentsFailStep.title')}
      description={t('reviewPaymentsFailStep.description', {
        total: formatCurrencyFn(total, currency),
      })}
    />
  );
};
