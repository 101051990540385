import cn from 'clsx';
import { FC } from 'react';
import { TTooltipActionMenuItem } from '@uikit/components/Tooltip/components/TooltipActionMenu';
import { TooltipActionWithConfirmation } from '../TooltipActionWithConfirmation';
import styles from './TooltipAction.module.scss';

type TTooltipActionProps = {
  onClick: () => void;
  className?: string;
  action: TTooltipActionMenuItem;
};

export const TooltipAction: FC<TTooltipActionProps> = ({ onClick, className, action }) => {
  if (action?.isNeedConfirmation)
    return (
      <TooltipActionWithConfirmation
        className={className}
        onClick={onClick}
        action={action}
      />
    );

  return (
    <div
      onClick={action.isDisable ? () => {} : onClick}
      className={cn(
        styles.tooltipAction,
        className,
        action.isDisable && styles.tooltipAction_disable
      )}
    >
      {action?.icon && (
        <img
          src={action.icon}
          alt={action.text}
        />
      )}
      <div>{action?.text}</div>
    </div>
  );
};
