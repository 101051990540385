/*
 * Компонент модального окна подтверждения и выполнения платежей.
 */
import { useStore } from 'effector-react/compat';
import { FC, useState } from 'react';
import { Modal, TModalAPI } from '@uikit/components/Modal';
import { ReviewPaymentsFailStep } from '@pagesPaymentsPage/components/ReviewPaymentsModal/components/ReviewPaymentsFailStep';
import { ReviewPaymentsReviewStep } from '@pagesPaymentsPage/components/ReviewPaymentsModal/components/ReviewPaymentsReviewStep';
import { ReviewPaymentsSucessStep } from '@pagesPaymentsPage/components/ReviewPaymentsModal/components/ReviewPaymentsSucessStep';
import { executePaymentsFx } from '@pagesPaymentsPage/payments.model';
import { ReviewPaymentsInProgressStep } from './components/ReviewPaymentsInProgressStep';

export const ReviewPaymentsModal: FC = () => {
  const [isPaymentsSuccess, setTsPaymentsSuccess] = useState<boolean | null>(null);
  const inProgress = useStore(executePaymentsFx.pending);

  const onStartPayments = () => {
    executePaymentsFx()
      .then(() => setTsPaymentsSuccess(true))
      .catch(() => setTsPaymentsSuccess(false));
  };

  const onCloseModal = (modalAPI: TModalAPI): void => {
    isPaymentsSuccess ? modalAPI.resolve() : modalAPI.reject();
  };

  const getStep = () => {
    if (inProgress) {
      return <ReviewPaymentsInProgressStep />;
    }

    if (isPaymentsSuccess === true) {
      return <ReviewPaymentsSucessStep />;
    }

    if (isPaymentsSuccess === false) {
      return <ReviewPaymentsFailStep />;
    }

    return <ReviewPaymentsReviewStep goNextStep={onStartPayments} />;
  };

  return (
    <Modal
      height="440px"
      withClose={!inProgress}
      onClose={onCloseModal}
    >
      {getStep()}
    </Modal>
  );
};
