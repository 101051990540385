/*
 * Utils for work with dom events.
 */

type TCreateEventParams = Record<string, unknown> & { event: string };

/*
 * Тригерит кастомный event на ноде. В react с этим проблемы - подробнее по ссылке.
 * @see https://stackoverflow.com/a/71340077
 */
export const triggerNativeEventFor = (
  elm: HTMLElement,
  { event, ...valueObj }: TCreateEventParams
): void => {
  const [prop, value] = Object.entries(valueObj)[0] ?? [];
  const desc = Object.getOwnPropertyDescriptor(
    (elm as unknown as Record<string, unknown>).__proto__, // eslint-disable-line no-proto
    prop
  );

  desc?.set?.call(elm, value);
  elm.dispatchEvent(new Event(event, { bubbles: true }));
};
