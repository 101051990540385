import { ReactNode } from 'react';
import { SmaPlatform } from '@hypetrainCommon';
import { TFilterConfig } from '@uikit/components/Filter';
import {
  FORMAT_NUMBER_NOTATION,
  FORMAT_NUMBER_STYLES,
  FormatNumber,
} from '@uikit/components/Formats';
import { CELL_POSITION, DefaultCell, TableHeaderCell } from '@uikit/components/Table';
import { TColumn } from '@uikit/components/Table/table.types';
import { audienceValuesFieldName } from '@components/TableCells/IqdataCells/iqdataCells.constants';
import { useDynamicColumns } from './useDynamicColumns.hook';

export const getGenderColumn = (currentFilter: TFilterConfig, platform: SmaPlatform): TColumn => ({
  width: 50,
  Header: (
    <DefaultCell position={CELL_POSITION.CENTER}>
      <TableHeaderCell text={currentFilter.filterValue.values?.[0] || ''} />
    </DefaultCell>
  ),
  accessor: currentFilter.filterValue.values?.[0] || '',
  Cell: ({ row }) => {
    const genders = useDynamicColumns({
      campaignValue:
        row?.original?.smaStatistics?.audience?.[audienceValuesFieldName[platform] as string]
          ?.genders?.values?.[currentFilter.filterValue.values?.[0] || ''],
      browseValue:
        row?.original?.audience?.[audienceValuesFieldName[platform] as string]?.genders?.values?.[
          currentFilter.filterValue.values?.[0] || ''
        ],
    });
    return (
      <DefaultCell
        isEmpty={!genders}
        position={CELL_POSITION.CENTER}
      >
        <FormatNumber
          notation={FORMAT_NUMBER_NOTATION.STANDARD}
          numberStyles={FORMAT_NUMBER_STYLES.PERCENT}
        >
          {genders as ReactNode}
        </FormatNumber>
      </DefaultCell>
    );
  },
});
