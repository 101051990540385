/*
 * Утилиты для работы с миниатюрами.
 */
import { AVATAR_SIZES, AVATAR_SIZE_MAP } from '@uikit/components/Avatar';
import { SUPPORTED_IMAGE_TYPES, checkSupportsImageType } from '@utils/images.utils';

/*
 * Возвращает ссылку на миниатюру Youtube-инфлюенсера оптимального размера.
 */
export function prepareYoutubeChannelThumbnail(
  thumbnail: string,
  outputSize: AVATAR_SIZES
): string {
  const size = AVATAR_SIZE_MAP[outputSize];

  return thumbnail?.replace(/=s\d*/gm, `=s${size}`);
}

/*
 * Возвращает ссылку на миниатюру Youtube-видео оптимального формата и размера.
 */
export function prepareYoutubeVideoThumbnail(thumbnail: string): string {
  const optimazedSize = thumbnail?.replace(/\/maxresdefault/gm, '/mqdefault');
  const isWebPSupports = checkSupportsImageType(SUPPORTED_IMAGE_TYPES.WEBP);

  if (isWebPSupports) {
    return optimazedSize?.replace(/\/vi\//gm, '/vi_webp/')?.replace(/\.jpg[^*]*/gm, '.webp');
  }

  return optimazedSize;
}
