/*
 * Дефолтный компонент для выбранного значения селекта
 */
import { FC } from 'react';
import { SingleValueProps, components } from 'react-select';
import styles from './SelectFieldValue.module.scss';

export const SelectFieldValue: FC = ({ children, ...props }: SingleValueProps) => (
  <components.SingleValue
    className={styles.selectFieldValue}
    {...props}
  >
    {children}
  </components.SingleValue>
);
