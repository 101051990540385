// TODO Женя, разбить компонент на 2, где в первый будет для формы, второй для текстов(empty state)
import cn from 'clsx';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from './DefaultForm.module.scss';

type TDefaultFormProps = {
  children: ReactNode;
  mainTitle: string;
  secondaryTitle?: string;
  linkTitle?: string;
  directionTitle?: string;
  whereTo?: string;
  customClassName?: string;
  customClassNameMainTitle?: string;
  customClassNameSecondaryTitle?: string;
  customClassNameLinkTitle?: string;
  customChildrenClassName?: string;
};

export const DefaultForm: FC<TDefaultFormProps> = ({
  children,
  mainTitle,
  secondaryTitle,
  linkTitle,
  directionTitle,
  whereTo,
  customClassName,
  customClassNameMainTitle,
  customClassNameSecondaryTitle,
  customClassNameLinkTitle,
  customChildrenClassName,
}) => (
  <div className={cn(styles.main, customClassName)}>
    <h1 className={cn(styles.main__mainTitle, customClassNameMainTitle)}>{mainTitle}</h1>
    {secondaryTitle && (
      <h2
        className={cn(
          styles.main__secondaryTitle,
          customClassNameSecondaryTitle,
          linkTitle && styles.main__isLinkBlock
        )}
      >
        {secondaryTitle}
      </h2>
    )}
    {linkTitle && (
      <div className={cn(styles.linkBlock, customClassNameLinkTitle)}>
        {linkTitle}
        <Link
          to={whereTo || ''}
          tabIndex={-1}
        >
          {directionTitle}
        </Link>
      </div>
    )}
    <div className={cn(styles.children, customChildrenClassName)}>{children}</div>
  </div>
);
