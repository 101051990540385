import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import styles from './Budge.module.scss';

type TBudgeProps = {
  className?: string;
  leftIcon?: ICON_TYPES;
};

export const Budge: FC<PropsWithChildren<TBudgeProps>> = ({ className, leftIcon, children }) => {
  if (!children) return null;

  return (
    <h4 className={cn(styles.budge, [className])}>
      {leftIcon && (
        <Icon
          type={leftIcon}
          size={12}
        />
      )}
      {children}
    </h4>
  );
};
