/*
 *   Компонент для отрисовки заголовка в группе select
 */
import { FC } from 'react';
import { GroupHeadingProps, components } from 'react-select';
import styles from './GroupHeader.module.scss';

export const GroupHeader: FC<GroupHeadingProps> = (props) => (
  <h4 className={styles.groupHeader}>
    <components.GroupHeading {...props} />
  </h4>
);
