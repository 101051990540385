/*
 * Модель активного воркспейса.
 */
import { createEvent, createStore } from 'effector/compat';
import { IPersonWorkspaceResponseDto } from '@hypetrainCommon';

/*
 * Установка активного воркспейса.
 */
export const setActiveWorkspace = createEvent<IPersonWorkspaceResponseDto>();

/*
 * Стор активного воркспейса.
 */
export const $activeWorkspaceStore = createStore<IPersonWorkspaceResponseDto>(
  {} as IPersonWorkspaceResponseDto
).on(setActiveWorkspace, (state, workspace) => workspace);
