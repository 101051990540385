/*
 * Сервис разграничения доступа пользователя к функционалу приложения.
 */
import _isObject from 'lodash/isObject';
import { RightGroup } from '@hypetrainCommon';
import { TUsetRights } from './userAccess.service.types';

export class UserAccessService {
  public userRights: TUsetRights = {};

  public hasWorkspaceRightGroup = false;

  public hasAddressBookRightGroup = false;

  public hasCampaignRightGroup = false;

  public hasSubscriptionRightGroup = false;

  public hasPaymentRightGroup = false;

  /*
   * ========================= Rights groups getters end. =========================================
   */

  public initUserRights(userRights: TUsetRights): void {
    if (!_isObject(userRights)) return;

    this.userRights = userRights;

    const rightsValues = Object.values(userRights);
    this.hasWorkspaceRightGroup = rightsValues.some((r) => r?.group === RightGroup.workspace);
    this.hasAddressBookRightGroup = rightsValues.some((r) => r?.group === RightGroup.addressBook);
    this.hasCampaignRightGroup = rightsValues.some((r) => r?.group === RightGroup.campaign);
    this.hasSubscriptionRightGroup = rightsValues.some((r) => r?.group === RightGroup.subscription);
    this.hasPaymentRightGroup = rightsValues.some((r) => r?.group === RightGroup.payment);
  }
}

const createUserAccessService = () => new UserAccessService();

export const userAccessService = createUserAccessService();
