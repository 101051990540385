import { FC } from 'react';
import { Row } from 'react-table';
import styles from '@uikit/components/Table/Table.module.scss';
import { TableRow } from '@uikit/components/Table/components/TableRow';
import { TTableRow } from '../../table.types';

type TTBodyProps = {
  prepareRow: (row: Row) => void;
  role?: string | undefined;
  rows: Array<TTableRow>;
};

export const TBody: FC<TTBodyProps> = ({ role, prepareRow, rows }) => (
  <tbody
    className={styles.body}
    role={role}
  >
    {rows.map((row) => (
      <TableRow
        key={row.id}
        prepareRow={prepareRow}
        row={row}
      />
    ))}
  </tbody>
);
