import isFinite from 'lodash/isFinite';
import isNaN from 'lodash/isNaN';

export const isNumeric = (value: string) => !isNaN(parseFloat(value)) && isFinite(+value);

export const fractionToInteger = (value: number, decimal = 2) => {
  if (typeof value !== 'number') {
    return 'N/A';
  }

  return (value * 100).toFixed(decimal);
};

// Утилка для преобразования строки в число или null
export const getNumberOrNullFromString = (value: string): number | null =>
  isNaN(parseFloat(value)) ? null : parseFloat(value);
