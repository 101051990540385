/*
 * Switcher group component.
 */
import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import styles from './SwitcherGroup.module.scss';
import { SwitcherGroupProvider } from './switcherGroup.context';
import { TSwitcherGroupValues } from './switcherGroup.types';

type TSwitcherGroupProps = PropsWithChildren<{
  value: TSwitcherGroupValues; // group state
  onChange: (value: TSwitcherGroupValues) => void; // cb on change one of checkboxes state
  className?: string;
}>;

export const SwitcherGroup: FC<TSwitcherGroupProps> = ({
  value,
  onChange,
  className,
  children,
}) => {
  const onChangeSwitcherGroup = (switcherName: string, isChecked: boolean): void => {
    onChange({
      ...value,
      [switcherName]: isChecked,
    });
  };

  return (
    <SwitcherGroupProvider
      value={{
        switcherGroupValue: value,
        onChangeSwitcherGroup,
      }}
    >
      <fieldset className={cn(styles.switcherGroup, className)}>{children}</fieldset>
    </SwitcherGroupProvider>
  );
};
