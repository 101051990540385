// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".usersListTooltip__D6hZ9{overflow:auto;max-height:400px}", "",{"version":3,"sources":["webpack://./components/UsersList/components/UsersListTooltip/UsersListTooltip.module.scss"],"names":[],"mappings":"AAEA,yBACE,aAAA,CACA,gBAAA","sourcesContent":["@import '../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.usersListTooltip {\n  overflow: auto;\n  max-height: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"usersListTooltip": "usersListTooltip__D6hZ9"
};
export default ___CSS_LOADER_EXPORT___;
