import { FC, PropsWithChildren } from 'react';
import { Currency } from '@hypetrainCommon';
import { FORMAT_NUMBER_NOTATION } from '@uikit/components/Formats/Formats.constants';
import { formatCurrencyFn } from './formatCurrency.utils';

type TFormatCurrency = {
  className?: string;
  precision?: number;
  notation?: FORMAT_NUMBER_NOTATION;
  currency?: Currency;
  id?: string;
  signDisplay?: 'auto' | 'never' | 'always' | 'exceptZero';
};

export const FormatCurrency: FC<PropsWithChildren<TFormatCurrency>> = ({
  children,
  className,
  precision = 2,
  notation = FORMAT_NUMBER_NOTATION.STANDARD,
  currency = Currency.USD,
  id,
  signDisplay,
}) => (
  <div
    id={id}
    className={className}
  >
    {formatCurrencyFn(children, currency, precision, notation, signDisplay)}
  </div>
);
