/*
 * A component that adds a scroll to the content and, if the scroll is displayed, adds borders.
 */
import cn from 'clsx';
import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { isHasScroll } from '@uikit/helpers/scroll.helpers';
import styles from './BorderedScrollableContainer.module.scss';
import { ScrollProvider } from './scroll.context';

type TBorderedScrollableContainerProps = {
  hideScrollTopDivider?: boolean; // признак необходимости скрытия верхнего разделителя при наличии скролла
  className?: string;
};

export const BorderedScrollableContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<TBorderedScrollableContainerProps>
>(({ hideScrollTopDivider = false, className, children }, ref) => {
  const emailListRef = useRef<HTMLDivElement>(null);
  const [hasScroll, setHasScroll] = useState(false);

  useImperativeHandle(ref, () => emailListRef.current!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const updateScrollSign = useCallback(() => {
    setHasScroll(isHasScroll(emailListRef));
  }, []);

  useEffect(() => {
    if (!emailListRef.current) return;

    updateScrollSign();

    const observer = new ResizeObserver(updateScrollSign);
    observer.observe(emailListRef.current);

    return () => observer.disconnect();
  }, [emailListRef.current, updateScrollSign]);

  return (
    <div
      ref={emailListRef}
      className={cn(
        className,
        styles.borderedScrollableContainer,
        hasScroll && styles.borderedScrollableContainer_withScroll,
        hideScrollTopDivider && styles.borderedScrollableContainer_withoutTopDivider
      )}
    >
      <ScrollProvider value={hasScroll}>{children}</ScrollProvider>
    </div>
  );
});
