/*
 * Шаг с проверкой платежей в модальном окне проводения платежей.
 */
import { useStore } from 'effector-react/compat';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FeeType } from '@hypetrainCommon';
import { BUTTON_TYPE, Button } from 'hypetrain-ui/src/components/Buttons';
import { FormatCurrency } from 'hypetrain-ui/src/components/Formats';
import { useSelectedPayments } from '@pagesPaymentsPage/hooks/useSelectedPayments.hook';
import {
  $calculatedPaymentsSummary,
  $paymentsCurrencyFilter,
} from '@pagesPaymentsPage/payments.model';
import styles from './ReviewPaymentsReviewStep.module.scss';

type TReviewPaymentsReviewStep = {
  goNextStep: () => void;
};

export const ReviewPaymentsReviewStep: FC<TReviewPaymentsReviewStep> = ({ goNextStep }) => {
  const { t } = useTranslation();
  const selectedPayments = useSelectedPayments();
  const currency = useStore($paymentsCurrencyFilter);
  const paymentsTotalAndFees = useStore($calculatedPaymentsSummary);

  const data = paymentsTotalAndFees?.[currency];
  const total = data?.total;
  const fees = data?.totalAppliedFees;

  return (
    <div className={styles.reviewPaymentsReviewStep}>
      <h2>{t('reviewPaymentsReviewStep.title')}</h2>

      <div className={styles.reviewPaymentsReviewStep__countBlock}>
        <div className={styles.reviewPaymentsReviewStep__countDescription}>
          {t('reviewPaymentsReviewStep.countDescription')}
        </div>
        <div className={styles.reviewPaymentsReviewStep__count}>{selectedPayments?.length}</div>
      </div>

      <div className={styles.reviewPaymentsReviewStep__fees}>
        <div>
          <span>{t('paymentAmountMetricTooltip.subtotal')}</span>
          <FormatCurrency currency={currency}>{data?.subTotal || 0}</FormatCurrency>
        </div>
        <div>
          <span>{t('paymentAmountMetricTooltip.ourFee')}</span>
          <FormatCurrency currency={currency}>{fees?.[FeeType.Hypetrain] || 0}</FormatCurrency>
        </div>
        <div>
          <span>{t('paymentAmountMetricTooltip.conversionFee')}</span>
          <FormatCurrency currency={currency}>
            {fees?.[FeeType.CurrencyConversion] || 0}
          </FormatCurrency>
        </div>
        <div>
          <span>{t('paymentAmountMetricTooltip.crossBorderFee')}</span>
          <FormatCurrency currency={currency}>{fees?.[FeeType.CrossBorder] || 0}</FormatCurrency>
        </div>
        <div>
          <span>{t('paymentAmountMetricTooltip.fixedOperatingCost')}</span>
          <FormatCurrency currency={currency}>{fees?.[FeeType.Operational] || 0}</FormatCurrency>
        </div>
        <div>
          <h2>{t('paymentAmountMetricTooltip.total')}</h2>
          <h2>
            <FormatCurrency currency={currency}>{total || 0}</FormatCurrency>
          </h2>
        </div>
      </div>

      <Button
        buttonType={BUTTON_TYPE.COMMIT}
        onClick={goNextStep}
      >
        {t('reviewPaymentsReviewStep.payButton')}
      </Button>
    </div>
  );
};
