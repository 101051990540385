/*
 * Hook that provides correct auto-height value for dialog content.
 * It's important for corrent work scroll in dialog.
 */
import _parseInt from 'lodash/parseInt';
import { RefObject, useEffect, useRef } from 'react';

const MAX_MODAL_HEIGHT = 0.8; // 80%

export const useModalContent = (
  dialogRef: RefObject<HTMLElement>,
  excludeNodes: RefObject<HTMLElement>[], // nodes that will be excluded of calculate available diolog content height
  fixModalHeight: boolean, // sign of the need to fix modal height (without resize on change content height)
  minHeight: string
): RefObject<HTMLDivElement> => {
  const contentRef = useRef<HTMLDivElement>(null);

  /*
   * Set fixed dialog height for correct work scroll on content.
   */
  const updateHeight = () => {
    if (!contentRef.current) return;

    const contentHeight = contentRef.current.getBoundingClientRect().height;
    const excludeNodesHeight = excludeNodes.reduce(
      (sumHeight, node) => sumHeight + Number(node.current?.getBoundingClientRect()?.height),
      0
    );
    const maxAvailableDialogHeight =
      document.body.getBoundingClientRect().height * MAX_MODAL_HEIGHT;
    const { paddingTop, paddingBottom } = getComputedStyle(dialogRef.current as HTMLElement);
    // eslint-disable-next-line max-len
    const fullExcludeHeight =
      excludeNodesHeight + parseFloat(paddingTop) + parseFloat(paddingBottom);
    const maxAvailableContentHeight = maxAvailableDialogHeight - fullExcludeHeight;

    // Если высота контента уже равна максимально доступной высоте - делаем вывод,
    // что ранее уже была выставлена максимальная высота и это повторный/лишний запуск.
    // Выходим, что бы не делать ложных предположений (расчёт уже выполнен ранее).
    if (Math.floor(contentHeight) === Math.floor(maxAvailableContentHeight)) {
      return;
    }

    // Если высота контента больше максимально доступной - диалог растянется больше доступной выличины.
    // Устанавливаем высоту контента в максимально доступную высоту.
    if (contentHeight > maxAvailableContentHeight) {
      contentRef.current.style.height = `${maxAvailableContentHeight}px`;
      return;
    }

    // Если установлен флаг фиксации высоты модального окна - провеям не не является ли
    // высота контента меньше минимально доступной.
    // Фиксируем высоту контента либо на минимально доступной высоте либо на фактической.
    if (fixModalHeight) {
      const minModalHeightValue = _parseInt(minHeight);
      const minAvailableContentHeight = minModalHeightValue - fullExcludeHeight;
      const height =
        contentHeight < minAvailableContentHeight ? minAvailableContentHeight : contentHeight;

      contentRef.current.style.height = `${height}px`;
    }
  };

  useEffect(() => {
    if (!contentRef.current || !dialogRef?.current) return;

    updateHeight();

    const observer = new ResizeObserver(updateHeight);
    observer.observe(contentRef.current);

    return () => observer.disconnect();
  }, [excludeNodes]);

  return contentRef;
};
