/*
 * Константы Range select
 */

/*
 * число которое используется в опции >1m
 * >1m и невыбраное значение в фильтре одно и тоже,
 * но нам нужно их отличать например при подгрузке фильтров в кампании
 */
export const MORE_THAN_1M = 2147483647;

export const DEFAULT_RANGE_SELECT_FROM_OPTIONS = [
  { value: 1000, label: '1k' },
  { value: 5000, label: '5k' },
  { value: 10000, label: '10k' },
  { value: 50000, label: '50k' },
  { value: 100000, label: '100k' },
  { value: 500000, label: '500k' },
  { value: 1000000, label: '1m' },
];

export const DEFAULT_RANGE_SELECT_TO_OPTIONS = [
  { value: 1000, label: '1k' },
  { value: 5000, label: '5k' },
  { value: 10000, label: '10k' },
  { value: 50000, label: '50k' },
  { value: 100000, label: '100k' },
  { value: 500000, label: '500k' },
  { value: 1000000, label: '1m' },
  { value: MORE_THAN_1M, label: '>1m' },
];
