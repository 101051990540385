/*
 * Сервис разграничения доступа пользователя к функционалу модуля платежей.
 */
import { UserAccessService, userAccessService } from './userAccess.service';
import { TUsetRights } from './userAccess.service.types';

export class PaymentUserAccessService {
  private get userRights(): TUsetRights {
    return this.userAccess.userRights;
  }

  /*
   * Может ли пользователь работать с модулем payments.
   */
  public get isAvailable(): boolean {
    return this.userAccess.hasAddressBookRightGroup;
  }

  /*
   * Может ли пользователь управлять платежами.
   */
  public get canManagePayments(): boolean {
    return Boolean(this.userRights.ManagePayments);
  }

  constructor(private readonly userAccess: UserAccessService) {}
}

const createPaymentUserAccessService = (userAccess: UserAccessService) =>
  new PaymentUserAccessService(userAccess);

export const paymentUserAccessService = createPaymentUserAccessService(userAccessService);
