/*
 * Константы компонента Avatar.
 */
import {
  CSS_AVATAR_M,
  CSS_AVATAR_S,
  CSS_AVATAR_XL,
  CSS_AVATAR_XS,
} from '@uikit/helpers/styles.helpers';

export const MAX_NUMBER_HEX = 255;
export const AVATAR_COLORS = [
  '#C0DAF1',
  '#73A7FF',
  '#DFCBE5',
  '#FFA08B',
  '#C0EEF1',
  '#CBE5D4',
  '#FFD4CE',
  '#F5FF80',
  '#64C7FF',
  '#BECEDC',
  '#B0CEFF',
  '#8FCCB2',
  '#D3AEF0',
  '#E4F1C0',
  '#C2BEBA',
  '#C5B8E8',
  '#FFCC80',
  '#FFBEA9',
  '#F8F5F2',
  '#FFE0BE',
  '#D3A798',
  '#BCFFE5',
  '#9DF3FF',
];

/*
 * Возможные размеры Avatar.
 */
export enum AVATAR_SIZES {
  XS = 'XS', // 24px
  S = 'S', // 30px
  M = 'M', // 60px
  L = 'L',
  XL = 'XL', // 180px
}

/*
 * Маппинг размнеров Avatar'а на имена констант в стилях.
 */
export const AVATAR_SIZE_MAP: Record<AVATAR_SIZES, number> = {
  [AVATAR_SIZES.XS]: CSS_AVATAR_XS,
  [AVATAR_SIZES.S]: CSS_AVATAR_S,
  [AVATAR_SIZES.M]: CSS_AVATAR_M,
  [AVATAR_SIZES.L]: 0,
  [AVATAR_SIZES.XL]: CSS_AVATAR_XL,
};

/*
 * Размеры Avatar'а по-умолчанию.
 */
export const DEFAULT_AVATAR_SIZE = AVATAR_SIZES.S;
