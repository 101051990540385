/*
 * Service for executing exploration requests
 */
import { ApiService, apiService } from '@api';
import {
  IBrandsRequestDto,
  IBrandsResponseDto,
  IGamesRequestDto,
  IGamesResponseDto,
  IInstagramFilteringRequestDto,
  IInstagramFilteringResponseDto,
  ILastFilteringRequestsResponseDto,
  ISearchRequestDto,
  ISearchResponseDto,
  IUploadingListResponseDto,
  IYoutubeFilteringRequestDto,
  IYoutubeFilteringResponseDto,
  SmaPlatform,
} from '@hypetrainCommon';
import { TBrand, TCategory, TLanguage } from '@api/exploration';

/* eslint-disable arrow-body-style */
export class ExplorationApiService {
  constructor(private readonly api: ApiService) {}

  /*
   * Фильтрация и сортировка инфлюенсеров.
   * @see: https://reference.api.rc-staging.hypetrain.io/?http#filter-youtube-smas
   */
  filterAndSortInfluencers = (
    requestData: IYoutubeFilteringRequestDto | IInstagramFilteringRequestDto
  ): Promise<IYoutubeFilteringResponseDto | IInstagramFilteringResponseDto> => {
    return this.api
      .post(
        `/exploration/${apiService.activeSocialPlatform}/filtering`,
        requestData,
        {},
        { showLoader: false }
      )
      .then((response) => response.data);
  };

  /*
   * Поиск инфлюенсера.
   * @see: https://reference.api.rc-staging.hypetrain.io/#search-youtube-smas-by-basic-parameters-name-customurl-legacyurl-externalid
   */
  public searchInfluencers = (requestData: ISearchRequestDto): Promise<ISearchResponseDto> => {
    return this.api
      .post(`/exploration/${apiService.activeSocialPlatform}/search`, requestData)
      .then((response) => response.data);
  };

  /*
   * Загрузка списка инфлюенсера.
   * @see: https://reference.api.rc-staging.hypetrain.io/#upload-user-list-with-smas
   */
  public uploadUserList = (
    requestData: FormData,
    showLoader = false
  ): Promise<IUploadingListResponseDto> => {
    return this.api
      .post('/exploration/upload-list', requestData, {}, { showLoader })
      .then((response) => response.data);
  };

  /*
   * поиск игр
   * @see: https://reference.api.rc-staging.hypetrain.io/#explore-games-of-youtube-smas
   */
  public searchGames = (requestData: IGamesRequestDto): Promise<IGamesResponseDto> => {
    return this.api
      .post('/exploration/games', requestData, {}, { showLoader: false })
      .then((response) => response.data);
  };

  /*
   * поиск брендов
   * @see: https://reference.api.team-master-dev.hypetrain.io/?shell#explore-brands-of-youtube-smas
   */
  public searchBrands = (requestData: IBrandsRequestDto): Promise<IBrandsResponseDto> => {
    return this.api
      .post('/exploration/brands', requestData, {}, { showLoader: false })
      .then((response) => response.data);
  };

  /*
   * справочник брендов
   * @see: https://reference.api.test.hypetrain.io/#get-instagram-dictionary
   */
  public getBrands = (): Promise<TBrand[]> => {
    return this.api
      .get('/exploration/instagram/dicts/brands', {}, { showLoader: false })
      .then((response) => response.data);
  };

  /*
   * справочник категорий
   * @see: https://reference.api.test.hypetrain.io/#get-instagram-dictionary
   */
  public getCategories = (): Promise<TCategory[]> => {
    return this.api
      .get('/exploration/instagram/dicts/categories', {}, { showLoader: false })
      .then((response) => response.data);
  };

  /*
   * справочник языков
   * @see: https://reference.api.test.hypetrain.io/#get-instagram-dictionary
   */
  public getLanguages = (activePlatform: SmaPlatform): Promise<TLanguage[]> => {
    return this.api
      .get(`/exploration/${activePlatform}/dicts/languages`, {}, { showLoader: false })
      .then((response) => response.data);
  };

  /*
   * Получених примененных последних фильтров
   */
  public getFilters = (): Promise<ILastFilteringRequestsResponseDto> => {
    return this.api
      .get(`/exploration/last-filtering-request`, {}, { showLoader: false })
      .then((response) => response.data);
  };
}

export const explorationApiService = new ExplorationApiService(apiService);
