/*
 * Компонент-хедер в меню выбора воркспейса
 */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PopupPositions } from '@uikit/components/Popup';
import { Tooltip } from '@uikit/components/Tooltip';
import addIcon from '@assets/images/add.svg';
import { noop } from '@helpers/common.helpers';
import styles from './WorkspaceMenuHeader.module.scss';

type TWorkspaceMenuHeaderProps = {
  showCreateButton: boolean;
  onButtonClick?: () => void;
};

export const WorkspaceMenuHeader: FC<TWorkspaceMenuHeaderProps> = ({
  showCreateButton,
  onButtonClick = noop,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.workspaceMenuHeader}>
      {t('navigation.workspaces')}
      {showCreateButton && (
        <Tooltip
          position={PopupPositions.BottomCenter}
          content={<span>{t('menu.createWorkspaceTooltip')}</span>}
        >
          <img
            onClick={onButtonClick}
            src={addIcon}
            alt="addIcon"
          />
        </Tooltip>
      )}
    </div>
  );
};
