import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { EmptyBlock } from '@uikit/components/EmptyBlock';
import { EMPTY_BLOCK_POSITION } from '@uikit/components/EmptyBlock/EmptyBlock.constants';
import { CELL_POSITION } from './DefaultCell.constants';
import styles from './DefaultCell.module.scss';

type TDefaultColumn = {
  isEmpty?: boolean;
  position?: CELL_POSITION;
};

const POSITION_TYPE_MAP: Partial<Record<CELL_POSITION, EMPTY_BLOCK_POSITION>> = {
  [CELL_POSITION.LEFT]: EMPTY_BLOCK_POSITION.LEFT,
  [CELL_POSITION.CENTER]: EMPTY_BLOCK_POSITION.CENTER,
  [CELL_POSITION.RIGHT]: EMPTY_BLOCK_POSITION.RIGHT,
};

const CELL_POSITION_MAP: Partial<Record<CELL_POSITION, string>> = {
  [CELL_POSITION.LEFT]: styles.defaultColumn__left,
  [CELL_POSITION.CENTER]: styles.defaultColumn__center,
  [CELL_POSITION.RIGHT]: styles.defaultColumn__right,
};

export const DefaultCell: FC<PropsWithChildren<TDefaultColumn>> = ({
  children,
  isEmpty,
  position = CELL_POSITION.LEFT,
}) => {
  if (isEmpty) {
    return <EmptyBlock position={POSITION_TYPE_MAP[position]} />;
  }
  return <div className={cn(styles.defaultColumn, CELL_POSITION_MAP[position])}>{children}</div>;
};
