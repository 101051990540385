/*
 * Утилиты связанные с авторизацией пользователя в системе.
 */
import axios from 'axios';
import { ICredentialsResponseDto, IGoogleAuthResponseDto } from '@hypetrainCommon';
import { DEFAUTL_SUBDOMAIN_ROUTES_WITH_AUTH } from '@router/Routes';

let IS_AUTH = false;

/*
 * Устанавливает заголовки авторизации пользователя к системе.
 */
export const setAuthHeader = (accessToken: string | null): void => {
  if (accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

/*
 * Устанавливает токен доступа пользователя к системе.
 */
export const setAccessToken = (accessToken: string): void => {
  IS_AUTH = Boolean(accessToken);
  setAuthHeader(accessToken);
};

/*
 * Возвращает признак авторизации пользователя к системе.
 */
export const isAuth = (): boolean => IS_AUTH;

/*
 * Возвращает признак роута, в котором ошбки авторизации игнорируются.
 */
export const isRouteWithIgnoreAuthorizationErrors = (): boolean =>
  DEFAUTL_SUBDOMAIN_ROUTES_WITH_AUTH.every((regExp) => regExp.test(window.location.pathname));

export const willRedirectOnWorkspace = (
  response: ICredentialsResponseDto | IGoogleAuthResponseDto
): boolean => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  urlParams.has('workspace');

  // Новый пользователь, нет workspace -> /create-workspace
  if (!response?.workspace?.handler && !urlParams.has('workspace')) {
    return false;
  }

  // Новый пользователь, нет workspace, получил инвайт -> /join
  if (!response?.workspace?.handler && urlParams.has('workspace')) {
    return false;
  }

  // Старый пользователь, есть воркспейс -> redirect
  if (response?.workspace?.handler && !urlParams.has('workspace')) {
    return true;
  }

  // Старый пользователь, есть воркспейс, получил инвайт -> /join
  if (response?.workspace?.handler && urlParams.has('workspace')) {
    return false;
  }

  return true;
};
