// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lastMessageContent__AASFi{width:100%;padding-right:var(--vSpacing12)}.lastMessageContent__textContainer__joJa1{margin-bottom:var(--vSpacing8);display:grid;grid-template:\"text systemMessage\" minmax(0, 45px)/minmax(31%, 100%) max-content;overflow:hidden}.lastMessageContent__text__LFkS4{grid-area:text;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;margin:auto 0}.lastMessageContent__systemText__BWZsw{grid-area:systemMessage}.lastMessageContent__info__aVfQV{display:flex}.attachFile__rEJXp{padding-top:var(--vSpacing16);color:var(--textPrimary)}", "",{"version":3,"sources":["webpack://./pages/Campaigns/CampaignDetailsPage/components/Communication/components/LastMessageContent/LastMessageContent.module.scss"],"names":[],"mappings":"AAEA,2BACE,UAAA,CACA,+BAAA,CAEA,0CACE,8BAAA,CACA,YAAA,CAGA,gFAAA,CACA,eAAA,CAGF,iCACE,cAAA,CACA,eAAA,CACA,sBAAA,CACA,kBAAA,CACA,aAAA,CAGF,uCACE,uBAAA,CAGF,iCACE,YAAA,CAIJ,mBACE,6BAAA,CACA,wBAAA","sourcesContent":["@import '../../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.lastMessageContent {\n  width: 100%;\n  padding-right: var(--vSpacing12);\n\n  &__textContainer {\n    margin-bottom: var(--vSpacing8);\n    display: grid;\n\n    // 50px выбрана как временный размер текста сообщения (выбрана как половина высоты строки)\n    grid-template: 'text systemMessage' minmax(0, 45px) / minmax(31%, 100%) max-content;\n    overflow: hidden;\n  }\n\n  &__text {\n    grid-area: text;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    margin: auto 0;\n  }\n\n  &__systemText {\n    grid-area: systemMessage;\n  }\n\n  &__info {\n    display: flex;\n  }\n}\n\n.attachFile {\n  padding-top: var(--vSpacing16);\n  color: var(--textPrimary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lastMessageContent": "lastMessageContent__AASFi",
	"lastMessageContent__textContainer": "lastMessageContent__textContainer__joJa1",
	"lastMessageContent__text": "lastMessageContent__text__LFkS4",
	"lastMessageContent__systemText": "lastMessageContent__systemText__BWZsw",
	"lastMessageContent__info": "lastMessageContent__info__aVfQV",
	"attachFile": "attachFile__rEJXp"
};
export default ___CSS_LOADER_EXPORT___;
