// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".usersList__ipnIL{display:flex;flex-shrink:0}.usersList__avatarContainer__iOWpV{margin-right:var(--vSpacing8)}.usersList__avatarContainer__iOWpV:last-child{margin-right:unset}", "",{"version":3,"sources":["webpack://./components/UsersList/UsersList.module.scss"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,aAAA,CAEA,mCACE,6BAAA,CAEA,8CACE,kBAAA","sourcesContent":["@import '../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.usersList {\n  display: flex;\n  flex-shrink: 0;\n\n  &__avatarContainer {\n    margin-right: var(--vSpacing8);\n\n    &:last-child {\n      margin-right: unset;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"usersList": "usersList__ipnIL",
	"usersList__avatarContainer": "usersList__avatarContainer__iOWpV"
};
export default ___CSS_LOADER_EXPORT___;
