import cn from 'clsx';
import { useStore } from 'effector-react/compat';
import { TextEllipsis } from 'hypetrain-ui/src/components/TextEllipsis';
import _isEmpty from 'lodash/isEmpty';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  ICommonSmaBasicInfoResponseDto,
  IInstagramSmaResponseDto,
  IYoutubeSmaBasicInfoResponseDto,
  IYoutubeSmaResponseDto,
} from '@hypetrainCommon';
import { AVATAR_SIZES } from '@uikit/components/Avatar';
import { BUTTON_SIZE, Button } from '@uikit/components/Buttons';
import { Divider } from '@uikit/components/Divider';
import { HighlightedText } from '@uikit/components/HighlightedText';
import { noop } from '@uikit/helpers/common.helpers';
import { Routes } from '@router/Routes';
import { getSocialPlatformBySMAId } from '@utils/socialPlatform.utils';
import { getCountryName } from '@constants/countries';
import { useServices } from '@hooks/useServices.hook';
import { $campaignStore } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.model';
import { UnreadMessageCounter } from '@pagesCampaigns/CampaignDetailsPage/components/Communication/components/UnreadMessageCounter';
import { ChannelName } from '@components/CommonColumnsTable/ChannelInfoColumn/components/ChannelName';
import { ContactBadge } from '@components/ContactBadge';
import { InfluencerAvatar } from '@components/InfluencerAvatar';
import styles from './ChannelInfoColumn.module.scss';

export type TChannelInfoColumnData =
  | IYoutubeSmaResponseDto
  | IInstagramSmaResponseDto
  | ICommonSmaBasicInfoResponseDto
  | IYoutubeSmaBasicInfoResponseDto;

type TBloggerInfoColumnProps = {
  info: TChannelInfoColumnData;
  hideCountry?: boolean;
  className?: string;
  highlightedString?: string;
  dealId?: string;
  // isContact - параметр который отвечает за то есть ли контакты блогера
  isContact?: boolean;
  // unreadMessageCount - параметр который отвечает за колличество непрочитанных сообщений от блогера
  unreadMessageCount?: number;
  onSmaProfile?: () => void;
};

export const ChannelInfoColumn: FC<TBloggerInfoColumnProps> = memo(
  ({
    info,
    hideCountry,
    className,
    dealId,
    highlightedString,
    isContact = true,
    unreadMessageCount,
    onSmaProfile = noop,
  }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { influencerProfileService } = useServices();
    const campaign = useStore($campaignStore);

    if (_isEmpty(info)) return null;

    const { title, thumbnail, verificationStatus, country, uuid, notesCount } = info;
    const handler = 'handler' in info ? info.handler : undefined;

    const openSmaProfile = (): void => {
      influencerProfileService.closeInfluencerProfileModal();
      influencerProfileService.openInfluencerProfileModal({
        id: uuid,
        title,
      });
      onSmaProfile();
    };

    const openDealPage = () => {
      const path = generatePath(Routes.campaignDeal, {
        campaignId: campaign?.campaignDetails?.id,
        dealId,
      });
      navigate(path);
    };

    return (
      <div className={styles.bloggerInfo}>
        <div className={styles.bloggerInfo__avatar}>
          <InfluencerAvatar
            influencerId={info.uuid}
            img={thumbnail}
            size={AVATAR_SIZES.M}
            onClick={openSmaProfile}
          />
          <UnreadMessageCounter count={Number(unreadMessageCount)} />
        </div>

        <div className={cn(styles.bloggerInfo__infoBlock, className)}>
          <ChannelName
            title={
              <HighlightedText
                text={title}
                highlightedString={highlightedString}
              />
            }
            handler={handler}
            platform={getSocialPlatformBySMAId(uuid)}
            verificationStatus={verificationStatus}
            onClick={openSmaProfile}
            channelNote={notesCount ? t('table.comments', { count: notesCount }) : undefined}
          />
          <div className={styles.bloggerInfo__secondLine}>
            {!hideCountry && country && (
              <div className={styles.bloggerInfo__countryContainer}>
                <TextEllipsis className={styles.bloggerInfo__country}>
                  {getCountryName(country)}
                </TextEllipsis>
              </div>
            )}
            {isContact && !hideCountry && country && (
              <Divider className={styles.bloggerInfo__divider} />
            )}
            {isContact && (
              <ContactBadge
                isContact={isContact}
                className={country ? styles.bloggerInfo__noContactWithCountry : ''}
              />
            )}
          </div>

          <Button
            className={cn(
              styles.bloggerInfo__infoBlock__buttonChat_hide,
              styles.bloggerInfo__openChat
            )}
            size={BUTTON_SIZE.XS}
            onClick={openDealPage}
          >
            {t('openChat')}
          </Button>
        </div>
      </div>
    );
  }
);
