/*
 * Утилиты для компонентов списка фильтров.
 */
import isNil from 'lodash/isNil';
import {
  CampaignFilterConfig,
  ICampaignFilterDto,
  IInstagramFilterDto,
  ITiktokFilterDto,
  IYoutubeFilterDto,
  InstagramCampaignFilterName,
  YoutubeCampaignFilterName,
} from '@hypetrainCommon';
import { TFilterConfig } from '@uikit/components/Filter';
import { TAbstractFilterConfig, TAvailableFilter } from '@uikit/components/Filters';
import { TFilter, TFilterDto } from '@uikit/components/Filters/filters.types';
import { TOptionsToggleItem, TOptionsTogglerValue } from '@uikit/components/OptionsToggler';

const DEFAULT_FORMAT_VALUE_TO_DTO = (filterValue: TFilter): TFilterDto => filterValue as TFilterDto;

// Внутри приложения используется общая модель для всех фильтров,
// дефолтный адаптер для приведения моделей бэкэнда к локальной модели
const DEFAULT_FORMAT_VALUE_FROM_DTO = (filterValue: TFilterDto): TFilter => ({
  name: filterValue?.name,
  min: 'min' in filterValue ? filterValue.min : undefined,
  max: 'max' in filterValue ? filterValue.max : undefined,
  values:
    ('values' in filterValue && (filterValue.values as string[])) ||
    ('value' in filterValue && [String(filterValue.value)]) ||
    undefined,
});

/*
 * Возвращает инициализированные фильтры на основе их первоначального конфига.
 */
export function getInitFilters(configs: TAvailableFilter[]): TFilterConfig[] {
  return configs.map((config) => ({
    id: config.id,
    group: config.group,
    switcherLabel: config.switcherLabel,
    isVisible: config.isVisible || false,
    label: config.label,
    popupTitle: config.popupTitle,
    infoTooltip: config.infoTooltip,
    description: config.description,
    filterPopupWidth: config.filterPopupWidth,
    filterType: config.filterType,
    filterProps: config.filterProps,
    filterInitialValue: config.filterInitialValue || { name: config.id },
    filterValue: config.filterValue || { name: config.id },
    formatValueToDTO: config.formatValueToDTO || DEFAULT_FORMAT_VALUE_TO_DTO,
    formatValueFromDTO: config.formatValueFromDTO || DEFAULT_FORMAT_VALUE_FROM_DTO,
    testId: config.testId,
  })) as TFilterConfig[];
}

/*
 * Возврашает признак пустого фильтра.
 */
export function isEmptyFilter(filterValue: TFilter): boolean {
  return !filterValue?.values?.length && isNil(filterValue?.min) && isNil(filterValue?.max);
}

/*
 * Возврашает признак того, что фильтр имеет выставленное значение.
 */
export function isFilterHasValue(filter: TFilterConfig): boolean {
  return (filter.filterProps as any)?.hasPercentage // eslint-disable-line  @typescript-eslint/no-explicit-any
    ? !!filter.filterValue.values?.length
    : !isEmptyFilter(filter?.filterValue);
}

/*
 * Возврашает признак наличия активных (примененных) фильтров.
 */
export const hasActiveFilters = (filters: TFilterConfig[]): boolean =>
  filters.some((filter) =>
    (filter.filterProps as any)?.hasPercentage // eslint-disable-line  @typescript-eslint/no-explicit-any
      ? filter.filterValue.values?.length
      : !isEmptyFilter(filter?.filterValue)
  );

/*
 * Возврашает количество активных (примененных) фильтров.
 */
export const getActiveFiltersCount = (filters: TFilterConfig[]): number =>
  filters.filter((filter) =>
    (filter.filterProps as any)?.hasPercentage // eslint-disable-line  @typescript-eslint/no-explicit-any
      ? filter.filterValue.values?.length
      : !isEmptyFilter(filter.filterValue)
  ).length;

/*
 * На основе списка фильтров создаёт объект-конфигурацию для отправки на backend.
 */
export function makeFilterRequestData(filters: TFilterConfig[]): TAbstractFilterConfig {
  const selectedFilters = filters.filter((filter) => filter.isVisible).map((filter) => filter.id);

  const activeFilters = filters
    .filter(isFilterHasValue)
    .map((filter) => filter.formatValueToDTO!(filter.filterValue)); // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    activeFilters,
    selectedFilters: selectedFilters as InstagramCampaignFilterName[] | YoutubeCampaignFilterName[],
  };
}

const getFilterValue = (
  newFilters: ICampaignFilterDto[],
  currentFilters: TFilterConfig
): TFilter => {
  const existingActiveFilter = newFilters?.find(
    (activeFilter) => activeFilter.name === currentFilters.id
  );

  return existingActiveFilter
    ? currentFilters.formatValueFromDTO!(existingActiveFilter) // eslint-disable-line @typescript-eslint/no-non-null-assertion
    : currentFilters.filterValue;
};

/*
 * Преобразовывает backend модель в модель которую использует компонент фильтров
 */
export const transformFiltersFromDTO = (
  currentFilters: TFilterConfig[],
  newFilters: CampaignFilterConfig
): TFilterConfig[] =>
  currentFilters?.map(
    (filter) =>
      ({
        ...filter,
        isVisible: (newFilters?.selectedFilters as string[])?.includes(filter.id), // TODO: Удалить as string[] разобравшись с типом для id
        filterValue: getFilterValue(newFilters.activeFilters, filter), // устанавливаем либо состояние фильтра полученное с backend либо имеющееся
      } as TFilterConfig)
  );

/*
 * Преобразовывает backend модель в модель которую использует компонент фильтров
 */
export const transformBrowseFiltersFromDTO = (
  currentFilters: TFilterConfig[],
  newFilters: IYoutubeFilterDto[] | ITiktokFilterDto[] | IInstagramFilterDto[]
): TFilterConfig[] =>
  currentFilters?.map(
    (filter) =>
      ({
        ...filter,
        isVisible: true,
        // @ts-ignore TODO Женя как в ICampaignFilterDto появится Tik Tok, можно раскоментить
        filterValue: getFilterValue(newFilters, filter), // устанавливаем либо состояние фильтра полученное с backend либо имеющееся
      } as TFilterConfig)
  );

/*
 * На основе списка фильтров создаёт список switcher'ов для отображения в меню выбора фильтров.
 */
export function getFilterSwitchers(filters: TFilterConfig[]): TOptionsToggleItem[] {
  return filters.map((filter) => ({
    id: filter.id,
    label: filter.label,
    selected: !!filter.isVisible,
    group: filter.group,
    switcherLabel: filter.switcherLabel,
  }));
}

/*
 * Возвращает обновлённый список фильтров на основе обновлённого состояния их видимости.
 */
export function getFiltersWithUpdatedVisibility(
  filters: TFilterConfig[],
  visibilityFiltersState: TOptionsTogglerValue
): TFilterConfig[] {
  return filters.map((filter) => {
    const isVisible = visibilityFiltersState[filter.id];

    return {
      ...filter,
      isVisible,
      filterValue: isVisible ? filter.filterValue : filter.filterInitialValue, // Сохраняем значение фильтра только ели он отображается.
    };
  });
}

/*
 * Возвращает скрытый активный фильтр (если он есть).
 */
export function getHideActiveFilter(
  filters: TFilterConfig[],
  visibilityFiltersState: TOptionsTogglerValue
): TFilterConfig | undefined {
  return filters.find((filter) => {
    const isVisible = visibilityFiltersState[filter.id];

    return !isVisible && isFilterHasValue(filter);
  });
}

/*
 * Возвращает актуализированный список фильтров после изменения какого либо из них.
 */
export function getUpdatedFilters(
  filters: TFilterConfig[],
  changedFilterId: string,
  filterValue: TFilter
): TFilterConfig[] {
  return filters.map((filter) => {
    const isChangedFilter = filter.id === changedFilterId;

    // Если это изменённый фильтр - устанавливаем ему новое значение.
    if (isChangedFilter) {
      return {
        ...filter,
        filterValue,
      };
    }

    // Если это фильтр состояние которого не изменилось - ничего не меняем.
    return filter;
  });
}

/*
 * Преобразовывает строки в boolean
 */
export function stringToBoolean(value = 'false'): boolean {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return false;
  }
}
