/*
 * Константы связанные с подписками.
 */
import { SubscriptionPlanType, SubscriptionStatusType } from '@hypetrainCommon';
import i18n from '@services/i18n';

/*
 * Список статусов подписки при которых приложение доступно.
 */
export const ACTIVE_SUBSCRIPTION_STATUSES: SubscriptionStatusType[] = [
  SubscriptionStatusType.active,
  SubscriptionStatusType.trialing,
  SubscriptionStatusType.unpaid,
  SubscriptionStatusType.pastDue,
];

/*
 * Список статусов подписки при которых приложение не доступно.
 */
export const INACTIVE_SUBSCRIPTION_STATUSES: SubscriptionStatusType[] = [
  SubscriptionStatusType.canceled,
  SubscriptionStatusType.unpaid,
  SubscriptionStatusType.incomplete,
  SubscriptionStatusType.incompleteExpired,
];

/*
 * Маппинг типа тарифного плана на его локализованное имя.
 */
export const SUBSCRIPTION_NAMES_LOCALES: Record<SubscriptionPlanType, string> = {
  [SubscriptionPlanType.universal]: i18n.t('subscriptionPlanNames.universal'),
};
