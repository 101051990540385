import { useStore } from 'effector-react/compat';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { InvitationSignUp } from '@pages/SignUp/InvitationSignUp';
import { $personInvitationStore } from '@pagesPersonInvitation/personInvitation.model';
import { CreateOrgPerson } from '../CreateOrgPerson';
import { $registrationInvitationStore, changeStep } from './model';

export const RegistrationViaInvite: FC = () => {
  const { inviteId = '' } = useParams();
  const { personEmail, organizationName } = useStore($personInvitationStore);
  const step = useStore($registrationInvitationStore);

  const buildContent = () => {
    switch (true) {
      case step === 1:
        return (
          <InvitationSignUp
            inviteId={inviteId}
            email={personEmail}
            organizationName={organizationName}
            action={() => changeStep(2)}
          />
        );
      case step === 2:
        return (
          <CreateOrgPerson
            email={personEmail}
            organizationName={organizationName}
          />
        );
      default:
        return <div />;
    }
  };

  return <>{buildContent()}</>;
};
