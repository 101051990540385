// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".overlay__oiXv2{position:fixed;width:100%;height:100%;left:0;z-index:1}.overlay__oiXv2.localOverlay__vElL0{position:relative}.overlay__oiXv2 .content__cy0hs{position:absolute;width:100%;height:100%;display:flex;align-items:center;justify-content:center}.overlay__oiXv2 .content__cy0hs.localContent__D42cM{position:relative}.overlay__oiXv2 .loader__QhXfB{position:absolute;animation:spin___5Mig 750ms linear infinite}@keyframes spin___5Mig{100%{transform:rotate(360deg)}}.m__SRccb{width:40px}.s__u1prO{width:26px}.xs__O3tkK{width:18px}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Loader/Loader.module.scss"],"names":[],"mappings":"AAEA,gBACE,cAAA,CACA,UAAA,CACA,WAAA,CACA,MAAA,CACA,SAAA,CAEA,oCACE,iBAAA,CAGF,gCACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,oDACE,iBAAA,CAIJ,+BACE,iBAAA,CACA,2CAAA,CAGF,uBACE,KACE,wBAAA,CAAA,CAKN,UACE,UAAA,CAGF,UACE,UAAA,CAGF,WACE,UAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.overlay {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  left: 0;\n  z-index: 1;\n\n  &.localOverlay {\n    position: relative;\n  }\n\n  .content {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    &.localContent {\n      position: relative;\n    }\n  }\n\n  .loader {\n    position: absolute;\n    animation: spin 750ms linear infinite;\n  }\n\n  @keyframes spin {\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n}\n\n.m {\n  width: 40px;\n}\n\n.s {\n  width: 26px;\n}\n\n.xs {\n  width: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "overlay__oiXv2",
	"localOverlay": "localOverlay__vElL0",
	"content": "content__cy0hs",
	"localContent": "localContent__D42cM",
	"loader": "loader__QhXfB",
	"spin": "spin___5Mig",
	"m": "m__SRccb",
	"s": "s__u1prO",
	"xs": "xs__O3tkK"
};
export default ___CSS_LOADER_EXPORT___;
