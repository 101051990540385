import { CELL_POSITION, DefaultCell, TableHeaderCell } from '@uikit/components/Table';
import { TColumn } from '@uikit/components/Table/table.types';
import i18n from '@services/i18n';
import { ICampaignDealListItemCustom } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.types';
import { TargetAudience } from '@components/CommonColumnsTable/TargetAudience';

export const audienceShareCellCampaign: TColumn<ICampaignDealListItemCustom> = {
  width: 100,
  Header: (
    <DefaultCell position={CELL_POSITION.CENTER}>
      <TableHeaderCell text={i18n.t('targetAudience')} />
    </DefaultCell>
  ),
  accessor: 'calculations.targetAudienceShare',
  Cell: ({ row }) => (
    <TargetAudience
      percent={Number(row?.original?.smaStatistics?.calculations?.targetAudienceShare)}
    />
  ),
};
