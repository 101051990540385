/*
 * Компонент системного уведомления (отображается сверху на всю ширину во всём приложении).
 */
import cn from 'clsx';
import { FC } from 'react';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '@uikit/components/Buttons';
import { Icon, ICON_TYPES } from '@uikit/components/Icon';
import styles from './SystemNotification.module.scss';
import { SYSTEM_NOTIFICATION_TYPES, TSystemNotificationAction } from './systemNotification.types';
import { systemNotificationsService } from './systemNotifications.service';
import { TSystemNotificationProps } from './systemNotifications.types';

export const SystemNotification: FC<TSystemNotificationProps> = ({
  id,
  type = SYSTEM_NOTIFICATION_TYPES.WARNING,
  caption,
  closable = true,
  actions,
}) => {
  const onActionClick = (action: TSystemNotificationAction): void => {
    action.onClick();
    action.closeOnClick && systemNotificationsService.hide(id);
  };

  return (
    <div className={cn(styles.systemNotification, styles[type])}>
      <span className={styles.systemNotification__caption}>{caption}</span>
      <div className={styles.systemNotification__right}>
        {!!actions?.length &&
          actions.map((action) => (
            <Button
              key={action.text}
              buttonType={BUTTON_TYPE.TERTIARY}
              size={BUTTON_SIZE.XS}
              onClick={() => onActionClick(action)}
              {...action.button}
              className={cn(styles.systemNotification__button, action.button?.className)}
            >
              {action.text}
            </Button>
          ))}
        {closable && (
          <Icon
            className={styles.systemNotification__close}
            type={ICON_TYPES.CLOSE}
            size={10}
            onClick={() => systemNotificationsService.hide(id)}
          />
        )}
      </div>
    </div>
  );
};
