// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".switcherGroup__ZwwA1>*{padding:var(--hSpacing12) 0}.switcherGroup__ZwwA1>*:not(:last-child){border-bottom:1px solid var(--outlineDivider)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/SwitcherGroup/SwitcherGroup.module.scss"],"names":[],"mappings":"AAGE,wBACE,2BAAA,CAEA,yCACE,6CAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.switcherGroup {\n  & > * {\n    padding: var(--hSpacing12) 0;\n\n    &:not(:last-child) {\n      border-bottom: 1px solid var(--outlineDivider);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switcherGroup": "switcherGroup__ZwwA1"
};
export default ___CSS_LOADER_EXPORT___;
