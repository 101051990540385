/*
 * Switcher group context.
 * Provides API for interaction Switcher with SwitcherGroup.
 */
import { createContext, useContext } from 'react';
import { TSwitcherGroupValues } from './switcherGroup.types';

type TSwitcherGroupContext = {
  switcherGroupValue: TSwitcherGroupValues;
  onChangeSwitcherGroup: (switcherId: string, value: unknown) => void;
} | null;

const SwitcherGroupContext = createContext<TSwitcherGroupContext>(null);

export const SwitcherGroupProvider = SwitcherGroupContext.Provider;

export const useSwitcherGroup = () => useContext(SwitcherGroupContext);
