// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menuSubscriptionBadge__WZuNK{background-color:var(--backgroundAttention);border:none;white-space:nowrap}", "",{"version":3,"sources":["webpack://./components/Background/components/NavigationPanel/components/WorkspacesMenu/components/MenuSubscriptionBadge/MenuSubscriptionBadge.module.scss"],"names":[],"mappings":"AAEA,8BACE,2CAAA,CACA,WAAA,CACA,kBAAA","sourcesContent":["@import '../../../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.menuSubscriptionBadge {\n  background-color: var(--backgroundAttention);\n  border: none;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuSubscriptionBadge": "menuSubscriptionBadge__WZuNK"
};
export default ___CSS_LOADER_EXPORT___;
