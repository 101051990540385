/*
 * Компонент совета/подсказки.
 */
import cn from 'clsx';
import { FC, PropsWithChildren, useRef } from 'react';
import { Icon, ICON_TYPES } from '@uikit/components/Icon';
import { TIP_STYLE } from '@uikit/components/Tip/tip.types';
import styles from './Tip.module.scss';

type TTipProps = {
  icon?: ICON_TYPES; // иконка, отображаемая слева
  onClose?: () => void; // обработчик по нажатию на крест
  style?: TIP_STYLE; // визульный стиль совета
  className?: string;
};

const TIP_CLASS_MAP_BY_STYLES: Record<TIP_STYLE, string> = {
  [TIP_STYLE.DEFAULT]: styles.tip_default,
  [TIP_STYLE.ATTENTION]: styles.tip_attention,
};

export const Tip: FC<PropsWithChildren<TTipProps>> = ({
  icon,
  onClose,
  style = TIP_STYLE.DEFAULT,
  children,
  className,
}) => {
  const tipLabelRef = useRef(null);

  return (
    <div className={cn(styles.tip, TIP_CLASS_MAP_BY_STYLES[style], className)}>
      <div className={styles.tip__leftPart}>
        {icon && (
          <Icon
            className={styles.tip__icon}
            type={icon}
          />
        )}
        <span
          ref={tipLabelRef}
          className={styles.tip__label}
        >
          {children}
        </span>
      </div>

      {onClose && (
        <Icon
          className={styles.tip__closeIcon}
          type={ICON_TYPES.CLOSE}
          size={8}
          onClick={onClose}
        />
      )}
    </div>
  );
};
