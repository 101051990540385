// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tableHeaderCell__bOgL6{color:var(--textSecondary)}.tableHeaderCell__center__gODaN{justify-content:center}.tableHeaderCell__right__u3zhm{justify-content:flex-end}.tableHeaderCell__bOgL6>div{display:flex}.tableHeaderCell__bOgL6 img{padding-left:3px}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Table/components/columns/TableHeaderCell/TableHeaderCell.module.scss"],"names":[],"mappings":"AAEA,wBACE,0BAAA,CAEA,gCACE,sBAAA,CAGF,+BACE,wBAAA,CAGF,4BACE,YAAA,CAGF,4BACE,gBAAA","sourcesContent":["@import '../../../../../assets/styles/ui_kit_mixins';\n\n.tableHeaderCell {\n  color: var(--textSecondary);\n\n  &__center {\n    justify-content: center;\n  }\n\n  &__right {\n    justify-content: flex-end;\n  }\n\n  > div {\n    display: flex;\n  }\n\n  img {\n    padding-left: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeaderCell": "tableHeaderCell__bOgL6",
	"tableHeaderCell__center": "tableHeaderCell__center__gODaN",
	"tableHeaderCell__right": "tableHeaderCell__right__u3zhm"
};
export default ___CSS_LOADER_EXPORT___;
