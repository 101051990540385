/*
  Компонент строки таблицы
 */
import cn from 'clsx';
import { FC, useState } from 'react';
import { Cell } from 'react-table';
import { TTableColumn, TTableRow } from '@uikit/components/Table';
import styles from '@uikit/components/Table/Table.module.scss';
import { TableRowProvider } from '../../tableRow.context';

type TTableRowProps = {
  prepareRow: (row: TTableRow) => void;
  row: TTableRow;
};

export const TableRow: FC<TTableRowProps> = ({ prepareRow, row }) => {
  const [isHovered, setIsHovered] = useState(false);
  prepareRow(row);

  return (
    <TableRowProvider value={{ isHovered }}>
      <tr
        className={cn(styles.tr, row.canExpand && styles.tr_expandable)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => row.toggleRowExpanded(!row.isExpanded)}
        {...row.getRowProps()}
      >
        {row.cells.map((cell: Cell) => (
          <td
            className={cn(styles.td, (cell.column as TTableColumn).className)}
            {...cell.getCellProps()}
          >
            {cell.render('Cell')}
          </td>
        ))}
      </tr>
    </TableRowProvider>
  );
};
