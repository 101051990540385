// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".smaInfoCell__aXzW3{display:flex;align-items:center;overflow:hidden}.smaInfoCell__icon__FYJs7{margin-right:var(--vSpacing12)}", "",{"version":3,"sources":["webpack://./components/TableCells/SmaInfoCell/SmaInfoCell.module.scss"],"names":[],"mappings":"AAEA,oBACE,YAAA,CACA,kBAAA,CACA,eAAA,CAEA,0BACE,8BAAA","sourcesContent":["@import '../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.smaInfoCell {\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n\n  &__icon {\n    margin-right: var(--vSpacing12);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smaInfoCell": "smaInfoCell__aXzW3",
	"smaInfoCell__icon": "smaInfoCell__icon__FYJs7"
};
export default ___CSS_LOADER_EXPORT___;
