/*
 * Модель асинхронного select фильтра
 */
import { createEvent, createStore } from 'effector/compat';
import { SmaPlatform } from '@hypetrainCommon';
import { TSelectOptions } from '@uikit/components/SelectField/SelectField.types';
import { $activeSocialPlatform } from '@models/activeSocialPlatform';

/*
 * Сброс стора с выбранными опциями
 */
export const resetSelectedOptions = createEvent<void>();
/*
 * Сохранение выбранныех опций
 */
export const setSelectedOptions = createEvent<{ type: string; options: TSelectOptions }>();

/*
 * Стор выбранных опций
 */

const INIT_ASYNC_SELECT_FILTERS = {
  [SmaPlatform.Youtube]: {},
  [SmaPlatform.Instagram]: {},
  [SmaPlatform.Tiktok]: {},
  [SmaPlatform.Twitch]: {},
  [SmaPlatform.Facebook]: {},
  [SmaPlatform.Twitter]: {},
};
type TAsyncSelectFilterSelectedOptionsStore = Record<SmaPlatform, Record<string, TSelectOptions>>;

export const $asyncSelectFilterSelectedOptionsStore =
  createStore<TAsyncSelectFilterSelectedOptionsStore>(INIT_ASYNC_SELECT_FILTERS)
    .on(setSelectedOptions, (state, payload) => {
      const activePlatform = $activeSocialPlatform.getState()?.platform || SmaPlatform.Youtube;
      return {
        ...state,
        [activePlatform]: {
          ...state[activePlatform],
          [payload.type]: payload.options,
        },
      };
    })
    .on(resetSelectedOptions, (state) => {
      const activePlatform = $activeSocialPlatform.getState()?.platform || SmaPlatform.Youtube;
      return {
        ...state,
        [activePlatform]: INIT_ASYNC_SELECT_FILTERS[activePlatform],
      };
    });
