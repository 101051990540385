/*
 * Модель профиля YouTube.
 */
import { createEffect, createEvent, createStore } from 'effector/compat';
import { IYoutubeSmaResponseDto } from '@hypetrainCommon';
import { smaApiService } from '@api/sma';

/*
 * Сброс профиля YouTube.
 */
export const resetYoutubeProfile = createEvent<void>();

/*
 * Получение профиля YouTube.
 */
export const getYoutubeProfileFx = createEffect(smaApiService.getYoutubeSmaProfile);

/*
 * Стор профиля YouTube.
 */
export const $profileYoutubeStore = createStore<IYoutubeSmaResponseDto>(
  {} as IYoutubeSmaResponseDto
)
  .on(getYoutubeProfileFx.doneData, (_, payload) => payload)
  .reset(resetYoutubeProfile);
