/*
 * Модель профиля Instagram.
 */
import { createEffect, createEvent, createStore } from 'effector/compat';
import { IInstagramSmaResponseDto } from '@hypetrainCommon';
import { smaApiService } from '@api/sma';

/*
 * Сброс профиля Instagram.
 */
export const resetInstagramProfile = createEvent<void>();

/*
 * Получение профиля Instagram.
 */
export const getInstagramProfileFx = createEffect(smaApiService.getInstagramSmaProfile);

/*
 * Стор профиля Instagram.
 */
export const $profileInstagramStore = createStore<IInstagramSmaResponseDto>(
  {} as IInstagramSmaResponseDto
)
  .on(getInstagramProfileFx.doneData, (_, payload) => payload)
  .reset(resetInstagramProfile);
