// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".scroll__ZvUiG{overflow:auto;height:100%;max-height:100%}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Scroll/Scroll.module.scss"],"names":[],"mappings":"AAEA,eACE,aAAA,CACA,WAAA,CACA,eAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.scroll {\n  overflow: auto;\n  height: 100%;\n  max-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroll": "scroll__ZvUiG"
};
export default ___CSS_LOADER_EXPORT___;
