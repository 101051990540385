/*
 * Сервис лоадеров приложения.
 * TODO: (только глобальных? тогда переиеновать?)
 */
import { LoaderStore, loaderStore } from './loader.store';

export class LoaderService {
  constructor(private readonly store: LoaderStore) {}

  public show(): void {
    this.store.add();
  }

  public hide(): void {
    this.store.remove();
  }
}

const createLoaderService = (loaderStoreInstance: LoaderStore) =>
  new LoaderService(loaderStoreInstance);

export const loaderService = createLoaderService(loaderStore);
