import { createEffect, createEvent, createStore, forward } from 'effector/compat';
import _keyBy from 'lodash/keyBy';
import {
  IInstagramSmaStatisticsSimpleInfoDto,
  IYoutubeSmaStatisticsSimpleInfoDto,
  SmaPlatform,
} from '@hypetrainCommon';
import { TSmaStatisticResponse } from '@api/campaigns';
import { selectionApiService, selectionApiServiceNew } from '@api/selection';
import { prepareInfluencersData } from '@services/tableSmaFormatter';
import { getSocialPlatformBySMAId } from '@utils/socialPlatform.utils';
import { $activeSocialPlatform, setActiveSocialPlatform } from '@models/activeSocialPlatform';
import { profileNoteChanged } from '@components/Profile/profile.model';

export type TYoutubeSmaStatisticsWithDisables = IYoutubeSmaStatisticsSimpleInfoDto & {
  isDisabled?: boolean;
};

export type TInstagramSmaStatisticsWithDisables = IInstagramSmaStatisticsSimpleInfoDto & {
  isDisabled?: boolean;
};

type TInfluencerSelectionChange = {
  isInfluencerSelected: boolean;
  influencer: TYoutubeSmaStatisticsWithDisables | TInstagramSmaStatisticsWithDisables;
};

export type TSmaStatisticsWithDisables = Record<
  string,
  TYoutubeSmaStatisticsWithDisables | TInstagramSmaStatisticsWithDisables
>;

const updateFilteredSmaStore = createEvent<
  TYoutubeSmaStatisticsWithDisables[] | TInstagramSmaStatisticsWithDisables[]
>();

export const getSelectionFx = createEffect(() => {
  const platform = $activeSocialPlatform.getState()?.platform;
  if (!platform) return;

  if (platform === SmaPlatform.Tiktok) {
    return selectionApiServiceNew.getSelection(platform);
  }

  return selectionApiService.getSelection();
});

// Add one person to sma selection
export const addPersonToSelectionFx = createEffect((uuids: string[]) => {
  const platform = $activeSocialPlatform.getState()?.platform;
  if (!platform) return;

  if (platform === SmaPlatform.Tiktok) {
    return selectionApiServiceNew.addToSelection(uuids);
  }
  return selectionApiService.addToSelection(uuids);
});

// Used for all checkbox, to add first 100
export const addAllPersonsToSelectionFx = createEffect((uuids: string[]) => {
  const platform = $activeSocialPlatform.getState()?.platform;
  if (!platform) return;

  if (platform === SmaPlatform.Tiktok) {
    return selectionApiServiceNew.addToSelection(uuids);
  }
  return selectionApiService.addToSelection(uuids);
});

// Delete one person from sma selection
export const deletePersonFromSelectionFx = createEffect(
  (params: { platform: SmaPlatform; smaUuids: string[] }) => {
    const platform = $activeSocialPlatform.getState()?.platform;
    if (!platform) return;

    if (platform === SmaPlatform.Tiktok) {
      return selectionApiServiceNew.deleteFromSelection(params);
    }

    return selectionApiService.deletePersonFromSelection(params);
  }
);

// Delete all from selection
export const deleteAllPersonsFromSelectionFx = createEffect(() => {
  const platform = $activeSocialPlatform.getState()?.platform;
  if (!platform) return;

  if (platform === SmaPlatform.Tiktok) {
    return selectionApiServiceNew.deleteFromSelection({
      platform: $activeSocialPlatform.getState()?.platform as SmaPlatform,
    });
  }

  selectionApiService.deletePersonFromSelection({
    platform: $activeSocialPlatform.getState()?.platform as SmaPlatform,
  });
});

export const exportSelectionFx = createEffect(selectionApiService.exportYoutubeSmas);

export const exportSelectionCsvFx = createEffect((platform: SmaPlatform) => {
  if (platform === SmaPlatform.Tiktok) {
    return selectionApiServiceNew.exportSmasCsv(platform, []);
  }

  return selectionApiService.exportSmasCsv(platform);
});

export const exportSelectionCsvByIdsFx = createEffect(
  (params: { platform: SmaPlatform; smas: string[] }) => {
    if (params.platform === SmaPlatform.Tiktok) {
      return selectionApiServiceNew.exportSmasCsv(params.platform, params.smas);
    }

    return selectionApiService.exportSmasCsvByIds(params);
  }
);

export const exportSelectionByIdsFx = createEffect(selectionApiService.exportYoutubeSmasByIds);

export const addSmaPerson = createEvent<TSmaStatisticsWithDisables>();
export const deleteSmaPerson = createEvent<string>();
export const deleteTemporarySmaPerson = createEvent<string>();

forward({
  from: [addAllPersonsToSelectionFx.done, deleteAllPersonsFromSelectionFx.done],
  to: [getSelectionFx],
});

export const $selectedSmaStore = createStore<TSmaStatisticsWithDisables>({})
  .on(addSmaPerson, (store, payload) => ({ ...store, ...payload }))
  .on(deleteTemporarySmaPerson, (store, payload) => {
    const storeCopy = { ...store };
    storeCopy[payload] = { ...storeCopy[payload], isDisabled: true };
    return storeCopy;
  })
  .on(deleteSmaPerson, (store, payload) => {
    const storeCopy = { ...store };
    delete storeCopy[payload];
    return storeCopy;
  })
  .on(getSelectionFx.doneData, (_, payload) => {
    $activeSocialPlatform.getState()?.isYoutube &&
      prepareInfluencersData(payload as IYoutubeSmaStatisticsSimpleInfoDto[]);

    return _keyBy(payload, 'sma.uuid') as TSmaStatisticsWithDisables;
  })
  .on(profileNoteChanged, (store, payload) => {
    const sma = store[payload.smaId];
    if (sma) {
      sma.sma.notesCount = payload.newCount;
      return {
        ...store,
        [payload.smaId]: {
          ...sma,
        },
      };
    }

    return store;
  });

$selectedSmaStore.updates.watch((store) =>
  updateFilteredSmaStore(
    Object.values(store).filter((selected) => !selected?.isDisabled) as TSmaStatisticResponse
  )
);

export const $filteredSmaStore = createStore<
  TYoutubeSmaStatisticsWithDisables[] | TInstagramSmaStatisticsWithDisables[]
>([]).on(updateFilteredSmaStore, (_, payload) => payload);

/*
 * Удаление или добавление sma в стор
 */
export const influencerSelectionChangeFx = createEffect(
  ({ isInfluencerSelected, influencer }: TInfluencerSelectionChange) => {
    if (isInfluencerSelected) {
      deleteSmaPerson(influencer?.uuid);
      deletePersonFromSelectionFx({
        platform: getSocialPlatformBySMAId(influencer?.uuid),
        smaUuids: [influencer?.uuid],
      });
      return;
    }

    addSmaPerson({ [influencer?.uuid]: influencer });
    addPersonToSelectionFx([influencer?.uuid]);
  }
);

forward({
  from: setActiveSocialPlatform,
  to: getSelectionFx,
});
