import cn from 'clsx';
import { useStore } from 'effector-react/compat';
import { FC, ReactElement, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@uikit/components/ErrorBoundary';
import { $isGroupActionsVisibleStore } from '@uikit/components/GroupActions';
import logoMobile from '@assets/images/logoMobile.svg';
import { Routes } from '@router/Routes';
import { hasOtherActiveWorkspace } from '@utils/workspace.utils';
import { $activeWorkspaceStore } from '@models/activeWorkspace';
import { $workspacesStore } from '@models/workspaces/workspaces.model';
import { BrandLayout } from '@components/Background/components/BrandLayout';
import { NavigationPanel } from '@components/Background/components/NavigationPanel';
import { UnsupportedMobile } from '@components/UnsupportedMobile';
import styles from './Background.module.scss';

type TBackgroundProps = {
  children: ReactElement;
};

// Роуты по которым сбоку отображается заглушка вместо меню.
const ROUTERS_WITH_BRAND = [
  '/login',
  '/join',
  '/join/email',
  '/restore/reset-password',
  '/join/account',
  '/join/invite',
  '/create-workspace',
  '/edit-workspace',
  '/create-workspace/person',
  '/restore/change-password',
  '/no-access',
  '/choseTariffPlan',
];

// Роуты по которым сбоку отображается заглушка вместо меню (для кейса пользователя с несколькими воркспейсами).
const ROUTERS_WITH_BRAND_SEVERAL_ACTIVE_WORKSPACES = ROUTERS_WITH_BRAND.filter(
  (r) => !['/no-access', '/choseTariffPlan'].includes(r)
);

const ROUTERS_WITH_INNER_SCROLL = [
  Routes.workspaceCreation,
  Routes.editWorkspace,
  Routes.choseTariffPlan,
];

export const Background: FC<TBackgroundProps> = memo(({ children }) => {
  const { pathname } = useLocation();
  const workspaces = useStore($workspacesStore);
  const activeWorkspace = useStore($activeWorkspaceStore);
  const isGroupActionsVisible = useStore<boolean>($isGroupActionsVisibleStore);
  // TODO: Очень плохо. Переписать архитектуру этих компонентов-обёрток. Они на таком высоком уровне не должны знать про это.
  const routersWithBrand = hasOtherActiveWorkspace(activeWorkspace, workspaces)
    ? ROUTERS_WITH_BRAND_SEVERAL_ACTIVE_WORKSPACES
    : ROUTERS_WITH_BRAND;

  const getBlockWithBrand = () => (
    <div className={styles.wrapper}>
      <div className={styles.side}>
        <BrandLayout />
      </div>
      <div
        className={cn(
          styles.content,
          ROUTERS_WITH_INNER_SCROLL.includes(pathname) && styles.content__innerScroll
        )}
      >
        <div
          className={cn(
            styles.block,
            ROUTERS_WITH_INNER_SCROLL.includes(pathname) && styles.block__innerScroll
          )}
        >
          <div className={styles.logo}>
            <img
              src={logoMobile}
              alt="logo"
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );

  const getMainBlock = () => (
    <UnsupportedMobile>
      <>
        <ErrorBoundary
          layerName="navigationPanel"
          errorStubClassName={styles.navifationPanel_error}
        >
          <NavigationPanel />
        </ErrorBoundary>
        <div
          className={cn(
            styles.mainBlock,
            pathname === Routes.choseTariffPlan && styles.mainBlock__innerScroll,
            isGroupActionsVisible && styles.mainBlock_withGroupActions
          )}
        >
          <ErrorBoundary layerName="pageGlobal">{children}</ErrorBoundary>
        </div>
      </>
    </UnsupportedMobile>
  );

  return (
    <main className={styles.main}>
      {routersWithBrand.some((v) => pathname.includes(v)) ? getBlockWithBrand() : getMainBlock()}
    </main>
  );
});
