// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notificationsList__ozjS9{position:fixed;z-index:var(--notificationZIndex);bottom:0;left:0;white-space:break-spaces;margin:var(--hSpacing20) var(--vSpacing20) var(--hSpacing12)}.notification__F6nbG{margin-bottom:var(--hSpacing12)}.notificationEnter__YjpAb{opacity:0}.notificationEnterActive___ONAI{opacity:1;transition:opacity 300ms ease-in}.notificationExit__UN2Ri{opacity:1}.notificationExitActive__GEjoL{opacity:0;transition:opacity 300ms ease-in}.listItemEnter__f4HeX{transform:translateY(64px)}.listItemEntering__BRSUi{transform:translateY(0);transition:transform 300ms ease-out}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Notification/components/NotificationsList/NotificationsList.module.scss"],"names":[],"mappings":"AAEA,0BACE,cAAA,CACA,iCAAA,CACA,QAAA,CACA,MAAA,CACA,wBAAA,CACA,4DAAA,CAGF,qBACE,+BAAA,CAGF,0BACE,SAAA,CAGF,gCACE,SAAA,CACA,gCAAA,CAGF,yBACE,SAAA,CAGF,+BACE,SAAA,CACA,gCAAA,CAGF,sBACE,0BAAA,CAGF,yBACE,uBAAA,CACA,mCAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.notificationsList {\n  position: fixed;\n  z-index: var(--notificationZIndex);\n  bottom: 0;\n  left: 0;\n  white-space: break-spaces;\n  margin: var(--hSpacing20) var(--vSpacing20) var(--hSpacing12);\n}\n\n.notification {\n  margin-bottom: var(--hSpacing12);\n}\n\n.notificationEnter {\n  opacity: 0;\n}\n\n.notificationEnterActive {\n  opacity: 1;\n  transition: opacity 300ms ease-in;\n}\n\n.notificationExit {\n  opacity: 1;\n}\n\n.notificationExitActive {\n  opacity: 0;\n  transition: opacity 300ms ease-in;\n}\n\n.listItemEnter {\n  transform: translateY(64px);\n}\n\n.listItemEntering {\n  transform: translateY(0);\n  transition: transform 300ms ease-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationsList": "notificationsList__ozjS9",
	"notification": "notification__F6nbG",
	"notificationEnter": "notificationEnter__YjpAb",
	"notificationEnterActive": "notificationEnterActive___ONAI",
	"notificationExit": "notificationExit__UN2Ri",
	"notificationExitActive": "notificationExitActive__GEjoL",
	"listItemEnter": "listItemEnter__f4HeX",
	"listItemEntering": "listItemEntering__BRSUi"
};
export default ___CSS_LOADER_EXPORT___;
