/*
 * Global dialogs store.
 */
import { makeAutoObservable } from 'mobx';
import { FunctionComponent } from 'react';
import { TDialogProps } from './dialog.types';

export type TDialogConfig<P = Record<never, never>, D = never, R = never> = {
  id: string;
  component: FunctionComponent<TDialogProps<P, D, R>>;
  props: TDialogProps<P, D, R>;
};

export class DialogStore {
  /*
   * Displayed dialogs.
   */
  public dialogs: TDialogConfig[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  /*
   * Add dialog for display.
   */
  public add<P, D, R>(config: TDialogConfig<P, D, R>): void {
    this.dialogs.push(config as TDialogConfig<unknown, D, R>);
  }

  /*
   * Delete (hide) dialog.
   */
  public delete(id: string): void {
    this.dialogs = this.dialogs.filter((dialog) => dialog.id !== id);
  }
}

export const dialogStore = new DialogStore();
