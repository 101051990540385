/*
 * EventBus таблиц
 */
import { EventBus } from '../../utils/eventBus';

/*
 * ивенты поддерживаемые EventBus таблиц
 */
export enum TABLE_EVENTS {
  // ивент скролла к верху таблицы
  SCROLL_TO_TOP = 'SCROLL_TO_TOP',
}
export const tableEventBus = new EventBus();
