/*
 * Сервис разграничения доступа пользователя к функционалу адресной книгой.
 */
import {
  IPersonInfoResponseDto,
  IPersonSimpleInfoDto,
  PersonPartyType,
  PersonRegistrationType,
} from '@hypetrainCommon';
import { isWorkspaceOwner } from '@utils/workspace.utils';
import { UserAccessService, userAccessService } from './userAccess.service';
import { TUsetRights } from './userAccess.service.types';

export class AddressBookUserAccessService {
  private get userRights(): TUsetRights {
    return this.userAccess.userRights;
  }

  /*
   * Может ли пользователь работать с адресной книгой.
   */
  public get isAvailable(): boolean {
    return this.userAccess.hasAddressBookRightGroup;
  }

  /*
   * Может ли пользователь изменить статус персоны (активна-не активна) в аддресной книге.
   */
  public get changeUserPersonStatus(): boolean {
    return Boolean(this.userRights.ChangeUserPersonStatus);
  }

  /*
   * Может ли пользователь запрашивать список персон в адресной книге.
   */
  public get getPersons(): boolean {
    return Boolean(this.userRights.GetPersons);
  }

  /*
   * Может ли пользователь создать персону связанную с компанией, не являющуюся членом текущего воркспейса (персона добавляется в аддрессную книгу через серкцию "company").
   */
  private get createOwnRepresentativePerson(): boolean {
    return Boolean(this.userRights.CreateOwnRepresentativePerson);
  }

  /*
   * Может ли пользователь создать персону-контрагента (инфлюенсер, представитель инфлюенсера и тд).
   */
  private get createCounterpartyRepresentativePerson(): boolean {
    return Boolean(this.userRights.CreateCounterpartyRepresentativePerson);
  }

  /*
   * Может ли пользователь удалить персону связанную с компанией, не являющуюся членом текущего воркспейса (персону добавленную в аддрессную книгу через серкцию "company").
   */
  private get deleteOwnRepresentativePerson(): boolean {
    return Boolean(this.userRights.DeleteOwnRepresentativePerson);
  }

  /*
   * Может ли пользователь удалить персону-контрагента (инфлюенсер, представитель инфлюенсера и тд).
   */
  private get deleteCounterpartyRepresentativePerson(): boolean {
    return Boolean(this.userRights.DeleteCounterpartyRepresentativePerson);
  }

  /*
   * Может ли пользователь обновить персону-члена своего воркспейса (другой менеджер агенства, бухгалтер агенства и тд).
   */
  private get updateOwnUserPerson(): boolean {
    return Boolean(this.userRights.UpdateOwnUserPerson);
  }

  /*
   * Может ли пользователь обновить владельца воркспейса.
   */
  private get updateWorkspaceOwner(): boolean {
    return Boolean(this.userRights.EditWorkspaceOwner);
  }

  /*
   * Может ли пользователь обновить персону связанную с компанией, не являющуюся членом текущего воркспейса (персону добавленную в аддрессную книгу через серкцию "company").
   */
  private get updateOwnRepresentativePerson(): boolean {
    return Boolean(this.userRights.UpdateOwnRepresentativePerson);
  }

  /*
   * Может ли пользователь обновить персону-контрагента (инфлюенсер, представитель инфлюенсера и тд).
   */
  private get updateCounterpartyPerson(): boolean {
    return Boolean(this.userRights.UpdateCounterpartyPerson);
  }

  constructor(private readonly userAccess: UserAccessService) {}

  /*
   * Может ли пользователь создавать персону.
   */
  public canCreatePerson(isOwnRepresentative?: boolean): boolean {
    return isOwnRepresentative
      ? this.createOwnRepresentativePerson
      : this.createCounterpartyRepresentativePerson;
  }

  /*
   * Может ли пользователь обновить персону.
   */
  public canUpdatePerson(person: IPersonSimpleInfoDto | IPersonInfoResponseDto): boolean {
    if (person.registrationType === PersonRegistrationType.user) {
      return isWorkspaceOwner(person) ? this.updateWorkspaceOwner : this.updateOwnUserPerson;
    }

    return person.partyType === PersonPartyType.own
      ? this.updateOwnRepresentativePerson
      : this.updateCounterpartyPerson;
  }

  /*
   * Может ли пользователь удалить персону.
   */
  public canDeletePerson(person: IPersonInfoResponseDto): boolean {
    return person?.partyType === PersonPartyType.counterparty
      ? this.deleteCounterpartyRepresentativePerson
      : this.deleteOwnRepresentativePerson;
  }
}

const createAddressBookUserAccessService = (userAccess: UserAccessService) =>
  new AddressBookUserAccessService(userAccess);

export const addressBookUserAccessService = createAddressBookUserAccessService(userAccessService);
