import { FC } from 'react';
import { ListItem } from '@uikit/components/ListItem';
import { TUsersListItem } from '@components/UsersList';
import styles from './UsersListTooltip.module.scss';

type TUsersListTooltipProps = {
  users: TUsersListItem[];
};

export const UsersListTooltip: FC<TUsersListTooltipProps> = ({ users }) => (
  <div className={styles.usersListTooltip}>
    {users?.map((user, i) => (
      <ListItem
        key={user.id}
        mainText={user.name}
        avatar={{
          idForColor: user.id,
          img: user.thumbnail,
          entityName: user.name,
          className: user.avatarClassName,
        }}
        isLast={i === users.length - 1}
        isSelectable={false}
      />
    ))}
  </div>
);
