import { SmaPlatform } from '@hypetrainCommon';
import { DefaultCell, TableHeaderCell } from '@uikit/components/Table';
import { TColumn } from '@uikit/components/Table/table.types';
import i18n from '@services/i18n';
import { ICampaignDealListItemCustom } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.types';
import { getIsContact } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.utils';
import { LastMessage } from '@pagesCampaigns/CampaignDetailsPage/components/Communication/components/LastMessage';
import { SelectCampaignAllCheckbox } from '@pagesCampaigns/CampaignDetailsPage/components/SelectCampaignAllCheckbox';
import { ChannelInfoColumn } from '@components/CommonColumnsTable/ChannelInfoColumn';
import { TChannelInfoColumnData } from '@components/CommonColumnsTable/ChannelInfoColumn/ChannelInfoColumn.component';
import { SelectCampaignCheckbox } from '../SelectCampaignCheckbox';
import styles from './Communication.module.scss';

export const getCommunicationColumns = (
  platform: SmaPlatform
): TColumn<ICampaignDealListItemCustom>[] => [
  {
    width: 40,
    canResize: false,
    Header: <SelectCampaignAllCheckbox />,
    accessor: 'selection',
    Cell: ({ row }) => (
      <>
        <SelectCampaignCheckbox
          campaignId={row?.original?.id}
          className={styles.tableCheckbox}
        />
        <div className={styles.numbering}>{Number(row.id) + 1}</div>
      </>
    ),
  },
  {
    width: 230,
    Header: (
      <DefaultCell>
        <TableHeaderCell text={i18n.t(`smaTable.${platform}.info`)} />
      </DefaultCell>
    ),
    accessor: 'channelInfo',
    Cell: ({ row }) => (
      <ChannelInfoColumn
        className={styles.channelInfoCampaign}
        info={row?.original?.smaStatistics?.sma || ({} as TChannelInfoColumnData)}
        highlightedString={row?.original?.highlightedString}
        dealId={row?.original?.id}
        unreadMessageCount={row?.original?.unreadCount}
        isContact={getIsContact(row?.original)}
      />
    ),
  },
  {
    minWidth: 400,
    Header: (
      <DefaultCell>
        <TableHeaderCell text={i18n.t('communication.communicationTable.lastMessage')} />
      </DefaultCell>
    ),
    accessor: 'lastMessage',
    Cell: ({ row }) => (
      <DefaultCell>
        <LastMessage
          message={row.original?.chat?.latestMessage}
          attachFiles={
            row?.original.chat?.latestMessage && 'attachments' in row.original.chat.latestMessage
              ? row.original?.chat?.latestMessage?.attachments
              : undefined
          }
          hasContact={getIsContact(row?.original)}
          platform={platform}
          countPerson={row?.original?.persons?.length}
        />
      </DefaultCell>
    ),
  },
];
