/* Компонент для отрисовки графика пол/возраст
 Используется как в таблице так и в профайле.
 @see https://www.figma.com/file/jl6mAOWEYarcQ4TvtFIjMy/YouTube-profile?node-id=199%3A6627
 */
import cn from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SmaPlatform } from '@hypetrainCommon';
import { ChartCard } from '@uikit/components/ChartCard';
import { EMPTY_BLOCK_POSITION, EmptyBlock } from '@uikit/components/EmptyBlock';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { Popup, PopupPositions } from '@uikit/components/Popup';
import { Table } from '@uikit/components/Table';
import {
  TGenders,
  TGendersPerAges,
  TMaxAgeAudienceDemographic,
} from '../AudienceDemographic.types';
import styles from './AudienceDemographic.module.scss';
import { getAudienceDemographicsColumn } from './AudienceDemographics.column';
import { AudienceLines } from './AudienceLines.component';

type TAudienceDemographicsProps = {
  isProfile: boolean;
  gendersPerAges: TGendersPerAges;
  views: number;
  filterAudience: TFilter;
  constAges: string[];
  maxAge: TMaxAgeAudienceDemographic;
  genders: TGenders;
  uuid: string;
  info: string;
  selectedTab?: string;
  platform: SmaPlatform;
  className?: string;
};

export const AudienceDemographics: FC<TAudienceDemographicsProps> = ({
  isProfile,
  constAges,
  gendersPerAges,
  views,
  filterAudience,
  maxAge,
  genders,
  uuid,
  info,
  selectedTab,
  platform,
  className,
}) => {
  const { t } = useTranslation();
  if (isEmpty(gendersPerAges) && !isProfile) {
    return <EmptyBlock position={EMPTY_BLOCK_POSITION.CENTER} />;
  }

  const demographicContentPopup = (
    <ChartCard
      className={cn(
        styles.audienceDemographics,
        isProfile && styles.audienceDemographics__profile,
        className
      )}
      name={t('profile.audience.demographics')}
      isNoData={isEmpty(gendersPerAges)}
      info={isProfile ? info : undefined}
    >
      <Table
        data={constAges}
        className={styles.demographicTable}
        columns={getAudienceDemographicsColumn({
          gendersPerAges,
          views,
          filterAudience,
          maxAge,
          genders,
          selectedTab,
          platform,
        })}
      />
    </ChartCard>
  );

  if (isProfile) {
    return demographicContentPopup;
  }

  return (
    <Popup
      content={demographicContentPopup}
      position={PopupPositions.RightCenter}
      offset={20}
    >
      <AudienceLines
        filterAudience={filterAudience}
        maxAge={maxAge}
        gendersPerAges={gendersPerAges}
        uuid={uuid}
      />
    </Popup>
  );
};
