/*
 * Configurable list item.
 * @see https://www.figma.com/file/TCZxcHBXR1hXEvOco2kqnc/UI-Kit-Hypetrain?node-id=1755%3A3130
 */
import cn from 'clsx';
import _omit from 'lodash/omit';
import { FC, RefCallback } from 'react';
import { OptionProps } from 'react-select';
import { Avatar, TAvatarProps } from '@uikit/components/Avatar';
import { Budge } from '@uikit/components/Budge';
import { CheckboxField } from '@uikit/components/Fields/CheckboxField';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { TextEllipsis } from '@uikit/components/TextEllipsis';
import { noop } from '@uikit/helpers/common.helpers';
import styles from './ListItem.module.scss';

type TListItemProps = {
  mainText: string | JSX.Element; // TODO При первом использовании с JSX.Element проверить не мешает ли TextEllipsis
  secondaryText?: string | JSX.Element; // При первом использовании с JSX.Element проверить не мешает ли TextEllipsis
  avatar?: TAvatarProps; // props for avatar show
  innerProps?: unknown | OptionProps; // В первую очередь для поддерки работы с react-select
  innerRef?: RefCallback<HTMLDivElement>; // В первую очередь для поддерки работы с react-select
  budge?: string;
  isMulti?: boolean; // sign that item is part in multiselect list
  isSelected?: boolean; // sign selected item
  isDisabled?: boolean; // sign disabled item
  isSelectable?: boolean; // sign that item can be select
  isLast?: boolean; // sign last item in items list
  className?: string;
  nameCheckbox?: string; // Уникальный id для работы с группой чекбоксов из ListItem
  onClick?: () => void;
};

const LIST_ITEM_CLASS_NAME = 'LIST_ITEM'; // используется в стилях select dropdown

export const ListItem: FC<TListItemProps> = ({
  innerProps,
  innerRef,
  mainText,
  secondaryText,
  avatar,
  budge,
  isSelected,
  isDisabled,
  isSelectable = true,
  isLast = false,
  isMulti,
  className,
  nameCheckbox,
  onClick,
}) => (
  <div
    className={cn(
      styles.listItem__container,
      isLast && styles.listItem_last,
      isMulti && styles.listItem_isMulti,
      isDisabled && styles.listItem_disabled,
      !isSelectable && styles.listItem_notSelectable,
      LIST_ITEM_CLASS_NAME
    )}
  >
    <div
      className={cn(styles.listItem, className)}
      ref={innerRef}
      onClick={onClick}
      // @ts-ignore TODO Никита, вылезла ошибка после обновления либ, пока не могу понять с чем связана
      {...innerProps}
    >
      <div className={styles.listItem__leftPart}>
        {isMulti && (
          <CheckboxField
            className={styles.listItem__checkbox}
            isDisabled={isDisabled}
            input={{
              name: nameCheckbox ?? 'listItemCheckbox',
              checked: isSelected,
              onChange: noop,
              onFocus: noop,
              onBlur: noop,
              value: '',
            }}
          />
        )}

        {avatar && (
          <Avatar
            className={cn(styles.listItem__avatar, avatar.className)}
            isDisabled={isDisabled}
            {..._omit(avatar, 'className')}
          />
        )}

        <div className={styles.listItem__mainText}>
          <TextEllipsis>{mainText}</TextEllipsis>
        </div>

        <div className={styles.listItem__secondaryText}>
          <TextEllipsis>{secondaryText}</TextEllipsis>
        </div>
      </div>

      <div className={styles.listItem__rightPart}>
        {budge && <Budge className={styles.listItem__budge}>{budge}</Budge>}

        {isSelected && !isMulti && (
          <Icon
            className={styles.listItem__selectMark}
            type={ICON_TYPES.CHECK}
          />
        )}
      </div>
    </div>
  </div>
);
