// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectFieldDropdown__IaZo9{box-shadow:var(--shadow2);border-radius:var(--vSpacing8);margin-top:var(--hSpacing8)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/SelectField/components/SelectFieldDropdown/SelectFieldDropdown.module.scss"],"names":[],"mappings":"AAEA,4BACE,yBAAA,CACA,8BAAA,CACA,2BAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.selectFieldDropdown {\n  box-shadow: var(--shadow2);\n  border-radius: var(--vSpacing8);\n  margin-top: var(--hSpacing8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectFieldDropdown": "selectFieldDropdown__IaZo9"
};
export default ___CSS_LOADER_EXPORT___;
