// Хук для получения аудиторных стран из фильтров
import { useMemo } from 'react';
import { TFilterConfig } from '@uikit/components/Filter';
import { TSelectFilterConfig } from '@uikit/components/Filter/components/SelectFilter';
import { EFilterName, TFilter } from '@uikit/components/Filters/filters.types';
import { useGetBrowseFiltersByPlatform } from '@hooks/useGetBrowseFiltersByPlatform';

export const useCountriesFilters = (): TFilter => {
  const browseFilters = useGetBrowseFiltersByPlatform();

  return useMemo(
    () =>
      (
        browseFilters?.find(
          (filter) => filter.id === EFilterName.AudienceCountries
        ) as TFilterConfig<TSelectFilterConfig>
      )?.filterValue,
    [browseFilters]
  );
};
