// Хук для получения аудиторного возраста из фильтров
import { useMemo } from 'react';
import { InstagramFilterName, YoutubeFilterName } from '@hypetrainCommon';
import { TFilterConfig } from '@uikit/components/Filter';
import { TRadioGroupFilterConfig } from '@uikit/components/Filter/components/RadioGroupFilter';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { useGetBrowseFiltersByPlatform } from '@hooks/useGetBrowseFiltersByPlatform';

export const useGenderFiltersHook = (): TFilter => {
  const browseFilters = useGetBrowseFiltersByPlatform();

  return useMemo(
    () =>
      (
        browseFilters.find(
          (filter) =>
            filter.id === YoutubeFilterName.AudienceAges || InstagramFilterName.AudienceAges
        ) as TFilterConfig<TRadioGroupFilterConfig>
      )?.filterValue,
    [browseFilters]
  );
};
