// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".groupHeader__sBRba{margin-left:var(--vSpacing8)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/SelectField/components/GroupHeader/GroupHeader.module.scss"],"names":[],"mappings":"AAEA,oBACE,4BAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.groupHeader {\n  margin-left: var(--vSpacing8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groupHeader": "groupHeader__sBRba"
};
export default ___CSS_LOADER_EXPORT___;
