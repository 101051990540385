import i18n from 'i18next';
import { SystemMessageType } from '@hypetrainCommon';

const SUPPORTED_SYSTEM_MESSAGE: Record<string, SystemMessageType> = {
  personAdded: SystemMessageType.personAdded,
};

export const getSystemMessage = (format: SystemMessageType): string =>
  SUPPORTED_SYSTEM_MESSAGE[format] &&
  i18n.t(`communication.systemMessage.${SUPPORTED_SYSTEM_MESSAGE[format]}`);
