// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".radioGroup__ZzPcC{margin-top:var(--hSpacing16)}.radioGroup__ZzPcC>*{margin-bottom:var(--hSpacing16)}.radioGroup__vertical__uG_Ei{flex-direction:column}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/RadioGroup/RadioGroup.module.scss"],"names":[],"mappings":"AAEA,mBACE,4BAAA,CAEA,qBACE,+BAAA,CAGF,6BACE,qBAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.radioGroup {\n  margin-top: var(--hSpacing16);\n\n  & > * {\n    margin-bottom: var(--hSpacing16);\n  }\n\n  &__vertical {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioGroup": "radioGroup__ZzPcC",
	"radioGroup__vertical": "radioGroup__vertical__uG_Ei"
};
export default ___CSS_LOADER_EXPORT___;
