/*
 * Service for executing requests for entities in selection.
 */
import { ApiService, apiService } from '@api';
import {
  IInstagramSmaResponseDto,
  ISetSmaNoteRequestDto,
  IYoutubeSmaResponseDto,
} from '@hypetrainCommon';
import { PROFILE_ERROR_SLUGS } from '@api/sma/sma.api.constants';

/* eslint-disable arrow-body-style */
export class SmaApiService {
  constructor(private readonly api: ApiService) {}

  /*
   * Получение youtube sma
   * @see: https://reference.api.rc-staging.hypetrain.io/#hypetrain-api-sma
   */
  public getYoutubeSmaProfile = (uuid: string): Promise<IYoutubeSmaResponseDto> => {
    return this.api
      .get(`/sma/youtube/${uuid}`, {}, { showLoader: false })
      .then((response) => response.data);
  };

  /*
   * Получение instagram sma
   * @see: https://reference.api.test.hypetrain.io/?http#get-instagram-sma-info-by-external-id
   */
  public getInstagramSmaProfile = (uuid: string): Promise<IInstagramSmaResponseDto> => {
    return this.api
      .get(
        `/sma/instagram/${uuid}`,
        {},
        {
          showLoader: false,
          errorCodesToIgnore: PROFILE_ERROR_SLUGS,
        }
      )
      .then((response) => response.data);
  };

  /*
   * Сохранение заметки по SMA.
   * @see: https://reference.api.test.hypetrain.io/?http#set-sma-note
   */
  public setSmaNote = (body: ISetSmaNoteRequestDto): Promise<void> => {
    return this.api.post(`/sma/note`, body).then((response) => response.data);
  };
}

export const smaApiService = new SmaApiService(apiService);
