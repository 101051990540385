import { useStore } from 'effector-react/compat';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import arrowLink from '@assets/images/arrowLink.svg';
import { Routes } from '@router/Routes';
import { logoutUserFx } from '@models/auth.model';
import { $userProfile } from '@models/profile';
import { TMenuItemProps } from '@components/Background/components/NavigationPanel/components/MenuWrapper/MenuWrapper';
import { TMenuDefaultItemProps } from '@components/Background/components/NavigationPanel/components/MenuWrapper/components/MenuDefaultItem/MenuDefaultItem.component';
import { MenuWrapper } from '../MenuWrapper';
import styles from './ProfileMenu.module.scss';

export const ProfileMenu = () => {
  const profile = useStore($userProfile);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const header = <h4 className={styles.email}>{profile?.email}</h4>;

  const menuItems: TMenuItemProps<TMenuDefaultItemProps>[] = [
    {
      id: 'logout',
      text: t('navigation.logOut'),
      action: () => logoutUserFx(),
    },
  ];

  profile?.hasPassword &&
    menuItems.unshift({
      id: 'changePassword',
      text: t('navigation.changePassword'),
      textRightIcon: arrowLink,
      action: () => navigate(Routes.changePassword),
    });
  return (
    <MenuWrapper
      header={header}
      menuItems={menuItems}
    />
  );
};
