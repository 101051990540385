/*
 * Input filter component
 */
import { FC, memo, useEffect, useRef, useState } from 'react';
import { Input } from '@uikit/components/Fields/Inputs';
import { Filter, TFilterConfig } from '@uikit/components/Filter';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { TPopupAPI } from '@uikit/components/Popup';
import { noop } from '@uikit/helpers/common.helpers';
import styles from './InputFilter.module.scss';

export type TInputFilterConfig = { placeholder: string };

type TInputFilterProps = {
  config: TFilterConfig<TInputFilterConfig>;
  onApply: (value: TFilter) => void;
  applyOnClose: boolean;
};

export const InputFilter: FC<TInputFilterProps> = memo(({ config, onApply, applyOnClose }) => {
  const [filterValue, setFilterValue] = useState<TFilter>(config?.filterValue);
  const popupRef = useRef<TPopupAPI>(null);

  useEffect(() => {
    setFilterValue(config.filterValue);
  }, [config.filterValue]);

  const onClearCallback = () => {
    setFilterValue(config?.filterInitialValue);
    onApply(config?.filterInitialValue);
  };

  const onChange = (value: string): void => {
    setFilterValue({ ...filterValue, values: value ? [value] : [] });
  };

  const onKeyDown = (key: string): void => {
    if (key === 'Enter') {
      (popupRef.current as TPopupAPI).toggle();
      onApply(filterValue);
    }
  };

  return (
    <Filter
      config={config}
      viewValue={config.filterValue.values?.[0] || ''}
      onClear={onClearCallback}
      onApply={() => onApply(filterValue)}
      outerRef={popupRef}
      applyOnClose={applyOnClose}
    >
      <div className={styles.inputFilter}>
        <Input
          input={{
            onBlur: noop,
            name: 'inputFilter',
            onChange: (e) => onChange(e.target.value),
            onFocus: noop,
            value: filterValue.values?.[0] || '',
          }}
          placeholder={config.filterProps.placeholder}
          onKeyDown={(e) => onKeyDown(e.key)}
          hideError
        />
      </div>
    </Filter>
  );
});
