/*
 * A hook that tracks a click anywhere outside the target element.
 */
import { RefObject, useEffect, useRef } from 'react';
import { APP_PORTAL_CONTAINER_ID, CLOSE_PORTAL_NODE_ID } from '@uikit/constants/common.constants';

export const useClickAway = <T extends HTMLElement>(
  callback: EventListener, // called on click outside the container returned by the hook and exception nodes (if any)
  ignoreRefs: RefObject<HTMLElement>[] = [], // list of exception refs not handled by the hook.
  closeByClickOnOtherPortal = false // признак необходимости закрытия при клике по другой сущности открытой в портале (диалога, модалке и тд)
): RefObject<T> => {
  const container = useRef<T>(null);
  const portalContainer = document.body.querySelector(`#${APP_PORTAL_CONTAINER_ID}`) as HTMLElement;

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      const targetNode = event.target as HTMLElement;
      const containerNode = container.current;

      if (!targetNode || !containerNode) return;

      const excludedNodes = [containerNode, ...ignoreRefs.map((ref) => ref.current)];

      if (!closeByClickOnOtherPortal) {
        excludedNodes.push(portalContainer);
      }

      /*
       * Вызывыет callback, если нода на которой произошло событие не входит в список исключений, среди которых:
       * 1. Сам контейнер, за пределыми которого отслеживается клик.
       * Это необходимо, например, что бы по клику на содержимое диалога он не закрывался.
       * 2. Если closeByClickOnOtherPortal = false, Контейнер порталов, в котором размещаются всплывающие окна и диалоги.
       * Это необходимо, например, что бы при клике по tooltip, отображённому в далоге,
       * сам диалог не закрывался (tooltip будт находиться за пределами диалога - в другом портале).
       * 3. Список узлов для игнорирования (если передан).
       */
      if (!excludedNodes.some((node) => node?.contains(targetNode))) {
        callback(event);
      }

      /*
       * Если узел на котором произошло событие отмечен как узел вызывающий закрытие портала - вызывается callback.
       * Например, клик по фону диалогового окна должен вызывать закрытие окна, однако этого не произойдёт,
       * так фон вместе с диалогом находится в контейнере порталов, клики по которому игнорируются.
       */
      if (targetNode.id === CLOSE_PORTAL_NODE_ID) {
        callback(event);
      }
    };

    document.addEventListener('mousedown', onClick);

    return () => {
      document.removeEventListener('mousedown', onClick);
    };
  }, [callback]);

  return container;
};
