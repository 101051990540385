// Компонент отрисовывает значения TMale или TFemale
import cn from 'clsx';
import { FC } from 'react';
import { FORMAT_NUMBER_NOTATION, FormatNumber } from '@uikit/components/Formats';
import { fractionToInteger } from '@uikit/utils/number.utils';
import { DISPLAYED_AGES, SEX_DEMOGRAPHIC, TGendersPerAges } from '../AudienceDemographic.types';
import styles from './AudienceDemographic.module.scss';

type TDemographicVisitorsProps = {
  gendersPerAges: TGendersPerAges;
  rangeAge: DISPLAYED_AGES;
  views: number;
  sex: SEX_DEMOGRAPHIC;
};

export const DemographicVisitors: FC<TDemographicVisitorsProps> = ({
  gendersPerAges,
  rangeAge,
  views,
  sex,
}) => {
  const isFemale = sex === SEX_DEMOGRAPHIC.FEMALE;
  const sexData = isFemale ? gendersPerAges?.[rangeAge]?.female : gendersPerAges?.[rangeAge]?.male;

  return (
    <div className={cn(isFemale && styles.demographicTable__textRight)}>
      <FormatNumber
        notation={FORMAT_NUMBER_NOTATION.COMPACT}
        className={cn(styles.margin_right, isFemale && styles.demographicTable__inline)}
      >
        {(sexData || 0) * views}
      </FormatNumber>
      <span>({fractionToInteger(Number(sexData), 0)}%)</span>
    </div>
  );
};
