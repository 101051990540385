/*
 * Сервис нотификаций по подпискам.
 */
import dayjs from 'dayjs';
import {
  IPersonWorkspaceResponseDto,
  ISubscriptionSimpleInfoDto,
  NotificationType,
  SubscriptionPlanType,
  SubscriptionStatusType,
  TrialWillEndNotification,
} from '@hypetrainCommon';
import { formatDate } from '@uikit/components/DatePickerField/datePickerField.utils';
import { NotificationsService, notificationsService } from '@uikit/components/Notification';
import {
  SystemNotificationsService,
  systemNotificationsService,
} from '@uikit/components/SystemNotification';
import { TSystemNotificationAction } from '@uikit/components/SystemNotification/systemNotification.types';
import { Routes } from '@router/Routes';
import webSocketService, { WebSocketService } from '@api/webSocket';
import i18n from '@services/i18n';
import { TSubscriptionModelAPI } from '@services/subscriptionNotifications/subscriptionNotifications.types';
import { subscriptionUserAccessService } from '@services/userAccess';
import { getDateUnitBefore } from '@utils/dateTime.utils';
import { redirectToApp } from '@utils/redirect.utils';
import {
  getSubscriptionCost,
  getSubscriptionName,
  isDeactivatedSubscription,
  isDeactivatedWithReminder,
  isSubscriptionHasTrialTime,
} from '@utils/subscription.utils';
import { SUBSCRIPTION_NAMES_LOCALES } from '@constants/subscription.constants';

const SUBSCRIPTION_DEACTIVATED_BY_USER_NOTIFICATION_ID =
  'SUBSCRIPTION_DEACTIVATED_BY_USER_NOTIFICATION_ID';

export class SubscriptionNotificationsService {
  /*
   * Текущий активный workspace.
   */
  private activeWorkspace: IPersonWorkspaceResponseDto | null = null;

  /*
   * Текущая подписка.
   */
  private subscription: ISubscriptionSimpleInfoDto | null = null;

  /*
   * Признак того, что подписка на нотификации уже выполнена.
   */
  private isSubscribed = false;

  private subscriptionAPI: TSubscriptionModelAPI | null = null;

  constructor(
    private readonly webSocket: WebSocketService,
    private readonly notifications: NotificationsService,
    private readonly systemNotifications: SystemNotificationsService
  ) {}

  /*
   * Инифиализация сервиса.
   * TODO All: Пробрасывать сторы в случае действительной необходимости.
   */
  public init = (
    activeWorkspace: IPersonWorkspaceResponseDto,
    subscription: ISubscriptionSimpleInfoDto,
    subscriptionAPI: TSubscriptionModelAPI
  ): void => {
    if (this.isSubscribed || !activeWorkspace || !subscription) return;

    this.activeWorkspace = activeWorkspace;
    this.subscription = subscription;
    this.subscriptionAPI = subscriptionAPI;

    this.subscribe();
    this.isSubscribed = true;

    if (subscription.status === SubscriptionStatusType.pastDue) {
      this.onSubscriptionPastDue();
    }
  };

  /*
   * Подписка на нотификации по подпискам.
   */
  private subscribe = (): void => {
    if (!this.subscription) return;

    if (isSubscriptionHasTrialTime(this.subscription)) {
      this.subscribeOnTrialNotifications();
    }
    if (isDeactivatedWithReminder(this.subscription)) {
      this.showSubscriptionDeactivatedNotification();
    }
    this.webSocket.subscription?.onSubscriptionPastDue(this.onSubscriptionPastDue);
    this.webSocket.subscription?.onSubscriptionEnded(this.onSubscriptionlEnd);
  };

  /*
   * Подписка на нотификации о статусе триальной подписки.
   */
  private subscribeOnTrialNotifications = (): void => {
    this.webSocket.subscription?.onTrialWillEnd(this.onTrialWillEnd);
    this.webSocket.subscription?.onTrialEnded(() => window.location.reload());
  };

  /*
   * Обработка нотификации о скором окончании триала.
   */
  private onTrialWillEnd = (notification: TrialWillEndNotification): void => {
    const endDate = dayjs(notification?.data?.trialEnd).format('MMM D, YYYY, h:mm a');
    const cost = getSubscriptionCost(notification?.data, { seats: this.subscription?.seats });
    const name = getSubscriptionName(notification?.data?.plan?.type);

    this.systemNotifications.warning({
      id: NotificationType.trialWillEnd,
      caption: notification.data?.paymentMethodAdded
        ? i18n.t('subscriptionNotifications.trialWillEndWithPaymentMethod', { endDate, cost })
        : i18n.t('subscriptionNotifications.trialWillEnd', { endDate, name }),
      actions: notification.data?.paymentMethodAdded
        ? []
        : [
            {
              text: i18n.t('subscriptionNotifications.trialWillEndButton'),
              onClick: () => this.subscriptionAPI?.openCustomerPortal(),
            },
          ],
    });
  };

  /*
   * Обработка нотификации о подписке находящейся в грейс-периоде.
   */
  public onSubscriptionPastDue = (): void => {
    if (!this.activeWorkspace?.person) return;

    const canUpdateBillingDetails = subscriptionUserAccessService.isAvailable;
    const name = getSubscriptionName(
      this.activeWorkspace.subscription?.planType as SubscriptionPlanType
    );
    const caption = canUpdateBillingDetails
      ? i18n.t('subscriptionNotifications.paymentFailedAdmin', { name })
      : i18n.t('subscriptionNotifications.paymentFailedMember', { name });
    const actions: TSystemNotificationAction[] = canUpdateBillingDetails
      ? [
          {
            text: i18n.t('subscriptionNotifications.paymentFailedButton'),
            onClick: () =>
              redirectToApp(this.activeWorkspace?.workspace?.handler || '', Routes.billing),
          },
        ]
      : [];

    this.systemNotifications.warning({
      id: NotificationType.subscriptionPastDue,
      caption,
      actions,
      closable: false,
    });
  };

  /*
   * Отображает нотификацию о деакивированной подписке.
   */
  private showSubscriptionDeactivatedNotification = (): void => {
    if (!this.activeWorkspace || !this.subscription) return;

    const daysLeft = getDateUnitBefore(
      this.subscription.endedAt || '',
      'day',
      this.subscription.currentTime
    );

    // Показываем нотификацию только за 3 дня до окончания подписки.
    if (daysLeft > 3) return;

    const canResumeSubscription = subscriptionUserAccessService.resumeSubscription;
    const planName = SUBSCRIPTION_NAMES_LOCALES[this.subscription.plan.type];
    const endedAt = formatDate(this.subscription.endedAt || '', 'MMM D, YYYY [at] H:mm a');
    const caption = canResumeSubscription
      ? i18n.t('subscriptionNotifications.deactivatedByUserAdmin', { planName, endedAt })
      : i18n.t('subscriptionNotifications.deactivatedByUserMember', { planName, endedAt });
    const actions: TSystemNotificationAction[] = canResumeSubscription
      ? [
          {
            onClick: () => this.subscriptionAPI?.resumeSubscription(),
            text: i18n.t('subscriptionNotifications.activateSubscription'),
          },
        ]
      : [];

    this.systemNotifications.warning({
      id: SUBSCRIPTION_DEACTIVATED_BY_USER_NOTIFICATION_ID,
      caption,
      actions,
    });
  };

  /*
   * Обработка нотификации об истёкшей подписке.
   * Перезагружаем страницу если на момент получения нотификации подписка в приложении находится в статсе активной.
   */
  private onSubscriptionlEnd = (): void => {
    if (!this.subscription) return;

    if (!isDeactivatedSubscription(this.subscription)) {
      window.location.reload();
    }
  };
}

export const createSubscriptionNotificationsService = (
  webSocket: WebSocketService,
  notifications: NotificationsService,
  systemNotifications: SystemNotificationsService
) => new SubscriptionNotificationsService(webSocket, notifications, systemNotifications);

export const subscriptionNotificationsService = createSubscriptionNotificationsService(
  webSocketService,
  notificationsService,
  systemNotificationsService
);
