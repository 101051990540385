import { useStore } from 'effector-react/compat';
import { FC, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@uikit/components/ErrorBoundary';
import { Routes } from '@router/Routes';
import { SubscriptionRoute } from '@router/SubscriptionRoute';
import { isAuth } from '@utils/auth.utils';
import { redirectToLoginPage } from '@utils/redirect.utils';
import { isAnothersAccount } from '@utils/workspace.utils';
import { $workspacesStore } from '@models/workspaces/workspaces.model';

type TPrivateRouteProps = {
  errorBoundaryName: string;
};

// Список private-роутов доступных вне зависимости от наличия активного workspace.
const ACTIVE_WORKSPACE_INDEPENDENT_ROUTES = [Routes.workspaceCreation, Routes.noAccess];

// Рассмотреть по мере усложнения создание 2 отдельных private-роутов, вложенных сюда - для admin и member.
export const PrivateRoute: FC<PropsWithChildren<TPrivateRouteProps>> = ({
  errorBoundaryName,
  children,
}) => {
  const { pathname } = useLocation();
  const workspaces = useStore($workspacesStore);

  // Пользователь не авторизован - перенапрявляем на логин.
  if (!isAuth()) {
    redirectToLoginPage();
  }

  // Если пользователь зашёл в чужой subdomain - блокируем доступ.
  if (isAnothersAccount(workspaces) && !ACTIVE_WORKSPACE_INDEPENDENT_ROUTES.includes(pathname)) {
    return <Navigate to={Routes.noAccess} />;
  }

  return (
    <ErrorBoundary
      key={errorBoundaryName}
      layerName={errorBoundaryName}
    >
      <SubscriptionRoute>{children}</SubscriptionRoute>
    </ErrorBoundary>
  );
};
