/*
 * Компонент вывода информации о составе платежа.
 */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency } from '@hypetrainCommon';
import { FormatCurrency } from '@uikit/components/Formats';
import { InformationTooltip } from '@uikit/components/InformationTooltip';
import styles from './PaymentCompositionInfo.module.scss';

type TPaymentCompositionInfoProps = {
  currency: Currency;
  subTotal?: number;
  hypetrainFee?: number;
  conversionFee?: number;
  crossBorderFee?: number;
  operationalFee?: number;
  total?: number;
  className?: string;
};

export const PaymentCompositionInfo: FC<TPaymentCompositionInfoProps> = ({
  currency,
  subTotal = 0,
  hypetrainFee = 0,
  conversionFee = 0,
  crossBorderFee = 0,
  operationalFee = 0,
  total = 0,
  className,
}) => {
  const { t } = useTranslation();

  const tooltipWithDesctiption = (
    <div className={styles.paymentCompositionInfo__tooltip}>
      <div>
        <span>{t('paymentAmountMetricTooltip.subtotal')}</span>
        <FormatCurrency currency={currency}>{subTotal}</FormatCurrency>
      </div>
      <div>
        <span>{t('paymentAmountMetricTooltip.ourFee')}</span>
        <FormatCurrency currency={currency}>{hypetrainFee}</FormatCurrency>
      </div>
      <div>
        <span>{t('paymentAmountMetricTooltip.conversionFee')}</span>
        <FormatCurrency currency={currency}>{conversionFee}</FormatCurrency>
      </div>
      <div>
        <span>{t('paymentAmountMetricTooltip.crossBorderFee')}</span>
        <FormatCurrency currency={currency}>{crossBorderFee}</FormatCurrency>
      </div>
      <div>
        <span>{t('paymentAmountMetricTooltip.fixedOperatingCost')}</span>
        <FormatCurrency currency={currency}>{operationalFee}</FormatCurrency>
      </div>
      <br />
      <div>
        <span>{t('paymentAmountMetricTooltip.total')}</span>
        <FormatCurrency currency={currency}>{total}</FormatCurrency>
      </div>
    </div>
  );

  return (
    <InformationTooltip
      className={className}
      width={300}
    >
      {tooltipWithDesctiption}
    </InformationTooltip>
  );
};
