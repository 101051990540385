/*
 * Компонент вкладки входящий транзакций по платёжному счёту.
 */
import { useStore } from 'effector-react/compat';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@uikit/components/Table';
import {
  $incomingPaymentAccountTransactions,
  getPaymentAccountTransactionsFx,
} from '@pagesPaymentsPage/paymentsAccounts.model';
import { paymentAccountTransactionsColumns } from '../../paymentAccountTransactions.columns';
import { PaymentAccounNotingFound } from '../PaymentAccounNotingFound';
import styles from './PaymentAccountIncomingTab.module.scss';

export const PaymentAccountIncomingTab: FC = memo(() => {
  const { t } = useTranslation();
  const isLoading = useStore(getPaymentAccountTransactionsFx.pending);
  const transactions = useStore($incomingPaymentAccountTransactions);

  return (
    <div className={styles.paymentAccountIncomingTab}>
      <Table
        data={transactions}
        columns={paymentAccountTransactionsColumns}
        isLoading={isLoading}
        emptyTable={
          <PaymentAccounNotingFound
            title={t('paymentAccountIncomingTab.nothingFoundTitle')}
            description={t('paymentAccountIncomingTab.nothingFoundDescription')}
          />
        }
        className={styles.paymentAccountIncomingTab__table}
      />
    </div>
  );
});
