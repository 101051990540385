/*
 *  Компонента для отрисовки полосок пустого сообщения в таблице communication
 * */
import cn from 'clsx';
import { FC } from 'react';
import styles from './EmptyTextCommunication.module.scss';

type TEmptyTextProps = {
  className?: string;
};
export const EmptyTextCommunication: FC<TEmptyTextProps> = ({ className }) => (
  <div className={cn(styles.emptyText, className)}>
    <div className={styles.emptyText__lineOne} />
    <div className={styles.emptyText__lineTwo} />
  </div>
);
