// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login__linkTitle__vBPgu{margin-bottom:var(--hSpacing16) !important}.login__secondaryTitle__ykthF{margin-bottom:var(--hSpacing8) !important}.login__separator__Fdhk6{margin:var(--hSpacing36) 0;background:var(--outlineDivider);height:1px}.login__checkbox__UXKxk{margin-bottom:unset}.login__adminInfo__nBS2p{margin-bottom:var(--hSpacing20)}", "",{"version":3,"sources":["webpack://./pages/Login/Login.module.scss"],"names":[],"mappings":"AAGE,yBACE,0CAAA,CAGF,8BACE,yCAAA,CAGF,yBACE,0BAAA,CACA,gCAAA,CACA,UAAA,CAGF,wBACE,mBAAA,CAGF,yBACE,+BAAA","sourcesContent":["@import '../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.login {\n  &__linkTitle {\n    margin-bottom: var(--hSpacing16) !important;\n  }\n\n  &__secondaryTitle {\n    margin-bottom: var(--hSpacing8) !important;\n  }\n\n  &__separator {\n    margin: var(--hSpacing36) 0;\n    background: var(--outlineDivider);\n    height: 1px;\n  }\n\n  &__checkbox {\n    margin-bottom: unset;\n  }\n\n  &__adminInfo {\n    margin-bottom: var(--hSpacing20);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login__linkTitle": "login__linkTitle__vBPgu",
	"login__secondaryTitle": "login__secondaryTitle__ykthF",
	"login__separator": "login__separator__Fdhk6",
	"login__checkbox": "login__checkbox__UXKxk",
	"login__adminInfo": "login__adminInfo__nBS2p"
};
export default ___CSS_LOADER_EXPORT___;
