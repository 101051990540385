import { apiService } from '@api';
import { createEvent, createStore } from 'effector/compat';
import { SmaPlatform } from '@hypetrainCommon';

export type TActiveSocialPlatforms = {
  platform: SmaPlatform;
  isYoutube: boolean;
  isInstagram: boolean;
  isTikTok: boolean;
};

export const resetActivePlatform = createEvent();
export const setActiveSocialPlatform = createEvent<SmaPlatform>();

export const $activeSocialPlatform = createStore<TActiveSocialPlatforms | null>(null)
  .on(setActiveSocialPlatform, (_, platform) => {
    apiService.setActiveSocialPlatform(platform);
    return {
      platform,
      isYoutube: platform === SmaPlatform.Youtube,
      isInstagram: platform === SmaPlatform.Instagram,
      isTikTok: platform === SmaPlatform.Tiktok,
    };
  })
  .reset(resetActivePlatform);
