// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lastMessage__Ps9Sj{max-width:100%;padding-right:var(--vSpacing12)}.lastMessage__noContact__IfmUc{width:fit-content}", "",{"version":3,"sources":["webpack://./pages/Campaigns/CampaignDetailsPage/components/Communication/components/LastMessage/LastMessage.module.scss"],"names":[],"mappings":"AAEA,oBACE,cAAA,CACA,+BAAA,CAEA,+BACE,iBAAA","sourcesContent":["@import '../../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.lastMessage {\n  max-width: 100%;\n  padding-right: var(--vSpacing12);\n\n  &__noContact {\n    width: fit-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lastMessage": "lastMessage__Ps9Sj",
	"lastMessage__noContact": "lastMessage__noContact__IfmUc"
};
export default ___CSS_LOADER_EXPORT___;
