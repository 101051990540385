import cn from 'clsx';
import { useStore } from 'effector-react/compat';
import { FC, MouseEvent, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@uikit/components/Icon';
import { TIconProps } from '@uikit/components/Icon/Icon.component';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { Loader } from '@uikit/components/Loader';
import { LOADER_SIZE, LOADER_TYPE } from '@uikit/components/Loader/Loader.constants';
import { PopupPositions } from '@uikit/components/Popup';
import { Tooltip } from '@uikit/components/Tooltip';
import { currentSubdomain } from '@constants/common';
import { $activeWorkspaceStore } from '@models/activeWorkspace';
import { $workspacesStore } from '@models/workspaces/workspaces.model';
import styles from './PanelItem.module.scss';

type TPanelItemProps = {
  name: string;
  icon?: TIconProps;
  tooltipText: string;
  isActive?: boolean;
  redirectUrl?: string;
  id?: string;
  disabled?: boolean;
  action?: () => void;
};

export const PanelItem: FC<TPanelItemProps> = memo(
  ({ icon, isActive = false, tooltipText, redirectUrl, id, disabled, action }) => {
    const activeWorkspace = useStore($activeWorkspaceStore);
    const workspaces = useStore($workspacesStore);
    const workspaceShortName = activeWorkspace.workspace
      ? activeWorkspace?.workspace?.name[0]
      : workspaces?.inactive.find((ws) => ws.workspace.handler === currentSubdomain)?.workspace
          ?.name?.[0];

    const onClick = (e: MouseEvent): void => {
      action && action();

      // NavLink невозможно задизейблить, по ээтому такое кастомное отключение.
      if (disabled) {
        e.preventDefault();
      }
    };

    const content = (
      <Tooltip
        content={<span>{tooltipText}</span>}
        position={PopupPositions.RightCenter}
        offset={icon ? 25 : 19}
      >
        {icon && (
          <Icon
            {...icon}
            size={icon.size || 24}
            style={disabled ? ICON_STYLES.DISABLED : icon?.style}
          />
        )}
        {!icon && (
          <div className={styles.panelItem__workspaceName}>
            {workspaceShortName || (
              <Loader
                type={LOADER_TYPE.ADDITIONAL}
                size={LOADER_SIZE.S}
              />
            )}
          </div>
        )}
      </Tooltip>
    );

    return (
      <NavLink
        to={redirectUrl as string}
        className={cn(styles.panelItem, {
          [styles.panelItem_active]: isActive,
          [styles.panelItem_isWorkspace]: !icon,
          [styles.panelItem_disabled]: disabled,
          [styles.panelItem_warning]: icon?.style === ICON_STYLES.WARNING,
        })}
        id={id}
        onClick={onClick}
      >
        {content}
      </NavLink>
    );
  }
);
