import { createEffect, createEvent, createStore, forward } from 'effector/compat';
import i18n from 'i18next';
import { IGetUserProfileResponseDto } from '@hypetrainCommon';
import { notificationsService } from '@uikit/components/Notification';
import { profileApiService } from '@api/profile';
import { usersApiService } from '@api/users';

/*
 * Устанавливает профиль пользователя.
 */
export const setUserProfile = createEvent<IGetUserProfileResponseDto>();

/*
 * Запрашивает профиль пользователя.
 */
export const getProfileFx = createEffect(profileApiService.getUserProfile);

// Add profile feedback
export const addProfileFeedbackFx = createEffect(profileApiService.addProfileFeedback);

/*
 * Эффект сохранения согласия на рассылку
 */
export const setMarketingConsentFx = createEffect(usersApiService.setMarketingConsent);

const onDoneProfileFeedback = createEffect(() => {
  notificationsService.successful({ props: { caption: i18n.t('profileFeedback.success') } });
});

const onFailProfileFeedback = createEffect(() => {
  notificationsService.error({ props: { caption: i18n.t('profileFeedback.error') } });
});

forward({
  from: [addProfileFeedbackFx.done],
  to: onDoneProfileFeedback,
});

forward({
  from: [addProfileFeedbackFx.fail],
  to: onFailProfileFeedback,
});

export const $userProfile = createStore<IGetUserProfileResponseDto | null>(null)
  .on(getProfileFx.doneData, (_, profile) => profile)
  .on(setUserProfile, (_, profile) => profile);
