/*
 * Currency input component.
 * Can be used for input formatted currencies.
 */
import cn from 'clsx';
import isNumber from 'lodash/isNumber';
import { FC, useState } from 'react';
import { TInputProps } from '@uikit/components/Fields/Inputs';
import { MASKED_INPUT_TYPES, MaskedInput } from '@uikit/components/Fields/Inputs/MaskedInput';
import { noop } from '@uikit/helpers/common.helpers';
import styles from './CurrencyInput.module.scss';

type TCurrencyInputProps = {
  min?: number;
  max?: number;
  precision?: number;
  value?: number;
  onChange?: (value: number) => void;
} & TInputProps;

export const CurrencyInput: FC<TCurrencyInputProps> = ({
  value,
  onChange,
  input,
  className,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onFocus = (): void => {
    setIsFocused(true);
    input?.onFocus && input?.onFocus();
  };

  const onBlur = (): void => {
    setIsFocused(false);
    input?.onBlur && input?.onBlur();
  };

  return (
    <MaskedInput
      {...props}
      className={cn(styles.currencyInput, className, [
        {
          [styles.currencyInput_highlighted]:
            isFocused || isNumber(input?.value) || isNumber(value),
          [styles.currencyInput_withLabel]: !!props.label,
          [styles.currencyInput_withoutLabel]: !props.label,
        },
      ])}
      input={{
        ...input,
        name: input?.name || 'currencyInput',
        value: input?.value || '',
        onChange: input?.onChange || noop,
        onBlur,
        onFocus,
      }}
      maskType={MASKED_INPUT_TYPES.CURRENCY}
      onChange={onChange}
      value={value}
    />
  );
};
