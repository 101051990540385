import cn from 'clsx';
import { FC, useMemo } from 'react';
import { MenuListProps } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import { useSelectFieldContext } from '@uikit/components/SelectField/selectField.context';
import { TSelectListAction } from '../../SelectField.types';
import { isOptionsGroup } from '../../SelectField.utils';
import styles from './SelectFieldMenuList.module.scss';

interface ISelectFieldMenuList extends MenuListProps {
  selectListAction?: TSelectListAction;
}

const MIN_SIZE_WITH_GROUP = 120;

export const SelectFieldMenuList: FC<ISelectFieldMenuList> = ({
  options,
  children,
  selectListAction,
  maxHeight,
  getValue,
  ...props
}) => {
  const selectFieldContext = useSelectFieldContext();

  const isArrayOfChildren = Array.isArray(children);

  const isGroup = useMemo(() => isOptionsGroup(options), [options]);

  const listHeight = useMemo(() => {
    // Если нет опций и есть action то делаем минимальную высоту блока 120
    // isArrayOfChildren хранит есть ли данные в конкретной группе
    // options хранит все данные всех груп
    if (!isArrayOfChildren && selectListAction) return MIN_SIZE_WITH_GROUP;

    if (isArrayOfChildren) {
      if (isGroup) {
        // Делаем подсчет высоты для группы
        // подсчеты нужны для того чтобы задать высоту контейнера т.к. исполльзуется виртуализация
        const itemsCount = children.reduce((count, obj) => count + obj.props.children.length, 0);
        return (
          (itemsCount + 1) * selectFieldContext.selectItemHeight +
          // добавлем высоту если есть экшен
          (selectListAction ? selectFieldContext.selectItemHeight : 0)
        );
      }
      return children.length * selectFieldContext.selectItemHeight;
    }

    return selectFieldContext.singleOptionHeight;
  }, [children, selectFieldContext]);

  const itemHeight = isArrayOfChildren
    ? selectFieldContext.selectItemHeight
    : selectFieldContext.singleOptionHeight;

  const itemCount = isArrayOfChildren ? children.length : 1;

  return (
    <List
      className={cn(props.className, styles.selectFieldMenuList)}
      height={maxHeight < listHeight ? maxHeight : listHeight}
      width="100%"
      itemCount={itemCount}
      itemSize={itemHeight}
    >
      {({ index, style }) => (
        <div
          style={!isGroup ? style : {}}
          className="OPTION"
        >
          {selectListAction && index === 0 && (
            <div
              onClick={selectListAction.action}
              className={styles.selectFieldMenuList__action}
            >
              {selectListAction.text}
            </div>
          )}
          {isArrayOfChildren ? children[index] : children}
        </div>
      )}
    </List>
  );
};
