// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".groupActionMetricList__VMyg2{display:flex;align-items:center}.groupActionMetricList__list__T_TzM{display:flex;overflow:hidden}.groupActionMetricList__item__fs_Of:not(:last-child){min-width:64px;margin-right:var(--hSpacing20)}.groupActionMetricList__tip__aqZBG{margin-left:var(--hSpacing16);width:fit-content}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/GroupActions/component/GroupActionMetricList/GroupActionMetricList.module.scss"],"names":[],"mappings":"AAEA,8BACE,YAAA,CACA,kBAAA,CAEA,oCACE,YAAA,CACA,eAAA,CAGF,qDACE,cAAA,CACA,8BAAA,CAGF,mCACE,6BAAA,CACA,iBAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.groupActionMetricList {\n  display: flex;\n  align-items: center;\n\n  &__list {\n    display: flex;\n    overflow: hidden;\n  }\n\n  &__item:not(:last-child) {\n    min-width: 64px;\n    margin-right: var(--hSpacing20);\n  }\n\n  &__tip {\n    margin-left: var(--hSpacing16);\n    width: fit-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groupActionMetricList": "groupActionMetricList__VMyg2",
	"groupActionMetricList__list": "groupActionMetricList__list__T_TzM",
	"groupActionMetricList__item": "groupActionMetricList__item__fs_Of",
	"groupActionMetricList__tip": "groupActionMetricList__tip__aqZBG"
};
export default ___CSS_LOADER_EXPORT___;
