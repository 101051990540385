/*
 * Checkbox component.
 * Can be used with or without a CheckboxGroup.
 */
import { FC, ReactElement } from 'react';
import { FieldInputProps } from 'react-final-form';
import { Checkbox } from '@uikit/components/Checkbox';

type TCheckboxFieldProps = {
  input: FieldInputProps<string>;
  label?: string | ReactElement;
  error?: string | null;
  isPartial?: boolean; // sign for checkbox with minus icon (for partial select of checkbox group)
  isDisabled?: boolean;
  className?: string;
};

export const CheckboxField: FC<TCheckboxFieldProps> = ({
  input,
  error,
  label,
  isPartial,
  isDisabled,
  className,
}) => (
  <Checkbox
    className={className}
    checked={input.checked}
    onChange={input.onChange}
    onFocus={input.onFocus}
    onBlur={input.onBlur}
    value={input.value}
    nameInGroup={input.name}
    error={error}
    label={label}
    isPartial={isPartial}
    isDisabled={isDisabled}
  />
);
