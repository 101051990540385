/*
 * Модель для работы с appApiService (!!!) и связаннного с ним.
 * Не превращать в помойку, только по тому, что в названии есть абстрактное слово app (!!!)
 */
import { createEffect } from 'effector/compat';
import { IAppInitializationResponseDto, IPersonWorkspaceResponseDto } from '@hypetrainCommon';
import { appApiService } from '@api/app/app.api.service';
import { TWorkspaceMap } from '@models/workspaces/workspaces.model';

export type TInitAppData = Omit<IAppInitializationResponseDto, 'workspaces'> & {
  workspaces: TWorkspaceMap;
};

/*
 * Загрузка информации необходимой для инициализации приложения.
 */
export const getInitAppDataFx = createEffect(
  (): Promise<TInitAppData> =>
    appApiService.getInitAppData().then((response) => ({
      ...response,
      workspaces: response?.workspaces?.reduce(
        (prev: TWorkspaceMap, current: IPersonWorkspaceResponseDto) => {
          prev[current?.person?.status as keyof TWorkspaceMap]?.push(current);
          return prev;
        },
        { active: [], inactive: [], pending: [] }
      ),
    }))
);
