/*
 * Сервис разграничения доступа пользователя к функционалу воркспейсов.
 */
import { UserAccessService, userAccessService } from './userAccess.service';
import { TUsetRights } from './userAccess.service.types';

export class WorkspaceUserAccessService {
  private get userRights(): TUsetRights {
    return this.userAccess.userRights;
  }

  /*
   * Может ли пользователь работать с воркспейсами.
   */
  public get isAvailable(): boolean {
    return this.userAccess.hasWorkspaceRightGroup;
  }

  public get getWorkspaceInfo(): boolean {
    return Boolean(this.userRights.GetWorkspaceInfo);
  }

  /*
   * Смена handler workspace в кейсе их миграции.
   */
  public get updateWorkspaceInfo(): boolean {
    return Boolean(this.userRights.UpdateWorkspaceInfo);
  }

  /*
   * Может ли пользователь создавать приглашение для другого пользовтаеля в свой с воркспейсами.
   */
  public get createWorkspaceInvite(): boolean {
    return Boolean(this.userRights.CreateWorkspaceInvite);
  }

  constructor(private readonly userAccess: UserAccessService) {}
}

const createWorkspaceUserAccessService = (userAccess: UserAccessService) =>
  new WorkspaceUserAccessService(userAccess);

export const workspaceUserAccessService = createWorkspaceUserAccessService(userAccessService);
