import { makeAutoObservable } from 'mobx';
import { TOverlayConfig } from './overlays.types';

export class OverlaysStore {
  public overlays: TOverlayConfig[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public add<P>(overlay: TOverlayConfig<P>): void {
    this.overlays.push(overlay as TOverlayConfig);
  }

  public delete(id: string): void {
    if (!id) return;

    this.overlays = this.overlays.filter((overlay) => overlay.id !== id);
  }
}

const createOverlaysStore = (): OverlaysStore => new OverlaysStore();

export const overlaysStore = createOverlaysStore();
