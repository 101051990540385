import { createEffect, createEvent, createStore, forward } from 'effector/compat';
import i18n from 'i18next';
import { IPersonalInboxConnectionInfoDto } from '@hypetrainCommon';
import { notificationsService } from '@uikit/components/Notification';
import { workspaceApiService } from '@api/workspace';

export const resetPersonalInboxConnectionInfoProfile = createEvent<void>();

/*
 * Получение информации о наличии подключенного имейла к воркспейсу
 */
export const getPersonalInboxConnectionInfoFx = createEffect(
  workspaceApiService.getPersonalInboxConnectionInfo
);

/*
 * Создание конфига для подключения имейла к воркспейсу
 */
export const createPersonalInboxConnectionFx = createEffect(
  workspaceApiService.createPersonalInboxConnection
);

/*
 * Удаление подключенного имейла к воркспейсу
 */
export const deletePersonalInboxConnectionFx = createEffect(
  workspaceApiService.deletePersonalInboxConnection
);

forward({
  from: createPersonalInboxConnectionFx.done,
  to: getPersonalInboxConnectionInfoFx,
});

export const $personalInboxStore = createStore<IPersonalInboxConnectionInfoDto | null>(null)
  .on(getPersonalInboxConnectionInfoFx.doneData, (_, payload) => payload)
  .on(getPersonalInboxConnectionInfoFx.fail, () => {})
  .reset(resetPersonalInboxConnectionInfoProfile);

// Реакция на успешный дисконект personal inbox email
const onDoneDeletePersonalInboxConnection = createEffect(() => {
  notificationsService.successful({
    props: {
      caption: i18n.t('personalInboxConnection.disconnectEmail.success', {
        email: $personalInboxStore.getState()?.email,
      }),
    },
  });
  resetPersonalInboxConnectionInfoProfile();
});

forward({
  from: deletePersonalInboxConnectionFx.done,
  to: onDoneDeletePersonalInboxConnection,
});
