/*
 * Checkbox component.
 * Can be used with or without a CheckboxGroup.
 */
import cn from 'clsx';
import { ChangeEvent, FC, FocusEvent, ReactElement } from 'react';
import { useCheckboxGroup } from '@uikit/components/CheckboxGroup';
import styles from './Checkbox.module.scss';

type TCheckboxProps = {
  nameInGroup: string; // internal input name (not label!), important for CheckboxGroup or forms
  checked?: boolean; // selected checkbox sign
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void; // cb on checkbox toggle
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void; // cb on checkbox focus
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void; // cb on checkbox blur event
  label?: string | ReactElement; // checkbox label
  error?: string | null; // checkbox error
  isPartial?: boolean; // sign for checkbox with minus icon (for partial select of checkbox group)
  isDisabled?: boolean; // checkbox disabled sign
  value?: string | number; // first of all for integration with react-final-form
  className?: string;
};

export const Checkbox: FC<TCheckboxProps> = ({
  checked,
  onChange,
  onFocus,
  onBlur,
  error,
  label,
  isPartial,
  isDisabled,
  nameInGroup,
  value,
  className,
}) => {
  /*
   * Checkbox может работать как в составе группы, будучи обёрнутым в CheckboxGroup, так и без неё, автономно.
   * Если обнаружена обёртка CheckboxGroup - признак checked и колбэк onChange используется из группы, иначе из props.
   */
  const checkboxGroup = useCheckboxGroup();
  const isChecked = checkboxGroup ? checkboxGroup.checkboxGroupValue[nameInGroup] : checked;
  const showErrorText = error && !checkboxGroup;

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    if (checkboxGroup) {
      checkboxGroup.onChangeCheckboxGroup(nameInGroup, e.target.checked);
      return;
    }

    onChange && onChange(e);
  };

  return (
    <>
      <label
        className={cn(
          styles.checkbox,
          className,
          error && styles.checkbox_error,
          checkboxGroup && styles.checkbox_group,
          isPartial && styles.checkbox_partial,
          isDisabled && styles.checkbox_disabled
        )}
      >
        <input
          type="checkbox"
          name={nameInGroup}
          value={value}
          checked={isChecked}
          onChange={onChangeHandler}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={isDisabled}
        />
        <span className={styles.checkbox__label}>{label}</span>
      </label>
      {showErrorText && <div className={styles.checkbox__error}>{error}</div>}
    </>
  );
};
