export const slugify = (s: string): string =>
  s
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // remove diacritics
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // spaces to dashes
    .replace(/&/g, '-and-') // ampersand to and
    .replace(/--+/g, '-') // collapse multiple dashes
    .replace(/^-+/, ''); // trim starting dash
