import cn from 'clsx';
import { FC } from 'react';
import spinner from '@uikit/assets/images/smallLoader.svg';
import styles from './InputAsyncLoader.module.scss';

type TInputAsyncLoaderProps = {
  children: JSX.Element;
  isPending: boolean;
  withDescription?: boolean;
  withoutLabel?: boolean;
};

export const InputAsyncLoader: FC<TInputAsyncLoaderProps> = ({
  children,
  isPending,
  withDescription = false,
  withoutLabel = false,
}) => (
  <div className={styles.inputAsyncLoader}>
    <div
      className={cn(
        isPending && styles.inputAsyncLoader__turnOffContent,
        withoutLabel && styles.inputAsyncLoader__turnOffContent_withoutLabel
      )}
    >
      {children}
    </div>
    {isPending && (
      <img
        src={spinner}
        className={cn(
          styles.spinner,
          withDescription && styles.spinner__withDescription,
          withoutLabel && styles.spinner__withoutLabel
        )}
        alt="spinner"
      />
    )}
  </div>
);
