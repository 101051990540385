/*
 * Возвращает список выбранных платежей.
 */
import { useStore } from 'effector-react/compat';
import { IPaymentListItemDto } from '@hypetrainCommon';
import { $paymentsCurrencyFilter, $selectedPayments } from '@pagesPaymentsPage/payments.model';

export const useSelectedPayments = (): IPaymentListItemDto[] => {
  const currencyFilter = useStore($paymentsCurrencyFilter);
  const selectedPayments = useStore($selectedPayments);

  return selectedPayments?.[currencyFilter] || [];
};
