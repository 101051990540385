import { Currency } from '@hypetrainCommon';
import { FILTER_NUMBER_FORMATS_MAP } from '@uikit/components/Filter/Filter.constants';
import { RANGE_TYPES } from '@uikit/components/Filter/components/RangeFilter/RangeFilter.types';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { FORMAT_NUMBER_NOTATION } from '@uikit/components/Formats';
import { MORE_THAN_1M } from './components/RangeSelect/rangeSelect.constants';

/*
 * Подготовка отображаемого значения фильтра диапазона значений
 */
export const prepareRangeViewValue = (value: TFilter, type: RANGE_TYPES): string => {
  const isPercent = type === RANGE_TYPES.PERCENT;
  const formatter = new Intl.NumberFormat('en', {
    notation: isPercent ? FORMAT_NUMBER_NOTATION.STANDARD : FORMAT_NUMBER_NOTATION.COMPACT,
    maximumFractionDigits: isPercent ? 1 : 0,
    style: FILTER_NUMBER_FORMATS_MAP[type],
    currency: Currency.USD,
  });

  const hasMin = value?.min || value?.min === 0;
  const hasMax = value?.max || value?.max === 0;

  return hasMin || hasMax
    ? `${
        hasMin
          ? // для форматирования процентного значения делим на 100 т.к. .format предполагает что 100% = 1
            formatter.format(isPercent ? Number(value.min) / 100 : Number(value.min))
          : '...'
      }–${
        hasMax && value?.max !== MORE_THAN_1M
          ? // для форматирования процентного значения делим на 100 т.к. .format предполагает что 100% = 1
            formatter.format(isPercent ? Number(value.max) / 100 : Number(value.max))
          : '...'
      }`
    : '';
};

// приведение внутренней модели компонента к внешней модели данных, для фильтров содержащих процентные значения
// 10 => 0.1
export const transformPercentFilterModelToDto = (filterValue: TFilter): TFilter => ({
  ...filterValue,
  ...(filterValue.min && { min: filterValue.min / 100 }),
  ...(filterValue.max && { max: filterValue.max / 100 }),
});

// приведение внешней модели данных к внутренней модели компонента, для фильтров содержащих процентные значения
// 0.1 => 10
export const transformDtoToPercentFilterModel = (filterValue: TFilter): TFilter => ({
  ...filterValue,
  ...(filterValue.min && { min: filterValue.min * 100 }),
  ...(filterValue.max && { max: filterValue.max * 100 }),
});

export const transformDefault = (filterValue: TFilter): TFilter => filterValue;

const FORMAT_FROM_DTO: Record<RANGE_TYPES, (filterValue: TFilter) => TFilter> = {
  [RANGE_TYPES.PERCENT]: transformDtoToPercentFilterModel,
  [RANGE_TYPES.NUMBER]: transformDefault,
  [RANGE_TYPES.CURRENCY]: transformDefault,
  [RANGE_TYPES.SELECT]: transformDefault,
};

const FORMAT_TO_DTO: Record<RANGE_TYPES, (filterValue: TFilter) => TFilter> = {
  [RANGE_TYPES.PERCENT]: transformPercentFilterModelToDto,
  [RANGE_TYPES.NUMBER]: transformDefault,
  [RANGE_TYPES.CURRENCY]: transformDefault,
  [RANGE_TYPES.SELECT]: transformDefault,
};

export const formatRangeToDTO = (type: RANGE_TYPES, value: TFilter): TFilter =>
  FORMAT_TO_DTO[type](value);

export const formatRangeFromDTO = (type: RANGE_TYPES, value: TFilter): TFilter =>
  FORMAT_FROM_DTO[type](value);
