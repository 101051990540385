/*
 * Компонент-посредник для отрисовки ютуб(youtube) версии полу-возрастной диаграммы
 * */
import orderBy from 'lodash/orderBy';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Age,
  IYoutubeSmaResponseDto,
  IYoutubeSmaStatisticsResponseDto,
  SmaPlatform,
} from '@hypetrainCommon';
import { getSocialPlatformBySMAId } from '@utils/socialPlatform.utils';
import { AGES_IQDATA, AGES_YOUTUBE } from '@helpers/constants';
import { AudienceDemographics } from '@components/AudienceDemographic/components/AudienceDemographic.component';
import { useGenderFiltersHook } from '@components/AudienceDemographic/useGenderFilters.hook';

type TAudienceDemographicsYoutubeProps = {
  isProfile?: boolean;
  influencer: IYoutubeSmaStatisticsResponseDto | IYoutubeSmaResponseDto;
};

// todo Никита, обсудить куда будем засовывать тайпагрды
// это тайпгард, возвращает тип  IYoutubeSmaStatisticsResponseDto | IYoutubeSmaResponseDto
// если есть свойство sma
export const isIYoutubeSmaStatisticsResponseDto = (
  type: IYoutubeSmaStatisticsResponseDto | IYoutubeSmaResponseDto
): type is IYoutubeSmaStatisticsResponseDto =>
  (type as IYoutubeSmaStatisticsResponseDto)?.sma !== undefined;

export const AudienceDemographicYoutube: FC<TAudienceDemographicsYoutubeProps> = ({
  isProfile = false,
  influencer,
}) => {
  const { t } = useTranslation();
  const filterAudience = useGenderFiltersHook();

  const ages = influencer?.audience?.ages?.values;
  const genders = influencer?.audience?.genders?.values;
  const gendersPerAges =
    // @ts-ignore TODO уберем ts-ignore когда обновят YoutubeSmaAudience и InstagramSmaAudience
    influencer?.audience?.gendersPerAges?.values;

  const views = influencer?.metrics?.subscriberCount;

  const constAges =
    getSocialPlatformBySMAId(
      isIYoutubeSmaStatisticsResponseDto(influencer) ? influencer.sma.uuid : influencer.uuid
    ) === SmaPlatform.Youtube
      ? AGES_YOUTUBE
      : AGES_IQDATA;

  const maxAge = useMemo(() => {
    const agesWithValue = constAges.map((el: Age) => ({
      key: el,
      value: ages?.[el],
    }));
    return orderBy(agesWithValue, ['value'], ['desc'])[0];
  }, [ages, genders]);

  return (
    <AudienceDemographics
      isProfile={isProfile}
      gendersPerAges={gendersPerAges}
      views={views}
      filterAudience={filterAudience}
      constAges={constAges}
      maxAge={maxAge}
      genders={genders}
      info={t('profile.audience.demographicsYoutubeInfo')}
      platform={SmaPlatform.Youtube}
      uuid={
        isIYoutubeSmaStatisticsResponseDto(influencer) ? influencer?.sma?.uuid : influencer?.uuid
      }
    />
  );
};
