/*
 * Компонент редактирования информации о воркспейсе
 */
import { useStore } from 'effector-react/compat';
import { FC, memo } from 'react';
import { Routes } from '@router/Routes';
import { redirectToApp } from '@utils/redirect.utils';
import { useRedirectNotification } from '@hooks/useRedirectNotification';
import { $activeWorkspaceStore } from '@models/activeWorkspace';
import { updateWorkspaceFx } from '@models/workspaces/workspaces.model';
import { WorkspaceInfoForm } from '@pagesWorkspaceSettings/components/WorkspaceInfo/components/WorkspaceInfoForm';
import { TWorkspaceInfo } from '@pagesWorkspaceSettings/components/WorkspaceInfo/components/WorkspaceInfoForm/WorkspaceInfoForm.component';
import styles from './WorkspaceInfo.module.scss';

export const WorkspaceInfo: FC = memo(() => {
  const activeWorkspace = useStore($activeWorkspaceStore);
  useRedirectNotification();

  const onSubmit = (params: TWorkspaceInfo): void => {
    updateWorkspaceFx({
      workspaceId: activeWorkspace?.workspace?.id,
      data: {
        name: params?.workspaceName,
        handler: params?.handler,
      },
    }).then((response) => redirectToApp(response?.handler, Routes.workspaceInfo, true));
  };

  return (
    <div className={styles.workspaceInfo}>
      <WorkspaceInfoForm
        onSubmit={onSubmit}
        initValue={{
          workspaceName: activeWorkspace?.workspace?.name,
          handler: activeWorkspace?.workspace?.handler,
        }}
      />
    </div>
  );
});
