/*
 * Контекст для селекта
 */
import { createContext, useContext } from 'react';

type TSelectFieldContext = {
  selectItemHeight: number;
  singleOptionHeight: number; // высота опции когда отображается заглушка No options или Loading..
};

const SelectFieldContext = createContext<TSelectFieldContext>({
  selectItemHeight: 0,
  singleOptionHeight: 0,
});

export const SelectFieldProvider = SelectFieldContext.Provider;

export const useSelectFieldContext = () => useContext(SelectFieldContext);
