/*
 * Роутер по состоянию подписки.
 */
import { useStore } from 'effector-react/compat';
import { FC, PropsWithChildren, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Routes } from 'router/Routes';
import { subscriptionUserAccessService } from '@services/userAccess';
import {
  checkTrialEnd,
  isNeedChooseSubscription,
  isSubscriptionInactive,
} from '@utils/subscription.utils';
import { isWorkspaceAdmin } from '@utils/workspace.utils';
import { $activeWorkspaceStore } from '@models/activeWorkspace';
import { $workspacesStore } from '@models/workspaces/workspaces.model';

// Список private-роутов доступных вне зависимости от статуса подписки.
const SUBSCRIPTION_INDEPENDENT_ROUTES = [Routes.changePassword, Routes.editWorkspace];

// Рассмотреть по мере усложнения создание 2 отдельных роутов, вложенных сюда - для admin и member.
export const SubscriptionRoute: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const { pathname } = useLocation();
  const workspaces = useStore($workspacesStore);
  const activeWorkspace = useStore($activeWorkspaceStore);
  const isRouteAvailable = SUBSCRIPTION_INDEPENDENT_ROUTES.includes(pathname);

  if (isRouteAvailable) {
    return children as ReactElement;
  }

  const isAdmin = isWorkspaceAdmin(activeWorkspace?.person);
  const subscription = activeWorkspace?.subscription;

  const chooseSubscriptionRoute = isAdmin ? Routes.choseTariffPlan : Routes.noAccess;
  const subscriptionCanceledRoute = subscriptionUserAccessService.isAvailable
    ? Routes.billing
    : Routes.noAccess;
  const trialEndedRoute = subscriptionUserAccessService.createSubscription
    ? Routes.reviewPayment
    : Routes.noAccess;

  // Подписки нет, но авторизация есть и не новый пользоватаель - это старый существующий пользователь, не выбравший ранее тарифный план. Перенаправяем его на старницу выбора тарифного плана.
  // Проверяем что текущий роут уже не равен этому, что бы избежать зацикливания.
  if (
    isNeedChooseSubscription(activeWorkspace, workspaces) &&
    pathname !== chooseSubscriptionRoute
  ) {
    return <Navigate to={chooseSubscriptionRoute} />;
  }

  // Триал истёк - перенапрявляем.
  // Проверяем, что текуший путь роутинга не равен роуту редиректа для этого статуса, что бы избежать зацикливания.
  if (checkTrialEnd(subscription) && pathname !== trialEndedRoute) {
    return <Navigate to={trialEndedRoute} />;
  }

  // Подписка истекла - перенапрявляем.
  // Проверяем, что текуший путь роутинга не равен роуту редиректа для этого статуса, что бы избежать зацикливания.
  if (isSubscriptionInactive(subscription) && pathname !== subscriptionCanceledRoute) {
    return <Navigate to={subscriptionCanceledRoute} />;
  }

  return children as ReactElement;
};
