// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menuWrapper__z6oUK{display:flex;flex-direction:column;width:100%}.menuWrapper__header__XTmBG{border-bottom:1px solid var(--outlineDivider)}.menuWrapper__footer__uAIgv{border-top:1px solid var(--outlineDivider);padding:var(--hSpacing12)}.menuWrapper__menuItems__kYSpM{overflow-y:auto}.menuWrapper__itemWrapper__NqYR0{padding:var(--hSpacing4) var(--vSpacing4)}.menuWrapper__menuItem___iFk2{cursor:pointer;border-radius:var(--round4)}.menuWrapper__menuItem___iFk2:hover{background-color:var(--indicatorRowHover)}.menuWrapper__divider__FsAUn{border-bottom:1px solid var(--outlineDivider);width:93%;margin-left:7%}.menuWrapper__divider__FsAUn:last-child{display:none}", "",{"version":3,"sources":["webpack://./components/Background/components/NavigationPanel/components/MenuWrapper/MenuWrapper.module.scss"],"names":[],"mappings":"AAEA,oBACE,YAAA,CACA,qBAAA,CACA,UAAA,CAEA,4BACE,6CAAA,CAGF,4BACE,0CAAA,CACA,yBAAA,CAGF,+BACE,eAAA,CAGF,iCACE,yCAAA,CAGF,8BACE,cAAA,CACA,2BAAA,CAEA,oCACE,yCAAA,CAIJ,6BACE,6CAAA,CACA,SAAA,CACA,cAAA,CAEA,wCACE,YAAA","sourcesContent":["@import '../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.menuWrapper {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n\n  &__header {\n    border-bottom: 1px solid var(--outlineDivider);\n  }\n\n  &__footer {\n    border-top: 1px solid var(--outlineDivider);\n    padding: var(--hSpacing12);\n  }\n\n  &__menuItems {\n    overflow-y: auto;\n  }\n\n  &__itemWrapper {\n    padding: var(--hSpacing4) var(--vSpacing4);\n  }\n\n  &__menuItem {\n    cursor: pointer;\n    border-radius: var(--round4);\n\n    &:hover {\n      background-color: var(--indicatorRowHover);\n    }\n  }\n\n  &__divider {\n    border-bottom: 1px solid var(--outlineDivider);\n    width: 93%;\n    margin-left: 7%;\n\n    &:last-child {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuWrapper": "menuWrapper__z6oUK",
	"menuWrapper__header": "menuWrapper__header__XTmBG",
	"menuWrapper__footer": "menuWrapper__footer__uAIgv",
	"menuWrapper__menuItems": "menuWrapper__menuItems__kYSpM",
	"menuWrapper__itemWrapper": "menuWrapper__itemWrapper__NqYR0",
	"menuWrapper__menuItem": "menuWrapper__menuItem___iFk2",
	"menuWrapper__divider": "menuWrapper__divider__FsAUn"
};
export default ___CSS_LOADER_EXPORT___;
