import { FC, memo } from 'react';
import { EMPTY_BLOCK_POSITION, EmptyBlock } from '@uikit/components/EmptyBlock';
import { FORMAT_NUMBER_STYLES, FormatNumber } from '@uikit/components/Formats';
import styles from './TargetAudience.module.scss';

type TTargetAudienceProps = {
  percent: number;
};

export const TargetAudience: FC<TTargetAudienceProps> = memo(({ percent }) => {
  if (percent === 0) {
    return <EmptyBlock position={EMPTY_BLOCK_POSITION.CENTER} />;
  }

  return (
    <div className={styles.targetContainer}>
      <div className={styles.targetContainer__percent}>
        <FormatNumber numberStyles={FORMAT_NUMBER_STYLES.PERCENT}>{percent}</FormatNumber>
      </div>
      <div
        className={styles.targetContainer__panel}
        style={{ width: percent * 50 }}
      />
    </div>
  );
});
