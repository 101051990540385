/*
 * Модель модуля платежей.
 * ТODO Кирилл: Плохо. Много сторов, много ивентов. Но нативно для юзера. Переделать на кэширование данных в API?
 */
import { createEffect, createEvent, createStore, forward } from 'effector/compat';
import {
  Currency,
  IPaymentListItemDto,
  IPaymentsAccountCurrencyFilter,
  IPaymentsStatusFilter,
  PaymentStatus,
  PaymentsFilterName,
  PaymentsSortName,
  SortOrder,
} from '@hypetrainCommon';
import { paymentsApiService } from '@api/payments';
import {
  COUNT_PAYMENTS_ON_PAGE,
  DEFAULT_PAYMENTS_CURRENCY_FILTER,
  INIT_PAYMENTS_OFFSET,
  INIT_PAYMENTS_STATE,
  INIT_SELECTED_PAYMENTS,
} from './payments.constants';
import {
  TGetPaymentsAPIFxParams,
  TPaymentCurrecyFilter,
  TPaymentsFilterName,
  TPaymentsOffset,
  TPaymentsTotalAndFees,
  TSelectedPayments,
} from './payments.types';
import { hasSelectedPayments } from './payments.utils';
import { resetPayments } from './paymentsAccounts.model';

/*
 * Событие выбора платежа из списка.
 */
export const selectPayment = createEvent<IPaymentListItemDto>();

/*
 * Собыие снятия выбора платежа из списка.
 */
export const unselectPayment = createEvent<IPaymentListItemDto>();

/*
 * Снятие выбора всех платежей из списка.
 */
export const selectAllPayments = createEvent<TPaymentCurrecyFilter>();

/*
 * Событие снятия выбора со всех платежей из списка.
 */
export const unselectAllPayments = createEvent<TPaymentCurrecyFilter>();

/*
 * Устанавливает фильтрацию платежей по валюте.
 */
export const setPaymentsCurrencyFilter = createEvent<TPaymentCurrecyFilter>();

/*
 * Устанавливает offset для загрузки списка платежей.
 */
export const increasePaymentsOffset = createEvent<TPaymentsFilterName>();

/*
 * Текущий фильтр платажей по валюте.
 */
export const $paymentsCurrencyFilter = createStore<TPaymentCurrecyFilter>(
  DEFAULT_PAYMENTS_CURRENCY_FILTER
)
  .on(setPaymentsCurrencyFilter, (_, newFilter) => newFilter)
  .reset(resetPayments);

/*
 * Текущей offset для загрузки списка платежей.
 */
export const $loadPaymentsOffset = createStore<TPaymentsOffset[]>(INIT_PAYMENTS_OFFSET)
  .on(increasePaymentsOffset, (currentOffsets, paymentsFilter) =>
    currentOffsets.map((offset) => {
      if (offset?.filterValue === paymentsFilter) {
        return { ...offset, offset: offset.offset + COUNT_PAYMENTS_ON_PAGE };
      }
      return offset;
    })
  )
  .reset(resetPayments);

/*
 * Загружает список платежей.
 */
const getPaymentsAPIFx = createEffect(({ filters, sorts, offset }: TGetPaymentsAPIFxParams) =>
  paymentsApiService.getPayments({
    limit: COUNT_PAYMENTS_ON_PAGE,
    sorts,
    filters,
    offset,
  })
);

/*
 * Загружает список готовых к проведению в USD платежей.
 */
export const getUSDReadyPaymnetsFx = createEffect(() => {
  const sorts = [{ name: PaymentsSortName.DueDate, options: { order: SortOrder.Descending } }];
  const currencyFilter: IPaymentsAccountCurrencyFilter = {
    name: PaymentsFilterName.AccountCurrency,
    value: Currency.USD,
  };

  const statusFilter: IPaymentsStatusFilter = {
    name: PaymentsFilterName.Status,
    value: PaymentStatus.Ready,
  };

  const offset =
    $loadPaymentsOffset.getState().find((o) => o.filterValue === Currency.USD)?.offset || 0;

  return getPaymentsAPIFx({
    filters: [currencyFilter, statusFilter],
    sorts,
    offset,
  });
});

/*
 * Загружает список готовых к проведению в EUR платежей.
 */
export const getEURReadyPaymnetsFx = createEffect(() => {
  const sorts = [{ name: PaymentsSortName.DueDate, options: { order: SortOrder.Descending } }];
  const currencyFilter: IPaymentsAccountCurrencyFilter = {
    name: PaymentsFilterName.AccountCurrency,
    value: Currency.EUR,
  };

  const statusFilter: IPaymentsStatusFilter = {
    name: PaymentsFilterName.Status,
    value: PaymentStatus.Ready,
  };

  const offset =
    $loadPaymentsOffset.getState().find((o) => o.filterValue === Currency.EUR)?.offset || 0;

  return getPaymentsAPIFx({
    filters: [currencyFilter, statusFilter],
    sorts,
    offset,
  });
});

/*
 * Загружает список выполненных платежей.
 */
export const getPaidPaymnetsFx = createEffect(() => {
  const sorts = [{ name: PaymentsSortName.PaidAt, options: { order: SortOrder.Descending } }];
  const statusFilter: IPaymentsStatusFilter = {
    name: PaymentsFilterName.Status,
    value: PaymentStatus.Paid,
  };

  const offset =
    $loadPaymentsOffset.getState().find((o) => o.filterValue === PaymentStatus.Paid)?.offset || 0;

  return getPaymentsAPIFx({
    filters: [statusFilter],
    sorts,
    offset,
  });
});

/*
 * Стор платежей.
 * Платежи храняться сразу разделённые по фильтрам, что бы не грузить их каждый раз сново при возврате к ранее уже выбранному фильтру.
 */
export const $payments = createStore(INIT_PAYMENTS_STATE)
  .on(getUSDReadyPaymnetsFx.doneData, (prevState, loadedUSDPayments) => {
    const prevUSDPayments = prevState[Currency.USD];
    const allLoadedUSDPayment = [
      ...(prevUSDPayments?.results || []),
      ...(loadedUSDPayments?.results || []),
    ];
    const usdPayments = {
      ...loadedUSDPayments,
      results: allLoadedUSDPayment,
    };

    return {
      ...prevState,
      [Currency.USD]: usdPayments,
    };
  })
  .on(getEURReadyPaymnetsFx.doneData, (prevState, loadedEURPayments) => {
    const prevEURPayments = prevState[Currency.EUR];
    const allLoadedEURPayment = [
      ...(prevEURPayments?.results || []),
      ...(loadedEURPayments?.results || []),
    ];
    const eurPayments = {
      ...loadedEURPayments,
      results: allLoadedEURPayment,
    };

    return {
      ...prevState,
      [Currency.EUR]: eurPayments,
    };
  })
  .on(getPaidPaymnetsFx.doneData, (prevState, loadedPaidPayments) => {
    const prevPaidPayments = prevState[PaymentStatus.Paid];
    const allLoadedPaidPayment = [
      ...(prevPaidPayments?.results || []),
      ...(loadedPaidPayments?.results || []),
    ];
    const paidPayments = {
      ...loadedPaidPayments,
      results: allLoadedPaidPayment,
    };

    return {
      ...prevState,
      [PaymentStatus.Paid]: paidPayments,
    };
  })
  .reset(resetPayments);

/*
 * Выбранные пользователем платежи.
 */
export const $selectedPayments = createStore<TSelectedPayments>(INIT_SELECTED_PAYMENTS)
  .on(selectPayment, (currentSelected, newSelectedPayment) => {
    const selectedCurrency = $paymentsCurrencyFilter.getState();

    return {
      ...currentSelected,
      [selectedCurrency]: [...currentSelected[selectedCurrency], newSelectedPayment],
    };
  })
  .on(unselectPayment, (currentSelected, unselectedPayment) => {
    const selectedCurrency = $paymentsCurrencyFilter.getState();

    return {
      ...currentSelected,
      [selectedCurrency]: currentSelected[selectedCurrency]?.filter(
        (payment) => payment?.id !== unselectedPayment?.id
      ),
    };
  })
  .on(selectAllPayments, (currentSelected, selectCurrency) => {
    const payments = $payments.getState()?.[selectCurrency]?.results || [];

    return {
      ...currentSelected,
      [selectCurrency]: payments,
    };
  })
  .on(unselectAllPayments, (currentSelected, unselectCurrency) => ({
    ...currentSelected,
    [unselectCurrency]: [],
  }))
  .reset(resetPayments);

/*
 * Рассчитывает суммы и комиссии по выбранным платежам.
 */
export const calculatePaymentsFx = createEffect(() => {
  const accountCurrency = $paymentsCurrencyFilter.getState();
  const selectedPayments = $selectedPayments.getState();
  const selectedPaymentsByCurrency = selectedPayments[accountCurrency];
  const paymentIds = selectedPaymentsByCurrency?.map((payment) => payment?.id);

  return paymentsApiService.calculatePaymentsFx({ paymentIds, accountCurrency });
});

/*
 * Реакция на необходимость загрузки дополнительных платежей.
 */
export const onLoadMorePaymentsFx = createEffect((paymentsFilter: TPaymentsFilterName) => {
  const paymentsTotal = $payments.getState()?.[paymentsFilter]?.total || 0;
  const currentOffset = $loadPaymentsOffset
    .getState()
    .find((offset) => offset.filterValue === paymentsFilter)?.offset;

  if ((currentOffset || 0) + COUNT_PAYMENTS_ON_PAGE < paymentsTotal) {
    increasePaymentsOffset(paymentsFilter);
  }
});

/*
 * Суммарная стоимость и коммиссии по выбранным платежам.
 */
export const $calculatedPaymentsSummary = createStore<TPaymentsTotalAndFees>({
  [Currency.USD]: null,
  [Currency.EUR]: null,
})
  .on(calculatePaymentsFx.doneData, (current, calculated) => ({
    ...current,
    [calculated?.currency]: calculated,
  }))
  .reset(resetPayments);

/*
 * Реакция на изменение фильтра по платежам.
 */
export const onChangePaymentsFilterFx = createEffect((paymentsFilter: TPaymentsFilterName) => {
  const isPaymentsLoaded = $payments.getState()?.[paymentsFilter] !== null;

  if (isPaymentsLoaded) return;

  paymentsFilter === Currency.USD ? getUSDReadyPaymnetsFx() : getEURReadyPaymnetsFx();
});

/*
 * Выполненяет платежи.
 */
export const executePaymentsFx = createEffect(() => {
  const accountCurrency = $paymentsCurrencyFilter.getState();
  const selectedPayments = $selectedPayments.getState();
  const selectedPaymentsByCurrency = selectedPayments[accountCurrency];
  const paymentIds = selectedPaymentsByCurrency?.map((payment) => payment?.id);

  return paymentsApiService.executePayments({
    paymentIds,
    accountCurrency,
  });
});

forward({
  from: [increasePaymentsOffset.filter({ fn: (filterValue) => filterValue === Currency.USD })],
  to: getUSDReadyPaymnetsFx,
});

forward({
  from: [increasePaymentsOffset.filter({ fn: (filterValue) => filterValue === Currency.EUR })],
  to: getEURReadyPaymnetsFx,
});

forward({
  from: [
    increasePaymentsOffset.filter({ fn: (filterValue) => filterValue === PaymentStatus.Paid }),
  ],
  to: getPaidPaymnetsFx,
});

forward({
  from: [$selectedPayments.updates.filter({ fn: (payments) => hasSelectedPayments(payments) })],
  to: calculatePaymentsFx,
});

forward({
  from: setPaymentsCurrencyFilter,
  to: onChangePaymentsFilterFx,
});
