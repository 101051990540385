/*
 * Компонент для отображения текста содержащего подсветку подстрок
 */
import { FC, PropsWithChildren, useMemo } from 'react';
import styles from './HighlightedText.module.scss';

type THighlightedTextProps = {
  text: string;
  highlightedString?: string;
};

export const HighlightedText: FC<PropsWithChildren<THighlightedTextProps>> = ({
  highlightedString,
  text,
}) => {
  const preparedText = useMemo(() => {
    if (!highlightedString) return text;

    // создаем регексп для поиска подсвечиваемой подстроки
    const regex = new RegExp(`(${highlightedString})`, 'gi');
    // разбиваем строку на подстроки
    const parts = text.split(regex);

    // отфильтровываем пустые строки, затем формируем собираем html из подсвеченных и неподсвеченных подстрок
    return parts
      .filter((part) => part)
      .map((part, index) =>
        // eslint-disable-next-line react/no-array-index-key
        regex.test(part) ? <mark key={index}>{part}</mark> : <span key={index}>{part}</span>
      );
  }, [text, highlightedString]);

  return <span className={styles.highlightedText}>{preparedText}</span>;
};
