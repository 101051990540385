// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectFieildDropdownIndicator_turnOver__iE0TV{transform:rotate(180deg)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/SelectField/components/SelectFieildDropdownIndicator/SelectFieildDropdownIndicator.module.scss"],"names":[],"mappings":"AAGE,+CACE,wBAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.selectFieildDropdownIndicator {\n  &_turnOver {\n    transform: rotate(180deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectFieildDropdownIndicator_turnOver": "selectFieildDropdownIndicator_turnOver__iE0TV"
};
export default ___CSS_LOADER_EXPORT___;
