import _get from 'lodash/get';
import { FC, memo, useMemo } from 'react';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '@uikit/components/Buttons';
import { CheckboxField } from '@uikit/components/Fields/CheckboxField';
import { ICON_TYPES } from '@uikit/components/Icon';
import { noop } from '@uikit/helpers/common.helpers';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import styles from './SelectRowCheckbox.module.scss';

type TCheckboxProps = {
  id: string;
  selectedRows: unknown[];
  onUnselect: (props?: unknown) => unknown;
  onSelect: (props?: unknown) => unknown;
  withPartialSelection?: boolean; // TODO Антон: Переименовать. Если передать, то выглядит как checkbox, а не кнопка.
  pathId?: string;
  className?: string;
};

export const SelectRowCheckbox: FC<TCheckboxProps> = memo(
  ({ id, selectedRows, onUnselect, onSelect, withPartialSelection, pathId = null, className }) => {
    const { t } = useUiKitTranslation();
    const toggleRowSelection = (isChecked: boolean) => (isChecked ? onUnselect() : onSelect());

    const withPath = (row: unknown) => {
      if (!pathId) return row;
      return _get(row, pathId);
    };

    const isRowChecked = useMemo(
      () => !!selectedRows.some((row) => withPath(row) === id),
      [selectedRows, id]
    );

    const handleChange = () => {
      toggleRowSelection(isRowChecked);
    };

    if (withPartialSelection) {
      return (
        <CheckboxField
          input={{
            name: `checkbox_${id}`,
            checked: isRowChecked,
            onChange: handleChange,
            onFocus: noop,
            onBlur: noop,
            value: '',
          }}
          className={className}
        />
      );
    }

    return (
      <Button
        className={styles.selectRowCheckboxButton}
        buttonType={isRowChecked ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.SECONDARY}
        icon={isRowChecked ? ICON_TYPES.CHECK : undefined}
        size={BUTTON_SIZE.XS}
        onClick={handleChange}
      >
        {!isRowChecked && t('selectRowCheckbox.addColumn')}
      </Button>
    );
  }
);
