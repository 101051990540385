import { IGetUserMessagesResponseDto, MessageStatusChangedNotification } from '@hypetrainCommon';
import { TCreateAndSendMessageParams } from '@api/communication';
import { TCreateMessageParams } from './dealPageCommunication.types';

/*
 * Возвращает объект с параметрами для выполнения запроса на создание нового сообщения в чате.
 */
export function getCreateMessageQueryParams(
  params: TCreateMessageParams
): TCreateAndSendMessageParams {
  return {
    chatId: params.deal?.chat?.id,
    body: {
      recipients: params.recipients.map((person) => person?.id),
      dealId: params.deal?.id,
      campaignId: params.campaignId,
      data: params.data,
      subject: params.subject,
      attachmentIds: params.attachmentIds,
    },
  };
}

/*
 * Обновляет статус сообщения в чате.
 * Находитит обновляемое сообщение из списка и обновляет статусы доставки сообщения у получателей.
 */
export function updateMessageStatus(
  messages: IGetUserMessagesResponseDto[],
  update: MessageStatusChangedNotification
): IGetUserMessagesResponseDto[] {
  return messages?.map((message) => {
    if (message?.id !== update?.data?.messageId) return message;

    const updatedRecipients = message?.recipients?.map((recipient) => {
      const updatedPerson = update?.data?.recipientsStatuses?.find(
        (status) => status.personId === recipient?.person?.id
      );
      const actualStatus = updatedPerson?.status || recipient?.status; // актуальным статусом является либо обновлённый (если пришёл), либо текущий (предыдущий

      return {
        ...recipient,
        status: actualStatus,
      };
    });

    return {
      ...message,
      recipients: updatedRecipients,
    };
  });
}
