import { useStore } from 'effector-react/compat';
import { FC } from 'react';
import { SelectRowCheckbox } from '@uikit/components/Table/components/columns/SelectRowCheckbox';
import {
  $campaignSelectionStore,
  addToCampaignSelection,
  deleteFromCampaignSelection,
} from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.model';

type TSelectionCheckbox = {
  campaignId: string;
  className?: string;
};

export const SelectCampaignCheckbox: FC<TSelectionCheckbox> = ({ campaignId, className }) => {
  const selectedCampaignDeals = useStore($campaignSelectionStore);

  return (
    <SelectRowCheckbox
      id={campaignId}
      selectedRows={selectedCampaignDeals}
      onUnselect={() => deleteFromCampaignSelection(campaignId)}
      onSelect={() => addToCampaignSelection(campaignId)}
      className={className}
      withPartialSelection
    />
  );
};
