/*
 * Модель для flow регистрации пользователя.
 */
import { createEvent, createStore } from 'effector/compat';

type TRedirectFromInfo = {
  url: string;
};

/*
 * Установка указатель на то, откуда произошёл редирект при открытии приложения.
 */
export const setRedirectFrom = createEvent<TRedirectFromInfo>();

/*
 * Стор хранит указатель на то, откуда произошёл редирект при открытии приложения.
 */
export const $redirectFrom = createStore<TRedirectFromInfo | null>(null).on(
  setRedirectFrom,
  (state, redirectFrom) => state || redirectFrom
);
