import _keyBy from 'lodash/keyBy';
import { ICampaignDealsResponseDto } from '@hypetrainCommon';
import { TCampaignDealsResponse } from '@api/campaigns/campaigns.api.types';

/*
 * Подготовка внутренней модели deals
 */
export const prepareDeals = (dealsResponse: ICampaignDealsResponseDto): TCampaignDealsResponse => {
  // мапим список персон чтобы не пришлось для каждой персоны в диле обходить общий список персон
  const personsMap = _keyBy(dealsResponse.persons, 'id');

  const preparedDeals = dealsResponse.deals?.map((deal) => ({
    ...deal,
    persons: deal?.persons?.map((personId) => personsMap[personId]),
  }));

  return { ...dealsResponse, deals: preparedDeals };
};
