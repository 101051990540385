import { RANGE_TYPES } from '@uikit/components/Filter/components/RangeFilter/RangeFilter.types';
import { FORMAT_NUMBER_STYLES } from '@uikit/components/Formats';

export enum FILTER_TYPES {
  RANGE = 'RANGE',
  RADIO = 'RADIO',
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
  SELECT = 'SELECT',
  ASYNC_SELECT = 'ASYNC_SELECT',
  KEYWORDS = 'KEYWORDS',
  TOGGLE = 'TOGGLE',
  INPUT = 'INPUT',
}

export const FILTER_NUMBER_FORMATS_MAP = {
  [RANGE_TYPES.NUMBER]: FORMAT_NUMBER_STYLES.DECIMAL,
  [RANGE_TYPES.PERCENT]: FORMAT_NUMBER_STYLES.PERCENT,
  [RANGE_TYPES.CURRENCY]: FORMAT_NUMBER_STYLES.CURRENCY,
  [RANGE_TYPES.SELECT]: FORMAT_NUMBER_STYLES.DECIMAL,
};
