/*
 * Dialog window component.
 */
import {
  FC,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  isValidElement,
  useCallback,
  useRef,
} from 'react';
import { BUTTON_TYPE, BackButton, Button } from '@uikit/components/Buttons';
import { useDialog } from '@uikit/components/Dialog/hooks/useDialog.hook';
import { useModalContent } from '@uikit/components/Dialog/hooks/useDialogContent.hook';
import { ErrorBoundary } from '@uikit/components/ErrorBoundary';
import { ModalWindow } from '@uikit/components/ModalWindow';
import styles from './dialog.module.scss';

type TDialogBackButton = {
  handler: () => void; // button click callback
  className?: string; // custom class name for back button
  text: string; // text in button
};

type TDialogButton = {
  text: string; // text in button
  type?: BUTTON_TYPE; // button type (PRIMARY by default)
  handler: (e: MouseEvent<HTMLButtonElement>) => void; // button click callback
  disabled?: boolean; // button disable sign
};

type TDialogProps = {
  title: string | ReactElement; // dialog title
  width?: string; // dialog width
  minHeight?: string; // max dialog height
  buttons: TDialogButton[]; // list dialog buttons
  onClose?: () => void; // callback that will be invoke on dialog window close by user
  hideButtons?: boolean; // sign of the need to hide buttons (space that buttons occupy will be saved/not hide)
  fixHeight?: boolean; // sign of the need to fix dialog height (without resize on change content height)
  withClose?: boolean; // признак возможности закрытия диалога (отображения креста и закрытия по клику на фон)
  autofocus?: boolean; // признак необходимости автофокусировки диалога (применяется по умолчанию, что бы убрать управление через клавиатуру элементами на заднем фоне диалога)
  backButton?: TDialogBackButton; // back кнопка над title диалога.
};

export const Dialog: FC<PropsWithChildren<TDialogProps>> = ({
  title,
  width = '540px',
  minHeight = '540px',
  buttons,
  onClose,
  hideButtons,
  fixHeight = true,
  autofocus = true,
  withClose = true,
  backButton,
  children,
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const contentRef = useModalContent(dialogRef, [headerRef, footerRef], fixHeight, minHeight);
  const dialogAPI = useDialog();

  const onDialogClose = useCallback((): void => {
    if (onClose) onClose();
    dialogAPI.reject();
  }, []);

  return (
    <ModalWindow
      onClose={onDialogClose}
      autofocus={autofocus}
      withClose={withClose}
    >
      <ErrorBoundary layerName="dialog">
        <div
          ref={dialogRef}
          className={styles.dialog}
          style={{ minHeight, width }}
        >
          <div>
            <div
              ref={headerRef}
              className={styles.dialog__header}
            >
              {backButton && (
                <BackButton
                  onClick={backButton.handler}
                  className={backButton.className}
                >
                  {backButton.text}
                </BackButton>
              )}
              <div className={styles.dialog__title}>
                {isValidElement(title) ? title : <h2>{title}</h2>}
              </div>
            </div>

            <div ref={contentRef}>{children}</div>
          </div>

          <div
            ref={footerRef}
            className={styles.dialog__buttons}
          >
            {!hideButtons &&
              buttons.map((button) => (
                <Button
                  key={button.text}
                  buttonType={button.type || BUTTON_TYPE.PRIMARY}
                  onClick={button.handler}
                  disabled={button.disabled}
                >
                  {button.text}
                </Button>
              ))}
          </div>
        </div>
      </ErrorBoundary>
    </ModalWindow>
  );
};
