/*
 * List of users with their icons and total users counter.
 */
import cn from 'clsx';
import { FC } from 'react';
import { AVATAR_SIZES, Avatar } from '@uikit/components/Avatar';
import { PopupPositions } from '@uikit/components/Popup';
import { Tooltip } from '@uikit/components/Tooltip';
import { InfluencerAvatar } from '@components/InfluencerAvatar';
import {
  USERS_LIST_COUNTER_MODE,
  UsersListCounter,
} from '@components/UsersList/components/UsersListCounter';
import { TUsersListItem } from '@components/UsersList/usersList.types';
import styles from './UsersList.module.scss';

type TUsersListProps = {
  total: number; // total users count
  users: TUsersListItem[]; // users list
  maxShowCount?: number; // count users that will show (other will hide in counter)
  counterMode?: USERS_LIST_COUNTER_MODE; // mode for counter
  className?: string;
  avatarSize?: AVATAR_SIZES;
};

export const UsersList: FC<TUsersListProps> = ({
  total,
  users,
  maxShowCount,
  counterMode = USERS_LIST_COUNTER_MODE.SHOW_ALL,
  className,
  avatarSize,
}) => {
  if (!users?.length || !total) return null;

  const showCounterOfOthers = total > Number(maxShowCount);

  return (
    <div className={cn(styles.usersList, className)}>
      {users?.slice(0, maxShowCount).map((user) => (
        <Tooltip
          key={user.id}
          className={styles.usersList__avatarContainer}
          content={<div>{user.name}</div>}
          position={PopupPositions.BottomCenter}
          offset={20}
        >
          {user.thumbnail ? (
            <InfluencerAvatar
              className={user.avatarClassName}
              influencerId={user.id}
              img={user.thumbnail}
              size={avatarSize}
            />
          ) : (
            <Avatar
              className={user.avatarClassName}
              size={avatarSize}
              idForColor={user.id}
              entityName={user.name}
            />
          )}
        </Tooltip>
      ))}
      {showCounterOfOthers && (
        <UsersListCounter
          total={total}
          users={users}
          maxShowCount={maxShowCount}
          mode={counterMode}
        />
      )}
    </div>
  );
};
