/*
 * Компонент-посредник для отрисовки ютуб(youtube) версии круговой диаграммы стран
 * */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IYoutubeSmaResponseDto,
  IYoutubeSmaStatisticsResponseDto,
  SmaPlatform,
} from '@hypetrainCommon';
import { isIYoutubeSmaStatisticsResponseDto } from '@components/AudienceDemographic/AudienceDemographicYoutube.component';
import { AudienceLocation } from '@components/AudienceLocation/components/AudienceLocation.component';

type TAudienceLocationYoutubeProps = {
  influencer: IYoutubeSmaStatisticsResponseDto | IYoutubeSmaResponseDto;
  isProfile?: boolean;
};

export const AudienceLocationYoutube: FC<TAudienceLocationYoutubeProps> = ({
  influencer,
  isProfile = false,
}) => {
  const { t } = useTranslation();
  const countryValues = influencer?.audience?.countries?.values;
  const subscribers = influencer?.metrics?.subscriberCount;

  return (
    <AudienceLocation
      countries={countryValues}
      subscribers={subscribers}
      platform={SmaPlatform.Youtube}
      isProfile={isProfile}
      info={t('profile.audience.locationYoutubeInfo')}
      uuid={
        isIYoutubeSmaStatisticsResponseDto(influencer) ? influencer?.sma?.uuid : influencer?.uuid
      }
    />
  );
};
