// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchInput__SCCv0 input{height:32px !important;padding-right:calc(16px + calc(32px / 2));position:relative;border-radius:calc(32px / 2) !important}.searchInput__container__cOE68{position:relative}.searchInput__icon__cI_ja{position:absolute;top:calc(32px / 2);right:calc(32px / 2)}.searchInput__icon_close__RiyDj{top:18px;cursor:pointer}.searchInput__icon_search__ZJPvf{cursor:pointer}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Fields/Inputs/SearchInput/SearchInput.module.scss"],"names":[],"mappings":"AAOE,0BACE,sBAAA,CACA,yCAAA,CACA,iBAAA,CACA,uCAAA,CAGF,+BACE,iBAAA,CAGF,0BACE,iBAAA,CACA,kBAAA,CACA,oBAjB6B,CAmB7B,gCACE,QAAA,CACA,cAAA,CAGF,iCACE,cAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n$search-input-height: 32px;\n$search-input-icon-size: 16px;\n$search-input-icon-margin-right: calc(#{$search-input-height} / 2);\n\n.searchInput {\n  input {\n    height: $search-input-height !important;\n    padding-right: calc(#{$search-input-icon-size} + #{$search-input-icon-margin-right});\n    position: relative;\n    border-radius: calc(#{$search-input-height} / 2) !important;\n  }\n\n  &__container {\n    position: relative;\n  }\n\n  &__icon {\n    position: absolute;\n    top: calc(#{$search-input-height} / 2);\n    right: $search-input-icon-margin-right;\n\n    &_close {\n      top: 18px;\n      cursor: pointer;\n    }\n\n    &_search {\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": "searchInput__SCCv0",
	"searchInput__container": "searchInput__container__cOE68",
	"searchInput__icon": "searchInput__icon__cI_ja",
	"searchInput__icon_close": "searchInput__icon_close__RiyDj",
	"searchInput__icon_search": "searchInput__icon_search__ZJPvf"
};
export default ___CSS_LOADER_EXPORT___;
