/*
 * Сервис для работы с API-запросами подписок.
 */
import { ApiService, apiService } from '@api';
import {
  ICreateCheckoutSessionRequestDto,
  ICreatePortalSessionRequestDto,
  IInvoiceInfoDto,
  IPaginatedDto,
  IProrationInfoResponseDto,
  IProviderSessionDto,
  ISubscriptionInfoResponseDto,
  ISubscriptionPlanInfoDto,
  ITaxInfoResponseDto,
  IUpdateSubscriptionRequestDto,
} from '@hypetrainCommon';
import {
  UPDATE_SUBSCRIPTION_PAYMENT_ERRORS,
  WRONG_TAX_LOCATION,
} from '@api/subscription/subscription.api.constants';
import { TCreateSubscriptionParams } from '@api/subscription/subscription.api.types';

/* eslint-disable arrow-body-style */
export class SubscriptionApiService {
  constructor(private readonly api: ApiService) {}

  /*
   * Создание подписки.
   * @see: https://reference.api.team-master-dev.hypetrain.io/?http#create-new-subscription
   */
  public createSubscription = (
    params: TCreateSubscriptionParams
  ): Promise<ISubscriptionInfoResponseDto> => {
    return this.api
      .post(
        '/workspaces/subscription',
        params.body,
        {
          headers: { 'x-workspace-id': params.workspaceId },
        },
        { errorCodesToIgnore: ['input-validation-error', WRONG_TAX_LOCATION] }
      )
      .then((response) => response.data);
  };

  /*
   * Обновление подписки.
   * @see: https://reference.api.team-master-dev.hypetrain.io/?shell#update-current-subscription
   */
  public updateSubscription = (
    data: IUpdateSubscriptionRequestDto
  ): Promise<ISubscriptionInfoResponseDto> => {
    return this.api
      .patch(
        '/workspaces/subscription',
        data,
        {},
        { errorCodesToIgnore: UPDATE_SUBSCRIPTION_PAYMENT_ERRORS }
      )
      .then((response) => response.data);
  };

  /*
   * Получение подписки.
   * @see: https://reference.api.team-master-dev.hypetrain.io/?shell#get-current-subscription
   */
  public getSubscription = (): Promise<ISubscriptionInfoResponseDto> => {
    return this.api.get('/workspaces/subscription').then((response) => response.data);
  };

  /*
   * Получение ссылки на портал пользователя.
   * @see: https://reference.api.team-master-dev.hypetrain.io/?shell#create-new-session-to-the-customer-portal
   */
  public getCustomerPortalUrl = (
    data: ICreatePortalSessionRequestDto
  ): Promise<IProviderSessionDto> => {
    return this.api
      .post('/workspaces/subscription/sessions/customer-portal', data)
      .then((response) => response.data);
  };

  /*
   * Отмена подписки.
   * @see: https://reference.api.team-master-dev.hypetrain.io/?http#cancel-subscription
   */
  public cancelSubscription = (): Promise<ISubscriptionInfoResponseDto> => {
    return this.api.post('/workspaces/subscription/cancel').then((response) => response.data);
  };

  /*
   * Возобновление подписки.
   * @see: https://reference.api.team-master-dev.hypetrain.io/?http#resume-subscription
   */
  public resumeSubscription = (): Promise<ISubscriptionInfoResponseDto> => {
    return this.api.post('/workspaces/subscription/resume').then((response) => response.data);
  };

  /*
   * Получение ссылки на чекаут сессию
   * @see: https://reference.api.team-master-dev.hypetrain.io/?shell#create-new-checkout-session
   */
  public createCheckout = (
    data: ICreateCheckoutSessionRequestDto
  ): Promise<IProviderSessionDto> => {
    return this.api
      .post('/workspaces/subscription/sessions/checkout', data)
      .then((response) => response.data);
  };

  /*
   * Получить список доступных тарифных планов.
   * @see: https://reference.api.team-master-dev.hypetrain.io/?http#get-subscription-plans
   */
  public getSubscriptionPlans = (showLoader = true): Promise<ISubscriptionPlanInfoDto[]> => {
    return this.api
      .get('/workspaces/subscription/plans', {}, { showLoader })
      .then((response) => response.data);
  };

  /*
   * Получение истории платежей
   * @see: https://reference.api.team-master-dev.hypetrain.io/?shell#get-invoice-history
   */
  public getBillingHistory = (): Promise<IPaginatedDto<IInvoiceInfoDto>> => {
    return this.api
      .get('/workspaces/subscription/invoices', { params: { limit: 9999 } })
      .then((response) => response.data);
  };

  /*
   * Расчёт пропорций при добавлении ситов.
   * @see: https://reference.api.team-master-dev.hypetrain.io/?http#calculate-a-proration-of-subscription
   */
  public calculateProration = (
    body: IUpdateSubscriptionRequestDto
  ): Promise<IProrationInfoResponseDto> => {
    return this.api
      .post('/workspaces/subscription/proration', body)
      .then((response) => response.data);
  };

  /*
   * Запрос суммы налогов.
   * @see: https://reference.api.team-master-test.hypetrain.io/?http#get-tax-amounts
   */
  public getTaxAmounts = (showLoader: boolean): Promise<ITaxInfoResponseDto> => {
    return this.api
      .get('/workspaces/subscription/tax-amounts', {}, { showLoader })
      .then((response) => response.data);
  };

  /*
   * Возвращает режим работы подписки пользователя.
   * @see: https://reference.api.team-master-dev.hypetrain.io/?http#get-mode
   */
  public getMode = (): Promise<ISubscriptionInfoResponseDto> => {
    return this.api
      .get('/workspaces/subscription/get-mode', {}, { showLoader: false })
      .then((response) => response.data);
  };
}

export const subscriptionApiService = new SubscriptionApiService(apiService);
