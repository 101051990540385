import cn from 'clsx';
import { FC, memo, useState } from 'react';
import { Row as IRow, UseExpandedRowProps } from 'react-table';
import { ListChildComponentProps, areEqual } from 'react-window';
import { PopupPositions } from '@uikit/components/Popup';
import styles from '@uikit/components/Table/Table.module.scss';
import { TTableColumn, TTableData } from '@uikit/components/Table/table.types';
import { Tooltip } from '@uikit/components/Tooltip';
import { TableRowProvider } from '../../tableRow.context';

export const Row: FC<ListChildComponentProps<TTableData>> = memo(
  ({ index, style, data: { rows, prepareRow, totalColumnsWidth, rowClassName } }) => {
    const [isHovered, setIsHovered] = useState(false);
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const row = rows[index] as IRow<any> & UseExpandedRowProps<any>;
    if (!row) return <div>Loading...</div>;
    prepareRow(row);

    const { style: rowStyle, ...restRow } = row.getRowProps({ style });

    const rowContent = (
      <TableRowProvider value={{ isHovered }}>
        <tr
          {...restRow}
          style={{ ...rowStyle, width: totalColumnsWidth }}
          // First column(button) not hiding under ::before background because it have position relative
          className={cn(
            styles.tr,
            // todo Пожалуйста исправь на rowClassNameItem
            row?.original?.isDisabled && styles.isDeletedOpacity,
            rowClassName,
            row?.original?.rowClassNameItem
          )}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => {
            if (row.canExpand) {
              row.toggleRowExpanded(!row.isExpanded);
            }
          }}
        >
          {row.cells.map((cell) => (
            <td
              {...cell.getCellProps()}
              className={cn(styles.td, (cell.column as TTableColumn).className)}
            >
              {cell.render('Cell')}
            </td>
          ))}
        </tr>
      </TableRowProvider>
    );

    if (row?.original?.textToolTip) {
      return (
        <Tooltip
          content={<span>{row.original.textToolTip}</span>}
          position={PopupPositions.BottomCenter}
          offset={10}
        >
          {rowContent}
        </Tooltip>
      );
    }

    return rowContent;
  },
  areEqual
);
