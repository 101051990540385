import { useEffect } from 'react';

export const useInitGoogleAnalytics = () => {
  useEffect(() => {
    if (process.env.ENVIRONMENT === 'prod') {
      const firstScriptToHead = document.createElement('script');
      firstScriptToHead.src = 'https://www.googletagmanager.com/gtag/js?id=GTM-NWMM3RS';
      firstScriptToHead.async = true;
      document?.head?.appendChild(firstScriptToHead);

      const secondScriptToHead = document.createElement('script');
      secondScriptToHead.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
  
        gtag('config', 'GTM-NWMM3RS');
      `;
      document?.head?.appendChild(secondScriptToHead);
    }
  }, []);
};
