/*
 * Service for executing requests for auth methods.
 */
import { ApiService, apiService } from '@api';
import {
  IChangePasswordDto,
  ICreateUserDto,
  ICredentialsResponseDto,
  IGoogleAuthRequestDto,
  IGoogleAuthResponseDto,
  IHandleEmailVerificationDto,
  IHandlePasswordResetDto,
  ILearnworldsAuthDto,
  IResetPasswordDto,
  ISignInRequestDto,
  ISignUpResponseDto,
  ISignUpWithInviteRequestDto,
  ISignUpWithInviteResponseDto,
} from '@hypetrainCommon';
import {
  CHANGE_PASSWORD_ERRORS,
  EMAIL_NOT_FOUND,
  LOGIN_EMAIL_ERROR_CODES,
  LOGIN_ERROR_CODES,
} from '@api/auth/auth.api.constants';
import { SIGNUP_EMAIL_ERROR_CODES } from '@pagesSignUp/signUp.constants';

/* eslint-disable arrow-body-style */
export class AuthApiService {
  constructor(private readonly api: ApiService) {}

  /*
   * Изменения пароля пользователя
   * @see: https://reference.api.rc-staging.hypetrain.io/#change-user-password
   */
  public changePassword = (body: IChangePasswordDto): Promise<void> => {
    return this.api
      .post(
        '/auth/change-password',
        body,
        { withCredentials: true },
        { errorCodesToIgnore: CHANGE_PASSWORD_ERRORS }
      )
      .then((response) => response.data);
  };

  /*
   * Инитиализация восстановления пароля
   * @see: https://reference.api.rc-staging.hypetrain.io/#initiate-user-password-reset
   */
  public initiateResetPassword = (email: IResetPasswordDto): Promise<void> => {
    return this.api
      .post(
        '/auth/initiate-password-reset',
        email,
        {},
        { errorCodesToIgnore: ['get-user--email-not-found'] }
      )
      .then((response) => response.data);
  };

  /*
   * Восстановление пароля
   * @see: https://reference.api.rc-staging.hypetrain.io/#handle-user-password-reset
   */
  public resetPassword = (body: IHandlePasswordResetDto): Promise<void> => {
    return this.api.post('/auth/handle-password-reset', body).then((response) => response.data);
  };

  /*
   * Авторизация пользователя.
   * @see: https://reference.api.team-master-test.hypetrain.io/?http#sign-in-user
   */
  public signIn = (credentials: ISignInRequestDto): Promise<ICredentialsResponseDto> => {
    return this.api
      .post(
        '/auth/sign-in',
        credentials,
        { withCredentials: true },
        { errorCodesToIgnore: [...LOGIN_ERROR_CODES, ...LOGIN_EMAIL_ERROR_CODES, EMAIL_NOT_FOUND] }
      )
      .then((response) => response.data);
  };

  /*
   * Регистрация пользователя.
   * @see: https://reference.api.team-master-test.hypetrain.io/?http#sign-up-user
   */
  public signUp = (credentials: ICreateUserDto): Promise<ISignUpResponseDto> => {
    return this.api
      .post(
        '/auth/sign-up',
        credentials,
        // Отправляем специальный заголовок со сгенеренным id если редирект произошёл с лэндинга (отслеживание пользователей зарегистрировавшихся по переходу с него).
        {
          withCredentials: true,
        },
        { errorCodesToIgnore: [...SIGNUP_EMAIL_ERROR_CODES] }
      )
      .then((response) => response.data);
  };

  /*
   * Регистрация пользователя по инвайту.
   * @see: https://reference.api.team-master-test.hypetrain.io/?http#sign-up-with-invite
   */
  public signUpWithInvite = (
    body: ISignUpWithInviteRequestDto
  ): Promise<ISignUpWithInviteResponseDto> => {
    return this.api
      .post('/auth/sign-up-with-invite', body, { withCredentials: true })
      .then((response) => response.data);
  };

  /*
   * Деавторизация (logout).
   * @see: https://reference.api.team-master-test.hypetrain.io/?http#sign-in-user
   */
  public signOut = (): Promise<void> => {
    return this.api
      .post('/auth/sign-out', {}, { withCredentials: true }, { skipErrorFn: () => true })
      .then((response) => response.data);
  };

  /*
   * Сбрасывает авторизационные токены.
   * @see: https://reference.api.team-master-test.hypetrain.io/?http#refresh-auth-tokens
   */
  public refreshAuthTokens = (fingerprint: string): Promise<ICredentialsResponseDto> => {
    return this.api
      .post(
        '/auth/refresh-tokens',
        { fingerprint },
        { withCredentials: true },
        { showLoader: false, skipErrorFn: () => true }
      )
      .then((response) => response.data);
  };

  /*
   * Проверка email пользоваталя.
   * @see: https://reference.api.team-master-test.hypetrain.io/?http#handle-user-email-verification
   */
  public userVerificationAndAuth = (
    body: IHandleEmailVerificationDto
  ): Promise<ICredentialsResponseDto> => {
    return this.api
      .post('/auth/handle-email-verification', body, { withCredentials: true })
      .then((response) => response.data);
  };

  /*
   * Auth через Google
   * @see: https://reference.api.team-f-dev.hypetrain.io/#google-sign-in-or-sign-up
   */
  public loginWithGoogle = (body: IGoogleAuthRequestDto): Promise<IGoogleAuthResponseDto> => {
    return this.api
      .post(
        '/auth/google',
        body,
        { withCredentials: true },
        {
          errorCodesToIgnore: [...LOGIN_ERROR_CODES, ...LOGIN_EMAIL_ERROR_CODES, EMAIL_NOT_FOUND],
          showLoader: true,
        }
      )
      .then((response) => response.data);
  };

  /*
   * Auth для академии
   */
  public loginAcademy = (body: ILearnworldsAuthDto): Promise<ICredentialsResponseDto> => {
    return this.api
      .post('/auth/learnworlds', body, { withCredentials: true }, { showLoader: true })
      .then((response) => response.data);
  };
}

export const authApiService = new AuthApiService(apiService);
