/*
 * Component-container for placing panels in app.
 */
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { PanelContext, panelStore } from '@uikit/components/Panel';

export const PanelsContainer: FC = observer(() => {
  const { panels } = panelStore;

  return (
    <>
      {panels.map(({ component: Component, props, id }) => (
        <PanelContext.Provider
          key={id}
          value={props.panel}
        >
          <Component {...toJS(props)} />
        </PanelContext.Provider>
      ))}
    </>
  );
});
