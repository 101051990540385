/*
 * Common constants.
 */

/*
 * ID of the node hosting the portals.
 */
export const APP_PORTAL_CONTAINER_ID = 'APP_PORTAL_CONTAINER';

/*
 * ID для нод внутри порталов, при клике по которым происходит закрытие портала.
 */
export const CLOSE_PORTAL_NODE_ID = 'CLOSE_PORTAL_NODE';

/*
 * Заглушка отображаемая в интерфейсе при отсутствии данных.
 */
export const EMPTY_PLACEHOLDER = '—';

/*
 * Ui-kit locales namespace name.
 */
export const UI_KIT_INTL_NAMESPACE_NAME = 'uikit';
