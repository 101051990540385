/*
 * Роутер раздела компаний.
 */
import { useStore } from 'effector-react/compat';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { lazily } from 'react-lazily';
import { Route } from 'react-router-dom';
import { Routes as Links } from 'router/Routes';
import { SmaPlatform } from '@hypetrainCommon';
import { PrivateRoute } from '@router/PrivateRoute';
import { campaignsUserAccessService } from '@services/userAccess';
import { CampaignStages } from '@pages/Campaigns/CampaignDetailsPage/components/CampaignStages';
import { Communication } from '@pages/Campaigns/CampaignDetailsPage/components/Communication';
import { DataAnalytics } from '@pages/Campaigns/CampaignDetailsPage/components/DataAnalytics';
import { $campaignStore } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.model';

const {
  CampaignsPage,
  CampaignWrapper,
  CampaignDetailsPage,
  CampaignDealPage,
  CampaignSettingsPage,
} = lazily(() => import('@pages/Campaigns'));

export const CampaignsRouter = (): JSX.Element | null => {
  const isCampaignssAvailable = campaignsUserAccessService.isAvailable;
  const campaign = useStore($campaignStore);
  const flags = useFlags();

  if (!isCampaignssAvailable) return null;

  return (
    <>
      <Route
        path={Links.campaigns}
        element={
          <PrivateRoute errorBoundaryName="campaigns">
            <CampaignsPage />
          </PrivateRoute>
        }
      />
      <Route
        element={
          <PrivateRoute errorBoundaryName="campaignWrapper">
            <CampaignWrapper />
          </PrivateRoute>
        }
      >
        {(!!flags.hypetrainTiktokDealDetails ||
          campaign?.campaignDetails?.platform !== SmaPlatform.Tiktok) && (
          <Route
            path={Links.campaignSettings}
            element={
              <PrivateRoute errorBoundaryName="campaignSettings">
                <CampaignSettingsPage />
              </PrivateRoute>
            }
          />
        )}

        <Route
          path={Links.campaignDeal}
          element={
            <PrivateRoute errorBoundaryName="campaignDeal">
              <CampaignDealPage />
            </PrivateRoute>
          }
        />

        <Route
          element={
            <PrivateRoute errorBoundaryName="CampaignDetailsPage">
              <CampaignDetailsPage />
            </PrivateRoute>
          }
        >
          <Route
            path={Links.dataAnalytics}
            element={
              <PrivateRoute errorBoundaryName="DataAnalytics">
                <DataAnalytics />
              </PrivateRoute>
            }
          />
          <Route
            path={Links.communication}
            element={
              <PrivateRoute errorBoundaryName="communication">
                <Communication />
              </PrivateRoute>
            }
          />
          <Route
            path={Links.stages}
            element={
              <PrivateRoute errorBoundaryName="stages">
                <CampaignStages />
              </PrivateRoute>
            }
          />
        </Route>
      </Route>
    </>
  );
};
