/*
 *   Модалка для добавления legacy entity
 */
import i18n from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ILegalEntityInfoResponseDto } from '@hypetrainCommon';
import { TDialogProps } from '@uikit/components/Dialog';
import { notificationsService } from '@uikit/components/Notification';
import { handleSubmitLegalEntityErrors } from '@pagesWorkspaceSettings/components/LegalEntity/LegalEntity.utils';
import { LegalEntityDialog } from '@pagesWorkspaceSettings/components/LegalEntity/components/LegalEntityDialog';
import {
  getLegalEntitiesFx,
  updateLegalEntityFx,
} from '@pagesWorkspaceSettings/components/LegalEntity/model';

type TLegalEntityDialogEditProps = {
  legalEntity: ILegalEntityInfoResponseDto;
};
export const LegalEntityDialogEdit: FC<TLegalEntityDialogEditProps & TDialogProps> = ({
  legalEntity,
  dialog,
}) => {
  const { t } = useTranslation();

  const updateLegalEntity = (
    values: ILegalEntityInfoResponseDto
  ): Promise<Record<string, string> | null> =>
    updateLegalEntityFx(values)
      .then(() => {
        getLegalEntitiesFx();
        dialog.resolve();
        notificationsService.successful({
          props: {
            caption: i18n.t('workspaceSettings.legalEntity.addSuccess'),
          },
        });
        return {};
      })
      .catch(handleSubmitLegalEntityErrors);

  return (
    <LegalEntityDialog
      legalEntity={legalEntity}
      action={updateLegalEntity}
      buttonText={t('workspaceSettings.legalEntity.modal.update')}
      title={t('workspaceSettings.legalEntity.modal.editHeader')}
    />
  );
};
