/*
 * Глобальный стор уведомлений
 */
import { makeAutoObservable } from 'mobx';
import { TNotificationsConfig } from './notification.types';

export class NotificationsStore {
  /*
   * Добавленные уведомления
   */
  public notifications: TNotificationsConfig[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  /*
   * Добавить уведомление в стор
   */
  public add(config: TNotificationsConfig): void {
    this.notifications.push(config);
  }

  /*
   * Удалить уведомление из стора
   */
  public delete(id: string): void {
    this.notifications = this.notifications.filter((notification) => notification.id !== id);
  }
}

export const notificationsStore = new NotificationsStore();
