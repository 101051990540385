import { TButtonProps } from '@uikit/components/Buttons';
import { ICON_TYPES } from '@uikit/components/Icon';

export type TNotificationAction = {
  text: string;
  button?: TButtonProps;
  onClick: () => void;
};

export enum NOTIFICATION_TYPES {
  INFO = 'INFO',
  ERROR = 'ERROR',
  SUCCESSFUL = 'SUCCESSFUL',
}

export const NOTIFICATION_TYPES_TO_ICON_TYPES: Omit<
  Record<NOTIFICATION_TYPES, ICON_TYPES>,
  NOTIFICATION_TYPES.INFO
> = {
  [NOTIFICATION_TYPES.ERROR]: ICON_TYPES.WARNING_WITH_STROKE,
  [NOTIFICATION_TYPES.SUCCESSFUL]: ICON_TYPES.SUCCESSFUL,
};

export type TNotificationProps = {
  type?: NOTIFICATION_TYPES;
  caption: string;
  description?: string;
  onClose?: () => void;
  actions?: TNotificationAction[];
  className?: string;
  slug?: string;
};

export type TAddNotificationsConfig = {
  timeout?: number;
  closable?: boolean;
  props?: TNotificationProps;
};

export type TNotificationsConfig = {
  id: string;
  props: TNotificationProps;
};
