/*
 * Компонент, который рендерит все контейнеры для UI-компонентов.
 */
import { FC } from 'react';
import { ErrorBoundary } from '@uikit/components/ErrorBoundary';
import { GlobalLoader } from '@uikit/components/Loader/components/GlobalLoader';
import { DialogsContainer } from '@uikit/components/containers/DialogsContainer';
import { ModalsContainer } from '@uikit/components/containers/ModalContainer';
import { NotificationsContainer } from '@uikit/components/containers/NotificationsContainer';
import { OverlaysContainer } from '@uikit/components/containers/OverlaysContainer';
import { PanelsContainer } from '@uikit/components/containers/PanelsContainer';
import {
  SystemNotificationsContainer
} from '@uikit/components/containers/SystemNotificationsContainer';

type THypetrainUIContainerProps = {
  className?: string;
};

export const HypetrainUIContainer: FC<THypetrainUIContainerProps> = ({ className }) => (
  <ErrorBoundary
    layerName="container"
    errorStubClassName={className}
  >
    <PanelsContainer />
    <DialogsContainer />
    <ModalsContainer />
    <NotificationsContainer />
    <SystemNotificationsContainer />
    <OverlaysContainer />
    <GlobalLoader />
  </ErrorBoundary>
);
