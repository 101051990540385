// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectAllRowsCheckboxButton__V_tzU{margin:unset;width:56px !important}.tableCheckbox__bVxRb{margin:unset}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Table/components/columns/SelectAllRowsCheckbox/SelectAllRowsCheckbox.module.scss"],"names":[],"mappings":"AAEA,oCACE,YAAA,CACA,qBAAA,CAGF,sBACE,YAAA","sourcesContent":["@import '../../../../../assets/styles/ui_kit_mixins';\n\n.selectAllRowsCheckboxButton {\n  margin: unset;\n  width: 56px !important;\n}\n\n.tableCheckbox {\n  margin: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectAllRowsCheckboxButton": "selectAllRowsCheckboxButton__V_tzU",
	"tableCheckbox": "tableCheckbox__bVxRb"
};
export default ___CSS_LOADER_EXPORT___;
