/**
 * Store of popup component.
 */
import { makeAutoObservable } from 'mobx';
import { PopupEvents, PopupPositions } from './popup.enum';

export class PopupStore {
  /*
   * Тип события, по которому popup открывается.
   */
  public popupEventType: PopupEvents = PopupEvents.Hover;

  /*
   * Признак необходимости отображения popup (только необходимости, не самого отображения).
   * Является частью двухэтапного отображения popup - needShow + isShow
   * Flow отображения popup:
   * 1. Popup отсутствует в DOM.
   * 2. Вызыется toggle или иной метод для его отображения.
   * 3. Выставляется needShow - popup размещается в DOM (визуально скрытно, не отображется).
   *    Это позволяет получить его размеры, что необходимо для его верного позиционирования.
   * 4. На изменение needShow опять тригерится toggle() в компоненте в useEffect.
   * 4. toggle() видя, что needShow теперь равен true, выставляет isShow = true. Popup отображается, вычисляются его размеры, на основе них он позиционируется.
   */
  public needShow = false;

  /*
   * Признак отображения popup.
   * Является частью двухэтапного отображения popup - needShow + isShow
   */
  public isShow = false;

  /*
   * Позиция на которой фактически отрисован popup (может отличаться от заданной, если popup не влез).
   */
  public renderedPosition: PopupPositions | null = null;

  /*
   * Признак того, что popup выключен и не должен отображаться.
   */
  public isDisabled = false;

  get isShowByClick(): boolean {
    return this.popupEventType === PopupEvents.Click;
  }

  get isShowByHover(): boolean {
    return this.popupEventType === PopupEvents.Hover;
  }

  constructor(
    public readonly offset: number,
    public readonly position: PopupPositions,
    public readonly showDuration: number | null,
    public readonly withExternalControl: boolean
  ) {
    makeAutoObservable(this);
  }

  public setEventType(eventType: PopupEvents): void {
    this.popupEventType = eventType;
  }

  public setVisibility(isShow: boolean): void {
    this.isShow = isShow;
  }

  public setNeedShow(needShow: boolean): void {
    this.needShow = needShow;
  }

  public setIsDisabled(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public setRenderedPosition(renderedPosition: PopupPositions | null): void {
    this.renderedPosition = renderedPosition;
  }
}

export const createPopupStore = (
  offset: number,
  position: PopupPositions,
  showDuration: number | null = null,
  withExternalControl = false
): PopupStore => new PopupStore(offset, position, showDuration, withExternalControl);
