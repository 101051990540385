/*
 * Компонент-посредник для отрисовки инстаграмной версии круговой диаграммы стран
 * */
import i18n from 'i18next';
import { FC } from 'react';
import { IInstagramSmaResponseDto, SmaAudienceCountryValue, SmaPlatform } from '@hypetrainCommon';
import { TAudience } from '@api/sma/sma.api.types';
import { AudienceLocation } from '@components/AudienceLocation/components/AudienceLocation.component';
import { getLimitAudienceData } from '@components/AudienceLocation/utils';

type TAudienceLocationInstagramProps = {
  influencer: IInstagramSmaResponseDto;
  isProfile?: boolean;
  selectedTab: string;
};

const textsTooltip: Record<string, string> = {
  followers: i18n.t('profile.audience.locationInstagramFollowersInfo'),
  likers: i18n.t('profile.audience.locationInstagramLikesInfo'),
};

export const AudienceLocationInstagram: FC<TAudienceLocationInstagramProps> = ({
  influencer,
  isProfile = false,
  selectedTab,
}) => {
  const subscribers = influencer?.metrics?.followersCount;
  const countryValues = getLimitAudienceData(
    influencer?.audience?.[selectedTab as keyof TAudience]?.countries?.values
  );

  return (
    <AudienceLocation
      platform={SmaPlatform.Instagram}
      countries={countryValues as SmaAudienceCountryValue[]}
      subscribers={subscribers}
      isProfile={isProfile}
      uuid={influencer?.uuid}
      info={textsTooltip[selectedTab]}
      selectedTab={selectedTab}
    />
  );
};
