import { FC, Fragment } from 'react';
import { MenuDefaultItem } from '@components/Background/components/NavigationPanel/components/MenuWrapper/components/MenuDefaultItem';
import styles from './MenuWrapper.module.scss';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type TMenuItemProps<T extends object = any> = { id: string } & T;

type TMenuWrapperProps = {
  header?: JSX.Element;
  footer?: JSX.Element;
  menuItems: TMenuItemProps[];
  menuItemComponent?: FC<TMenuItemProps>;
};

export const MenuWrapper: FC<TMenuWrapperProps> = ({
  header,
  footer,
  menuItems,
  menuItemComponent = MenuDefaultItem,
}) => {
  const MenuItem = menuItemComponent;

  return (
    <div className={styles.menuWrapper}>
      {header && <div className={styles.menuWrapper__header}>{header}</div>}
      <div className={styles.menuWrapper__menuItems}>
        {menuItems.map((item) => (
          <Fragment key={item?.id}>
            <div className={styles.menuWrapper__itemWrapper}>
              <div className={styles.menuWrapper__menuItem}>
                <MenuItem {...item} />
              </div>
            </div>
            <div className={styles.menuWrapper__divider} />
          </Fragment>
        ))}
      </div>
      {footer && <div className={styles.menuWrapper__footer}>{footer}</div>}
    </div>
  );
};
