/* Table component for med,min,max value on 2 rows */
import { FC, memo } from 'react';
import { EmptyBlock } from '@uikit/components/EmptyBlock';
import {
  FORMAT_NUMBER_NOTATION,
  FORMAT_NUMBER_STYLES,
  FormatNumber,
} from '@uikit/components/Formats';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { getUSDMetricView } from '@utils/metrics';
import {
  CIRCLE_TYPE,
  Circle,
} from '@components/CommonColumnsTable/MetricsInfoColumn/components/Circle';
import styles from './MetricsInfoColumn.module.scss';

type TMetricsInfoColumnProps = {
  med: number;
  max: number;
  min: number;
  metricsColors?: string[];
  numberStyles?: FORMAT_NUMBER_STYLES;
  isCurrency?: boolean;
};

export const MetricsInfoColumn: FC<TMetricsInfoColumnProps> = memo(
  ({ med, max, min, metricsColors = [0, 0, 0], numberStyles, isCurrency }) => {
    if (!med) {
      return <EmptyBlock />;
    }

    return (
      <div className={styles.metricsInfoColumn}>
        <div className={styles.metricsInfoColumn__numberBlock}>
          <Circle
            color={metricsColors?.[1]}
            type={CIRCLE_TYPE.MAX}
          />
          <div>
            {isCurrency ? (
              <div className={styles.metricsInfoColumn__mainNumber}>
                {getUSDMetricView(med, { minimumFractionDigits: 2 })}
              </div>
            ) : (
              <FormatNumber
                numberStyles={numberStyles}
                notation={FORMAT_NUMBER_NOTATION.COMPACT}
                className={styles.metricsInfoColumn__mainNumber}
              >
                {med}
              </FormatNumber>
            )}
          </div>
        </div>
        {max && min && (
          <div className={styles.metricsInfoColumn__additionalNumberContainer}>
            <div className={styles.metricsInfoColumn__numberBlock}>
              <Circle
                color={metricsColors?.[0]}
                type={CIRCLE_TYPE.MIN}
                className={styles.metricsInfoColumn__smallCircle}
              />
              {isCurrency ? (
                <div className={styles.metricsInfoColumn__additionalNumber}>
                  {getUSDMetricView(min, { minimumFractionDigits: 2 })}
                </div>
              ) : (
                <FormatNumber
                  numberStyles={numberStyles}
                  notation={FORMAT_NUMBER_NOTATION.COMPACT}
                  className={styles.metricsInfoColumn__additionalNumber}
                >
                  {min}
                </FormatNumber>
              )}
            </div>
            <Icon
              type={ICON_TYPES.DOUBLE_ARROWS}
              size={10}
              className={styles.metricsInfoColumn__arrows}
            />
            <div className={styles.metricsInfoColumn__numberBlock}>
              {isCurrency ? (
                <div className={styles.metricsInfoColumn__additionalNumber}>
                  {getUSDMetricView(max, { minimumFractionDigits: 2 })}
                </div>
              ) : (
                <FormatNumber
                  numberStyles={numberStyles}
                  notation={FORMAT_NUMBER_NOTATION.COMPACT}
                  className={styles.metricsInfoColumn__additionalNumber}
                >
                  {max}
                </FormatNumber>
              )}
              <Circle
                color={metricsColors?.[2]}
                type={CIRCLE_TYPE.MIN}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);
