// TODO: Разгрести это. Смесь страшного legacy и стилей для актуальный макетов.
import { StylesConfig } from 'react-select';
import { CSS_Z_INDEX_DROPDOWN } from '@uikit/helpers/styles.helpers';

export const getSelectFieldStyles = (hasError = false): StylesConfig => ({
  container: (provided) => ({
    ...provided,
    width: '100%',
    minHeight: '40px',
    position: 'relative',

    '&:focus': {
      border: '1px solid #e4e4e4',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px',
  }),
  input: (provided) => ({
    ...provided,
    paddingTop: 10,
    margin: 'unset',
    minHeight: '40px',
  }),
  control: (provided, controlState) => ({
    ...provided,
    position: 'relative',
    paddingLeft: 8,
    // eslint-disable-next-line no-nested-ternary
    border: hasError
      ? '1px solid #D64141 !important'
      : controlState.isFocused
      ? '1px solid #0386FF !important'
      : '1px solid #BDC4E5',
    borderRadius: 4,
    // height: '36px',
    minHeight: '40px',
    fontSize: 13,
    fontWeight: 500,
    fontFamily: 'WorkSans',
    boxShadow: 'unset',
    cursor: controlState.isDisabled ? 'default' : 'pointer',

    '&:hover': {
      border: '1px solid #BDC4E5',
    },

    '&:focus': {
      border: '1px solid #0386FF',
    },
  }),

  menu: () => ({}),

  menuPortal: (provided) => ({ ...provided, zIndex: CSS_Z_INDEX_DROPDOWN }),

  indicatorSeparator: () => ({
    display: 'none',
  }),
});
