import { FC } from 'react';
import { IGetUserMessagesResponseDto } from '@hypetrainCommon';
import { AVATAR_SIZES } from '@uikit/components/Avatar';
import { getUsersListFromRecipients } from '@pagesCampaigns/CampaignDealPage/components/DealPageCommunication/components/ChatMessage/chatMessage.utils';
import { UsersList } from '@components/UsersList';
import { USERS_LIST_COUNTER_MODE } from '@components/UsersList/components/UsersListCounter/UsersListCounter.component';
import { TUsersListItem } from '@components/UsersList/usersList.types';

type TRecipientsList = {
  message: IGetUserMessagesResponseDto;
  className?: string;
};
export const RecipientsList: FC<TRecipientsList> = ({ message, className }) => {
  const recipients: TUsersListItem[] = getUsersListFromRecipients(message.recipients);

  return (
    <UsersList
      className={className}
      users={recipients}
      total={recipients?.length}
      counterMode={USERS_LIST_COUNTER_MODE.SHOW_OTHER}
      maxShowCount={7}
      avatarSize={AVATAR_SIZES.XS}
    />
  );
};
