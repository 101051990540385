/*
 * Buttons for group actions.
 */
import { FC } from 'react';
import { BUTTON_SIZE, Button } from '@uikit/components/Buttons';
import { TGroupAction } from '@uikit/components/GroupActions';
import { PopupPositions } from '@uikit/components/Popup';
import { SUPPORT_SHOW_LINES, TextEllipsis } from '@uikit/components/TextEllipsis';
import { Tip } from '@uikit/components/Tip';
import { Tooltip } from '@uikit/components/Tooltip';
import style from './GroupActionsButtonList.module.scss';

type TGroupActionsButtonListProps = {
  actions: TGroupAction[];
  tip?: string;
};

export const GroupActionsButtonList: FC<TGroupActionsButtonListProps> = ({ actions, tip }) => (
  <div className={style.groupActionsButtonList}>
    {tip && (
      <Tip className={style.groupActionsButtonList__tip}>
        <TextEllipsis lineCount={SUPPORT_SHOW_LINES.TWO}>{tip}</TextEllipsis>
      </Tip>
    )}

    <div className={style.groupActionsButtonList__list}>
      {actions
        .filter((action) => !action.isHide)
        .map((action) => (
          <Tooltip
            key={`${action?.handler?.name}_${action.name}_${action.button.icon}_${action.tooltip}`}
            className={style.groupActionsButtonList__item}
            content={<span>{action.tooltip}</span>}
            position={PopupPositions.TopCenter}
            disable={!action.tooltip}
            offset={12}
          >
            <Button
              className={style.groupActionsButtonList__button}
              size={BUTTON_SIZE.M}
              {...action.button}
              onClick={action.handler}
            >
              {action.name}
            </Button>
          </Tooltip>
        ))}
    </div>
  </div>
);
