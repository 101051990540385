/*
 * Сервис инициализации приложения.
 * @see: Doc: https://bloggerslab.fibery.io/Wiki/folder/Development-5/New-document-1895
 */
import { ApiService } from '@api';
import { datadogLogs } from '@datadog/browser-logs';
import {
  ICredentialsResponseDto,
  IGetUserProfileResponseDto,
  IGoogleAuthResponseDto,
  IPersonWorkspaceResponseDto,
  ISignInRequestDto,
  ISubscriptionSimpleInfoDto,
} from '@hypetrainCommon';
import { WebSocketService } from '@api/webSocket';
import {
  APP_AUTH_TYPES,
  INIT_BY_LOGIN_HANDLERS_MAP,
  TLoginAcademyParams,
} from '@services/initApp/initApp.constants';
import { logGoogleAuthInitiated } from '@services/initApp/initApp.userLog';
import { SubscriptionNotificationsService } from '@services/subscriptionNotifications/subscriptionNotifications.service';
import { SubscriptionUserAccessService, UserAccessService } from '@services/userAccess';
import { usersActionsLogService } from '@services/usersActionsLog';
import { setAccessToken, willRedirectOnWorkspace } from '@utils/auth.utils';
import { APP_VERSION, currentSubdomain } from '@constants/common';
import { setActiveWorkspace } from '@models/activeWorkspace';
import { TInitAppData, getInitAppDataFx } from '@models/appApi.model';
import { loginUserAcademy, logoutUserFx } from '@models/auth.model';
import { setUserProfile } from '@models/profile';
import {
  getSubscriptionModeFx,
  getTaxAmountsFx,
  openCustomerPortalFx,
  resumeSubscriptionFx,
} from '@models/subscription.model';
import { TWorkspaceMap } from '@models/workspaces/workspaces.model';
import { getPersonalInboxConnectionInfoFx } from '@pagesCampaigns/PersonalInbox/personalInbox.model';

/* eslint-disable class-methods-use-this */
export class InitAppService {
  private initAppData: TInitAppData | null = null;

  private activeWorkspace: IPersonWorkspaceResponseDto | null = null;

  private accessToken = '';

  get workspaces(): TWorkspaceMap | null {
    return this.initAppData?.workspaces || null;
  }

  get profile(): IGetUserProfileResponseDto | null {
    return this.initAppData?.user || null;
  }

  get subscription(): ISubscriptionSimpleInfoDto | null {
    return this.activeWorkspace?.subscription || null;
  }

  constructor(
    private readonly apiService: ApiService,
    private readonly webSocketService: WebSocketService,
    private readonly userAccessService: UserAccessService,
    private readonly subscriptionUserAccessService: SubscriptionUserAccessService,
    private readonly subscriptionNotificationsService: SubscriptionNotificationsService
  ) {
    this.initErrorLogging();
  }

  /*
   * Инициализация приложения при логине пользователя.
   */
  public initByLogin = async (
    credentials: ISignInRequestDto | string,
    type: APP_AUTH_TYPES,
    onLogin: (loginResponse: ICredentialsResponseDto | IGoogleAuthResponseDto) => void
  ) => {
    const loginFn = INIT_BY_LOGIN_HANDLERS_MAP[type];
    const response = await loginFn({
      credentials,
      onLogin,
    });
    logGoogleAuthInitiated(type);
    // Выполняем дальнейшую инициализацию только если
    // 1) у пользователя нету воркспейса - он идёт дальше на /create-workspace.
    // 2) пользователя с воркспейсом приглашают в другой воркспейс - он идет на /join
    // В противном случае уже запущен переход на его workspace (redirectToApp).
    if (willRedirectOnWorkspace(response)) return;

    this.setSecrects(response?.accessToken);
  };

  /*
   * Инициализация приложения при логине пользователя для академении
   */
  public initByLoginAcademy = async (
    credentials: TLoginAcademyParams,
    onLogin: (loginResponse: ICredentialsResponseDto) => void
  ) => {
    const response = await loginUserAcademy({
      credentials,
      onLogin,
    });
    // Выполняем дальнейшую инициализацию только если
    // 1) у пользователя нету воркспейса - он идёт дальше на /create-workspace.
    // 2) пользователя с воркспейсом приглашают в другой воркспейс - он идет на /join
    // В противном случае уже запущен переход на его workspace (redirectToApp).
    if (willRedirectOnWorkspace(response)) return;

    this.setSecrects(response?.accessToken);
  };

  /*
   * Инициализация приложения по открытию пользователем произвольной страницы приложения.
   */
  public defaultInit = async (accessToken: string): Promise<void> => {
    try {
      this.setSecrects(accessToken);

      this.initAppData = await getInitAppDataFx();

      this.activeWorkspace = this.workspaces?.active.find(
        (space) => space?.workspace?.handler === currentSubdomain
      ) as IPersonWorkspaceResponseDto;
    } catch (e) {
      logoutUserFx();
    }

    this.startApp();
  };

  /*
   * Установка секретов/токенов/ключей в приложении.
   */
  public setSecrects = (accessToken: string): void => {
    this.accessToken = accessToken;
    setAccessToken(accessToken);
  };

  /*
   * Старт приложения (инициализация необходимых для его работы сущностей).
   */
  private startApp(): void {
    this.initUserRights();
    this.initWebSocket();
    this.initWorkspace();
    this.initUserProfile();
    this.initSubscription();
    this.initUserLogging();
    this.getPersonalInboxConnection();
  }

  /*
   * Инициализация прав пользователя.
   */
  private initUserRights = (): void => {
    if (!this.activeWorkspace?.person?.rights) return;

    this.userAccessService.initUserRights(this.activeWorkspace.person.rights);
  };

  /*
   * Инициализация воркспейсов.
   */
  private initWorkspace = (): void => {
    if (!this.activeWorkspace) return;

    this.apiService.setActiveWorkspace(this.activeWorkspace.workspace);
    setActiveWorkspace(this.activeWorkspace);
  };

  /*
   * Инициализация профиля пользователя.
   */
  private initUserProfile = (): void => {
    if (!this.initAppData?.user) return;

    setUserProfile(this.initAppData.user);
  };

  /*
   * Инициализация подписки.
   */
  private initSubscription(): void {
    if (!this.activeWorkspace || !this.subscription) return;

    if (this.subscriptionUserAccessService.getFullSubscriptionInfo) {
      getTaxAmountsFx(false);
    }

    getSubscriptionModeFx();
    this.subscriptionNotificationsService.init(this.activeWorkspace, this.subscription, {
      resumeSubscription: resumeSubscriptionFx,
      openCustomerPortal: openCustomerPortalFx,
    });
  }

  /*
   * Инициализация логирования пользовательских действий.
   */
  private initUserLogging(): void {
    if (!this.profile) return;

    usersActionsLogService.initUser(this.profile);
  }

  /*
   * Инициализация логирования ошибок.
   */
  private initErrorLogging(): void {
    if (process.env.ENVIRONMENT !== 'localhost') {
      datadogLogs.init({
        clientToken: process.env.DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        telemetrySampleRate: 0,
        service: 'hypetrain-frontend',
        version: APP_VERSION, // TODO Антон: semantic-release
        env: process.env.ENVIRONMENT,
        beforeSend: (log): boolean => {
          // Доработать по мере роста колличества условий.
          if (log.message === 'ResizeObserver loop limit exceeded') {
            return false;
          }
          return true;
        },
      });
    }
  }

  /*
   * Инициализация WebSocket-соединения.
   */
  private initWebSocket(): void {
    if (!this.activeWorkspace) return;

    this.webSocketService.init(
      this.accessToken,
      this.activeWorkspace.person?.id,
      this.activeWorkspace.workspace?.id
    );
  }

  /*
   * Инициализация подключения почтового ящика пользователя в приложении.
   */
  private getPersonalInboxConnection(): void {
    if (!this.activeWorkspace) return;

    getPersonalInboxConnectionInfoFx();
  }
}

export const createInitAppService = (
  apiService: ApiService,
  webSocketService: WebSocketService,
  userAccessService: UserAccessService,
  subscriptionUserAccessService: SubscriptionUserAccessService,
  subscriptionNotificationsService: SubscriptionNotificationsService
) =>
  new InitAppService(
    apiService,
    webSocketService,
    userAccessService,
    subscriptionUserAccessService,
    subscriptionNotificationsService
  );
