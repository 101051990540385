/*
 * Add to existing campaign dialog.
 */
import { useStore } from 'effector-react/compat';
import { FormApi } from 'final-form';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  ICampaignListItemDto,
  ICreateCampaignDto,
  IYoutubeSmaStatisticsResponseDto,
  SmaPlatform,
} from '@hypetrainCommon';
import { Dialog, TDialogProps } from '@uikit/components/Dialog';
import { TITLES_BY_SOCIAL_MEDIA } from '@uikit/helpers/social.helpers';
import { Routes } from '@router/Routes';
import { USERS_ACTIONS, usersActionsLogService } from '@services/usersActionsLog';
import { $activeSocialPlatform } from '@models/activeSocialPlatform';
import { createCampaignDealsFx } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.model';
import { CampaignsSearch } from '@pagesCampaigns/CampaignDetailsPage/components/AddToExistingCampaignDialog/components/CampaignsSearch';
import { createCampaignFx } from '@pagesCampaigns/CampaignsPage/campaignsPage.model';
import { CAMPAIGN_CREATION_PATH } from '@pagesCampaigns/CampaignsPage/components/CreateCampaignDialog/CreateCampaignDialog.component';
import styles from '@pagesCampaigns/CampaignsPage/components/CreateCampaignDialog/CreateCampaignDialog.module.scss';
import { CreateCampaignForm } from '@pagesCampaigns/CampaignsPage/components/CreateCampaignForm';
import { isCreate } from '@components/BlurScreen/BlurScreen.constants';

export type TAddToExistingCampaignDialogProps = {
  influencers: IYoutubeSmaStatisticsResponseDto[];
  campaigns: ICampaignListItemDto[];
};

export const AddToExistingCampaignDialog: FC<
  TDialogProps<TAddToExistingCampaignDialogProps, string>
> = ({ dialog, campaigns, influencers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showCreateCampaignForm, setShowCreateCampaignForm] = useState(false);
  const [form, setForm] = useState<FormApi<ICreateCampaignDto>>();
  const activeSocialPlatform = useStore($activeSocialPlatform);
  const [selectedCampaign, setSelectedCampaign] = useState<ICampaignListItemDto>();
  const [filteredCampaigns, setFilteredCampaigns] = useState<ICampaignListItemDto[]>([]);
  const activePlatform = useStore($activeSocialPlatform);

  const currentPlatformCampaigns = useMemo(
    () => campaigns?.filter((campaign) => campaign?.platform === activeSocialPlatform?.platform),
    [campaigns]
  );

  const createCampaign = (): void => {
    if (!form) return;

    const formState = form.getState();
    const campaignInfo = formState.values;

    form.submit();

    if (!formState.valid) return;

    createCampaignFx(campaignInfo).then((info) => {
      usersActionsLogService.log(USERS_ACTIONS.CAMPAIGN_CREATED, {
        campaignId: info?.id,
        platform: info?.platform,
        origin: CAMPAIGN_CREATION_PATH.SELECTION,
      });
      dialog.resolve();
      const path = generatePath(Routes.campaign, {
        id: info?.id,
      });

      navigate({ pathname: path, search: `?${isCreate}=true` });
    });
  };

  const onSubmit = (): void => {
    if (!selectedCampaign) return;

    const smas = influencers.map((influencer) => influencer.sma.uuid);

    createCampaignDealsFx({
      campaignId: selectedCampaign.id,
      body: { smas },
    }).then(() => {
      dialog.resolve(selectedCampaign?.id);
      const path = generatePath(Routes.campaign, { id: selectedCampaign.id });
      navigate(path);
    });
  };

  const getTitle = (): string =>
    showCreateCampaignForm
      ? t('createOrEditCampaign.createCampaignWithoutSelectionTitle', {
          platform: TITLES_BY_SOCIAL_MEDIA[activePlatform?.platform ?? SmaPlatform.Youtube],
        })
      : t('addToExistingCampaignDialog.dialogTitle', {
          platform: TITLES_BY_SOCIAL_MEDIA[activeSocialPlatform?.platform ?? SmaPlatform.Youtube],
        });

  const buttonSubmit = showCreateCampaignForm
    ? {
        text: t('createCampaign'),
        handler: createCampaign,
      }
    : {
        text: t('addToCampaign'),
        handler: onSubmit,
        disabled: !selectedCampaign,
      };

  const onCreateNewCampaign = () => {
    setShowCreateCampaignForm(true);
    setFilteredCampaigns(currentPlatformCampaigns);
  };

  const backButton = {
    handler: () => setShowCreateCampaignForm(false),
    className: styles.createCampaignDialog__backButton,
    text: getTitle(),
  };

  return (
    <Dialog
      title={getTitle()}
      width="540px"
      hideButtons={!filteredCampaigns.length}
      buttons={[buttonSubmit]}
      backButton={showCreateCampaignForm ? backButton : undefined}
    >
      {showCreateCampaignForm ? (
        <div className={styles.createCampaignDialog}>
          <CreateCampaignForm
            /* @ts-ignore TODO Андрей */
            onChangeForm={setForm}
          />
        </div>
      ) : (
        <CampaignsSearch
          campaigns={currentPlatformCampaigns}
          selected={selectedCampaign}
          onSelect={setSelectedCampaign}
          onChangeCampaignsList={setFilteredCampaigns}
          onCreateNewCampaign={onCreateNewCampaign}
        />
      )}
    </Dialog>
  );
};
