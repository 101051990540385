import cn from 'clsx';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tutorialVideo from '@assets/images/auth/videoTour.svg';
import { ModalVideo } from '@components/ModalVideo';
import styles from './AppQuickOverview.module.scss';

const tutorialVideoId = 'HOCDogtC6eg';

type TAppQuickOverview = {
  className?: string;
};

export const AppQuickOverview: FC<TAppQuickOverview> = ({ className }) => {
  const { t } = useTranslation();
  const [videoModalState, setVideoModalState] = useState(false);

  return (
    <>
      <ModalVideo
        isOpen={videoModalState}
        videoId={tutorialVideoId}
        onClose={() => setVideoModalState(false)}
      />
      <div className={cn(styles.appQuickOverview, className)}>
        <img
          src={tutorialVideo}
          alt="tutorial"
          onClick={() => setVideoModalState(true)}
        />
        <div className={styles.appQuickOverview__videoText}>{t('startTrial.videoText')}</div>
      </div>
    </>
  );
};
