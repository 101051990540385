export enum INPUT_TYPE {
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
}

export enum REQUEST_STATUS {
  PENDING = 'PENDING',
  DONE = 'DONE',
}
