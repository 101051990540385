// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".assigneeSelectValue__eSFJl{display:flex;align-items:center}.assigneeSelectValue__avatar__lu52I{margin-right:var(--vSpacing12)}", "",{"version":3,"sources":["webpack://./pages/Campaigns/CampaignsPage/components/AssigneeSelectValue/AssigneeSelectValue.module.scss"],"names":[],"mappings":"AAEA,4BACE,YAAA,CACA,kBAAA,CAEA,oCACE,8BAAA","sourcesContent":["@import '../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.assigneeSelectValue {\n  display: flex;\n  align-items: center;\n\n  &__avatar {\n    margin-right: var(--vSpacing12);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assigneeSelectValue": "assigneeSelectValue__eSFJl",
	"assigneeSelectValue__avatar": "assigneeSelectValue__avatar__lu52I"
};
export default ___CSS_LOADER_EXPORT___;
