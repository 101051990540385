/*
 * Хук детектирования авторизации пользователя в приложении.
 * @see: Doc: https://bloggerslab.fibery.io/Wiki/folder/Development-5/New-document-1895
 */
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { ICredentialsResponseDto } from '@hypetrainCommon';
import { Routes } from '@router/Routes';
import { InitAppService } from '@services/initApp/initApp.service';
import { isRouteWithIgnoreAuthorizationErrors } from '@utils/auth.utils';
import { redirectToApp, redirectToLoginPage } from '@utils/redirect.utils';
import { isDefaultSubdomain } from '@utils/workspace.utils';
import { logoutUserFx, updateAuthTokenFx } from '@models/auth.model';

type TUseAuthorize = (initAppService: InitAppService) => boolean;

/*
 * Принцип работы:
 *
 *  -------------------------------------------------------------------------------
 * |         User is authorized           |       User is not authorized         |
 * | -------------------------------------|--------------------------------------|
 * | Default workspace |  Work workspace  | Default workspace |  Work workspace  |
 * |-------------------|------------------|-------------------|------------------|
 * |     Redirect to   |  Start init app  |  Move to routing  |  Move to Login   |
 * |   work subdomain  |                  |                   |       page       |
 * -------------------------------------------------------------------------------
 */
export const useAuthorize: TUseAuthorize = (initAppService: InitAppService) => {
  const [isAuthCheked, setIsAuthCheked] = useState(false);

  const checkAuth = async () => {
    try {
      const updatedTokens = await updateAuthTokenFx().catch((e: AxiosError) => {
        if (e?.response?.status && e.response.status < 500) {
          return Promise.resolve({} as ICredentialsResponseDto);
        }
        return Promise.reject(e);
      });
      const isAuthorized = Boolean(updatedTokens?.accessToken);

      /*
       * Пользовательский subdoamin + пользователь не авторизован - отправляем на логин.
       */
      if (!isDefaultSubdomain() && !isAuthorized) {
        redirectToLoginPage();
        return;
      }

      /*
       * Пользовательский subdoamin + пользователь авторизован - запускаем инициализацию приложения, по окончании пускаем на роутинг.
       */
      if (!isDefaultSubdomain() && isAuthorized) {
        await initAppService.defaultInit(updatedTokens?.accessToken);
        setIsAuthCheked(true);
        return;
      }

      /*
       * app.hypetrain.io + пользователь не авторизован - пускаем на роутинг.
       */
      if (isDefaultSubdomain() && !isAuthorized) {
        setIsAuthCheked(true);
        return;
      }

      /*
       * app.hypetrain.io + пользователь авторизован:
       * 1. Это роут в котором требуется авторизация - пускаем на роутигинг.
       * 2. Авторизация не требуется (например login page) - редиректим на рабочий subdomain.
       */
      if (isDefaultSubdomain() && isAuthorized) {
        const isAuthRequired = isRouteWithIgnoreAuthorizationErrors();

        if (isAuthRequired) {
          setIsAuthCheked(true);
          return;
        }
        updatedTokens.workspace?.handler
          ? redirectToApp(updatedTokens.workspace?.handler || '', Routes.discovery)
          : logoutUserFx();
      }
    } catch (e) {
      redirectToLoginPage();
      setIsAuthCheked(true); // перестраховка
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return isAuthCheked;
};
