/*
 * коды ошибок смены пароля
 */
export const CHANGE_PASSWORD_ERRORS = [
  'change-password--wrong-credentials-provided',
  'input-validation-error',
];

export const LOGIN_ERROR_CODES = ['sign-in--wrong-credentials-provided'];

export const LOGIN_EMAIL_ERROR_CODES = [
  'user-mailing--email-is-not-valid',
  'sign-in--email-is-not-verified',
];

export const EMAIL_NOT_FOUND = 'get-user--email-not-found';
