/*
 * Component of panel with actions for a group of entities (show action buttons and entity metrics).
 */
import cn from 'clsx';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Budge } from '@uikit/components/Budge';
import { Portal } from '@uikit/components/Portal';
import { deleteAllPersonsFromSelectionFx } from '@models/selection.model';
import { GroupActionMetricList } from './component/GroupActionMetricList';
import { GroupActionsButtonList } from './component/GroupActionsButtonList';
import styles from './GroupActions.module.scss';
import { TGroupAction, TGroupActionsMetric } from './GroupActions.types';
import { setIsGroupActionsVisible } from './groupActions.model';

type TGroupActionsProps = {
  title?: string; // title on top
  metrics?: TGroupActionsMetric[]; // metrilcs list on the left
  metricsTip?: string; // tip for metrics list
  actions: TGroupAction[]; // metrilcs list on the right
  actionsTip?: string; // tip for actions list
  isDiscovery?: boolean;
};

export const GroupActions: FC<TGroupActionsProps> = ({
  title,
  metrics,
  metricsTip,
  actions,
  actionsTip,
  isDiscovery = false,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setIsGroupActionsVisible(true);
    return () => {
      setIsGroupActionsVisible(false);
    };
  }, []);

  return (
    <Portal>
      <div className={styles.groupActions}>
        <div
          className={cn(
            styles.groupActions__container,
            !metrics?.length && styles.groupActions__container_alignRight
          )}
        >
          {title && (
            <Budge className={styles.groupActions__title}>
              <div>{title}</div>
              {/* // TODO Женя: убрать убрать упоминание о isDiscovery */}
              {isDiscovery && (
                <div
                  onClick={() => deleteAllPersonsFromSelectionFx()}
                  className={styles.groupActions__clearAll}
                >
                  {t('summaryGroupAction.clearAllSelected')}
                </div>
              )}
            </Budge>
          )}
          {metrics?.length && (
            <GroupActionMetricList
              className={styles.groupActions__metrics}
              metrics={metrics}
              tip={metricsTip}
            />
          )}
          <GroupActionsButtonList
            actions={actions}
            tip={actionsTip}
          />
        </div>
      </div>
    </Portal>
  );
};
