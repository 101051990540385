import { ContractingStages } from 'components/ContractingStages';
import { SmaPlatform } from '@hypetrainCommon';
import { DefaultCell, TableHeaderCell } from '@uikit/components/Table';
import { TColumn } from '@uikit/components/Table/table.types';
import i18n from '@services/i18n';
import { ICampaignDealListItemCustom } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.types';
import { getIsContact } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.utils';
import { SelectCampaignAllCheckbox } from '@pagesCampaigns/CampaignDetailsPage/components/SelectCampaignAllCheckbox';
import { ChannelInfoColumn } from '@components/CommonColumnsTable/ChannelInfoColumn';
import { TChannelInfoColumnData } from '@components/CommonColumnsTable/ChannelInfoColumn/ChannelInfoColumn.component';
import { SelectCampaignCheckbox } from '../SelectCampaignCheckbox';
import styles from './CampaignStages.module.scss';

export const getStagesColumns = (platform: SmaPlatform): TColumn<ICampaignDealListItemCustom>[] => [
  {
    width: 40,
    canResize: false,
    Header: <SelectCampaignAllCheckbox />,
    accessor: 'selection',
    Cell: ({ row }) => (
      <>
        <SelectCampaignCheckbox
          campaignId={row?.original?.id}
          className={styles.tableCheckbox}
        />
        <div className={styles.numbering}>{Number(row.id) + 1}</div>
      </>
    ),
  },
  {
    width: 150,
    Header: (
      <DefaultCell>
        <TableHeaderCell text={i18n.t(`smaTable.${platform}.info`)} />
      </DefaultCell>
    ),
    accessor: 'channelInfo',
    Cell: ({ row }) => (
      <ChannelInfoColumn
        className={styles.channelInfoCampaign}
        info={row?.original?.smaStatistics?.sma || ({} as TChannelInfoColumnData)}
        highlightedString={row?.original?.highlightedString}
        dealId={row?.original?.id}
        unreadMessageCount={row?.original?.unreadCount}
        isContact={getIsContact(row?.original)}
      />
    ),
  },
  {
    minWidth: 450,
    accessor: 'stages',
    Cell: ({ row }) => (
      <DefaultCell>
        <ContractingStages
          listStage={row?.original?.stages}
          showHeader={false}
          turnOffAnimation
        />
      </DefaultCell>
    ),
  },
];
