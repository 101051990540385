import {
  CampaignDealContractingStageStateName,
  CampaignDealDraftingStageStateName,
  CampaignDealPaymentStageStateName,
  CampaignDealPublishingStageStateName,
  CampaignDealRecruitmentStageStateName,
  CampaignDealStageName,
  CampaignDealStageStateName,
} from '@hypetrainCommon';

enum INDICATOR_CLASS_NAME {
  INITIAL = 'initial',
  DECLINED = 'declined',
  REPLIED = 'replied',
  REACHED_OUT = 'reachedOut',
  AGREED = 'agreed',
  FILLING = 'filling',
  REVIEW = 'review',
  SIGNING = 'signing',
  SIGNED = 'signed',
  SKIPPED = 'skipped',
}

const CONTRACTING_INDICATOR_CLASS_NAME: Record<
  CampaignDealContractingStageStateName,
  INDICATOR_CLASS_NAME
> = {
  [CampaignDealContractingStageStateName.Filling]: INDICATOR_CLASS_NAME.FILLING,
  [CampaignDealContractingStageStateName.Review]: INDICATOR_CLASS_NAME.REVIEW,
  [CampaignDealContractingStageStateName.Signing]: INDICATOR_CLASS_NAME.SIGNING,
  [CampaignDealContractingStageStateName.Signed]: INDICATOR_CLASS_NAME.SIGNED,
  [CampaignDealContractingStageStateName.Skipped]: INDICATOR_CLASS_NAME.SKIPPED,
  [CampaignDealContractingStageStateName.Initial]: INDICATOR_CLASS_NAME.INITIAL,
  [CampaignDealContractingStageStateName.Declined]: INDICATOR_CLASS_NAME.DECLINED,
};

const DRAFTING_INDICATOR_CLASS_NAME: Record<
  CampaignDealDraftingStageStateName,
  INDICATOR_CLASS_NAME
> = {
  [CampaignDealDraftingStageStateName.Initial]: INDICATOR_CLASS_NAME.REPLIED,
  [CampaignDealDraftingStageStateName.Skipped]: INDICATOR_CLASS_NAME.SKIPPED,
  [CampaignDealDraftingStageStateName.Declined]: INDICATOR_CLASS_NAME.DECLINED,
  [CampaignDealDraftingStageStateName.Approved]: INDICATOR_CLASS_NAME.AGREED,
};

const PUBLISHING_INDICATOR_CLASS_NAME: Record<
  CampaignDealPublishingStageStateName,
  INDICATOR_CLASS_NAME
> = {
  [CampaignDealPublishingStageStateName.Initial]: INDICATOR_CLASS_NAME.REVIEW,
  [CampaignDealPublishingStageStateName.Review]: INDICATOR_CLASS_NAME.REPLIED,
  [CampaignDealPublishingStageStateName.Approved]: INDICATOR_CLASS_NAME.AGREED,
  [CampaignDealPublishingStageStateName.Declined]: INDICATOR_CLASS_NAME.DECLINED,
  [CampaignDealPublishingStageStateName.Skipped]: INDICATOR_CLASS_NAME.SKIPPED,
};

const RECRUITMENT_INDICATOR_CLASS_NAME: Record<
  CampaignDealRecruitmentStageStateName,
  INDICATOR_CLASS_NAME
> = {
  [CampaignDealRecruitmentStageStateName.Initial]: INDICATOR_CLASS_NAME.INITIAL,
  [CampaignDealRecruitmentStageStateName.Agreed]: INDICATOR_CLASS_NAME.AGREED,
  [CampaignDealRecruitmentStageStateName.ReachedOut]: INDICATOR_CLASS_NAME.REACHED_OUT,
  [CampaignDealRecruitmentStageStateName.Replied]: INDICATOR_CLASS_NAME.REPLIED,
  [CampaignDealRecruitmentStageStateName.Declined]: INDICATOR_CLASS_NAME.DECLINED,
};

// Никита переделать когда будут пэйменты
const PAYMENT_INDICATOR_CLASS_NAME: Record<
  CampaignDealPaymentStageStateName,
  INDICATOR_CLASS_NAME
> = {
  [CampaignDealPaymentStageStateName.Paid]: INDICATOR_CLASS_NAME.AGREED,
  [CampaignDealPaymentStageStateName.WaitingForPayment]: INDICATOR_CLASS_NAME.REPLIED,
  [CampaignDealPaymentStageStateName.Skipped]: INDICATOR_CLASS_NAME.SKIPPED,
  [CampaignDealPaymentStageStateName.Initial]: INDICATOR_CLASS_NAME.REACHED_OUT,
  [CampaignDealPaymentStageStateName.Declined]: INDICATOR_CLASS_NAME.DECLINED,
};

export const getStatusIndicatorClassName = (
  stage: CampaignDealStageName,
  state: CampaignDealStageStateName
) => {
  switch (stage) {
    case CampaignDealStageName.Contracting:
      return CONTRACTING_INDICATOR_CLASS_NAME[state as CampaignDealContractingStageStateName];
    case CampaignDealStageName.Payment:
      return PAYMENT_INDICATOR_CLASS_NAME[state as CampaignDealPaymentStageStateName];
    case CampaignDealStageName.Drafting:
      return DRAFTING_INDICATOR_CLASS_NAME[state as CampaignDealDraftingStageStateName];
    case CampaignDealStageName.Publishing:
      return PUBLISHING_INDICATOR_CLASS_NAME[state as CampaignDealPublishingStageStateName];
    case CampaignDealStageName.Recruitment:
      return RECRUITMENT_INDICATOR_CLASS_NAME[state as CampaignDealRecruitmentStageStateName];
    default:
      return RECRUITMENT_INDICATOR_CLASS_NAME[state as CampaignDealRecruitmentStageStateName];
  }
};
