/*
 * Утилиты для работы с числами.
 */

/*
 * Возвращает разницу между двумя числами в процентах.
 */
export function getDiffInPercents(x: number, y: number): number {
  if (x === y) return 0;
  if (y === 0) return -100;
  return ((x - y) / y) * 100;
}

/*
 * Возвращает количество цифр в числе
 */
export const getLengthOfNumber = (value: number): number => value?.toString()?.length;
