// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chipsContainer__PA1v3{max-width:100%;display:flex;flex-wrap:wrap;margin-top:var(--hSpacing4)}.chipsContainer__chip__QGdZQ{max-width:100%;margin:var(--hSpacing4) var(--vSpacing8) var(--hSpacing4) 0}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Keywords/Keywords.module.scss"],"names":[],"mappings":"AAEA,uBACE,cAAA,CACA,YAAA,CACA,cAAA,CACA,2BAAA,CAEA,6BACE,cAAA,CACA,2DAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.chipsContainer {\n  max-width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: var(--hSpacing4);\n\n  &__chip {\n    max-width: 100%;\n    margin: var(--hSpacing4) var(--vSpacing8) var(--hSpacing4) 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chipsContainer": "chipsContainer__PA1v3",
	"chipsContainer__chip": "chipsContainer__chip__QGdZQ"
};
export default ___CSS_LOADER_EXPORT___;
