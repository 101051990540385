// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".email__Q7Ksw{padding:var(--vSpacing16) var(--hSpacing16);color:var(--textSecondary)}", "",{"version":3,"sources":["webpack://./components/Background/components/NavigationPanel/components/ProfileMenu/ProfileMenu.module.scss"],"names":[],"mappings":"AAEA,cACE,2CAAA,CACA,0BAAA","sourcesContent":["@import '../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.email {\n  padding: var(--vSpacing16) var(--hSpacing16);\n  color: var(--textSecondary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"email": "email__Q7Ksw"
};
export default ___CSS_LOADER_EXPORT___;
