// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paymentAccountAllTransactionsTab__P3Wkv{position:relative;display:flex;height:100%;margin:var(--hSpacing4) var(--vSpacing20)}.paymentAccountAllTransactionsTab__noData__FHOiq{position:unset}", "",{"version":3,"sources":["webpack://./pages/PaymentsPage/components/PaymentAccountPage/components/PaymentAccountAllTransactionsTab/PaymentAccountAllTransactionsTab.module.scss"],"names":[],"mappings":"AAEA,yCACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,yCAAA,CAEA,iDACE,cAAA","sourcesContent":["@import '../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.paymentAccountAllTransactionsTab {\n  position: relative;\n  display: flex;\n  height: 100%;\n  margin: var(--hSpacing4) var(--vSpacing20);\n\n  &__noData {\n    position: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentAccountAllTransactionsTab": "paymentAccountAllTransactionsTab__P3Wkv",
	"paymentAccountAllTransactionsTab__noData": "paymentAccountAllTransactionsTab__noData__FHOiq"
};
export default ___CSS_LOADER_EXPORT___;
