export const pathInfluencerRoute = '/influencer/';
export const pathDataAnalytics = '/data-analytics';
export const pathCommunication = '/communication';
export const pathStages = '/stages';
export const pathCampaign = '/campaign/';
export const pathCampaignSettings = '/settings';

export const Routes: Record<string, string> = {
  discovery: '/discovery/browse',
  search: '/discovery/search',
  uploadList: '/discovery/upload-list',
  selection: '/discovery/selection',
  influencerPage: `${pathInfluencerRoute}:id`,
  // login
  login: '/login',
  // signUp
  join: '/join',
  joinWorkspace: '/join/invite/:inviteId',
  // restore
  resetSuccess: '/restore/success',
  resetPassword: '/restore/reset-password',
  changePassword: '/restore/change-password',
  // Workspace
  workspaceCreation: '/create-workspace',
  editWorkspace: '/edit-workspace',
  // AddressBook
  addressBook: '/address-book',
  // Workspace settings
  workspaceSettings: '/workspace-settings',
  workspaceMembers: '/workspace-settings/workspace-members',
  workspaceInfo: '/workspace-settings/workspace-info',
  billing: '/workspace-settings/billing',
  emailConnection: '/workspace-settings/email-connection',
  reviewPayment: '/review-payment',
  choseTariffPlan: '/choseTariffPlan',
  legalEntity: '/workspace-settings/legal-entity',
  // Campaigns
  campaigns: '/campaigns',
  campaignDeal: `${pathCampaign}:campaignId/deal/:dealId`,
  campaign: `${pathCampaign}:id${pathStages}`,
  dataAnalytics: `${pathCampaign}:id${pathDataAnalytics}`,
  communication: `${pathCampaign}:id${pathCommunication}`,
  stages: `${pathCampaign}:id${pathStages}`,
  campaignSettings: `${pathCampaign}:campaignId${pathCampaignSettings}`,
  // Workspace settings
  noAccess: '/no-access',
  // Payments
  payments: '/payments',
  paymentsReadyForPayment: '/payments/ready-for-payment',
  paymentsHistory: '/payments/history',
  paymentAccount: '/payments/account/:accountId',
  paymentAccountAll: '/payments/account/:accountId/all',
  paymentAccountIncoming: '/payments/account/:accountId/incoming',
  paymentAccountOutgoing: '/payments/account/:accountId/outgoing',
};

/*
 * Роуты в app-сабдомене, требующие авторизации пользователя.
 */
export const DEFAUTL_SUBDOMAIN_ROUTES_WITH_AUTH = [/join\/invite\/.+/];
