import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { PopupPositions } from '@uikit/components/Popup';
import { Tooltip } from '@uikit/components/Tooltip';
import styles from './UnderlineTooltip.module.scss';

type TInformationTooltipProps = {
  className?: string;
  position?: PopupPositions;
  tooltipText: string;
};

export const UnderlineTooltip: FC<PropsWithChildren<TInformationTooltipProps>> = ({
  children,
  className,
  position = PopupPositions.RightCenter,
  tooltipText,
}) => (
  <Tooltip
    content={<span className={styles.underlineTooltip__contentSize}>{tooltipText}</span>}
    position={position}
    className={cn(className, styles.underlineTooltip)}
  >
    <span className={styles.underlineTooltip__content}>{children}</span>
  </Tooltip>
);
