import axios from 'axios';
import { isRouteWithIgnoreAuthorizationErrors, setAccessToken } from '@utils/auth.utils';
import { logoutUserFx, updateAuthTokenFx } from '@models/auth.model';

type TFailedQueue = { resolve: (value: unknown) => void; reject: (reason?: unknown) => void };

let isRefreshing = false;
let failedQueue: TFailedQueue[] = [];

const processQueue = (error: unknown, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  (response) => response,
  async (err) => {
    const originalRequest = err?.config;

    if (isRouteWithIgnoreAuthorizationErrors()) {
      return Promise.reject(err);
    }

    if (
      err?.response?.status === 401 &&
      err?.response?.data?.slug === 'unauthorized--wrong-credentials' &&
      // eslint-disable-next-line no-underscore-dangle
      !originalRequest?._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;

            return axios(originalRequest);
          })
          .catch((er) => Promise.reject(er));
      }

      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const res = await updateAuthTokenFx();
        const { url, method, data } = originalRequest;

        setAccessToken(res?.accessToken);
        processQueue(null, res?.accessToken);

        return await axios({
          url,
          method,
          data,
          headers: { 'Content-Type': 'application/json' },
        });
      } catch (error) {
        // Если после обновления token произошла ошибка не связанная с токеном,
        // а связана с корректной работой повторного перезапроса метода(пример: валидация back)
        // то возвращаем ошибку не делая logout пользователя
        if (
          error?.response?.status !== 401 &&
          error?.response?.data?.slug !== 'unauthorized--wrong-credentials'
        ) {
          return await Promise.reject(error);
        }

        logoutUserFx();
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(err);
  }
);
