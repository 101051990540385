// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".legalEntityDialog__JUmPH{height:100%}.legalEntityDialog__halfBlock__OWqYx{display:flex;gap:var(--vSpacing20)}.legalEntityDialog__halfInputs__hSQZ6{width:100%}", "",{"version":3,"sources":["webpack://./pages/WorkspaceSettings/components/LegalEntity/components/LegalEntityDialog/LegalEntityDialog.module.scss"],"names":[],"mappings":"AAEA,0BACE,WAAA,CAEA,qCACE,YAAA,CACA,qBAAA,CAGF,sCACE,UAAA","sourcesContent":["@import '../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.legalEntityDialog {\n  height: 100%;\n\n  &__halfBlock {\n    display: flex;\n    gap: var(--vSpacing20);\n  }\n\n  &__halfInputs {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legalEntityDialog": "legalEntityDialog__JUmPH",
	"legalEntityDialog__halfBlock": "legalEntityDialog__halfBlock__OWqYx",
	"legalEntityDialog__halfInputs": "legalEntityDialog__halfInputs__hSQZ6"
};
export default ___CSS_LOADER_EXPORT___;
