/*
 * Провайдер контекста портала.
 */
import { createContext } from 'react';

export type TPortalContext = Record<string, never> | null;

export const portalContext = createContext<TPortalContext>(null);

export const PortalProvider = portalContext.Provider;
