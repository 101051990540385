/*
 * Multiselect component.
 */
import { FC, memo, ReactNode } from 'react';
import { Chip } from '@uikit/components/Chips/Chip';
import { MultiSelectOption } from '@uikit/components/MultiSelect/components/MultiSelectOption';
import { Scroll } from '@uikit/components/Scroll';
import { SelectField } from '@uikit/components/SelectField';
import {
  TSelectFieldProps,
  TSelectOption,
  TSelectOptions,
} from '@uikit/components/SelectField/SelectField.types';
import { noop } from '@uikit/helpers/common.helpers';
import styles from './MultiSelect.module.scss';

type TMultiSelectProps = {
  onChange?: (value: TSelectOptions) => void;
  value?: TSelectOptions;
  maxValues?: number;
  closeMenuOnSelect?: boolean;
};

export const MultiSelect: FC<TSelectFieldProps & TMultiSelectProps> = memo(
  ({
    input,
    onChange,
    value,
    valuePath,
    viewValuePath,
    maxValues,
    optionComponent = MultiSelectOption,
    closeMenuOnSelect = false,
    ...props
  }) => {
    const selectedValues = value || (input?.value as TSelectOptions);

    const onSelectChange = (selected: TSelectOptions): void => {
      if (maxValues && selected.length > maxValues) return;
      onChange ? onChange(selected) : input?.onChange(selected);
    };

    const onDeleteChip = (chipValue: string): void => {
      onSelectChange(
        selectedValues.filter((option) => {
          const optionValue = valuePath ? option[valuePath] : option;
          return optionValue !== chipValue;
        })
      );
    };

    return (
      <div>
        <SelectField
          input={{
            ...input,
            name: input?.name || 'multiSelect',
            value: selectedValues,
            onChange: onSelectChange,
            onBlur: input?.onBlur || noop,
            onFocus: input?.onFocus || noop,
          }}
          {...props}
          valuePath={valuePath}
          viewValuePath={viewValuePath}
          isMulti
          controlShouldRenderValue={false}
          optionComponent={optionComponent}
          closeMenuOnSelect={closeMenuOnSelect}
          hideSelectedOptions={false}
          isClearable={false}
        />

        <Scroll>
          <div className={styles.multiSelect__chipsContainer}>
            {!!selectedValues.length &&
              selectedValues.map((option) => (
                <Chip
                  key={option[valuePath as keyof TSelectOption] as string}
                  onDelete={() => onDeleteChip(option[valuePath as keyof TSelectOption] as string)}
                  className={styles.multiSelect__chip}
                  withDelete
                >
                  {option[viewValuePath as keyof TSelectOption] as ReactNode}
                </Chip>
              ))}
          </div>
        </Scroll>
      </div>
    );
  }
);
