/*
 * Abstract filters component.
 */
import cn from 'clsx';
import _differenceWith from 'lodash/differenceWith';
import _isEqual from 'lodash/isEqual';
import { FC } from 'react';
import { SmaPlatform } from '@hypetrainCommon';
import { FILTER_TYPES, TFilterConfig } from '@uikit/components/Filter';
import { AsyncSelectFilter } from '@uikit/components/Filter/components/AsyncSelectFilter';
import { CheckboxFilter } from '@uikit/components/Filter/components/CheckboxFilter';
import { InputFilter } from '@uikit/components/Filter/components/InputFilter';
import { KeywordFilter } from '@uikit/components/Filter/components/KeywordsFilter';
import { RadioGroupFilter } from '@uikit/components/Filter/components/RadioGroupFilter';
import { RangeFilter } from '@uikit/components/Filter/components/RangeFilter';
import { SelectFilter } from '@uikit/components/Filter/components/SelectFilter';
import { ToggleFilter } from '@uikit/components/Filter/components/ToggleFilter';
import { TAbstractFilterProps } from '@uikit/components/Filter/filter.types';
import { TFilter } from '@uikit/components/Filters/filters.types';
import {
  getFilterSwitchers,
  getFiltersWithUpdatedVisibility,
  getHideActiveFilter,
  getUpdatedFilters,
} from '@uikit/components/Filters/filters.utils';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import {
  OptionsToggler,
  TOptionsToggleItem,
  TOptionsTogglerGroup,
} from '@uikit/components/OptionsToggler';
import { getOptionsTogglerValue } from '@uikit/components/OptionsToggler/optionsToggler.utils';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import styles from './Filters.module.scss';
import { FILTERS_EMBEDDING_PLACES, FiltersProvider } from './filters.context';

type TFiltersProps = {
  filters: TFilterConfig[];
  groups?: TOptionsTogglerGroup[];
  onFilterChange: (filters: TFilterConfig[], needUpdateTableData: boolean) => void;
  platform: SmaPlatform;
  embeddingPlace: FILTERS_EMBEDDING_PLACES;
  withFilterManagment?: boolean; // TODO: Удалить после добавления в browse управления отображаемыми фильтрами
  className?: string;
  applyOnClose?: boolean;
};

const FILER_COMPONENTS_BY_TYPE: Record<FILTER_TYPES, FC<TAbstractFilterProps>> = {
  [FILTER_TYPES.RANGE]: RangeFilter,
  [FILTER_TYPES.RADIO]: RadioGroupFilter,
  [FILTER_TYPES.CHECKBOX_GROUP]: CheckboxFilter,
  [FILTER_TYPES.KEYWORDS]: KeywordFilter,
  [FILTER_TYPES.SELECT]: SelectFilter,
  [FILTER_TYPES.ASYNC_SELECT]: AsyncSelectFilter,
  [FILTER_TYPES.TOGGLE]: ToggleFilter,
  [FILTER_TYPES.INPUT]: InputFilter,
};

export const Filters: FC<TFiltersProps> = ({
  filters,
  groups,
  onFilterChange,
  platform,
  embeddingPlace,
  withFilterManagment = true,
  className,
  applyOnClose = true,
}) => {
  const { t } = useUiKitTranslation();
  const filtersSwitchers: TOptionsToggleItem[] = getFilterSwitchers(filters);
  const hasVisibleFilters = filtersSwitchers.some((sort) => sort.selected);

  const onFilterChangeHandler = (changedFilterId: string, value: TFilter): void => {
    const updatedFilters = getUpdatedFilters(filters, changedFilterId, value);

    if (!_differenceWith(filters, updatedFilters, _isEqual).length) return;

    onFilterChange(updatedFilters, true);
  };

  const onChangeVisibility = (switchers: TOptionsToggleItem[]): void => {
    const visibilityFiltersState = getOptionsTogglerValue(switchers);
    const updatedFilters = getFiltersWithUpdatedVisibility(filters, visibilityFiltersState);
    const hideActiveFilter = getHideActiveFilter(filters, visibilityFiltersState);

    onFilterChange(updatedFilters, !!hideActiveFilter); // обновляем таблицу если был скрыт активный фильтр (в этом случае он сбрасывается)
  };

  return (
    <FiltersProvider value={{ platform, embeddingPlace }}>
      <div className={cn(styles.filters, className)}>
        <Icon
          className={styles.filters__icon}
          type={ICON_TYPES.FILTER}
        />

        <div className={styles.filters__container}>
          {filters.map((filter) => {
            const Filter = FILER_COMPONENTS_BY_TYPE[filter.filterType];

            return (
              filter.isVisible && (
                <Filter
                  key={filter.id}
                  config={filter}
                  onApply={(value) => onFilterChangeHandler(filter.id, value)}
                  applyOnClose={applyOnClose}
                />
              )
            );
          })}

          {withFilterManagment && (
            <OptionsToggler
              className={styles.filters__options}
              title={t('filters.filtering')}
              text={hasVisibleFilters ? '' : t('filters.addFilters')}
              options={filtersSwitchers}
              groups={groups}
              onChange={onChangeVisibility}
            />
          )}
        </div>
      </div>
    </FiltersProvider>
  );
};
