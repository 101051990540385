/*
 * Контекст предоставляемый компонентом TableRow.
 */
import { createContext, useContext } from 'react';

type TTableRowContext = {
  isHovered: boolean;
};

const TableRowContext = createContext<TTableRowContext>({ isHovered: false });

export const TableRowProvider = TableRowContext.Provider;

export const useTableRow = (): TTableRowContext => useContext(TableRowContext);
