// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paymentTransactionAmount__jJbKJ{display:flex}.paymentTransactionAmount__info__WZM3K{margin-left:var(--vSpacing8)}", "",{"version":3,"sources":["webpack://./pages/PaymentsPage/components/PaymentAccountPage/components/PaymentTransactionAmount/PaymentTransactionAmount.module.scss"],"names":[],"mappings":"AAEA,iCACE,YAAA,CAEA,uCACE,4BAAA","sourcesContent":["@import '../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.paymentTransactionAmount {\n  display: flex;\n\n  &__info {\n    margin-left: var(--vSpacing8);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentTransactionAmount": "paymentTransactionAmount__jJbKJ",
	"paymentTransactionAmount__info": "paymentTransactionAmount__info__WZM3K"
};
export default ___CSS_LOADER_EXPORT___;
