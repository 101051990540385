/* Компонент отрисовывает график в виде горизонтальных столбчатых линий в таблице
 @see https://www.figma.com/file/TCZxcHBXR1hXEvOco2kqnc/UI-Kit-Hypetrain?node-id=1152%3A3768
 */
import cn from 'clsx';
import { FC, memo } from 'react';
import { SmaPlatform } from '@hypetrainCommon';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { getSocialPlatformBySMAId } from '@utils/socialPlatform.utils';
import { AGES_IQDATA, AGES_YOUTUBE } from '@helpers/constants';
import {
  DISPLAYED_AGES,
  SEX_DEMOGRAPHIC,
  TGendersPerAges,
  TMaxAgeAudienceDemographic,
} from '@components/AudienceDemographic/AudienceDemographic.types';
import { getAudienceDemographicColor } from '../audienceLines.constants';
import styles from './AudienceDemographic.module.scss';

type TAudienceLinesProps = {
  maxAge: TMaxAgeAudienceDemographic;
  filterAudience: TFilter;
  gendersPerAges: TGendersPerAges;
  uuid: string;
};

export const AudienceLines: FC<TAudienceLinesProps> = memo(
  ({ gendersPerAges, filterAudience, maxAge, uuid }) => {
    const ages =
      getSocialPlatformBySMAId(uuid) === SmaPlatform.Youtube ? AGES_YOUTUBE : AGES_IQDATA;

    return (
      <div className={styles.audienceLines}>
        {ages.map((rangeAge: DISPLAYED_AGES) => (
          <div
            key={rangeAge}
            className={styles.audienceLines__lineContainer}
          >
            <div className={cn(styles.audienceLines__halfBlocks, styles.audienceLines__firstBlock)}>
              <div
                style={{
                  width: `${(gendersPerAges[rangeAge]?.male || 0) * 165}%`,
                  background: getAudienceDemographicColor({
                    rangeAge,
                    filterAudience,
                    maxAge,
                    sex: SEX_DEMOGRAPHIC.MALE,
                  }),
                }}
                className={styles.audienceLines__line}
              />
            </div>
            <div className={styles.audienceLines__halfBlocks}>
              <div
                style={{
                  width: `${(gendersPerAges[rangeAge]?.female || 0) * 165}%`,
                  background: getAudienceDemographicColor({
                    rangeAge,
                    filterAudience,
                    maxAge,
                    sex: SEX_DEMOGRAPHIC.FEMALE,
                  }),
                }}
                className={styles.audienceLines__line}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
);
