/*
 * Range input component.
 * Can be used for input number ranges.
 */
import { FC, KeyboardEvent, memo } from 'react';
import { CurrencyInput } from '@uikit/components/Fields/Inputs/CurrencyInput';
import { MASKED_INPUT_TYPES, MaskedInput } from '@uikit/components/Fields/Inputs/MaskedInput';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import { RANGE_TYPES, TRangeConfig, TRangeValue } from '../../RangeFilter.types';
import styles from './RangeInput.module.scss';

type TRangeInputProps = {
  value?: TRangeValue;
  config: TRangeConfig;
  error?: string;
  onKeyDown?: (text: KeyboardEvent<HTMLInputElement>) => void;
  onChange: (value: TRangeValue) => void;
};

export const RangeInput: FC<TRangeInputProps> = memo(
  ({ value, config, error, onChange, onKeyDown }) => {
    const { t } = useUiKitTranslation();

    const handleChange = (inputValue: number | undefined, key: string): void => {
      onChange({ ...(value || {}), [key]: inputValue });
    };

    const getInputs = () => {
      switch (config.type) {
        case RANGE_TYPES.PERCENT:
        case RANGE_TYPES.NUMBER:
          return (
            <>
              <MaskedInput
                hideError
                error={error}
                onChange={(inputValue) => handleChange(inputValue, 'from')}
                onKeyDown={onKeyDown}
                maskType={config.type as unknown as MASKED_INPUT_TYPES}
                value={value?.from}
                {...config.from}
                label={t('rangeInput.from')}
              />
              <MaskedInput
                hideError
                error={error}
                onChange={(inputValue) => handleChange(inputValue, 'to')}
                onKeyDown={onKeyDown}
                maskType={config.type as unknown as MASKED_INPUT_TYPES}
                value={value?.to}
                {...config.to}
                label={t('rangeInput.to')}
              />
            </>
          );
        case RANGE_TYPES.CURRENCY:
          return (
            <>
              <CurrencyInput
                hideError
                error={error}
                onChange={(inputValue) => handleChange(inputValue, 'from')}
                onKeyDown={onKeyDown}
                value={value?.from}
                {...config.from}
                label={t('rangeInput.from')}
              />
              <CurrencyInput
                hideError
                error={error}
                onChange={(inputValue) => handleChange(inputValue, 'to')}
                onKeyDown={onKeyDown}
                value={value?.to}
                {...config.to}
                label={t('rangeInput.to')}
              />
            </>
          );
        default:
          return null;
      }
    };

    return (
      <>
        <div className={styles.rangeInput}>{getInputs()}</div>
        <div className={styles.rangeInput__error}>{error}</div>
      </>
    );
  }
);
