import cn from 'clsx';
import { FC } from 'react';
import { EMPTY_BLOCK_POSITION } from './EmptyBlock.constants';
import styles from './EmptyBlock.module.scss';

type TEmptyBlock = {
  className?: string;
  position?: EMPTY_BLOCK_POSITION;
};

const BLOCK_POSITION_MAP: Partial<Record<EMPTY_BLOCK_POSITION, string>> = {
  [EMPTY_BLOCK_POSITION.CENTER]: styles.emptyCell__center,
  [EMPTY_BLOCK_POSITION.RIGHT]: styles.emptyCell__right,
};

export const EmptyBlock: FC<TEmptyBlock> = ({
  className,
  position = EMPTY_BLOCK_POSITION.LEFT,
}) => <div className={cn(styles.emptyCell, [className], BLOCK_POSITION_MAP[position])}>N/A</div>;
