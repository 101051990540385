import { FC, useState } from 'react';
import { noop } from '@uikit/helpers/common.helpers';
import { Chip } from '../Chips/Chip';
import { Input } from '../Fields/Inputs';
import styles from './Keywords.module.scss';

type TKeywordsProps = {
  onChange: (chips: string[]) => void;
  placeholder?: string;
  label?: string;
  keywords: string[];
};

export const Keywords: FC<TKeywordsProps> = ({ onChange, placeholder, label, keywords }) => {
  const [inputValue, setInputValue] = useState('');

  const addingNewChip = (): void => {
    // если ввели пустоту, то не записываем в чипсу
    if (!inputValue.trim()) {
      return;
    }
    // если такой чипс уже есть
    if (keywords.some((el) => el === inputValue)) {
      return;
    }

    onChange([...keywords, inputValue]);
    setInputValue('');
  };

  const onKeyDown = (key: string): void => {
    if (key === 'Enter') {
      addingNewChip();
    }
  };

  const onDeleteChip = (chip: string): void => {
    onChange(keywords.filter((el) => el !== chip));
  };

  return (
    <>
      <Input
        input={{
          onBlur: addingNewChip,
          name: 'keywords',
          onChange: (e) => setInputValue(e.target.value),
          onFocus: noop,
          value: inputValue,
        }}
        label={label}
        placeholder={placeholder}
        onKeyDown={(e) => onKeyDown(e.key)}
        hideError
      />
      <div className={styles.chipsContainer}>
        {!!keywords.length &&
          keywords.map((el) => (
            <Chip
              key={el}
              onDelete={() => onDeleteChip(el)}
              className={styles.chipsContainer__chip}
              withDelete
            >
              {el}
            </Chip>
          ))}
      </div>
    </>
  );
};
