/*
 * Multi select option.
 */
import { FC } from 'react';
import { OptionProps } from 'react-select';
import { ListItem } from '@uikit/components/ListItem';

export const MultiSelectOption: FC<OptionProps<{ label: string; value: string }>> = ({
  innerProps,
  innerRef,
  data,
  isSelected,
  isMulti,
}) => (
  <ListItem
    isMulti={isMulti}
    mainText={data.label}
    innerProps={innerProps}
    innerRef={innerRef}
    isSelected={isSelected}
  />
);
