import cn from 'clsx';
import { FC } from 'react';
import { HeaderGroup } from 'react-table';
import styles from '@uikit/components/Table/Table.module.scss';
import { TTableColumn } from '@uikit/components/Table/table.types';

type TTHeadProps = {
  headerGroups: Array<HeaderGroup>;
};

export const THead: FC<TTHeadProps> = ({ headerGroups }) => (
  <thead className={styles.header}>
    {headerGroups.map((headerGroup) => (
      <tr
        {...headerGroup.getHeaderGroupProps()}
        className={styles.tr}
      >
        {headerGroup.headers.map((column) => (
          <th
            {...column.getHeaderProps()}
            className={cn(styles.th, (column as unknown as TTableColumn).className)}
          >
            {column.render('Header')}
          </th>
        ))}
      </tr>
    ))}
  </thead>
);
