/*
 * Хук для управления ивентами в тадлицах
 * */
import { RefObject, useEffect } from 'react';
import { TABLE_EVENTS, tableEventBus } from './tableEvenBus';

type TTableScrollTopParams = { name: string; isSmooth: boolean };

export const useTableEvents = (ref: RefObject<HTMLDivElement>, tableName?: string): void => {
  useEffect(() => {
    // подписываемся на событие "проскролить к верху таблицы"
    tableEventBus.on(TABLE_EVENTS.SCROLL_TO_TOP, (params: TTableScrollTopParams) => {
      if (tableName && tableName === params.name && ref?.current) {
        if (params.isSmooth) {
          ref.current.scrollTo(0, 0);
        } else {
          // по умолчанию у таблицы выставлен scroll-behavior: smooth,
          // свойство behavior метода scrollTo не перекрыввает его,
          // поэтому приходится изменять стиль scroll-behavior напрямую
          ref.current.style.scrollBehavior = 'auto'; // eslint-disable-line no-param-reassign
          ref.current.scrollTo(0, 0);
          ref.current.style.scrollBehavior = ''; // eslint-disable-line no-param-reassign
        }
      }
    });

    return () => tableEventBus.deleteListeners(TABLE_EVENTS.SCROLL_TO_TOP);
  }, []);
};
