export const APP_VERSION = '0.5.5';

export const currentSubdomain = window.location.host.split('.')[0];

export const defaultSubdomain = 'app';

export const csvData = [
  ['channel_id'],
  ['UCq-Fj5jknLsUf-MWSy4_brA'],
  ['https://www.youtube.com/channel/UCOmHUn--16B90oW2L6FRR3A'],
];
