import { useStore } from 'effector-react/compat';
import { SmaPlatform } from '@hypetrainCommon';
import { TFilterConfig } from '@uikit/components/Filter';
import { $activeSocialPlatform } from '@models/activeSocialPlatform';
import { $browseFilters } from '@pagesDiscovery/Browse/browse.model';

export const useGetBrowseFiltersByPlatform = (): TFilterConfig[] => {
  const activePlatform = useStore($activeSocialPlatform);
  const filters = useStore($browseFilters);
  const activeSocialPlatform = activePlatform?.platform || SmaPlatform.Youtube;

  return filters[activeSocialPlatform] as TFilterConfig[];
};
