/*
 * Сервис для работы с API-запросами exploration. (new)
 */
import { ExplorationBaseApiService, explorationBaseApiService } from '@api';
import {
  ISearchRequestDto,
  ISearchResponseDto,
  ISetSmaNoteRequestDto,
  ITiktokFilteringRequestDto,
  ITiktokFilteringResponseDto,
  ITiktokSmaResponseDto,
} from '@hypetrainCommon';
import { PROFILE_ERROR_SLUGS } from '@api/sma/sma.api.constants';

/* eslint-disable arrow-body-style */
export class ExplorationApiServiceNew {
  constructor(private readonly api: ExplorationBaseApiService) {}

  /*
   * Фильтрация и сортировка инфлюенсеров (TikTok).
   */
  filterInfluencers = (
    requestData: ITiktokFilteringRequestDto
  ): Promise<ITiktokFilteringResponseDto> => {
    return this.api
      .post(`/tiktok/filter`, requestData, {}, { showLoader: false })
      .then((response) => response.data);
  };

  /*
   * Получение профиля (TiKTok)
   */
  public getTikTokSmaProfile = (uuid: string): Promise<ITiktokSmaResponseDto> => {
    return this.api
      .get(
        `/tiktok/profile/${uuid}`,
        {},
        {
          showLoader: false,
          errorCodesToIgnore: PROFILE_ERROR_SLUGS,
        }
      )
      .then((response) => response.data);
  };

  /*
   * Сохранение заметки по SMA.
   */
  public setSmaNote = (body: ISetSmaNoteRequestDto): Promise<void> => {
    return this.api.post(`/sma/note`, body).then((response) => response.data);
  };

  /*
   * Поиск инфлюенсера.
   */
  public searchInfluencers = (requestData: ISearchRequestDto): Promise<ISearchResponseDto> => {
    return this.api.post(`/tiktok/search`, requestData).then((response) => response.data);
  };
}

export const explorationApiServiceNew = new ExplorationApiServiceNew(explorationBaseApiService);
