/*
 * Компонент для отображения стартового экрана процесса.
 * https://www.figma.com/file/TCZxcHBXR1hXEvOco2kqnc/UI-Kit-Hypetrain?type=design&node-id=10303-19039&t=wd1CxQzfuEzUaISC-4
 */
import cn from 'clsx';
import { Children, FC, PropsWithChildren } from 'react';
import { Button, TButtonProps } from '../Buttons';
import styles from './StartProcess.module.scss';

type TStartProcessProps = {
  img?: string;
  title: string;
  description?: string;
  buttons?: (TButtonProps & { text?: string })[];
  className?: string;
};

export const StartProcess: FC<PropsWithChildren<TStartProcessProps>> = ({
  img,
  title,
  description,
  buttons,
  className,
  children,
}) => (
  <div className={styles.startProcess__container}>
    <div className={cn(styles.startProcess, className)}>
      {img && (
        <img
          className={styles.startProcess__img}
          src={img}
          alt={img}
        />
      )}
      <h1>{title}</h1>
      {!!description && <p className={styles.startProcess__description}>{description}</p>}
      {children}
      {!!buttons?.length && (
        <div
          className={cn(
            styles.startProcess__buttonsContainer,
            Children.count(children) && styles.startProcess__buttonsContainer_topMargin
          )}
        >
          {buttons.map((button, i) => (
            <Button
              key={button.text || i}
              onClick={button.onClick}
              buttonType={button.buttonType}
            >
              {button.text}
            </Button>
          ))}
        </div>
      )}
    </div>
  </div>
);
