/*
 * Сервис для выполнения api-запросов по api app-route (/app/).
 * Не превращать в помойку, только по тому, что в названии есть абстрактное слово app (!!!)
 */
import { ApiService, apiService } from '@api';
import { IAppInitializationResponseDto } from '@hypetrainCommon';

/* eslint-disable arrow-body-style */
export class AppApiService {
  constructor(private readonly api: ApiService) {}

  /*
   * Загрузка информации необходимой для инициализации приложения.
   * @see: https://reference.api.team-master-dev.hypetrain.io/?http#get-data-for-frontend-app-initialization
   */
  public getInitAppData = (): Promise<IAppInitializationResponseDto> => {
    return this.api.get('/app/init').then((response) => response.data);
  };
}

export const appApiService = new AppApiService(apiService);
