import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { Button, TButtonProps } from '@uikit/components/Buttons';
import styles from './NoDataComponent.module.scss';

type TNoDataAction = TButtonProps & {
  buttonText: string;
};

export type TNoDataComponentProps = {
  img?: string;
  title: string;
  description?: string;
  className?: string;
  useMediumTitle?: boolean;
  actions?: TNoDataAction[];
};
// TODO: Отказаться от absolute!
export const NoDataComponent: FC<PropsWithChildren<TNoDataComponentProps>> = ({
  img,
  title,
  description,
  className,
  useMediumTitle = false,
  actions,
  children,
}) => (
  <div className={cn(styles.withoutDataContainer, className)}>
    {img && (
      <img
        src={img}
        alt={img}
      />
    )}
    {useMediumTitle && <div className={styles.withoutDataContainer__bodyMediumTitle}>{title}</div>}
    {!useMediumTitle && <h2 className={styles.withoutDataContainer__title}>{title}</h2>}
    {children}
    <div className={styles.withoutDataContainer__text}>{description}</div>
    <div className={styles.withoutDataContainer__buttonContainer}>
      {actions &&
        actions.map(
          (action, index) =>
            action.buttonText && (
              <Button
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={styles.withoutDataContainer__button}
                {...action}
              >
                {action.buttonText}
              </Button>
            )
        )}
    </div>
  </div>
);
