/*
 * Логируемые пользовательские события.
 */

export enum USERS_ACTIONS {
  // Нажатие по фильтру (YouTube)
  EXPLORATION_YOUTUBE_FILTERING_FILTER_OPENED = 'exploration-youtube-filtering-filter-opened',
  // Нажатие по сортировке (Instagram)
  EXPLORATION_INSTAGRAM_FILTERING_FILTER_OPENED = 'exploration-instagram-filtering-filter-opened',
  // Добавление в selection (YouTube)
  EXPLORATION_YOUTUBE_SMA_ADDED_IN_SELECTION = 'exploration-youtube-sma-added-in-selection',
  // Добавление в selection (Instagram)
  EXPLORATION_INSTAGRAM_SMA_ADDED_IN_SELECTION = 'exploration-instagram-sma-added-in-selection',
  // Выполнение поиска (YouTube)
  EXPLORATION_YOUTUBE_SEARCH_COMPLETED = 'exploration-youtube-search-completed',
  // Выполнение поиска (Instagram)
  EXPLORATION_INSTAGRAM_SEARCH_COMPLETED = 'exploration-instagram-search-completed',
  // Выполнение поиска, ничего не найдено (YouTube)
  EXPLORATION_YOUTUBE_SEARCH_NOTHING_FOUND = 'exploration-youtube-search-nothing-found',
  // Выполнение поиска, ничего не найдено (Instagram)
  EXPLORATION_INSTAGRAM_SEARCH_NOTHING_FOUND = 'exploration-instagram-search-nothing-found',
  // Выполнение поиска (TikTok)
  EXPLORATION_TIKTOK_SEARCH_COMPLETED = 'exploration-tiktok-search-completed',
  // Выполнение поиска, ничего не найдено (TikTok)
  EXPLORATION_TIKTOK_SEARCH_NOTHING_FOUND = 'exploration-tiktok-search-nothing-found',
  // Переход на табу upload (YouTube)
  EXPLORATION_YOUTUBE_UPLOAD_TAB_SELECTED = 'exploration-youtube-upload-tab-selected',
  // При загрузке файла не найдено ни одного инфлюенсера (YouTube)
  EXPLORATION_YOUTUBE_UPLOAD_NOTHING_FOUND = 'exploration-youtube-upload-nothing-found',
  // При загрузке файла найдены все инфлюенсеры (YouTube)
  EXPLORATION_YOUTUBE_UPLOAD_ALL_CHANNELS_FOUND = 'exploration-youtube-upload-all-channels-found',
  // При загрузке файла найдены не все инфлюенсеры (YouTube)
  EXPLORATION_YOUTUBE_UPLOAD_PARTIALLY_FOUND = 'exploration-youtube-upload-partially-found',
  // Попытка загрузить файл неверного формата
  EXPLORATION_YOUTUBE_UPLOAD_INVALID_FILE_FORMAT = 'exploration-youtube-upload-invalid-file-format',
  // Удаление из selection (YouTube)
  EXPLORATION_YOUTUBE_SMA_DELETE_FROM_SELECTION = 'exploration-youtube-sma-delete-from-selection',
  // Удаление из selection (Instagram)
  EXPLORATION_INSTAGRAM_SMA_DELETE_FROM_SELECTION = 'exploration-instagram-sma-delete-from-selection',
  // Клик по секции блока brands профиля Instagram
  EXPLORATION_INSTAGRAM_CLICK_ON_BRANDS_SECTION = 'exploration-instagram-click-on-brands-section',
  // Клик по кнопке show more блока brands профиля Instagram
  EXPLORATION_INSTAGRAM_CLICK_ON_SHOW_MORE_BRANDS = 'exploration-instagram-click-on-show-more-brands',
  // Нажатие на кпоку экпиротирования файла (YouTube)
  CLICK_ON_EXPORT_FILE_BUTTON_YOUTUBE = 'click-on-export-file-button-youtube',
  // Нажатие на кпоку экпиротирования файла (Instagram)
  CLICK_ON_EXPORT_FILE_BUTTON_INSTAGRAM = 'click-on-export-file-button-instagram',
  // Нажатие на логотип приложения в панели навигации
  CLICK_ON_LOGO = 'click-on-logo',
  // Добавлен новый фильтр в кампании
  CAMPAIGN_FILTER_ADDED = 'campaign-filter-added',
  // Удалён фильтр в кампании
  CAMPAIGN_FILTER_DELETED = 'campaign-filter-deleted',
  // Добавлена новая сортировка в кампании
  CAMPAIGN_SORT_ADDED = 'campaign-sort-added',
  // Удалёна сортировка в кампании
  CAMPAIGN_SORT_DELETED = 'campaign-sort-deleted',
  // Создана кампания
  CAMPAIGN_CREATED = 'campaign-created',
  // Заглушка для пока не реализованного события
  UNSUPPORTED = 'UNSUPPORTED',

  /// NEW
  // Открыта ссылка приглашения
  USER_INVITATION_LINK_CLICKED = 'user-invitationlink-clicked',
  // Открыта страница регистрации
  USER_REGISTRATION_PAGE_OPENED = 'user-registrationpage-opened',
  // Открыта страница авторизации
  USER_LOGIN_PAGE_OPENED = 'user-loginpage-opened',
  // Нажатие кнопки google auth
  USER_GOOGLE_AUTHENTICATION_INITIATED = 'user-googleauthentification-initiated',
  // Аккаунт создан
  USER_ACCOUNT_CREATED = 'user-account-created',
  // Имейл подтверждён
  USER_EMAIL_VERIFIED = 'user-email-verified',
  // Юзер первый раз авторизовался
  USER_LOGIN_EXECUTED = 'user-login-executed',
  // Повторная регистрация с уже существующим фингерпринттом
  USER_REPEATED_REGISTRATION_DETECTED = 'user-repeatedregistration-detected',
  // Start trial
  WORKSPACE_TRIAL_STARTED = 'workspace-trial-started',
  // Добавлен новый seat
  WORKSPACE_SEAT_ADDED = 'workspace-seat-added',
  // Открытие home страницы
  WORKSPACE_HOMEPAGE_OPENED = 'workspace-homepage-opened',
  // Инициализация проперти воркспейса
  WORKSPACE_INITIAL_PROPERTIES_DEFINED = 'workspace-initialproperties-defined',
  // Добавление данных персоны воркспейса
  WORKSPACE_MEMBER_PERSON_DEFINED = 'workspace-memberperson-defined',
  // Добавление данных пользователя
  WORKSPACE_USER_PERSON_CREATED = 'workspace-userperson-created',
  // Обновление данных пользователя
  WORKSPACE_USER_PERSON_UPDATED = 'workspace-userperson-updated',
  // Пользователь нажал кнопку создания сообщения с chatgpt
  CAMPAIGN_DEAL_MESSAGE_AI_SELECTED = 'campaignDeal-messageInputType-selected',
}
