/*
 * Компонент для отрисовки стадий кампаний/дилов(инфлуерсеров)
 * @see https://www.figma.com/file/EeuV0wy02eu5FGsF7cyi2P/Stages?node-id=649%3A19409&t=ZBqueWaTDJFrJB40-4
 * */
import cn from 'clsx';
import { StatusIndicator } from 'components/StatusIndicator';
import { useStore } from 'effector-react/compat';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CampaignDealStageStatus,
  ICampaignDealStageDto,
  ICampaignDealStagesDto,
  SmaPlatform,
} from '@hypetrainCommon';
import { PopupPositions } from '@uikit/components/Popup';
import { Tooltip } from '@uikit/components/Tooltip';
import {
  $stageActiveStore,
  setActiveStage,
} from '@pagesCampaigns/CampaignDealPage/campaignDealPage.model';
import { AMOUNT_PERCENTS } from '@pagesCampaigns/CampaignDealPage/utils/CampaignDealPage.utils';
import { $campaignStore } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.model';
import styles from './ContractingStages.module.scss';

type TStagesProps = {
  listStage: ICampaignDealStagesDto;
  showHeader?: boolean;
  turnOffAnimation?: boolean;
};

export const ContractingStages: FC<TStagesProps> = memo(
  ({ listStage, showHeader = true, turnOffAnimation = false }) => {
    const { t } = useTranslation();
    const currentStage = useStore($stageActiveStore);
    const campaign = useStore($campaignStore);

    // Удалить после отключения hypetrainDoubleBottom
    let listStageSort: ICampaignDealStageDto[] = listStage?.list.sort(
      (a, b) => a.sequenceNumber - b.sequenceNumber
    );
    let firstInactive: number;

    if (campaign?.campaignDetails?.platform === SmaPlatform.Tiktok) {
      firstInactive = 1;

      listStageSort = listStage?.list
        ?.slice(0, firstInactive + 1)
        .sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    }

    const changeStage = (stage: ICampaignDealStageDto) => {
      if (stage?.status !== CampaignDealStageStatus.NotStarted && stage?.status) {
        setActiveStage(stage);
      }
    };

    return (
      <div className={styles.contractingStages}>
        <div className={styles.contractingStages_scroll}>
          {showHeader && (
            <div className={styles.contractingStages__title}>
              <h4>{t('stages.title')}</h4>
            </div>
          )}

          {listStageSort?.map((stage, index) => {
            const isNotStarted =
              stage?.status === CampaignDealStageStatus.NotStarted || !stage?.status;
            const isCurrentStage = stage?.id === currentStage?.id;

            return (
              <Tooltip
                content={t('stages.moreStagesTooltip')}
                disable={stage?.isAvailable}
                position={PopupPositions.BottomCenter}
                key={stage?.id}
              >
                <div className={styles.contractingStages__stageContainer}>
                  <div className={styles.contractingStages__stage_next} />
                  <div
                    className={cn(
                      styles.contractingStages__stage,
                      isNotStarted && styles.contractingStages__stage_disabled
                    )}
                  >
                    <div
                      className={cn(
                        styles.contractingStages__stageShadow,
                        !turnOffAnimation &&
                          !isNotStarted &&
                          !isCurrentStage &&
                          styles.contractingStages__stageShadowHoverArea,
                        index === 0 && styles.contractingStages__stageShadow_first,
                        currentStage &&
                          stage?.isAvailable &&
                          isCurrentStage &&
                          styles.contractingStages__stageShadowActive
                      )}
                      onClick={() => !turnOffAnimation && changeStage(stage)}
                    >
                      <div className={styles.contractingStages__stageContent}>
                        {index === firstInactive && (
                          <div
                            data-hidden="stage"
                            className={styles.contractingStages__stage_blurLayer}
                          />
                        )}
                        <StatusIndicator
                          currentStage={stage?.name}
                          currentState={stage?.currentState}
                          isAvailable={stage?.isAvailable && !isNotStarted}
                          turnOffAnimation={turnOffAnimation}
                        />
                        <div className={styles.contractingStages__content}>
                          <h4 className={styles.contractingStages__title}>
                            {t(`stages.${stage.name}.name`)}{' '}
                            {stage?.additionalData?.amountPercent === AMOUNT_PERCENTS.HALF
                              ? t(`stages.${stage.name}.${stage.id}`)
                              : ''}
                          </h4>
                          <div
                            className={cn(
                              styles.contractingStages__status,
                              isNotStarted && styles.contractingStages__disableText
                            )}
                          >
                            {isNotStarted
                              ? t('stages.notStarted')
                              : t(`stages.${stage?.name}.${stage?.currentState}`)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
    );
  }
);
