/*
 *   Компонент для отображения анимированной иконки заметки
 */
import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { PopupPositions } from '@uikit/components/Popup';
import { Tooltip } from '@uikit/components/Tooltip';
import styles from './NoteIcon.module.scss';

type TNoteIconProps = {
  className?: string;
};

export const NoteIcon: FC<PropsWithChildren<TNoteIconProps>> = ({ className, children }) => (
  <Tooltip
    content={<span>{children}</span>}
    position={PopupPositions.BottomCenter}
    disable={!children}
    offset={20}
  >
    <div className={cn(styles.noteIcon, !!children && styles.noteIcon__needAnimate, className)}>
      <div className={styles.noteIcon__lineOne} />
      <div className={styles.noteIcon__lineTwo} />
      <div className={styles.noteIcon__lineThree} />
    </div>
  </Tooltip>
);
