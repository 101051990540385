/*
 * Component for action list, that show in tooltip.
 */
import { FC } from 'react';
import { usePopup } from '@uikit/components/Popup';
import { TooltipAction } from '@uikit/components/Tooltip/components/TooltipAction';
import styles from './TooltipActionMenu.module.scss';

export type TTooltipActionMenuItem = {
  handler: () => void;
  text: string;
  // TODO Кирилл: позорище страшное Вместо enum передаём каждый раз новую иконку (её нужно добавлять новую (вторую!) белую, ещё одну и тд)
  icon?: string;
  isNeedConfirmation?: boolean;
  isHide?: boolean;
  isDisable?: boolean;
};

type TTooltipActionMenuProps = {
  actions: TTooltipActionMenuItem[]; // list of actions displayed in the menu
};

export const TooltipActionMenu: FC<TTooltipActionMenuProps> = ({ actions }) => {
  const { popupService } = usePopup();

  const onItemClick = (action: TTooltipActionMenuItem): void => {
    action.handler();
    popupService.hidePopup();
  };

  return (
    <div className={styles.tooltipActionMenu}>
      {actions
        .filter((action) => !action?.isHide)
        .map((action) => (
          <TooltipAction
            className={styles.tooltipActionMenu__item}
            key={action.text}
            action={action}
            onClick={() => onItemClick(action)}
          />
        ))}
    </div>
  );
};
