/*
 * Модель подписки
 */
import { TBackendError } from '@api';
import { datadogLogs } from '@datadog/browser-logs';
import { createEffect, createStore, forward } from 'effector/compat';
import {
  ICreateSubscriptionRequestDto,
  ISubscriptionInfoResponseDto,
  ISubscriptionPlanInfoDto,
  ISubscriptionSimpleInfoDto,
  ITaxInfoResponseDto,
  IUpdateSubscriptionRequestDto,
} from '@hypetrainCommon';
import { notificationsService } from '@uikit/components/Notification';
import { Routes } from '@router/Routes';
import { subscriptionApiService } from '@api/subscription';
import { UPDATE_SUBSCRIPTION_PAYMENT_ERRORS } from '@api/subscription/subscription.api.constants';
import i18n from '@services/i18n';
import { subscriptionUserAccessService } from '@services/userAccess';
import { redirectToApp } from '@utils/redirect.utils';
import { isSubscriptionInTestMode } from '@utils/subscription.utils';
import { LOG_SEVERITY, LOG_TOPIC } from '@enums/log.enum';
import { $activeWorkspaceStore } from '@models/activeWorkspace';

type TCreateSubscriptionParams = ICreateSubscriptionRequestDto & { workspaceId: string };

/*
 * Создание подписки.
 */
export const createSubscriptionFx = createEffect(
  ({ country, postalCode, workspaceId }: TCreateSubscriptionParams) =>
    subscriptionApiService.createSubscription({ body: { country, postalCode }, workspaceId })
);

/*
 * Обновление подписки.
 */
export const updateSubscriptionFx = createEffect((data: IUpdateSubscriptionRequestDto) =>
  subscriptionApiService
    .updateSubscription(data)
    .then((updatedSubscription) => {
      const currentSubscription = $subscription.getState(); // eslint-disable-line @typescript-eslint/no-use-before-define
      // Если разница ситов больше 0 - это процесс добавления ситов, если меньше 0 - процесс удаления сита.
      const seatsDifference =
        Number(updatedSubscription?.seats) - Number(currentSubscription?.seats);
      const caption =
        seatsDifference > 0
          ? i18n.t('workspaceSettings.workspaceMembers.addSeatsNotification', {
              count: seatsDifference,
            })
          : i18n.t('workspaceSettings.workspaceMembers.removeSeatNotification');

      notificationsService.successful({
        timeout: 10000,
        props: { caption },
      });

      return updatedSubscription;
    })
    .catch((e: TBackendError) => {
      const isPaymentError =
        e.response?.data?.slug && UPDATE_SUBSCRIPTION_PAYMENT_ERRORS.includes(e.response.data.slug);

      if (isPaymentError) {
        const activeWorkspace = $activeWorkspaceStore.getState();
        notificationsService.error({
          timeout: 0,
          props: {
            caption: i18n.t('workspaceSettings.workspaceMembers.updateSubscriptionError'),
            actions: [
              {
                text: i18n.t('workspaceSettings.workspaceMembers.updateSubscriptionErrorButton'),
                onClick: () =>
                  redirectToApp(activeWorkspace?.workspace?.handler || '', Routes.billing),
              },
            ],
          },
        });
      }

      return Promise.reject(e);
    })
);

/*
 * Получение подписки.
 */
export const getSubscriptionFx = createEffect(subscriptionApiService.getSubscription);

/*
 * Получение ссылки на портал пользователя.
 */
export const getCustomerPortalFx = createEffect(subscriptionApiService.getCustomerPortalUrl);

/*
 * Открытие портала пользователя.
 */
export const openCustomerPortalFx = createEffect(() =>
  getCustomerPortalFx({ successUrl: window.location.href }).then(({ url }) => {
    window.open(url);
  })
);

/*
 * Отмена подписки.
 */
export const cancelSubscriptionFx = createEffect(subscriptionApiService.cancelSubscription);

/*
 * Возобновление подписки.
 */
export const resumeSubscriptionFx = createEffect(subscriptionApiService.resumeSubscription);

/*
 * Эффект получения чекаут сессии
 */
export const createCheckoutFx = createEffect(subscriptionApiService.createCheckout);

/*
 * Запрос суммы налогов.
 * Если данные уже были загружены - отдаём их.
 */
export const getTaxAmountsFx = createEffect(
  (showLoader = true): Promise<ITaxInfoResponseDto | null> => {
    const loadedTax: ITaxInfoResponseDto | null = $subscriptionTax.getState(); // eslint-disable-line @typescript-eslint/no-use-before-define

    if (loadedTax) {
      return Promise.resolve(loadedTax);
    }

    if (!subscriptionUserAccessService.getFullSubscriptionInfo) {
      return Promise.reject();
    }

    return subscriptionApiService.getTaxAmounts(showLoader);
  }
);

/*
 * Получить список доступных тарифных планов.
 */
export const getSubscriptionPlansFx = createEffect((showLoader = true) =>
  subscriptionApiService.getSubscriptionPlans(showLoader)
);

/*
 * Расчёт пропорций при добавлении ситов (например сколько будет стоить добавить 31 сита на оставшуюся часть платёжного периода).
 */
export const calculateProrationFx = createEffect(subscriptionApiService.calculateProration);

/*
 * Возвращает режим работы подписки пользователя.
 */
export const getSubscriptionModeFx = createEffect(() =>
  subscriptionApiService.getMode().then((subscription) => {
    if (isSubscriptionInTestMode(subscription) && process.env.ENVIRONMENT === 'prod') {
      console.error('Application work in test mode!'); // eslint-disable-line no-console
      datadogLogs.logger.error('Application work in test mode!', {
        topic: LOG_TOPIC.JS_ERROR,
        severity: LOG_SEVERITY.CRITICAL_SUBSCRIPTION_ERRORS,
      });
    }

    return subscription;
  })
);

/*
 * Реакция на изменение статуса подписки пользователем (деакцивация/активация).
 */
const onChangeSubscriptionStatusByUserFx = createEffect(() => {
  redirectToApp($activeWorkspaceStore.getState()?.workspace?.handler || '', Routes.billing);
});

/*
 * Стор подписки
 */
export const $subscription = createStore<
  ISubscriptionInfoResponseDto | ISubscriptionSimpleInfoDto | null
>(null)
  .on($activeWorkspaceStore.updates, (state, activeWorkspace) => activeWorkspace.subscription)
  .on(getSubscriptionFx.doneData, (state, subscription) => subscription)
  .on(updateSubscriptionFx.doneData, (state, subscription) => subscription)
  .on(cancelSubscriptionFx.doneData, (state, subscription) => subscription)
  .on(resumeSubscriptionFx.doneData, (state, subscription) => subscription)
  .on(getSubscriptionModeFx.doneData, (state, subscription) => ({
    ...(state || {}),
    ...subscription,
  }));

export const $subscriptionPlans = createStore<ISubscriptionPlanInfoDto[]>([]).on(
  getSubscriptionPlansFx.doneData,
  (state, plans) => plans
);

/*
 * Стор с данными о налогах в текущей подписки.
 */
export const $subscriptionTax = createStore<ITaxInfoResponseDto | null>(null).on(
  getTaxAmountsFx.doneData,
  (state, tax) => tax
);

forward({
  from: [cancelSubscriptionFx.done, resumeSubscriptionFx.done],
  to: [onChangeSubscriptionStatusByUserFx],
});
