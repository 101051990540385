import i18n from 'i18next';
import { SmaPlatform } from '@hypetrainCommon';
import TikTokIcon from '@uikit/assets/images/tikTok.svg';
import InstagramIcon from '@assets/images/instagram.svg';
import YoutubeIcon from '@assets/images/youtube.svg';

export const ICONS_BY_SOCIAL_MEDIA: Record<SmaPlatform, string> = {
  [SmaPlatform.Instagram]: InstagramIcon,
  [SmaPlatform.Youtube]: YoutubeIcon,
  [SmaPlatform.Twitter]: null as unknown as string,
  [SmaPlatform.Twitch]: null as unknown as string,
  [SmaPlatform.Facebook]: null as unknown as string,
  [SmaPlatform.Tiktok]: TikTokIcon,
};

export const TITLES_BY_SOCIAL_MEDIA: Record<SmaPlatform, string> = {
  [SmaPlatform.Instagram]: i18n.t('instagramTitle'),
  [SmaPlatform.Youtube]: i18n.t('youtubeTitle'),
  [SmaPlatform.Twitter]: null as unknown as string,
  [SmaPlatform.Twitch]: null as unknown as string,
  [SmaPlatform.Facebook]: null as unknown as string,
  [SmaPlatform.Tiktok]: i18n.t('tikTokTitle'),
};
