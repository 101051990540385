import { SmaAudienceCountryValue, SmaPlatform } from '@hypetrainCommon';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { FORMAT_NUMBER_NOTATION, FormatNumber } from '@uikit/components/Formats';
import { CELL_POSITION, DefaultCell, TableHeaderCell } from '@uikit/components/Table';
import { TColumn } from '@uikit/components/Table/table.types';
import { TextEllipsis } from '@uikit/components/TextEllipsis';
import { fractionToInteger } from '@uikit/utils/number.utils';
import i18n from '@services/i18n';
import { i18nToString } from '@utils/i18translate.utils';
import { colorCountries } from '@helpers/colorCountries';
import { getCountryName } from '@constants/countries';
import { getAudienceGraphicColor, getHeaderInstagramCell } from '../utils';
import styles from './AudienceLocation.module.scss';

type TGetAudienceLocationColumn = {
  filterCountries: TFilter;
  subscribers: number;
  platform: SmaPlatform;
  selectedTab?: string;
};

export const getAudienceLocationColumn = ({
  filterCountries,
  subscribers,
  platform,
  selectedTab,
}: TGetAudienceLocationColumn): TColumn<SmaAudienceCountryValue>[] => [
  {
    id: 'name',
    accessor: '',
    Header: <h4>{i18nToString(i18n.t('audienceCountryLabel'))}</h4>,
    width: 150,
    disableSortBy: true,
    Cell: ({ row }) => {
      const country = row?.original?.key;
      return (
        <div className={styles.countryColumn}>
          <div
            className={styles.countryColumn__countrySquare}
            style={{
              backgroundColor: getAudienceGraphicColor(
                country,
                colorCountries[country],
                filterCountries
              ),
            }}
          />
          <TextEllipsis className={styles.countryColumn__countryText}>
            {getCountryName(country)}
          </TextEllipsis>
        </div>
      );
    },
  },
  {
    id: 'views',
    accessor: '',
    Header: (
      <DefaultCell position={CELL_POSITION.RIGHT}>
        <TableHeaderCell
          text={
            platform === SmaPlatform.Youtube
              ? i18nToString(i18n.t('subscribers'))
              : getHeaderInstagramCell(selectedTab as string)
          }
        />
      </DefaultCell>
    ),
    width: 90,
    className: styles.flexEnd,
    disableSortBy: true,
    Cell: ({ row }) => (
      <>
        {subscribers && (
          <FormatNumber
            notation={FORMAT_NUMBER_NOTATION.COMPACT}
            className={styles.subscribersValue}
          >
            {(row?.original?.value || 0) * subscribers}
          </FormatNumber>
        )}
        <div>({fractionToInteger(row?.original?.value, 2)}%)</div>
      </>
    ),
  },
];
