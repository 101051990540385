import { FC } from 'react';
import { AttachIcon } from '@uikit/components/AttachIcon';
import { TextEllipsis } from '@uikit/components/TextEllipsis';
import { getFileFormat } from '@uikit/helpers/files.helpers';
import { getUnifiedFormat } from '@uikit/helpers/format.helpers';
import styles from './AttachManyFilesOneIcon.module.scss';

export type TAttachManyFilesOneIcon = {
  text: string;
};

export const AttachManyFilesOneIcon: FC<TAttachManyFilesOneIcon> = ({ text }) => (
  <div className={styles.attachFile}>
    <div>
      <AttachIcon type={getUnifiedFormat(getFileFormat(text))} />
    </div>
    <div className={styles.attachFile__textContainer}>
      <TextEllipsis className={styles.attachFile__textEllipsis}>{text}</TextEllipsis>
    </div>
  </div>
);
