import cn from 'clsx';
import { FC, memo } from 'react';
import { FILE_UPLOAD_STATUS } from '@uikit/components/Attach/AttachFile/attachFile.constants';
import { Icon, ICON_TYPES } from '@uikit/components/Icon';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { Loader } from '@uikit/components/Loader';
import {
  LOADER_POSITION,
  LOADER_SIZE,
  LOADER_TYPE,
} from '@uikit/components/Loader/Loader.constants';
import { FORMAT_TYPES, ICONS_BY_FILE_EXTENSIONS } from '@uikit/helpers/format.helpers';
import classes from './AttachIcon.module.scss';

type TAttachIcon = {
  type: FORMAT_TYPES | FILE_UPLOAD_STATUS;
};
export const AttachIcon: FC<TAttachIcon> = memo(
  ({ type }): JSX.Element => (
    <div className={cn(classes.attachIcon, classes[type])}>
      {type === FILE_UPLOAD_STATUS.LOADING ? (
        <Loader
          size={LOADER_SIZE.S}
          position={LOADER_POSITION.RELATIVE}
          type={LOADER_TYPE.ADDITIONAL}
        />
      ) : (
        <Icon
          type={ICONS_BY_FILE_EXTENSIONS[type as keyof Record<FORMAT_TYPES, ICON_TYPES>]}
          style={type === FILE_UPLOAD_STATUS.ERROR ? ICON_STYLES.NEGATIVE : ICON_STYLES.INVERTED}
          className={type === FORMAT_TYPES.DEFAULT ? classes.defaultIcon : ''}
        />
      )}
    </div>
  )
);
