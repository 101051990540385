/*
 * Контекст предоставляемый компонентом Filters.
 */
import { createContext, useContext } from 'react';
import { SmaPlatform } from '@hypetrainCommon';

export enum FILTERS_EMBEDDING_PLACES {
  SEARCH = 'search',
  CAMPAIGN = 'campaign',
}

type TFiltersContext = {
  platform: SmaPlatform;
  embeddingPlace: FILTERS_EMBEDDING_PLACES;
};

const FiltersContext = createContext<TFiltersContext>({
  platform: SmaPlatform.Youtube,
  embeddingPlace: FILTERS_EMBEDDING_PLACES.SEARCH,
});

export const FiltersProvider = FiltersContext.Provider;

export const useFilters = (): TFiltersContext => useContext(FiltersContext);
