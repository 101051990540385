/*
 * Service for executing users requests
 */
import { ApiService, apiService } from '@api';
import { EmailValidationResult } from '@hypetrainCommon';
import { STATUS_CODES } from '@api/api.enums';

/* eslint-disable arrow-body-style */
export class UsersApiService {
  constructor(private readonly api: ApiService) {}

  /*
   * Валидация email
   * @see: https://reference.api.rc-staging.hypetrain.io/#usercontroller_validateemail
   */
  public validateEmail = (email: string): Promise<EmailValidationResult> => {
    return this.api
      .get(
        `/users/validate-email`,
        { params: { email } },
        { showLoader: false, errorStatusesToIgnore: [STATUS_CODES.CONFLICT] }
      )
      .then((response) => response.data);
  };

  /*
   * Изменение согласия пользователя на маркетинговую рассылку
   * @see: https://reference.api.team-master-dev.hypetrain.io/?shell#usercontroller_setmarketingconsent
   */
  public setMarketingConsent = (consent: boolean): Promise<void> => {
    return this.api
      .post(`/users/marketing-consent`, { consent }, {}, { showLoader: false })
      .then((response) => response.data);
  };
}

export const usersApiService = new UsersApiService(apiService);
