/*
 * Дефолтный компонент опции в меню
 */
import cn from 'clsx';
import { FC } from 'react';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import styles from './MenuDefaultItem.module.scss';

export type TMenuDefaultItemProps = {
  id: string;
  text: string;
  textRightIcon?: string;
  action?: () => void;
  isExternalLink?: boolean;
  badge?: JSX.Element;
};

export const MenuDefaultItem: FC<TMenuDefaultItemProps> = ({
  id,
  text,
  textRightIcon,
  action,
  isExternalLink = false,
  badge,
}) => (
  <div
    id={id}
    className={cn(styles.menuDefaultItem, isExternalLink && styles.menuDefaultItem__external)}
    onClick={action}
  >
    <div className={styles.menuDefaultItem__leftBlock}>
      {text}
      {isExternalLink && (
        <Icon
          className={styles.menuDefaultItem__external__icon}
          type={ICON_TYPES.ARROW_UP_RIGHT}
          style={ICON_STYLES.SECONDARY}
        />
      )}
    </div>
    <div className={styles.menuDefaultItem__rightBlock}>
      {badge && <div className={styles.menuDefaultItem__rightBlock__badge}>{badge}</div>}
      {textRightIcon && (
        <img
          src={textRightIcon}
          alt="icon"
        />
      )}
    </div>
  </div>
);
