/*
 * Filters configuration for browse page.
 */
import {
  Age,
  DayNumber,
  Gender,
  SmaPlatform,
  YoutubeAudienceAgesFilter,
  YoutubeAudienceGendersFilter,
  YoutubeBrandsFilter,
  YoutubeEngagementRateFilter,
  YoutubeFilterName,
  YoutubeGamesFilter,
  YoutubeHasContactFilter,
  YoutubeHasVideosFilter,
  YoutubePriceReachFilter,
  YoutubeReachFilter,
  YoutubeSubscriberCountFilter,
} from '@hypetrainCommon';
import { FILTER_TYPES } from '@uikit/components/Filter';
import { TAsyncSelectFilterConfig } from '@uikit/components/Filter/components/AsyncSelectFilter';
import { $asyncSelectFilterSelectedOptionsStore } from '@uikit/components/Filter/components/AsyncSelectFilter/asyncSelectFilter.model';
import { TCheckboxFilterConfig } from '@uikit/components/Filter/components/CheckboxFilter';
import { TKeywordFilterConfig } from '@uikit/components/Filter/components/KeywordsFilter';
import { TRadioGroupFilterConfig } from '@uikit/components/Filter/components/RadioGroupFilter';
import {
  RANGE_TYPES,
  TRangeConfig,
} from '@uikit/components/Filter/components/RangeFilter/RangeFilter.types';
import { TSelectFilterConfig } from '@uikit/components/Filter/components/SelectFilter';
import { FilterMultiSelectOption } from '@uikit/components/Filter/components/SelectFilter/components/FilterMultiSelectOption';
import { TAvailableFilter } from '@uikit/components/Filters';
import { TFilter, TFilterDto } from '@uikit/components/Filters/filters.types';
import { stringToBoolean } from '@uikit/components/Filters/filters.utils';
import { TSelectOptions } from '@uikit/components/SelectField/SelectField.types';
import { explorationApiService } from '@api/exploration';
import i18n from '@services/i18n';
import { getCountryOptions } from '@constants/countries';
import { SOCIAL_NETWORK_OPTIONS } from '@constants/socialPlatform';
import { topicsKeys } from '@constants/topics';
import { getLanguagesFx } from '@models/languages';
import { GamesFilterOption } from '@components/GamesFilterOption';

const categoriesOptions: TSelectOptions = Object.entries(topicsKeys).map(
  ([topicCode, topicName]) => ({ value: topicCode, label: topicName })
);

const influencerssLocationFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: YoutubeFilterName.Country,
  filterType: FILTER_TYPES.SELECT,
  label: i18n.t('countryLabel'),
  popupTitle: i18n.t('channeBasedIn'),
  description: i18n.t('influencersLocationDescription'),
  isVisible: true,
  testId: 'YT_COUNTRY_FILTER',
  filterProps: {
    isMulti: true,
    placeholder: i18n.t('influencersLocationPlaceholder'),
    options: getCountryOptions(),
  },
};

const subcribersFilterConfig: TAvailableFilter<TRangeConfig> = {
  id: YoutubeFilterName.SubscriberCount,
  filterType: FILTER_TYPES.RANGE,
  label: i18n.t('subscriberCount'),
  isVisible: true,
  testId: 'YT_SUBSCRIBERS_FILTER',
  filterProps: {
    type: RANGE_TYPES.NUMBER,
    from: {
      placeholder: '3,000',
    },
    to: {
      placeholder: '10,000,000+',
    },
  },
  formatValueToDTO: (filter: TFilter): YoutubeSubscriberCountFilter => ({
    name: YoutubeFilterName.SubscriberCount,
    min: filter.min,
    max: filter.max,
  }),
};

const viewsByMonthFilterConfig: TAvailableFilter<TRangeConfig> = {
  id: YoutubeFilterName.Reach,
  filterType: FILTER_TYPES.RANGE,
  label: i18n.t('viewsByMonth'),
  description: i18n.t('viewsByMonthFilterDescription'),
  isVisible: true,
  testId: 'YT_VIEWS_FILTER',
  filterProps: {
    type: RANGE_TYPES.NUMBER,
    from: {
      placeholder: '3,000',
    },
    to: {
      placeholder: '10,000,000+',
    },
  },
  formatValueToDTO: (filter: TFilter): YoutubeReachFilter => ({
    name: YoutubeFilterName.Reach,
    min: filter.min,
    max: filter.max,
    value: DayNumber.Thirtieth,
  }),
};

const engagementRateFilterConfig: TAvailableFilter<TRangeConfig> = {
  id: YoutubeFilterName.EngagementRate,
  filterType: FILTER_TYPES.RANGE,
  label: i18n.t('engagementRate'),
  description: i18n.t('engagementRateFilterDescription'),
  isVisible: true,
  testId: 'YT_ER_FILTER',
  filterProps: {
    type: RANGE_TYPES.PERCENT,
    from: {
      placeholder: '0%',
      precision: 1,
    },
    to: {
      placeholder: '100%',
      precision: 1,
    },
  },
  formatValueToDTO: (filter: TFilter): YoutubeEngagementRateFilter => ({
    name: YoutubeFilterName.EngagementRate,
    min: filter.min,
    max: filter.max,
    value: DayNumber.Thirtieth,
  }),
};

const recommendedPriceFilterConfig: TAvailableFilter<TRangeConfig> = {
  id: YoutubeFilterName.PriceReach,
  filterType: FILTER_TYPES.RANGE,
  label: i18n.t('priceReach'),
  description: i18n.t('recommendedPriceFilterDescription'),
  isVisible: true,
  testId: 'YT_PRICE_FILTER',
  filterProps: {
    type: RANGE_TYPES.CURRENCY,
    from: {
      placeholder: '10',
    },
    to: {
      placeholder: '25,000+',
    },
  },
  formatValueToDTO: (filter: TFilter): YoutubePriceReachFilter => ({
    name: YoutubeFilterName.PriceReach,
    min: filter.min,
    max: filter.max,
  }),
};

const haveContactFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: YoutubeFilterName.HasContact,
  filterType: FILTER_TYPES.RADIO,
  label: i18n.t('hasEmail'),
  isVisible: true,
  testId: 'YT_HAS_CONTACT_FILTER',
  filterProps: {
    defaultValue: null,
    options: [
      {
        label: i18n.t('withEmail'),
        value: 'true',
      },
      {
        label: i18n.t('withoutEmail'),
        value: 'false',
      },
    ],
  },
  formatValueToDTO: (filter: TFilter): YoutubeHasContactFilter => ({
    name: YoutubeFilterName.HasContact,
    value: stringToBoolean(filter.values?.[0]),
  }),
};

const haveSocialLinksFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: YoutubeFilterName.HasSocialLinks,
  filterType: FILTER_TYPES.SELECT,
  label: i18n.t('filters.youtube.haveSocialLinks'),
  isVisible: true,
  testId: 'YT_HAS_SOCIAL_LINKS_FILTER',
  popupTitle: i18n.t('filters.youtube.haveSocialLinksTitle'),
  description: i18n.t('filters.youtube.socialLinksDescription'),
  filterProps: {
    isMulti: true,
    placeholder: i18n.t('filters.youtube.platformsPlaceholder'),
    options: SOCIAL_NETWORK_OPTIONS.filter((option) => option.value !== SmaPlatform.Youtube),
  },
};

const audienceCountryFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: YoutubeFilterName.AudienceCountries,
  filterType: FILTER_TYPES.SELECT,
  label: i18n.t('audienceLocations'),
  popupTitle: i18n.t('audienceCountriesInfoLabel'),
  isVisible: true,
  testId: 'YT_AUDIENCE_COUNTRY_FILTER',
  filterProps: {
    isMulti: true,
    placeholder: i18n.t('audienceLocationsPlaceholder'),
    options: getCountryOptions(),
    hasPercentage: true,
    percentageFirstLabel: i18n.t('countriesFirstLabel'),
    percentageLastLabel: i18n.t('youtubeCountriesSecondLabel'),
  },
  filterInitialValue: {
    name: YoutubeFilterName.AudienceCountries,
    min: 0.2,
    max: 1,
  },
  filterValue: {
    name: YoutubeFilterName.AudienceCountries,
    min: 0.2,
    max: 1,
  },
};

const categoriesFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: YoutubeFilterName.Topics,
  filterType: FILTER_TYPES.SELECT,
  label: i18n.t('categories'),
  popupTitle: i18n.t('categoriesLabel'),
  description: i18n.t('categoriesDescription'),
  isVisible: true,
  testId: 'YT_CATEGORIES_FILTER',
  filterProps: {
    isMulti: true,
    placeholder: i18n.t('categoriesPlaceholder'),
    options: categoriesOptions,
  },
};

const audienceGendersFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: YoutubeFilterName.AudienceGenders,
  filterType: FILTER_TYPES.RADIO,
  label: i18n.t('audienceGenders'),
  popupTitle: i18n.t('audienceGendersIs'),
  isVisible: true,
  testId: 'YT_AUDIENCE_GENDERS_FILTER',
  filterProps: {
    hasPercentage: true,
    defaultValue: null,
    options: [
      {
        label: i18n.t('filters.instagram.any'),
        value: null,
      },
      {
        label: i18n.t('male'),
        value: 'male',
      },
      {
        label: i18n.t('female'),
        value: 'female',
      },
    ],
  },
  filterInitialValue: {
    name: YoutubeFilterName.AudienceGenders,
    min: 0.6,
    max: 1,
  },
  filterValue: {
    name: YoutubeFilterName.AudienceGenders,
    min: 0.6,
    max: 1,
  },
  formatValueToDTO: (filter: TFilter): YoutubeAudienceGendersFilter => ({
    name: YoutubeFilterName.AudienceGenders,
    min: filter.min,
    max: filter.max,
    value: filter.values?.[0] as Gender,
  }),
};

const audienceAgesFilterConfig: TAvailableFilter<TCheckboxFilterConfig> = {
  id: YoutubeFilterName.AudienceAges,
  filterType: FILTER_TYPES.CHECKBOX_GROUP,
  label: i18n.t('audienceAges'),
  popupTitle: i18n.t('audienceAgeIs'),
  isVisible: true,
  testId: 'YT_AUDIENCE_AGES_FILTER',
  filterProps: {
    hasPercentage: true,
    isAge: true,
    options: {
      '13–17': false,
      '18–24': false,
      '25–34': false,
      '35–44': false,
      '45–54': false,
      '55+': false,
    },
  },
  filterInitialValue: {
    name: YoutubeFilterName.AudienceAges,
    min: 0.2,
    max: 1,
  },
  filterValue: {
    name: YoutubeFilterName.AudienceAges,
    min: 0.2,
    max: 1,
  },
  formatValueToDTO: (filter: TFilter): YoutubeAudienceAgesFilter => ({
    name: YoutubeFilterName.AudienceAges,
    min: filter.min,
    max: filter.max,
    value: filter.values?.[0] === '55-55+' ? Age.From55To55Plus : (filter.values?.[0] as Age),
  }),
};

const keywordsFilterConfig: TAvailableFilter<TKeywordFilterConfig> = {
  id: YoutubeFilterName.Keywords,
  filterType: FILTER_TYPES.KEYWORDS,
  label: i18n.t('keywords'),
  popupTitle: i18n.t('textFilterTitle'),
  description: i18n.t('keywordsDescription'),
  isVisible: true,
  testId: 'YT_KEYWORDS_FILTER',
  filterProps: {
    placeholder: i18n.t('enterKeywords'),
  },
};

const gamesFilterConfig: TAvailableFilter<TAsyncSelectFilterConfig> = {
  id: YoutubeFilterName.Games,
  filterType: FILTER_TYPES.ASYNC_SELECT,
  label: i18n.t('games'),
  popupTitle: i18n.t('gamesInfoLabel'),
  description: i18n.t('gamesDescription'),
  isVisible: true,
  filterPopupWidth: 323,
  testId: 'YT_GAMES_FILTER',
  filterProps: {
    isMulti: true,
    placeholder: i18n.t('enterGame'),
    loadOptions: async (key) => {
      if (key.length < 2) return [];
      const responseData = await explorationApiService.searchGames({ searchTerm: key });
      return responseData.games.map(({ thumbnail, uuid, releaseYear, title }) => ({
        thumbnail,
        value: uuid,
        label: releaseYear ? `${title} (${releaseYear})` : title,
      }));
    },
    optionComponent: GamesFilterOption,
    itemHeight: 65,
    needToReload: true,
    platform: SmaPlatform.Youtube,
  },
  formatValueToDTO: (filter): TFilterDto => {
    const asyncStoreFilters =
      $asyncSelectFilterSelectedOptionsStore.getState()[SmaPlatform.Youtube][filter.name];
    const requestData = filter.values?.map((gameId) =>
      asyncStoreFilters.find((val) => val.value === gameId)
    );
    return {
      name: filter.name,
      values: filter.values,
      meta: {
        [filter.name]: requestData,
      },
    } as YoutubeGamesFilter;
  },
};

const brandsFilterConfig: TAvailableFilter<TAsyncSelectFilterConfig> = {
  id: YoutubeFilterName.Brands,
  filterType: FILTER_TYPES.ASYNC_SELECT,
  label: i18n.t('brandMentions'),
  popupTitle: i18n.t('brandMentionsTitle'),
  description: i18n.t('brandsDescription'),
  isVisible: true,
  testId: 'YT_BRANDS_FILTER',
  filterProps: {
    isMulti: true,
    placeholder: i18n.t('brandMentionsPlaceholder'),
    loadOptions: async (key) => {
      if (key.length < 2) return [];
      const responseData = await explorationApiService.searchBrands({ searchTerm: key });
      return responseData.brands.map(({ uuid, name }) => ({
        value: uuid,
        label: name,
      }));
    },
    needToReload: true,
    optionComponent: FilterMultiSelectOption,
    platform: SmaPlatform.Youtube,
  },
  formatValueToDTO: (filter): TFilterDto => {
    const asyncStoreFilters =
      $asyncSelectFilterSelectedOptionsStore.getState()[SmaPlatform.Youtube][filter.name];
    const requestData = filter.values?.map((brandId) =>
      asyncStoreFilters.find((val) => val.value === brandId)
    );
    return {
      name: filter.name,
      values: filter.values,
      meta: {
        [filter.name]: requestData,
      },
    } as YoutubeBrandsFilter;
  },
};

const hasVideosFilterConfig: TAvailableFilter = {
  id: YoutubeFilterName.HasVideos,
  filterType: FILTER_TYPES.TOGGLE,
  label: i18n.t('hasVideosLabel'),
  isVisible: true,
  testId: 'YT_HAS_VIDEOS_FILTER',
  formatValueToDTO: (filter: TFilter): YoutubeHasVideosFilter => ({
    name: filter.name as YoutubeFilterName.HasVideos,
    value: stringToBoolean(filter.values?.[0]),
  }),
};

const influencerLanguageFilterConfig: TAvailableFilter = {
  id: YoutubeFilterName.Language,
  filterType: FILTER_TYPES.ASYNC_SELECT,
  label: i18n.t('filters.instagram.influencerLanguage'),
  popupTitle: i18n.t('filters.instagram.influencerLanguageLabel'),
  isVisible: true,
  testId: 'IG_LANGUAGE_FILTER',
  filterProps: {
    isMulti: false,
    placeholder: i18n.t('filters.instagram.influencerLanguagePlaceholder'),
    loadOptions: async () => {
      const responseData = await getLanguagesFx();
      return responseData?.map(({ name, code }) => ({
        value: code,
        label: name,
      }));
    },
    platform: SmaPlatform.Youtube,
  },
};

export const BROWSE_PAGE_AVAILABLE_FILTERS_CONFIG: TAvailableFilter[] = [
  influencerssLocationFilterConfig,
  influencerLanguageFilterConfig,
  subcribersFilterConfig,
  viewsByMonthFilterConfig,
  engagementRateFilterConfig,
  recommendedPriceFilterConfig,
  haveContactFilterConfig,
  haveSocialLinksFilterConfig,
  audienceCountryFilterConfig,
  audienceGendersFilterConfig,
  audienceAgesFilterConfig,
  keywordsFilterConfig,
  categoriesFilterConfig,
  gamesFilterConfig,
  brandsFilterConfig,
  hasVideosFilterConfig,
];
