/**
 * A provider that provides access to the app error handler.
 */
import { ErrorInfo, createContext } from 'react';

export type TErrorHandlingContext = {
  handler: (errorLayerName: string, error: Error, errorInfo: ErrorInfo) => void;
};

export const ErrorHandlingContext = createContext<TErrorHandlingContext>(
  {} as TErrorHandlingContext
);
export const ErrorHandlingProvider = ErrorHandlingContext.Provider;
