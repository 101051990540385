/*
 * Service for executing requests for entities in address book.
 */
import { ApiService, apiService } from '@api';
import {
  ICheckContactHandlerRequestDto,
  ICreateRepresentativePersonRequestDto,
  ICreateWorkspaceInvitesRequestDto,
  IPersonInfoResponseDto,
  IPersonSimpleInfoDto,
} from '@hypetrainCommon';
import { SEATS_LIMIT_EXCEEDED } from '@api/addressBook/addressBook.api.constants';
import {
  TAddressBookContent,
  TChangePersonStatusParams,
  TGetWorkspacesPersonsByPartyTypeParams,
  TUpdatePersonParams,
} from '@api/addressBook/addressBook.api.types';
import { STATUS_CODES } from '@api/api.enums';

/* eslint-disable arrow-body-style */
export class AddressBookApiService {
  get activeWorkspaceId(): string {
    return this.api.activeWorkspace?.id || '';
  }

  constructor(private readonly api: ApiService) {}

  /*
   * Получение активных персон в address book для активного workspace.
   * @see: https://reference.api.rc-staging.hypetrain.io/#get-workspaces-active-persons
   */
  public getWorkspacesActivePersons = (): Promise<IPersonSimpleInfoDto[]> => {
    return this.api
      .get(
        `/workspaces/${this.activeWorkspaceId}/address-book/persons/simple-list/active`,
        {},
        {
          showLoader: false,
        }
      )
      .then((response) => response.data);
  };

  /*
   * Проверяет email контанта в address book.
   * @see: https://reference.api.rc-staging.hypetrain.io/#check-contact-handler
   */
  public checkContactHandler = (body: ICheckContactHandlerRequestDto): Promise<undefined> => {
    return this.api
      .post(
        `/workspaces/${this.activeWorkspaceId}/address-book/contacts/check`,
        body,
        {},
        {
          showLoader: false,
          errorStatusesToIgnore: [STATUS_CODES.CONFLICT],
        }
      )
      .then((response) => response.data);
  };

  /*
   * Создаёт новый контакт в address book.
   * @see: https://reference.api.rc-staging.hypetrain.io/#create-representative-person
   */
  public createRepresentativePerson = (
    contactInfo: ICreateRepresentativePersonRequestDto
  ): Promise<IPersonInfoResponseDto> => {
    return this.api
      .post(
        `/workspaces/${this.activeWorkspaceId}/address-book/persons/representative`,
        contactInfo
      )
      .then((response) => response.data);
  };

  /*
   * Обновляет контакт в address book.
   * @see: https://reference.api.rc-staging.hypetrain.io/#update-person
   */
  public updatePerson = ({
    personId,
    personInfo,
  }: TUpdatePersonParams): Promise<IPersonInfoResponseDto> => {
    return this.api
      .patch(`/workspaces/${this.activeWorkspaceId}/address-book/persons/${personId}`, personInfo)
      .then((response) => response.data);
  };

  /*
   * Создаёт приглашение в workspace.
   * @see: https://reference.api.rc-staging.hypetrain.io/#create-workspace-invite
   */
  public createWorkspaceInvite = (body: ICreateWorkspaceInvitesRequestDto): Promise<undefined> => {
    return this.api
      .post(`/workspaces/${this.activeWorkspaceId}/invites`, body)
      .then((response) => response.data);
  };

  /*
   * Обновляет статус контакта в address book.
   * @see: https://reference.api.rc-staging.hypetrain.io/#change-person-status
   */
  public changePersonStatus = ({
    personId,
    body,
  }: TChangePersonStatusParams): Promise<IPersonInfoResponseDto> => {
    return this.api
      .put(
        `/workspaces/${this.activeWorkspaceId}/address-book/persons/${personId}/status`,
        body,
        undefined,
        {
          errorCodesToIgnore: [SEATS_LIMIT_EXCEEDED],
        }
      )
      .then((response) => response.data);
  };

  /*
   * Удаляет контакт из address book.
   * @see: https://reference.api.rc-staging.hypetrain.io/#delete-person
   */
  public deletePerson = (personId: string): Promise<IPersonInfoResponseDto> => {
    return this.api
      .deleteMethod(`/workspaces/${this.activeWorkspaceId}/address-book/persons/${personId}`)
      .then((response) => response.data);
  };

  /*
   * Запрашивает контакты из address book по party type.
   * @see: https://reference.api.rc-staging.hypetrain.io/#get-workspaces-persons-by-party-type
   */
  public getWorkspacesPersonsByPartyType = ({
    partyType,
    queryParams,
  }: TGetWorkspacesPersonsByPartyTypeParams): Promise<TAddressBookContent> => {
    return this.api
      .get(`/workspaces/${this.activeWorkspaceId}/address-book/persons/${partyType}`, {
        params: queryParams,
      })
      .then((response) => response.data);
  };
}

export const addressBookApiService = new AddressBookApiService(apiService);
