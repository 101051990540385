/*
 * Component of tooltip.
 */
import { FC } from 'react';
import { Popup } from '@uikit/components/Popup';
import { PopupStyles } from '@uikit/components/Popup/popup.enum';
import { TPopupProps } from '@uikit/components/Popup/popup.types';
import { TooltipContainer } from './components/TooltipContainer';
import styles from './Tooltip.module.scss';

type TTooltipProps = TPopupProps;

export const Tooltip: FC<TTooltipProps> = ({ content, children, ...props }) => (
  <Popup
    content={<TooltipContainer position={props.position}>{content}</TooltipContainer>}
    styles={PopupStyles.None}
    showClassName={styles.tooltip}
    {...props}
  >
    {children}
  </Popup>
);
