import { useStore } from 'effector-react/compat';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IPersonWorkspaceResponseDto } from '@hypetrainCommon';
import { Routes } from '@router/Routes';
import { $workspacesStore } from '@models/workspaces/workspaces.model';
import { TMenuItemProps } from '@components/Background/components/NavigationPanel/components/MenuWrapper/MenuWrapper';
import { WorkspaceMenuHeader } from '@components/Background/components/NavigationPanel/components/WorkspacesMenu/components/WorkspaceMenuHeader';
import { WorkspaceMenuItem } from '@components/Background/components/NavigationPanel/components/WorkspacesMenu/components/WorkspaceMenuItem';
import { MenuWrapper } from '../MenuWrapper';

export const WorkspacesMenu: FC = memo(() => {
  const navigate = useNavigate();
  const workspaces = useStore($workspacesStore);

  const redirect = (): void => navigate(Routes.workspaceCreation);

  const header = (
    <WorkspaceMenuHeader
      showCreateButton={false}
      onButtonClick={redirect}
    />
  );

  const menuItems: TMenuItemProps<IPersonWorkspaceResponseDto>[] =
    workspaces?.active?.map((workspace) => ({
      ...workspace,
      id: workspace?.workspace?.id,
    })) || [];

  return (
    <MenuWrapper
      header={header}
      menuItemComponent={WorkspaceMenuItem}
      menuItems={menuItems}
    />
  );
});
