import cn from 'clsx';
import { FC } from 'react';
import styles from './Divider.module.scss';

type TDivider = {
  className?: string;
};

export const Divider: FC<TDivider> = ({ className }) => (
  <div className={cn(styles.divider, className)} />
);
