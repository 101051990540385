/*
 * Campaigns list.
 */
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICampaignListItemDto } from '@hypetrainCommon';
import { BorderedScrollableContainer } from '@uikit/components/BorderedScrollableContainer';
import { campaignsUserAccessService } from '@services/userAccess';
import { CampaignsSearchListItem } from '../CampaignsSearchListItem';
import styles from './CampaignsSearchList.module.scss';

type TCampaignsSearchListProps = {
  campaigns: ICampaignListItemDto[];
  selected?: ICampaignListItemDto;
  onSelect?: (campaign: ICampaignListItemDto) => void;
  className?: string;
  onCreateNewCampaign?: () => void;
};

export const CampaignsSearchList: FC<TCampaignsSearchListProps> = memo(
  ({ campaigns, selected, onSelect, className, onCreateNewCampaign }) => {
    const { t } = useTranslation();

    return (
      <BorderedScrollableContainer className={className}>
        {campaignsUserAccessService.createCampaign && (
          <div
            onClick={onCreateNewCampaign}
            className={styles.campaignsSearchList}
          >
            {t('campaignsSearch.createCampaign')}
          </div>
        )}
        {campaigns?.map((campaign, i) => (
          <CampaignsSearchListItem
            key={campaign?.id}
            campaign={campaign}
            onClick={onSelect}
            isSelected={campaign?.id === selected?.id}
            isLast={i === campaigns.length - 1}
          />
        ))}
      </BorderedScrollableContainer>
    );
  }
);
