/*
 * Компонент с 2 селектами
 */
import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TRangeConfig,
  TRangeValue,
} from '@uikit/components/Filter/components/RangeFilter/RangeFilter.types';
import { SelectField } from '@uikit/components/SelectField';
import { noop } from '@uikit/helpers/common.helpers';
import styles from './RangeSelect.module.scss';

type TRangeSelectProps = {
  value?: TRangeValue;
  config: TRangeConfig;
  error?: string;
  onChange: (value: TRangeValue) => void;
};

export const RangeSelect: FC<TRangeSelectProps> = memo(({ value, config, error, onChange }) => {
  const { t } = useTranslation();

  const fromOptions = useMemo(() => {
    if (!value?.to) return config.fromOptions;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return config.fromOptions?.filter((option) => Number(option.value) <= value.to!);
  }, [value]);

  const toOptions = useMemo(() => {
    if (!value?.from) return config.toOptions;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return config.toOptions?.filter((option) => Number(option.value) >= value.from!);
  }, [value]);

  const handleChange = (inputValue: number, key: string): void => {
    onChange({ ...(value || {}), [key]: inputValue });
  };

  return (
    <>
      <div className={styles.rangeInput}>
        <SelectField
          input={{
            name: 'selectFrom',
            onFocus: noop,
            onBlur: noop,
            onChange: (inputValue) => handleChange(inputValue, 'from'),
            value: value?.from || '',
          }}
          valuePath="value"
          viewValuePath="label"
          label={t('from')}
          placeholder={config.from.placeholder}
          isClearable
          isMulti={false}
          options={fromOptions}
          hideError
        />

        <SelectField
          input={{
            name: 'selectTo',
            onFocus: noop,
            onBlur: noop,
            onChange: (inputValue) => handleChange(inputValue, 'to'),
            value: value?.to || '',
          }}
          valuePath="value"
          viewValuePath="label"
          label={t('to')}
          placeholder={config.to.placeholder}
          isClearable
          isMulti={false}
          options={toOptions}
          hideError
        />
      </div>
      <div className={styles.rangeInput__error}>{error}</div>
    </>
  );
});
