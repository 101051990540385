/*
 * Компонент ячейки таблицы с действиями.
 */
import { FC, memo, useState } from 'react';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '@uikit/components/Buttons';
import { ICON_TYPES } from '@uikit/components/Icon';
import { PopupEvents, PopupPositions } from '@uikit/components/Popup';
import { useTableRow } from '@uikit/components/Table/components/TableRow';
import { TTooltipActionMenuItem, Tooltip, TooltipActionMenu } from '@uikit/components/Tooltip';
import styles from './ActionsCell.module.scss';

type TActionsCellProps = {
  actions: TTooltipActionMenuItem[];
};

export const ActionsCell: FC<TActionsCellProps> = memo(({ actions }) => {
  const rowContext = useTableRow();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return actions.length && (rowContext?.isHovered || isMenuOpen) ? (
    <Tooltip
      className={styles.actionsCell}
      content={<TooltipActionMenu actions={actions} />}
      position={PopupPositions.LeftCenter}
      event={PopupEvents.Click}
      offset={8}
      onClose={() => setIsMenuOpen(false)}
      onOpen={() => setIsMenuOpen(true)}
    >
      <Button
        buttonType={BUTTON_TYPE.SECONDARY}
        icon={ICON_TYPES.TRIPLE_DOTS}
        size={BUTTON_SIZE.S}
        className={styles.actionsCell__button}
      />
    </Tooltip>
  ) : null;
});
