/*
 * Компонент вкладки исходящих транзакций по платёжному счёту.
 */
import { useStore } from 'effector-react/compat';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ITransactionDto } from '@hypetrainCommon';
import { Table } from '@uikit/components/Table';
import {
  $outgoingPaymentAccountTransactions,
  getPaymentAccountTransactionsFx,
} from '@pagesPaymentsPage/paymentsAccounts.model';
import { paymentAccountTransactionsColumns } from '../../paymentAccountTransactions.columns';
import { PaymentAccounNotingFound } from '../PaymentAccounNotingFound';
import styles from './PaymentAccountOutgoingTab.module.scss';

export const PaymentAccountOutgoingTab: FC = memo(() => {
  const { t } = useTranslation();
  const isLoading = useStore(getPaymentAccountTransactionsFx.pending);
  const transactions = useStore($outgoingPaymentAccountTransactions);

  return (
    <div className={styles.paymentAccountOutgoingTab}>
      <Table
        data={transactions}
        columns={paymentAccountTransactionsColumns}
        getSubRows={(transaction: ITransactionDto) => transaction.transfers}
        isLoading={isLoading}
        emptyTable={
          <PaymentAccounNotingFound
            title={t('paymentAccountOutgoingTab.nothingFoundTitle')}
            description={t('paymentAccountOutgoingTab.nothingFoundDescription')}
          />
        }
        className={styles.paymentAccountOutgoingTab__table}
      />
    </div>
  );
});
