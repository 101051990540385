/*
 * Компонент скелетона для профилей инфлюенсеров.
 */
import { FC } from 'react';
import profileSkeleton from '@assets/images/profile/peofileSkeleton.svg';
import styles from './ProfileSkeleton.module.scss';

export const ProfileSkeleton: FC = () => (
  <div className={styles.profileSkeleton}>
    <img
      className={styles.profileSkeleton__img}
      src={profileSkeleton}
      alt="Loading..."
    />
    <div className={styles.profileSkeleton__animationElem} />
  </div>
);
