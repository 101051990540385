/*
 * Back button (animated) component.
 */
import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import styles from './BackButton.module.scss';

type TBackButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  isButtonWithoutText?: boolean;
};

export const BackButton: FC<PropsWithChildren<TBackButtonProps>> = ({
  disabled,
  onClick,
  className,
  children,
  isButtonWithoutText,
}) => {
  const { t } = useUiKitTranslation();

  return (
    <button
      className={cn(styles.backButton, isButtonWithoutText && styles.backButton_fixSize, className)}
      disabled={disabled}
      onClick={onClick}
    >
      <Icon
        type={ICON_TYPES.BACK}
        className={cn(styles.backButton__icon, disabled && styles.backButton__iconDisabled)}
      />
      {!isButtonWithoutText && (
        <div className={styles.backButton__text}>
          <div className={styles.backButton__textContainer}>
            {children || t('backButton.title')}
          </div>
        </div>
      )}
    </button>
  );
};
