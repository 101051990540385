/*
 * Component-container for placing overlays in app.
 */
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { overlaysStore } from '@uikit/services/overlays';

export const OverlaysContainer: FC = observer(() => {
  const { overlays } = overlaysStore;

  return (
    <>
      {overlays.map(({ component: Component, props, id }) => (
        <Component
          key={id}
          {...toJS(props)}
        />
      ))}
    </>
  );
});
