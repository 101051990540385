import { FC, useMemo } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { SmaAudienceCountryValue, SmaAudienceLanguageValue } from '@hypetrainCommon';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { getAudienceGraphicColor } from '@components/AudienceLocation/utils';

type TSize = {
  width: number;
  height: number;
};

type TPieAudienceGraphicProps = {
  isProfile?: boolean;
  values: SmaAudienceCountryValue[] | SmaAudienceLanguageValue[];
  filterValues?: TFilter;
  graphicData: SmaAudienceCountryValue[][] | SmaAudienceLanguageValue[][];
  colorsMap: Record<string, string>;
  uuid: string;
};

const defaultGraphicPieSize: TSize = {
  width: 64,
  height: 64,
};

const profileGraphicPieSize: TSize = {
  width: 100,
  height: 100,
};

const fullCircle = 360;

// Алгоритм построения кругового графика. Существует 2 варианта:
// 1) Если у нас выбран фильтр, то мы строим в 2 этапа. Сперва мы отрисовываем
//    жирно и выпирающе отфильтрованные сектора. Затем мы достраиваем остальные.
// 2) Если у нас не выбран фильтр, то мы просто строим один целым график.

export const PieAudienceGraphic: FC<TPieAudienceGraphicProps> = ({
  isProfile = false,
  values,
  filterValues,
  graphicData,
  colorsMap,
  uuid,
}) => {
  const size: TSize = isProfile ? profileGraphicPieSize : defaultGraphicPieSize;

  // Считаем с какого угла начинаем/заканчиваем строить если есть Audience Location
  // Сумма значений выбранных стран * 360 = тот самый
  const angle = useMemo(() => {
    const target = values
      // @ts-ignore TODO Женя: разобраться что за target, откуда появляется в этой модели
      ?.filter((el: SmaAudienceCountryValue) => el?.target)
      ?.reduce((acc: number, cur: SmaAudienceCountryValue) => acc + (cur?.value || 0), 0);
    return target * fullCircle;
  }, [values]);

  const getInnerRadius = (): number => {
    if (isProfile) return 20;
    return size.width === 160 ? 30 : 13;
  };

  // Получаем углы для целого графика: старт, конец, внутренний радиус(отступ)
  // Если у нас есть фильтр, то нам надо получить 'Дельту'
  // Которая нам скажет где заканчиваются выбранные в фильтре сектора и
  // Начинаются остальные
  const getAngles = (index: number) => {
    const innerRadius = getInnerRadius();
    if (filterValues?.values) {
      return index === 0
        ? {
            startAngle: 90,
            endAngle: 90 + angle,
            innerRadius: innerRadius * 0.7,
          }
        : {
            startAngle: 90 + angle,
            endAngle: 450,
            innerRadius,
          };
    }
    return {
      startAngle: 90,
      endAngle: 450,
      innerRadius,
    };
  };

  return (
    <PieChart {...size}>
      {graphicData?.map((items, index) => (
        <Pie
          // eslint-disable-next-line react/no-array-index-key
          key={`${uuid}_${index}`}
          {...getAngles(index)}
          outerRadius={size.width / 2}
          data={items}
          isAnimationActive={false}
          dataKey="value"
          stroke=""
        >
          {items?.map((entry) => (
            <Cell
              key={`cell-${entry?.key}`}
              fill={getAudienceGraphicColor(entry?.key, colorsMap[entry?.key], filterValues)}
            />
          ))}
        </Pie>
      ))}
    </PieChart>
  );
};
