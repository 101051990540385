/*
 * Checkbox group component.
 * For create checkbox group, pass an arbitrary number of <Checkbox /> in children.
 */
import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import styles from './CheckboxGroup.module.scss';
import { CheckboxGroupProvider } from './checkboxGroup.context';
import { TCheckboxGroupValues } from './checkboxGroup.types';

type TCheckboxGroupProps = PropsWithChildren<{
  value: TCheckboxGroupValues; // group state
  onChange: (checkboxGroupValue: TCheckboxGroupValues) => void; // cb on change one of checkboxes state
  className?: string;
}>;

export const CheckboxGroup: FC<TCheckboxGroupProps> = ({
  value,
  onChange,
  className,
  children,
}) => {
  const onChangeCheckboxGroup = (checkboxName: string, isChecked: boolean): void => {
    onChange({
      ...value,
      [checkboxName]: isChecked,
    });
  };

  return (
    <CheckboxGroupProvider
      value={{
        checkboxGroupValue: value,
        onChangeCheckboxGroup,
      }}
    >
      <fieldset className={cn(styles.checkboxGroup, className)}>{children}</fieldset>
    </CheckboxGroupProvider>
  );
};
