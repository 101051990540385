// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paymentAccounNotingFound__emptyTable__ufnrh{position:unset;height:unset}.paymentAccounNotingFound__emptyTableContainer__m91OW{display:flex;flex-grow:1}", "",{"version":3,"sources":["webpack://./pages/PaymentsPage/components/PaymentAccountPage/components/PaymentAccounNotingFound/PaymentAccounNotingFound.module.scss"],"names":[],"mappings":"AAGE,6CACE,cAAA,CACA,YAAA,CAGF,sDACE,YAAA,CACA,WAAA","sourcesContent":["@import '../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.paymentAccounNotingFound {\n  &__emptyTable {\n    position: unset;\n    height: unset;\n  }\n\n  &__emptyTableContainer {\n    display: flex;\n    flex-grow: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentAccounNotingFound__emptyTable": "paymentAccounNotingFound__emptyTable__ufnrh",
	"paymentAccounNotingFound__emptyTableContainer": "paymentAccounNotingFound__emptyTableContainer__m91OW"
};
export default ___CSS_LOADER_EXPORT___;
