/*
 * Radio group filter component
 */
import cn from 'clsx';
import { FC, memo, useEffect, useState } from 'react';
import { Filter, TFilterConfig } from '@uikit/components/Filter';
import { Percentage } from '@uikit/components/Filter/components/Percentage';
import {
  prepareViewValueWithPercents,
  transformDtoToFilterModel,
  transformFilterModelToDto,
} from '@uikit/components/Filter/filter.utils';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { Radio, RadioGroup } from '@uikit/components/RadioGroup';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import styles from './RadioGroupFilter.module.scss';

export type TRadioGroupFilterConfig = {
  options: { label: string; value: null | string; tooltip?: string }[];
  defaultValue: null | string;
  hasPercentage?: boolean;
};

type TRadioGroupFilterProps = {
  config: TFilterConfig<TRadioGroupFilterConfig>;
  onApply: (value: TFilter) => void;
  applyOnClose: boolean;
};

export const RadioGroupFilter: FC<TRadioGroupFilterProps> = memo(
  ({ config, onApply, applyOnClose }) => {
    const { t } = useUiKitTranslation();

    const [filterValue, setFilterValue] = useState<TFilter>(
      transformDtoToFilterModel(config.filterValue, config.filterProps.hasPercentage)
    );

    useEffect(() => {
      setFilterValue(
        transformDtoToFilterModel(config.filterValue, config.filterProps.hasPercentage)
      );
    }, [config.filterValue]);

    const onApplyHandler = (value: TFilter) =>
      onApply(transformFilterModelToDto(value, config.filterProps.hasPercentage));

    const onClearCallback = () => {
      setFilterValue(
        transformDtoToFilterModel(config.filterInitialValue, config.filterProps.hasPercentage)
      );
      onApply(config.filterInitialValue);
    };

    const prepareViewValue = () => {
      // для радиокнопок в config.filterValue.values лежит всегда одно value, например ['true']
      // ищем в списке опций опцию с подходящим value
      const selectedOption = config.filterProps.options.find(
        (option) => option.value === config.filterValue.values?.[0]
      );
      // если ничего не нашли - выходим
      if (!selectedOption) return;
      // если нашли то формируем viewValue
      return config.filterProps.hasPercentage
        ? prepareViewValueWithPercents(selectedOption.label, config.filterValue.min as number)
        : selectedOption.label;
    };

    const onRadioChangeHandler = (value: string): void => {
      setFilterValue(
        value
          ? { ...filterValue, values: [value] }
          : transformDtoToFilterModel(config.filterInitialValue, config.filterProps.hasPercentage)
      );
    };

    return (
      <Filter
        config={config}
        viewValue={prepareViewValue()}
        onClear={onClearCallback}
        onApply={() => onApplyHandler(filterValue)}
        applyOnClose={applyOnClose}
      >
        <>
          <RadioGroup
            value={filterValue.values?.[0] || config.filterProps.defaultValue}
            className={cn(
              styles.radioGroupFilter__radioGroup,
              config.filterProps.hasPercentage && styles.radioGroupFilter__radioGroup_withPercentage
            )}
            onChange={onRadioChangeHandler}
          >
            {config.filterProps.options.map((option) => (
              <Radio
                key={option.value}
                value={option.value}
                label={option.label}
                tooltip={option.tooltip}
              />
            ))}
          </RadioGroup>
          {config.filterProps.hasPercentage && (
            <Percentage
              firstText={t('radioGroupFilter.atLeast')}
              isDisabled={!filterValue.values?.[0]}
              onChange={(value) => setFilterValue({ ...filterValue, min: value })}
              value={Number(filterValue.min)}
            />
          )}
        </>
      </Filter>
    );
  }
);
