/*
 * Simple select field component.
 */
import cn from 'clsx';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import { FC, memo, useMemo, useState } from 'react';
import Select, { ActionMeta, InputActionMeta, OnChangeValue, OptionProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import { GroupHeader } from '@uikit/components/SelectField/components/GroupHeader';
import { SelectClearIndicator } from '@uikit/components/SelectField/components/SelectClearIndicator';
import { SelectFieldOption } from '@uikit/components/SelectField/components/SelectFieldOption';
import { SelectFieldValue } from '@uikit/components/SelectField/components/SelectFieldValue';
import { SelectFieldProvider } from '@uikit/components/SelectField/selectField.context';
import { UnderlineTooltip } from '@uikit/components/UnderlineTooltip';
import { APP_PORTAL_CONTAINER_ID } from '@uikit/constants/common.constants';
import { useStateIfMounted } from '@uikit/hooks/useStateIfMounted.hooks';
import { SelectFieildDropdownIndicator } from './components/SelectFieildDropdownIndicator';
import { SelectFieldDropdown } from './components/SelectFieldDropdown';
import { SelectFieldLoadingIndicator } from './components/SelectFieldLoadingIndicator';
import { SelectFieldMenuList } from './components/SelectFieldMenuList';
import styles from './SelectField.module.scss';
import {
  TSelectFieldLoadOptionsFn,
  TSelectFieldProps,
  TSelectLoadOptions,
  TSelectOption,
} from './SelectField.types';
import { isGroupSelectItem } from './SelectField.utils';
import { getSelectFieldStyles } from './styles';

const SELECT_FIELD_LOAD_DEBOUNCE_TIME = 500;
const SINGLE_OPTION_HEIGHT = 32;
export const DEFAULT_ITEM_HEIGHT = 47;

// TODO: Становится сложно. Переписать на MVC (mobX)
export const SelectField: FC<TSelectFieldProps> = memo(
  ({
    input,
    label,
    error,
    placeholder,
    valuePath,
    viewValuePath,
    optionComponent = SelectFieldOption,
    selectValueComponent = SelectFieldValue,
    options = [],
    loadOptions,
    autocomplete = false,
    hideError = false,
    className,
    isMulti,
    controlShouldRenderValue,
    closeMenuOnSelect,
    hideSelectedOptions,
    isClearable,
    defaultOptions = true,
    needToReload = false,
    itemHeight = DEFAULT_ITEM_HEIGHT,
    descriptionText,
    clearInputOnMenuClose = false,
    underlineTooltip,
    selectListAction,
    initialValueComponent,
    isDisabled,
  }) => {
    const isAsync = !!loadOptions;
    const [asyncOptions, setAsyncOptions] = useStateIfMounted<unknown[]>([]);
    const [filterValue, setFilterValue] = useState<string>('');

    const value = useMemo(() => {
      if (isMulti) return input?.value;

      let selectedOptions = isAsync ? asyncOptions : options;

      if (isGroupSelectItem(selectedOptions) && valuePath) {
        selectedOptions =
          selectedOptions?.find((el) =>
            el?.options?.find((opt) => _get(opt, valuePath) === input?.value)
          )?.options || selectedOptions;
      }

      return valuePath && input?.value
        ? selectedOptions?.find((option) => _get(option, valuePath) === input?.value)
        : input?.value;
    }, [input?.value, options, valuePath, asyncOptions]);

    const portalContainer = useMemo(() => document.getElementById(APP_PORTAL_CONTAINER_ID), []);

    const getOptionValue = (option: unknown): string =>
      valuePath ? _get(option, valuePath, null) : option;

    const getOptionLabel = (option: unknown): string =>
      viewValuePath ? _get(option, viewValuePath, null) : option;

    const onChange = (
      newValue: OnChangeValue<unknown, false>,
      actionMeta: ActionMeta<unknown>
    ): void => {
      // pop-value - событие возникающее при развыборе опции мультиселекта через Backspace в инпуте
      // так как выбранные опции находятся в чипсах снаружи селекта, предотвращаем такое удаление
      if (actionMeta.action === 'pop-value') return;

      if (isMulti) {
        input?.onChange(newValue);
        return;
      }

      valuePath ? input?.onChange(_get(newValue, valuePath, null)) : input?.onChange(newValue);
    };

    const loadOptionsWrapper: TSelectFieldLoadOptionsFn = async (inputValue, callback) => {
      if (asyncOptions.length && !needToReload) {
        // при вводе в инпут не перезапрашиваем заново данные и фильтруем список локально
        const stringCompare = (item: TSelectOption) =>
          getOptionLabel(item).toString().toLowerCase().startsWith(inputValue.trim().toLowerCase());

        callback(asyncOptions.filter(stringCompare));
        return;
      }
      const result = await (loadOptions as TSelectLoadOptions)(inputValue);

      setAsyncOptions(result);
      callback(result);
    };

    const onInputChange = (newValue: string, actionMeta: InputActionMeta): string => {
      // предотвращаем очистку инпута при выборе опции
      if (
        actionMeta.action === 'input-change' ||
        (clearInputOnMenuClose && actionMeta.action === 'menu-close')
      ) {
        setFilterValue(newValue);
        return newValue;
      }
      return filterValue;
    };

    return (
      <SelectFieldProvider
        value={{
          selectItemHeight: itemHeight,
          singleOptionHeight: SINGLE_OPTION_HEIGHT,
        }}
      >
        <div className={cn(styles.selectField, className)}>
          <div className={styles.selectField__topText}>
            <div className={styles.selectField__label}>
              {underlineTooltip ? (
                <UnderlineTooltip tooltipText={underlineTooltip}>{label}</UnderlineTooltip>
              ) : (
                label
              )}
            </div>
          </div>
          {!!descriptionText && (
            <div className={styles.selectField__descriptionText}>{descriptionText}</div>
          )}
          {isAsync ? (
            <AsyncSelect
              {...input}
              value={value}
              onChange={onChange}
              inputValue={filterValue}
              onInputChange={onInputChange}
              options={options}
              styles={getSelectFieldStyles(!!error)}
              placeholder={placeholder}
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              menuPortalTarget={portalContainer}
              isSearchable={autocomplete}
              menuPlacement="auto"
              menuPosition="fixed"
              components={{
                ClearIndicator: SelectClearIndicator,
                Option: optionComponent as FC<OptionProps>,
                SingleValue: selectValueComponent,
                Menu: SelectFieldDropdown,
                MenuList: SelectFieldMenuList,
                LoadingIndicator: SelectFieldLoadingIndicator,
                DropdownIndicator: SelectFieildDropdownIndicator,
              }}
              loadOptions={
                _debounce(
                  loadOptionsWrapper,
                  SELECT_FIELD_LOAD_DEBOUNCE_TIME
                ) as TSelectFieldLoadOptionsFn
              }
              cacheOptions
              defaultOptions={defaultOptions}
              isMulti={isMulti}
              isClearable={isClearable}
              closeMenuOnSelect={closeMenuOnSelect}
              // Хак, чтобы попап не закрывался при скролле внутри попапа
              closeMenuOnScroll={(e: Event) =>
                !(e?.target as Element)?.classList?.toString().includes('selectFieldMenuList')
              }
              hideSelectedOptions={hideSelectedOptions}
              controlShouldRenderValue={controlShouldRenderValue}
            />
          ) : (
            <Select
              {...input}
              value={initialValueComponent ?? value}
              onChange={onChange}
              inputValue={filterValue}
              onInputChange={onInputChange}
              options={options}
              styles={getSelectFieldStyles(!!error)}
              placeholder={placeholder}
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              menuPortalTarget={portalContainer}
              menuPlacement="auto"
              menuPosition="fixed"
              // Хак, чтобы попап не закрывался при скролле внутри попапа
              closeMenuOnScroll={(e: Event) =>
                !(e?.target as Element)?.classList?.toString().includes('selectFieldMenuList')
              }
              isSearchable={autocomplete}
              isClearable={isClearable}
              hideSelectedOptions={hideSelectedOptions}
              isMulti={isMulti}
              closeMenuOnSelect={closeMenuOnSelect}
              controlShouldRenderValue={controlShouldRenderValue}
              components={{
                ClearIndicator: SelectClearIndicator,
                Option: optionComponent as FC<OptionProps>,
                SingleValue: selectValueComponent,
                Menu: SelectFieldDropdown,
                // eslint-disable-next-line react/no-unstable-nested-components
                MenuList: (props) => (
                  <SelectFieldMenuList
                    {...props}
                    selectListAction={selectListAction}
                  />
                ),
                DropdownIndicator: SelectFieildDropdownIndicator,
                GroupHeading: GroupHeader,
              }}
              isDisabled={isDisabled}
            />
          )}
          {!hideError && <span className={styles.selectField__error}>{error}</span>}
        </div>
      </SelectFieldProvider>
    );
  }
);
