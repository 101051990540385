// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkboxFilter__checkboxGroup__Kat4c{margin-top:var(--hSpacing8)}.checkboxFilter__checkboxGroup_withPercentage__eiMbI>*:last-of-type{margin-bottom:var(--hSpacing8)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Filter/components/CheckboxFilter/CheckboxFilter.module.scss"],"names":[],"mappings":"AAGE,sCACE,2BAAA,CAGE,oEACE,8BAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.checkboxFilter {\n  &__checkboxGroup {\n    margin-top: var(--hSpacing8);\n\n    &_withPercentage {\n      & > *:last-of-type {\n        margin-bottom: var(--hSpacing8);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxFilter__checkboxGroup": "checkboxFilter__checkboxGroup__Kat4c",
	"checkboxFilter__checkboxGroup_withPercentage": "checkboxFilter__checkboxGroup_withPercentage__eiMbI"
};
export default ___CSS_LOADER_EXPORT___;
