/*
 * Component that provides global services context.
 */
import { FC, PropsWithChildren } from 'react';
import { ServicesContext } from './services.context';
import { TAppServices } from './services.types';

type TServicesProviderProps = {
  services: TAppServices;
};

export const ServicesProvider: FC<PropsWithChildren<TServicesProviderProps>> = ({
  services,
  children,
}) => <ServicesContext.Provider value={services}>{children}</ServicesContext.Provider>;
