/*
 * Edit campaign form.
 */
import { useStore } from 'effector-react/compat';
import { FormApi, createForm } from 'final-form';
import { FC, memo, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ICreateCampaignDto, SmaPlatform } from '@hypetrainCommon';
import { Input } from '@uikit/components/Fields/Inputs';
import { Radio, RadioGroup } from '@uikit/components/RadioGroup';
import { SelectField } from '@uikit/components/SelectField';
import { addressBookApiService } from '@api/addressBook';
import i18n from '@services/i18n';
import { campaignsUserAccessService } from '@services/userAccess';
import { makeValidate } from '@utils/makeValidate';
import { noop } from '@helpers/common.helpers';
import { $activeSocialPlatform } from '@models/activeSocialPlatform';
import { AssigneeSelectOption } from '@pages/Campaigns/CampaignsPage/components/AssigneeSelectOption';
import { AssigneeSelectValue } from '@pagesCampaigns/CampaignsPage/components/AssigneeSelectValue';
import styles from './CreateCampaignForm.module.scss';

type TEditCampaignFormProps = {
  createWithoutSelection?: boolean;
  onChangeForm?: (personContacts: FormApi<ICreateCampaignDto>) => void; // forwarding form object (not invoke on data change!!!)
};

export const CreateCampaignForm: FC<TEditCampaignFormProps> = memo(
  ({ onChangeForm, createWithoutSelection = false }) => {
    const { t } = useTranslation();
    const activePlatform = useStore($activeSocialPlatform);

    const PLATFORM_RADIO_CONFIG = [
      { label: t(`platform.${SmaPlatform.Youtube}`), value: SmaPlatform.Youtube },
      {
        label: t(`platform.${SmaPlatform.Instagram}`),
        value: SmaPlatform.Instagram,
        disable: true,
      },
      { label: t(`platform.${SmaPlatform.Tiktok}`), value: SmaPlatform.Tiktok, disable: true },
    ];

    // @ts-ignore OK. Ждём новый тип от бэка
    const schema: Yup.SchemaOf<ICreateCampaignDto> = Yup.object().shape({
      platform: createWithoutSelection
        ? Yup.string().required('Select a platform.')
        : Yup.string().optional(),
      name: Yup.string()
        .required(i18n.t('errors.campaign.enterCampaignName'))
        .max(255, i18n.t('errors.campaign.max')),
      assignee: campaignsUserAccessService.createAndAssignCampaign
        ? Yup.string().required(i18n.t('errors.campaign.assignee'))
        : Yup.string().optional(),
    });

    const validate = makeValidate(schema);
    const form = createForm({
      validate,
      onSubmit: () => {},
      initialValues: { platform: !createWithoutSelection ? activePlatform?.platform : undefined },
    });

    useEffect(() => {
      onChangeForm && onChangeForm(form);
    }, []);

    return (
      <Form
        onSubmit={noop}
        form={form}
        render={() => (
          <form className={styles.editCampaignForm}>
            {createWithoutSelection && (
              <Field name="platform">
                {({ meta, input }) => (
                  <>
                    <div className={styles.editCampaignForm__selectPlatformLabel}>
                      {t('createOrEditCampaign.selectPlatformLabel')}
                    </div>
                    <RadioGroup
                      value={input.value}
                      onChange={input.onChange}
                      className={styles.editCampaignForm__selectPlatformRadioGroup}
                    >
                      {PLATFORM_RADIO_CONFIG.map((item) => (
                        <Radio
                          key={item.value}
                          value={item.value}
                          label={item.label}
                          disable={item.disable}
                        />
                      ))}
                    </RadioGroup>

                    <span className={styles.editCampaignForm__selectPlatformError}>
                      {meta?.submitFailed && meta?.error}
                    </span>
                  </>
                )}
              </Field>
            )}

            <Field name="name">
              {({ meta, input }) => (
                <Input
                  input={input}
                  label={t('campaignName')}
                  error={meta?.submitFailed && meta?.error}
                />
              )}
            </Field>

            {campaignsUserAccessService.createAndAssignCampaign && (
              <Field name="assignee">
                {({ meta, input }) => (
                  <SelectField
                    input={input}
                    label={t('assignee')}
                    error={meta?.submitFailed && meta?.error}
                    valuePath="id"
                    viewValuePath="name"
                    optionComponent={AssigneeSelectOption}
                    selectValueComponent={AssigneeSelectValue}
                    loadOptions={addressBookApiService.getWorkspacesActivePersons}
                  />
                )}
              </Field>
            )}
          </form>
        )}
      />
    );
  }
);
