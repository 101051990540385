// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".readyForPaymentFilters__C_XmX{display:flex;gap:var(--vSpacing12)}", "",{"version":3,"sources":["webpack://./pages/PaymentsPage/components/PaymentsReadyForPayment/components/ReadyForPaymentFilters/ReadyForPaymentFilters.module.scss"],"names":[],"mappings":"AAEA,+BACE,YAAA,CACA,qBAAA","sourcesContent":["@import '../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.readyForPaymentFilters {\n  display: flex;\n  gap: var(--vSpacing12);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"readyForPaymentFilters": "readyForPaymentFilters__C_XmX"
};
export default ___CSS_LOADER_EXPORT___;
