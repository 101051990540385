// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltip__xVYNX{z-index:var(--tooltipZIndex)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Tooltip/Tooltip.module.scss"],"names":[],"mappings":"AAEA,gBACE,4BAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.tooltip {\n  z-index: var(--tooltipZIndex);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "tooltip__xVYNX"
};
export default ___CSS_LOADER_EXPORT___;
