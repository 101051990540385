/*
 * Компонент профиля.
 */
import { FC, FunctionComponent, Suspense, useEffect } from 'react';
import { lazily } from 'react-lazily';
import { SmaPlatform } from '@hypetrainCommon';
import { getSocialPlatformBySMAId } from '@utils/socialPlatform.utils';
import { ProfileModalWindow } from '@components/Profile/components/ProfileModalWindow';
import { ProfileSkeleton } from '@components/Profile/components/ProfileSkeleton';
import { initProfile, resetProfile } from '@components/Profile/profile.model';

// лениво грузим компоненты профилей отдельных платформ, а не общий компонент профиля потому что,
// профиль открывается через сервис и там невозможно добавить красивый Suspense со скелетоном
const { ProfileYoutube } = lazily(() => import('@components/Profile/components/ProfileYoutube'));
const { ProfileInstagram } = lazily(
  () => import('@components/Profile/components/ProfileInstagram')
);
const { ProfileTikTok } = lazily(() => import('@components/Profile/components/ProfileTikTok'));

export type TProfileConfig = {
  component: FunctionComponent;
  props?: Record<string, unknown>;
};

export type TProfileProps = {
  id: string;
  title?: string;
};

export const Profile: FC<TProfileProps> = ({ id, title }) => {
  const profileSocialPlatform = getSocialPlatformBySMAId(id);

  useEffect(() => {
    initProfile(id);
    return resetProfile;
  }, [id]);

  const platformProfiles: Partial<Record<SmaPlatform, TProfileConfig>> = {
    [SmaPlatform.Youtube]: {
      component: ProfileYoutube,
      props: {
        id,
      },
    },
    [SmaPlatform.Instagram]: {
      component: ProfileInstagram,
      props: {
        id,
        title: title || '',
      },
    },
    [SmaPlatform.Tiktok]: {
      component: ProfileTikTok,
      props: {
        id,
        title: title || '',
      },
    },
  };

  const PlatformProfile = platformProfiles[profileSocialPlatform]!.component; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return (
    <ProfileModalWindow>
      <Suspense fallback={<ProfileSkeleton />}>
        <PlatformProfile {...platformProfiles[profileSocialPlatform]?.props} />
      </Suspense>
    </ProfileModalWindow>
  );
};
