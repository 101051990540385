import { OverlaysService } from '@uikit/services/overlays';
import { Profile, TProfileProps } from '@components/Profile';

export class InfluencerProfileService {
  private influencerProfileModalId: string | null = null;

  constructor(private readonly overlaysService: OverlaysService) {}

  public openInfluencerProfileModal = (props: TProfileProps): void => {
    this.influencerProfileModalId = this.overlaysService.addOverlay<TProfileProps>(Profile, props);
  };

  public closeInfluencerProfileModal = (): void => {
    if (this.influencerProfileModalId === null) return; // страхуемся на случай вызова closeInfluencerProfileModal раньше closeInfluencerProfileModal и проверка на null (вдруг id === 0)

    this.overlaysService.deleteOverlay(this.influencerProfileModalId);
  };
}

export const createInfluencerProfileService = (overlaysService: OverlaysService) =>
  new InfluencerProfileService(overlaysService);
