/*
 * Стор лоадеров приложения.
 * TODO: (только глобальных? тогда переиеновать?)
 */
import { makeAutoObservable } from 'mobx';

export class LoaderStore {
  public loadersCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  public add(): void {
    this.loadersCount += 1;
  }

  public remove(): void {
    if (this.loadersCount <= 0) return;

    this.loadersCount -= 1;
  }
}

const createLoaderStore = (): LoaderStore => new LoaderStore();

export const loaderStore = createLoaderStore();
