// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".circle__vz7bH{display:flex;align-self:center;border-radius:50%}.circle__circleMax__J3WCD{width:20px;height:20px;margin-right:var(--vSpacing4)}.circle__circleMin__XI6g2{width:12px;height:12px;margin:0 var(--vSpacing8)}", "",{"version":3,"sources":["webpack://./components/CommonColumnsTable/MetricsInfoColumn/components/Circle/Circle.module.scss"],"names":[],"mappings":"AAEA,eACE,YAAA,CACA,iBAAA,CACA,iBAAA,CAEA,0BACE,UAAA,CACA,WAAA,CACA,6BAAA,CAGF,0BACE,UAAA,CACA,WAAA,CACA,yBAAA","sourcesContent":["@import '../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.circle {\n  display: flex;\n  align-self: center;\n  border-radius: 50%;\n\n  &__circleMax {\n    width: 20px;\n    height: 20px;\n    margin-right: var(--vSpacing4);\n  }\n\n  &__circleMin {\n    width: 12px;\n    height: 12px;\n    margin: 0 var(--vSpacing8);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circle": "circle__vz7bH",
	"circle__circleMax": "circle__circleMax__J3WCD",
	"circle__circleMin": "circle__circleMin__XI6g2"
};
export default ___CSS_LOADER_EXPORT___;
