import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import styles from './InfoDialogTemplate.module.scss';

type TInfoDialogTemplate = {
  icon: JSX.Element;
  title: string;
  description?: JSX.Element | string;
  className?: string;
  classNameDescription?: string;
};

export const InfoDialogTemplate: FC<PropsWithChildren<TInfoDialogTemplate>> = ({
  icon,
  title,
  description,
  children,
  className,
  classNameDescription,
}) => (
  <div className={cn(styles.infoDialogTemplate, className)}>
    {icon}
    <h2 className={styles.infoDialogTemplate__title}>{title}</h2>
    {description && (
      <div className={cn(styles.infoDialogTemplate__description, classNameDescription)}>
        {description}
      </div>
    )}
    {children}
  </div>
);
