/*
 * Component-container for placing modals in app.
 */
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { modalStore } from '@uikit/components/Modal';
import { ModalContext } from '@uikit/components/Modal/modal.context';

export const ModalsContainer: FC = observer(() => {
  const { modals } = modalStore;

  return (
    <>
      {modals.map(({ component: Component, props, id }) => (
        <ModalContext.Provider
          key={id}
          value={props.modal}
        >
          <Component {...toJS(props)} />
        </ModalContext.Provider>
      ))}
    </>
  );
});
