/*
 * Утилиты для работы с метриками(блогера, кампании и т.д.).
 */
import { EMPTY_PLACEHOLDER } from 'hypetrain-ui/src/constants/common.constants';
import _ceil from 'lodash/ceil';
import _floor from 'lodash/floor';
import _round from 'lodash/round';
import { Currency } from '@hypetrainCommon';
import { FORMAT_NUMBER_NOTATION, FORMAT_NUMBER_STYLES } from '@uikit/components/Formats';
import { getLengthOfNumber } from '@utils/calc.utils';

type TGetRangeRecPrice = {
  minPrice: number;
  maxPrice: number;
};

/*
 * Возвращает диапазон рекомендованной цены
 * формулы https://bloggerslab.fibery.io/Software_Development/Sprint/Sprint-071-(current)-96/Team-Board-808#Story/Add-Range-for-Recommended-Price-768
 */
export const getRangeRecPrice = (price: number): TGetRangeRecPrice | undefined => {
  if (!price) return;

  let minPrice;
  let maxPrice;

  if (price < 500) {
    minPrice = _floor(price / 25) * 25;
    if (!minPrice) minPrice = 1;

    maxPrice = _ceil((price + 1) / 25) * 25;

    return {
      minPrice,
      maxPrice,
    };
  }

  if (price >= 500 && price < 1000) {
    minPrice = _floor(price / 50) * 50;
    maxPrice = _ceil((price + 1) / 50) * 50;

    return {
      minPrice,
      maxPrice,
    };
  }

  minPrice = _round(price * 0.95);
  minPrice = _round(minPrice, 2 - getLengthOfNumber(minPrice));
  maxPrice = _round(price * 1.1);
  maxPrice = _round(maxPrice, 2 - getLengthOfNumber(maxPrice));

  return {
    minPrice,
    maxPrice,
  };
};

/*
 * Возвращает view для метрики в USD.
 */
export const getUSDMetricView = (
  value: number | undefined,
  options?: Intl.NumberFormatOptions
): string => {
  if (!value) return EMPTY_PLACEHOLDER;

  const formatter = new Intl.NumberFormat('en', {
    notation: FORMAT_NUMBER_NOTATION.COMPACT,
    style: FORMAT_NUMBER_STYLES.CURRENCY,
    currency: Currency.USD,
    ...(options || {}),
  });

  return formatter.format(Number(value));
};

/*
 * Возвращает диапазон цены для непосредственного отображения(например в таблице).
 */
export const getRangePriceForView = (price: TGetRangeRecPrice | undefined): string => {
  if (!price) return EMPTY_PLACEHOLDER;

  return `${getUSDMetricView(price.minPrice)}–${getUSDMetricView(price.maxPrice)}`;
};
