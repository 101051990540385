/*
 * Компонент ячейки раскрытия строки.
 */
import cn from 'clsx';
import { FC } from 'react';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { TTableRow } from '../../../table.types';
import styles from './ExpandCell.module.scss';

type TExpandCellProps = {
  row: TTableRow;
};

export const ExpandCell: FC<TExpandCellProps> = ({ row }) => {
  if (!row || !row?.canExpand) {
    return null;
  }

  return (
    <div className={styles.expandCell}>
      <Icon
        className={cn(styles.expandCell__icon, row.isExpanded && styles.expandCell__icon_top)}
        type={ICON_TYPES.NEXT}
      />
    </div>
  );
};
