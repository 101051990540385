/*
 * Роутер раздела настроек воркспейса.
 */
import { lazily } from 'react-lazily';
import { Navigate, Route } from 'react-router-dom';
import { Routes as Links } from 'router/Routes';
import { PrivateRoute } from '@router/PrivateRoute';
import { subscriptionUserAccessService } from '@services/userAccess';
import { workspaceUserAccessService } from '@services/userAccess/workspaceUserAccess.service';
import { LegalEntity } from '@pagesWorkspaceSettings/components/LegalEntity';
import { WorkspaceInfo } from '@pagesWorkspaceSettings/components/WorkspaceInfo';

const { WorkspaceSettings } = lazily(() => import('@pages/WorkspaceSettings'));
const { WorkspaceMembersTab } = lazily(
  () => import('@pages/WorkspaceSettings/components/WorkspaceMembersTab')
);
const { BillingTab } = lazily(() => import('@pages/WorkspaceSettings/components/BillingTab'));
const { WorkspaceInboxConnection } = lazily(
  () => import('@pages/WorkspaceSettings/components/WorkspaceInboxConnection')
);

export const WorkspaceSettingsRouter = (): JSX.Element => {
  const isSubscriptionsAvailable = subscriptionUserAccessService.isAvailable;

  return (
    <Route
      element={
        <PrivateRoute errorBoundaryName="workspaceSettings">
          <WorkspaceSettings />
        </PrivateRoute>
      }
    >
      <Route
        path={Links.workspaceSettings}
        element={
          <Navigate
            to={isSubscriptionsAvailable ? Links.workspaceMembers : Links.emailConnection}
          />
        }
      />
      {isSubscriptionsAvailable && (
        <>
          <Route
            path={Links.workspaceMembers}
            element={
              <PrivateRoute errorBoundaryName="workspaceMembers">
                <WorkspaceMembersTab />
              </PrivateRoute>
            }
          />
          <Route
            path={Links.billing}
            element={
              <PrivateRoute errorBoundaryName="billing">
                <BillingTab />
              </PrivateRoute>
            }
          />
        </>
      )}
      {workspaceUserAccessService.updateWorkspaceInfo && (
        <Route
          path={Links.workspaceInfo}
          element={
            <PrivateRoute errorBoundaryName="workspaceInfo">
              <WorkspaceInfo />
            </PrivateRoute>
          }
        />
      )}
      <Route
        path={Links.legalEntity}
        element={
          <PrivateRoute errorBoundaryName="legalEntity">
            <LegalEntity />
          </PrivateRoute>
        }
      />
      <Route
        path={Links.emailConnection}
        element={
          <PrivateRoute errorBoundaryName="emailConnection">
            <WorkspaceInboxConnection />
          </PrivateRoute>
        }
      />
    </Route>
  );
};
