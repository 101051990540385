// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".emptyText__fbhPL{display:flex;flex-direction:column;width:50%}.emptyText__lineOne__ufEAd{height:10px;max-width:50%;background-color:var(--buttonDisabled);margin-bottom:var(--vSpacing12)}.emptyText__lineTwo__lskU0{height:10px;background-color:var(--buttonDisabled)}", "",{"version":3,"sources":["webpack://./pages/Campaigns/CampaignDetailsPage/components/Communication/components/EmptyText/EmptyTextCommunication.module.scss"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,qBAAA,CACA,SAAA,CAEA,2BACE,WAAA,CACA,aAAA,CACA,sCAAA,CACA,+BAAA,CAGF,2BACE,WAAA,CACA,sCAAA","sourcesContent":["@import '../../../../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.emptyText {\n  display: flex;\n  flex-direction: column;\n  width: 50%;\n\n  &__lineOne {\n    height: 10px;\n    max-width: 50%;\n    background-color: var(--buttonDisabled);\n    margin-bottom: var(--vSpacing12);\n  }\n\n  &__lineTwo {\n    height: 10px;\n    background-color: var(--buttonDisabled);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyText": "emptyText__fbhPL",
	"emptyText__lineOne": "emptyText__lineOne__ufEAd",
	"emptyText__lineTwo": "emptyText__lineTwo__lskU0"
};
export default ___CSS_LOADER_EXPORT___;
