/*
 * Keyword filter component
 */
import { FC, memo, useEffect, useState } from 'react';
import { Filter, TFilterConfig } from '@uikit/components/Filter';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { Keywords } from '@uikit/components/Keywords';
import styles from './KeywordFilter.module.scss';

export type TKeywordFilterConfig = { placeholder: string };

type TKeywordFilterProps = {
  config: TFilterConfig<TKeywordFilterConfig>;
  onApply: (value: TFilter) => void;
  applyOnClose: boolean;
};

export const KeywordFilter: FC<TKeywordFilterProps> = memo(({ config, onApply, applyOnClose }) => {
  const [filterValue, setFilterValue] = useState<TFilter>(config?.filterValue);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    setFilterValue(config.filterValue);
  }, [config.filterValue]);

  const onClearCallback = () => {
    setFilterValue(config?.filterInitialValue);
    onApply(config?.filterInitialValue);
  };

  const onKeywordChange = (keywords: string[]): void => {
    if (keywords.length > 3) return;
    const newFilterValue = { ...filterValue, values: keywords };
    setFilterValue(newFilterValue);

    // при клике вне попапа фильтра, onChange у компонента Keywords срабатывает позже чем onApply,
    // по этому тут нужен этот костыль, если onKeywordChange сработал но попап закрыт то вызываем onApply с новыми значениями
    if (!isFilterOpen) {
      onApply(newFilterValue);
    }
  };

  return (
    <Filter
      config={config}
      viewValue={config.filterValue.values?.join(', ')}
      onClear={onClearCallback}
      onOpen={() => setIsFilterOpen(true)}
      onClose={() => setIsFilterOpen(false)}
      onApply={() => onApply(filterValue)}
      applyOnClose={applyOnClose}
    >
      <div className={styles.keywordFilter}>
        <Keywords
          onChange={onKeywordChange}
          keywords={filterValue.values || []}
          {...config.filterProps}
        />
      </div>
    </Filter>
  );
});
