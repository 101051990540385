/*
 * Модель для регистрации пользователя.
 */
import { createEffect, createEvent, createStore } from 'effector/compat';
import { EmailProvider, ICreateUserDto, ISignUpWithInviteRequestDto } from '@hypetrainCommon';
import { authApiService } from '@api/auth';
import { getFingerPrint } from '@services/fingetPrint';
import { APP_AUTH_TYPES } from '@services/initApp/initApp.constants';
import { setAccessToken } from '@utils/auth.utils';
import {
  USER_AUTHENTICATION_STRATEGY,
  USER_REGISTRATION_STRATEGY,
} from '@enums/logUsersActions.enum';
import { loginUserWithGoogleFx } from '@models/auth.model';
import { TSignUpStore } from '@pagesSignUp/SignUp.types';
import { logUserAccountCreated, logUserAccountDuplicate } from '@pagesSignUp/SignUp.userLog';

/*
 * Регистрация пользователя.
 */
export const signUpUserFx = createEffect(async (credentials: ICreateUserDto) => {
  const fingerprint = await getFingerPrint();
  const result = await authApiService.signUp({ ...credentials, fingerprint });
  logUserAccountCreated(
    USER_REGISTRATION_STRATEGY.BY_OWN_INTENTION,
    USER_AUTHENTICATION_STRATEGY.LOCAL
  );
  logUserAccountDuplicate(result?.duplicateFingerprint, fingerprint);
  return {
    email: credentials.email,
    emailProvider: result?.emailProvider,
  };
});

/*
 * Регистрация пользователя по инвайту.
 */
export const signUpUserWithInviteFx = createEffect(async (params: ISignUpWithInviteRequestDto) => {
  const fingerprint = await getFingerPrint();
  const result = await authApiService.signUpWithInvite({ ...params, fingerprint });
  logUserAccountCreated(
    USER_REGISTRATION_STRATEGY.BY_INVITATION,
    USER_AUTHENTICATION_STRATEGY.LOCAL
  );
  logUserAccountDuplicate(result?.duplicateFingerprint, fingerprint);
  setAccessToken(result?.accessToken);
  return result;
});

export const resetSignUpStore = createEvent();

export const $signUpStore = createStore<TSignUpStore>({
  email: null,
  authType: APP_AUTH_TYPES.DEFAULT,
  authProvider: EmailProvider.unknown,
})
  .on(signUpUserFx.doneData, (_, payload) => ({
    email: payload?.email,
    authType: APP_AUTH_TYPES.DEFAULT,
    authProvider: payload?.emailProvider,
  }))
  .on(loginUserWithGoogleFx.doneData, (_, payload) => ({
    email: payload?.googleAccountInfo?.email,
    authType: APP_AUTH_TYPES.GOOGLE,
    authProvider: EmailProvider.google,
  }))
  .reset(resetSignUpStore);
