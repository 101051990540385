/*
 * Компонент для отрисовки заглушки о том что мы не поддерживаем мобильную версию
 * */
import cn from 'clsx';
import { FC, ReactElement, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoMobile from '@assets/images/logoMobile.svg';
import unsupportedMobileIcon from '@assets/images/unsupportedMobile.svg';
import { AppQuickOverview } from '@components/AppQuickOverview';
import { Unsupported } from '@components/templates/Unsupported';
import styles from './UnsupportedMobile.module.scss';

const maxDesktopSize = 850;

type TUnsupportedMobileProps = {
  children: ReactElement;
};

export const UnsupportedMobile: FC<TUnsupportedMobileProps> = memo(({ children }) => {
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < maxDesktopSize);

  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth < maxDesktopSize);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <div className={cn(styles.hide, { [styles.unsupportedMobile]: isMobile })}>
        <div className={styles.unsupportedMobile__container}>
          <div className={styles.unsupportedMobile__containerContent}>
            <div className={styles.unsupportedMobile__containerLogo}>
              <img
                src={logoMobile}
                alt="logo"
              />
            </div>

            <div className={styles.unsupportedMobile__centerContent}>
              <Unsupported
                icon={unsupportedMobileIcon}
                title={t('unsupportedTitle')}
                text={t('unsupportedText')}
                onClick={() => window.open('https://hypetrain.io/', '_self')}
              />
              <AppQuickOverview className={styles.unsupportedMobile__videoContainer} />
            </div>
          </div>
        </div>
      </div>
      <div className={cn(styles.mainContent, { [styles.mainContent_hide]: isMobile })}>
        {children}
      </div>
    </>
  );
});
