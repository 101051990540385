import cn from 'clsx';
import { useStore } from 'effector-react/compat';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SmaPlatform } from '@hypetrainCommon';
import { TFilterConfig } from '@uikit/components/Filter';
import { hasActiveFilters } from '@uikit/components/Filters/filters.utils';
import { VirtualizedTable } from '@uikit/components/Table';
import {
  $campaignSelectionStore,
  $campaignStore,
  $searchTerm,
  addAllToCampaignSelection,
  getCampaignDealsFx,
  getCampaignDealsWithSearchFx,
  resetSearchTerm,
} from '@pages/Campaigns/CampaignDetailsPage/campaignDetailsPage.model';
import {
  $campaignFilters,
  $isFilterUsedStore,
} from '@pages/Campaigns/CampaignDetailsPage/campaignDetailsPageConfig.model';
import { CAMPAIGN_DETAILS_TABLE_NAME } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.constants';
import { getPlatformCampaignColumnsMap } from '@pagesCampaigns/CampaignDetailsPage/components/DataAnalytics/DataAnalytics.utils';
import styles from './DataAnalytics.module.scss';

export const DataAnalytics: FC = () => {
  const { t } = useTranslation();
  const campaign = useStore($campaignStore);
  const selectedCampaignDeals = useStore($campaignSelectionStore);
  const searchTerm = useStore($searchTerm);
  const filters = useStore<TFilterConfig[]>($campaignFilters);
  const isFilterUsed = useStore($isFilterUsedStore);
  const columns =
    getPlatformCampaignColumnsMap(filters)[
      campaign?.campaignDetails?.platform || SmaPlatform.Youtube
    ] || [];
  const loading = useStore(getCampaignDealsFx.pending);

  useEffect(() => {
    campaign?.campaignDeals && addAllToCampaignSelection(Object.keys(campaign.campaignDeals));
  }, []);

  const campaignDeals = useMemo(
    () => campaign?.campaignDeals && Object.values(campaign.campaignDeals),
    [campaign?.campaignDeals]
  );

  if (!campaign || !campaignDeals) return null;

  const onResetClick = () => {
    resetSearchTerm();
    getCampaignDealsWithSearchFx({
      campaignId: campaign.campaignDetails.id,
    });
  };

  return (
    <div className={styles.campaignDetailsPage__tableBlock}>
      {searchTerm && !!campaignDeals.length && (
        <div className={styles.campaignDetailsPage__searchResultsContainer}>
          <div className={styles.campaignDetailsPage__searchResults}>{`${t('searchResults')} ${
            Object.keys(campaign?.campaignDeals).length
          }`}</div>

          <span
            onClick={onResetClick}
            className={styles.campaignDetailsPage__reset}
          >
            {t('reset')}
          </span>
        </div>
      )}
      <VirtualizedTable
        data={campaignDeals || []}
        columns={columns}
        tableName={CAMPAIGN_DETAILS_TABLE_NAME}
        loadOnScroll={() => {}}
        isLoading={loading}
        wasFiltered={hasActiveFilters(filters) || !!searchTerm || isFilterUsed}
        className={cn(
          styles.campaignDetailsPage__table,
          campaign?.campaignDetails?.platform !== SmaPlatform.Youtube &&
            styles.campaignDetailsPage_flexNone
        )}
        rowClassName={cn(
          styles.campaignTableRow,
          selectedCampaignDeals.length && styles.showCheckboxes
        )}
        nothingFoundOptions={{
          message:
            campaign?.campaignDetails?.platform === SmaPlatform.Youtube
              ? t('campaignPage.youtube.notFoundChannelCampaign')
              : t('campaignPage.instagram.notFoundChannelCampaign'),
        }}
      />
    </div>
  );
};
