import cn from 'clsx';
import { FC } from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { Icon, ICON_TYPES } from '@uikit/components/Icon';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import styles from './SelectFieildDropdownIndicator.module.scss';

export const SelectFieildDropdownIndicator: FC<DropdownIndicatorProps> = (props) => (
  <components.DropdownIndicator {...props}>
    <Icon
      className={cn(
        // eslint-disable-next-line react/destructuring-assignment
        props.selectProps.menuIsOpen && styles.selectFieildDropdownIndicator_turnOver
      )}
      style={ICON_STYLES.SECONDARY}
      type={ICON_TYPES.DOWN}
    />
  </components.DropdownIndicator>
);
