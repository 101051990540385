/*
 * Компонент опции в меню выбора воркспейса
 */
import i18n from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ISubscriptionSimpleInfoDto, SubscriptionStatusType } from '@hypetrainCommon';
import { Budge } from '@uikit/components/Budge';
import styles from './MenuSubscriptionBadge.module.scss';

const STATUSES_MAP: Partial<Record<SubscriptionStatusType, string>> = {
  [SubscriptionStatusType.unpaid]: i18n.t('menuSubscriptionBadge.unpaid'),
  [SubscriptionStatusType.pastDue]: i18n.t('menuSubscriptionBadge.latePayment'),
  [SubscriptionStatusType.canceled]: i18n.t('menuSubscriptionBadge.subscriptionNeeded'),
  [SubscriptionStatusType.incomplete]: i18n.t('menuSubscriptionBadge.subscriptionNeeded'),
  [SubscriptionStatusType.incompleteExpired]: i18n.t('menuSubscriptionBadge.subscriptionNeeded'),
};

type TMenuSubscriptionBadgeProps = {
  subscription?: ISubscriptionSimpleInfoDto;
};

export const MenuSubscriptionBadge: FC<TMenuSubscriptionBadgeProps> = ({ subscription }) => {
  const { t } = useTranslation();

  return (
    <Budge className={styles.menuSubscriptionBadge}>
      {subscription
        ? STATUSES_MAP[subscription?.status]
        : t('menuSubscriptionBadge.subscriptionNeeded')}
    </Budge>
  );
};
