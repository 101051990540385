/*
 * ПОМОЙКА. Расширение запрещено!
 * TODO: Разнести по местам использования.
 */

export const AGES_YOUTUBE = ['55+', '45-54', '35-44', '25-34', '18-24', '13-17'];
export const AGES_IQDATA = ['65-', '45-64', '35-44', '25-34', '18-24', '13-17'];

export const youtubeLink = 'https://www.youtube.com/channel';

export const postfix = 'videos?view=0';
