/*
 * Хук для получения меетрик на странице поиска
 */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IInstagramSmaStatisticsResponseDto,
  ITiktokSmaStatisticsSimpleInfoDto,
  IYoutubeSmaStatisticsResponseDto,
  SmaPlatform,
} from '@hypetrainCommon';
import {
  FORMAT_NUMBER_NOTATION,
  FORMAT_NUMBER_STYLES,
  FormatNumber,
} from '@uikit/components/Formats';
import { TGroupActionsMetric } from '@uikit/components/GroupActions';
import { TSmaStatisticResponse } from '@api/campaigns';
import { getUSDMetricView } from '@utils/metrics';
import {
  getInstagramSummaryDeals,
  getSummaryDeals,
  getTikTokSummaryDeals,
} from '@pages/Discovery/summaryDeals.utils';

export const useSearchPlatformMetrics = (
  selectedInfValues: TSmaStatisticResponse,
  platform: SmaPlatform
): TGroupActionsMetric[] => {
  const { t } = useTranslation();
  const summaryDeals = useMemo(() => {
    if (platform === SmaPlatform.Youtube) {
      return getSummaryDeals(selectedInfValues as IYoutubeSmaStatisticsResponseDto[]);
    }

    if (platform === SmaPlatform.Tiktok) {
      return getTikTokSummaryDeals(selectedInfValues as ITiktokSmaStatisticsSimpleInfoDto[]);
    }

    return getInstagramSummaryDeals(selectedInfValues as IInstagramSmaStatisticsResponseDto[]);
  }, [selectedInfValues]);

  if (platform === SmaPlatform.Instagram) {
    return [
      {
        title: t('summaryGroupAction.erMedian'),
        value: (
          <FormatNumber numberStyles={FORMAT_NUMBER_STYLES.PERCENT}>
            {summaryDeals?.erMedian}
          </FormatNumber>
        ),
      },
    ];
  }

  if (platform === SmaPlatform.Tiktok) {
    return [
      {
        title: t('summaryGroupAction.viewsTotal'),
        value: (
          <FormatNumber notation={FORMAT_NUMBER_NOTATION.COMPACT}>
            {summaryDeals?.viewsTotal}
          </FormatNumber>
        ),
      },
      {
        title: t('summaryGroupAction.erMedian'),
        value: (
          <FormatNumber numberStyles={FORMAT_NUMBER_STYLES.PERCENT}>
            {summaryDeals?.erMedian}
          </FormatNumber>
        ),
      },
    ];
  }

  return [
    {
      title: t('summaryGroupAction.viewsTotal'),
      value: (
        <FormatNumber notation={FORMAT_NUMBER_NOTATION.COMPACT}>
          {summaryDeals?.viewsTotal}
        </FormatNumber>
      ),
    },
    {
      title: t('summaryGroupAction.erMedian'),
      value: (
        <FormatNumber numberStyles={FORMAT_NUMBER_STYLES.PERCENT}>
          {summaryDeals?.erMedian}
        </FormatNumber>
      ),
    },
    {
      title: t('summaryGroupAction.recPriceTotal'),
      value: (
        <div>
          {getUSDMetricView(summaryDeals?.recPrice || undefined, {
            maximumFractionDigits: 1,
            minimumFractionDigits: 0,
            notation: FORMAT_NUMBER_NOTATION.COMPACT,
          })}
        </div>
      ),
    },
    {
      title: t('summaryGroupAction.cpmTotal'),
      value: (
        <div>
          {getUSDMetricView(summaryDeals?.cpmTotal || undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          })}
        </div>
      ),
    },
  ];
};
