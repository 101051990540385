/*
 * Компонент-обёртка для встройки содержимого в portal.
 */
import { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { ErrorBoundary } from '@uikit/components/ErrorBoundary';
import { PortalProvider } from '@uikit/components/Portal/portal.provider';
import { APP_PORTAL_CONTAINER_ID } from '@uikit/constants/common.constants';
import styles from './Portal.module.scss';

// eslint-disable-next-line @typescript-eslint/ban-types
export const Portal: FC<PropsWithChildren<{}>> = ({ children }) => {
  let portalContainer = document.body.querySelector(`#${APP_PORTAL_CONTAINER_ID}`);

  /*
   * Создаёт контейнер, если он отсутствует.
   */
  if (!portalContainer) {
    const containerNode = document.createElement('div');

    containerNode.id = APP_PORTAL_CONTAINER_ID;
    document.body.append(containerNode);
    portalContainer = containerNode;
  }

  return (
    <ErrorBoundary
      layerName="portal"
      errorStubClassName={styles.portal_error}
    >
      <PortalProvider value={{}}>{createPortal(children, portalContainer)}</PortalProvider>
    </ErrorBoundary>
  );
};
