import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tip } from '@uikit/components/Tip';
import styles from './HideUnreadMessage.module.scss';

type TUnreadMessage = {
  count: number;
};
export const HideUnreadMessage: FC<TUnreadMessage> = ({ count }) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(!!count);

  if (!count || !isShow) return null;

  return (
    <Tip
      className={styles.hideUnreadMessage}
      onClose={() => setIsShow((prev) => !prev)}
    >
      {t('hideUnreadMessage', { unreadMessageCount: count })}
    </Tip>
  );
};
