/*
 * Global modal service.
 */
import { nanoid } from 'nanoid';
import { ModalStore, TModalConfig, modalStore } from './modal.store';
import { TModalAPI, TOpenModalConfig } from './modal.types';

export class ModalService {
  constructor(private readonly store: ModalStore) {}

  /*
   * Open modal.
   */
  public open<D = never, P = never, R = never>(config: TOpenModalConfig<P, D, R>): Promise<D> {
    const id = nanoid();

    const modalResult = new Promise<D>((resolve: (data: D) => void, reject: (data: R) => void) => {
      const props = {
        ...(config.props || ({} as P)),
        modal: this.makeModalAPI<D, R>(id, resolve, reject),
      };

      const modalConfig: TModalConfig<P, D, R> = {
        ...config,
        props,
        id,
      };
      this.store.add<P, D, R>(modalConfig);
    });

    return modalResult;
  }

  /*
   * Making modal API for forwarding in modal component.
   */
  private makeModalAPI<D, R>(
    modalId: string,
    resolveModal: (data?: D) => void,
    rejectModal: (data?: R) => void
  ): TModalAPI<D, R> {
    const closeModal = (): void => this.store.delete(modalId);
    const resolve = (resolveData: D): void => {
      closeModal();
      resolveModal(resolveData);
    };
    const reject = (rejectData: R): void => {
      closeModal();
      rejectModal(rejectData);
    };

    return {
      resolve,
      reject,
    };
  }
}

const createModalService = (store: ModalStore) => new ModalService(store);
export const modalService = createModalService(modalStore);
