import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import {
  IYoutubeSmaStatisticsSimpleInfoDto,
  MetricValueType,
  SmaAudienceCountryValue,
} from '@hypetrainCommon';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { ICampaignDealListItemDto } from '@api/campaigns';
import { ICampaignDealListItemCustom } from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.types';
import { isIncludeCountry } from '@components/AudienceLocation/utils';

type TCalcMetrics = IYoutubeSmaStatisticsSimpleInfoDto & {
  countriesDiagram: TCountriesDiagram;
  rgb?: string[];
  uuid: string;
};

type TCountriesDiagram = {
  isTargetedCountries: SmaAudienceCountryValue[];
  memoizedValues: SmaAudienceCountryValue[];
  isNonTargetedCountries: SmaAudienceCountryValue[];
};

const lowColor = [92, 232, 107];
const mediumColor = [255, 237, 134];
const medColorDiff = [163, 5, 27];
const highcolorDiff = [0, -95, 10];

const getColor = ({
  medDiffMed,
  maxDiffMed,
  medDiffMax,
  maxDiffMax,
  medDiffMin,
  maxDiffMin,
  med,
  min,
  max,
  minValueMed,
  minValueMax,
  minValueMin,
  maxValueMed,
  maxValueMax,
  maxValueMin,
  medValueMed,
  medValueMax,
  medValueMin,
}: Record<string, number>) => {
  const arrayObj = [
    {
      value: min,
      medDiff: medDiffMin,
      maxDiff: maxDiffMin,
      minValue: minValueMin,
      maxValue: maxValueMin,
      medValue: medValueMin,
    },
    {
      value: med,
      medDiff: medDiffMed,
      maxDiff: maxDiffMed,
      minValue: minValueMed,
      maxValue: maxValueMed,
      medValue: medValueMed,
    },
    {
      value: max,
      medDiff: medDiffMax,
      maxDiff: maxDiffMax,
      minValue: minValueMax,
      maxValue: maxValueMax,
      medValue: medValueMax,
    },
  ];
  const color: string[] = [];

  arrayObj.forEach((el) => {
    if (el.value <= el.medValue) {
      const valueDiff = el.value - el.minValue;
      const factor = valueDiff / el.medDiff;
      const colorDiff = medColorDiff.map((item) => Math.round(item * factor));

      color.push(lowColor.map((item, index) => Math.round(item + colorDiff[index])).join(','));
      return;
    }
    const valueDiff = el.value - el.medValue;
    const factor = valueDiff / el.maxDiff;
    const colorDiff = highcolorDiff.map((item) => Math.round(item * factor));

    color.push(mediumColor.map((item, index) => Math.round(item + colorDiff[index])).join(','));
  });

  return color;
};

export const getRgb = (
  data: Record<MetricValueType, number>[],
  med: number,
  min: number,
  max: number
) => {
  let minValueMed: number = _get(data, '[0].med', 0);
  let minValueMax: number = _get(data, '[0].max', 0);
  let minValueMin: number = _get(data, '[0].min', 0);
  let maxValueMed: number = _get(data, '[0].med', 0);
  let maxValueMax: number = _get(data, '[0].max', 0);
  let maxValueMin: number = _get(data, '[0].min', 0);
  let medValueMed = 0;
  let medValueMax = 0;
  let medValueMin = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const el of data) {
    if (el.med < minValueMed) minValueMed = el.med;
    if (el.max < minValueMax) minValueMax = el.max;
    if (el.min < minValueMin) minValueMin = el.min;

    if (el.med > maxValueMed) maxValueMed = el.med;
    if (el.max > maxValueMax) maxValueMax = el.max;
    if (el.min > maxValueMin) maxValueMin = el.min;

    medValueMed += el.med;
    medValueMax += el.max;
    medValueMin += el.min;
  }

  medValueMed /= data.length;
  medValueMax /= data.length;
  medValueMin /= data.length;

  const medDiffMed = medValueMed - minValueMed;
  const maxDiffMed = maxValueMed - medValueMed;
  const medDiffMax = medValueMax - minValueMax;
  const maxDiffMax = maxValueMax - medValueMax;
  const medDiffMin = medValueMin - minValueMin;
  const maxDiffMin = maxValueMin - medValueMin;

  return getColor({
    medDiffMed,
    maxDiffMed,
    medDiffMax,
    maxDiffMax,
    medDiffMin,
    maxDiffMin,
    med,
    min,
    max,
    minValueMed,
    minValueMax,
    minValueMin,
    maxValueMed,
    maxValueMax,
    maxValueMin,
    medValueMed,
    medValueMax,
    medValueMin,
  });
};

const getMemoizedValues = (values: SmaAudienceCountryValue[]) => {
  const sortValues: SmaAudienceCountryValue[] = _sortBy(values, 'value').reverse();

  // search for Unknown and insert at the end of the array
  sortValues.push(
    sortValues.splice(
      sortValues.findIndex((v) => v?.key === 'ZZ'),
      1
    )[0]
  );
  return { memoizedValues: sortValues };
};

export const getAudienceCountries = (filters: TFilter[], values: SmaAudienceCountryValue[]) => {
  const getFilterCountries = filters?.find((el) => el?.name === 'audienceCountries');

  const notUndefined = (anyValue: SmaAudienceCountryValue) => typeof anyValue !== 'undefined';
  let { memoizedValues } = getMemoizedValues(values);

  memoizedValues = getFilterCountries
    ? memoizedValues
        .map((el) => ({
          ...el,
          target: isIncludeCountry(getFilterCountries?.values || [], el?.key),
        }))
        .filter(notUndefined)
    : memoizedValues.filter(notUndefined);

  // @ts-ignore TODO Женя: разобраться что за target, откуда появляется в этой модели
  const isTargetedCountries = memoizedValues.filter((el) => el?.target).filter(notUndefined);

  // @ts-ignore TODO Женя: разобраться что за target, откуда появляется в этой модели
  const isNonTargetedCountries = memoizedValues.filter((el) => !el?.target).filter(notUndefined);
  return {
    memoizedValues,
    isTargetedCountries,
    isNonTargetedCountries,
  };
};

// переписать данную мега систему
export const metricsForTable: Record<string, TCalcMetrics> = {};

export const prepareInfluencersData = (
  statistics: IYoutubeSmaStatisticsSimpleInfoDto[] | ICampaignDealListItemCustom[],
  filters: TFilter[] = []
) => {
  let influencers: IYoutubeSmaStatisticsSimpleInfoDto[];

  // В Deals блоггеры хранятся под ключом smaStatistic
  // чекаем есть ли он и достаем блоггеров
  const isDealBloggersList = statistics.some((el) => 'smaStatistics' in el && el.smaStatistics);
  if (isDealBloggersList) {
    influencers = statistics.map(
      (el) => (el as ICampaignDealListItemDto)?.smaStatistics as IYoutubeSmaStatisticsSimpleInfoDto
    );
  } else {
    influencers = [...(statistics as IYoutubeSmaStatisticsSimpleInfoDto[])];
  }

  const dataCPM =
    influencers
      ?.filter(
        (item) =>
          item?.calculations?.calculatedMetrics[30]?.projectedCPM?.min &&
          item?.calculations?.calculatedMetrics[30]?.projectedCPM?.med &&
          item?.calculations?.calculatedMetrics[30]?.projectedCPM?.max
      )
      .map(
        (el) =>
          el?.calculations?.calculatedMetrics[30]?.projectedCPM ||
          ({} as Record<MetricValueType, number>)
      ) || [];

  influencers?.forEach((element) => {
    const countriesDiagram = getAudienceCountries(filters, element?.audience?.countries?.values);

    const getObject = (): TCalcMetrics => ({
      ...element,
      rgb: getRgb(
        dataCPM,
        Number(element?.calculations?.calculatedMetrics[30]?.projectedCPM?.med),
        Number(element?.calculations?.calculatedMetrics[30]?.projectedCPM?.min),
        Number(element?.calculations?.calculatedMetrics[30]?.projectedCPM?.max)
      ),
      countriesDiagram,
    });

    const calcMetrics = getObject();

    if (!metricsForTable[element?.uuid]) {
      metricsForTable[element?.uuid] = calcMetrics;
    }
    if (!metricsForTable[element?.sma?.uuid]) {
      metricsForTable[element?.sma?.uuid] = calcMetrics;
    }
  });
};

// Считает данные для одного единственного блоггера, !!!ТОЛЬКО!!! если он один в таблице и не надо
// ничего считать относительно других блогеров
// Иначе используем prepareInfluencersData(выше функция), которая считает инфу относительна друг друга
export const calculationDataForOneInfluencer = (
  statistic: IYoutubeSmaStatisticsSimpleInfoDto
): void => {
  const countriesDiagram = getAudienceCountries([], statistic?.audience?.countries?.values);

  const getObject = () => ({
    ...statistic,
    countriesDiagram,
  });

  const calcMetrics = getObject();

  if (!metricsForTable[statistic?.uuid]) {
    metricsForTable[statistic?.uuid] = calcMetrics;
  }

  if (!metricsForTable[statistic?.sma?.uuid]) {
    metricsForTable[statistic?.sma?.uuid] = calcMetrics;
  }
};
