/*
 * Модель авторизации пользователя в системе.
 */
import { createEffect } from 'effector/compat';
import i18n from 'i18next';
import { ICredentialsResponseDto, ISignInRequestDto } from '@hypetrainCommon';
import { notificationsService } from '@uikit/components/Notification';
import { authApiService } from '@api/auth';
import webSocketService from '@api/webSocket';
import { getFingerPrint } from '@services/fingetPrint';
import { TLoginAcademyParams, TLoginParams } from '@services/initApp/initApp.constants';
import { setAccessToken } from '@utils/auth.utils';
import { redirectToLoginPage } from '@utils/redirect.utils';
import {
  logUserAccountCreationAndEmailVerificationWithGoogle,
  logUserAccountDuplicate,
} from '@pagesSignUp/SignUp.userLog';

/*
 * Обновляет авторизационные токены.
 */
export const updateAuthTokenFx = createEffect(async (): Promise<ICredentialsResponseDto> => {
  const fingerprint = await getFingerPrint();
  const credentials = await authApiService.refreshAuthTokens(fingerprint);

  setAccessToken(credentials?.accessToken);

  return credentials;
});

/*
 * Подтверждение имейла пользователя.
 */
export const userVerificationAndAuthFx = createEffect(
  async ({ credentials, onLogin }: TLoginParams) => {
    const fingerprint = await getFingerPrint();
    const response = await authApiService.userVerificationAndAuth({
      token: credentials as string,
      fingerprint,
    });
    notificationsService.successful({
      props: {
        caption: i18n.t('emailVerified'),
        description: i18n.t('emailVerified_description'),
      },
    });
    onLogin(response);

    return response;
  }
);

/*
 * Авторизация пользователя в системе.
 */
export const loginUserFx = createEffect(async ({ credentials, onLogin }: TLoginParams) => {
  const fingerprint = await getFingerPrint();
  const response = await authApiService.signIn({
    ...(credentials as ISignInRequestDto),
    fingerprint,
  });
  onLogin(response);

  return response;
});

/*
 * Авторизация пользователя через google
 */
export const loginUserWithGoogleFx = createEffect(
  async ({ credentials, onLogin }: TLoginParams) => {
    const fingerprint = await getFingerPrint();
    const result = await authApiService.loginWithGoogle({
      token: credentials as string,
      fingerprint,
    });
    logUserAccountCreationAndEmailVerificationWithGoogle(result?.workspace?.handler);
    logUserAccountDuplicate(result?.duplicateFingerprint, fingerprint);
    onLogin(result);

    return result;
  }
);

export const loginUserAcademy = createEffect(async ({ credentials, onLogin }: TLoginParams) => {
  const fingerprint = await getFingerPrint();
  const result = await authApiService.loginAcademy({
    ...(credentials as TLoginAcademyParams),
    fingerprint,
  });
  onLogin(result);

  return result;
});

/*
 * Деавторизация пользователя (logout).
 */
export const logoutUserFx = createEffect(async () => {
  await authApiService.signOut().finally(() => {
    webSocketService?.disconnect(); // на всякий пожарный
    redirectToLoginPage();
  });
});
