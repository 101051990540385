import { setIn } from 'final-form';
import { AnySchema, ValidationError } from 'yup';

/**
 * Sets the `innerError.message` in an `errors` object at the key
 * defined by `innerError.path`.
 */
const setInError = (errors: Record<string, string>, innerError: ValidationError) =>
  setIn(errors, innerError.path ?? '', innerError.message);

/**
 * Empty object map with no prototype. Used as default
 * value for reducing the `err.inner` array of errors
 * from a `yup~ValidationError`.
 */
const emptyObj: Record<never, never> = Object.create(null);

/**
 * Takes a `yup` validation schema and returns a function that expects
 * a map of values to validate. If the validation passes, the function resolves to `undefined`
 * (signalling that the values are valid). If the validation doesn't pass, it resolves
 * to a map of invalid field names to errors.
 */

export function makeValidate<T>(schema: AnySchema<T>) {
  return async function validate(values: T) {
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err) {
      return (err as ValidationError).inner.reduce(setInError, emptyObj);
    }
  };
}
