/*
 * Component for metric (entity) description.
 */
import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { TextEllipsis } from '@uikit/components/TextEllipsis';
import style from './Metric.module.scss';

export enum MetricSize {
  M = 'M',
  L = 'L',
}

type TMetricProps = {
  name: string; // metric/entity name (placed on top)
  size?: MetricSize; // size that set font sizes
  className?: string;
};

export const DEFAULT_METRIC_SIZE = MetricSize.M;

export const Metric: FC<PropsWithChildren<TMetricProps>> = ({
  name,
  size = DEFAULT_METRIC_SIZE,
  className,
  children,
}) => (
  <div className={cn(style.metric, className)}>
    <h4 className={style.metric__name}>
      <TextEllipsis>{name}</TextEllipsis>
    </h4>
    <TextEllipsis className={cn(style.metric__value, style[size])}>{children}</TextEllipsis>
  </div>
);
