/*
 * Service for executing requests for personal inbox connection.
 */
import { ApiService, apiService } from '@api';
import {
  ICreateCollaborativeWorkspaceDto,
  ICreatePersonalInboxConnectionDto,
  ICreatePersonalInboxConnectionResponseDto,
  ILegalEntityDto,
  ILegalEntityInfoResponseDto,
  IPersonWorkspaceResponseDto,
  IPersonalInboxConnectionInfoDto,
  IUpdateWorkspaceDto,
  IWorkspaceInfoResponseDto,
  IWorkspaceInvitePublicInfoResponseDto,
} from '@hypetrainCommon';
import { STATUS_CODES } from '@api/api.enums';
import {
  GET_INVITE_INFO_ERROR_CODES,
  INVITE_ERROR_CODES,
} from '@api/workspace/workspace.api.constants';
import { TPersonCreationProps } from '@api/workspace/workspace.api.types';

/* eslint-disable arrow-body-style */
export class WorkspaceApiService {
  constructor(private readonly api: ApiService) {}

  /*
   * Получение информации о наличии подключеннного к воркспейсу имейла персоны, для общения с блогерами в deal чате
   * @see: https://reference.api.rc-staging.hypetrain.io/?javascript#get-personal-inbox-connection
   */
  public getPersonalInboxConnectionInfo = (): Promise<IPersonalInboxConnectionInfoDto> => {
    return this.api
      .get(
        '/workspaces/personal-inbox-connection',
        {},
        {
          errorStatusesToIgnore: [STATUS_CODES.NOT_FOUND],
          errorCodesToIgnore: ['access-forbidden--person-not-active'],
          showLoader: false,
        }
      )
      .then((response) => response.data);
  };

  /*
   * Создание inbox connection
   * @see: https://reference.api.rc-staging.hypetrain.io/?javascript#create-personal-inbox-connection
   */
  public createPersonalInboxConnection = (
    body: ICreatePersonalInboxConnectionDto
  ): Promise<ICreatePersonalInboxConnectionResponseDto> => {
    return this.api
      .post(
        '/workspaces/personal-inbox-connection',
        body,
        {},
        {
          errorCodesToIgnore: ['personal-inbox-provider--invalid-smtp-or-imap-config'],
        }
      )
      .then((response) => response.data);
  };

  /*
   * Удаление inbox connection
   * @see: https://reference.api.test.hypetrain.io/?javascript#delete-personal-inbox-connection
   */
  public deletePersonalInboxConnection = (): Promise<void> => {
    return this.api
      .deleteMethod('/workspaces/personal-inbox-connection')
      .then((response) => response.data);
  };

  /*
   * получение списка воркспейсов
   * @see: https://reference.api.test.hypetrain.io/?shell#get-user-39-s-workspaces
   */
  public getWorkspaces = (): Promise<IPersonWorkspaceResponseDto[]> => {
    return this.api.get('/workspaces').then((response) => response.data);
  };

  /*
   * проверка хэндлера воркспейса
   * @see: https://reference.api.test.hypetrain.io/?shell#get-user-39-s-workspaces
   */
  public checkWorkspaceHandler = (handler: string): Promise<void> => {
    return this.api
      .post(
        '/workspaces/check-handler',
        { handler },
        {},
        { showLoader: false, errorStatusesToIgnore: [STATUS_CODES.CONFLICT] }
      )
      .then((response) => response.data);
  };

  /*
   * обновление информации о воркспейсе
   * @see: https://reference.api.team-master-dev.hypetrain.io/?shell#update-workspace-by-id
   */
  public patchWorkspaces = (requestData: {
    workspaceId: string;
    data: IUpdateWorkspaceDto;
  }): Promise<IWorkspaceInfoResponseDto> => {
    return this.api
      .patch(`/workspaces/${requestData.workspaceId}`, requestData.data, {
        headers: { 'x-workspace-id': requestData.workspaceId },
      })
      .then((response) => response.data);
  };

  /*
   * Создание воркспейса
   * @see: https://reference.api.team-master-dev.hypetrain.io/?http#get-all-user-39-s-workspaces
   */
  public createWorkspace = (
    params: ICreateCollaborativeWorkspaceDto
  ): Promise<IWorkspaceInfoResponseDto> => {
    return this.api.post('/workspaces', params).then((response) => response.data);
  };

  /*
   * Создание legal entity
   */
  public createLegalEntity = (params: ILegalEntityDto): Promise<ILegalEntityInfoResponseDto> => {
    return this.api.post('/workspaces/legal-entities', params).then((response) => response.data);
  };

  /*
   * Получение всех legal entities
   */
  public getLegalEntities = (): Promise<ILegalEntityInfoResponseDto[]> => {
    return this.api.get('/workspaces/legal-entities').then((response) => response.data);
  };

  /*
   * Удаление legal entity
   */
  public deleteLegalEntities = (legalEntityId: string): Promise<void> => {
    return this.api
      .deleteMethod(`/workspaces/legal-entities/${legalEntityId}`)
      .then((response) => response.data);
  };

  /*
   * Обновление legal entity
   */
  public updateLegalEntity = (
    legalEntity: ILegalEntityInfoResponseDto
  ): Promise<ILegalEntityInfoResponseDto> => {
    return this.api
      .put(
        `/workspaces/legal-entities/${legalEntity.id}`,
        legalEntity,
        {},
        { errorCodesToIgnore: ['input-validation-error'] }
      )
      .then((response) => response.data);
  };

  /*
   * Получение информации об инвайте
   * @see: https://reference.api.team-master-dev.hypetrain.io/?shell#get-workspace-invite-public-info-by-id
   */
  public getInviteInfo = (params: {
    workspaceId: string;
    inviteId: string;
  }): Promise<IWorkspaceInvitePublicInfoResponseDto> => {
    return this.api
      .get(
        `/workspaces/${params.workspaceId}/invites/${params.inviteId}`,
        {},
        { errorCodesToIgnore: GET_INVITE_INFO_ERROR_CODES }
      )
      .then((response) => response.data);
  };

  /*
   * Принятие инвайта
   * @see: https://reference.api.team-master-dev.hypetrain.io/?shell#accept-workspace-invite-for-registered-users
   */
  public acceptInvite = (params: TPersonCreationProps): Promise<IWorkspaceInfoResponseDto> => {
    return this.api
      .post(
        `/workspaces/${params.workspaceId}/invites/${params.inviteId}/accept`,
        params.personInfo,
        {},
        {
          errorCodesToIgnore: [...INVITE_ERROR_CODES, ...GET_INVITE_INFO_ERROR_CODES],
        }
      )
      .then((response) => response.data);
  };
}

export const workspaceApiService = new WorkspaceApiService(apiService);
