// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal__j56FH{padding:var(--vSpacing30) var(--hSpacing30)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Modal/Modal.module.scss"],"names":[],"mappings":"AAEA,cACE,2CAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.modal {\n  padding: var(--vSpacing30) var(--hSpacing30);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal__j56FH"
};
export default ___CSS_LOADER_EXPORT___;
