import { APP_AUTH_TYPES } from '@services/initApp/initApp.constants';
import { USERS_ACTIONS, usersActionsLogService } from '@services/usersActionsLog';
import { AUTHENTICATION_ORIGIN_PAGE } from '@enums/logUsersActions.enum';

export const logGoogleAuthInitiated = (type: APP_AUTH_TYPES) => {
  if (type === APP_AUTH_TYPES.GOOGLE) {
    usersActionsLogService.log(USERS_ACTIONS.USER_GOOGLE_AUTHENTICATION_INITIATED, {
      authenticationOriginPage:
        window.location.pathname === '/login'
          ? AUTHENTICATION_ORIGIN_PAGE.LOGIN_PAGE
          : AUTHENTICATION_ORIGIN_PAGE.REGISTRATION_PAGE,
    });
  }
};
