/*
 * Checkbox group with ages component
 */
import compact from 'lodash/compact';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import { FC, memo, useMemo } from 'react';
import { Checkbox } from '@uikit/components/Checkbox';
import { CheckboxGroup, TCheckboxGroupValues } from '@uikit/components/CheckboxGroup';
import { transformDtoToFilterModel } from '@uikit/components/Filter/filter.utils';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { ageChipFormatter } from './ageChipFormatter.utils';

type TAgeCheckboxFilterProps = {
  options: TCheckboxGroupValues;
  initialValue: TFilter;
  hasPercentage: boolean;
  onChange: (value: TFilter) => void;
  value: TFilter;
  className?: string;
};

export const AgeCheckboxGroup: FC<TAgeCheckboxFilterProps> = memo(
  ({ options, initialValue, hasPercentage, onChange, value, className }) => {
    const preparedValues = useMemo(() => {
      // получаем границы промежутка
      const [min, max] = compact(value.values?.[0]?.split(/[-+\s]/)); // "21 - 31" => [21, 31]
      const newValues: TCheckboxGroupValues = {};

      forEach(options, (checkboxValue, key) => {
        // нормализируем значения удаляя +, -, и пробелы,
        // затем проверяем входит ли число в общий промежуток,
        // если входит выставляем ему selected=true
        newValues[key] = compact(key.split(/[–+\s]/)).every((val) => {
          const preparedValue = Number(val);
          return preparedValue >= Number(min) && preparedValue <= Number(max);
        });
      });
      return newValues;
    }, [value.values, options]);

    const onCheck = (checkboxValues: TCheckboxGroupValues): void => {
      // отфильтровываем только выбранные чекбоксы
      const filteredValues = pickBy(checkboxValues, (checkboxValue) => checkboxValue);

      if (isEmpty(filteredValues)) {
        onChange(transformDtoToFilterModel(initialValue, hasPercentage));
        return;
      }
      // среди выбранных находим общее максимальное и минимальное значение
      const { min, max } = ageChipFormatter(filteredValues, options);

      onChange({
        ...value,
        values: [`${min}-${max}`],
      } as TFilter);
    };

    return (
      <CheckboxGroup
        value={preparedValues}
        className={className}
        onChange={onCheck}
      >
        {Object.keys(options).map((option) => (
          <Checkbox
            key={option}
            nameInGroup={option}
            label={option}
          />
        ))}
      </CheckboxGroup>
    );
  }
);
