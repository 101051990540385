/*
  Утилка для проверки соответствует ли тип файла одному из типов переданных в виде ".csv, text/csv"
*/
export const isFileAccepted = (file: File, acceptedFileTypes?: string): boolean => {
  if (!file || !acceptedFileTypes) return true;

  const acceptedFileTypesArray = acceptedFileTypes.split(',');
  const fileName = file.name || '';
  const mimeType = (file.type || '').toLowerCase();

  return acceptedFileTypesArray.some((type) => {
    const normalizedType = type.trim().toLowerCase();
    /*
    Если это тип вида .txt, то проверяем заканчиватся ли имя файла этим типом
    */
    if (normalizedType.charAt(0) === '.') {
      return fileName.toLowerCase().endsWith(normalizedType);
    }

    /*
    Иначе просто сравниваем MIME типы
    */
    return mimeType === normalizedType;
  });
};

/*
 * Подготавливает строку для отображения размера файла
 * 16 => 16b, 731484 => 714kb
 */
export const prepareFileSize = (size: number): string => {
  const units = ['b', 'kb', 'mb', 'gb', 'tb'];
  let bytes = size;
  let degree = 0;

  // в цикле делим размер файла на 1024 чтобы вычислить кратность
  while (bytes >= 1024) {
    bytes /= 1024;
    degree += 1;
  }

  return `${Math.round(bytes)}${units[degree]}`;
};

/*
  Скачивание файла по ссылке
*/
export const downloadByUrl = (fileName: string, fileUrl: string): void => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
