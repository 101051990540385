/*
 * Дефолтная опция селекта
 */
import { FC } from 'react';
import { OptionProps } from 'react-select';
import { ListItem } from '@uikit/components/ListItem';

export const SelectFieldOption: FC<OptionProps<{ label: string; value: string }>> = ({
  innerProps,
  innerRef,
  data,
}) => (
  <ListItem
    mainText={data.label}
    innerProps={innerProps}
    innerRef={innerRef}
  />
);
