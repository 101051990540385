/*
 * Radio component.
 * For now, it is meant to be used only wrapped in RadioGroup.
 */
import cn from 'clsx';
import { FC, useState } from 'react';
import { PopupPositions } from '@uikit/components/Popup';
import { useRadioGroup } from '@uikit/components/RadioGroup/RadioGroup.context';
import { Tooltip } from '@uikit/components/Tooltip';
import style from './Radio.module.scss';

type TRadioProps = {
  value: unknown;
  label: string;
  isThinLabel?: boolean;
  isVertical?: boolean;
  disable?: boolean;
  tooltip?: string;
};

// TODO: Использовать useId из React 18 после его внедрения.
export const Radio: FC<TRadioProps> = ({
  value,
  label,
  isThinLabel = false,
  isVertical = false,
  disable = false,
  tooltip,
}) => {
  const [inputId] = useState(Math.random().toString());
  const radioGroup = useRadioGroup();

  return (
    <label
      className={cn(style.radio, isVertical && style.radio__vertical)}
      htmlFor={inputId}
    >
      <input
        type="radio"
        id={inputId}
        className={cn(disable && style.radio__disableInput)}
        checked={value === radioGroup?.radioGroupValue}
        onChange={() => radioGroup?.onChangeRadioGroup(value)}
        disabled={disable}
      />

      <Tooltip
        disable={!tooltip}
        position={PopupPositions.BottomCenter}
        content={<div>{tooltip}</div>}
      >
        <span
          className={cn(
            style.radio__label,
            isThinLabel && style.radio__thinLabel,
            disable && style.radio__disableText,
            tooltip && style.radio__withTooltip
          )}
        >
          {label}
        </span>
      </Tooltip>
    </label>
  );
};
