import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { PopupEvents, PopupPositions } from '@uikit/components/Popup';
import { Icon, ICON_TYPES } from '@uikit/components/Icon';
import { TextEllipsis } from '@uikit/components/TextEllipsis';
import { Tooltip } from '@uikit/components/Tooltip';
import {
  TooltipActionMenu,
  TTooltipActionMenuItem,
} from '@uikit/components/Tooltip/components/TooltipActionMenu';
import styles from './Chip.module.scss';

type TChip = PropsWithChildren<{
  className?: string;
  onDelete?: () => void;
  actions?: TTooltipActionMenuItem[];
  withDelete?: boolean; // delete available
}>;

export const Chip: FC<TChip> = ({
  children,
  className,
  onDelete = null,
  actions,
  withDelete = true,
}) => (
  <div className={cn(styles.chip, [className])}>
    <TextEllipsis className={styles.chip__text}>{children}</TextEllipsis>
    {!!actions?.length && (
      <Tooltip
        content={<TooltipActionMenu actions={actions} />}
        position={PopupPositions.BottomCenter}
        event={PopupEvents.Click}
        offset={20}
      >
        <div className={styles.chip__menuIcon} />
      </Tooltip>
    )}
    {onDelete && withDelete && (
      <Icon
        className={styles.chip__deleteIcon}
        onClick={onDelete}
        type={ICON_TYPES.CLOSE}
        size={8}
      />
    )}
  </div>
);
