import cn from 'clsx';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { usePopup } from '@uikit/components/Popup';
import { TTooltipActionMenuItem } from '@uikit/components/Tooltip/components/TooltipActionMenu';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import styles from './TooltipActionWithConfirmation.module.scss';

type TTooltipActionWithConfirmationProps = {
  onClick: (e: MouseEvent<HTMLElement>) => void;
  className?: string;
  action: TTooltipActionMenuItem;
};

export const TooltipActionWithConfirmation: FC<TTooltipActionWithConfirmationProps> = ({
  onClick,
  className,
  action,
}) => {
  const { popupStore } = usePopup();
  const { isShow } = popupStore;
  const { t } = useUiKitTranslation();
  const [isSure, setIsSure] = useState(false);

  useEffect(() => {
    if (!isShow) {
      setIsSure(false);
    }
  }, [isShow]);

  if (isSure) {
    return (
      <div
        onClick={onClick}
        className={cn(styles.item, styles.warning, className)}
      >
        {action?.icon && (
          <img
            src={action.icon}
            alt={action.text}
          />
        )}
        <div>{t('tooltipActionWithConfirmation.sure')}</div>
      </div>
    );
  }

  return (
    <div
      onClick={() => setIsSure(true)}
      className={cn(styles.item, className)}
    >
      {action?.icon && (
        <img
          src={action.icon}
          alt={action.text}
        />
      )}
      <div>{action?.text}</div>
    </div>
  );
};
