/*
 * Types for Icon component.
 */

/*
 * Icon style (color and etc) types.
 */
export enum ICON_STYLES {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  INVERTED = 'INVERTED',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  WARNING = 'WARNING',
  INFORMATIVE = 'INFORMATIVE',
}
