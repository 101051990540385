import { FC, PropsWithChildren } from 'react';
import { PopupPositions } from '@uikit/components/Popup';
import { Tooltip } from '@uikit/components/Tooltip';
import styles from './CellWithTooltip.module.scss';

type TCellWithTooltipProps = {
  textTooltip: string;
  width?: number;
};

export const CellWithTooltip: FC<PropsWithChildren<TCellWithTooltipProps>> = ({
  children,
  textTooltip,
  width = 238,
}) => (
  <Tooltip
    position={PopupPositions.BottomCenter}
    content={<span>{textTooltip}</span>}
    width={width}
    className={styles.cellWithTooltip}
  >
    {children}
  </Tooltip>
);
