/*
 * Утилиты компонента аватар инфлюенсера.
 */
import { SmaPlatform } from '@hypetrainCommon';
import { AVATAR_SIZES, DEFAULT_AVATAR_SIZE } from '@uikit/components/Avatar';
import { prepareYoutubeChannelThumbnail } from '@utils/thumbnail.utils';

/*
 * Возвращает ссылку на аватар инфлюенсера оптимального размера.
 */
export const getOptimazedInfluencerAvatar = (
  img: string,
  platform: SmaPlatform,
  size: AVATAR_SIZES = DEFAULT_AVATAR_SIZE
): string => {
  switch (platform) {
    case SmaPlatform.Youtube:
      return prepareYoutubeChannelThumbnail(img, size);
    default:
      return img;
  }
};
