export const colorCountries: Record<string, string> = {
  AF: '#00A2E0',
  AX: '#006DA7',
  AL: '#E1D6E9',
  DZ: '#F4EF86',
  AS: '#0B3190',
  AD: '#0B3190',
  AO: '#DEAF43',
  AI: '#0E3CAF',
  AQ: '#D6E8DB',
  AG: '#B7BF33',
  AR: '#5D8EBB',
  AM: '#00A2E0',
  AW: '#DDE835',
  AU: '#D9AF65',
  AT: '#E77712',
  AZ: '#FFD964',
  BS: '#68BE8E',
  BH: '#F19EC2',
  BD: '#F19EC2',
  BB: '#00479D',
  BY: '#EE0808',
  BE: '#00A2E0',
  BZ: '#F5D962',
  BJ: '#B6DDEB',
  BM: '#104A2D',
  BT: '#EE0707',
  BO: '#B7BF33',
  BA: '#9EAB6D',
  BW: '#0B3190',
  BV: '#0B3190',
  BR: '#FFED51',
  IO: '#104A2D',
  BN: '#19B969',
  BG: '#00CD67',
  BF: '#4B9531',
  BI: '#19B969',
  KH: '#B7BF33',
  CM: '#9A9FBC',
  CA: '#00A77C',
  CV: '#BF8733',
  KY: '#EEE867',
  CF: '#667E2E',
  TD: '#00A2E0',
  CL: '#68BE8E',
  CN: '#F4EF86',
  CX: '#FFED51',
  CC: '#887D43',
  CO: '#00AC5C',
  KM: '#BF6533',
  CG: '#F19EC2',
  CD: '#88888F',
  CK: '#E95484',
  CR: '#E13728',
  CI: '#68BE8E',
  HR: '#EB616F',
  CU: '#667E2E',
  CY: '#E95484',
  CZ: '#F1B31D',
  DK: '#933481',
  DJ: '#0B3190',
  DM: '#F86659',
  DO: '#E95484',
  EC: '#00A2E0',
  EG: '#4AC1B5',
  SV: '#00757F',
  GQ: '#EFDC34',
  ER: '#FFED51',
  EE: '#E95484',
  ET: '#68BE8E',
  FK: '#1294DA',
  FO: '#12C7BC',
  FJ: '#68BE8E',
  FI: '#B6DDEB',
  FR: '#B7BF33',
  GF: '#7FC04E',
  PF: '#D7E12F',
  TF: '#4158D6',
  GA: '#4F82AE',
  GM: '#FFB800',
  GE: '#FF9900',
  DE: '#88888d',
  GH: '#E13728',
  GI: '#2CD0DB',
  GR: '#00A2E0',
  GL: '#C159CA',
  GD: '#C71E0F',
  GP: '#D0DA2A',
  GU: '#006DA7',
  GT: '#68BE8E',
  GG: '#0E9DE9',
  GN: '#B7BF33',
  GW: '#FFED51',
  GY: '#EB5520',
  HT: '#2E6C17',
  HM: '#8E96C6',
  VA: '#F2F554',
  HN: '#D94574',
  HK: '#FF0000',
  HU: '#00479D',
  IS: '#FF008E',
  IN: '#4158D6',
  ID: '#E77712',
  IR: '#68BE8E',
  IQ: '#CAD333',
  IE: '#47C980',
  IM: '#FF7A00',
  IL: '#4158D6',
  IT: '#4B9531',
  JM: '#00A2E0',
  JP: '#006D3B',
  JE: '#769AAD',
  JO: '#00AC5C',
  KZ: '#E95484',
  KE: '#436486',
  KI: '#E1BA2F',
  KR: '#0E96DF',
  KW: '#CF005C',
  KG: '#FF4545',
  LA: '#006DA7',
  LV: '#4158D6',
  LB: '#E77712',
  LS: '#E77715',
  LR: '#00A2E0',
  LY: '#9EAB6D',
  LI: '#2DB6AE',
  LT: '#68BE8E',
  LU: '#862020',
  MO: '#FF7A00',
  MK: '#E13728',
  MG: '#00A2E0',
  MW: '#D9AF65',
  MY: '#9A9FFF',
  MV: '#DD0C69',
  ML: '#7F98BA',
  MT: '#55DC26',
  MH: '#4158D6',
  MQ: '#E13728',
  MR: '#E95484',
  MU: '#006DA7',
  YT: '#BF6533',
  MX: '#006DA7',
  FM: '#41BBD6',
  MD: '#B7BF33',
  MC: '#E95555',
  MN: '#7F98BA',
  ME: '#4EB9AE',
  MS: '#4158D6',
  MA: '#0B3190',
  MZ: '#FFD900',
  MM: '#68BE8E',
  NA: '#E46C9B',
  NR: '#F34B5D',
  NP: '#E77712',
  NL: '#CF005C',
  AN: '#FF7641',
  NC: '#E95484',
  NZ: '#00AC5C',
  NI: '#0B3190',
  NE: '#CF005C',
  NG: '#006DA7',
  NU: '#B54CF5',
  NF: '#2CAF2A',
  MP: '#1AB99C',
  NO: '#F1EC76',
  OM: '#E95484',
  PK: '#88888D',
  PW: '#2E9BA1',
  PS: '#D2F20A',
  PA: '#EBC564',
  PG: '#9A9FBC',
  PY: '#4B3066',
  PE: '#E13728',
  PH: '#4B9531',
  PN: '#2E90C7',
  PL: '#9A9FBC',
  PT: '#B01F2D',
  PR: '#C40D23',
  QA: '#006DA7',
  RE: '#E95484',
  RO: '#FFED51',
  RU: '#85674E',
  RW: '#EEE867',
  BL: '#FDCD3C',
  SH: '#2E4EFF',
  KN: '#38C782',
  LC: '#7687BC',
  MF: '#EDE75B',
  PM: '#F08019',
  VC: '#904CB0',
  WS: '#E12FAF',
  SM: '#F98870',
  ST: '#219EE0',
  SA: '#DEAF43',
  SN: '#006DA7',
  RS: '#FFD900',
  SC: '#D8E052',
  SL: '#E95484',
  SG: '#21CD86',
  SK: '#D09156',
  SI: '#85C7DE',
  SB: '#B7BF33',
  SO: '#00A77C',
  ZA: '#B7BF33',
  GS: '#006DA7',
  ES: '#FFD900',
  LK: '#E13728',
  SD: '#4158D6',
  SR: '#006DA7',
  SJ: '#D3CE55',
  SZ: '#B03C6B',
  SE: '#00A2E0',
  CH: '#104A2D',
  SY: '#4B9531',
  TW: '#8BCF1C',
  TJ: '#A99C27',
  TZ: '#E95484',
  TH: '#E95484',
  TL: '#5FCC38',
  TG: '#FFD900',
  TK: '#E1642F',
  TO: '#7687BC',
  TT: '#68BE8E',
  TN: '#E3007E',
  TR: '#006DA7',
  TM: '#C9C9C9',
  TC: '#B03C6B',
  TV: '#909722',
  UG: '#104A2D',
  UA: '#1CA194',
  AE: '#FFED51',
  GB: '#006DA7',
  US: '#9A9FBC',
  UM: '#7687BC',
  UY: '#ECAA83',
  UZ: '#FFD900',
  VU: '#00A2E0',
  VE: '#887D43',
  VN: '#21AFE5',
  VG: '#293DAB',
  VI: '#B6BBD6',
  WF: '#54E0A6',
  EH: '#00A77C',
  YE: '#006DA7',
  ZM: '#9A9FBC',
  ZW: '#A6D4AD',
  ZZ: '#DADADA',
  OZ: '#E4E4E4',
};
