/*
 * Service for executing requests for entities in campaigns.
 */
import { ApiService, apiService } from '@api';
import {
  ICampaignDealContractInfoResponseDto,
  ICampaignDealPromoContentDto,
  ICampaignDealResponseDto,
  ICampaignFullInfoDto,
  ICampaignListItemDto,
  ICampaignSimpleInfoDto,
  ICreateCampaignDto,
  IPaginatedDto,
} from '@hypetrainCommon';
import { ACCES_DENIED_ERRORS, GET_PROMO_CONTENT } from '@api/campaigns/campaigns.api.constants';
import {
  IGetCampaignDealsParams,
  IGetCampaignsParams,
  TCampaignDealsResponse,
  TCreateCampaignDealsParams,
  TDeleteCampaignDealsParams,
  TGetCampaignsById,
  TGetTrackingLink,
  TLinkPersonToDealParams,
  TSetPersonCampaignConfigParams,
  TSetTrackingLink,
  TUpdateCampaignsParams,
  TUpdateContract,
  TUpdateDealTermsRequirements,
  TUpdateRecruitmentStatus,
} from '@api/campaigns/campaigns.api.types';
import { prepareDeals } from '@api/campaigns/campaigns.api.utils';

/* eslint-disable arrow-body-style */
export class CampaignsApiService {
  constructor(private readonly api: ApiService) {}

  /*
   * Получает список компаний.
   * @see https://reference.api.rc-staging.hypetrain.io/?http#get-campaigns
   */
  public getCampaigns = (
    params: IGetCampaignsParams
  ): Promise<IPaginatedDto<ICampaignListItemDto>> => {
    return this.api.get('/campaigns', { params }).then((data) => data.data);
  };

  /*
   * Получает упрощенный список компаний.
   * @see https://reference.api.test.hypetrain.io/#get-simplified-campaign-list
   */
  public getCampaignsSimpleList = (): Promise<ICampaignSimpleInfoDto[]> => {
    return this.api
      .get('/campaigns/simple-list', {}, { showLoader: false })
      .then((data) => data.data);
  };

  /*
   * Создаёт компанию.
   */
  public createCampaign = (campaignInfo: ICreateCampaignDto): Promise<ICampaignFullInfoDto> => {
    return this.api.post('/campaigns', campaignInfo).then((data) => data.data);
  };

  /*
   * Обновляет компанию.
   * @see https://reference.api.rc-staging.hypetrain.io/?http#update-campaign
   */
  public updateCampaign = ({
    campaignId,
    body,
  }: TUpdateCampaignsParams): Promise<ICampaignFullInfoDto> => {
    return this.api.patch(`/campaigns/${campaignId}`, body).then((data) => data.data);
  };

  /*
   * Получает deals компании.
   * @see https://reference.api.rc-staging.hypetrain.io/#get-campaign-deals
   */
  public getCampaignDeals = ({
    campaignId,
    signal,
  }: IGetCampaignDealsParams): Promise<TCampaignDealsResponse> => {
    return this.api
      .get(
        `/campaigns/${campaignId}/deals`,
        { signal },
        {
          errorCodesToIgnore: ACCES_DENIED_ERRORS,
        }
      )
      .then((data) => prepareDeals(data.data));
  };

  /*
   * Поиск по deals компании.
   * @see https://reference.api.rc-staging.hypetrain.io/#search-campaign-deals
   */
  public searchCampaignDeals = ({
    campaignId,
    signal,
    searchTerm,
  }: IGetCampaignDealsParams): Promise<TCampaignDealsResponse> => {
    return this.api
      .get(`/campaigns/${campaignId}/deals/search`, { signal, params: { searchTerm } })
      .then((data) => prepareDeals(data.data));
  };

  /*
   * Получает deal компании.
   * @see https://reference.api.rc-staging.hypetrain.io/#get-campaign-deal-by-id
   */
  public getCampaignDeal = ({
    campaignId,
    dealId,
  }: {
    campaignId: string;
    dealId: string;
  }): Promise<ICampaignDealResponseDto> => {
    return this.api.get(`/campaigns/${campaignId}/deals/${dealId}`).then((data) => data.data);
  };

  /*
   * Удаляет deal компании.
   * @see https://reference.api.rc-staging.hypetrain.io/#delete-campaign-deal
   */
  public deleteCampaignDeal = ({
    campaignId,
    dealId,
  }: {
    campaignId: string;
    dealId: string;
  }): Promise<ICampaignDealResponseDto> => {
    return this.api
      .deleteMethod(`/campaigns/${campaignId}/deals/${dealId}`)
      .then((data) => data.data);
  };

  /*
   * Получает информацию о компании.
   * @see https://reference.api.rc-staging.hypetrain.io/#get-campaign-by-id
   */
  public getCampaignsById = ({ campaignId }: TGetCampaignsById): Promise<ICampaignFullInfoDto> => {
    return this.api
      .get(
        `/campaigns/${campaignId}`,
        {},
        {
          errorCodesToIgnore: ACCES_DENIED_ERRORS,
        }
      )
      .then((data) => data.data);
  };

  /*
   * Удаляет deals компании.
   * @see https://reference.api.rc-staging.hypetrain.io/?http#delete-campaign-deal
   */
  public deleteCampaignDeals = ({
    campaignId,
    dealsIds,
  }: TDeleteCampaignDealsParams): Promise<ICampaignFullInfoDto> => {
    return this.api
      .deleteMethod(`/campaigns/${campaignId}/deals`, { data: { dealsIds } })
      .then((data) => data.data);
  };

  /*
   * Добавление SMAs в компанию.
   * @see https://reference.api.rc-staging.hypetrain.io/#create-campaign-deals
   */
  public createCampaignDeals = ({
    campaignId,
    body,
  }: TCreateCampaignDealsParams): Promise<void> => {
    return this.api.post(`/campaigns/${campaignId}/deals`, body).then((data) => data.data);
  };

  /*
   * Установка конфигурации (фильтров/сортировка) отображения компании.
   * @see https://reference.api.rc-staging.hypetrain.io/?http#set-person-campaign-config
   */
  public setPersonCampaignConfig = ({
    campaignId,
    body,
    signal,
  }: TSetPersonCampaignConfigParams): Promise<void> => {
    return this.api
      .post(`/campaigns/${campaignId}/person-config`, body, { signal }, { showLoader: false })
      .then((data) => data.data);
  };

  /*
   * Добавление контакта к сделке.
   * @see https://reference.api.rc-staging.hypetrain.io/?javascript#link-persons-to-deal
   */
  public linkPersonToDeal = ({
    campaignId,
    dealId,
    body,
  }: TLinkPersonToDealParams): Promise<ICampaignDealResponseDto> => {
    return this.api
      .post(`/campaigns/${campaignId}/deals/${dealId}/persons`, body)
      .then((data) => data.data);
  };

  /*
   * Обновление дила
   * @see https://reference.api.team-f-dev.hypetrain.io/#update-campaign-deal
   */
  public updateDealTermsRequirements = ({
    campaignId,
    dealId,
    body,
  }: TUpdateDealTermsRequirements): Promise<ICampaignDealResponseDto> => {
    return this.api
      .patch(`/campaigns/${campaignId}/deals/${dealId}`, body, {}, { showLoader: false })
      .then((data) => data.data);
  };

  /*
   * Обновление состояния в стадии
   * @see https://reference.api.team-f-dev.hypetrain.io/#set-campaign-deal-stage-state
   */
  public updateDealStageStatus = ({
    campaignId,
    dealId,
    body,
  }: TUpdateRecruitmentStatus): Promise<ICampaignDealResponseDto> => {
    return this.api
      .put(`/campaigns/${campaignId}/deals/${dealId}/stage-state`, body, {}, { showLoader: true })
      .then((data) => data.data);
  };

  /*
   * Обновление данных для контракта
   * @see https://api.contracting.dynamics.hypetrain.io/docs#/campaign/CampaignController_updateCampaignDealContractData
   */
  public updateDealContract = ({
    campaignId,
    dealId,
    body,
  }: TUpdateContract): Promise<ICampaignDealContractInfoResponseDto> => {
    return this.api
      .put(`/campaigns/${campaignId}/deals/${dealId}/contract`, body, {}, { showLoader: true })
      .then((data) => data.data);
  };

  /*
   * Получение данных для контракта
   * @see https://api.contracting.dynamics.hypetrain.io/docs#/campaign/CampaignController_updateCampaignDealContractData
   */
  public getDealContract = ({
    campaignId,
    dealId,
    showLoader = true,
  }: TUpdateContract): Promise<ICampaignDealContractInfoResponseDto> => {
    return this.api
      .get(`/campaigns/${campaignId}/deals/${dealId}/contract`, {}, { showLoader })
      .then((data) => data.data);
  };

  /*
   * Добавление поста для трекинга
   */
  public addTrackingPost = ({
    campaignId,
    dealId,
    body,
  }: TSetTrackingLink): Promise<ICampaignDealPromoContentDto> => {
    return this.api
      .post(
        `/campaigns/${campaignId}/deals/${dealId}/promo-content`,
        body,
        {},
        { showLoader: false }
      )
      .then((data) => data.data);
  };

  /*
   * Получение отслеживаемого поста
   */
  public getTrackingPost = ({
    campaignId,
    dealId,
  }: TGetTrackingLink): Promise<ICampaignDealPromoContentDto> => {
    return this.api
      .get(
        `/campaigns/${campaignId}/deals/${dealId}/promo-content`,
        {},
        {
          errorCodesToIgnore: GET_PROMO_CONTENT,
        }
      )
      .then((response) => response.data);
  };
}

export const campaignsApiService = new CampaignsApiService(apiService);
