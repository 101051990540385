import { useTranslation } from 'react-i18next';
import emptyIcon from '@assets/images/addressBook/emptyAddressBook.svg';
import styles from './EmptyAddressBook.module.scss';

export const EmptyAddressBook = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.emptyContainer}>
      <img
        src={emptyIcon}
        alt="emptyIcon"
      />
      <div className={styles.firstTitle}>{t('emptyAddressBook')}</div>
      <div className={styles.secondTitle}>{t('pressAddContact')}</div>
    </div>
  );
};
