/*
 * Global modal store.
 */
import { makeAutoObservable } from 'mobx';
import { FunctionComponent } from 'react';
import { TModalProps } from '@uikit/components/Modal/modal.types';

export type TModalConfig<P = Record<never, never>, D = never, R = never> = {
  id: string;
  component: FunctionComponent<TModalProps<P, D, R>>;
  props: TModalProps<P, D, R>;
};

export class ModalStore {
  /*
   * Displayed modals.
   */
  public modals: TModalConfig[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  /*
   * Add modal for modals.
   */
  public add<P, D, R>(config: TModalConfig<P, D, R>): void {
    this.modals.push(config as TModalConfig<unknown, D, R>);
  }

  /*
   * Delete (hide) modal.
   */
  public delete(id: string): void {
    this.modals = this.modals.filter((modals) => modals.id !== id);
  }
}

const createModalStore = () => new ModalStore();

export const modalStore = createModalStore();
