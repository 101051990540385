/*
 * Модель воркспейсов.
 */
import { createEffect, createStore } from 'effector/compat';
import { IPersonWorkspaceResponseDto, IWorkspaceInfoResponseDto } from '@hypetrainCommon';
import { workspaceApiService } from '@api/workspace';
import { getInitAppDataFx } from '@models/appApi.model';
import { loginUserFx, loginUserWithGoogleFx, logoutUserFx } from '@models/auth.model';
import { resetWorkspaces } from './workspaces.events';

export type TWorkspaceMap = {
  active: IPersonWorkspaceResponseDto[];
  inactive: IPersonWorkspaceResponseDto[];
  pending: IPersonWorkspaceResponseDto[];
};

/*
 * Обновляет воркспейс.
 */
export const updateWorkspaceFx = createEffect(workspaceApiService.patchWorkspaces);

/*
 *  Валидация workspace name
 */
export const checkWorkspaceHandlerFx = createEffect(workspaceApiService.checkWorkspaceHandler);

/*
 * Стор воркспейсов (рабочих пространств пользователя).
 */
export const $workspacesStore = createStore<TWorkspaceMap | null>(null)
  .on(getInitAppDataFx.doneData, (_, initData) => initData?.workspaces)
  .reset(resetWorkspaces);

/*
 * Стор текущего воркспейса
 * Последний воркспейс, в котором работал пользователь.
 * Не путать с $activeWorkspaceStore (!!!)
 */
export const $currentWorkspace = createStore<IWorkspaceInfoResponseDto | null | undefined>(null)
  .on(loginUserFx.doneData, (state, payload) => payload?.workspace)
  .on(loginUserWithGoogleFx.doneData, (_, payload) => payload?.workspace)
  .reset(logoutUserFx.done);
