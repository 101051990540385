// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltipAction__DLSVK{display:flex;align-items:center;cursor:pointer;padding:var(--vSpacing4)}.tooltipAction__DLSVK img{margin-right:var(--vSpacing4)}.tooltipAction_disable__WLcKX{opacity:.5;cursor:default}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Tooltip/components/TooltipAction/TooltipAction.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,wBAAA,CAEA,0BACE,6BAAA,CAGF,8BACE,UAAA,CACA,cAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.tooltipAction {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  padding: var(--vSpacing4);\n\n  img {\n    margin-right: var(--vSpacing4);\n  }\n\n  &_disable {\n    opacity: 0.5;\n    cursor: default;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipAction": "tooltipAction__DLSVK",
	"tooltipAction_disable": "tooltipAction_disable__WLcKX"
};
export default ___CSS_LOADER_EXPORT___;
