/*
 * Утилиты страницы Browse.
 */
import {
  IInstagramFilteringRequestDto,
  IInstagramSmaStatisticsSimpleInfoDto,
  IYoutubeSmaStatisticsSimpleInfoDto,
  IYoutubeSmaStatisticsSortDto,
  InstagramSmaStatisticsBaseSort,
  InstagramSmaStatisticsSortName,
  SortOrder,
  TiktokSmaStatisticsBaseSort,
  TiktokSmaStatisticsSortName,
  YoutubeSmaStatisticsSortName,
} from '@hypetrainCommon';
import { TFilterConfig } from '@uikit/components/Filter';
import { TFilterDto } from '@uikit/components/Filters/filters.types';
import { isFilterHasValue } from '@uikit/components/Filters/filters.utils';
import { TSortItem } from '@uikit/components/Sorts';

/*
 * Преобразует фильтры и ключевые слова для их отправки на backend.
 */
export function getBrowseFiltersDTO(filtersConfigs: TFilterConfig[]): TFilterDto[] {
  return filtersConfigs
    .filter(isFilterHasValue)
    .map((filter) => filter.formatValueToDTO!(filter.filterValue)); // eslint-disable-line @typescript-eslint/no-non-null-assertion
}

/*
 * Преобразует сортировки для их отправки на backend.
 */
export function getBrowseSortsDTO(
  sorts: TSortItem[]
): IYoutubeSmaStatisticsSortDto[] | IInstagramFilteringRequestDto[] | undefined {
  const sortsDTO = sorts
    .filter((sort) => sort.order)
    .map((sort) => ({
      name: sort.id as YoutubeSmaStatisticsSortName,
      options: {
        order: sort.order as SortOrder,
      },
      extra: sort.extra,
    }));

  return sortsDTO.length ? sortsDTO : undefined;
}

/*
 * Подготовка сортировок при изменении фильтров
 */
export function prepareBrowseSorts(
  sorts: TSortItem[],
  hasActiveFilter: boolean,
  hasActiveAudienceFilters: boolean
): TSortItem[] {
  return sorts.map((sort) => {
    // если сортировка по views для youtube или по followers для tiktok или instagram была выставлена заранее,
    // то оставляем, если нет, то выставляем Descending
    if (!hasActiveFilter) {
      switch (sort.id) {
        case YoutubeSmaStatisticsSortName.Reach:
        case InstagramSmaStatisticsSortName.FollowersCount:
        case TiktokSmaStatisticsSortName.FollowersCount:
          return { ...sort, order: sort.order || SortOrder.Descending };
        case YoutubeSmaStatisticsSortName.TargetAudienceShare:
          return {
            ...sort,
            order: null,
            isDisabled: !hasActiveAudienceFilters,
          };
        default:
          return { ...sort, order: null };
      }
    }

    //  для всех остальных случаев кроме TargetAudienceShare просто возвращаем сортировки
    if (sort.id !== YoutubeSmaStatisticsSortName.TargetAudienceShare) return sort;

    //  для TargetAudienceShare дизейблим если не выставлены audience фильтры
    return {
      ...sort,
      order: hasActiveAudienceFilters ? sort.order : null,
      isDisabled: !hasActiveAudienceFilters,
    };
  });
}

/*
 * Обогощает smasStatistics ноутами
 */
export function updateDiscoveryNoteCounts(
  smasStatistics: IYoutubeSmaStatisticsSimpleInfoDto[] | IInstagramSmaStatisticsSimpleInfoDto[],
  changedSmaId: string,
  newCount: number
) {
  return smasStatistics.map((smaStatistic) => {
    if (smaStatistic.sma.uuid === changedSmaId) {
      return { ...smaStatistic, sma: { ...smaStatistic.sma, notesCount: newCount } };
    }

    return smaStatistic;
  });
}

/*
 * Преобразовывает backend модель в модель которую использует компонент сортировок в Browse
 */
export const transformBrowseSortFromDTO = (
  currentSorts: TSortItem[],
  newSorts:
    | IYoutubeSmaStatisticsSortDto
    | InstagramSmaStatisticsBaseSort
    | TiktokSmaStatisticsBaseSort
): TSortItem[] =>
  currentSorts?.map((sort) => ({
    ...sort,
    isVisible: true,
    order: sort.id === newSorts.name ? newSorts.options.order : null,
  }));
