// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".expandCell__RaJj4{display:flex;align-items:center}.expandCell__icon__zTfur{transform:rotate(90deg)}.expandCell__icon_top__lqhuV{transform:rotate(270deg)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Table/components/columns/ExpandCell/ExpandCell.module.scss"],"names":[],"mappings":"AAEA,mBACE,YAAA,CACA,kBAAA,CAEA,yBACE,uBAAA,CAEA,6BACE,wBAAA","sourcesContent":["@import '../../../../../assets/styles/ui_kit_mixins';\n\n.expandCell {\n  display: flex;\n  align-items: center;\n\n  &__icon {\n    transform: rotate(90deg);\n\n    &_top {\n      transform: rotate(270deg);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandCell": "expandCell__RaJj4",
	"expandCell__icon": "expandCell__icon__zTfur",
	"expandCell__icon_top": "expandCell__icon_top__lqhuV"
};
export default ___CSS_LOADER_EXPORT___;
