/* Компонент для воспроизведения Youtube видео в модалке */
import { FC } from 'react';
import Video, { ClassNames } from 'react-modal-video';
import styles from './ModalVideo.module.scss';

type TModalVideo = {
  isOpen: boolean;
  onClose?: () => void;
  videoId: string;
};

export const ModalVideo: FC<TModalVideo> = ({ isOpen, onClose, videoId }) => (
  <Video
    classNames={
      {
        modalVideo: styles.modalVideo,
        modalVideoClose: styles.modalVideoClose,
        modalVideoBody: styles.modalVideoBody,
        modalVideoInner: styles.modalVideoInner,
        modalVideoIframeWrap: styles.modalVideoMovieWrap,
        modalVideoCloseBtn: styles.modalVideoCloseBtn,
      } as ClassNames
    }
    channel="youtube"
    isOpen={isOpen}
    videoId={videoId}
    onClose={onClose}
  />
);
