// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inputFilter__wSfw1{margin-bottom:var(--hSpacing16)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Filter/components/InputFilter/InputFilter.module.scss"],"names":[],"mappings":"AAEA,oBACE,+BAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.inputFilter {\n  margin-bottom: var(--hSpacing16);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputFilter": "inputFilter__wSfw1"
};
export default ___CSS_LOADER_EXPORT___;
