/* Компонент отрисовывает график в виде горизонтальных столбчатых линий в профайле
 https://www.figma.com/file/jl6mAOWEYarcQ4TvtFIjMy/YouTube-profile?node-id=199%3A6627
 */
import cn from 'clsx';
import { FC } from 'react';
import { TFilter } from '@uikit/components/Filters/filters.types';
import {
  DISPLAYED_AGES,
  SEX_DEMOGRAPHIC,
  TGendersPerAges,
  TMaxAgeAudienceDemographic,
} from '../AudienceDemographic.types';
import { getAudienceDemographicColor } from '../audienceLines.constants';
import styles from './AudienceDemographic.module.scss';

type TDemographicGraphicProps = {
  gendersPerAges: TGendersPerAges;
  rangeAge: DISPLAYED_AGES;
  filterAudience: TFilter;
  maxAge: TMaxAgeAudienceDemographic;
  sex: SEX_DEMOGRAPHIC;
};

export const multiplySmallNumber = (value: number): number | string =>
  value ? value * 100 : 'N/A';

export const DemographicGraphic: FC<TDemographicGraphicProps> = ({
  gendersPerAges,
  rangeAge,
  filterAudience,
  maxAge,
  sex,
}) => {
  const sexData =
    sex === SEX_DEMOGRAPHIC.MALE
      ? gendersPerAges[rangeAge]?.male
      : gendersPerAges[rangeAge]?.female;

  return (
    <div
      className={cn(
        styles.demographicTable__graphic,
        sex === SEX_DEMOGRAPHIC.MALE && styles.demographicTable__graphic_male
      )}
    >
      <div
        style={{
          width: `${multiplySmallNumber(Number(sexData))}%`,
          background: getAudienceDemographicColor({ rangeAge, filterAudience, maxAge, sex }),
          height: 16,
        }}
      />
    </div>
  );
};
