// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filters__lnxDR{display:flex}.filters__container___xUjo{display:flex;flex-wrap:wrap}.filters__container___xUjo>*{margin-right:var(--vSpacing12)}.filters__icon__oVLlW{display:flex;flex-shrink:0;margin:6px var(--vSpacing12) 0 0}.filters__options__i_D43{margin-bottom:var(--hSpacing16)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Filters/Filters.module.scss"],"names":[],"mappings":"AAEA,gBACE,YAAA,CAEA,2BACE,YAAA,CACA,cAAA,CAEA,6BACE,8BAAA,CAIJ,sBACE,YAAA,CACA,aAAA,CACA,gCAAA,CAGF,yBACE,+BAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.filters {\n  display: flex;\n\n  &__container {\n    display: flex;\n    flex-wrap: wrap;\n\n    & > * {\n      margin-right: var(--vSpacing12);\n    }\n  }\n\n  &__icon {\n    display: flex;\n    flex-shrink: 0;\n    margin: 6px var(--vSpacing12) 0 0;\n  }\n\n  &__options {\n    margin-bottom: var(--hSpacing16);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": "filters__lnxDR",
	"filters__container": "filters__container___xUjo",
	"filters__icon": "filters__icon__oVLlW",
	"filters__options": "filters__options__i_D43"
};
export default ___CSS_LOADER_EXPORT___;
