/*
 * Утилиты связанные с подписками.
 */
import {
  IPersonWorkspaceResponseDto,
  ISubscriptionInfoResponseDto,
  ISubscriptionSimpleInfoDto,
  ITaxInfoResponseDto,
  SubscriptionPlanType,
  SubscriptionStatusType,
  TrialWillEndNotificationData,
} from '@hypetrainCommon';
import { formatCurrencyFn } from 'hypetrain-ui/src/components/Formats/FormatCurrency';
import i18n from '@services/i18n';
import { getDateUnitBefore } from '@utils/dateTime.utils';
import {
  ACTIVE_SUBSCRIPTION_STATUSES,
  INACTIVE_SUBSCRIPTION_STATUSES,
  SUBSCRIPTION_NAMES_LOCALES,
} from '@constants/subscription.constants';
import { TWorkspaceMap } from '@models/workspaces/workspaces.model';

/*
 * Статусы подписки отображаемые на платформе как deactivated.
 */
export const SUBSCRIPTION_DEACTIVATED_STATUSES: SubscriptionStatusType[] = [
  SubscriptionStatusType.incompleteExpired,
  SubscriptionStatusType.canceled,
  SubscriptionStatusType.incomplete,
  SubscriptionStatusType.unpaid,
];

const SUBSCRIPTION_STATUS_NAME_MAP: Record<SubscriptionStatusType, string> = {
  [SubscriptionStatusType.active]: i18n.t('subscriptionsStatuses.active'),
  [SubscriptionStatusType.trialing]: i18n.t('subscriptionsStatuses.trial'),
  [SubscriptionStatusType.incompleteExpired]: i18n.t('subscriptionsStatuses.deactivated'),
  [SubscriptionStatusType.canceled]: i18n.t('subscriptionsStatuses.deactivated'),
  [SubscriptionStatusType.pastDue]: i18n.t('subscriptionsStatuses.active'),
  [SubscriptionStatusType.incomplete]: i18n.t('subscriptionsStatuses.deactivated'),
  [SubscriptionStatusType.unpaid]: i18n.t('subscriptionsStatuses.deactivated'),
};

/*
 * Возвращает признак действующей подписки.
 */
export const isActiveSubscription = (
  subscription: ISubscriptionInfoResponseDto | ISubscriptionSimpleInfoDto
): boolean => ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status);

export const checkAddSeatsAvailable = (
  subscription: ISubscriptionInfoResponseDto | ISubscriptionSimpleInfoDto
): boolean =>
  isActiveSubscription(subscription) &&
  !subscription.endedAt &&
  subscription.status !== SubscriptionStatusType.pastDue;

/*
 * Возвращает признак недействующей подписки.
 */
export const isInactiveSubscription = (
  subscription?: ISubscriptionInfoResponseDto | ISubscriptionSimpleInfoDto | null
): boolean => !subscription || INACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status);

/*
 * Возвращает удобочитаемую стоимость подписки.
 * По умолчанию колличество ситов, цена сита и валюта берутся из переданной подписки.
 * Стоимость приходит в центах - делим на 100.
 * @param subscription - подписка.
 * @param params.seatsCount - колличество ситов.
 * @param params.price - стоимость подписки за 1 сита, если она берётся не из subscription.
 */
export function getSubscriptionCost(
  subscription:
    | ISubscriptionInfoResponseDto
    | ISubscriptionSimpleInfoDto
    | TrialWillEndNotificationData,
  params?: { seats?: number; price?: number }
): string {
  const subscriptionPrice = params?.price ?? Number(subscription?.plan?.price);
  const seatsCount = params?.seats ?? Number(subscription?.seats);

  return formatCurrencyFn(subscriptionPrice * seatsCount, subscription?.plan?.currency);
}

/*
 * Возвращает полную стоимость подписки (с налогами).
 */
export function getTotalSubscriptionCost(
  subscription: ISubscriptionInfoResponseDto | ISubscriptionSimpleInfoDto,
  tax: ITaxInfoResponseDto,
  seatsCount?: number
): string {
  return getSubscriptionCost(subscription, {
    price: tax.amountPerSeat + tax.taxPerSeat,
    seats: seatsCount || subscription.seats,
  });
}

/*
 * Возвращает признак подписки находящейся в состоянии действующего триала.
 */
export function isSubscriptionHasTrialTime(
  subscription: ISubscriptionSimpleInfoDto | ISubscriptionInfoResponseDto
): boolean {
  return (
    !!subscription?.trialEnd &&
    getDateUnitBefore(subscription.trialEnd, 'ms', subscription.currentTime) > 0
  );
}

/*
 * Возвращает признак деактивированной подписки с остатком времени (деактивирована пользователем).
 */
export function isDeactivatedWithReminder(
  subscription: ISubscriptionInfoResponseDto | ISubscriptionSimpleInfoDto
): boolean {
  return subscription.status === SubscriptionStatusType.active && !!subscription.endedAt;
}

/*
 * Возвращает локализованное имя тарифного плана подписки.
 */
export function getSubscriptionName(planType: SubscriptionPlanType): string {
  if (!planType) return '';

  return SUBSCRIPTION_NAMES_LOCALES[planType];
}

/*
 * Возвращает признак пользователя, у которого не выбрана подписка (тарифный план).
 * На данный момент это 1 кейс - был зарегестрирован на платформе до введения подписок, после этого не заходил на платфрому и не выбрал нужную.
 */
export function isNeedChooseSubscription(
  activeWorkspace: IPersonWorkspaceResponseDto,
  workspaces: TWorkspaceMap | null
): boolean {
  const subscription = activeWorkspace?.subscription;
  const isNewUser = !workspaces?.active.length && !workspaces?.inactive.length;

  return !subscription && !isNewUser;
}

/*
 * Возвращает описание для статуса подписки.
 */
export const getSubscriptionStatusDescription = (
  subscription: ISubscriptionInfoResponseDto | ISubscriptionSimpleInfoDto
): string => {
  if (!subscription?.status) return '';

  const name = getSubscriptionName(subscription.plan.type);

  const SUBSCRIPTION_STATUS_DESCRIPTION_MAP: Record<SubscriptionStatusType, string> = {
    [SubscriptionStatusType.active]: i18n.t('subscriptionStatusBadge.activeDescription', {
      name,
    }),
    [SubscriptionStatusType.trialing]: i18n.t('subscriptionStatusBadge.trialDescription'),
    [SubscriptionStatusType.incompleteExpired]: i18n.t(
      'subscriptionStatusBadge.deactivatedDescription',
      { name }
    ),
    [SubscriptionStatusType.canceled]: i18n.t('subscriptionStatusBadge.deactivatedDescription', {
      name,
    }),
    [SubscriptionStatusType.pastDue]: i18n.t('subscriptionStatusBadge.activeDescription', {
      name,
    }),
    [SubscriptionStatusType.incomplete]: i18n.t('subscriptionStatusBadge.deactivatedDescription', {
      name,
    }),
    [SubscriptionStatusType.unpaid]: i18n.t('subscriptionStatusBadge.deactivatedDescription', {
      name,
    }),
  };

  if (isDeactivatedWithReminder(subscription)) {
    return i18n.t('subscriptionStatusBadge.unsubscribedDescription', { name });
  }

  return SUBSCRIPTION_STATUS_DESCRIPTION_MAP[subscription.status];
};

/*
 * Возвращает имя статуса подписки.
 */
export function getSubscriptionStatusName(
  subscription: ISubscriptionInfoResponseDto | ISubscriptionSimpleInfoDto
): string {
  return SUBSCRIPTION_STATUS_NAME_MAP[subscription.status];
}

/*
 * Возвращает имя статуса подписки.
 */
export function getSubscriptionStatusWithLeftTime(
  subscription: ISubscriptionInfoResponseDto | ISubscriptionSimpleInfoDto
): string {
  if (subscription.status === SubscriptionStatusType.trialing) {
    const diff = getDateUnitBefore(subscription.currentPeriodEnd, 'day');

    return diff > 5
      ? i18n.t('subscriptionsStatuses.trial')
      : i18n.t('subscriptionsStatuses.trialDays', { days: diff });
  }

  if (isDeactivatedWithReminder(subscription)) {
    const diff = getDateUnitBefore(subscription.endedAt || '', 'day');

    return diff === 1
      ? i18n.t('subscriptionsStatuses.deactivatedLeftDay', { diff })
      : i18n.t('subscriptionsStatuses.deactivatedLeftDays', { diff });
  }

  return getSubscriptionStatusName(subscription);
}

/*
 * Возвращает признак деактивированной подписки.
 */
export function isDeactivatedSubscription(
  subscription: ISubscriptionInfoResponseDto | ISubscriptionSimpleInfoDto
): boolean {
  return SUBSCRIPTION_DEACTIVATED_STATUSES.includes(subscription.status);
}

/*
 * Возвращает имя статуса подписки для бэйджа с этим статусом.
 */
export function getSubscriptionStatusNameForBadge(
  subscription: ISubscriptionInfoResponseDto | ISubscriptionSimpleInfoDto
): string {
  if (isDeactivatedWithReminder(subscription)) {
    return i18n.t('subscriptionsStatuses.unsubscribed');
  }

  if (isDeactivatedSubscription(subscription)) {
    return i18n.t('subscriptionStatusBadge.labelStatus', {
      status: i18n.t('subscriptionsStatuses.deactivated'),
    });
  }

  if (subscription.status === SubscriptionStatusType.trialing) {
    return i18n.t('subscriptionStatusBadge.labelSubscription', {
      status: i18n.t('subscriptionsStatuses.trial'),
    });
  }

  return i18n.t('subscriptionStatusBadge.labelStatus', {
    status: getSubscriptionStatusName(subscription),
  });
}

/*
 * Возвращает признак подписки находящейся в статусе истёкшего триала.
 */
export function checkTrialEnd(
  subscription?: ISubscriptionInfoResponseDto | ISubscriptionSimpleInfoDto
): boolean {
  const isTrial = Boolean(subscription?.trialEnd);
  const hasTrialTime = subscription && isSubscriptionHasTrialTime(subscription);

  return isTrial && !hasTrialTime && subscription?.status === SubscriptionStatusType.canceled;
}

/*
 * Возвращает признак истёкшей подписки.
 */
export function isSubscriptionInactive(subscription?: ISubscriptionSimpleInfoDto): boolean {
  return !!subscription && !checkTrialEnd(subscription) && isInactiveSubscription(subscription);
}

/*
 * Возвращает признак подписки находящейся в тестовом режиме.
 */
export function isSubscriptionInTestMode(
  subscription?: ISubscriptionInfoResponseDto | ISubscriptionSimpleInfoDto | null
): boolean {
  return !!subscription && 'providerMode' in subscription && subscription?.providerMode === 'test';
}
