import { useStore } from 'effector-react/compat';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { GET_INVITE_INFO_ERROR_CODES } from '@api/workspace/workspace.api.constants';
import { isAuth } from '@utils/auth.utils';
import { $userProfile, getProfileFx } from '@models/profile';
import { Login } from '@pages/Login';
import { logUserInvitationLinkClicked } from '@pagesPersonInvitation/PersonInvitation.userLog';
import { AcceptWorkspaceInviteError } from '@pagesPersonInvitation/components/AcceptWorkspaceInviteError';
import { CreateOrgPerson } from './components/CreateOrgPerson';
import { RegistrationViaInvite } from './components/RegistrationViaInvite';
import {
  $personInvitationStore,
  personInvitationFx,
  resetPersonInvitationStore,
} from './personInvitation.model';

export const PersonInvitation: FC = () => {
  const { t } = useTranslation();
  const { inviteId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const userProfile = useStore($userProfile);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(isAuth());
  const { personEmail, isUserExists, organizationName } = useStore($personInvitationStore);
  const [acceptWorkspaceInviteError, setAcceptWorkspaceInviteError] = useState<{
    header: string;
    description: string;
  } | null>(null);

  useEffect(() => {
    if (isLoggedIn) {
      getProfileFx();
    }
    personInvitationFx({ inviteId, workspaceId: searchParams.get('workspace') || '' })
      .then(() => logUserInvitationLinkClicked(true))
      .catch((error) => {
        logUserInvitationLinkClicked(false);
        if (GET_INVITE_INFO_ERROR_CODES.includes(error.response?.data?.slug)) {
          setAcceptWorkspaceInviteError({
            header: t('acceptWorkspaceInviteError.getInfoErrorHeader'),
            description: t('acceptWorkspaceInviteError.getInfoErrorDescription'),
          });
        }
      });

    return resetPersonInvitationStore;
  }, [isLoggedIn, inviteId, searchParams]);

  useEffect(() => {
    if (!personEmail || !userProfile) return;

    // Если пользователь авторизован, но профиль инвайта не совпадает с данными профиля текущей авторизации (чужой инвайт) - отображаем ошибку.
    if (userProfile.email !== personEmail) {
      setAcceptWorkspaceInviteError({
        header: t('acceptWorkspaceInviteError.acceptErrorHeader'),
        description: t('acceptWorkspaceInviteError.acceptErrorDescription', {
          organizationName,
          currentEmail: userProfile.email,
          newEmail: personEmail,
        }),
      });
    }
  }, [userProfile, personEmail]);

  switch (true) {
    case Boolean(acceptWorkspaceInviteError):
      return (
        <AcceptWorkspaceInviteError
          header={acceptWorkspaceInviteError?.header || ''}
          description={acceptWorkspaceInviteError?.description || ''}
        />
      );
    case isLoggedIn:
      return (
        <CreateOrgPerson
          email={personEmail}
          organizationName={organizationName}
        />
      );
    case !isLoggedIn && isUserExists:
      return (
        <Login
          mainTitle={t('welcomeTo', { organizationName })}
          secondaryTitle={t('logInJoin')}
          email={personEmail}
          onLogin={() => setIsLoggedIn(true)}
        />
      );
    case !isUserExists:
      return <RegistrationViaInvite />;
    default:
      return <div />;
  }
};
