// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkboxGroup__nATUT{margin-top:var(--hSpacing16)}.checkboxGroup__nATUT>label{margin-bottom:var(--hSpacing12)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/CheckboxGroup/CheckboxGroup.module.scss"],"names":[],"mappings":"AAEA,sBACE,4BAAA,CAEA,4BACE,+BAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.checkboxGroup {\n  margin-top: var(--hSpacing16);\n\n  & > label {\n    margin-bottom: var(--hSpacing12);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxGroup": "checkboxGroup__nATUT"
};
export default ___CSS_LOADER_EXPORT___;
