export enum LOADER_TYPE {
  MAIN = 'MAIN',
  ADDITIONAL = 'ADDITIONAL',
}

export enum LOADER_SIZE {
  M = 'm',
  S = 's',
  XS = 'xs',
}

export enum LOADER_POSITION {
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative',
}
