/*
 * Компонент иконки верификации
 */
import i18n from 'i18next';
import { FC } from 'react';
import { SmaPlatform, VerificationStatus } from '@hypetrainCommon';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { PopupPositions } from '@uikit/components/Popup';
import { Tooltip } from '@uikit/components/Tooltip';

type TVerificationIconProps = {
  verificationStatus: VerificationStatus;
  platform: SmaPlatform;
  className?: string;
  size?: number;
};

type TVerificationText = Partial<Record<VerificationStatus, string>>;

const CHANNEL_NAME_ICON_MAP: Record<VerificationStatus, ICON_TYPES> = {
  [VerificationStatus.verified]: ICON_TYPES.VERIFIED_BADGE,
  [VerificationStatus.musicArtist]: ICON_TYPES.VERIFIED_BADGE_ARTIST,
};

const VERIFICATION_TEXT_MAP: Partial<Record<SmaPlatform, TVerificationText>> = {
  [SmaPlatform.Youtube]: {
    [VerificationStatus.verified]: i18n.t('verificationIcon.youtube'),
    [VerificationStatus.musicArtist]: i18n.t('verificationIcon.youtubeArtist'),
  },
  [SmaPlatform.Instagram]: {
    [VerificationStatus.verified]: i18n.t('verificationIcon.instagram'),
  },
  [SmaPlatform.Tiktok]: {
    [VerificationStatus.verified]: i18n.t('verificationIcon.tikTok'),
  },
};

export const VerificationIcon: FC<TVerificationIconProps> = ({
  verificationStatus,
  platform,
  className,
  size = 20,
}) => (
  <Tooltip
    position={PopupPositions.BottomCenter}
    content={<div>{VERIFICATION_TEXT_MAP[platform]?.[verificationStatus]}</div>}
  >
    <Icon
      type={CHANNEL_NAME_ICON_MAP[verificationStatus]}
      style={ICON_STYLES.INFORMATIVE}
      size={size}
      className={className}
    />
  </Tooltip>
);
