/*
 * Assignee select option.
 */
import { FC } from 'react';
import { OptionProps } from 'react-select';
import { IPersonSimpleInfoDto } from '@hypetrainCommon';
import { ListItem } from '@uikit/components/ListItem';

export const AssigneeSelectOption: FC<OptionProps<IPersonSimpleInfoDto>> = ({
  innerProps,
  innerRef,
  data,
  isSelected,
}) => (
  <ListItem
    mainText={data.name || ''}
    secondaryText={data.jobTitle}
    avatar={{
      idForColor: data.id,
      entityName: data.name,
    }}
    innerProps={innerProps}
    innerRef={innerRef}
    isSelected={isSelected}
  />
);
