import { FC } from 'react';
import { FILE_UPLOAD_STATUS } from '@uikit/components/Attach/AttachFile/attachFile.constants';
import { TAttachedFile } from '@uikit/components/Attach/AttachFile/attachFile.types';
import { AttachIcon } from '@uikit/components/AttachIcon';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '@uikit/components/Buttons';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { TextEllipsis } from '@uikit/components/TextEllipsis';
import { noop } from '@uikit/helpers/common.helpers';
import { getFileFormat } from '@uikit/helpers/files.helpers';
import { getUnifiedFormat } from '@uikit/helpers/format.helpers';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import { downloadByUrl, prepareFileSize } from '@uikit/utils/file.utils';
import styles from './AttachFile.module.scss';

export type TAttachFile = {
  attachedFile: TAttachedFile;
  isShowInfo?: boolean;
  onDelete?: (fileId: string) => void;
  isShowDownload?: boolean;
};

export const AttachFile: FC<TAttachFile> = ({
  attachedFile,
  isShowInfo = true,
  onDelete = noop,
  isShowDownload = true,
}) => {
  const { t } = useUiKitTranslation();

  return (
    <div className={styles.attachFile}>
      <div>
        <AttachIcon
          type={
            !attachedFile.status || attachedFile.status === FILE_UPLOAD_STATUS.DONE
              ? getUnifiedFormat(getFileFormat(attachedFile.fileName))
              : attachedFile.status
          }
        />
      </div>
      <div className={styles.attachFile__textContainer}>
        <TextEllipsis className={styles.attachFile__textEllipsis}>
          {attachedFile.fileName}
        </TextEllipsis>
      </div>
      {attachedFile.status === FILE_UPLOAD_STATUS.ERROR && attachedFile.errorMessage && (
        <div className={styles.attachFile__uploadFailNotification}>{attachedFile.errorMessage}</div>
      )}
      {attachedFile.path && isShowDownload ? (
        <div className={styles.attachFile__download}>
          <Button
            className={styles.attachFile__button}
            size={BUTTON_SIZE.XS}
            buttonType={BUTTON_TYPE.SECONDARY}
            onClick={() =>
              downloadByUrl(
                attachedFile.fileName,
                `${process.env.FILE_STORAGE_URL}${attachedFile.path}`
              )
            }
          >
            {t('attachFile.download')}
          </Button>
        </div>
      ) : (
        !!isShowInfo && (
          <>
            <div className={styles.attachFile__size}>
              {prepareFileSize(Number(attachedFile.fileSize))}
            </div>
            <div
              className={styles.delete}
              onClick={() => onDelete(attachedFile.id)}
            >
              <Icon
                type={ICON_TYPES.CLOSE_ICON}
                size={12}
              />
            </div>
          </>
        )
      )}
    </div>
  );
};
