/*
 * Хук аналог useState, но не присваевает значения в useState если компонент был демонтирован
 * */
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useIsComponentMounted } from '@uikit/hooks/useIsComponentMounted.hooks';

export const useStateIfMounted = <T>(
  initialValue: T
): [state: T, newSetState: Dispatch<SetStateAction<T>>] => {
  const isComponentMounted = useIsComponentMounted();
  const [state, setState] = useState<T>(initialValue);

  const newSetState = useCallback(
    (value: T) => {
      if (isComponentMounted.current) {
        setState(value);
      }
    },
    [isComponentMounted]
  );
  return [state, newSetState];
};
