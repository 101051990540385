/*
 * Аватар инфлюенсера.
 * Использовать вместо стандартного компонента Avatar с целью оптимизации загружаемого изображения!
 */
import { FC, useMemo } from 'react';
import { Avatar, TAvatarProps } from '@uikit/components/Avatar';
import { getSocialPlatformBySMAId } from '@utils/socialPlatform.utils';
import { getOptimazedInfluencerAvatar } from '@components/InfluencerAvatar/InfluencerAvatar.utils';

type TInfluencerAvatarProps = {
  influencerId: string;
  img: string;
} & TAvatarProps;

export const InfluencerAvatar: FC<TInfluencerAvatarProps> = ({ influencerId, ...avatarProps }) => {
  const thumbnail = useMemo(() => {
    const platform = getSocialPlatformBySMAId(influencerId);

    return getOptimazedInfluencerAvatar(avatarProps.img, platform, avatarProps.size);
  }, [influencerId, avatarProps.img, avatarProps.size]);

  return (
    <Avatar
      {...avatarProps}
      img={thumbnail}
    />
  );
};
