/*
 * Утилиты раздела настроек legal entity.
 */
import { TBackendError } from '@api';
import { TLegalEntityErrors, TLegalEntitySchema } from './LegalEntity.types';

/*
 * Обработчик ошибок при созранении legal entity.
 * Формирует маппинг имя поля - код ошибки.
 */
export function handleSubmitLegalEntityErrors(
  error: TBackendError
): Record<keyof TLegalEntitySchema, string> | null {
  if (error.response?.data.slug !== 'input-validation-error') {
    return null;
  }

  return error.response?.data?.data?.reduce(
    (errorsAcc: Record<keyof TLegalEntitySchema, string>, err: TLegalEntityErrors) => {
      const fieldName = err?.property;

      // TODO Никита: Обсудить нейминг ошибок.
      return { ...errorsAcc, [fieldName]: 'Invalid format for input field.' };
    },
    {}
  );
}
