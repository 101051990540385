export default {
  tooltipActionWithConfirmation: {
    sure: 'Sure?',
  },
  backButton: {
    title: 'Go back'
  },
  emailChip: {
    setAsPrimary: 'Set as primary',
  },
  accordion: {
    showMore: 'Show more',
    hideAll: 'Hide all',
  },
  checkboxFilter: {
    atLeast: 'at least',
  },
  radioGroupFilter: {
    atLeast: 'at least',
  },
  rangeFilter: {
    zeroInToFieldError: 'Enter 0 in the “From” field.',
    minimumError: 'Enter the minimum first.',
  },
  selectAllRowsCheckbox: {
    all: 'All',
  },
  selectAllModal: {
    addToSelection: 'Add to “Selection”',
    selectAll: 'Add to “Selection”?',
    addChannels: 'You’re about to add channels ({{count}}) to “Selection.”',
    addAccounts: 'You’re about to add accounts ({{count}}) to “Selection.”',
  },
  selectRowCheckbox: {
    addColumn: 'Add',
  },
  rangeInput: {
    from: 'From',
    to: 'To',
  },
  selectFilter: {
    atLeast: 'at least',
  },
  timeInputField: {
    placeholder: '00:00 UTC',
  },
  nothingFound: {
    title: 'Nothing found',
  },
  stepsProgressBar: {
    text: 'Step {{currentStep}} of {{stepsCount}}',
  },
  sortToggler: {
    noAudienceFilterTooltip: 'To make it active, apply audience filters.',
  },
  showMore: {
    showMore: ' Read the full description',
    showLess: ' Show less',
  },
  attachFile: {
    download: 'Download',
  },
  errorStub: {
    weHavingAProblem: 'Something isn’t right. \n Try again later or reload your web page.',
  },
  cardList: {
    hideAll: 'Hide',
  },
  filters: {
    addFilters: 'Add filters',
    filtering: 'Filters',
  },
  sorts: {
    sortingLabel: 'Sorts',
    addSorting: 'Add sorts',
  },
  filter: {
    apply: 'Apply',
  }
}
