/*
 * Компонент ячейки суммы платежа.
 */
import { FC } from 'react';
import { Currency } from '@hypetrainCommon';
import { FormatCurrency } from '@uikit/components/Formats';
import styles from './PaymentAmountCell.module.scss';

type TPaymentAmountCellProps = {
  amount: number;
  currency: Currency;
  withConverted?: boolean;
  convertedAmount?: number;
  convertedCurrency?: Currency;
};

export const PaymentAmountCell: FC<TPaymentAmountCellProps> = ({
  amount,
  currency,
  withConverted = false,
  convertedAmount,
  convertedCurrency,
}) => (
  <div className={styles.paymentAmountCell}>
    <div className={styles.paymentAmountCell__mainContainer}>
      <FormatCurrency currency={currency}>{amount}</FormatCurrency>
    </div>
    {withConverted && (
      <FormatCurrency
        className={styles.paymentAmountCell__converted}
        currency={convertedCurrency}
      >
        {convertedAmount}
      </FormatCurrency>
    )}
  </div>
);
