/*
 *   Компонент для отображения ошибки при переходе по инвайт ссылке
 */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import keyIcon from '@assets/images/key.svg';
import styles from './AcceptWorkspaceInviteError.module.scss';

type TAcceptWorkspaceInviteErrorProps = {
  header: string;
  description: string;
};
export const AcceptWorkspaceInviteError: FC<TAcceptWorkspaceInviteErrorProps> = ({
  header,
  description,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.acceptWorkspaceInviteError}>
      <div className={styles.acceptWorkspaceInviteError__icon}>
        <img
          width={80}
          height={80}
          src={keyIcon}
          alt="workspace"
        />
      </div>
      <div>
        <h1>{header}</h1>
      </div>
      <div>
        <h2>{description}</h2>
      </div>
      <div className={styles.acceptWorkspaceInviteError__helpText}>
        {t('acceptWorkspaceInviteError.helpText')}{' '}
        <a href={`mailto:${t('helpEmail.hello')}`}>{t('helpEmail.hello')}</a>
      </div>
    </div>
  );
};
