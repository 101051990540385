/*
 * Компонент таблицы истории платежей.
 */
import { useStore } from 'effector-react/compat';
import { t } from 'i18next';
import { FC, useEffect, useMemo } from 'react';
import { PaymentStatus } from '@hypetrainCommon';
import { VirtualizedTable } from '@uikit/components/Table';
import emptyIcon from '@assets/images/addressBook/emptyAddressBook.svg';
import { getPaymentsColumns } from '@pagesPaymentsPage/payments.colums';
import {
  $payments,
  getPaidPaymnetsFx,
  onLoadMorePaymentsFx,
} from '@pagesPaymentsPage/payments.model';
import styles from './PaymentsHistory.module.scss';

export const PaymentsHistory: FC = () => {
  const payments = useStore($payments);
  const isLoading = useStore(getPaidPaymnetsFx.pending);
  const paidPayments = payments[PaymentStatus.Paid];

  const columns = useMemo(() => getPaymentsColumns(), []);

  useEffect(() => {
    if (!paidPayments) {
      getPaidPaymnetsFx();
    }
  }, [paidPayments]);

  return (
    <div className={styles.paymentsHistory}>
      {/* <PaymentsHistoryFilters /> */}
      <div className={styles.paymentsHistory__table}>
        <VirtualizedTable
          data={paidPayments?.results || []}
          columns={columns}
          isLoading={isLoading}
          itemHeight={48}
          totalCount={paidPayments?.total}
          noDataOptions={{
            title: t('paymentsHistory.noDataMessage'),
            description: t('paymentsHistory.noDataDescription'),
            img: emptyIcon,
            useMediumTitle: true,
          }}
          loadOnScroll={() => onLoadMorePaymentsFx(PaymentStatus.Ready)}
        />
      </div>
    </div>
  );
};
