/*
 * Компонент отображающий budge по статусу.
 */
import cn from 'clsx';
import { FC } from 'react';
import { Icon } from '@uikit/components/Icon';
import { TIconStateProps } from '@uikit/components/StateBadge/StateBadge.types';
import {
  STATE_BUDGE_STYLES,
  TIP_CLASS_MAP_BY_STYLES,
} from '@uikit/components/StateBadge/StateBadge.utils';
import styles from './StateBadge.module.scss';

type TStateBadgeProps = {
  className?: string;
  style?: STATE_BUDGE_STYLES;
  children: JSX.Element | JSX.Element[];
  leftIcon?: TIconStateProps; // icon to the left of the text
  rightIcon?: TIconStateProps; // icon to the right of the text
};

export const StateBadge: FC<TStateBadgeProps> = ({
  className,
  children,
  leftIcon,
  rightIcon,
  style = STATE_BUDGE_STYLES.POSITIVE,
}) => (
  <div className={cn(styles.stateBadge, TIP_CLASS_MAP_BY_STYLES[style], className)}>
    {leftIcon && (
      <Icon
        className={cn(styles.stateBadge__leftIcon, leftIcon.className)}
        type={leftIcon.type}
        style={leftIcon.style}
        size={leftIcon.size || 12}
      />
    )}

    <div className={styles.stateBadge__children}>{children}</div>
    {rightIcon && (
      <Icon
        className={cn(styles.stateBadge__rightIcon, leftIcon?.className)}
        type={rightIcon.type}
        style={rightIcon.style}
        size={rightIcon.size || 12}
      />
    )}
  </div>
);
