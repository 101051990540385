import { Store, createEffect, createEvent, createStore } from 'effector/compat';
import { ISearchRequestDto, ISearchResponseDto, SmaPlatform } from '@hypetrainCommon';
import { explorationApiService, explorationApiServiceNew } from '@api/exploration';
import { $activeSocialPlatform, resetActivePlatform } from '@models/activeSocialPlatform';
import { updateDiscoveryNoteCounts } from '@pagesDiscovery/Browse/browse.utils';
import { profileNoteChanged } from '@components/Profile/profile.model';

/*
 * Очистка стора найденых инфлюенсеров.
 */
export const resetSearchStore = createEvent();

/*
 * Устанавливает search term
 */

export const setSearchTermSearchPage = createEvent<string>();
export const resetSearchTermSearchPage = createEvent<string>();

/*
 * Поиск инфлюенсеров.
 */
export const searchInfluencersFx = createEffect((requestData: ISearchRequestDto) => {
  if ($activeSocialPlatform.getState()?.platform === SmaPlatform.Tiktok) {
    return explorationApiServiceNew.searchInfluencers(requestData);
  }
  return explorationApiService.searchInfluencers(requestData);
});

/*
 * Поиск инфлюенсеров с подгрузкой.
 */
export const searchInfluencersWithUpdateFx = createEffect((requestData: ISearchRequestDto) => {
  if ($activeSocialPlatform.getState()?.platform === SmaPlatform.Tiktok) {
    return explorationApiServiceNew.searchInfluencers(requestData);
  }
  return explorationApiService.searchInfluencers(requestData);
});

/*
 * Стор найденых инфлюенсеров.
 */

export const $searchStore: Store<ISearchResponseDto> = createStore({
  smasStatistics: [],
  total: 0,
  limit: 30,
  offset: 0,
} as ISearchResponseDto)
  .on(searchInfluencersFx.doneData, (state, payload) => payload)
  .on(searchInfluencersWithUpdateFx.doneData, (state, payload) => ({
    ...payload,
    // any тут полностью легально, т.к. мы не можем привести его к одному
    // из двух типов IYoutubeSmaStatisticsResponseDto[] | IInstagramSmaStatisticsResponseDto[]
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    smasStatistics: [...(state.smasStatistics as any), ...payload.smasStatistics],
  }))
  .on(profileNoteChanged, (store, payload) => {
    const prepared = updateDiscoveryNoteCounts(
      store.smasStatistics,
      payload.smaId,
      payload.newCount
    );
    // any тут полностью легально, т.к. мы не можем привести его к одному
    // из двух типов IYoutubeSmaStatisticsResponseDto[] | IInstagramSmaStatisticsResponseDto[]
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    return { ...store, smasStatistics: prepared as any };
  })
  .reset(resetSearchStore)
  .reset(resetActivePlatform);

/*
 * Стор для хранения строки поиска нужен для формирования
 * запроса на подгрузку блогеров внутри профиля на странице search
 */
export const $searchTermSearchPageStore = createStore<string>('')
  .on(setSearchTermSearchPage, (state, payload) => payload)
  .reset(resetSearchTermSearchPage);
