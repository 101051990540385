/*
 * Хук для live-обновления чатов на вкладке communication.
 */
import { useStore } from 'effector-react/compat';
import { useEffect } from 'react';
import webSocketService from '@api/webSocket';
import {
  $campaignStore,
  chatMessageStatusChanged,
  incomeChatMessage,
} from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.model';

export const useLiveUpdateCommunication = (): void => {
  const campaign = useStore($campaignStore);

  useEffect(() => {
    if (!campaign?.campaignDetails) return;

    const dealsIds = Object.values(campaign.campaignDeals || {}).map((deal) => deal?.chat?.id);

    // Вопросики потому как был 1 раз когда campaignDealMessages был навен undefined, как это возможно не ясно.
    webSocketService.campaignDealMessages?.onMessageIncome(dealsIds, incomeChatMessage);
    webSocketService.campaignDealMessages?.onMessageStatusChanges(
      dealsIds,
      chatMessageStatusChanged
    );

    return () => {
      webSocketService.campaignDealMessages?.offMessageIncome();
      webSocketService.campaignDealMessages?.offMessageStatusChanges();
    };
  }, [campaign?.campaignDetails]);
};
