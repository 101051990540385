/*
 * Сервис разграничения доступа пользователя к функционалу компаний.
 */
import { UserAccessService, userAccessService } from './userAccess.service';
import { TUsetRights } from './userAccess.service.types';

export class CampaignsUserAccessService {
  private get userRights(): TUsetRights {
    return this.userAccess.userRights;
  }

  /*
   * Может ли пользователь работать с компаниями.
   */
  // cheked
  public get isAvailable(): boolean {
    return this.userAccess.hasCampaignRightGroup;
  }

  /*
   * Может ли пользователь создавать компании.
   */
  // Может отображать задизейбленную кнопку вместо её скрытия? Иначе зачем мы тогда даём выбирать блогеров в списке?
  // checked
  public get createCampaign(): boolean {
    return (
      Boolean(this.userRights.CreateCampaign) || Boolean(this.userRights.CreateAndAssignCampaign)
    );
  }

  /*
   * Может ли пользователь при создавании компании назначать assaignee.
   */
  // checked
  public get createAndAssignCampaign(): boolean {
    return Boolean(this.userRights.CreateAndAssignCampaign);
  }

  /*
   * Может ли пользователь запрашивать список компаний.
   */
  // cheked
  public get getCampaigns(): boolean {
    return (
      Boolean(this.userRights.GetAllWorkspaceCampaigns) ||
      Boolean(this.userRights.GetAssignedCampaigns)
    );
  }

  /*
   * Может ли пользователь просматривать конкретную компанию.
   */
  // cheked
  public get getCampaignById(): boolean {
    return (
      Boolean(this.userRights.GetWorkspaceCampaignById) ||
      Boolean(this.userRights.GetAssignedCampaignById)
    );
  }

  /*
   * Может ли пользователь запрашивать список сделок (deals) в компании.
   */
  // cheked
  public get getCampaignDeals(): boolean {
    return (
      Boolean(this.userRights.GetWorkspaceCampaignDeals) ||
      Boolean(this.userRights.GetAssignedCampaignDeals)
    );
  }

  // Roles problems: Мы не можем запретить только обнолвение, так как юзер по идее должен иметь возможность читать настройки компании.
  public get updateAssignedCampaign(): boolean {
    return Boolean(this.userRights.UpdateAssignedWorkspaceCampaign);
  }

  public get reassignCampaign(): boolean {
    return Boolean(this.userRights.UpdateAndAssignWorkspaceCampaign);
  }

  /*
   * Может ли пользователь удалять инфлюенсера (deal) из компанию.
   */
  // checked
  public get deleteCampaignDeal(): boolean {
    return (
      Boolean(this.userRights.DeleteWorkspaceCampaignDeal) ||
      Boolean(this.userRights.DeleteAssignedWorkspaceCampaignDeal)
    );
  }

  /*
   * Может ли пользователь добавлять контакт (person) в сделку (deal).
   */
  // checked
  public get linkPersonsToCampaignDeal(): boolean {
    return (
      Boolean(this.userRights.LinkPersonsToWorkspaceCampaignDeal) ||
      Boolean(this.userRights.LinkPersonsToAssignedWorkspaceCampaignDeal)
    );
  }

  /*
   * Может ли пользователь добавлять инфлюенсера (deal) в компанию.
   */
  // checked
  public get addSmaInCampaign(): boolean {
    return (
      Boolean(this.userRights.CreateWorkspaceCampaignDeal) ||
      Boolean(this.userRights.CreateAssignedCampaignDeal)
    );
  }

  /*
   * Может ли пользователь видеть пункт Campaings в навигационном меню.
   */
  // checked
  public get canShowCampaignsMenuItem(): boolean {
    return this.isAvailable && (this.getCampaigns || this.createCampaign);
  }

  /*
   * Может ли пользователь добавить инфлюенсера в существующую компанию, выбранную из списка.
   */
  // checked
  public get canAddToExistingCampaignFromList(): boolean {
    return this.addSmaInCampaign && this.getCampaigns;
  }

  constructor(private readonly userAccess: UserAccessService) {}

  /*
   * Может ли пользователь добавить или удалять инфлюенсера (deal) из компании.
   */
  // checked
  public canUpdateDealExistingInCampaign(isDealInCampaign: boolean): boolean {
    return isDealInCampaign ? this.deleteCampaignDeal : this.addSmaInCampaign;
  }
}

const createCampaignsUserAccessService = (userAccess: UserAccessService) =>
  new CampaignsUserAccessService(userAccess);

export const campaignsUserAccessService = createCampaignsUserAccessService(userAccessService);
