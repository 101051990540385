import {
  ICredentialsResponseDto,
  IGoogleAuthResponseDto,
  ISignInRequestDto,
} from '@hypetrainCommon';
import { loginUserFx, loginUserWithGoogleFx, userVerificationAndAuthFx } from '@models/auth.model';

export type TLoginParams = {
  credentials: ISignInRequestDto | string | TLoginAcademyParams;
  onLogin: (loginResponse: ICredentialsResponseDto | IGoogleAuthResponseDto) => void;
};

export type TLoginAcademyParams = {
  userId: string;
  email: string;
};

export enum APP_AUTH_TYPES {
  DEFAULT = 'DEFAULT',
  GOOGLE = 'GOOGLE',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
}

// Матчинг способа авторизации с методом авторизации
export const INIT_BY_LOGIN_HANDLERS_MAP: Record<
  APP_AUTH_TYPES,
  (params: TLoginParams) => Promise<ICredentialsResponseDto | IGoogleAuthResponseDto>
> = {
  [APP_AUTH_TYPES.DEFAULT]: loginUserFx,
  [APP_AUTH_TYPES.GOOGLE]: loginUserWithGoogleFx,
  [APP_AUTH_TYPES.EMAIL_VERIFICATION]: userVerificationAndAuthFx,
};
