/*
 Компонент help center, внутренняя часть тултипа
 */
import dayjs from 'dayjs';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopup } from '@uikit/components/Popup';
import { StateBadge } from '@uikit/components/StateBadge';
import { STATE_BUDGE_STYLES } from '@uikit/components/StateBadge/StateBadge.utils';
import arrowLink from '@assets/images/arrowLink.svg';
import {
  MenuWrapper,
  TMenuItemProps,
} from '@components/Background/components/NavigationPanel/components/MenuWrapper/MenuWrapper';
import { TMenuDefaultItemProps } from '@components/Background/components/NavigationPanel/components/MenuWrapper/components/MenuDefaultItem/MenuDefaultItem.component';

const supportStartHour = 9;
const supportEndHour = 17;
const fridayDayNumber = 5;

export const HelpCenter: FC = memo(() => {
  const { t } = useTranslation();
  const popup = usePopup();

  const isSupportOnline = () => {
    // Hide online badge on weekends
    if (dayjs().day() > fridayDayNumber) return;
    const currentTime = dayjs.tz(new Date(), 'cet').hour();
    return currentTime >= supportStartHour && currentTime < supportEndHour;
  };

  const openBlankPage = (url: string) => {
    window.open(url, '_blank')?.focus();
    popup.popupService.hidePopup();
  };

  const menuItems: TMenuItemProps<TMenuDefaultItemProps>[] = [
    {
      id: 'intercom-messenger',
      text: t('navigation.helpCenter.supportChat'),
      badge: isSupportOnline() ? (
        <StateBadge style={STATE_BUDGE_STYLES.POSITIVE}>{t('online')}</StateBadge>
      ) : (
        <div />
      ),
      textRightIcon: arrowLink,
      action: () => {
        // setTimeout нужен ввиду того, что при клике мы открываем intercom и закрываем попап
        // intercom не успевает отправить запрос на открытие хелп центра до момент дестроя попапа
        // Самый простой и безболезненный хак
        setTimeout(() => {
          popup.popupService.hidePopup();
        }, 0);
      },
    },
    {
      id: 'helpCenter',
      text: t('navigation.helpCenter.intercomHelpCenter'),
      action: () => openBlankPage('https://intercom.help/hypetrain/en'),
      isExternalLink: true,
    },
    {
      id: 'blog',
      text: t('navigation.helpCenter.blog'),
      action: () => openBlankPage('https://hypetrain.io/blog'),
      isExternalLink: true,
    },
    {
      id: 'termsOfService',
      text: t('navigation.helpCenter.termsOfService'),
      action: () => openBlankPage('https://academy.hypetrain.io/terms'),
      isExternalLink: true,
    },
    {
      id: 'privacyPolicy',
      text: t('navigation.helpCenter.privacyPolicy'),
      action: () => openBlankPage('https://academy.hypetrain.io/privacy'),
      isExternalLink: true,
    },
    {
      id: 'privacyPolicy',
      text: t('navigation.helpCenter.cookiePolicy'),
      action: () => openBlankPage('https://academy.hypetrain.io/cookies'),
      isExternalLink: true,
    },
  ];

  return <MenuWrapper menuItems={menuItems} />;
});

// http://app.academy.hypetrain.io/login?id=e1f19276-c485-48d4-84a9-895619bf1726&email=john2@doe.com
