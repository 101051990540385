/*
 * Компонент суммы совершённой транзакции.
 */
import { FC } from 'react';
import { FeeType, ITransactionDto, TransactionType } from '@hypetrainCommon';
import { FormatCurrency } from '@uikit/components/Formats';
import { PaymentCompositionInfo } from '@pagesPaymentsPage/components/PaymentCompositionInfo';
import styles from './PaymentTransactionAmount.module.scss';

type TPaymentTransactionAmountProps = {
  transaction: ITransactionDto;
};

export const PaymentTransactionAmount: FC<TPaymentTransactionAmountProps> = ({ transaction }) => {
  const isOutgoing = transaction?.type === TransactionType.OUT;
  const sign = isOutgoing ? '-' : '+';

  return (
    <div className={styles.paymentTransactionAmount}>
      <span>{sign}</span>
      <FormatCurrency currency={transaction?.currency}>{transaction?.amount}</FormatCurrency>

      {isOutgoing && (
        <PaymentCompositionInfo
          className={styles.paymentTransactionAmount__info}
          currency={transaction?.currency}
          subTotal={transaction?.subTotal}
          hypetrainFee={transaction?.appliedFees?.[FeeType.Hypetrain]}
          conversionFee={transaction?.appliedFees?.[FeeType.CurrencyConversion]}
          crossBorderFee={transaction?.appliedFees?.[FeeType.CrossBorder]}
          operationalFee={transaction?.appliedFees?.[FeeType.Operational]}
          total={transaction?.amount}
        />
      )}
    </div>
  );
};
