/*
 * Сервис для работы с WebSocket.
 */
import { io } from 'socket.io-client';
import { Socket } from 'socket.io-client/build/esm/socket';
import { UserNotification } from '@hypetrainCommon';
import {
  CampaignDealContractingSocketService,
  createCampaignDealContractingSocketService,
} from '@api/webSocket/campaignDealContracting';
import {
  CampaignDealMessagesSocketService,
  createCampaignDealMessagesSocketService,
} from '@api/webSocket/campaignDealMessages';
import {
  CampaignDealPaymentSocketService,
  createCampaignDealPaymentSocketService,
} from '@api/webSocket/campaignDealPayment';
import {
  SubscriptionSocketService,
  createSubscriptionSocketService,
} from '@api/webSocket/subscription';

export class WebSocketService {
  private socket: Socket | null = null;

  public campaignDealMessages: CampaignDealMessagesSocketService | null = null;

  public subscription: SubscriptionSocketService | null = null;

  public campaignDealContracting: CampaignDealContractingSocketService | null = null;

  public campaignDealPayment: CampaignDealPaymentSocketService | null = null;

  /*
   * Инициализация WebSocket в приложении.
   */
  public init(token: string, personId: string, workspaceId: string): void {
    if (this.socket) {
      this.campaignDealMessages?.destroy();
      this.subscription?.destroy();
      this.campaignDealContracting?.destroy();
      this.campaignDealPayment?.destroy();
      this.disconnect();
    }

    this.socket = io(process.env.WEBSOCKET_URL, {
      auth: {
        token,
        personId,
        workspaceId,
      },
    });
    this.campaignDealMessages = createCampaignDealMessagesSocketService(this);
    this.subscription = createSubscriptionSocketService(this);
    this.campaignDealContracting = createCampaignDealContractingSocketService(this);
    this.campaignDealPayment = createCampaignDealPaymentSocketService(this);
  }

  /*
   * Подписка на события WebSocket.
   */
  public on(event: string, listener: (message: UserNotification, ack: () => void) => void): void {
    this.socket?.on(event, listener);
  }

  /*
   * Отписка от события WebSocket.
   */
  public off(event: string, listener: (message: UserNotification, ack: () => void) => void): void {
    this.socket?.off(event, listener);
  }

  /*
   * Отключение соединения.
   */
  public disconnect() {
    this.socket?.disconnect();
  }
}

const createWebSocketService = () => new WebSocketService();

const webSocketService = createWebSocketService();

export default webSocketService;
