/*
 *   Компонент для отрисовки данных для legal entity карточки
 */
import i18n from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ILegalEntityInfoResponseDto, countriesNames } from '@hypetrainCommon';
import { BUTTON_TYPE, Button } from '@uikit/components/Buttons';
import { dialogService } from '@uikit/components/Dialog';
import { ICON_TYPES } from '@uikit/components/Icon';
import { notificationsService } from '@uikit/components/Notification';
import { TextEllipsis } from '@uikit/components/TextEllipsis';
import { SUPPORT_SHOW_LINES } from '@uikit/components/TextEllipsis/TextEllipsis.component';
import { LegalEntityDialogEdit } from '@pagesWorkspaceSettings/components/LegalEntity/components/LegalEntityDialog/components/LegalEntityDialogEdit';
import {
  deleteLegalEntitiesFx,
  getLegalEntitiesFx,
} from '@pagesWorkspaceSettings/components/LegalEntity/model';
import styles from './LegalEntityInfo.module.scss';

type TLegalEntityInfoProps = {
  legalEntity: ILegalEntityInfoResponseDto;
};
export const LegalEntityInfo: FC<TLegalEntityInfoProps> = ({ legalEntity }) => {
  const { t } = useTranslation();

  const deleteLegalEntity = (id: string) => {
    deleteLegalEntitiesFx(id).then(() => {
      getLegalEntitiesFx();
      notificationsService.successful({
        props: { caption: i18n.t('workspaceSettings.legalEntity.deleteSuccess') },
      });
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const openModal = (legalEntity: ILegalEntityInfoResponseDto) => {
    dialogService.open({
      component: LegalEntityDialogEdit,
      props: {
        legalEntity,
      },
    });
  };

  return (
    <div className={styles.legalEntityInfo}>
      <h3>
        <TextEllipsis lineCount={SUPPORT_SHOW_LINES.THREE}>{legalEntity.name}</TextEllipsis>
      </h3>

      <div className={styles.legalEntityInfo__text}>
        <TextEllipsis>{legalEntity.registrationNumber},</TextEllipsis>
        <TextEllipsis>{legalEntity.phoneNumber},</TextEllipsis>
        <TextEllipsis>{legalEntity.email}</TextEllipsis>
      </div>
      <div className={styles.legalEntityInfo__text}>
        <TextEllipsis>{legalEntity.address},</TextEllipsis>
        <TextEllipsis>
          {legalEntity.city}, {legalEntity.region},
        </TextEllipsis>
        <TextEllipsis>
          {legalEntity.postalCode}, {countriesNames[legalEntity.country].name}
        </TextEllipsis>
      </div>
      <div className={styles.legalEntityInfo__buttons}>
        <Button
          buttonType={BUTTON_TYPE.SECONDARY}
          onClick={() => openModal(legalEntity)}
        >
          {t('workspaceSettings.legalEntity.edit')}
        </Button>
        <Button
          buttonType={BUTTON_TYPE.SECONDARY}
          icon={ICON_TYPES.DELETE}
          onClick={() => deleteLegalEntity(legalEntity.id)}
        />
      </div>
    </div>
  );
};
