import { ICON_TYPES } from '@uikit/components/Icon';

export enum FORMAT_TYPES {
  WORD = 'word',
  PDF = 'pdf',
  PICTURE = 'picture',
  VIDEO = 'video',
  AUDIO = 'audio',
  RAR = 'rar',
  ZIP = 'zip',
  XLS = 'xls',
  PPT = 'ppt',
  DEFAULT = 'default',
  ERROR = 'error',
}

const UNIFIED_FORMAT: Record<string, FORMAT_TYPES> = {
  // WORD
  docx: FORMAT_TYPES.WORD,
  doc: FORMAT_TYPES.WORD,
  // PDF
  pdf: FORMAT_TYPES.PDF,
  // PICTURE
  jpg: FORMAT_TYPES.PICTURE,
  png: FORMAT_TYPES.PICTURE,
  jpeg: FORMAT_TYPES.PICTURE,
  raw: FORMAT_TYPES.PICTURE,
  tiff: FORMAT_TYPES.PICTURE,
  gif: FORMAT_TYPES.PICTURE,
  webp: FORMAT_TYPES.PICTURE,
  heif: FORMAT_TYPES.PICTURE,
  svg: FORMAT_TYPES.PICTURE,
  // VIDEO
  mp4: FORMAT_TYPES.VIDEO,
  mov: FORMAT_TYPES.VIDEO,
  avi: FORMAT_TYPES.VIDEO,
  webm: FORMAT_TYPES.VIDEO,
  mpeg: FORMAT_TYPES.VIDEO,
  // AUDIO
  mp3: FORMAT_TYPES.AUDIO,
  m4a: FORMAT_TYPES.AUDIO,
  aac: FORMAT_TYPES.AUDIO,
  oga: FORMAT_TYPES.AUDIO,
  ogg: FORMAT_TYPES.AUDIO,
  wav: FORMAT_TYPES.AUDIO,
  flac: FORMAT_TYPES.AUDIO,
  // RAR
  rar: FORMAT_TYPES.RAR,
  // ZIP
  zip: FORMAT_TYPES.ZIP,
  // XLS
  xls: FORMAT_TYPES.XLS,
  xlsx: FORMAT_TYPES.XLS,
  // PPT
  ppt: FORMAT_TYPES.PPT,
  pptx: FORMAT_TYPES.PPT,
  // DEFAULT
  default: FORMAT_TYPES.DEFAULT,
};

export const ICONS_BY_FILE_EXTENSIONS: Record<FORMAT_TYPES, ICON_TYPES> = {
  // WORD
  [FORMAT_TYPES.WORD]: ICON_TYPES.WORD_ICON,
  // PDF
  [FORMAT_TYPES.PDF]: ICON_TYPES.PDF_ICON,
  // PICTURE
  [FORMAT_TYPES.PICTURE]: ICON_TYPES.PICTURE_ICON,
  // VIDEO
  [FORMAT_TYPES.VIDEO]: ICON_TYPES.VIDEO_ICON,
  // AUDIO
  [FORMAT_TYPES.AUDIO]: ICON_TYPES.AUDIO_ICON,
  // RAR
  [FORMAT_TYPES.RAR]: ICON_TYPES.RAR_ICON,
  // ZIP
  [FORMAT_TYPES.ZIP]: ICON_TYPES.ZIP_ICON,
  // XLS
  [FORMAT_TYPES.XLS]: ICON_TYPES.XLS_ICON,
  // PPT
  [FORMAT_TYPES.PPT]: ICON_TYPES.PPT_ICON,
  // DEFAULT
  [FORMAT_TYPES.DEFAULT]: ICON_TYPES.ANOTHER_ICON,
  // FAIL
  [FORMAT_TYPES.ERROR]: ICON_TYPES.WARNING,
};

export const getUnifiedFormat = (format: string): FORMAT_TYPES =>
  UNIFIED_FORMAT[format] ?? FORMAT_TYPES.DEFAULT;
