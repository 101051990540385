// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".workspaceInfo__S2oi0{height:100%;display:grid;justify-content:center;align-content:center}", "",{"version":3,"sources":["webpack://./pages/WorkspaceSettings/components/WorkspaceInfo/WorkspaceInfo.module.scss"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,YAAA,CACA,sBAAA,CACA,oBAAA","sourcesContent":["@import '../../../../../../uikit/src/assets/styles/ui_kit_mixins';\n\n.workspaceInfo {\n  height: 100%;\n  display: grid;\n  justify-content: center;\n  align-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workspaceInfo": "workspaceInfo__S2oi0"
};
export default ___CSS_LOADER_EXPORT___;
