import { IUploadingListResponseDto } from '@hypetrainCommon';
import { USERS_ACTIONS, usersActionsLogService } from '@services/usersActionsLog';

// метод для логирования результатов загрузки инфлюенсеров
export const logInfluencersUploading = (uploadingResult: IUploadingListResponseDto): void => {
  if (!!uploadingResult?.notFound && !uploadingResult?.smasStatistics?.length) {
    usersActionsLogService.log(USERS_ACTIONS.EXPLORATION_YOUTUBE_UPLOAD_NOTHING_FOUND);
    return;
  }

  if (!uploadingResult?.notFound && !!uploadingResult?.smasStatistics?.length) {
    usersActionsLogService.log(USERS_ACTIONS.EXPLORATION_YOUTUBE_UPLOAD_ALL_CHANNELS_FOUND);
    return;
  }

  usersActionsLogService.log(USERS_ACTIONS.EXPLORATION_YOUTUBE_UPLOAD_PARTIALLY_FOUND);
};
