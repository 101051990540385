/*
 * Multiselect filter component
 */
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { OptionProps } from 'react-select';
import { Filter, TFilterConfig } from '@uikit/components/Filter';
import { Percentage } from '@uikit/components/Filter/components/Percentage';
import { FilterMultiSelectOption } from '@uikit/components/Filter/components/SelectFilter/components/FilterMultiSelectOption';
import {
  prepareViewValueWithPercents,
  transformDtoToFilterModel,
  transformFilterModelToDto,
} from '@uikit/components/Filter/filter.utils';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { MultiSelect } from '@uikit/components/MultiSelect';
import { MultiSelectOption } from '@uikit/components/MultiSelect/components/MultiSelectOption';
import { SelectField } from '@uikit/components/SelectField';
import { TSelectOption, TSelectOptions } from '@uikit/components/SelectField/SelectField.types';
import { noop } from '@uikit/helpers/common.helpers';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import styles from './SelectFilter.module.scss';

export type TSelectFilterConfig = {
  options: TSelectOptions;
  placeholder?: string;
  hasPercentage?: boolean;
  percentageFirstLabel?: string;
  percentageLastLabel?: string;
  valuePath?: string;
  viewValuePath?: string;
  itemHeight?: number;
  isMulti?: boolean;
  maxValues?: number;
};

type TSelectFilterProps = {
  config: TFilterConfig<TSelectFilterConfig>;
  onApply: (value: TFilter) => void;
  applyOnClose: boolean;
};

export const SelectFilter: FC<TSelectFilterProps> = memo(({ config, onApply, applyOnClose }) => {
  const { t } = useUiKitTranslation();
  const [filterValue, setFilterValue] = useState<TFilter>(
    transformDtoToFilterModel(config.filterValue, config.filterProps.hasPercentage)
  );

  const [selectedValues, setSelectedValues] = useState<TSelectOptions | string>();

  const valuePath = config.filterProps.valuePath || 'value';
  const viewValuePath = config.filterProps.viewValuePath || 'label';

  useEffect(() => {
    // если состояние фильтра снаружи компонента изменилось формируем список выбранных опций
    const newValues =
      config.filterValue.values?.map(
        (item) =>
          config.filterProps.options.find(
            (option) => option[valuePath as keyof TSelectOption] === item
          ) as TSelectOption
      ) || [];

    setFilterValue(transformDtoToFilterModel(config.filterValue, config.filterProps.hasPercentage));
    setSelectedValues(
      config.filterProps.isMulti
        ? newValues
        : (newValues?.[0]?.[valuePath as keyof TSelectOption] as string)
    );
  }, [config.filterValue, config.filterProps.options]);

  const onApplyHandler = (value: TFilter) =>
    onApply(transformFilterModelToDto(value, config.filterProps.hasPercentage));

  const onClearCallback = () => {
    setFilterValue(
      transformDtoToFilterModel(config.filterInitialValue, config.filterProps.hasPercentage)
    );
    onApply(config.filterInitialValue);
  };

  const onSelect = (selected: TSelectOptions | string): void => {
    setSelectedValues(selected);
    // сохраняем выбранные значения фильтра или приводим его к начальному состоянию если ничего не выбрано

    if (config.filterProps.isMulti) {
      setFilterValue(
        selected.length
          ? {
              ...filterValue,
              values: (selected as TSelectOptions)?.map(
                (value) => value[valuePath as keyof TSelectOption] as string
              ),
            }
          : transformDtoToFilterModel(config.filterInitialValue, config.filterProps.hasPercentage)
      );
      return;
    }

    setFilterValue(
      selected
        ? {
            ...filterValue,
            values: [selected as string],
          }
        : transformDtoToFilterModel(config.filterInitialValue, config.filterProps.hasPercentage)
    );
  };

  const preparedViewValue = useMemo((): string => {
    if (!config.filterValue.values?.length) return '';

    // ищем в списке опций нужную выбранные опции, чтобы получить из них лейблы и сформировать viewValue фильтра
    const preparedValue = config.filterValue.values
      ?.map(
        (item) =>
          (config.filterProps.options.find((option) => option.value === item) as TSelectOption)[
            viewValuePath as keyof TSelectOption
          ]
      )
      ?.join(', ');

    return config.filterProps.hasPercentage
      ? prepareViewValueWithPercents(preparedValue, Number(config.filterValue.min))
      : preparedValue;
  }, [config.filterValue]);

  return (
    <Filter
      config={config}
      viewValue={preparedViewValue}
      onClear={onClearCallback}
      onApply={() => onApplyHandler(filterValue)}
      applyOnClose={applyOnClose}
    >
      <>
        <div className={styles.multiSelectFilter__select}>
          {config.filterProps.isMulti ? (
            <MultiSelect
              onChange={onSelect}
              value={(selectedValues as TSelectOptions) || []}
              placeholder={config.filterProps.placeholder}
              valuePath={valuePath}
              viewValuePath={viewValuePath}
              autocomplete
              maxValues={config.filterProps.maxValues}
              optionComponent={FilterMultiSelectOption}
              options={config.filterProps.options}
              itemHeight={config.filterProps.itemHeight}
              closeMenuOnSelect
              clearInputOnMenuClose
              hideError
            />
          ) : (
            <SelectField
              input={{
                name: 'selectFilter',
                onFocus: noop,
                onBlur: noop,
                onChange: onSelect,
                value: selectedValues || '',
              }}
              placeholder={config.filterProps.placeholder}
              valuePath={valuePath}
              viewValuePath={viewValuePath}
              isMulti={false}
              optionComponent={MultiSelectOption as FC<OptionProps>}
              options={config.filterProps.options}
              itemHeight={config.filterProps.itemHeight}
              clearInputOnMenuClose
              hideError
            />
          )}
        </div>

        {config.filterProps.hasPercentage && (
          <Percentage
            firstText={config.filterProps.percentageFirstLabel || t('selectFilter.atLeast')}
            lastText={config.filterProps.percentageLastLabel}
            isDisabled={!filterValue.values?.length}
            onChange={(value) => setFilterValue({ ...filterValue, min: value })}
            value={Number(filterValue.min)}
          />
        )}
      </>
    </Filter>
  );
});
