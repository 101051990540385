/**
 * A provider that provides access to the resources of the popup component.
 */
import { createContext } from 'react';
import { PopupService } from './popup.service';
import { PopupStore } from './popup.store';

export type TPopupContext = {
  popupService: PopupService;
  popupStore: PopupStore;
};

export const PopupContext = createContext<TPopupContext>({} as TPopupContext);
export const PopupProvider = PopupContext.Provider;
