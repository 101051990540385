/*
 * Утилиты для форматирования валюты.
 */
import { ReactNode } from 'react';
import { Currency } from '@hypetrainCommon';
import { EMPTY_PLACEHOLDER } from '@uikit/constants/common.constants';
import { isNumeric } from '@uikit/utils/number.utils';
import { FORMAT_NUMBER_NOTATION, FORMAT_NUMBER_STYLES } from '../Formats.constants';

/*
 * Форматириует пережанное значение валюты в принятый формат.
 */
export function formatCurrencyFn(
  value: string | number | ReactNode,
  currency?: Currency,
  precision = 2,
  notation?: FORMAT_NUMBER_NOTATION,
  signDisplay?: 'auto' | 'never' | 'always' | 'exceptZero'
): string {
  const minimumFractionDigits = Number(value) === 0 ? 2 : 0; // Если значение 0, то всегда отображаем 2 знака после запятой (0,00 USD).
  const maximumFraction = Number(precision || 0); // Точность отображения знаков после запятой.
  const maximumFractionDigits =
    minimumFractionDigits > maximumFraction ? minimumFractionDigits : maximumFraction; // Минимальное колличество не может быть больше максимального - будет JS-error.

  /*
   * Формат чисел.
   * @see https://bloggerslab.fibery.io/Design/Capitalization-and-formats-1096
   * en-ZA (Ангнлийский в Замбии? ОМГ) Подобрано на основе требований из сцификации UX-writer из списка https://gist.github.com/raushankrjha/d1c7e35cf87e69aa8b4208a8171a8416.
   */
  const formatter = new Intl.NumberFormat('en-ZA', {
    style: FORMAT_NUMBER_STYLES.CURRENCY,
    currency,
    notation,
    currencyDisplay: 'code',
    minimumFractionDigits,
    maximumFractionDigits,
    signDisplay,
  });

  const isEmptyValue = !isNumeric(String(value));

  return isEmptyValue
    ? EMPTY_PLACEHOLDER
    : formatter
        .format(Number(value))
        .replace(/\D0(?=\D*$)/, '')
        .replace(/^([A-Z]{3})\s*(.+)$/, '$2 $1'); // Где бы ни был символ валюты, переносим его в конец.
}
