// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".asyncSelectFilter__select__nn3aP{margin-bottom:var(--hSpacing16)}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/Filter/components/AsyncSelectFilter/AsyncSelectFilter.module.scss"],"names":[],"mappings":"AAGE,kCACE,+BAAA","sourcesContent":["@import '../../../../assets/styles/ui_kit_mixins';\n\n.asyncSelectFilter {\n  &__select {\n    margin-bottom: var(--hSpacing16);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"asyncSelectFilter__select": "asyncSelectFilter__select__nn3aP"
};
export default ___CSS_LOADER_EXPORT___;
