/*
 * Хук обрабатывающий редирект на приложение.
 */
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { USERS_ACTIONS, usersActionsLogService } from '@services/../services/usersActionsLog';
import { setRedirectFrom } from '@models/registration.model';

/*
 * Необходимо понимать, что произошёл редирект с лэндинга (в url передаётся параметр redirect).
 * Если так, то добавить в запрос на регистрацию id в залоловке x-temp-user-id (выполняется не в этом хуке).
 * Логирует все редиректы. Пытаясь обработать и другие источники редиректа так же смотрит на document.referrer (не поддерживается лэндингом).
 */
export const useRedirectFrom = (logEvent: USERS_ACTIONS): void => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const redirectFrom = document.referrer || searchParams.get('redirect') || 'none';

    setRedirectFrom({ url: redirectFrom });

    searchParams.delete('redirect');
    setSearchParams(searchParams);

    usersActionsLogService.log(logEvent, { redirectFrom });
  }, []); // (!!!) Не устанавливать searchParams в зависимости, так как setSearchParams вызывает изменение searchParams, повторный лог и портит статистику!
};
