/*
 *   Компонент для отрисовки статуса стейджа
 *   @see https://www.figma.com/file/EeuV0wy02eu5FGsF7cyi2P/Stages?node-id=385%3A16916&t=RbL8Dtkus1aiaCwv-0
 */
import cn from 'clsx';
import { FC, memo } from 'react';
import { CampaignDealStageName, CampaignDealStageStateName } from '@hypetrainCommon';
import { getStatusIndicatorClassName } from '@components/StatusIndicator/statusIndicator.constants';
import styles from './StatusIndicator.module.scss';

type TStatusIndicatorProps = {
  currentStage: CampaignDealStageName;
  currentState: CampaignDealStageStateName;
  isAvailable: boolean;
  turnOffAnimation?: boolean;
};
export const StatusIndicator: FC<TStatusIndicatorProps> = memo(
  ({ currentState, isAvailable, turnOffAnimation = false, currentStage }) => (
    <div
      className={cn(
        isAvailable && styles[getStatusIndicatorClassName(currentStage, currentState)],
        styles.statusIndicator__container,
        !isAvailable && styles.statusIndicator__notAvailable,
        turnOffAnimation && styles.turnOffAnimation
      )}
    >
      <div />
    </div>
  )
);
