/*
 * Константы для сервиса логирования пользовательских событий.
 */
import { SmaPlatform } from '@hypetrainCommon';
import { USERS_ACTIONS } from '@services/usersActionsLog/usersActions.enum';

/*
 * Маппинг событий клика по кнопке экспорта файла.
 */
export const LOG_EXPORT_FILES_ACTIONS: Record<SmaPlatform, USERS_ACTIONS> = {
  [SmaPlatform.Youtube]: USERS_ACTIONS.CLICK_ON_EXPORT_FILE_BUTTON_YOUTUBE,
  [SmaPlatform.Instagram]: USERS_ACTIONS.CLICK_ON_EXPORT_FILE_BUTTON_INSTAGRAM,
  [SmaPlatform.Tiktok]: USERS_ACTIONS.UNSUPPORTED,
  [SmaPlatform.Twitter]: USERS_ACTIONS.UNSUPPORTED,
  [SmaPlatform.Facebook]: USERS_ACTIONS.UNSUPPORTED,
  [SmaPlatform.Twitch]: USERS_ACTIONS.UNSUPPORTED,
};

/*
 * Маппинг событий добавления в селекшен.
 */
export const LOG_ADD_TO_SELECTION_ACTIONS: Record<SmaPlatform, USERS_ACTIONS> = {
  [SmaPlatform.Youtube]: USERS_ACTIONS.EXPLORATION_YOUTUBE_SMA_ADDED_IN_SELECTION,
  [SmaPlatform.Instagram]: USERS_ACTIONS.EXPLORATION_INSTAGRAM_SMA_ADDED_IN_SELECTION,
  [SmaPlatform.Tiktok]: USERS_ACTIONS.UNSUPPORTED,
  [SmaPlatform.Twitter]: USERS_ACTIONS.UNSUPPORTED,
  [SmaPlatform.Facebook]: USERS_ACTIONS.UNSUPPORTED,
  [SmaPlatform.Twitch]: USERS_ACTIONS.UNSUPPORTED,
};

/*
 * Маппинг событий удаления из селекшена.
 */
export const LOG_DELETE_FROM_SELECTION_ACTIONS: Record<SmaPlatform, USERS_ACTIONS> = {
  [SmaPlatform.Youtube]: USERS_ACTIONS.EXPLORATION_YOUTUBE_SMA_DELETE_FROM_SELECTION,
  [SmaPlatform.Instagram]: USERS_ACTIONS.EXPLORATION_INSTAGRAM_SMA_DELETE_FROM_SELECTION,
  [SmaPlatform.Tiktok]: USERS_ACTIONS.UNSUPPORTED,
  [SmaPlatform.Twitter]: USERS_ACTIONS.UNSUPPORTED,
  [SmaPlatform.Facebook]: USERS_ACTIONS.UNSUPPORTED,
  [SmaPlatform.Twitch]: USERS_ACTIONS.UNSUPPORTED,
};

/*
 * Маппинг событий клика по фильтру.
 */
export const LOG_FILTER_ACTIONS_BY_PLATFORM: Record<SmaPlatform, USERS_ACTIONS> = {
  [SmaPlatform.Youtube]: USERS_ACTIONS.EXPLORATION_YOUTUBE_FILTERING_FILTER_OPENED,
  [SmaPlatform.Instagram]: USERS_ACTIONS.EXPLORATION_INSTAGRAM_FILTERING_FILTER_OPENED,
  [SmaPlatform.Tiktok]: USERS_ACTIONS.UNSUPPORTED,
  [SmaPlatform.Twitter]: USERS_ACTIONS.UNSUPPORTED,
  [SmaPlatform.Facebook]: USERS_ACTIONS.UNSUPPORTED,
  [SmaPlatform.Twitch]: USERS_ACTIONS.UNSUPPORTED,
};
