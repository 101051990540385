import { SmaPlatform } from '@hypetrainCommon';

const SOCIALS_ID: Record<string, SmaPlatform> = {
  IG: SmaPlatform.Instagram,
  YT: SmaPlatform.Youtube,
  TT: SmaPlatform.Tiktok,
};

/*
 * Уузнаем по uuid компании, к какой соц сети это относится
 */
export const getSocialPlatformBySMAId = (id: string): SmaPlatform => {
  const [platformId, userId] = (id || '').split(':');

  if (!userId) {
    return SmaPlatform.Instagram;
  }

  return SOCIALS_ID[platformId];
};
