import { useStore } from 'effector-react/compat';
import { FC, useMemo } from 'react';
import { SelectAllRowsCheckbox } from '@uikit/components/Table/components/columns/SelectAllRowsCheckbox';
import { $activeSocialPlatform } from '@models/activeSocialPlatform';
import {
  $campaignSelectionStore,
  $campaignStore,
  addAllToCampaignSelection,
  deleteAllFromCampaignSelection,
} from '@pagesCampaigns/CampaignDetailsPage/campaignDetailsPage.model';
import styles from './SelectCampaignAllCheckbox.module.scss';

export const SelectCampaignAllCheckbox: FC = () => {
  const activePlatform = useStore($activeSocialPlatform);
  const selectedCampaignDeals = useStore($campaignSelectionStore);
  const campaign = useStore($campaignStore);

  const dealsIds = useMemo(() => Object.keys(campaign?.campaignDeals || {}), [campaign]);

  return (
    <div className={styles.selectCampaignAllCheckbox}>
      <SelectAllRowsCheckbox
        displayedRows={dealsIds}
        selectedRows={selectedCampaignDeals}
        activePlatform={activePlatform || null}
        onUnselect={deleteAllFromCampaignSelection}
        onSelect={addAllToCampaignSelection}
        withPartialSelection
      />
    </div>
  );
};
