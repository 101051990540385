/*
 * Компонент ячейки таблицы, отвечающий за выбор всех платежей.
 */
import { useStore } from 'effector-react/compat';
import { FC, memo } from 'react';
import { SelectAllRowsCheckbox } from '@uikit/components/Table/components/columns/SelectAllRowsCheckbox';
import { useSelectedPayments } from '@pagesPaymentsPage/hooks/useSelectedPayments.hook';
import {
  $payments,
  $paymentsCurrencyFilter,
  selectAllPayments,
  unselectAllPayments,
} from '@pagesPaymentsPage/payments.model';

export const SelectAllPaymentsCell: FC = memo(() => {
  const currencyFilter = useStore($paymentsCurrencyFilter);
  const payments = useStore($payments);
  const selectedPayments = useSelectedPayments();

  return (
    <SelectAllRowsCheckbox
      pathId="id"
      displayedRows={payments[currencyFilter]?.results || []}
      selectedRows={selectedPayments}
      activePlatform={null}
      onUnselect={() => unselectAllPayments(currencyFilter)}
      onSelect={() => selectAllPayments(currencyFilter)}
      withPartialSelection
    />
  );
});
