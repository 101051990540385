/*
 * Toggler for sort. Shows selected sort order if is.
 * Dump component, controlled outside / store model outside.
 */
import cn from 'clsx';
import { FC } from 'react';
import { SortOrder } from '@hypetrainCommon';
import { Icon, ICON_TYPES } from '@uikit/components/Icon';
import { PopupPositions } from '@uikit/components/Popup';
import { Tooltip } from '@uikit/components/Tooltip';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import styles from './SortToggler.module.scss';

type TSortTogglerProps = {
  order: SortOrder | null; // current (selected) sort order
  onToggle: (order: SortOrder | null) => void; // on toggle cb
  name: string; // sort property name
  isDisabled?: boolean;
  toggleMode?: boolean;
  testId?: string;
};

export const SortToggler: FC<TSortTogglerProps> = ({
  order,
  onToggle,
  name,
  isDisabled,
  toggleMode = false,
  testId,
}) => {
  const { t } = useUiKitTranslation();

  const hasOrder = !!order;
  const isDescending = order === SortOrder.Descending;

  const onToggleHandler = (): void => {
    if (isDisabled) return;

    if (!hasOrder) {
      onToggle(SortOrder.Descending);
      return;
    }

    if (!toggleMode && isDescending) {
      onToggle(SortOrder.Ascending);
      return;
    }

    onToggle(null);
  };

  return (
    <Tooltip
      disable={!isDisabled}
      content={<span>{t('sortToggler.noAudienceFilterTooltip')}</span>}
      position={PopupPositions.BottomCenter}
    >
      <div
        className={cn(
          styles.sortToggler,
          hasOrder && styles.sortToggler_withOrder,
          isDisabled && styles.sortToggler_disabled
        )}
        id={testId}
        onClick={onToggleHandler}
      >
        {!toggleMode && hasOrder && (
          <Icon
            className={cn(styles.sortToggler__icon, isDescending && styles.sortToggler__icon_flip)}
            type={ICON_TYPES.ARROW}
            size={12}
          />
        )}
        <div
          className={cn(styles.sortToggler__name, isDisabled && styles.sortToggler__name_disabled)}
        >
          {name}
        </div>
      </div>
    </Tooltip>
  );
};
