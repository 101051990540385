// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".multiSelect__chipsContainer__gEBhX{max-width:100%;max-height:250px;display:flex;flex-wrap:wrap;margin-top:var(--hSpacing8)}.multiSelect__chip___mYwR{max-width:100%;margin:var(--hSpacing4) var(--vSpacing8) var(--hSpacing4) 0}", "",{"version":3,"sources":["webpack://./../../uikit/src/components/MultiSelect/MultiSelect.module.scss"],"names":[],"mappings":"AAGE,oCACE,cAAA,CACA,gBAAA,CACA,YAAA,CACA,cAAA,CACA,2BAAA,CAGF,0BACE,cAAA,CACA,2DAAA","sourcesContent":["@import '../../assets/styles/ui_kit_mixins';\n\n.multiSelect {\n  &__chipsContainer {\n    max-width: 100%;\n    max-height: 250px;\n    display: flex;\n    flex-wrap: wrap;\n    margin-top: var(--hSpacing8);\n  }\n\n  &__chip {\n    max-width: 100%;\n    margin: var(--hSpacing4) var(--vSpacing8) var(--hSpacing4) 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multiSelect__chipsContainer": "multiSelect__chipsContainer__gEBhX",
	"multiSelect__chip": "multiSelect__chip___mYwR"
};
export default ___CSS_LOADER_EXPORT___;
