/*
 * Компонент ячейки таблицы с действиями над платежом.
 */
import { FC } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { IPaymentListItemDto } from '@hypetrainCommon';
import { ActionsCell } from '@uikit/components/Table/components/columns/ActionsСell';
import { TTooltipActionMenuItem } from 'hypetrain-ui/src/components/Tooltip';
import arrowUpRight from '@assets/images/arrow-up-right.svg';
import { Routes } from '@router/Routes';
import i18n from '@services/i18n';
import { campaignsUserAccessService } from '@services/userAccess';

type TPaymentActionsCellProps = {
  payment: IPaymentListItemDto;
};

export const PaymentActionsCell: FC<TPaymentActionsCellProps> = ({ payment }) => {
  const navigate = useNavigate();

  const goToDeal = () => {
    const path = generatePath(Routes.campaignDeal, {
      campaignId: payment?.campaignId,
      dealId: payment?.dealId,
    });
    navigate(path);
  };

  const actions: TTooltipActionMenuItem[] = [
    /*     {
      text: i18n.t('paymentActionsCell.contract'),
      icon: arrowUpRight,
      handler: goToContract,
    }, */
    {
      text: i18n.t('paymentActionsCell.deal'),
      icon: arrowUpRight,
      handler: goToDeal,
      isHide: !campaignsUserAccessService.getCampaignDeals,
    },
  ];

  return <ActionsCell actions={actions} />;
};
