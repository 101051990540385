/*
 * Конфигурация сортировок для кампании в инстаграме
 */
import { CampaignDealsSortName, InstagramSmaStatisticsSortName } from '@hypetrainCommon';
import { TAvailableSort } from '@uikit/components/Sorts/sorts.types';
import i18n from '@services/i18n';

export const AVAILABLE_INSTAGRAM_CAMPAIGN_SORTS_CONFIG: TAvailableSort[] = [
  {
    id: InstagramSmaStatisticsSortName.FollowersCount,
    label: i18n.t('sorts.instagram.followers'),
    testId: 'IG_FOLLOWERS_COUNT_SORT',
  },
  {
    id: InstagramSmaStatisticsSortName.EngagementRate,
    label: i18n.t('sorts.instagram.engagementRate'),
    extra: { day: '30' },
    testId: 'IG_ER_SORT',
  },
  {
    id: CampaignDealsSortName.NewMessages,
    label: i18n.t('sorts.instagram.newMessagesLabel'),
    toggleMode: true,
    switcherLabel: i18n.t('sorts.instagram.newMessages'),
    testId: 'IG_NEW_MESSAGES_SORT',
  },
];
