/*
 * Checkbox filter component
 */
import cn from 'clsx';
import forEach from 'lodash/forEach';
import pickBy from 'lodash/pickBy';
import { FC, memo, useEffect, useState } from 'react';
import { Checkbox } from '@uikit/components/Checkbox';
import { CheckboxGroup, TCheckboxGroupValues } from '@uikit/components/CheckboxGroup';
import { Filter, TFilterConfig } from '@uikit/components/Filter';
import { AgeCheckboxGroup } from '@uikit/components/Filter/components/CheckboxFilter/components/AgeCheckboxGroup';
import { Percentage } from '@uikit/components/Filter/components/Percentage';
import {
  prepareViewValueWithPercents,
  transformDtoToFilterModel,
  transformFilterModelToDto,
} from '@uikit/components/Filter/filter.utils';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { useUiKitTranslation } from '@uikit/hooks/useUiKitTranslation.hook';
import styles from './CheckboxFilter.module.scss';

export type TCheckboxFilterConfig = {
  options: TCheckboxGroupValues;
  hasPercentage?: boolean;
  isAge?: boolean;
};

type TCheckboxFilterProps = {
  config: TFilterConfig<TCheckboxFilterConfig>;
  onApply: (value: TFilter) => void;
  applyOnClose: boolean;
};

export const CheckboxFilter: FC<TCheckboxFilterProps> = memo(
  ({ config, onApply, applyOnClose }) => {
    const [filterValue, setFilterValue] = useState<TFilter>(
      transformDtoToFilterModel(config.filterValue, config.filterProps.hasPercentage)
    );

    const [checkBoxValues, setCheckBoxValues] = useState<TCheckboxGroupValues>(
      config.filterProps.options
    );

    const { t } = useUiKitTranslation();

    useEffect(() => {
      const newValues: TCheckboxGroupValues = {};

      forEach(config.filterProps.options, (checkboxValue, key) => {
        newValues[key] = Boolean(filterValue.values?.includes(key));
      });

      setFilterValue(
        transformDtoToFilterModel(config.filterValue, config.filterProps.hasPercentage)
      );
      setCheckBoxValues(newValues);
    }, [config.filterValue, config.filterProps.options]);

    const onApplyHandler = (value: TFilter) =>
      onApply(transformFilterModelToDto(value, config.filterProps.hasPercentage));

    const onClearCallback = (): void => {
      setFilterValue(
        transformDtoToFilterModel(config.filterInitialValue, config.filterProps.hasPercentage)
      );
      setCheckBoxValues(config.filterProps.options);
      onApply(config.filterInitialValue);
    };

    const onChangeCallback = (checkboxValues: TCheckboxGroupValues): void => {
      // отфильтровываем только выбранные чекбоксы
      const filteredValues = pickBy(checkboxValues, (value) => value);

      setCheckBoxValues(checkboxValues);
      setFilterValue(
        filteredValues.length
          ? {
              ...filterValue,
              values: Object.keys(filteredValues),
            }
          : transformDtoToFilterModel(config.filterInitialValue, config.filterProps.hasPercentage)
      );
    };

    const prepareViewValue = (): string => {
      if (!config.filterValue.values?.length) return '';

      const preparedValue = config.filterValue.values.join(', ');

      return config.filterProps.hasPercentage
        ? prepareViewValueWithPercents(preparedValue, Number(config.filterValue.min))
        : preparedValue;
    };

    return (
      <Filter
        config={config}
        viewValue={prepareViewValue()}
        onClear={onClearCallback}
        onApply={() => onApplyHandler(filterValue)}
        applyOnClose={applyOnClose}
      >
        {config.filterProps.isAge ? (
          <AgeCheckboxGroup
            className={cn(
              styles.checkboxFilter__checkboxGroup,
              config.filterProps.hasPercentage &&
                styles.checkboxFilter__checkboxGroup_withPercentage
            )}
            options={config.filterProps.options}
            initialValue={config.filterInitialValue}
            hasPercentage={Boolean(config.filterProps.hasPercentage)}
            onChange={setFilterValue}
            value={filterValue}
          />
        ) : (
          <CheckboxGroup
            className={cn(
              styles.checkboxFilter__checkboxGroup,
              config.filterProps.hasPercentage &&
                styles.checkboxFilter__checkboxGroup_withPercentage
            )}
            value={checkBoxValues}
            onChange={onChangeCallback}
          >
            {Object.keys(config.filterProps.options).map((option) => (
              <Checkbox
                key={option}
                nameInGroup={option}
                label={option}
              />
            ))}
          </CheckboxGroup>
        )}
        {config.filterProps.hasPercentage && (
          <Percentage
            firstText={t('checkboxFilter.atLeast')}
            isDisabled={!filterValue.values?.[0]}
            onChange={(value) => {
              setFilterValue({
                ...filterValue,
                min: value,
              });
            }}
            value={Number(filterValue.min)}
          />
        )}
      </Filter>
    );
  }
);
