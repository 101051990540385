import { createEffect, createEvent, createStore } from 'effector/compat';
import { IWorkspaceInvitePublicInfoResponseDto } from '@hypetrainCommon';
import { workspaceApiService } from '@api/workspace';

export const resetPersonInvitationStore = createEvent();

export const personInvitationFx = createEffect(workspaceApiService.getInviteInfo);

export const $personInvitationStore = createStore<IWorkspaceInvitePublicInfoResponseDto>({
  personEmail: '',
  isUserExists: false,
  organizationName: '',
  inviteId: '',
})
  .on(personInvitationFx.doneData, (state, data) => ({ ...state, ...data }))
  .reset(resetPersonInvitationStore);
