/*
 * Checkbox group context.
 * Provides API for interaction Checkbox with CheckboxGroup.
 */
import { createContext, useContext } from 'react';
import { TCheckboxGroupValues } from './checkboxGroup.types';

type TCheckboxGroupContext = {
  checkboxGroupValue: TCheckboxGroupValues;
  onChangeCheckboxGroup: (checkboxName: string, isChecked: boolean) => void;
} | null;

const CheckboxGroupContext = createContext<TCheckboxGroupContext>(null);

export const CheckboxGroupProvider = CheckboxGroupContext.Provider;

export const useCheckboxGroup = () => useContext(CheckboxGroupContext);
