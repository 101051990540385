import { RefObject } from 'react';

/*
 * Returns the presence of a scroll at the node.
 */
export const isHasScroll = (ref: RefObject<HTMLElement>): boolean => {
  if (!ref.current) return false;

  return ref.current.scrollHeight > ref.current.clientHeight;
};
