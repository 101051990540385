/*
 * Radio group component.
 * For create radio group, pass an arbitrary number of <Radio /> in children.
 */
import cn from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { RadioGroupProvider } from '@uikit/components/RadioGroup/RadioGroup.context';
import style from './RadioGroup.module.scss';

type TRadioGroupProps = PropsWithChildren<{
  value: unknown;
  onChange: (value: unknown) => void;
  className?: string;
  isVertical?: boolean;
}>;

export const RadioGroup: FC<TRadioGroupProps> = ({
  value,
  onChange,
  className,
  children,
  isVertical = false,
}) => {
  const onChangeRadioGroup = (selectedValue: unknown) => {
    onChange(selectedValue);
  };

  return (
    <RadioGroupProvider
      value={{
        radioGroupValue: value,
        onChangeRadioGroup,
      }}
    >
      <fieldset
        className={cn(style.radioGroup, className, isVertical && style.radioGroup__vertical)}
      >
        {children}
      </fieldset>
    </RadioGroupProvider>
  );
};
