import { FC } from 'react';
import { HeaderGroup } from 'react-table';
import styles from '@uikit/components/Table/Table.module.scss';

type TTFooterProps = {
  footerGroups: Array<HeaderGroup>;
};

export const TFooter: FC<TTFooterProps> = ({ footerGroups }) => (
  <div className={styles.footer}>
    {footerGroups.map((footerGroup) => (
      <div
        {...footerGroup.getHeaderGroupProps()}
        className={styles.rt}
      >
        {footerGroup.headers.map((column) => (
          <div
            {...column.getHeaderProps()}
            className={styles.td}
          >
            {column.Footer && column.render('Footer')}
          </div>
        ))}
      </div>
    ))}
  </div>
);
