/*
 * Campaigns list item.
 */
import cn from 'clsx';
import { FC } from 'react';
import { ICampaignListItemDto } from '@hypetrainCommon';
import { formatDateInterval } from '@uikit/components/DatePickerField/datePickerField.utils';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { TextEllipsis } from '@uikit/components/TextEllipsis';
import { noop } from '@helpers/common.helpers';
import styles from './CampaignsSearchListItem.module.scss';

type TCampaignsSearchListItemProps = {
  campaign: ICampaignListItemDto;
  onClick?: (campaign: ICampaignListItemDto) => void;
  isSelected?: boolean;
  isLast: boolean; // sign last item in items list
};

export const CampaignsSearchListItem: FC<TCampaignsSearchListItemProps> = ({
  campaign,
  onClick = noop,
  isSelected,
  isLast,
}) => (
  <div
    className={cn(
      styles.campaignsSearchListItem,
      isLast && styles.campaignsSearchListItem_last,
      isSelected && styles.campaignsSearchListItem_selected
    )}
  >
    <div
      className={styles.campaignsSearchListItem__content}
      onClick={() => onClick(campaign)}
    >
      <div className={styles.campaignsSearchListItem__header}>
        <TextEllipsis className={styles.campaignsSearchListItem__name}>
          {campaign?.name}
        </TextEllipsis>
        {isSelected && (
          <Icon
            className={styles.campaignsSearchListItem__selectMark}
            type={ICON_TYPES.CHECK}
          />
        )}
      </div>

      <div className={styles.campaignsSearchListItem__info}>
        <TextEllipsis
          className={cn(
            styles.campaignsSearchListItem__infoItem,
            !campaign?.productInfo?.name && styles.campaignsSearchListItem__infoItem_notDot
          )}
        >
          {campaign?.brandInfo?.name}
        </TextEllipsis>
        <TextEllipsis
          className={cn(
            styles.campaignsSearchListItem__infoItem,
            !campaign?.startDate &&
              !campaign?.endDate &&
              styles.campaignsSearchListItem__infoItem_notDot
          )}
        >
          {campaign?.productInfo?.name}
        </TextEllipsis>
        <TextEllipsis
          className={cn(
            styles.campaignsSearchListItem__infoItem,
            styles.campaignsSearchListItem__infoItem_notCut
          )}
        >
          {formatDateInterval(campaign?.startDate, campaign?.endDate)}
        </TextEllipsis>
      </div>
    </div>
  </div>
);
