/*
 * Шаг прогресса выполения в модальном окне проводения платежей.
 */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import hypetrainLoader from '@assets/images/hypetrainLoader.gif';
import styles from './ReviewPaymentsInProgressStep.module.scss';

export const ReviewPaymentsInProgressStep: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.reviewPaymentsInProgressStep}>
      <img
        className={styles.reviewPaymentsInProgressStep__loader}
        src={hypetrainLoader}
        alt="hypetrainLoader"
      />
      <h2 className={styles.reviewPaymentsInProgressStep__description}>
        {t('reviewPaymentsInProgressStep.description')}
      </h2>
    </div>
  );
};
