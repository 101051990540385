/*
 * Metrics for group actions.
 */
import cn from 'clsx';
import { FC } from 'react';
import { TGroupActionsMetric } from '@uikit/components/GroupActions';
import { Metric } from '@uikit/components/Metric';
import { Tip } from '@uikit/components/Tip';
import styles from './GroupActionMetricList.module.scss';

type TGroupActionMetricListProps = {
  metrics: TGroupActionsMetric[];
  tip?: string;
  className?: string;
};

export const GroupActionMetricList: FC<TGroupActionMetricListProps> = ({
  metrics,
  tip,
  className,
}) => (
  <div className={cn(styles.groupActionMetricList, className)}>
    <div className={styles.groupActionMetricList__list}>
      {metrics.map((metric) => (
        <Metric
          key={metric?.title}
          className={styles.groupActionMetricList__item}
          name={metric?.title}
        >
          {metric?.value}
        </Metric>
      ))}
    </div>

    {tip && <Tip className={styles.groupActionMetricList__tip}>{tip}</Tip>}
  </div>
);
