import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getFingerPrint } from '@services/fingetPrint';
import { SignUpForm, TSignUpFormSchema } from '../SignUpForm/SignUpForm.component';
import { signUpUserWithInviteFx } from '../signUp.model';

type TInvitationSignUpProps = {
  inviteId: string;
  email: string;
  organizationName?: string;
  action: () => void;
};

export const InvitationSignUp: FC<TInvitationSignUpProps> = ({
  inviteId,
  email,
  action,
  organizationName,
}) => {
  const { t } = useTranslation();

  const onSubmit = async (params: TSignUpFormSchema) => {
    const fingerprint = await getFingerPrint();
    const request = {
      password: params.password,
      marketingConsent: params.marketingConsent,
      inviteId,
      fingerprint,
    };
    await signUpUserWithInviteFx(request);
    action();
  };

  return (
    <SignUpForm
      mainTitle={t('youWereInvited', { organizationName })}
      secondaryTitle={t('createYourAccountStarted')}
      onSubmit={onSubmit}
      email={email}
    />
  );
};
