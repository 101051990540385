import i18n from 'i18next';
import { FC, MouseEvent, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Routes } from 'router/Routes';
import * as Yup from 'yup';
import { ICredentialsResponseDto, ISignInRequestDto } from '@hypetrainCommon';
import { BUTTON_TYPE, Button } from '@uikit/components/Buttons';
import { INPUT_TYPE, Input } from '@uikit/components/Fields/Inputs';
import { DefaultForm } from '@uikit/components/templates/DefaultForm';
import { EMAIL_NOT_FOUND, LOGIN_EMAIL_ERROR_CODES, LOGIN_ERROR_CODES } from '@api/auth';
import { APP_AUTH_TYPES } from '@services/initApp/initApp.constants';
import { USERS_ACTIONS } from '@services/usersActionsLog';
import { makeValidate } from '@utils/makeValidate';
import { redirectToApp } from '@utils/redirect.utils';
import { useInitIntercom } from '@hooks/useInitIntercom';
import { useRedirectFrom } from '@hooks/useRedirectFrom.hook';
import { useServices } from '@hooks/useServices.hook';
import { logLoginExecuted } from '@pagesLogin/Login.userLog';
import { logUserEmailVerification } from '@pagesSignUp/SignUp.userLog';
import styles from './Login.module.scss';

type TLoginSchema = {
  email: string;
  password: string;
};

type TLoginProps = {
  mainTitle?: string;
  secondaryTitle?: string;
  email?: string;
  onLogin?: (loginResponce: ICredentialsResponseDto) => void;
};

export const Login: FC<TLoginProps> = ({ mainTitle, secondaryTitle, email, onLogin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { initAppService } = useServices();
  const [searchParams] = useSearchParams();
  const updateIntercom = useInitIntercom();

  useRedirectFrom(USERS_ACTIONS.USER_LOGIN_PAGE_OPENED);

  useEffect(() => {
    const emailVerificationToken = searchParams.get('token');
    if (emailVerificationToken) {
      initAppService.initByLogin(emailVerificationToken, APP_AUTH_TYPES.EMAIL_VERIFICATION, () => {
        updateIntercom({ verified_at: new Date() });
        logUserEmailVerification(true);
        navigate(Routes.workspaceCreation);
      });
    }
  }, []);

  useEffect(() => {
    const userId = searchParams.get('id');
    const userEmail = searchParams.get('email');
    if (!userId || !userEmail) return;

    initAppService.initByLoginAcademy(
      {
        userId,
        email: userEmail,
      },
      (loginResponse) => redirectToApp(loginResponse?.workspace?.handler || '', Routes.campaigns)
    );
  }, []);

  const onSubmit = (params: ISignInRequestDto | string, type: APP_AUTH_TYPES) =>
    initAppService
      .initByLogin(params, type, (loginResponse) => {
        if (onLogin) {
          onLogin(loginResponse);
          return;
        }
        logLoginExecuted();
        if (!loginResponse?.workspace?.handler) {
          updateIntercom({ verified_at: new Date() });
        }
        loginResponse?.workspace?.handler
          ? redirectToApp(loginResponse?.workspace?.handler || '', Routes.discovery)
          : navigate(Routes.workspaceCreation);
      })
      .catch((error) => {
        if (LOGIN_EMAIL_ERROR_CODES.includes(error.response?.data?.slug)) {
          return { email: t(error.response?.data?.slug) };
        }

        if (LOGIN_ERROR_CODES.includes(error.response?.data?.slug)) {
          return { password: t('incorrectLoginOrPasswordError'), email: ' ' };
        }

        if (EMAIL_NOT_FOUND === error.response?.data?.slug) {
          return { email: t('emailNotFound') };
        }
      });

  const schema: Yup.SchemaOf<TLoginSchema> = Yup.object().shape({
    email: Yup.string()
      .validEmail()
      .required(i18n.t('errors.email.templateEmail'))
      .required(i18n.t('errors.email.enterEmail')),
    password: Yup.string()
      .required(i18n.t('errors.password.enterPassword'))
      .oneOf([Yup.ref('password'), null])
      .min(8, i18n.t('errors.password.shortPassword')),
  });

  const validate = makeValidate(schema);

  const onRedirectToAcademy = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    window.open('https://www.influencermarketing.courses', '_self');
  };

  return (
    <DefaultForm
      mainTitle={mainTitle || t('loginScreen.loginMainTitle')}
      secondaryTitle={secondaryTitle || t('loginScreen.loginSecondaryTitle')}
      customClassNameSecondaryTitle={styles.login__secondaryTitle}
      customClassNameLinkTitle={styles.login__linkTitle}
    >
      <Form
        onSubmit={(params) => onSubmit(params, APP_AUTH_TYPES.DEFAULT)}
        validate={validate}
        initialValues={{ isRemember: false, email }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Button
              buttonType={BUTTON_TYPE.PRIMARY}
              onClick={onRedirectToAcademy}
            >
              {t('loginScreen.openAcademy')}
            </Button>
            <div className={styles.login__separator} />
            <h3 className={styles.login__adminInfo}>{t('loginScreen.adminInfo')}</h3>
            <Field name="email">
              {({ meta, input }) => (
                <Input
                  input={input}
                  label={t('loginScreen.emailForLogin')}
                  disabled={!!email}
                  valueFormatter={(value) => value.toLowerCase()}
                  error={
                    (meta?.submitFailed && meta?.error) ||
                    (!meta?.dirtySinceLastSubmit && meta?.submitError)
                  }
                />
              )}
            </Field>
            <Field name="password">
              {({ meta, input }) => (
                <Input
                  input={input}
                  label={t('loginScreen.password')}
                  type={INPUT_TYPE.PASSWORD}
                  error={
                    (meta?.submitFailed && meta?.error) ||
                    (!meta?.dirtySinceLastSubmit && meta?.submitError)
                  }
                />
              )}
            </Field>
            <Button buttonType={BUTTON_TYPE.SECONDARY}>{t('loginScreen.login')}</Button>
          </form>
        )}
      />
    </DefaultForm>
  );
};
