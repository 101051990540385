/*
 * VirtualizedTable used for large tables with complex data and scroll pagination.
 */
import cn from 'clsx';
import _throttle from 'lodash/throttle';
import {
  CSSProperties,
  ReactElement,
  RefObject,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useFlexLayout, useTable } from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, ListOnScrollProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { NothingFound } from '@uikit/components/NothingFound';
import { TNothingFoundProps } from '@uikit/components/NothingFound/NothingFound.component';
import styles from '@uikit/components/Table/Table.module.scss';
import { Row } from '@uikit/components/Table/components/Row';
import { TableWrapper } from '@uikit/components/Table/components/TableWrapper';
import { TColumn } from '@uikit/components/Table/table.types';
import { NoDataComponent } from '@uikit/components/templates/NoDataComponent';
import { TNoDataComponentProps } from '@uikit/components/templates/NoDataComponent/NoDataComponent.component';
import { noop } from '@uikit/helpers/common.helpers';
import { useTableEvents } from '../useTableEvents.hooks';

type TVirtualizedTableProps = {
  columns: ReadonlyArray<TColumn>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: readonly Object[];
  loadOnScroll?: (startIndex: number, stopIndex: number) => Promise<void> | void;
  itemHeight?: number;
  noDataOptions?: TNoDataComponentProps;
  wasFiltered?: boolean;
  totalCount?: number;
  className?: string;
  rowClassName?: string;
  listClassName?: string;
  showHeader?: boolean;
  customHeight?: number;
  tablePosition?: number;
  scrollElementsPosition?: number[];
  onScroll?: (props: ListOnScrollProps) => void;
  listRef?: RefObject<List>;
  isLoading?: boolean;
  nothingFoundOptions?: TNothingFoundProps;
  footerComponent?: JSX.Element;
  // getSubRows?: (originalRow: object, relativeIndex: number) => object[];
  tableName?: string;
};

export const VirtualizedTable = forwardRef<HTMLDivElement, TVirtualizedTableProps>(
  (
    {
      columns,
      data,
      loadOnScroll = noop,
      noDataOptions = { title: '' },
      itemHeight = 100,
      wasFiltered,
      totalCount = 9999,
      className,
      rowClassName,
      listClassName,
      showHeader = true,
      customHeight,
      tablePosition,
      onScroll,
      listRef,
      isLoading,
      nothingFoundOptions,
      footerComponent,
      // getSubRows = (row: TRowWithSubRows) => row?.subRows,
      tableName,
    },
    ref
  ) => {
    const outerListRef = useRef<HTMLDivElement>(null);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, totalColumnsWidth } =
      useTable(
        {
          columns,
          data: data || [],
          // getSubRows,
        },
        useFlexLayout
        // useExpanded
      );

    useTableEvents(outerListRef, tableName);

    const dispatchScrollEvent = useCallback(
      _throttle((): void => {
        document.dispatchEvent(new CustomEvent('scroll'));
      }, 500),
      []
    );

    const itemData = useMemo(
      () => ({ rows, prepareRow, totalColumnsWidth, rowClassName }),
      [rows, prepareRow, totalColumnsWidth, rowClassName]
    );

    const TableWrapperComponent = useCallback(
      ({ children, style }: { children: ReactElement; style: CSSProperties }) => (
        <TableWrapper
          headerGroups={headerGroups}
          footerComponent={footerComponent}
          style={style}
          className={styles.virtualizeTable}
          showHeader={showHeader}
          {...getTableBodyProps()}
        >
          {children}
        </TableWrapper>
      ),
      [getTableBodyProps]
    );

    if (isLoading && !data?.length) return null;

    if (!data?.length) {
      return wasFiltered ? (
        <NothingFound {...nothingFoundOptions} />
      ) : (
        <NoDataComponent {...noDataOptions} />
      );
    }

    return (
      <div
        {...getTableProps()}
        ref={ref}
        style={{ height: customHeight }}
        className={cn(styles.table, [className])}
      >
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={(index) => index < data.length - 1}
              loadMoreItems={loadOnScroll}
              itemCount={totalCount - 1}
              threshold={5}
            >
              {({ onItemsRendered, ref: LoaderRef }) => (
                <List
                  className={cn(styles.virtualizeTable__list, listClassName)}
                  outerRef={outerListRef}
                  height={height}
                  width={width}
                  itemCount={data?.length}
                  itemSize={itemHeight}
                  onItemsRendered={onItemsRendered}
                  initialScrollOffset={tablePosition}
                  ref={listRef || LoaderRef}
                  innerElementType={TableWrapperComponent}
                  overscanCount={20}
                  onScroll={(scrollProps) => {
                    if (onScroll) {
                      dispatchScrollEvent();
                      onScroll(scrollProps);
                    }
                  }}
                  itemData={itemData}
                >
                  {Row}
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </div>
    );
  }
);
