import cn from 'clsx';
import { CSSProperties, FC, PropsWithChildren } from 'react';
import { HeaderGroup } from 'react-table';
import styles from '@uikit/components/Table/Table.module.scss';
import { THead } from '@uikit/components/Table/components/THead';

type TTableWrapper = {
  headerGroups: Array<HeaderGroup>;
  footerComponent?: JSX.Element;
  style: CSSProperties;
  role?: string | undefined;
  className?: string;
  showHeader?: boolean;
};

export const TableWrapper: FC<PropsWithChildren<TTableWrapper>> = ({
  headerGroups,
  children,
  style,
  role,
  className,
  showHeader,
  footerComponent,
}) => (
  <table className={className}>
    {showHeader && <THead headerGroups={headerGroups} />}
    <tbody
      className={cn(styles.body, !!footerComponent && styles.body_withFooter)}
      role={role}
      style={style}
    >
      {children}
    </tbody>
    {!!footerComponent && (
      <tbody className={styles.footer}>
        <tr className={styles.footer__row}>
          <td className={styles.footer__data}>{footerComponent}</td>
        </tr>
      </tbody>
    )}
  </table>
);
