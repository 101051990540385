/*
 * Шаг с подтверждением успешности провердения платежей в модальном окне проводения платежей.
 */
import { useStore } from 'effector-react/compat';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrencyFn } from '@uikit/components/Formats/FormatCurrency';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { InfoDialogTemplate } from '@uikit/components/templates/InfoDialogTemplate/InfoDialogTemplate.component';
import { useSelectedPayments } from '@pagesPaymentsPage/hooks/useSelectedPayments.hook';
import {
  $calculatedPaymentsSummary,
  $paymentsCurrencyFilter,
} from '@pagesPaymentsPage/payments.model';
import { getPaymentAccountName } from '@pagesPaymentsPage/payments.utils';
import styles from './ReviewPaymentsSucessStep.module.scss';

export const ReviewPaymentsSucessStep: FC = () => {
  const { t } = useTranslation();
  const currency = useStore($paymentsCurrencyFilter);
  const paymentsTotalAndFees = useStore($calculatedPaymentsSummary);
  const selectedPayments = useSelectedPayments();

  const data = paymentsTotalAndFees?.[currency];
  const total = data?.total;

  return (
    <InfoDialogTemplate
      className={styles.reviewPaymentsSucessStep}
      icon={
        <Icon
          type={ICON_TYPES.CHECK}
          withBackground
          style={ICON_STYLES.POSITIVE}
        />
      }
      title={t('reviewPaymentsSucessStep.title')}
      description={t('reviewPaymentsSucessStep.description', {
        total: formatCurrencyFn(total, currency),
        accountName: getPaymentAccountName(currency),
        paymentsCount: selectedPayments?.length,
      })}
    />
  );
};
