/*
 * Поддерживаемые браузером форматы изображения.
 */
export enum SUPPORTED_IMAGE_TYPES {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  WEBP = 'image/webp',
  AVIF = 'image/avif',
}

/*
 * Маппинг признаков поддерживаемых браузером форматов изображения.
 */
const SUPPORTED_IMAGE_TYPES_MAP: Record<SUPPORTED_IMAGE_TYPES, boolean> = {
  [SUPPORTED_IMAGE_TYPES.PNG]: false,
  [SUPPORTED_IMAGE_TYPES.JPEG]: false,
  [SUPPORTED_IMAGE_TYPES.WEBP]: false,
  [SUPPORTED_IMAGE_TYPES.AVIF]: false,
};

/*
 * Выставляет признаки поддержки поддерживаемых браузером форматов изображения.
 * @see: https://habr.com/ru/company/yandex/blog/559442/ or https://habr.com/ru/post/594211/
 */
function fillSupportedImageTypesMap() {
  async function checkSupport(type: SUPPORTED_IMAGE_TYPES): Promise<boolean> {
    const img = document.createElement('img');

    document.createElement('picture').append(
      Object.assign(document.createElement('source'), {
        srcset: 'data:,x', // валидный URL, который не дёргает сеть
        type,
      }),
      img
    );
    await 0; // даём примениться currentSrc

    return !!img.currentSrc; // если браузер умеет, он заполнит значение currentSrc
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const type of Object.values(SUPPORTED_IMAGE_TYPES)) {
    checkSupport(type).then((isSupport) => {
      SUPPORTED_IMAGE_TYPES_MAP[type] = isSupport;
    });
  }
}

fillSupportedImageTypesMap();

/*
 * Возвращает признак поддержки браузером переданного формата изображения.
 */
export function checkSupportsImageType(type: SUPPORTED_IMAGE_TYPES): boolean {
  return SUPPORTED_IMAGE_TYPES_MAP[type];
}
