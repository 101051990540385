/*
 * Modal window component.
 */
import cn from 'clsx';
import { FC, PropsWithChildren, Ref, useEffect } from 'react';
import { ErrorBoundary } from '@uikit/components/ErrorBoundary';
import { ICON_TYPES, Icon } from '@uikit/components/Icon';
import { Portal } from '@uikit/components/Portal';
import { CLOSE_PORTAL_NODE_ID } from '@uikit/constants/common.constants';
import { useClickAway } from '@uikit/hooks/useClickAway.hook';
import styles from './modalWindow.module.scss';

type TModalWindowProps = {
  onClose: () => void; // callback of modal window close by user
  withClose?: boolean; // признак возможности закрытия модалки (отображения креста и закрытия по клику на фон)
  autofocus?: boolean; // признак необходимости автофокусировки модалки (применяется по умолчанию, что бы убрать управление через клавиатуру элементами на заднем фоне модалки)
  withBlur?: boolean; // признак необходимости блюр фона синего
  className?: string; // classname для настройки стилей модального окна
};

export const ModalWindow: FC<PropsWithChildren<TModalWindowProps>> = ({
  onClose,
  children,
  withClose = true,
  autofocus = true,
  withBlur = true,
  className,
}) => {
  const windowRef = useClickAway(onClose);

  useEffect(() => {
    autofocus && windowRef.current?.focus();
  }, []);

  return (
    <Portal>
      <div
        id={withClose ? CLOSE_PORTAL_NODE_ID : ''}
        className={cn(styles.modalWindow__container, withBlur && styles.modalWindow__overlay)}
      >
        <div
          ref={windowRef as Ref<HTMLDivElement>}
          className={cn(styles.modalWindow, className)}
          tabIndex={-1}
        >
          {withClose && (
            <button
              className={styles.modalWindow__closeButton}
              type="button"
              onClick={onClose}
            >
              <Icon
                type={ICON_TYPES.CLOSE}
                size={10}
              />
            </button>
          )}

          <div className={styles.modalWindow__content}>
            <ErrorBoundary layerName="modal">{children}</ErrorBoundary>
          </div>
        </div>
      </div>
    </Portal>
  );
};
