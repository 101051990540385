/*
 * Percentage component
 */
import { FC, memo } from 'react';
import { MaskedInput } from '@uikit/components/Fields/Inputs';
import { MASKED_INPUT_TYPES } from '@uikit/components/Fields/Inputs/MaskedInput';
import styles from './Percentage.module.scss';

type TPercentageProps = {
  firstText: string;
  lastText?: string;
  isDisabled: boolean;
  onChange: (value: number) => void;
  value: number;
};

export const Percentage: FC<TPercentageProps> = memo(
  ({ firstText, lastText, isDisabled, onChange, value }) => (
    <>
      <div className={styles.percentage}>
        <div className={styles.percentage__firstLabel}>{firstText}</div>
        <MaskedInput
          hideError
          placeholder="%"
          disabled={isDisabled}
          max={100}
          precision={0}
          maskType={MASKED_INPUT_TYPES.PERCENT}
          value={value}
          onChange={onChange}
          className={styles.percentage__input}
        />
      </div>
      {lastText && <div className={styles.percentage__lastLabel}>{lastText}</div>}
    </>
  )
);
