/*
 * Конфигурация для фильтров в кампании TikTok
 */
import {
  Age,
  DayNumber,
  Gender,
  ITiktokCampaignFilterDto,
  InfluencerGender,
  TiktokAudienceAgesFilter,
  TiktokAudienceGendersFilter,
  TiktokCampaignFilterName,
  TiktokEngagementRateFilter,
  TiktokFilterName,
  TiktokGenderFilter,
  TiktokHasContactFilter,
  TiktokReachFilter,
} from '@hypetrainCommon';
import { FILTER_TYPES } from '@uikit/components/Filter';
import { TCheckboxFilterConfig } from '@uikit/components/Filter/components/CheckboxFilter';
import { TRadioGroupFilterConfig } from '@uikit/components/Filter/components/RadioGroupFilter';
import {
  RANGE_TYPES,
  TRangeConfig,
} from '@uikit/components/Filter/components/RangeFilter/RangeFilter.types';
import {
  DEFAULT_RANGE_SELECT_FROM_OPTIONS,
  DEFAULT_RANGE_SELECT_TO_OPTIONS,
} from '@uikit/components/Filter/components/RangeFilter/components/RangeSelect/rangeSelect.constants';
import { TSelectFilterConfig } from '@uikit/components/Filter/components/SelectFilter';
import { TAvailableFilter } from '@uikit/components/Filters';
import { TFilter } from '@uikit/components/Filters/filters.types';
import { stringToBoolean } from '@uikit/components/Filters/filters.utils';
import i18n from '@services/i18n';
import { isoCountries } from '@constants/countries';
import { getLanguagesFx } from '@models/languages';
import { CAMPAIGN_FILTERS_GROUPS } from '@pagesCampaigns/CampaignDetailsPage/components/CampaignsFilters/instagramCampaignsFilters.config';

const engagementRateFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: TiktokFilterName.EngagementRate,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  filterType: FILTER_TYPES.SELECT,
  label: i18n.t('filters.tikTok.engagementRate'),
  popupTitle: i18n.t('filters.tikTok.engagementRateLabel'),
  description: i18n.t('filters.tikTok.engagementRateDescription'),
  testId: 'TT_ER_FILTER',
  filterProps: {
    placeholder: i18n.t('filters.tikTok.engagementRatePlaceholder'),
    isMulti: false,
    options: Array.from({ length: 100 }, (_, i) => ({
      label: `>${i + 1}%`,
      value: `${(i + 1) / 100}`,
    })),
  },
  formatValueToDTO: (filter: TFilter): TiktokEngagementRateFilter => ({
    name: TiktokFilterName.EngagementRate,
    min: Number(filter.values?.[0]),
    value: DayNumber.Thirtieth,
  }),
  formatValueFromDTO: (filter: TiktokEngagementRateFilter): TFilter => ({
    name: filter.name,
    values: [filter.min?.toString() || ''],
  }),
};

const followersFilterConfig: TAvailableFilter<TRangeConfig> = {
  id: TiktokFilterName.FollowersCount,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  filterType: FILTER_TYPES.RANGE,
  label: i18n.t('filters.tikTok.followers'),
  testId: 'TT_FOLLOWERS_COUNT_FILTER',
  filterProps: {
    type: RANGE_TYPES.SELECT,
    fromOptions: DEFAULT_RANGE_SELECT_FROM_OPTIONS,
    toOptions: DEFAULT_RANGE_SELECT_TO_OPTIONS,
    from: {
      placeholder: '1k',
    },
    to: {
      placeholder: '>1m',
    },
  },
};

const viewsFilterConfig: TAvailableFilter<TRangeConfig> = {
  id: TiktokFilterName.Reach,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  filterType: FILTER_TYPES.RANGE,
  label: i18n.t('filters.tikTok.views'),
  testId: 'TT_VIEWS_COUNT_FILTER',
  filterProps: {
    type: RANGE_TYPES.SELECT,
    fromOptions: DEFAULT_RANGE_SELECT_FROM_OPTIONS,
    toOptions: DEFAULT_RANGE_SELECT_TO_OPTIONS,
    from: {
      placeholder: '1k',
    },
    to: {
      placeholder: '>1m',
    },
  },
  formatValueToDTO: (filter: TFilter): TiktokReachFilter => ({
    name: TiktokFilterName.Reach,
    min: filter.min,
    max: filter.max,
    value: DayNumber.Thirtieth,
  }),
};

const influencerLocationFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: TiktokFilterName.Country,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  switcherLabel: i18n.t('filters.tikTok.locationInGroup'),
  filterType: FILTER_TYPES.SELECT,
  popupTitle: i18n.t('filters.tikTok.locationLabel'),
  label: i18n.t('filters.tikTok.influencerLocation'),
  description: i18n.t('filters.tikTok.influencerLocationDescription'),
  testId: 'TT_COUNTRY_FILTER',
  filterProps: {
    isMulti: true,
    placeholder: i18n.t('filters.tikTok.locationPlaceholder'),
    options: Object.entries(isoCountries).map(([countryCode, countryName]) => ({
      value: countryCode,
      label: countryName,
    })),
  },
};

const influencerLanguageFilterConfig: TAvailableFilter = {
  id: TiktokFilterName.Language,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  switcherLabel: i18n.t('filters.tikTok.languageInGroup'),
  filterType: FILTER_TYPES.ASYNC_SELECT,
  label: i18n.t('filters.tikTok.influencerLanguage'),
  popupTitle: i18n.t('filters.tikTok.influencerLanguageLabel'),
  testId: 'TT_LANGUAGE_FILTER',
  filterProps: {
    isMulti: false,
    placeholder: i18n.t('filters.tikTok.influencerLanguagePlaceholder'),
    loadOptions: async () => {
      const responseData = await getLanguagesFx();
      return responseData?.map(({ name, code }) => ({
        value: code,
        label: name,
      }));
    },
  },
};

const influencerGendersFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: TiktokFilterName.Gender,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  switcherLabel: i18n.t('filters.tikTok.genderInGroup'),
  popupTitle: i18n.t('filters.tikTok.genderRadioLabel'),
  filterType: FILTER_TYPES.RADIO,
  label: i18n.t('filters.tikTok.influencerGenders'),
  testId: 'TT_GENDER_FILTER',
  filterProps: {
    defaultValue: null,
    options: [
      {
        label: i18n.t('filters.tikTok.any'),
        value: null,
      },
      {
        label: i18n.t('filters.tikTok.male'),
        value: InfluencerGender.Male,
      },
      {
        label: i18n.t('filters.tikTok.female'),
        value: InfluencerGender.Female,
      },
      {
        label: i18n.t('filters.tikTok.maleOrFemale'),
        value: InfluencerGender.Known,
        tooltip: i18n.t('filters.tikTok.maleOrFemaleTooltip'),
      },
      {
        label: i18n.t('filters.tikTok.neutral'),
        value: InfluencerGender.Unknown,
        tooltip: i18n.t('filters.tikTok.neutralTooltip'),
      },
    ],
  },
  formatValueToDTO: (filter: TFilter): TiktokGenderFilter => ({
    name: TiktokFilterName.Gender,
    value: filter.values?.[0] as InfluencerGender,
  }),
};

const haveContactFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: TiktokFilterName.HasContact,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  filterType: FILTER_TYPES.RADIO,
  label: i18n.t('filters.tikTok.hasEmail'),
  testId: 'TT_HAS_CONTACT_FILTER',
  filterProps: {
    defaultValue: null,
    options: [
      {
        label: i18n.t('filters.tikTok.withEmail'),
        value: 'true',
      },
      {
        label: i18n.t('filters.tikTok.withoutEmail'),
        value: 'false',
      },
    ],
  },
  formatValueToDTO: (filter: TFilter): TiktokHasContactFilter => ({
    name: TiktokFilterName.HasContact,
    value: stringToBoolean(filter.values?.[0] || ''),
  }),
};

const audienceGendersFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: TiktokFilterName.AudienceGenders,
  group: CAMPAIGN_FILTERS_GROUPS.AUDIENCE,
  switcherLabel: i18n.t('filters.tikTok.genderInGroup'),
  filterType: FILTER_TYPES.RADIO,
  popupTitle: i18n.t('filters.tikTok.genderRadioLabel'),
  label: i18n.t('filters.tikTok.audienceGenders'),
  testId: 'TT_AUDIENCE_GENDER_FILTER',
  filterProps: {
    hasPercentage: true,
    defaultValue: null,
    options: [
      {
        label: i18n.t('filters.tikTok.any'),
        value: null,
      },
      {
        label: i18n.t('filters.tikTok.male'),
        value: 'male',
      },
      {
        label: i18n.t('filters.tikTok.female'),
        value: 'female',
      },
    ],
  },
  filterInitialValue: {
    name: TiktokFilterName.AudienceGenders,
    min: 0.6,
    max: 1,
  },
  filterValue: {
    name: TiktokFilterName.AudienceGenders,
    min: 0.6,
    max: 1,
  },
  formatValueToDTO: (filter: TFilter): TiktokAudienceGendersFilter => ({
    name: TiktokFilterName.AudienceGenders,
    min: filter.min,
    max: filter.max,
    value: filter.values?.[0] as Gender,
  }),
};

const audienceAgesFilterConfig: TAvailableFilter<TCheckboxFilterConfig> = {
  id: TiktokFilterName.AudienceAges,
  group: CAMPAIGN_FILTERS_GROUPS.AUDIENCE,
  switcherLabel: i18n.t('filters.tikTok.agesInGroup'),
  filterType: FILTER_TYPES.CHECKBOX_GROUP,
  popupTitle: i18n.t('filters.tikTok.audienceAges'),
  label: i18n.t('filters.tikTok.audienceAges'),
  testId: 'TT_AUDIENCE_AGES_FILTER',
  filterProps: {
    hasPercentage: true,
    isAge: true,
    options: {
      '13–17': false,
      '18–24': false,
      '25–34': false,
      '35–44': false,
      '45–64': false,
      '65+': false,
    },
  },
  filterInitialValue: {
    name: TiktokFilterName.AudienceAges,
    min: 0.2,
    max: 1,
  },
  filterValue: {
    name: TiktokFilterName.AudienceAges,
    min: 0.2,
    max: 1,
  },
  formatValueToDTO: (filter: TFilter): TiktokAudienceAgesFilter => ({
    name: TiktokFilterName.AudienceAges,
    min: filter.min,
    max: filter.max,
    value: filter.values?.[0] === '65-65+' ? Age.From65To65Plus : (filter.values?.[0] as Age),
  }),
  formatValueFromDTO: (filter: TiktokAudienceAgesFilter): TFilter => ({
    name: filter.name,
    min: filter.min,
    max: filter.max,
    values: filter.value === Age.From65To65Plus ? ['65-65+'] : [filter.value],
  }),
};

const audienceLocationFilterConfig: TAvailableFilter<TSelectFilterConfig> = {
  id: TiktokFilterName.AudienceCountries,
  group: CAMPAIGN_FILTERS_GROUPS.AUDIENCE,
  switcherLabel: i18n.t('filters.tikTok.locationInGroup'),
  filterType: FILTER_TYPES.SELECT,
  popupTitle: i18n.t('filters.tikTok.locationLabel'),
  label: i18n.t('filters.tikTok.audienceLocations'),
  infoTooltip: i18n.t('filters.tikTok.audienceLocationTooltipText'),
  testId: 'TT_AUDIENCE_COUNTRIES_FILTER',
  filterProps: {
    isMulti: true,
    maxValues: 3,
    placeholder: i18n.t('filters.tikTok.locationPlaceholder'),
    options: Object.entries(isoCountries).map(([countryCode, countryName]) => ({
      value: countryCode,
      label: countryName,
    })),
    hasPercentage: true,
    percentageFirstLabel: i18n.t('countriesFirstLabel'),
    percentageLastLabel: i18n.t('instagramCountriesSecondLabel'),
  },
  filterInitialValue: {
    name: TiktokFilterName.AudienceCountries,
    min: 0.2,
    max: 1,
  },
  filterValue: {
    name: TiktokFilterName.AudienceCountries,
    min: 0.2,
    max: 1,
  },
};

const replyStatusFilterConfig: TAvailableFilter<TRadioGroupFilterConfig> = {
  id: TiktokCampaignFilterName.ReplyStatus,
  group: CAMPAIGN_FILTERS_GROUPS.INFLUENCER,
  filterType: FILTER_TYPES.RADIO,
  label: i18n.t('filters.tikTok.replyStatus'),
  testId: 'TT_REPLY_STATUS_FILTER',
  filterProps: {
    defaultValue: null,
    options: [
      {
        label: i18n.t('filters.tikTok.replied'),
        value: 'true',
      },
      {
        label: i18n.t('filters.tikTok.notReplied'),
        value: 'false',
      },
    ],
  },
  formatValueToDTO: (filter: TFilter): ITiktokCampaignFilterDto => ({
    name: TiktokCampaignFilterName.ReplyStatus,
    value: stringToBoolean(filter.values?.[0] || ''),
  }),
};

export const AVAILABLE_TIKTOK_CAMPAIGN_FILTERS_CONFIG: TAvailableFilter[] = [
  followersFilterConfig,
  engagementRateFilterConfig,
  viewsFilterConfig,
  influencerLocationFilterConfig,
  influencerGendersFilterConfig,
  influencerLanguageFilterConfig,
  haveContactFilterConfig,
  replyStatusFilterConfig,
  audienceLocationFilterConfig,
  audienceAgesFilterConfig,
  audienceGendersFilterConfig,
];
