/*
 * Radio group context.
 * Provides API for interaction Radio component with RadioGroup component.
 */
import { createContext, useContext } from 'react';

export type TRadioGroupContext = {
  radioGroupValue: unknown;
  onChangeRadioGroup: (value: unknown) => void;
} | null;

const RadioGroupContext = createContext<TRadioGroupContext>(null);

export const RadioGroupProvider = RadioGroupContext.Provider;

export const useRadioGroup = () => useContext(RadioGroupContext);
