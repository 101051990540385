/*
 * Компонент-обёртка для диаграмм.графиков, обеспечивающий их общее поведение.
 */
import cn from 'clsx';
import _isNil from 'lodash/isNil';
import _isNumber from 'lodash/isNumber';
import { FC, PropsWithChildren, ReactNode } from 'react';
import barChartNoData from '@uikit/assets/images/chartCardStub.svg';
import { Change } from '@uikit/components/Change';
import {
  FORMAT_NUMBER_NOTATION,
  FORMAT_NUMBER_STYLES,
  FormatNumber,
} from '@uikit/components/Formats';
import { ICON_STYLES } from '@uikit/components/Icon/icon.types';
import { InformationTooltip } from '@uikit/components/InformationTooltip';
import styles from './ChartCard.module.scss';

type TChartCardProps = {
  name: string; // отображаемое имя диаграммы/графика
  dynamicNamePart?: string; // динамическая часть имени, зависит от чего-то изменяемое (курсивом обозначается)
  value?: number; // числовое значение (не модель передаваемая в оборачиваемую диаграмму/график)
  valueFormat?: FORMAT_NUMBER_STYLES;
  change?: number; // значение отображаемое в блоке изменения
  info?: string; // подсказка
  rightHeaderContent?: ReactNode; // контект, отображаемый вверху справа (возле info-иконки)
  className?: string;
  isNoData?: boolean;
};

export const ChartCard: FC<PropsWithChildren<TChartCardProps>> = ({
  name,
  dynamicNamePart,
  value,
  valueFormat = FORMAT_NUMBER_STYLES.DECIMAL,
  change,
  info,
  isNoData,
  rightHeaderContent,
  className,
  children,
}) => (
  <div className={cn(styles.chartCard, !children && styles.chartCard_withoutContent, className)}>
    <div className={styles.chartCard__header}>
      <div className={styles.chartCard__leftHeaderPart}>
        <div className={styles.chartCard__name}>
          <span>{name}</span>
          {dynamicNamePart && (
            <span className={styles.chartCard__name_dynamic}>{` ${dynamicNamePart}`}</span>
          )}
        </div>

        {(!_isNil(value) || _isNumber(change)) && (
          <div className={styles.chartCard__info}>
            <FormatNumber
              className={styles.chartCard__value}
              numberStyles={valueFormat}
              notation={FORMAT_NUMBER_NOTATION.COMPACT}
            >
              {value}
            </FormatNumber>

            {_isNumber(change) && <Change value={change} />}
          </div>
        )}

        {_isNil(value) && !children && <div className={styles.chartCard__emptyValue}>–</div>}
      </div>

      <div className={styles.chartCard__rightHeaderPart}>
        {rightHeaderContent}
        {info && (
          <InformationTooltip
            iconStyle={ICON_STYLES.SECONDARY}
            className={styles.chartCard__infoIcon}
          >
            {info}
          </InformationTooltip>
        )}
      </div>
    </div>

    {isNoData && (
      <img
        className={styles.chartCard__noData}
        src={barChartNoData}
        alt="noData"
      />
    )}

    {!isNoData && children && <div className={styles.chartCard__content}>{children}</div>}
  </div>
);
