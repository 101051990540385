/**
 * Service of popup component.
 */
import { RefObject } from 'react';
import { EventBus } from '@uikit/utils/eventBus';
import { PopupEvents, PopupPositions, PopupSides } from './popup.enum';
import { PopupStore } from './popup.store';
import { TCorrectedPopupCoordinates, TPopup, TPopupCoordinates } from './popup.types';

const ANIMATION_DURATION = 200;
const ANIMATION_DELAY = 1000;
const POPUP_POSITIONS_COUNT = 12;

const OPEN_EVENT = 'OPEN_POPUP';
const CLOSE_EVENT = 'CLOSE_POPUP';

export class PopupService {
  /*
   * Признак необходимости игнорирования hover-события (наведение мыши, увод мыши) на якоре popup'а.
   */
  private get isIgnoreHoverEvent(): boolean {
    return (
      !this.popupStore.isShowByHover ||
      this.popupStore.withExternalControl ||
      this.popupStore.isDisabled
    );
  }

  /*
   * Признак необходимости игнорирования click-события на якоре popup'а.
   */
  private get isIgnoreClickEvent(): boolean {
    return (
      !this.popupStore.isShowByClick ||
      this.popupStore.withExternalControl ||
      this.popupStore.isDisabled
    );
  }

  constructor(
    private readonly popupStore: PopupStore,
    private readonly popupRef: RefObject<HTMLDivElement | null>,
    private readonly anchorRef: RefObject<HTMLDivElement | null>,
    private readonly eb: EventBus
  ) {}

  /*
   * Отображает popup.
   */
  public showPopup = (): void => {
    if (this.popupStore.isShow) return;

    this.popupStore.setVisibility(true);
    this.eb.emit(OPEN_EVENT);

    if (this.popupStore.showDuration) {
      this.planClose();
    }

    this.setPosition();
  };

  /**
   * Скрывает popup.
   */
  public hidePopup = (): void => {
    if (!this.popupStore.isShow) return;

    this.popupStore.setVisibility(false);
    this.popupStore.setNeedShow(false);
    this.eb.emit(CLOSE_EVENT);
  };

  /*
   * Обработчик события наведения мыши на якорь popup'а.
   */
  public onMouseEnter = (): void => {
    if (this.isIgnoreHoverEvent) return;

    if (!this.popupStore.needShow) {
      this.popupStore.setNeedShow(true);
      return;
    }

    this.showPopup();
  };

  /*
   * Обработчик события увода мыши с якоря popup'а.
   */
  public onMouseLeave = (): void => {
    if (this.isIgnoreHoverEvent) return;

    if (!this.popupStore.needShow) {
      this.popupStore.setNeedShow(true);
      return;
    }

    this.hidePopup();
  };

  /*
   * Обработчик открытия/скрытия popup по клику.
   */
  public toggleOnClick = (): void => {
    if (this.isIgnoreClickEvent) return;

    this.toggle();
  };

  /*
   * Обработчик открытия/скрытия popup.
   * Flow отображения popup:
   * 1. Popup отсутствует в DOM.
   * 2. Выставляется needShow - popup размещается в DOM (визуально скрытно, не отображется).
   *    Это позволяет получить его размеры, что необходимо для его верного позиционирования.
   * 3. Выставляем isShow = true. Popup отображается, вычисляются его popup, на основе них он позиционируется.
   */
  public toggle = (): void => {
    if (!this.popupStore.needShow) {
      this.popupStore.setNeedShow(true);
      return;
    }

    if (this.popupStore.isShow) {
      this.hidePopup();
      return;
    }

    this.showPopup();
  };

  /*
   * Подписка на открытие popup.
   */
  public onOpen = (cb: () => void): void => {
    this.eb.deleteListeners(OPEN_EVENT);
    this.eb.on(OPEN_EVENT, cb);
  };

  /*
   * Подписка на закрытие popup.
   */
  public onClose = (cb: () => void): void => {
    this.eb.deleteListeners(CLOSE_EVENT);
    this.eb.on(CLOSE_EVENT, cb);
  };

  /*
   * Устанавливает текушее событие-триггер для отображения popup.
   */
  public setEventType = (eventType: PopupEvents): void => {
    this.popupStore.setEventType(eventType);
  };

  /*
   * Запланировать закрытие popup.
   */
  private planClose(): void {
    const showDuration =
      this.popupStore.popupEventType === PopupEvents.Hover
        ? Number(this.popupStore.showDuration) + ANIMATION_DELAY + ANIMATION_DURATION
        : Number(this.popupStore.showDuration) + ANIMATION_DURATION;

    const closeTimeout = setTimeout(() => this.hidePopup(), showDuration);
    this.onClose(() => clearTimeout(closeTimeout));
  }

  /*
   * Позиционирует popup относительно якоря.
   */
  public setPosition(): void {
    const anchorElement = this.anchorRef.current?.firstChild;
    const popup = this.popupRef.current;

    if (!anchorElement || !popup) return;

    const { left, top, position } = this.getCorrectedCoordinates(this.popupStore.position);

    popup.style.transform = `translate(-${left}px, ${top}px)`;
    this.popupStore.setRenderedPosition(position);
  }

  /*
   * Возвращает коррдинаты по доступной для отображения popup позиции.
   * Если переданная позиция не доступна (popup не помещается на экран), пытается подобрать другую позицию.
   * 1. Проверяет, помещается ли popup целиком, при отображении на переданной позиции.
   * 2. Если помещается - возвращает координаты для отображения.
   * 3. Если не помещается - вызывает себя же (рекурсивно) с иной позицией, при которой весь popup предположительно должен поместиться на экран.
   */
  private getCorrectedCoordinates(
    position: PopupPositions, // предлагаемое место для размещения popup
    positionInteration = 1 // текущая итерация вызова этой функции (для защиты от зацикливания, когда не подходит ни одна из позиций)
  ): TCorrectedPopupCoordinates {
    const popupCoordinates = this.getPopupCoordinates(position);
    const { top, left } = popupCoordinates;

    /*
     * Если текущая итерация превышает колличетсво точек позиционирования popup, значит ни одна из них не подошла (popup слошком большой что бы влезть хоть куда-то) - возвращаем позицию по-умолчанию.
     */
    if (positionInteration > POPUP_POSITIONS_COUNT) return { left, top, position };

    const popup = this.popupRef.current as HTMLDivElement;
    /*
     * Popup помещается сверху когда верхняя граница popup не выше верхней границы экрана.
     */
    const isPlacedOnTop = top > 0;
    /*
     * Popup помещается справа когда правая граница popup не выходит за правую границу экрана.
     */
    const isPlacedOnRight = left > 0;
    /*
     * Popup помещается снизу когда нижняя точка popup не выходит за пределы экрана.
     */
    const isPlacedOnBottom = top + popup.offsetHeight <= window.innerHeight;
    /*
     * Popup помещается слева когда место оставшееся слева >= ширине popup.
     */
    const isPlacedOnLeft = window.innerWidth - left >= popup.offsetWidth;
    /*
     * Popup помещается вертикально по центу когда нижняя или верхняя граница popup не выходит за пределы экрана.
     */
    const isPlacedOnVerticalCenter =
      isPlacedOnBottom && top + popup.offsetHeight / 2 <= window.innerHeight;
    /*
     * Popup помещается горизонтально по центу когда левая или правая граница popup не выходит за пределы экрана.
     */
    const isPlacedOnHorizontalCenter =
      isPlacedOnRight &&
      window.innerWidth - left >= popup.offsetWidth / 2 &&
      isPlacedOnLeft &&
      left > 0;

    let newPosition: PopupPositions;

    /* eslint-disable max-len, no-case-declarations */
    // prettier-ignore
    switch (position) { // eslint-disable-line default-case
      case PopupPositions.RightTop:
        newPosition = `${isPlacedOnRight ? PopupSides.Right : PopupSides.Left}-${isPlacedOnTop ? PopupSides.Top : PopupSides.Bottom}` as PopupPositions;
        return (isPlacedOnRight && isPlacedOnTop) ? { left, top, position: newPosition } : this.getCorrectedCoordinates(newPosition, positionInteration + 1);

      case PopupPositions.RightCenter:
        newPosition = `${isPlacedOnRight ? PopupSides.Right : PopupSides.Left}-${isPlacedOnVerticalCenter ? PopupSides.Center : PopupSides.Bottom}` as PopupPositions;
        return (isPlacedOnRight && isPlacedOnVerticalCenter) ?{ left, top, position: newPosition } : this.getCorrectedCoordinates(newPosition, positionInteration + 1);

      case PopupPositions.RightBottom:
        newPosition = `${isPlacedOnRight ? PopupSides.Right : PopupSides.Left}-${isPlacedOnBottom ? PopupSides.Bottom : PopupSides.Top}` as PopupPositions;
        return (isPlacedOnRight && isPlacedOnBottom) ? { left, top, position: newPosition } : this.getCorrectedCoordinates(newPosition, positionInteration + 1);

      case PopupPositions.LeftTop:
        newPosition = `${isPlacedOnLeft ? PopupSides.Left : PopupSides.Right}-${isPlacedOnTop ? PopupSides.Top : PopupSides.Bottom}` as PopupPositions;
        return (isPlacedOnLeft && isPlacedOnTop) ? { left, top, position: newPosition } : this.getCorrectedCoordinates(newPosition, positionInteration + 1);

      case PopupPositions.LeftCenter:
        newPosition = `${isPlacedOnLeft ? PopupSides.Left : PopupSides.Right}-${isPlacedOnVerticalCenter ? PopupSides.Center : PopupSides.Bottom}` as PopupPositions;
        return (isPlacedOnLeft && isPlacedOnVerticalCenter) ? { left, top, position: newPosition } : this.getCorrectedCoordinates(newPosition, positionInteration + 1);

      case PopupPositions.LeftBottom:
        newPosition = `${isPlacedOnLeft ? PopupSides.Left : PopupSides.Right}-${isPlacedOnBottom ? PopupSides.Bottom : PopupSides.Top}` as PopupPositions;
        return (isPlacedOnLeft && isPlacedOnBottom) ? { left, top, position: newPosition } : this.getCorrectedCoordinates(newPosition, positionInteration + 1);

      case PopupPositions.TopLeft:
        newPosition = `${isPlacedOnTop ? PopupSides.Top : PopupSides.Bottom}-${isPlacedOnLeft ? PopupSides.Left : PopupSides.Right}` as PopupPositions;
        return (isPlacedOnTop && isPlacedOnLeft) ? { left, top, position: newPosition } : this.getCorrectedCoordinates(newPosition, positionInteration + 1);

      case PopupPositions.TopCenter:
        newPosition = `${isPlacedOnTop ? PopupSides.Top : PopupSides.Bottom}-${isPlacedOnHorizontalCenter ? PopupSides.Center : PopupSides.Right}` as PopupPositions;
        return (isPlacedOnTop && isPlacedOnHorizontalCenter) ? { left, top, position: newPosition } : this.getCorrectedCoordinates(newPosition, positionInteration + 1);

      case PopupPositions.TopRight:
        newPosition = `${isPlacedOnTop ? PopupSides.Top : PopupSides.Bottom}-${isPlacedOnRight ? PopupSides.Right : PopupSides.Left}` as PopupPositions;
        return (isPlacedOnTop && isPlacedOnRight) ? { left, top, position: newPosition } : this.getCorrectedCoordinates(newPosition, positionInteration + 1);

      case PopupPositions.BottomLeft:
        newPosition = `${isPlacedOnBottom ? PopupSides.Bottom : PopupSides.Top}-${isPlacedOnLeft ? PopupSides.Left : PopupSides.Right}` as PopupPositions;
        return (isPlacedOnBottom && isPlacedOnLeft) ? { left, top, position: newPosition } : this.getCorrectedCoordinates(newPosition, positionInteration + 1);

      case PopupPositions.BottomCenter:
        newPosition = `${isPlacedOnBottom ? PopupSides.Bottom : PopupSides.Top}-${isPlacedOnHorizontalCenter ? PopupSides.Center : PopupSides.Right}` as PopupPositions;
        return (isPlacedOnBottom && isPlacedOnHorizontalCenter) ? { left, top, position: newPosition } : this.getCorrectedCoordinates(newPosition, positionInteration + 1);

      case PopupPositions.BottomRight:
        newPosition = `${isPlacedOnBottom ? PopupSides.Bottom : PopupSides.Top}-${isPlacedOnRight ? PopupSides.Right : PopupSides.Left}` as PopupPositions;
        return (isPlacedOnBottom && isPlacedOnRight) ? { left, top, position: newPosition } : this.getCorrectedCoordinates(newPosition, positionInteration + 1);
    }
    /* eslint-enable max-len, no-case-declarations */
  }

  /*
   * Возвращает координаты для отображения popup по переданной позиции.
   */
  private getPopupCoordinates(popupPosition: PopupPositions): TPopupCoordinates {
    const isTopOrBottomCase = [
      PopupPositions.TopLeft,
      PopupPositions.TopRight,
      PopupPositions.TopCenter,
      PopupPositions.BottomLeft,
      PopupPositions.BottomRight,
      PopupPositions.BottomCenter,
    ].includes(popupPosition);

    if (isTopOrBottomCase) {
      return this.getCoordinatesForTopOrBottomCases(popupPosition);
    }

    return this.getCoordinatesForLeftOrRightCases(popupPosition);
  }

  /*
   * Возвращает координаты popup при позиционировании сверху или снизу.
   */
  private getCoordinatesForTopOrBottomCases(position: PopupPositions): TPopupCoordinates {
    const { offset } = this.popupStore;
    const popup = this.popupRef.current as HTMLDivElement;
    const anchor = this.anchorRef.current?.firstChild as Element;
    const anchorPosition = anchor.getBoundingClientRect();
    const isLeft = [PopupPositions.BottomLeft, PopupPositions.TopLeft].includes(position);
    const isCenter = [PopupPositions.BottomCenter, PopupPositions.TopCenter].includes(position);
    const isTop = [
      PopupPositions.TopLeft,
      PopupPositions.TopCenter,
      PopupPositions.TopRight,
    ].includes(position);

    const top = isTop
      ? anchorPosition.top - popup.offsetHeight - offset
      : anchorPosition.bottom + offset;

    if (isLeft) {
      return {
        top,
        left: window.innerWidth - anchorPosition.right,
      };
    }

    if (isCenter) {
      return {
        top,
        left:
          window.innerWidth -
          anchorPosition.right -
          popup.offsetWidth / 2 +
          anchorPosition.width / 2,
      };
    }

    return {
      top,
      left: window.innerWidth - anchorPosition.left - popup.offsetWidth,
    };
  }

  /*
   * Возвращает координаты popup при позиционировании слева или справа.
   */
  private getCoordinatesForLeftOrRightCases(position: PopupPositions): TPopupCoordinates {
    const { offset } = this.popupStore;
    const popup = this.popupRef.current as HTMLDivElement;
    const anchor = this.anchorRef.current?.firstChild as Element;
    const anchorPosition = anchor.getBoundingClientRect();
    const isTop = [PopupPositions.LeftTop, PopupPositions.RightTop].includes(position);
    const isCenter = [PopupPositions.LeftCenter, PopupPositions.RightCenter].includes(position);
    const isLeft = [
      PopupPositions.LeftTop,
      PopupPositions.LeftCenter,
      PopupPositions.LeftBottom,
    ].includes(position);

    const left = isLeft
      ? window.innerWidth - anchorPosition.left + offset
      : window.innerWidth - anchorPosition.right - popup.offsetWidth - offset;

    if (isTop) {
      return {
        left,
        top: anchorPosition.bottom - popup.offsetHeight,
      };
    }

    if (isCenter) {
      return {
        left,
        top: anchorPosition.bottom - anchorPosition.height / 2 - popup.offsetHeight / 2,
      };
    }

    return {
      left,
      top: anchorPosition.top,
    };
  }
}

export const createPopupService = (
  popupStore: PopupStore,
  popupRef: RefObject<TPopup>,
  anchorRef: RefObject<HTMLDivElement | null>
): PopupService => {
  const eventBus = new EventBus();
  return new PopupService(popupStore, popupRef, anchorRef, eventBus);
};
